import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Table, Row, Form } from 'reactstrap';
import { useQueryClient } from 'react-query';
import { MenuItem } from '@mui/material';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { HiPencil } from 'react-icons/hi';
import { confirmAlert } from 'react-confirm-alert';

import Sidebar from './../Sidebar.js';
import ButtonComponent from './../../../../components/Buttons';
import './styles.scss';
import ModalComponent from 'components/Modal/index.js';
import Loading from 'components/Loading';
import {
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_OK,
  HTTP_NOT_ACCESS,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_EXPECTATION_FAILED,
} from 'constants/StatusCode.js';
import AlertComponent from 'components/Alert/index.js';
import { apiUpdateMappingRequest, apiCreateMappingRequest, apiDeleteMappingRequest } from 'api/masterData';
import { USE_LIST_MAPPING_REQUEST, WORKFLOW, CATEGORY, ZERO } from 'constants/MasterData';
import { useGetHrListType, useGetHrListCategory, useListMappingRequest } from 'hook/useMasterData.js';
import { isNotNullObject, getStrError } from 'helpers/index.js';
import SelectComponent from 'components/Select/Select.js';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';
import InputField from 'components/Input/InputField.js';

const DEFAULT_FORM_DATA = {
  id: null,
  name: '',
  code: ZERO,
  type: ZERO,
  error_name: '',
  error_type: '',
  error_code: '',
};

const DEFAULT_FORM_DATA_ERROR = {
  error_name: '',
};

const MappingRequest = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [isShowModal, setShowModal] = useState(false);
  const [stateButton, setStateButton] = useState(false);
  const [formAdd, setFormAdd] = useState(DEFAULT_FORM_DATA);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [textModal, setTextModal] = useState({
    title: t('masterData.updateIdSystemRequest'),
    btn_submit: t('common.save'),
  });

  const addMappingRequest = () => {
    setShowModal(true);
    setFormAdd({ ...formAdd, ...DEFAULT_FORM_DATA });
    setTextModal({
      title: t('masterData.moreData'),
      btn_submit: t('common.add'),
    });
  };

  function onclickUpdate(item) {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setShowModal(true);
    setTextModal({
      title: t('masterData.updateIdSystemRequest'),
      btn_submit: t('common.save'),
    });
    setFormAdd({
      ...formAdd,
      id: item.id,
      name: item.name ? item.name : '',
      code: item.code ? item.code : ZERO,
      type: item.type ? item.type : ZERO,
      error_name: '',
      error_code: '',
      error_type: '',
    });
  }

  const handleInput = (e) => {
    setFormAdd({ ...formAdd, [e.target.name]: e.target.value, error_name: '' });
  };

  const handleSelectType = (event) => {
    setFormAdd({ ...formAdd, type: event.target.value, code: ZERO, error_type: '' });
  };

  const handleSelectCode = (event) => {
    setFormAdd({ ...formAdd, code: event.target.value, error_code: '' });
  };

  const { data: uListMappingRequest, isLoading: isLoadingListMappingRequest } = useListMappingRequest();
  const { data: uListMappingRequestType } = useGetHrListType();
  const { data: uListMappingRequestCategory } = useGetHrListCategory();

  function validate() {
    var messages = {};

    if (!formAdd.name) {
      messages.error_name = t('common.pleaseFillOutThisField');
    }

    if (!formAdd.code) {
      messages.error_code = t('common.pleaseSelectField');
    }

    if (!formAdd.type) {
      messages.error_type = t('common.pleaseSelectField');
    }

    if (isNotNullObject(messages)) {
      setFormAdd({
        ...formAdd,
        ...messages,
      });
      return false;
    }
    return true;
  }

  const handleSubmit = async (event) => {
    setFormAdd({ ...formAdd, ...DEFAULT_FORM_DATA_ERROR });
    setStateButton(true);
    event.preventDefault();

    if (!validate()) {
      setStateButton(false);
      return;
    }

    setFormAdd({ ...formAdd, ...DEFAULT_FORM_DATA_ERROR });

    let nameRequest = '';
    if (formAdd.type === WORKFLOW) {
      uListMappingRequestType?.data.forEach((item) => {
        if (item.id === formAdd.code) {
          nameRequest = item.name;
        }
      });
    } else {
      uListMappingRequestCategory?.data.forEach((item) => {
        if (item.id === formAdd.code) {
          nameRequest = item.name;
        }
      });
    }
    const data = {
      name: formAdd.name,
      code: formAdd.code,
      type: formAdd.type,
      name_request: nameRequest,
    };

    let response = {};
    if (!formAdd.id) {
      response = await apiCreateMappingRequest(data);
    } else {
      response = await apiUpdateMappingRequest(formAdd.id, data);
    }

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      let msg = response.data.messages;
      if (msg?.code) {
        setFormAdd({
          ...formAdd,
          error_code: getStrError(msg?.code[0]),
        });
      }

      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModal(false);
      setStateButton(false);
      setFormAdd({ ...formAdd, ...DEFAULT_FORM_DATA });
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_LIST_MAPPING_REQUEST);
    }
    setStateButton(false);
  };

  const getIdRequest = (id, name_request) => {
    if (id < 10) {
      return (
        <>
          00{id} - {name_request}
        </>
      );
    }
    return (
      <>
        0{id} - {name_request}
      </>
    );
  };

  const alertConfirm = (id) => {
    confirmAlert({
      title: t('infoEmployee.youWantToDelete'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteMappingRequest(id);
            if (response?.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_LIST_MAPPING_REQUEST);
            }
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (
              response?.data.status === HTTP_INTERNAL_SERVER_ERROR ||
              response?.data.status === HTTP_EXPECTATION_FAILED
            ) {
              setMessage(response?.data?.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_LIST_MAPPING_REQUEST);
            }
          },
        },
      ],
    });
  };

  return (
    <div>
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>

        <Col lg={9} xl={10}>
          <Row>
            <Col className="md-12">
              <div className="box-group d-flex float-right">
                <ButtonComponent
                  text={t('common.add')}
                  icon={<IoIosAdd />}
                  addClass="ml-3"
                  className="button-add"
                  handleClick={() => addMappingRequest()}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="md-12">
              <div className="table-list">
                <Table className="align-items-center table-hover" responsive>
                  <thead>
                    <tr>
                      <th scope="col">{t('masterData.id')}</th>
                      <th scope="col">{t('masterData.typeRequest')}</th>
                      <th scope="col">{t('masterData.idSystemRequest')}</th>
                      <th scope="col">{t('masterData.type')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {uListMappingRequest?.data?.length === 0 ? (
                      <tr>
                        <td colSpan={5}>
                          <div className="error-no-data">{t('common.notData')}</div>
                        </td>
                      </tr>
                    ) : (
                      uListMappingRequest?.data?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id > 9 ? <>{item.id}</> : <>0{item.id}</>}</td>
                          <td className="text-bold">
                            {item.name.charAt(0).toUpperCase() + item.name.slice(1, item.name.length)}
                          </td>
                          <td className="text-bold">{getIdRequest(item.code, item.name_request)}</td>
                          <td className="text-bold">
                            {item.type === WORKFLOW ? <>{t('masterData.workflow')}</> : <>{t('masterData.category')}</>}
                          </td>
                          <td>
                            <div className="col-action d-flex float-right">
                              <div
                                className={`${
                                  dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                } edit mr-2`}
                                onClick={() => onclickUpdate(item)}
                              >
                                <HiPencil />
                              </div>
                              <div className="remove mr-3" onClick={() => alertConfirm(item.id)}>
                                <MdDelete />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                    {isLoadingListMappingRequest && (
                      <tr>
                        <td colSpan={5}>
                          <Loading addClass="mt-2" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalComponent toggle={() => setShowModal(!isShowModal)} isShowModal={isShowModal} title={textModal.title}>
        <Form className="mt-3" onSubmit={handleSubmit}>
          <div className="fieldset-group mt-2 ">
            <InputField
              label={t('masterData.roleMasterData.nameRole')}
              variant="outlined"
              name="name"
              defaultValue={formAdd.name}
              onHandleInput={handleInput}
              error={formAdd.error_name}
              isRequired={false}
            />
          </div>

          <div className="fieldset-group mt-3">
            <SelectComponent
              name="type"
              addClass="select"
              valueDefault={formAdd.type}
              onHandleChangeSelect={handleSelectType}
              error={formAdd.error_type}
            >
              <MenuItem value={ZERO}>{t('masterData.typeID')}</MenuItem>
              <MenuItem value={WORKFLOW}>{t('masterData.workflow')}</MenuItem>
              <MenuItem value={CATEGORY}>{t('masterData.category')}</MenuItem>
            </SelectComponent>
          </div>

          <div className="fieldset-group mt-3">
            <SelectComponent
              name="code"
              addClass="select"
              valueDefault={formAdd.code}
              onHandleChangeSelect={handleSelectCode}
              error={formAdd.error_code}
            >
              <MenuItem value={ZERO}>{t('masterData.idSystemRequest')}</MenuItem>
              {formAdd.type === WORKFLOW &&
                uListMappingRequestType?.data &&
                uListMappingRequestType?.data?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {getIdRequest(item.id, item.name)}
                    </MenuItem>
                  );
                })}
              {formAdd.type === CATEGORY &&
                uListMappingRequestCategory?.data &&
                uListMappingRequestCategory?.data?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {getIdRequest(item.id, item.name)}
                    </MenuItem>
                  );
                })}
            </SelectComponent>
          </div>

          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModal(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="w-100"
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </Form>
      </ModalComponent>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default MappingRequest;
