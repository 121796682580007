import { COURSE_API, ALL_USERS_API, COURSE_USER_API, CERTIFICATE_CATEGORY, CERTIFICATE_API, END_COMMIT, END_COMMIT_COST } from 'constants/Training';
import { sendPut, sendDelete, sendPost, sendGet } from 'helpers/axios';

export const apiGetCourses = (params) => sendGet(COURSE_API, params);
export const apiCreateCourse = (data) => sendPost(COURSE_API, data);
export const apiUpdateCourse = (data, id) => sendPut(`${COURSE_API}/${id}`, data);
export const apiDeleteCourse = (id) => sendDelete(`${COURSE_API}/${id}`);
export const apiGetCourseById = (params, id) => sendGet(`${COURSE_API}/${id}`, params);
export const apiGetAllUsers = (params) => sendGet(ALL_USERS_API, params);
export const apiGetAllUsersCourse = (params) => sendGet(COURSE_USER_API, params);
export const apiAddMemberToCourse = (data) => sendPost(COURSE_USER_API, data);
export const apiDeleteMemberFromCourse = (id) => sendDelete(`${COURSE_USER_API}/${id}`);
export const apiUpdateMemberCourseInCommit = (id, data) => sendPut(`${COURSE_USER_API}/${id}`, data);
export const apiEndCommitMemberCourse = (id, data) => sendPut(`${COURSE_USER_API}/${id}/${END_COMMIT}`, data);
export const apiGetEndCommitCost = (id) => sendGet(`${COURSE_USER_API}/${id}/${END_COMMIT_COST}`);
export const apiUploadCommitFile = (id, data) => sendPost(`${COURSE_USER_API}/${id}/commit-file`, data);
export const apiDeleteCommitFile = (id) => sendDelete(`${COURSE_USER_API}/${id}/commit-file`);
export const apiDownloadCommitFile = (path) => sendGet(`/api/commit-file?url=${path}`);
export const apiGetCertificateCategory = (params) => sendGet(CERTIFICATE_CATEGORY, params);
export const apiGetCertificates = (data) => sendGet(CERTIFICATE_API, data);
