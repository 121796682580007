import ModalComponent from 'components/Modal';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BackgroundImage } from 'react-image-and-background-image-fade';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';
import { Checkbox, debounce, Grid, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

import CustomInputYup from '../../../../../components/InputYupAndMui/CustomInputYup';
import { MAX_LENGTH_30 } from 'constants/index.js';
import { IS_INPUT_SEARCH } from 'constants/MasterData';
import { useListAllDivision } from 'hook/useMasterData';
import { useListAllDivisionPosition } from 'hook/useMasterData';
import { useListCommunityAddMemberCustom } from 'hook/useMasterData';
import ButtonComponent from 'components/Buttons';
import CheckedIconCheckbox from '../../../../../assets/img/icons/check_circle.svg';
import CustomAutoComplete from 'components/InputYupAndMui/CustomAutoComplete';
import Loading from 'components/Loading';
import avatarDefault from 'assets/img/icons/avatar_default.svg';
import { useCheckUserRole } from 'hook/useMasterData';

const CheckedIcon = () => (
  <span
    style={{ backgroundImage: `url(${CheckedIconCheckbox})`, fontSize: '14px', width: '27px', height: '27px' }}
  ></span>
);

function PopupGroupCustom({ isShowModal, setShowModal, setDataToSave, dataOld }) {
  const {
    control,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();
  const [checkedValues, setCheckedValues] = useState();
  const [userId, setUserId] = useState();
  const [dataUser, setDataUser] = useState();

  // Searching
  const [filter, setFilter] = useState({
    key_word: '',
    division_id: [],
    position_id: [],
  });

  const [filterDivision, setFilterDivision] = useState({
    key_word: '',
  });

  const [filterDivisionPosition, setFilterDivisionPosition] = useState({
    key_word: '',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({ ...filter, key_word: value });
    }, 1000),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchDivision = useCallback(
    debounce((value) => {
      setFilterDivision({ ...filter, key_word: value });
    }, 1000),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchDivisionPosition = useCallback(
    debounce((value) => {
      setFilterDivisionPosition({ ...filter, key_word: value });
    }, 1000),
  );

  // Division
  const { data: uListAllDivision, isLoading: isLoadingListAllDivision } = useListAllDivision(
    isShowModal,
    filterDivision,
  );

  const { data: uCheckUserRole, isLoading: isLoadingCheckUserRole } = useCheckUserRole({ user_id: userId });

  // Position
  const { data: uListAllDivisionPosition, isLoading: isLoadingListAllDivisionPosition } = useListAllDivisionPosition(
    isShowModal,
    filterDivisionPosition,
  );

  // Get all search
  const { data: uListCommunityAddMemberCustom, isLoading: isLoadingListCommunityAddMemberCustom } =
    useListCommunityAddMemberCustom(isShowModal, filter);

  // Handle case filter all user when list user search change
  // Watch value select type contract
  const watchSelectDivision = useWatch({
    control,
    name: 'division_id',
  });
  // Watch value select position
  const watchSelectDivisionPosition = useWatch({
    control,
    name: 'position_id',
  });

  useEffect(() => {
    if (uListCommunityAddMemberCustom?.length > 0) {
      setDataUser(
        uListCommunityAddMemberCustom?.filter(
          (data) => !dataOld?.find((itemOld) => Number(itemOld.user_id) === Number(data.user_id)),
        ),
      );
    } else {
      setDataUser([]);
    }
    // eslint-disable-next-line
  }, [uListCommunityAddMemberCustom]);

  useEffect(() => {
    if (uCheckUserRole) {
      confirmAlert({
        title:
          t('masterData.roleMasterData.thisEmployeeAlreadyHasAnotherAuthorization') +
          uCheckUserRole +
          t('masterData.roleMasterData.doYouWantToChangePermissions'),
        buttons: [
          {
            label: t('infoEmployee.cancel'),
            className: 'btn-alert-cancel',
            onClick: () => {
              let dataUsers = [];
              checkedValues.forEach((item) => {
                if (item.user_id !== userId) {
                  dataUsers.push(item);
                }
              });
              setCheckedValues([...dataUsers]);
              setUserId(null);
            },
          },
          {
            label: t('infoEmployee.ok'),
            className: 'btn-alert-ok',
          },
        ],
        closeOnClickOutside: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uCheckUserRole]);

  // Handle case filter all user when list user search change
  useEffect(() => {
    setFilterDivision({
      ...filterDivision,
      key_word: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSelectDivision]);
  useEffect(() => {
    setFilterDivisionPosition({
      ...filterDivisionPosition,
      key_word: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSelectDivisionPosition]);

  // Handle Filter
  const handleFilterPosition = (value) => {
    const transformed = value?.map((item) => item?.id);
    setFilter({ ...filter, position_id: transformed });
  };
  const handleFilterDivision = (value) => {
    const transformed = value?.map((item) => item?.id);
    setFilter({ ...filter, division_id: transformed });
  };

  function handleSelect(checkedName) {
    if (!checkedValues?.find((it) => Number(it.user_id) === Number(checkedName.user_id))) {
      setUserId(checkedName.user_id);
    }

    const newNames = checkedValues?.find((it) => Number(it.user_id) === Number(checkedName.user_id))
      ? checkedValues?.filter((item) => Number(item.user_id) !== Number(checkedName.user_id))
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newNames);
  }

  const checkedAll = () => {
    if (checkedValues?.length === dataUser?.length) {
      setCheckedValues([]);
    } else {
      confirmAlert({
        title: t('masterData.roleMasterData.thisEmployeeAlreadyCanHasAnotherAuthorizationDoYouWantToChangePermissions'),
        buttons: [
          {
            label: t('infoEmployee.cancel'),
            className: 'btn-alert-cancel',
          },
          {
            label: t('infoEmployee.ok'),
            className: 'btn-alert-ok',
            onClick: () => {
              setCheckedValues(dataUser);
            },
          },
        ],
      });
    }
  };

  let conditionalCheckAllWhenNodata = checkedValues?.length === dataUser?.length && dataUser?.length > 0;

  // Const handleClearAllData when close
  const handleClearAllData = () => {
    setShowModal(!isShowModal);
    setFilter({
      key_word: '',
      division_id: [],
      position_id: [],
    });
    setCheckedValues([]);
    reset();
  };

  return (
    <ModalComponent
      isShowModal={isShowModal}
      title={t('masterData.groupAccountList.memberInfo')}
      toggle={() => {
        setShowModal(!isShowModal);
        handleClearAllData();
      }}
      classNameAdd="no-padding-modal-body"
    >
      <form className="popup-add-member-custom">
        {/* Title  */}
        <div className="mt-2 mb-4">
          <CustomInputYup
            placeholder={t('masterData.groupAccountList.nameEmployee')}
            label={t('masterData.groupAccountList.nameEmployee')}
            defaultValue={''}
            name="user_name"
            control={control}
            register={register}
            errors={errors}
            useWatch={useWatch}
            setValue={setValue}
            maxLength={MAX_LENGTH_30}
            type={IS_INPUT_SEARCH}
            handleSearch={handleSearch}
          />
        </div>
        {/* Division and position */}
        <div className="mb-4">
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <CustomAutoComplete
                valueAutoFill={uListAllDivision}
                placeholder={t('masterData.groupAccountList.divisionClass')}
                defaultValue={null}
                label={t('masterData.groupAccountList.divisionClass')}
                name="division_id"
                control={control}
                register={register}
                errors={errors}
                useWatch={useWatch}
                setValue={setValue}
                limitTags={3}
                handleChange={handleFilterDivision}
                keyClose={isShowModal}
                handleSearch={handleSearchDivision}
                isLoading={isLoadingListAllDivision}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomAutoComplete
                valueAutoFill={uListAllDivisionPosition}
                placeholder={t('masterData.groupAccountList.divisionPosition')}
                defaultValue={null}
                label={t('masterData.groupAccountList.divisionPosition')}
                name="position_id"
                control={control}
                register={register}
                errors={errors}
                useWatch={useWatch}
                setValue={setValue}
                limitTags={3}
                handleChange={handleFilterPosition}
                maxLength={MAX_LENGTH_30}
                keyClose={isShowModal}
                handleSearch={handleSearchDivisionPosition}
                isLoading={isLoadingListAllDivisionPosition}
              />
            </Grid>
          </Grid>
        </div>
        {/* List employee */}
        <section className="list-member-custom">
          <p className="text-bold color-navy d-inline">{t('masterData.groupAccountList.listMember')}</p>
          <span
            className={`float-right ${conditionalCheckAllWhenNodata ? 'not-select-all-option' : 'select-all-option'}`}
            onClick={checkedAll}
          >
            {conditionalCheckAllWhenNodata
              ? t('masterData.groupAccountList.notSelectAll')
              : t('masterData.groupAccountList.selectAll')}
          </span>
          <List className="list-member-checkbox mt-1">
            {isLoadingListCommunityAddMemberCustom && <Loading addClass="mt-3 mb-3" />}
            {dataUser?.map((item, index) => (
              <ListItem
                className={classNames(
                  'w-100 item-selected disable-checkbox',
                  checkedValues && checkedValues?.find((it) => it.user_id === item.user_id) && 'selected-item',
                )}
                key={index}
              >
                <ListItemButton>
                  <ListItemText>
                    <Controller
                      render={({ field: { onChange } }) => (
                        <FormControlLabel
                          label={
                            <div className="d-flex align-items-center info-wrapper">
                              <BackgroundImage
                                element="section"
                                src={item?.avatar ? item?.avatar : avatarDefault}
                                lazyLoad
                                wrapperClassName="avatar p-0"
                              />
                              <div>
                                <span className="d-block user-name">{item?.user_name}</span>
                                <span className="bottom-content">
                                  {item?.email} - {item?.position_name}
                                </span>
                              </div>
                            </div>
                          }
                          control={
                            <Checkbox
                              checked={checkedValues?.find((it) => it.user_id === item.user_id) ? true : false}
                              checkedIcon={<CheckedIcon />}
                              onChange={() => handleSelect(item)}
                            />
                          }
                        />
                      )}
                      name="names"
                      control={control}
                    />
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            {!isLoadingListCommunityAddMemberCustom && dataUser?.length < 1 && (
              <p className="error">{t('common.notData')}</p>
            )}
          </List>
        </section>
        {/* Button */}
        <div className="mt-2">
          <ButtonComponent
            text={t('masterData.groupAccountList.addMember')}
            addClass="w-100"
            handleClick={() => {
              setDataToSave(checkedValues);
              handleClearAllData();
            }}
            hasDisabled={isLoadingCheckUserRole}
          />
        </div>
      </form>
    </ModalComponent>
  );
}

export default PopupGroupCustom;
