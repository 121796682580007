import MenuItem from '@mui/material/MenuItem';
import React, { useState, useEffect } from 'react';
import { Col, Row, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import iconEmployee from './../../../../assets/img/icons/employee.png';

import './style.scss';
import * as ColorConstants from './../../../../constants/ColorMap.js';
import DoughnutComponent from './../../../../components/Doughnut';
import Loading from 'components/Loading';
import SelectComponent from 'components/Select/Select';
import { optionDoughnut, optionDoughnut2 } from './../../../../variables/ctChats.js';
import { usePositionLevel } from './../../../../hook/useEmployees.js';
import { NOT_DATA } from 'constants/index.js';

const Session = (props) => {
  const { t } = useTranslation();
  const { listDivision } = props;

  const [filterDivisionPositionLevel, setFilterDivisionPositionLevel] = useState(0);
  const { data: listPositionLevel, isLoading: isLoadingPositionLevel } = usePositionLevel({
    division_id: filterDivisionPositionLevel,
  });

  const [positionMap, setPositionMap] = useState([]);
  const [levelMap, setLevelMap] = useState([]);

  useEffect(() => {
    if (listPositionLevel?.levels) {
      let _dataLeave = [];

      listPositionLevel.levels.forEach((item) => {
        _dataLeave.push({
          name: item.name,
          total: item.total_member,
        });
      });
      setLevelMap(_dataLeave);
    }

    if (listPositionLevel?.positions) {
      let _dataLeave = [];

      listPositionLevel.positions.forEach((item) => {
        _dataLeave.push({
          name: item.name,
          total: item.total_member,
        });
      });
      setPositionMap(_dataLeave);
    }
  }, [listPositionLevel]);

  const labelLevelEmployee = levelMap?.filter((it) => it.total > 0)?.map((item) => item.name);
  const dataLevelEmployee = levelMap?.filter((it) => it.total > 0)?.map((item) => item.total);
  const bgColorLevelEmployee = ColorConstants.COLOR_COMMON.slice(0, labelLevelEmployee.length).map((item) => item);
  const totalMapLevelEmployee = dataLevelEmployee.reduce((a, v) => (a = Number(a) + Number(v)), 0);
  const optionLevelEmployee = optionDoughnut.options;

  const DoughnutDataLevel = {
    labels: labelLevelEmployee,
    datasets: [
      {
        data: dataLevelEmployee,
        backgroundColor: bgColorLevelEmployee,
        hoverOffset: 4,
      },
    ],
  };

  const labelPosition = positionMap.filter((it) => it.total > 0)?.map((item) => item.name);
  const dataLevelPosition = positionMap.filter((it) => it.total > 0)?.map((item) => item.total);
  const bgColorPosition = ColorConstants.COLOR_COMMON.slice(0, labelPosition.length).map((item) => item);
  const totalMapPosition = dataLevelPosition.reduce((a, v) => (a = Number(a) + Number(v)), 0);
  const optionPosition = optionDoughnut2.options;

  const DoughnutDataPosition = {
    labels: labelPosition,
    datasets: [
      {
        data: dataLevelPosition,
        backgroundColor: bgColorPosition,
        hoverOffset: 4,
      },
    ],
  };

  const handelDivisionPosition = (event) => {
    setFilterDivisionPositionLevel(event.target.value);
  };

  const getTotalLevel = (arrays) => {
    if (!arrays) return 0;

    return arrays.reduce(function (value, item) {
      return (value = value + item.total_member);
    }, 0);
  };

  return (
    <>
      <Col className="col-md-12 mt-3">
        <div className="division-map">
          <div className="box-session border-show">
            <Row>
              <Col className="col-md-6">
                <div className="d-flex">
                  <img src={iconEmployee} alt={iconEmployee} className="icon" />
                  <h4 className="title-body">{t('employees.statisticalChartOfPositionsInTheCompany')}</h4>
                </div>
              </Col>
              <Col className="col-md-6">
                <div className="float-right">
                  <SelectComponent
                    name="select"
                    valueDefault={filterDivisionPositionLevel}
                    addClass="w-170"
                    onHandleChangeSelect={handelDivisionPosition}
                  >
                    <MenuItem value={0}>{t('common.all')}</MenuItem>
                    {listDivision &&
                      listDivision?.divisions?.map((division, key) => (
                        <MenuItem key={key} value={division.id}>
                          {division.name}
                        </MenuItem>
                      ))}
                  </SelectComponent>
                </div>
              </Col>

              <Col className="col-md-12">
                <div className="info-top-body">
                  <p className="text-truncate">{t('employees.totalNumberOfPositions')}</p>
                  <p className="number-employee">{labelPosition?.length}</p>
                </div>
              </Col>

              <Col className="col-md-6 col-lg-5">
                <div className="position">
                  <div className="level-map box-map">
                    <DoughnutComponent
                      data={DoughnutDataLevel}
                      mapNote={levelMap.map((item) => item).filter((it) => it.total > NOT_DATA)}
                      bgColor={ColorConstants.COLOR_COMMON}
                      title={t('employees.statisticsByLevel')}
                      options={optionLevelEmployee}
                      total={totalMapLevelEmployee}
                    />
                  </div>
                  <hr />
                  <div className="position-map box-map">
                    <DoughnutComponent
                      data={DoughnutDataPosition}
                      mapNote={positionMap.map((item) => item).filter((it) => it.total > NOT_DATA)}
                      bgColor={ColorConstants.COLOR_COMMON}
                      title={t('employees.positionAllocation')}
                      options={optionPosition}
                      total={totalMapPosition}
                    />
                  </div>
                </div>
              </Col>

              <Col className="col-md-6 col-lg-7">
                <div className="box-list table-total-employee ">
                  <div className="ct-scroll-table">
                    <Table className="align-items-center table-hover border-table" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" className="first pl-3">
                            <div className="text-left">{t('employees.position_')}</div>
                          </th>
                          <th scope="col" className="">
                            <div>
                              {t('common.total')} ({getTotalLevel(listPositionLevel?.levels)})
                            </div>
                          </th>
                          {listPositionLevel?.levels?.map((item) => (
                            <th scope="col">
                              <div>
                                {item.name} ({item.total_member})
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {listPositionLevel &&
                          listPositionLevel?.positions?.map((itemPosition, index) => (
                            <tr key={index}>
                              <td className="first pl-3">{itemPosition.name}</td>
                              <td className="text-bold">{itemPosition.total_member}</td>
                              {itemPosition?.levels?.map((itemLevel) => (
                                <td>{itemLevel.total_member}</td>
                              ))}
                            </tr>
                          ))}

                        {isLoadingPositionLevel && (
                          <tr>
                            <td colSpan={listPositionLevel?.levels?.length ? listPositionLevel?.levels?.length : 2}>
                              <Loading addClass="mt-5" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Session;
