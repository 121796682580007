import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoIosAdd } from 'react-icons/io';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MdDelete } from 'react-icons/md';

import './style.scss';
import Nav from './Nav.js';
import Sidebar from './../../Sidebar.js';
import PopupGroupCustom from './PopupGroupCustom';
import ButtonComponent from 'components/Buttons/index.js';
import {
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_OK,
  HTTP_NOT_ACCESS,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_EXPECTATION_FAILED,
} from 'constants/StatusCode.js';
import { CATEGORY_CERTIFICATE, SYSTEM_ACMS_HR, USE_LIST_USER_DETAIL_ROLE, ZERO } from 'constants/MasterData';
import { MAX_LENGTH_20 } from 'constants/ProfileEmployee.js';
import SearchComponent from 'components/Search';
import { useListUserDatailRole } from 'hook/useMasterData';
import { apiAddUserDetailRole, apiDeleteUserDetailRole } from 'api/masterData'
import AlertComponent from 'components/Alert/index.js';
import { customDate } from 'helpers/formatDate.js';
import { USE_INFOR_USER } from 'constants/Auth.js'

const DetailUser = () => {
  let { t } = useTranslation();
  let location = useLocation();
  const datas = location?.state;
  const queryClient = useQueryClient();

  const [isModalPopupCustom, setIsOpenPopupCustom] = useState(false);
  const [dataToSave, setDataToSave] = useState([]);
  const [key_word, setKeyWord] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');

  const handleSearch = (value) => {
    if (value.length > MAX_LENGTH_20) {
      return;
    }
    setKeyWord(value);
  };

  const { data: uListUserDetailRole } = useListUserDatailRole(
    { system_id: SYSTEM_ACMS_HR, group_role_id: datas.id, key_word: key_word }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    let dataUsers = [];
    let response = {};
    dataToSave.forEach((item) => {
      dataUsers.push(item.user_id);
    })

    if (dataUsers.length > ZERO) {
      response = await apiAddUserDetailRole({ list_user: dataUsers, system_id: SYSTEM_ACMS_HR, group_role_id: datas.id });
    }

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      setMessage(response.data.messages);
      setShowAlert(true);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
    }
    if (response?.data?.status === HTTP_OK) {
      setMessage(response.data.messages);
      setShowAlert(true);
      setDataToSave([]);
      queryClient.invalidateQueries(USE_LIST_USER_DETAIL_ROLE);
      queryClient.invalidateQueries(USE_INFOR_USER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToSave])

  const alertConfirm = (id) => {
    confirmAlert({
      title: t('masterData.roleMasterData.areYouSureYouWantToRemoveThisEmployeeFromTheAuthorization'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteUserDetailRole({ user_id: id, system_id: SYSTEM_ACMS_HR });
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response?.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_LIST_USER_DETAIL_ROLE);
            }

            if (
              response?.data.status === HTTP_INTERNAL_SERVER_ERROR ||
              response?.data.status === HTTP_EXPECTATION_FAILED
            ) {
              setMessage(response?.data?.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_LIST_USER_DETAIL_ROLE);
            }
          },
        },
      ],
    });
  };

  return (
    <div className="master-data-detail-user">
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>
        <Col lg={9} xl={10}>
          <Row>
            <Col lg={6} >
              <Nav type={CATEGORY_CERTIFICATE} id={datas.id} />
            </Col>
            <Col lg={6} className='search-add-member'>
              <SearchComponent
                txtPlaceholder={t('masterData.roleMasterData.searchNameMember')}
                addClass="input-search"
                handleSearch={handleSearch}
                maxLength={MAX_LENGTH_20}
              />
              <ButtonComponent
                text={t('common.add')}
                icon={<IoIosAdd />}
                addClass="ml-3 d-flex align-items-center"
                className="button-add"
                handleClick={() => setIsOpenPopupCustom(true)}
              />
            </Col>
          </Row>
          <Row>
            <TableContainer component={Paper} className="table-list">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow className="header">
                    <TableCell>{t('common.STT')}</TableCell>
                    <TableCell>{t('common.fullName')}</TableCell>
                    <TableCell>{t('timekeeping.request.createdDay')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uListUserDetailRole &&
                    uListUserDetailRole.map((item, index) => (
                      <TableRow key={item.user_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.name}
                          {item.email && <p className='email'>{item.email}</p>}
                        </TableCell>
                        <TableCell>{customDate(item.createdAt, 'DD/MM/YYYY')}</TableCell>
                        <TableCell>
                          <div className="col-action d-flex float-right">
                            <div className="remove mr-3">
                              <MdDelete onClick={() => alertConfirm(item.user_id)} />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  {uListUserDetailRole && uListUserDetailRole.length < 1 && (
                    <TableRow>
                      <TableCell className="error-no-data" colSpan={5}>
                        {t('common.notData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Row>
        </Col>
      </Row>
      {isModalPopupCustom && <PopupGroupCustom
        dataOld={uListUserDetailRole}
        setDataToSave={setDataToSave}
        isShowModal={isModalPopupCustom}
        setShowModal={setIsOpenPopupCustom}
      />}
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default DetailUser;
