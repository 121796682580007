import './../style.scss';
import React from 'react';
import { FiPlus } from 'react-icons/fi';
import AddMember from './AddMember';
import { NavLink } from 'react-router-dom';
import { TrainingProvider } from '../Context/TrainingProvider';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { Card, Button, Row, Col } from 'antd';
import { useState } from 'react';
import { useGetAllUsers } from 'hook/useTraining';
import { useGetCourseDetails } from 'hook/useTraining';
import { useParams } from 'react-router';
import Loading from 'components/Loading';
import CourseInCompanyDetail from './CourseInCompanyDetail';
import CourseOutsideCompanyDetail from './CourseOutsideCompanyDetail';
import CertificateDetail from './CertificateDetail';
import { COURSE_OUTSIDE_COMPANY, COURSE_IN_COMPANY, CERTIFICATE } from 'constants/Training';
import { BiLinkExternal } from 'react-icons/bi';
import ButtonComponent from 'components/Buttons';
import MemberInfo from './MemberInfo';
import { handleExportExcel } from 'helpers';
const ProgramDetails = () => {
  const { t } = useTranslation();
  const params = useParams();
  const id = params.id;
  const [isOpenAddMember, setIsOpenAddMember] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});
  const { course, isLoading, refetch } = useGetCourseDetails('', id);
  const { users } = useGetAllUsers();
  const getDetailComponent = () => {
    switch (course.type) {
      case COURSE_IN_COMPANY:
        return <CourseInCompanyDetail refetch={refetch} record={course} t={t} />;

      case COURSE_OUTSIDE_COMPANY:
        return <CourseOutsideCompanyDetail refetch={refetch} record={course} t={t} />;

      case CERTIFICATE:
        return <CertificateDetail refetch={refetch} record={course} t={t} />;
      default:
        return <></>
    }
  }
  return (

    <TrainingProvider>
      {isLoading ? <Loading /> :
        <div className="scroll ct-form-controller-filter mb-3">
          <div className="nav-bar">

            <Breadcrumb>
              <BreadcrumbItem>
                <NavLink to={{ pathname: '/admin/training/program' }}>
                  {t('training.educationProgram')}
                </NavLink>
              </BreadcrumbItem>
              {isLoading ? "" :
                <BreadcrumbItem>{course.name}</BreadcrumbItem>
              }
            </Breadcrumb>
            <ButtonComponent
              className="button export"
              icon={<BiLinkExternal />}
              text="Export"
              handleClick={() => {
                  handleExportExcel(`/api/course/${course.id}/export-users`,"", `${course.name}_Danh sách nhân sự.xlsx`);
              }}
            >
            </ButtonComponent>
          </div>

          <div >
            <Row gutter={22} className='flex'>
              <Col span={6} >

                {getDetailComponent()}

              </Col>
              <Col span={18} className='flex'>

                <Card
                  className="card member-list"
                  title={
                    <span className="detail-title title" style={{ justifyContent: 'space-between' }} >
                      {t('training.listMember')} ({course.members?.length})
                      <Button className="btn-add-member" size="small" shape="circle" icon={<FiPlus color='#FEA628' size={16} />}
                        onClick={() => {
                          setIsOpenAddMember(true);
                          setSelectedCourse(course);
                        }}
                      />
                    </span>

                  }
                >
                  <Row style={{ flex: 1, padding: '20px 6px 0 13px' }} gutter={[26, 20]}>
                    {course.members?.map((item, key) => (
                      <Col span={8} key={key}>
                        <MemberInfo
                          course_name={course.name}
                          member={course.members[key]}
                          refetch={refetch}
                          t={t}
                        />
                      </Col>
                    ))}
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
          {isOpenAddMember && (
            <AddMember
              isOpen={isOpenAddMember}
              setIsOpen={setIsOpenAddMember}
              selectedCourse={selectedCourse}
              users={users}
              refetch={refetch}
            />
          )}
        </div>
      }
    </TrainingProvider>
  );
};
export default ProgramDetails;
