export const STYLE_SCROLL_CUSTOM = ` &::-webkit-scrollbar {
  width: 5px;
}
&.thin::-webkit-scrollbar {
  width: 2px;
}
&::-webkit-scrollbar-track {
  border-radius: 10px;
  background: white;
}
&::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #fea628;
}`;
