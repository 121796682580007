import classNames from 'classnames';
import React from 'react';

import { styled } from '@mui/material/styles';

import { LENGTH_NO_BORDER } from 'constants/TimeSheet';
import { STYLE_SCROLL_CUSTOM } from 'constants/StyleScroll';

const { Tooltip } = require('@mui/material');

export const StyledTooltip = styled((props) => <Tooltip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-tooltip {
    background-color: #f3f7fb;
    padding: 0 !important;
    border-radius: 5px;
    border: 1px solid #e9ebee;
    max-height: 230px;
    width: 202px;
    overflow: auto;
    box-shadow: 0px 5px 25px rgb(34 51 84 / 15%);

    .request-info-day {
      font-size: 16px;
      color: #223354;
      margin-bottom: 0;
      font-weight: 500;
    }

    .request-item {
      padding: 10px 10px 0px 10px !important;
    }

    .active-border-bottom {
      border-bottom: 1px solid #e9ebee;

      &:last-child {
        border-bottom: none;
      }
    }

    .added-info {
      color: #8d8d8d;
      font-size: 12px;
      display: flex;
    }
    .seperate {
      margin: 0 5px 0 2px;
    }
    ${STYLE_SCROLL_CUSTOM}
  }
`;

export const TooltipContent = ({ item }) => {
  return (
    <>
      {item &&
        item?.map((info, index) => (
          <div
            className={classNames(
              'request-item',
              item.length === LENGTH_NO_BORDER ? 'border-0' : 'active-border-bottom',
            )}
            key={index}
          >
            <p className={classNames('request-info-day', info.hr_time_start_morning_log === null && 'mt-1')}>
              {info.hr_date_log}
            </p>
            <p className="mt-0 added-info">
              {(info?.hr_time_morning_log === 'Ca sáng' || info?.hr_time_afternoon_log === 'Ca chiều') && (
                <>
                  <span className="mr-1 d-block">{info?.hr_time_afternoon_log}</span>
                  {info?.hr_time_morning_log === 'Ca sáng' && info?.hr_time_afternoon_log === 'Ca chiều' && (
                    <span className="seperate">{'-'}</span>
                  )}
                  <span>{info?.hr_time_afternoon_log}</span>
                </>
              )}
            </p>
            {(info?.hr_time_morning_log !== 'Ca sáng' || info?.hr_time_afternoon_log !== 'Ca chiều') && (
              <>
                {info?.hr_time_morning_log?.length <= 8 || info?.hr_time_afternoon_log?.length <= 8 ? (
                 <p className="mt-0 added-info">{info?.hr_time_morning_log} <span>{info?.hr_time_morning_log && info?.hr_time_afternoon_log && ' - '}</span>  {info?.hr_time_afternoon_log}</p>
                ) : (
                  <>
                    {info?.hr_time_morning_log && <p className="mt-0 added-info">{info?.hr_time_morning_log}</p>}
                    {info?.hr_time_afternoon_log && <p className="mt-0 added-info">{info?.hr_time_afternoon_log}</p>}
                    {info?.hr_time_afternoon_log_first && (
                      <p className="mt-0 added-info">{info?.hr_time_afternoon_log_first}</p>
                    )}
                    {info?.hr_time_afternoon_log_second && (
                      <p className="mt-0 added-info">{info?.hr_time_afternoon_log_second}</p>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        ))}
    </>
  );
};
