import { apiGetCourses, apiGetAllUsers, apiGetCertificateCategory, apiGetCertificates, apiGetCourseById, apiGetAllUsersCourse, apiGetEndCommitCost } from 'api/training';
import { COURSE_API, ALL_USERS_API, CERTIFICATE_CATEGORY, CERTIFICATE_API, COURSE_USER_API } from 'constants/Training';
import { HTTP_OK } from 'constants/StatusCode';
import { useQuery } from 'react-query';

export function useGetCourses(params) {
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useQuery([COURSE_API, params], async () => {
    const response = await apiGetCourses(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading, refetch };
}

export function useGetAllUsers(params) {
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useQuery([ALL_USERS_API, params], async () => {
    const response = await apiGetAllUsers(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading, refetch };
}

export function useGetAllUsersCourse(params) {
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useQuery([COURSE_USER_API, params], async () => {
    const response = await apiGetAllUsersCourse(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading, refetch };
}

export function useGetCertificateCategory(params) {
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useQuery([CERTIFICATE_CATEGORY, params], async () => {
    const response = await apiGetCertificateCategory(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading, refetch };
}

export function useGetCertificates(params) {
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useQuery([CERTIFICATE_API, params], async () => {
    const response = await apiGetCertificates(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading, refetch };
}
export function useGetCourseDetails(params, id) {
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useQuery([ COURSE_API, id], async () => {
    const response = await apiGetCourseById(params, id);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading, refetch };
}

export function useGetEndCommitCost(id) {
  const {
    data: response,
    error,
    isSuccess,
    refetch,
  } = useQuery([ COURSE_USER_API, id], async () => {
    if (id) {
      const response = await apiGetEndCommitCost(id);
      if (response.status === HTTP_OK) {
        return response.data;
      }
    }
    return null;
  });

  return { ...response, error, isSuccess, refetch };
}