import NumberFormat from 'react-number-format';

export function FormatNumber({ value, decimalScale, noDecimalSeparator }) {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      renderText={(formattedValue) => <div>{formattedValue}</div>}
      decimalScale={decimalScale ? decimalScale : null}
      decimalSeparator={noDecimalSeparator ? null : '.'}
    />
  );
}

export const formatNumberCurrency = (value) => {
  if (!value) return value;

  let _value = value.toString().replace(/,/g, '');
  _value = value.toString().replace(/\./g, '');
  return new Intl.NumberFormat('vi-VN').format(_value).replace(/\./g, '.');
};

export const formatCurrencyNumber = (value) => {
  if (!value) return value;
  return value.toString().replace(/\./g, '');
};

export const formatHourNumber = (value) => {
  if (!value) return value;
  if (String(value).includes('.')) {
    return (
      new Intl.NumberFormat('vi-VN')
        .format(Number(String(value).slice(0, String(value).indexOf('.'))))
        .replace(/\./g, '.') +
      ',' +
      String(value).slice(String(value).indexOf('.') + 1)
    );
  } else {
    return new Intl.NumberFormat('vi-VN').format(value).replace(/\./g, '.');
  }
};

export const formatNumber = (value) => {
  if (!value) return value;

  let _value = value.toString().replace(/,/g, '');
  return new Intl.NumberFormat('vi-VN').format(_value).replace(/\./g, ',');
};

export const convertNumberToMillion = (value) => {
  return Math.round((value / 1000000) * 100) / 100 + 'M';
};
