import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form } from 'reactstrap';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query';
import $ from 'jquery';
import { Button } from 'reactstrap';

import { HTTP_UNPROCESSABLE_ENTITY, HTTP_OK, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import {
  USE_GET_DETAIL_ROLE,
  ROLE_VIEW_DIAGRAM,
  ROLE_VIEW_EMPLOYEE,
  ROLE_VIEW_CONTRACT,
  ROLE_VIEW_TIMESHEET,
  ROLE_VIEW_ASSETS,
  ROLE_VIEW_MASTER_DATA,
  ROLE_VIEW_INFOR_EMPLOYEE,
  ROLE_VIEW_POLICY,
  SYSTEM_ACMS_HR,
} from 'constants/MasterData.js';

import './style.scss';
import Nav from './Nav.js';
import Sidebar from './../../Sidebar.js';
import YupValidate from './../yupValidate';
import { MANAGER_CERTIFICATE } from 'constants/MasterData';
import { MAX_LENGTH_50 } from 'constants/ProfileEmployee.js';
import RadioCustomRole from './../RadioCustom/RadioCustom.js';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import { apiUpdateRole } from 'api/masterData';
import AlertComponent from 'components/Alert/index.js';
import { useGetDetailRole } from 'hook/useMasterData';
import { NOT_HAVE_ACCESS } from 'constants/MasterData';
import { USE_INFOR_USER } from 'constants/Auth.js';

const DetailRole = () => {
  let { t } = useTranslation();
  let location = useLocation();
  const datas = location?.state;
  const queryClient = useQueryClient();

  const { data: uGetDetailRole } = useGetDetailRole({ group_role_id: datas.id });

  const [errorsCustom, setErrorsCustom] = useState('');
  const [stateButton, setStateButton] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(YupValidate()),
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    if (data.name.trim() === '') {
      setErrorsCustom(t('messages.pleaseEnterTitle'));
      $('input[name=name]').focus();
      return;
    }
    setStateButton(true);
    let dataUpdate = {
      role_id: [
        ROLE_VIEW_DIAGRAM,
        ROLE_VIEW_EMPLOYEE,
        ROLE_VIEW_CONTRACT,
        ROLE_VIEW_TIMESHEET,
        ROLE_VIEW_ASSETS,
        ROLE_VIEW_MASTER_DATA,
        ROLE_VIEW_INFOR_EMPLOYEE,
        ROLE_VIEW_POLICY
      ],
      permission_id: [
        data.diagram,
        data.employee,
        data.contract,
        data.timesheet,
        data.assets,
        data.master_data,
        data.infor_employee,
        data.policy
      ],
      name: data.name,
      group_role_id: datas.id,
      system_id: SYSTEM_ACMS_HR,
    };
    let isViewOrEdit = false;
    dataUpdate.permission_id.forEach((item) => {
      if (item > NOT_HAVE_ACCESS) {
        isViewOrEdit = true;
      }
    });
    if (!isViewOrEdit) {
      setMessage(t('masterData.roleMasterData.pleaseSelectAtLeastOneViewPermissionOrOneEditPermission'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    let response = {};
    response = await apiUpdateRole(dataUpdate);
    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      setMessage(response.data.messages);
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setMessage(response.data.messages);
      setShowAlert(true);
      setStateButton(false);
      queryClient.invalidateQueries(USE_GET_DETAIL_ROLE);
      queryClient.invalidateQueries(USE_INFOR_USER);
    }
    setStateButton(false);
  };

  const watchFieldName = useWatch({
    control,
    name: 'name',
  });

  useEffect(() => {
    if (errorsCustom) {
      setErrorsCustom('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFieldName]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="master-data-detail-role">
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>
        <Col lg={9} xl={10}>
          <Row>
            <Nav type={MANAGER_CERTIFICATE} id={datas.id} />
          </Row>
          <Row className="save-role">
            <Button onClick={handleSubmit(onSubmit)} className={`ctButton`} disabled={stateButton}>
              {t('common.save')}
              <i
                className={classNames(`fa fa-spinner fa-pulse fa-3x fa-fw ${stateButton ? '' : 'hidden'} icon-loading`)}
              />
            </Button>
          </Row>
          <Row>
            <Col lg={8} className="fieldset-group">
              <CustomInputYup
                placeholder={t('masterData.roleMasterData.nameRole')}
                defaultValue={uGetDetailRole?.group_role?.name}
                label={t('masterData.roleMasterData.nameRole')}
                name="name"
                control={control}
                register={register}
                errors={errors}
                addErrors={errorsCustom}
                useWatch={useWatch}
                required
                setValue={setValue}
                maxLength={MAX_LENGTH_50}
              />
            </Col>
            <Col lg={12} className="fieldset-group">
              <h6 className="title-role">{t('masterData.roleMasterData.setupRole')}</h6>
            </Col>
            <Col lg={12} className="fieldset-group mt-4">
              {uGetDetailRole && (
                <>
                  <RadioCustomRole
                    name="diagram"
                    defaultValue={uGetDetailRole?.permission[0]}
                    label={t('divisionDiagram.name')}
                    Controller={Controller}
                    control={control}
                  />
                  <RadioCustomRole
                    name="employee"
                    defaultValue={uGetDetailRole?.permission[1]}
                    label={t('common.employees')}
                    Controller={Controller}
                    control={control}
                  />
                  <RadioCustomRole
                    name="contract"
                    defaultValue={uGetDetailRole?.permission[2]}
                    label={t('dashboard.contract')}
                    Controller={Controller}
                    control={control}
                  />
                  <RadioCustomRole
                    name="timesheet"
                    defaultValue={uGetDetailRole?.permission[3]}
                    label={t('dashboard.timekeeping')}
                    Controller={Controller}
                    control={control}
                  />
                  <RadioCustomRole
                    name="assets"
                    defaultValue={uGetDetailRole?.permission[4]}
                    label={t('dashboard.asset')}
                    Controller={Controller}
                    control={control}
                  />
                  <RadioCustomRole
                    name="policy"
                    defaultValue={typeof(uGetDetailRole?.permission[7]) !== 'undefined' ? uGetDetailRole?.permission[7] : NOT_HAVE_ACCESS}
                    label={t('menu.policy')}
                    Controller={Controller}
                    control={control}
                  />
                  <RadioCustomRole
                    name="master_data"
                    defaultValue={uGetDetailRole?.permission[5]}
                    label={t('menu.masterData')}
                    Controller={Controller}
                    control={control}
                  />
                  <RadioCustomRole
                    name="infor_employee"
                    defaultValue={uGetDetailRole?.permission[6]}
                    label={t('masterData.roleMasterData.watchInforEmployee')}
                    Controller={Controller}
                    control={control}
                  />
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </Form>
  );
};

export default DetailRole;
