import React, { useState, useContext, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Table, Row } from 'reactstrap';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { IoIosAdd } from 'react-icons/io';
import { useQueryClient } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import { isNotNullObject } from 'helpers/index';
import Box from '@mui/material/Box';
import InputField from 'components/Input/InputField';
import 'react-confirm-alert/src/react-confirm-alert.css';
import $ from 'jquery';

import './style.scss';
import Sidebar from './../Sidebar.js';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import ButtonComponent from 'components/Buttons';
import ModalComponent from 'components/Modal';
import SearchComponent from 'components/Search';
import { apiCreateAssetCategory, apiDeleteAssetCategory, apiUpdateAssetCategory } from 'api/masterData.js';
import { MAX_LENGTH_5, MAX_LENGTH_20, MAX_LENGTH_50 } from 'constants/ProfileEmployee.js';
import { useListCategoryAsset } from 'hook/useMasterData.js';
import AlertComponent from 'components/Alert';
import { USE_CATEGORY_ASSET } from 'constants/MasterData';
import { MAX_LENGTH_30 } from 'constants/ProfileEmployee';
import Loading from 'components/Loading';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE, DISABLE_BUTTON_ROLE } from 'constants/index.js';
import { Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
const RentalPrices = React.lazy(() => import('./tabs/rental-prices'))

const TAB = {
  CATEGORY: 1,
  RENT_BOARD: 2,
}

const Warehouse = () => {
  let { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [isShowModalAdd, setShowModalAdd] = useState(false);
  const [key_word, setKeyWord] = useState('');
  const [formAdd, setFormAdd] = useState({
    id: null,
    name: '',
    code: '',
    description: '',
    error_name: '',
    error_code: '',
    error_description: '',
  });
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [textModal, setTextModal] = useState({
    title: t('masterData.moreCategory'),
    btn_submit: t('common.add'),
  });
  const [stateButton, setStateButton] = useState(false);
  const [currentTab, setCurrentTab] = useState(TAB.CATEGORY)

  const { data: listCategoryAsset, isLoading: isLoadingListCategoryAsset } = useListCategoryAsset({ key_word });

  const handleSearch = (value) => {
    setKeyWord(value);
  };

  const addCategoryAsset = () => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setShowModalAdd(true);
    setFormAdd({
      ...formAdd,
      id: null,
      name: '',
      code: '',
      description: '',
      error_name: '',
      error_code: '',
      error_description: '',
    });
    setTextModal({
      title: t('masterData.moreCategory'),
      btn_submit: t('common.add'),
    });
  };

  const handleInput = (e) => {
    const error = {};
    if (e.target.name === 'name') {
      error.error_name = '';
    } else if (e.target.name === 'code') {
      error.error_code = '';
    } else if (e.target.name === 'description') {
      error.error_description = '';
    }
    setFormAdd({ ...formAdd, ...error, [e.target.name]: e.target.value });
  };

  function validate() {
    var error = {};
    var focus = true;
    if (!formAdd.name) {
      error.error_name = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (formAdd.name.length > MAX_LENGTH_20) {
      error.error_name = t('common.pleaseEnterLessThanTwentyCharacters');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (!formAdd.code) {
      error.error_code = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=code]').focus();
        focus = false;
      }
    }
    if (formAdd.description?.length > MAX_LENGTH_50) {
      error.error_description = t('common.pleaseEnterLessThanTwentyCharacters');
      if (focus) {
        $('input[name=description]').focus();
        focus = false;
      }
    }

    if (isNotNullObject(error)) {
      setFormAdd({
        ...formAdd,
        ...error,
      });
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = async (event) => {
    setFormAdd({
      ...formAdd,
      error_name: '',
      error_code: '',
      error_description: '',
    });
    setStateButton(true);
    event.preventDefault();
    if (!validate()) {
      setStateButton(false);
      return;
    }
    setFormAdd({
      ...formAdd,
      error_name: '',
      error_code: '',
      error_description: '',
    });

    const data = {
      name: formAdd.name,
      code: formAdd.code,
      description: formAdd.description,
    };

    let response = {};
    if (!formAdd.id) {
      response = await apiCreateAssetCategory(data);
    } else {
      response = await apiUpdateAssetCategory(formAdd.id, data);
    }

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setFormAdd({
        ...formAdd,
        error_name: msg?.name
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_code: msg?.code
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModalAdd(false);
      setFormAdd({
        ...formAdd,
        id: null,
        name: '',
        code: '',
        description: '',
        error_name: '',
        error_code: '',
        error_description: '',
      });
      setMessage(response.data.messages);
      setShowAlert(true);
      setStateButton(false);
      queryClient.invalidateQueries(USE_CATEGORY_ASSET);
    }

    setTextModal({
      ...textModal,
      title: t('masterData.moreData'),
      btn_submit: t('common.add'),
    });
    setStateButton(false);
  };

  const alertConfirm = (id) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    confirmAlert({
      title: t('common.doYouWantToDeletedData?'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteAssetCategory(id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
              return;
            }
            if (response?.data.status === 200 || response?.data.status === 417) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_CATEGORY_ASSET);
            }
          },
        },
      ],
    });
  };

  const handleEdit = (CategoryAsset) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setFormAdd({
      ...formAdd,
      id: CategoryAsset.id,
      name: CategoryAsset.name,
      code: CategoryAsset.code,
      description: CategoryAsset.description,
      error_name: '',
      error_code: '',
      error_description: '',
    });
    setTextModal({ ...textModal, title: t('masterData.updatingCategory'), btn_submit: t('common.update') });
    setShowModalAdd(!isShowModalAdd);
  };

  return (
    <div>
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>
        <Col lg={9} xl={10}>
          <Row className='mb-3'>
            <Tabs
              value={currentTab}
              onChange={(event, value) => setCurrentTab(value)}
              className={'tabs'}
            >
              <Tab value={TAB.CATEGORY} label={t('masterData.assets.category.tab.category')} disableRipple />
              <Tab value={TAB.RENT_BOARD} label={t('masterData.assets.category.tab.rentalPrices')} disableRipple />
            </Tabs>
          </Row>
          <TabContext value={currentTab}>
            <TabPanel value={TAB.CATEGORY} className='p-0'>
              <Row>
                <Col className="md-12">
                  <div className="box-group d-flex float-right">
                    <SearchComponent
                      txtPlaceholder={t('masterData.findData')}
                      addClass="input-search"
                      handleSearch={handleSearch}
                      defaultValue={key_word}
                      maxLength={MAX_LENGTH_30}
                    />
                    <ButtonComponent
                      text={t('common.add')}
                      icon={<IoIosAdd />}
                      addClass={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                        } ml-3`}
                      handleClick={addCategoryAsset}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="md-12">
                  <div className="table-list">
                    <Table className="align-items-center table-hover" responsive>
                      <thead>
                        <tr>
                          <th scope="col">{t('masterData.categoryCertificateCode')}</th>
                          <th scope="col">{t('masterData.categoryCertificateCategory')}</th>
                          <th scope="col">{t('masterData.categoryAssetDescription')}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {listCategoryAsset &&
                          listCategoryAsset.map((item) => (
                            <tr key={item.id}>
                              <td>{item.code}</td>
                              <td className="text-bold">{item.name}</td>
                              <td>{item.description}</td>
                              <td>
                                <div className="col-action d-flex float-right">
                                  <div
                                    className={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                      } edit mr-2`}
                                  >
                                    <HiPencil onClick={() => handleEdit(item)}></HiPencil>
                                  </div>
                                  <div
                                    className={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                      } remove mr-3`}
                                  >
                                    <MdDelete onClick={() => alertConfirm(item.id)}></MdDelete>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        {isLoadingListCategoryAsset && (
                          <tr>
                            <td colSpan={4}>
                              <Loading addClass="mt-2" />
                            </td>
                          </tr>
                        )}
                        {listCategoryAsset && (!listCategoryAsset || (listCategoryAsset && !listCategoryAsset.length)) && (
                          <tr>
                            <td colSpan={4} className="error-no-data">
                              {t('common.notData')}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </TabPanel>
            <TabPanel value={TAB.RENT_BOARD} className='p-0'>
              <Suspense fallback=''>
                <RentalPrices
                  categories={listCategoryAsset}
                />
              </Suspense>
            </TabPanel>
          </TabContext>
        </Col>
      </Row>

      <ModalComponent
        toggle={() => setShowModalAdd(!isShowModalAdd)}
        isShowModal={isShowModalAdd}
        title={textModal.title}
      >
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className="mt-4">
            <InputField
              label={t('masterData.categoryName')}
              variant="outlined"
              name="name"
              maxLength={MAX_LENGTH_20}
              defaultValue={formAdd.name}
              value={formAdd.name}
              onHandleInput={handleInput}
              isRequired={true}
              error={formAdd.error_name}
            />
          </div>
          <div className="mt-4">
            <InputField
              label={t('masterData.categoryCertificateCode')}
              variant="outlined"
              name="code"
              defaultValue={formAdd.code}
              value={formAdd.code}
              maxLength={MAX_LENGTH_5}
              isRequired={true}
              onHandleInput={handleInput}
              error={formAdd.error_code}
            />
          </div>
          <div className="mt-4">
            <InputField
              label={t('masterData.categoryAssetDescription')}
              variant="outlined"
              name="description"
              maxLength={MAX_LENGTH_50}
              defaultValue={formAdd.description}
              value={formAdd.description}
              onHandleInput={handleInput}
              error={formAdd.error_description}
              rows={2}
            />
          </div>
          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalAdd(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="w-100"
                handleClick={() => setShowModalAdd(true)}
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default Warehouse;
