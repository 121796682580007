import React, { useCallback, useState } from 'react';
import { ButtonBase, Grid, debounce } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import classNames from 'classnames';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import { COLOR_CHECKBOX_NOT_ACTIVE } from 'constants/ColorVariable';
import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import * as Constants from 'constants/index.js';

import styles from './styles.module.scss';
import ButtonComponent from 'components/Buttons';
import { customDate } from 'helpers/formatDate.js';
import SearchComponent from 'components/Search';
import Loading from 'components/Loading';
import AlertComponent from 'components/Alert';
import iconLoading from 'assets/img/icons/loading.png';
import iconSandClockGray from 'assets/img/icons/sandClockGray.png';
import iconActiveGray from 'assets/img/icons/activeGray.png';
import iconMenuTotal from 'assets/img/icons/menu-total.png';
import { convertFormatTimeSend } from 'views/pages/MasterData/SpecialTimekeeping/ultil';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { DEFAULT_LIMIT, DEFAULT_PAGE_MUI, PER_PAGE_OPTION } from 'constants/index.js';
import TablePagination from '@mui/material/TablePagination';
import { ID_MAPPING_REQUEST } from 'constants/Assets';
import NavAssets from '../index';
import { useGetCategoryId } from 'hook/useTimekeeping';
import { useListRequestAssets, useTotalListRequestAssets } from 'hook/useAssets';
import { USE_LIST_REQUEST_ASSETS, MAX_LENGTH_CONTENT, START_STRING_SLICE } from 'constants/Assets.js';
import DateDayMonthComponent from 'components/DateTime/DateDay.js';
import { ZERO } from 'constants/MasterData';
import { transferStatus, transferStatusToColor, transferStatusToBackgroundColor } from 'views/pages/TimeKeeping/Request/ultil';
import { CONTROL_RADIO } from 'components/InputYupAndMui/constant';
import { 
  STATUS_REQUEST_OPEN,
  STATUS_REQUEST_IN_PROGRESS, 
  STATUS_REQUEST_REWORK,
  STATUS_REQUEST_COMPLETED, 
  STATUS_REQUEST_RECALL,
  STATUS_REQUEST_DENY,
} from 'constants/TimeKeeping';
import configs from 'config';

const ListRequest = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [filter, setFilter] = useState({
    status: null,
    key_word: null,
    start_date: null,
    end_date: convertFormatTimeSend(new Date(), 'DD/MM/YYYY'),
    page: DEFAULT_PAGE_MUI,
    per_page: DEFAULT_LIMIT,
  });

  const STATUS_REQUEST = [
    {
      value: STATUS_REQUEST_OPEN,
      labelName: t('timekeeping.request.statusOpen'),
    },
    {
      value: STATUS_REQUEST_IN_PROGRESS,
      labelName: t('timekeeping.request.statusInProgress'),
    },
    {
      value: STATUS_REQUEST_COMPLETED,
      labelName: t('timekeeping.request.statusCompleted'),
    },
    {
      value: STATUS_REQUEST_DENY,
      labelName: t('timekeeping.request.statusDeny'),
    },
    {
      value: STATUS_REQUEST_REWORK,
      labelName: t('timekeeping.request.statusRework'),
    },
    {
      value: STATUS_REQUEST_RECALL,
      labelName: t('timekeeping.request.statusRecall'),
    }
  ];
  const [isShowAlert, setShowAlert] = useState(false);
  const [message] = useState([]);

  const { data: idCategory, isLoading: isLoadingIdCategory } = useGetCategoryId(ID_MAPPING_REQUEST);
  const { data: uListRequestAssets, isLoading: isLoadingListRequestAssets } = useListRequestAssets({
    ...filter,
    page: filter?.page + 1,
    category_id: idCategory?.data?.code,
  });
  const { data: uTotalListRequestAssets } = useTotalListRequestAssets({
    start_date: filter.start_date,
    end_date: filter.end_date,
    category_id: idCategory?.data?.code,
  });

  const { control } = useForm({
    mode: 'onBlur',
  });

  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({ ...filter, key_word: value, page: DEFAULT_PAGE_MUI });
    }),
  );

  const handleReset = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFilter({
      status: null,
      key_word: null,
      start_date: null,
      end_date: convertFormatTimeSend(new Date(), 'DD/MM/YYYY'),
      page: DEFAULT_PAGE_MUI,
      per_page: DEFAULT_LIMIT,
    });
    await queryClient.invalidateQueries(USE_LIST_REQUEST_ASSETS);
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const renderOrder = (index) => {
    const order = filter?.page * filter?.per_page + index + 1;
    if (order <= 9) {
      return <>0{order}</>;
    }
    return order;
  };

  return (
    <>
      <div className={styles.content}>
        <Grid container className="nav-fixed">
          <Grid container className="align-items-center">
            <Grid item md={8} xs={12}>
              <NavAssets />
            </Grid>
            <Grid item className="button text-right" md={4} xs>
              <a href={`${configs.DOMAIN_WORK_FOLLOW}/request`} target="blank">
                <ButtonComponent
                  addClass={styles.export}
                  icon={<AddIcon />}
                  text={t('assets.assetsListRequests.createRequest')}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={9} className={styles.assetMain}>
            <div className={styles.profileBox}>
              <Paper>
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead className={styles.assetHeader}>
                      <TableRow className={styles.assetHeaderRow}>
                        <TableCell className={styles.short} align="center">
                          {t('assets.assetsListRequests.stt')}
                        </TableCell>
                        <TableCell align="center">{t('assets.userCode')}</TableCell>
                        <TableCell align="center">{t('assets.assetsListRequests.requester')}</TableCell>
                        <TableCell align="center">{t('employees.email')}</TableCell>
                        <TableCell align="center">{t('assets.dateRequest')}</TableCell>
                        <TableCell align="center">{t('assets.assetsListRequests.requestType')}</TableCell>
                        <TableCell align="center">{t('assets.assetsListRequests.content')}</TableCell>
                        <TableCell align="center">{t('common.status')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(isLoadingListRequestAssets || isLoadingIdCategory) && (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Loading addClass="mt-3 mb-3" />
                          </TableCell>
                        </TableRow>
                      )}
                      {uListRequestAssets &&
                        uListRequestAssets?.data?.data?.map((item, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              <TableCell>{renderOrder(index)}</TableCell>
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item?.user_code ? item?.user_code : <>{t('infoEmployee.notUpdate')}</>}
                              </TableCell>
                              <TableCell>
                                <a
                                  href={`${configs.DOMAIN_WORK_FOLLOW}/request?request_id=${item?.request_id}`}
                                  target="blank"
                                  ref={(input) => {
                                    item.handelClick = input;
                                  }}
                                  className={styles.linkToWorkFl}
                                >
                                  <div className={styles.linkRequest}>
                                    {item?.user_name ? item?.user_name : <>{t('infoEmployee.notUpdate')}</>}
                                  </div>
                                </a>
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item?.email ? item?.email : <>{t('infoEmployee.notUpdate')}</>}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item?.date ? item?.date : <>{t('infoEmployee.notUpdate')}</>}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item?.type_name ? item?.type_name : <>{t('infoEmployee.notUpdate')}</>}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item?.hr_content ? (
                                  item?.hr_content.substring(START_STRING_SLICE, MAX_LENGTH_CONTENT)
                                ) : (
                                  <>{t('infoEmployee.notUpdate')}</>
                                )}
                                {item?.hr_content && item?.hr_content.length > MAX_LENGTH_CONTENT && '...'}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item?.status ? (
                                  <span
                                    className={classNames(
                                      transferStatusToColor(item?.status)
                                    )}
                                  >
                                    {transferStatus(item?.status)}
                                  </span>
                                ) : (
                                  t('common.notDataContract')
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}

                      {!uListRequestAssets?.data?.total && !isLoadingListRequestAssets && !isLoadingIdCategory && (
                        <tr>
                          <td colSpan={8} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {uListRequestAssets?.data?.total > ZERO && (
                  <TablePagination
                    rowsPerPageOptions={PER_PAGE_OPTION}
                    component="div"
                    count={uListRequestAssets?.data?.total}
                    rowsPerPage={filter?.per_page}
                    page={filter?.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="table-pagination"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                  />
                )}
              </Paper>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className={styles.boxFilter}>
              <div className={classNames(`${styles.selectField} ${styles.selected}`)}>
                <img alt={iconMenuTotal} src={iconMenuTotal} className={styles.imgFilter} />
                <div className={styles.selectFieldTitle}>{t('assets.assetsListRequests.totalRequest')}</div>
                <div className={styles.selectFieldTotal}>{uTotalListRequestAssets?.data?.total_request}</div>
              </div>
              <div className={classNames(`${styles.selectField} ${styles.notSelected}`)}>
                <img alt={iconSandClockGray} src={iconSandClockGray} className={styles.imgFilter} />
                <div className={styles.selectFieldTitle}>{t('assets.assetsListRequests.totalHandingleRequest')}</div>
                <div className={styles.selectFieldTotal}>{
                  uTotalListRequestAssets?.data?.request_processing
                  + uTotalListRequestAssets?.data?.request_open
                  + uTotalListRequestAssets?.data?.request_remake
                }</div>
              </div>
              <div className={classNames(`${styles.selectField} ${styles.marginElementBottom} ${styles.notSelected}`)}>
                <img alt={iconActiveGray} src={iconActiveGray} className={styles.imgFilter} />
                <div className={styles.selectFieldTitle}>{t('assets.assetsListRequests.totalHandledRequest')}</div>
                <div className={styles.selectFieldTotal}>{
                  uTotalListRequestAssets?.data?.request_completed
                  + uTotalListRequestAssets?.data?.request_deny
                  + uTotalListRequestAssets?.data?.request_recall
                }</div>
              </div>
              <div className={classNames(`${styles.text} ${styles.borderTop} ${styles.paddingElementTop}`)}>
                <SearchComponent
                  txtPlaceholder={t('assets.assetsListRequests.searchNameEmployee')}
                  addClass="input-search-box"
                  handleSearch={handleSearch}
                  defaultValue={filter?.key_word}
                  maxLength={Constants.MAX_LENGTH_20}
                />
              </div>
              <div className={`${styles.paddingElementTop} ${styles.dateFilter}`}>
                <DateDayMonthComponent
                  label=""
                  addClass="from-date"
                  name="start_date"
                  valueDefault={
                    typeof filter.start_date === 'string' ? moment(filter.start_date, 'DD/MM/YYYY') : filter.start_date
                  }
                  onChangeHandle={(value) => {
                    setFilter({
                      ...filter,
                      start_date: customDate(value, 'DD/MM/YYYY'),
                      page: DEFAULT_PAGE_MUI,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  placeholder={t('masterData.placeholderStartDate')}
                  sizeDate="small"
                  max={moment(filter?.end_date, 'DD/MM/yyyy').toDate()}
                />
                <p className={styles.between}>~</p>
                <DateDayMonthComponent
                  label=""
                  name="end_date"
                  valueDefault={
                    typeof filter.end_date === 'string' ? moment(filter.end_date, 'DD/MM/YYYY') : filter.end_date
                  }
                  onChangeHandle={(value) => {
                    setFilter({
                      ...filter,
                      end_date: customDate(value, 'DD/MM/YYYY'),
                      page: DEFAULT_PAGE_MUI,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  placeholder={t('masterData.placeholderEndDate')}
                  sizeDate="small"
                  min={moment(filter?.start_date, 'DD/MM/yyyy').toDate()}
                  max={moment().toDate()}
                />
              </div>
              <div className={classNames(`${styles.paddingElementTop}`)}>
                <FormControl component="fieldset">
                  <FormLabel
                    className={classNames(
                      `${styles.text} ${styles.marginElementBottom} ${styles.borderTop} ${styles.paddingElementTop}`,
                    )}
                  >
                    {t('employees.status')}
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value={0}
                          checked={!filter?.status}
                          onChange={(e) => {
                            setFilter({ ...filter, status: null, page: DEFAULT_PAGE_MUI });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={
                            <Radio
                              sx={{
                                color: COLOR_CHECKBOX_NOT_ACTIVE,
                                '&.Mui-checked': {
                                  color: COLOR_CHECKBOX_CUSTOM,
                                },
                              }}
                            />
                          }
                          label={t('employees.all')}
                        />
                        
                        {STATUS_REQUEST?.map((item, index) => (
                          <div key={index} className="division d-flex justify-content-between">
                            <FormControlLabel
                              key={index}
                              value={item?.value}
                              onChange={(e) => {
                                setFilter({
                                  ...filter,
                                  status: e.target.value,
                                  page: Constants.DEFAULT_PAGE_MUI,
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                              }}
                              checked={Number(item?.value) === Number(filter?.status)}
                              control={CONTROL_RADIO}
                              label={item?.labelName}
                            />
                            <div
                              className={classNames(
                                `${styles.dotStatusAccept} ${transferStatusToBackgroundColor(item?.value)}`,
                              )}
                            ></div>
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                    name="status_accept"
                    control={control}
                    defaultValue={filter?.status_accept ? filter?.status_accept : 0}
                  />
                </FormControl>
              </div>
              <div className={styles.borderTop}>
                <ButtonBase
                  onClick={() => handleReset()}
                  className={classNames(`${styles.reset} ${styles.borderTop}`)}
                  size="lg"
                >
                  <img src={iconLoading} alt="Reset" width="20px" className={styles.imgReset} /> &emsp;
                  {t('common.reset')}
                </ButtonBase>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        <p className={styles.textLeft}>{message}</p>
      </AlertComponent>
    </>
  );
};

export default ListRequest;
