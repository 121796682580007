import React, { useState, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';
import moment from 'moment';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import styles from './../styles.module.scss';
import ButtonComponent from 'components/Buttons';
import CustomCheckboxYup from 'components/InputYupAndMui/CustomCheckboxYup';
import ModalComponent from 'components/Modal';
import { MAX_LENGTH_30 } from 'constants/index.js';
import {
  useGeListMemberContract,
  useStatisticalCategoryContract,
  useGenerateCodeContract,
  useCheckContractOnboardUser,
} from 'hook/useContract';

import { YupValidateCreate } from './ValidationCreateEditContract.js';
import { YupValidateEditContract } from './ValidationEditContract.js';
import { apiCreateContract } from 'api/contract.js';
import { HTTP_UNPROCESSABLE_ENTITY, HTTP_OK, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { NOT_IS_ONBOARD } from 'constants/index.js';
import InputField from 'components/ReactForm/InputField.js';
import SelectField from 'components/ReactForm/SelectField.js';
import DateDayCustomInput from 'components/ReactForm/DateDayCustomInput.js';
import { getStrError } from 'helpers/index.js';
import styleSelect from './../../../../components/Select/style.module.scss';
import { TAB_LIST_CONTRACT, TAB_LIST_CONTRACT_WAITING } from 'constants/Contract.js';
import AVATAR_DEFAULT from '../../../../assets/img/icons/avatar_default.svg';
import { INDEFINITE_TERM_CONTRACT } from 'constants/Contract.js';
import { handleOnError } from 'helpers';

const CreateContract = ({
  onSetMessage,
  isShowModal,
  onSetShowModal,
  txtTitleModal,
  txtBtnModal,
  type,
  keyCache,
  user,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    reset,
    setError,
    formState: { errors, isSubmitting },
    clearErrors,
  } = useForm({
    resolver: yupResolver(user ? YupValidateEditContract() : YupValidateCreate()),
    mode: 'onChange',
  });

  const [filter] = useState({ key_word: '', perPage: 60 });
  const [keyGenerate, setKeyGenerate] = useState({
    user_id: 0,
    contract_name: '',
  });
  const [errorForm, setErrorForm] = useState({});
  const [checkContractOnboard, setCheckContractOnboard] = useState({ user_id: '' });

  const { data: uListMember } = useGeListMemberContract(filter);
  const { data: checkContract } = useCheckContractOnboardUser(checkContractOnboard);
  const { data: uListCategoryContract } = useStatisticalCategoryContract();
  const { data: generateCodeContract, isLoading: isLoadingGenerate } = useGenerateCodeContract(keyGenerate);
  const [contractCode, setContractCode] = useState('');

  const watchSelectTypeContract = useWatch({
    control,
    name: 'type_contract',
  });

  useEffect(() => {
    if (checkContract) {
      confirmAlert({
        title: t('assets.thereAlreadyExistsAnotherHDLDWhichIsTheOnboardHDLDAreYouSureYouWantToChange?'),
        buttons: [
          {
            label: t('common.cancel'),
            className: 'btn-alert-cancel',
            onClick: async () => {
              setValue('is_onboard', false);
              setCheckContractOnboard({ user_id: '' });
            },
          },
          {
            label: t('common.ok'),
            className: 'btn-alert-ok',
          },
        ],
      });
    }
    // Handle Filter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkContract, t]);

  useEffect(() => {
    if (generateCodeContract) {
      setContractCode(generateCodeContract);
    }
  }, [generateCodeContract]);

  useEffect(() => {
    if (!isShowModal) {
      setContractCode('');
      reset({
        user: null,
        type_contract: null,
        type_employee: null,
        code_contract: '',
        start_date: null,
        end_date: null,
        is_onboard: NOT_IS_ONBOARD,
      });
      setErrorForm({});
    } else {  
      reset({ start_date: null, end_date: null });
      setKeyGenerate({ user_id: 0, contract_name: '' })
    }
  }, [isShowModal, reset]);

  const watchCheckboxValueOnboard = useWatch({
    control,
    name: 'is_onboard',
    defaultValue: false,
  });

  const watchStartDateValue = useWatch({
    control,
    name: 'start_date',
  });

  const watchEndDateValue = useWatch({
    control,
    name: 'end_date',
  });

  const onSubmit = async (data) => {
    setErrorForm({});

    if (!user) {
      if (!data.user) {
        setError('user', {
          type: 'focus',
          message: t('messages.listContract.pleaseSelectPersonnelNameOfTheContract'),
        });
        return;
      }
    }
    let isOnboard = data.is_onboard && { is_onboard: data.is_onboard };
    let formSubmit = {
      user_id: user ? user.user_id : data.user.user_id,
      contract_category: data.type_contract,
      group_id: data.type_employee,
      code: data.code_contract,
      start_date: data.start_date,
      end_date: Number(data.type_contract) === INDEFINITE_TERM_CONTRACT ? null : data.end_date,
      ...isOnboard,
    };

    let response = {};
    if (type === TAB_LIST_CONTRACT || type === TAB_LIST_CONTRACT_WAITING) {
      response = await apiCreateContract(formSubmit);
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      onSetMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      onSetShowModal(false);
    }
    if (response?.status === HTTP_OK) {
      onSetShowModal(false);
      onSetMessage([response.data.messages]);
      queryClient.invalidateQueries(keyCache);
      clearErrors();
    }

    if (response?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      let msg = response.data.messages;
      if (msg?.code) {
        setError('code_contract', {
          message: getStrError(msg?.code[0]),
        });
      }
      if (msg?.start_date) {
        setError('start_date', {
          message: getStrError(msg?.start_date[0]),
        });
      }
      if (msg?.end_date) {
        setError('end_date', {
          message: getStrError(msg?.end_date[0]),
        });
      }
      if (msg?.user_id) {
        setError('user', {
          message: getStrError(msg?.user_id[0]),
        });
      }
      if (msg?.contract_category) {
        setError('type_contract', {
          message: getStrError(msg?.contract_category[0]),
        });
      }
      if (msg?.group_id) {
        setError('type_employee', {
          message: getStrError(msg?.group_id[0]),
        });
      }
      if (msg?.time_error) {
        setErrorForm({ ...errorForm, time_error: msg?.time_error });
      } else {
        setErrorForm({ ...errorForm, time_error: '' });
      }
    }
  };

  const handleChangeUser = (value) => {
    if (value) {
      setKeyGenerate({ ...keyGenerate, user_id: value.user_id });
    }
  };

  const handelChangeInput = (event) => {
    if (event.target.name === 'type_contract') {
      let categoryContract = uListCategoryContract.filter((item) => item.id === event.target.value);

      if (user && categoryContract && categoryContract.length) {
        setKeyGenerate({ ...keyGenerate, contract_name: categoryContract[0].name, user_id: user.user_id });
      } else if (categoryContract && categoryContract.length) {
        setKeyGenerate({ ...keyGenerate, contract_name: categoryContract[0].name });
      }
    }
    if (type === 'code_contract') setContractCode(event.target.value);
  };

  return (
    <>
      <ModalComponent toggle={onSetShowModal} isShowModal={isShowModal} title={txtTitleModal}>
        <Box autoComplete="off" className={styles.modalCreateContract} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {user ? (
                <InputField
                  label={t('contract.employeeName')}
                  name="user"
                  error=""
                  control={control}
                  setValue={setValue}
                  disabled={true}
                  maxLength={MAX_LENGTH_30}
                  defaultValue={user?.user_name}
                />
              ) : (
                <div className={classNames(styleSelect.selectComponent, styleSelect.selectComponentAuto)}>
                  {uListMember ? (
                    <Controller
                      name="user"
                      control={control}
                      defaultValue={null}
                      render={({ field: { ref, ...field }, fieldState: { error } }) => (
                        <Autocomplete
                          {...field}
                          disableClearable
                          // disablePortal
                          filterSelectedOptions
                          autoHighlight
                          getOptionDisabled={(option) => option.disabled}
                          getOptionLabel={(option) => option.user_name}
                          // onChange={(event, value) => field.onChange(value)}
                          onChange={(event, value) => {
                            field.onChange(value);
                            handleChangeUser(value);
                          }}
                          options={uListMember}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              {...props}
                              key={option.user_id}
                            >
                              <img
                                onError={handleOnError}
                                loading="lazy"
                                src={option.avatar || AVATAR_DEFAULT}
                                alt={option.avatar}
                                className="avatar bg-transparent"
                              />
                              <div>
                                <p>{option.user_name}</p>
                                <p>{option.email}</p>
                              </div>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              required
                              error={!!error}
                              helperText={error?.message}
                              label={t('contract.employeeName')}
                              name="user"
                              type="search"
                              inputRef={ref}
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  ) : (
                    ''
                  )}
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <SelectField
                name="type_contract"
                label={t('contract.contractNav.typeContract')}
                error={errors?.type_contract?.message}
                defaultValue={null}
                onHandleSelect={handelChangeInput}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
              >
                {uListCategoryContract &&
                  uListCategoryContract.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </SelectField>
            </Grid>

            <Grid item xs={12}>
              <InputField
                label={t('contract.codeContract')}
                name="code_contract"
                error={errors?.code_contract?.message}
                isLoading={isLoadingGenerate}
                defaultValue={contractCode}
                control={control}
                setValue={setValue}
                maxLength={MAX_LENGTH_30}
                autoComplete="off"
                onHandleInput={handelChangeInput}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="fs-14 color-navy text-bold">{t('contract.durationContract')}</p>
            </Grid>
            <Grid item xs={6}>
              <DateDayCustomInput
                label={t('masterData.placeholderStartDate')}
                name="start_date"
                error={errors?.start_date?.message}
                defaultValue={null}
                control={control}
                setValue={setValue}
                register={register}
                setError={setError}
                isShowModal={isShowModal}
                maxDate={watchEndDateValue ? moment(watchEndDateValue, 'DD/MM/YYYY').subtract(1, 'days') : null}
              />
              <p className="error-input error">{errorForm?.time_error}</p>
            </Grid>

            <Grid item xs={6}>
              <DateDayCustomInput
                label={t('masterData.placeholderEndDate')}
                name="end_date"
                error={errors?.end_date?.message}
                defaultValue={null}
                control={control}
                setValue={setValue}
                register={register}
                setError={setError}
                isShowModal={isShowModal}
                minDate={watchStartDateValue ? moment(watchStartDateValue, 'DD/MM/YYYY').add(1, 'days') : null}
                disabled={Number(watchSelectTypeContract) === INDEFINITE_TERM_CONTRACT ? true : false}
                isReadOnly={Number(watchSelectTypeContract) === INDEFINITE_TERM_CONTRACT ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={`${styles.colorCheckBox}`}>
                <CustomCheckboxYup
                  name="is_onboard"
                  defaultValue={watchCheckboxValueOnboard}
                  control={control}
                  register={register}
                  onClick={(value) => {
                    if (value.target.checked) {
                      setCheckContractOnboard({ user_id: keyGenerate?.user_id });
                    } else {
                      setCheckContractOnboard({ user_id: '' });
                    }
                  }}
                  label={t('contract.haveContractOnboard')}
                  isDisable={keyGenerate?.user_id ? false : true}
                />
              </div>
            </Grid>
            <p className={styles.notification}>{t('contract.notiEditOrCreate')}</p>
            <div className={styles.footerModal}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <ButtonComponent
                    text={t('common.cancel')}
                    addClass="w-100 btn-bg-yellow2"
                    handleClick={onSetShowModal}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ButtonComponent
                    text={txtBtnModal}
                    addClass="w-100"
                    type="submit"
                    handleClick={handleSubmit(onSubmit)}
                    hasDisabled={isSubmitting}
                    isLoading={isSubmitting}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Box>
      </ModalComponent>
    </>
  );
};

export default CreateContract;
