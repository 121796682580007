import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalCreateContract from './../../Modals/CreateContract.js';
import { TAB_LIST_CONTRACT } from 'constants/Contract.js';
import { USE_LIST_CONTRACT } from 'constants/Contract.js';

const CreateContract = (props) => {
  const { onSetMessage, isShowModalAdd, onSetShowModal, user } = props;

  const { t } = useTranslation();
  return (
    <>
      <ModalCreateContract
        user={user}
        onSetMessage={onSetMessage}
        isShowModal={isShowModalAdd}
        onSetShowModal={onSetShowModal}
        txtTitleModal={t('contract.createContract')}
        txtBtnModal={t('common.add')}
        type={TAB_LIST_CONTRACT}
        keyCache={USE_LIST_CONTRACT}
      />
    </>
  );
};

export default CreateContract;
