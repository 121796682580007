import { Box, Button, Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import rafikiIcon from 'assets/img/icons/404-error-rafiki-1.svg';
import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';

const CommingSoon = (props) => {
  const { isGoBack, isShowCustom, handleCloseCustom } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [isShow, setIsShow] = useState(isShowCustom === undefined ? true : isShowCustom);

  useEffect(() => {
    setIsShow(isShowCustom === undefined ? true : isShowCustom);
  }, [isShowCustom]);

  const handleClose = () => {
    setIsShow(false);
    !isGoBack && history.goBack();
    handleCloseCustom && handleCloseCustom();
  };

  return (
    <>
      <Dialog open={isShow}>
        <Box className={styles.commingSoon}>
          <Box>
            <img src={rafikiIcon} alt="" />
          </Box>
          <Box className={styles.message}>{t('common.commingSoon')}</Box>
          <Box className={styles.button}>
            <Button className={styles.button__ok} onClick={handleClose}>
              {t('common.ok')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default CommingSoon;
