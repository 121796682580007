import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Avatar, ButtonBase, Grid } from '@mui/material';
import { BiLinkExternal } from 'react-icons/bi';
import { Controller } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { HiPencil } from 'react-icons/hi';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FormControlLabel, Radio, RadioGroup, IconButton, MenuItem, Menu } from '@mui/material';

import iconLoading from 'assets/img/icons/loading.png';
import imgAvatar from 'assets/img/theme/avatar_default.png';
import AlertComponent from 'components/Alert';
import ButtonComponent from 'components/Buttons';
import DateDayMonthComponent from 'components/DateTime/DateMonth.js';
import Loading from 'components/Loading';
import ModalComponent from 'components/Modal';
import SearchComponent from 'components/Search';
import EditRequestRetireModal from './ModalEdit.js';
import { BG_POSITION } from 'constants/ColorMap';
import { WHEAT } from 'constants/ColorForm';
import { DEFAULT_LIMIT, DEFAULT_PAGE_MUI, PER_PAGE_OPTION, MAX_LENGTH_20, NOT_DATA } from 'constants/index.js';
import {
  USE_LIST_RETIRE_CONTRACT,
  ZERO_STATUS_ACCEPT,
  ONE_STATUS_ACCEPT,
  TWO_STATUS_ACCEPT,
  ALL_STATUS_RETIRE,
} from 'constants/Contract';
import { 
  STATUS_REQUEST_OPEN,
  STATUS_REQUEST_IN_PROGRESS, 
  STATUS_REQUEST_REWORK,
  STATUS_REQUEST_COMPLETED, 
  STATUS_REQUEST_RECALL,
  STATUS_REQUEST_DENY,
} from 'constants/TimeKeeping';
import { transferStatus, transferStatusToColor, transferStatusToBackgroundColor } from 'views/pages/TimeKeeping/Request/ultil.js';
import * as Constants from 'constants/index.js';
import { CONTROL_RADIO } from 'components/InputYupAndMui/constant';
import { useGetListRetireContract, useGetListDivisionContract, useRequestRetire } from 'hook/useContract';
import NavContract from '../Nav';
import styles from './style.module.scss';
import { handleExportExcel } from 'helpers';
import configs from 'config';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';
import { handleOnError } from 'helpers/index.js';

const Retire = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  let location = useLocation();
  const dataInforUser = useContext(ThemContext);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [requestRetire, setRequestRetire] = useState();
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, item) => {
    setRequestRetire(item);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const [filter, setFilter] = useState({
    date: location?.state?.date ? moment(location?.state?.date, 'DD/MM/YYYY').format('MM/YYYY') : null,
    division_id: 0,
    category_id: null,
    key_word: '',
    status: location?.state?.status ? location?.state?.status : null,
    page: DEFAULT_PAGE_MUI,
    per_page: DEFAULT_LIMIT,
  });
  const [dataDivision, setDataDivision] = useState([]);
  const [isShowModal, setShowModal] = useState(false);
  const [textModal] = useState(t('contract.contractRetire.detailRetire'));
  const [formData] = useState();

  const { data: uRequestRetire } = useRequestRetire(configs.ID_RETIRE_REQUEST);
  useEffect(() => {
    setFilter({ ...filter, category_id: uRequestRetire?.code });
    // eslint-disable-next-line
  }, [uRequestRetire]);

  const { data: uListRetireContract, isLoading: isLoadingListRetire } = useGetListRetireContract({
    ...filter,
    status: filter?.status < 0 ? null : filter?.status,
    page: filter?.page + 1,
  });
  const { data: uListDivision } = useGetListDivisionContract({
    ...filter,
    status: filter?.status < 0 ? null : filter?.status,
    page: filter?.page + 1,
  });

  useEffect(
    (dataDivision) => {
      if (uListDivision && !dataDivision && Array.isArray(uListDivision?.divisions)) {
        setDataDivision(uListDivision);
      }
    },
    [uListDivision],
  );

  const { control, setValue } = useForm({
    mode: 'onBlur',
  });

  const handleChangeDivision = (event) => {
    setFilter({ ...filter, division_id: event.target.value, page: DEFAULT_PAGE_MUI });
  };

  const leaderStatus = (leader_status) => {
    if (leader_status === ZERO_STATUS_ACCEPT) {
      return <div className={styles.colorWaiting}>{t('contract.contractRetire.waitingAccept')}</div>;
    } else if (leader_status === ONE_STATUS_ACCEPT) {
      return <div className={styles.colorDeny}>{t('contract.contractRetire.deny')}</div>;
    } else {
      return <div className={styles.colorAccept}>{t('contract.contractRetire.accept')}</div>;
    }
  };

  const STATUS_REQUEST = [
    {
      value: STATUS_REQUEST_OPEN,
      labelName: t('timekeeping.request.statusOpen'),
    },
    {
      value: STATUS_REQUEST_IN_PROGRESS,
      labelName: t('timekeeping.request.statusInProgress'),
    },
    {
      value: STATUS_REQUEST_COMPLETED,
      labelName: t('timekeeping.request.statusCompleted'),
    },
    {
      value: STATUS_REQUEST_DENY,
      labelName: t('timekeeping.request.statusDeny'),
    },
    {
      value: STATUS_REQUEST_REWORK,
      labelName: t('timekeeping.request.statusRework'),
    },
    {
      value: STATUS_REQUEST_RECALL,
      labelName: t('timekeeping.request.statusRecall'),
    }
  ];

  const handleSearch = (value) => {
    setFilter({ ...filter, key_word: value, page: DEFAULT_PAGE_MUI });
  };

  const handleMonth = (value) => {
    setFilter({ ...filter, date: moment(value).format('MM/YYYY'), page: DEFAULT_PAGE_MUI });
  };

  const handleAcceptHR = () => {
    setShowModal(false);
    confirmAlert({
      title: t('messages.retireContract.acceptHrFirst'),
      message: t('messages.retireContract.acceptHrSecond'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
        },
      ],
    });
  };

  const handleCancelHR = () => {
    setShowModal(false);
    confirmAlert({
      title: t('messages.retireContract.areYouSuretoCancelThisStatement'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
        },
      ],
    });
  };

  const handleReset = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setValue('division', ALL_STATUS_RETIRE);
    setValue('request', ALL_STATUS_RETIRE);
    setFilter({
      ...filter,
      date: null,
      category_id: uRequestRetire?.code,
      division_id: null,
      key_word: '',
      status: null,
      page: DEFAULT_PAGE_MUI,
      per_page: DEFAULT_LIMIT,
    });
    await queryClient.invalidateQueries(USE_LIST_RETIRE_CONTRACT);
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleModalEditRequestRetire = (event) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(null);
    }
    if (requestRetire?.type === NOT_DATA) {
      setMessage(t('contract.contractRetire.thisResignationRequestWasDeniedSorryYouCannotEdit'));
      setShowAlert(true);
      return;
    }
    setShowModalEdit(!isShowModalEdit);
  };

  const handleShowMessage = (value) => {
    if (value) {
      setMessage(value);
      setShowAlert(true);
    }
  };

  return (
    <>
      <div className={styles.content}>
        <Grid container>
          <Grid container className="nav-fixed">
            <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
              <Grid container>
                <NavContract />
              </Grid>
            </Grid>
            <Grid item xs sm md lg xl className={styles.button}>
              <ButtonComponent
                addClass={styles.export}
                icon={<BiLinkExternal />}
                text="Export"
                handleClick={() =>
                  handleExportExcel('/api/checkout-request/export', filter, 'list-contract-retire.xlsx')
                }
              />
            </Grid>
          </Grid>
          <Grid item md={9} className={styles.assetMain}>
            <div className={styles.profileBox}>
              <Paper>
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead className={styles.assetHeader}>
                      <TableRow className={styles.assetHeaderRow}>
                        <TableCell align="center">{t('contract.contractRetire.code')}</TableCell>
                        <TableCell align="center">{t('contract.contractRetire.name')}</TableCell>
                        <TableCell align="center" colSpan={2}>
                          {t('employees.email')}
                        </TableCell>
                        <TableCell align="center">{t('contract.contractRetire.division')}</TableCell>
                        <TableCell align="center">{t('contract.contractRetire.dayOff')}</TableCell>
                        <TableCell align="center">{t('contract.contractRetire.daySendRequest')}</TableCell>
                        <TableCell align="center">{t('common.status')}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoadingListRetire && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Loading />
                          </TableCell>
                        </TableRow>
                      )}
                      {uListRetireContract && uListRetireContract?.data?.data?.data?.length === NOT_DATA ? (
                        <TableRow>
                          <TableCell colSpan={9}>
                            <div className={styles.errorNoData}>{t('common.notData')}</div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        uListRetireContract?.data?.data &&
                        uListRetireContract?.data?.data?.data?.map((item, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              <TableCell>
                                <a
                                  href={`${configs.DOMAIN_WORK_FOLLOW}/request?request_id=${item?.request_id}`}
                                  target="blank"
                                  ref={(input) => {
                                    item.handelClick = input;
                                  }}
                                  className={styles.linkToWorkFl}
                                >
                                  {item?.code}
                                </a>
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                <div className={styles.linkRequest}>
                                  {item?.user_name ? item?.user_name : <>{t('infoEmployee.notUpdate')}</>}
                                </div>
                              </TableCell>
                              <TableCell
                                colSpan={2}
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                <div>{item?.email ? item?.email : <>{t('infoEmployee.notUpdate')}</>}</div>
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item?.division_name ? item?.division_name : <>{t('infoEmployee.notUpdate')}</>}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item?.checkout_date ? item?.checkout_date : <>{t('infoEmployee.notUpdate')}</>}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item?.date ? item?.date : <>{t('infoEmployee.notUpdate')}</>}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item?.status ? (
                                  <span
                                    className={classNames(
                                      transferStatusToColor(item?.status)
                                    )}
                                  >
                                    {transferStatus(item?.status)}
                                  </span>
                                ) : (
                                  t('common.notDataContract')
                                )}
                              </TableCell>
                              <TableCell>
                                {item?.status === STATUS_REQUEST_COMPLETED  && (
                                  <div className={styles.floatRight}>
                                    <IconButton
                                      className={`${
                                        dataInforUser?.hr_permission?.contracts_permission === ROLE_VIEW && DISABLE_ROLE
                                      }`}
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls="long-menu"
                                      aria-expanded={open ? 'true' : undefined}
                                      aria-haspopup="true"
                                      onClick={(event) =>
                                        dataInforUser?.hr_permission?.contracts_permission !== ROLE_VIEW &&
                                        handleClick(event, item)
                                      }
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {uListRetireContract?.data?.data?.total > NOT_DATA  && (
                  <div className="table-pagination">
                    <TablePagination
                      rowsPerPageOptions={PER_PAGE_OPTION}
                      component="div"
                      count={uListRetireContract?.data?.data?.total}
                      rowsPerPage={filter?.per_page}
                      page={filter?.page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                    />
                  </div>
                )}
              </Paper>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: '220px',
                    width: '20ch',
                  },
                }}
              >
                <MenuItem onClick={handleModalEditRequestRetire}>
                  <HiPencil />
                  {t('divisionDiagram.update')}
                </MenuItem>
              </Menu>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className={styles.boxFilter}>
              <div className={styles.paddingElementFilter}>
                <SearchComponent
                  txtPlaceholder={t('contract.contractRetire.search')}
                  addClass="input-search-box"
                  handleSearch={handleSearch}
                  maxLength={MAX_LENGTH_20}
                  defaultValue={filter.key_word}
                />
              </div>
              <div className={styles.paddingElementFilter}>
                <DateDayMonthComponent
                  label=""
                  valueDefault={typeof filter.date === 'string' ? moment(filter.date, 'MM/YYYY') : filter.date}
                  onChangeHandle={(value) => handleMonth(value)}
                  placeholder={t('contract.contractRetire.monthOut')}
                />
              </div>
              <div className={classNames(`${styles.paddingElementTop} ${styles.borderTop}`)}>
              <Controller
                render={({ field }) => (
                  <RadioGroup {...field} value={filter?.status ? filter?.status : NOT_DATA}>
                    <FormControlLabel
                      value={0}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          status: null,
                          page: Constants.DEFAULT_PAGE_MUI,
                        });
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }}
                      control={CONTROL_RADIO}
                      label={t('employees.all')}
                    />
                    {STATUS_REQUEST?.map((item, index) => (
                      <div key={index} className="division d-flex justify-content-between">
                        <FormControlLabel
                          key={index}
                          value={item?.value}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              status: e.target.value,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={CONTROL_RADIO}
                          label={item?.labelName}
                        />
                        <div className={classNames(`${styles.dotStatusAccept} ${transferStatusToBackgroundColor(item?.value)}`)}></div>
                      </div>
                      ))}
                      </RadioGroup>
                    )}
                    name="status"
                    control={control}
                    defaultValue={filter?.status ? filter?.status : STATUS_REQUEST_IN_PROGRESS}
                  />
                <FormControl component="fieldset">
                  <FormLabel
                    className={classNames(
                      `${styles.text} ${styles.marginElementBottom} ${styles.text} ${styles.borderTop} ${styles.paddingElementTop} title-body`,
                    )}
                  >
                    {t('contract.contractRetire.division')}
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value={ALL_STATUS_RETIRE}
                          onChange={(e) => {
                            setValue('division', e.target.value);
                            handleChangeDivision(e);
                          }}
                          control={
                            <Radio
                              sx={{
                                color: WHEAT,
                                '&.Mui-checked': {
                                  color: BG_POSITION[0],
                                },
                              }}
                            />
                          }
                          label={t('employees.all')}
                          className={styles.radioCustom}
                        />
                        {dataDivision?.divisions?.map((item, index) => (
                          <div key={index} className={styles.division}>
                            <FormControlLabel
                              key={index}
                              value={item?.division_id}
                              onChange={(e) => {
                                setValue('division', e.target.value);
                                handleChangeDivision(e);
                              }}
                              control={
                                <Radio
                                  sx={{
                                    color: WHEAT,
                                    '&.Mui-checked': {
                                      color: BG_POSITION[0],
                                    },
                                  }}
                                />
                              }
                              label={item?.division_name}
                              className={styles.radioCustom}
                            />

                            <span className={styles.total}>{item?.member ? item?.member : 0}</span>
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                    name="division"
                    control={control}
                    defaultValue={filter?.division_id}
                  />
                </FormControl>
              </div>
              <div className={styles.borderTop}>
                <ButtonBase
                  onClick={() => handleReset()}
                  className={classNames(`${styles.reset} ${styles.borderTop}`)}
                  size="lg"
                >
                  <img src={iconLoading} alt="Reset" width="20px" className={styles.imgReset} /> &emsp;
                  {t('common.reset')}
                </ButtonBase>
              </div>
            </div>
          </Grid>
        </Grid>
        <ModalComponent
          classNameAdd={styles.modal}
          toggle={() => setShowModal(!isShowModal)}
          isShowModal={isShowModal}
          title={textModal}
        >
          <div className={styles.infoDetail}>
            <div>
              {formData?.avatar !== '' ? (
                <div className={styles.avatar}>
                  <Avatar onError={handleOnError} alt={formData?.avatar} src={formData?.avatar} />
                </div>
              ) : (
                <div className={styles.avatar}>
                  <Avatar onError={handleOnError} alt={imgAvatar} src={imgAvatar} />
                </div>
              )}
            </div>
            <div className={styles.info}>
              <div className={styles.linkRequest}>{formData?.name}</div>
              <div className={styles.email}>
                {formData?.email} - {formData?.position}
              </div>
              <div className={classNames(`${styles.flex} ${styles.statusInfo}`)}>
                {t('contract.contractRetire.status')}: &nbsp;{leaderStatus(formData?.leader_status)}
              </div>
              {formData?.leader_status === TWO_STATUS_ACCEPT && formData?.hr_status === ONE_STATUS_ACCEPT ? (
                formData?.status_assets === ZERO_STATUS_ACCEPT ? (
                  <div className={classNames(`${styles.flex} ${styles.statusInfo}`)}>
                    {t('contract.contractRetire.status')}: &nbsp;
                    <div className={styles.colorWaiting}>{t('contract.contractRetire.notReceive')}</div>
                  </div>
                ) : (
                  <div className={classNames(`${styles.flex} ${styles.statusInfo}`)}>
                    {t('contract.contractRetire.status')}: &nbsp;
                    <div className={styles.colorAccept}>{t('contract.contractRetire.receive')}</div>
                  </div>
                )
              ) : (
                ''
              )}
            </div>
            {formData?.leader_status === TWO_STATUS_ACCEPT &&
              (formData?.hr_status === ZERO_STATUS_ACCEPT ? (
                <div className={styles.buttonAcceptHr}>
                  <ButtonComponent
                    addClass={styles.hrCancel}
                    text={t('contract.contractRetire.hrCancel')}
                    handleClick={handleCancelHR}
                  />
                  <ButtonComponent
                    addClass={styles.hrAccept}
                    text={t('contract.contractRetire.hrAccept')}
                    handleClick={handleAcceptHR}
                  />
                </div>
              ) : (
                <div className={styles.buttonAcceptHr}>
                  <ButtonComponent
                    addClass={styles.hrCancel}
                    text={t('contract.contractRetire.hrCancel')}
                    handleClick={handleCancelHR}
                  />
                </div>
              ))}
          </div>
          <div className={styles.infoProfile}>
            <Grid container>
              <Grid item md={6}>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.typePosition')}</div>
                  <div className={styles.infoProfileContent}>
                    {formData?.user_type ? formData?.user_type : <>{t('infoEmployee.notUpdate')}</>}
                  </div>
                </div>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.division')}</div>
                  <div className={styles.infoProfileContent}>
                    {formData?.division_name ? formData?.division_name : <>{t('infoEmployee.notUpdate')}</>}
                  </div>
                </div>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.position')}</div>
                  <div className={styles.infoProfileContent}>
                    {formData?.position ? formData?.position : <>{t('infoEmployee.notUpdate')}</>}
                  </div>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.accepter')}</div>
                  <div className={styles.infoProfileContent}>
                    {formData?.approved_user_name ? formData?.approved_user_name : <>{t('infoEmployee.notUpdate')}</>}
                  </div>
                </div>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.dayOff')}</div>
                  <div className={styles.infoProfileContent}>
                    {formData?.checkout_date ? formData?.checkout_date : <>{t('infoEmployee.notUpdate')}</>}
                  </div>
                </div>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.daySendRequest')}</div>
                  <div className={styles.infoProfileContent}>
                    {formData?.request_date ? formData?.request_date : <>{t('infoEmployee.notUpdate')}</>}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </ModalComponent>
        <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
          <p className={styles.textLeft}>{confirmAlert.title ? confirmAlert.title : ''}</p>
          <p className={styles.textLeft}>{confirmAlert.message ? confirmAlert.message : ''}</p>
        </AlertComponent>
      </div>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
      {isShowModalEdit && (
        <EditRequestRetireModal
          onSetShowModal={handleModalEditRequestRetire}
          isShowModalEdit={isShowModalEdit}
          onSetMessage={handleShowMessage}
          formData={requestRetire}
        />
      )}
    </>
  );
};

export default Retire;
