import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import styles from './style.module.scss';

import ButtonComponent from 'components/Buttons';
import SearchComponent from 'components/Search';
import ModalCommon from 'components/Modal';
import ResultFilter from './SearchComponent';
import ModalFilter from 'components/ModalCommon/ModalCommon';

import Employee from '../Employees/Dashboard/index';
import Timekeeping from '../TimeKeeping/Dashboard/index';
import Asset from '../Asset/Dashboard/index';
import Contract from '../Contracts/Dashboard/index';
import { MAX_LENGTH_20 } from 'constants/index.js';
import { ThemContext } from 'layouts/Admin';
import { ROLE_NOT_VIEW } from 'constants/index.js';
import config from 'config';

const STATUS_DISPLAY = {
  display: 0,
  not_display: 1,
};

const Home = () => {
  const { t } = useTranslation();
  const dataInforUser = useContext(ThemContext);
  const [filter, setFilter] = useState({
    key_word: '',
  });

  const [isShowModalCustom, setIsShowModalCustom] = useState(false);
  const [listModule, setListModule] = useState([]);
  const [listModuleTemp, setListModuleTemp] = useState([]);

  const [modules, setModules] = useState([]);

  useEffect(() => {
    if (dataInforUser && !dataInforUser?.hr_permission) {
      window.location.href = config.DOMAIN_APP_DOMAIN_AMS_BO + '/admin/page-not-access';
    }
  }, [dataInforUser]);

  useEffect(() => {
    setListModule([
      {
        id: 0,
        name: t('dashboard.all'),
        status: true,
        display: 1,
      },
      {
        id: 1,
        name: t('dashboard.employee'),
        status: true,
        display:
          dataInforUser?.hr_permission?.users_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
      },
      {
        id: 2,
        name: t('dashboard.timekeeping'),
        status: true,
        display:
          dataInforUser?.hr_permission?.time_sheet_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
      },
      {
        id: 3,
        name: t('dashboard.asset'),
        status: true,
        display:
          dataInforUser?.hr_permission?.assets_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
      },
      {
        id: 4,
        name: t('dashboard.contract'),
        status: true,
        display:
          dataInforUser?.hr_permission?.contracts_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
      },
    ]);
    setListModuleTemp([
      {
        id: 0,
        name: t('dashboard.all'),
        status: true,
        display: 1,
      },
      {
        id: 1,
        name: t('dashboard.employee'),
        status: true,
        display:
          dataInforUser?.hr_permission?.users_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
      },
      {
        id: 2,
        name: t('dashboard.timekeeping'),
        status: true,
        display:
          dataInforUser?.hr_permission?.time_sheet_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
      },
      {
        id: 3,
        name: t('dashboard.asset'),
        status: true,
        display:
          dataInforUser?.hr_permission?.assets_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
      },
      {
        id: 4,
        name: t('dashboard.contract'),
        status: true,
        display:
          dataInforUser?.hr_permission?.contracts_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
      },
    ]);
    setModules([
      {
        id: '1',
        display:
          dataInforUser?.hr_permission?.users_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
        component: (dragHandleProps) => (
          <div className={styles.moduleItem}>
            <div className={styles.moduleItemHeader} {...dragHandleProps}>
              <div className={styles.line} />
              <div className={styles.content}>{t('dashboard.employee')}</div>
            </div>

            {dataInforUser && dataInforUser?.hr_permission?.users_permission !== ROLE_NOT_VIEW && (
              <Employee isDashboard={true} />
            )}
          </div>
        ),
      },
      {
        id: '2',
        display:
          dataInforUser?.hr_permission?.time_sheet_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
        component: (dragHandleProps) => (
          <div className={styles.moduleItem}>
            <div className={styles.moduleItemHeader} {...dragHandleProps}>
              <div className={styles.line} />
              <div className={styles.content}>{t('dashboard.timekeeping')}</div>
            </div>
            {dataInforUser && dataInforUser?.hr_permission?.time_sheet_permission !== ROLE_NOT_VIEW && (
              <Timekeeping isDashboard={true} />
            )}
          </div>
        ),
      },
      {
        id: '3',
        display:
          dataInforUser?.hr_permission?.assets_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
        component: (dragHandleProps) => (
          <div className={styles.moduleItem}>
            <div className={styles.moduleItemHeader} {...dragHandleProps}>
              <div className={styles.line} />
              <div className={styles.content}>{t('dashboard.asset')}</div>
            </div>
            {dataInforUser && dataInforUser?.hr_permission?.assets_permission !== ROLE_NOT_VIEW && (
              <Asset isDashboard={true} />
            )}
          </div>
        ),
      },
      {
        id: '4',
        display:
          dataInforUser?.hr_permission?.contracts_permission === ROLE_NOT_VIEW
            ? STATUS_DISPLAY.display
            : STATUS_DISPLAY.not_display,
        component: (dragHandleProps) => (
          <div className={styles.moduleItem}>
            <div className={styles.moduleItemHeader} {...dragHandleProps}>
              <div className={styles.line} />
              <div className={styles.content}>{t('dashboard.contract')}</div>
            </div>
            {dataInforUser && dataInforUser?.hr_permission?.contracts_permission !== ROLE_NOT_VIEW && (
              <Contract isDashboard={true} />
            )}
          </div>
        ),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInforUser]);

  const onDragEnd = useCallback(
    ({ destination, source }) => {
      if (destination && destination.index >= 0) {
        const cloneModules = [...modules];

        const spliceModule = cloneModules.splice(source.index, 1);

        cloneModules.splice(destination.index, 0, spliceModule[0]);

        setModules(cloneModules);
      }
    },
    [modules],
  );

  const changeSelectListModule = useCallback(
    (item) => {
      if (!item.id && !item.status) {
        const cloneListModule = [...listModuleTemp].map((el) => {
          return { ...el, status: true };
        });

        return setListModuleTemp(cloneListModule.filter((item) => item.display > STATUS_DISPLAY.display));
      }

      if (!item.id && item.status) {
        const cloneListModule = [...listModuleTemp].map((el) => {
          return { ...el, status: false };
        });

        return setListModuleTemp(cloneListModule.filter((item) => item.display > STATUS_DISPLAY.display));
      }

      if (item.id) {
        let cloneListModule = [...listModuleTemp].map((el) => {
          return el.id === item.id ? { ...el, status: !el.status } : el;
        });
        cloneListModule[0].status = false;

        if (![...cloneListModule].slice(1, cloneListModule.length).find((el) => !el.status)) {
          cloneListModule = cloneListModule.map((el) => ({ ...el, status: true }));
        }

        return setListModuleTemp(cloneListModule.filter((item) => item.display > STATUS_DISPLAY.display));
      }
    },
    [listModuleTemp],
  );

  const onSubmitListModule = useCallback(() => {
    setListModule(listModuleTemp);

    const modulesTerm = [];
    listModuleTemp.slice(1, listModuleTemp.length).forEach((el) => {
      const module = modules.find((module) => +module.id === el.id);
      modulesTerm.push(module);
    });

    setModules(modulesTerm.filter((item) => item.display > STATUS_DISPLAY.display));
    setIsShowModalCustom(false);
  }, [listModuleTemp, modules]);

  const handleSearch = (value) => {
    setFilter({
      key_word: value,
    });
  };

  const onDragEndCustom = useCallback(
    ({ destination, source }) => {
      if (destination && destination.index >= 0) {
        const cloneModules = [...listModuleTemp];

        const spliceModule = cloneModules.splice(source.index + 1, 1);

        cloneModules.splice(destination.index + 1, 0, spliceModule[0]);

        setListModuleTemp(cloneModules.filter((item) => item.display > STATUS_DISPLAY.display));
      }
    },
    [listModuleTemp],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    return;
  };

  useEffect(() => {
    window.addEventListener('unload', handleScroll);

    return () => window.removeEventListener('beforeunload', handleScroll);
  }, [handleScroll]);

  return (
    <>
      <div className="nav-fixed">
        <div className={styles.search}>
          <SearchComponent
            txtPlaceholder={t('dashboard.search')}
            addClass="input-search-box mr-2"
            handleSearch={handleSearch}
            defaultValue={filter?.key_word}
            isEnter={true}
            maxLength={MAX_LENGTH_20}
            dataInforUser={dataInforUser}
          />
          <ButtonComponent text={t('dashboard.custom')} handleClick={() => setIsShowModalCustom(true)} />
        </div>
      </div>

      {!listModule?.find((el) => el.status) && <div className="error-no-data">{t('common.notData')}</div>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-1">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {modules.map((module, index) => {
                if (module.display > STATUS_DISPLAY.display) {
                  return (
                    <Draggable draggableId={module.id} index={index} key={module.id}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={classNames(styles.dragable, {
                            [styles.visiable]:
                              listModule[0]?.status || listModule.find((el) => el.id === +module.id)?.status,
                          })}
                        >
                          {module.component(provided.dragHandleProps)}
                        </div>
                      )}
                    </Draggable>
                  );
                } else {
                  return '';
                }
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!!filter?.key_word && (
        <ModalCommon
          isShowModal={!!filter?.key_word}
          toggle={() => setFilter({ key_word: '' })}
          title={t('dashboard.search')}
          classNameAdd="modalSearchDashboard"
        >
          <ResultFilter dataInforUser={dataInforUser} key_word={filter?.key_word} />
        </ModalCommon>
      )}
      <ModalFilter
        items={listModuleTemp.filter((item) => item.display > STATUS_DISPLAY.display)}
        onSubmit={onSubmitListModule}
        onClick={changeSelectListModule}
        toggle={() => setIsShowModalCustom(!isShowModalCustom)}
        modal={isShowModalCustom}
        title={t('dashboard.customDashboard')}
        footer={t('dashboard.select')}
        isDrag={true}
        onDragEnd={onDragEndCustom}
      />
    </>
  );
};

export default Home;
