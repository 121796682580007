import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import AlertComponent from 'components/Alert';
import DivisionBox from './DivisionBox';
import Loading from 'components/Loading';
import ModalBranch from './ModalBranch';
import ModalTeam from './ModalTeam';
import ModalDivision from './ModalDivision';
import Nav from './Nav';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_EXPECTATION_FAILED, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { isNotNullObject } from 'helpers';
import { useDiagramDepartment, useDetailTeam } from './../../../hook/useDepartment';
import { apiCreateBranch, apiUpdateBranch, apiDeleteBranch, apiDeleteTeam, apiDeleteDivision } from 'api/department';
import { MAX_LENGTH_50 } from 'constants/ProfileEmployee.js';
import { USE_DIAGRAM_DEPARTMENT } from 'constants/Department';
import { removeSpecial } from 'helpers';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';

const DEFAULT_FORM_DATA_TEAM = {
  id: 0,
  name: '',
  founding_at: null,
  description: '',
  parent_id: 0,
  error_name: '',
  error_founding: '',
  error_des: '',
  error_parent: '',
};

const DEFAULT_FORM_DATA_BRANCH = {
  id: 0,
  name: '',
  address: '',
  error_name: '',
  error_address: '',
};

const DEFAULT_ERRORS_DATA_DIVISION = {
  error_name: '',
  error_founding: '',
  error_des: '',
  error_parent: '',
  error_manager: '',
};

const DEFAULT_FORM_DATA_DIVISION = {
  id: 0,
  file: null,
  avatar: null,
  name: '',
  founding_at: null,
  description: '',
  parent_id: 0,
  ...DEFAULT_ERRORS_DATA_DIVISION,
};

const DepartmentDiagram = (props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const { data: udata, isLoading: isLoadingData } = useDiagramDepartment();
  const [isEmptyDivision, setEmptyDivision] = useState(false);
  const [isEmptyTeam, setEmptyTeam] = useState(false);
  const [dataOrignal, setDataOrignal] = useState([]);
  const [isShowAlert, setShowAlert] = useState(false);
  const [stateButton, setStateButton] = useState(false);
  const [message, setMessage] = useState('');

  const [bodExist, setBodExist] = useState({
    is_bod: false,
    division_id: 0,
  });
  const [isShowModalDivision, setShowModalDivision] = useState(false);
  const [isShowModalBranch, setShowModalBranch] = useState(false);
  const [isShowModalTeam, setShowModalTeam] = useState(false);
  const [textModal, setTextModal] = useState({
    title: '',
    btn_submit: '',
  });

  const [infoBranch, setInfoBranch] = useState({});
  const [infoDivision, setInfoDivision] = useState({});
  const [infoDivisionChange, setInfoDivisionChange] = useState({});
  const [infoTeam, setInForTeam] = useState({});

  useEffect(() => {
    const department = udata?.data;
    department &&
      department.forEach((branch, indexBranch) => {
        branch.isActive = indexBranch === 0 ? true : false;
        branch.isActiveVertical = false;

        //check empty division to show button add more
        if (branch.isActive && branch.divisions?.filter((item) => item.total_member > 0).length === 0) {
          setEmptyDivision(true);
        }

        branch.divisions?.filter((item) => item.total_member > 0) &&
          branch.divisions
            ?.filter((item) => item.total_member > 0)
            .forEach((division, indexDivision) => {
              division.isActive = indexDivision === 0 && indexBranch === 0 ? true : false;
              division.isActiveVertical = false;

              if (division?.isActive && division?.team?.length === 0) {
                setEmptyTeam(true);
              }
            });
      });
    udata && setDataOrignal(department);

    if (udata && udata?.data.length && udata.data[0]?.divisions.length) {
      setInfoDivision(udata.data[0].divisions[0]);
      let _checkBOD = {};

      udata?.data?.forEach((branch) => {
        if (_checkBOD?.is_bod && _checkBOD?.division_id) {
          return false;
        }
        branch?.divisions?.forEach((item) => {
          if (item.level && !_checkBOD?.is_bod && !_checkBOD?.division_id) {
            _checkBOD = {
              is_bod: true,
              division_id: item.division_id,
            };
            return;
          }
        });
      });
      setBodExist({ ..._checkBOD });
    }
    if (udata && udata?.data.length) {
      setInfoBranch(udata.data[0]);
    }
  }, [udata]);

  const showDivision = function (key) {
    setInForTeam({});
    dataOrignal.forEach((branch, index) => {
      branch.isActiveVertical = index < key ? true : false;
      branch.isActive = index === key ? true : false;

      if (branch.isActive) {
        setInfoBranch(branch);
        if (branch?.divisions) {
          setInfoDivision(branch.divisions?.filter((item) => item.total_member > 0)[0]);
        } else {
          setInfoDivision({});
        }
      }

      //check empty division to show button add more
      if (branch?.isActive && branch?.divisions?.length === 0) {
        setEmptyDivision(true);
      }
      if (branch?.isActive && branch?.divisions?.length !== 0) {
        setEmptyDivision(false);
      }

      branch.divisions
        ?.filter((item) => item.total_member > 0)
        .forEach((division) => {
          division.isActiveVertical = false;
          division.isActive = false;
        });

      var divisionTerm = branch.divisions?.filter((item) => item.total_member > 0);
      // first box always active
      if (branch?.isActive && branch?.divisions?.length !== 0) {
        divisionTerm[0].isActive = true;
        //check empty team
        divisionTerm.forEach((division) => {
          if (division.isActive && division.teams.length === 0) {
            setEmptyTeam(true);
          }
          if (division.isActive && division.teams.length !== 0) {
            setEmptyTeam(false);
          }
        });
      }
    });
    // setChoosenBranch(dataOrignal[key]);
    setDataOrignal([...dataOrignal]);
  };

  const showTeam = function (key) {
    setInForTeam({});
    dataOrignal.forEach((branch) => {
      if (branch.isActive) {
        branch.divisions
          ?.filter((item) => item.total_member > 0)
          .forEach((division, index) => {
            division.isActiveVertical = index < key ? true : false;
            division.isActive = index === key ? true : false;

            if (division.isActive) {
              setInfoDivision(division);
            }

            if (division.isActive && division.teams.length === 0) {
              setEmptyTeam(true);
            }
            if (division.isActive && division.teams.length !== 0) {
              setEmptyTeam(false);
            }
          });
      }
    });
    setDataOrignal([...dataOrignal]);
  };

  // branch
  const [formAddBranch, setFormAddBranch] = useState(DEFAULT_FORM_DATA_BRANCH);

  const addBranch = () => {
    if (dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW) {
      return;
    }
    setShowModalBranch(true);
    setFormAddBranch({
      ...formAddBranch,
      ...DEFAULT_FORM_DATA_BRANCH,
    });
    setTextModal({
      title: t('divisionDiagram.addBranchTitle'),
      btn_submit: t('divisionDiagram.addBranch'),
    });
  };

  function openEditBranch(branch) {
    setShowModalBranch(true);
    setTextModal({
      title: t('divisionDiagram.updateBranchTitle'),
      btn_submit: t('divisionDiagram.updateBranch'),
    });
    setFormAddBranch({
      ...formAddBranch,
      id: branch.branch_id,
      name: branch.branch_name,
      address: branch?.address,
      error_name: '',
      error_address: '',
    });
  }

  const alertConfirmBranch = (id) => {
    confirmAlert({
      title: t('infoEmployee.youWantToDelete'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteBranch(infoBranch?.branch_id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              showDivision(0);
              queryClient.invalidateQueries(USE_DIAGRAM_DEPARTMENT);
            }

            if (response.data.status === HTTP_EXPECTATION_FAILED) {
              setMessage(response.data.messages);
              setShowAlert(true);
            }
          },
        },
      ],
    });
  };

  const handleInputBranch = (e) => {
    const error = {};

    if (e.target.name === 'name') {
      e.target.value = removeSpecial(e.target.value);
      error.error_name = '';
    }
    if (e.target.name === 'address') {
      error.error_address = '';
    }

    setFormAddBranch({ ...formAddBranch, [e.target.name]: e.target.value, ...error });
  };

  function validateBranch() {
    var messages = {};
    if (!formAddBranch.name.trim()) {
      messages.error_name = t('common.pleaseFillOutThisField');
    }
    if (formAddBranch.name.length > MAX_LENGTH_50) {
      messages.error_name = t('common.pleaseEnterLessThanFiftyCharacters');
    }
    if (!formAddBranch.address.trim()) {
      messages.error_address = t('common.pleaseFillOutThisField');
    }

    if (isNotNullObject(messages)) {
      setFormAddBranch({
        ...formAddBranch,
        ...messages,
      });
      return false;
    }
    return true;
  }

  const handleSubmitBranch = async (event) => {
    setFormAddBranch({ ...formAddBranch, error_name: '' });
    setStateButton(true);
    event.preventDefault();

    if (!validateBranch()) {
      setStateButton(false);
      return;
    }

    setFormAddBranch({ ...formAddBranch, error_name: '' });

    const data = {
      name: formAddBranch.name.trim(),
      address: formAddBranch.address.trim(),
    };

    let response = {};
    if (!formAddBranch.id) {
      response = await apiCreateBranch(data);
    } else {
      response = await apiUpdateBranch(formAddBranch.id, data);
    }

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setFormAddBranch({
        ...formAddBranch,
        error_name: msg?.name
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModalBranch(false);
      setStateButton(false);
      setFormAddBranch({
        ...formAddBranch,
        ...DEFAULT_FORM_DATA_BRANCH,
      });
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_DIAGRAM_DEPARTMENT);
    }
    setTextModal({
      ...textModal,
      title: t('divisionDiagram.addBranchTitle'),
      btn_submit: t('divisionDiagram.addBranch'),
    });
    setStateButton(false);
  };
  //end branch

  // division
  const addDivision = () => {
    if (dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW) {
      return;
    }
    setInfoDivisionChange(DEFAULT_FORM_DATA_DIVISION);
    setShowModalDivision(!isShowModalDivision);
    return true;
  };

  const handleEditDivision = (division) => {
    setInfoDivisionChange(division);
    setShowModalDivision(true);
  };

  // Delete Division Done
  const alertDeleteDone = (message) => {
    confirmAlert({
      title: message,
      buttons: [
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            await queryClient.invalidateQueries(USE_DIAGRAM_DEPARTMENT);
          },
        },
      ],
    });
  };
  // Confirm Delete Division
  const alertConfirmDivision = (id) => {
    confirmAlert({
      title: t('common.doYouWantToDeletedData?'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteDivision(id);
            if (response.data.status === HTTP_OK) {
              alertDeleteDone(response.data.messages);
            }
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_EXPECTATION_FAILED) {
              alertDeleteDone(t('divisionDiagram.divisionPopup.alertDel'));
            }
          },
        },
      ],
    });
  };
  // end division

  // team
  const [filterTeam, setFilterTeam] = useState({ team_id: '' });
  const { data: detailTeam, isLoading: isLoadingDetailTeam } = useDetailTeam(filterTeam);

  const createDataModalTeam = (detailTeam, team) => {
    if ((detailTeam && !team) || (detailTeam && team && team.team_id === detailTeam.id)) {
      setInForTeam(detailTeam);
    }
  };

  useEffect(() => {
    createDataModalTeam(detailTeam);
  }, [detailTeam]);

  const addTeam = () => {
    if (dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW) {
      return;
    }
    setShowModalTeam(true);
    setInForTeam(DEFAULT_FORM_DATA_TEAM);
  };

  function openEditTeam(team) {
    setFilterTeam({ ...filterTeam, team_id: team.team_id });
    setShowModalTeam(true);
    createDataModalTeam(detailTeam, team);
  }

  const alertConfirmTeam = (id) => {
    confirmAlert({
      title: t('infoEmployee.youWantToDelete'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteTeam(id);
            if (response.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_DIAGRAM_DEPARTMENT);
            }
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_EXPECTATION_FAILED) {
              setMessage(response.data.messages);
              setShowAlert(true);
            }
          },
        },
      ],
    });
  };

  const handleToggleModalTeam = () => {
    setShowModalTeam(!isShowModalTeam);
  };

  const addMoreBranch = {
    branch_name: t('divisionDiagram.addMoreBranch'),
    total_member: t('divisionDiagram.addIcon'),
  };

  const addMoreDivision = {
    division_name: t('divisionDiagram.addMoreDvision'),
    total_member: t('divisionDiagram.addIcon'),
  };

  const addMoreTeam = {
    team_name: t('divisionDiagram.addMoreTeam'),
    total_member: t('divisionDiagram.addIcon'),
  };

  const amelaVN = {
    isActive: true,
    branch_name: t('divisionDiagram.companyName'),
    total_member: udata?.total_member,
  };

  return (
    <div className="department">
      <div className="nav-fixed">
        <Nav />
      </div>

      {isLoadingData ? (
        <Loading />
      ) : (
        <>
          <Row className="diagram">
            <Col md="3" className="remove-pd-mg">
              <ul className="remove-pd-mg">
                <li>
                  <hr className="line-grey-right line-orange" />
                  <DivisionBox
                    divisionBox={amelaVN}
                    textcolor="color-active"
                    background="bg-active"
                    colorBorder="border-orange"
                    colorCircle="border-orange cirle-orange"
                    bgCircle="bg-orange"
                    disableDropdown={dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW ? true : false}
                  ></DivisionBox>
                </li>
              </ul>
            </Col>

            {/* branch  */}
            <Col md="3" className="remove-pd-mg">
              <ul className="remove-pd-mg">
                <div className="number-branch">{dataOrignal?.length}</div>
                {dataOrignal &&
                  dataOrignal.map((branch, index) => {
                    return (
                      <li key={index}>
                        <div
                          className={classNames(`vertical-line
                     ${branch.isActiveVertical ? 'line-vertical-orange' : ''}`)}
                        ></div>
                        <hr className={classNames(`line-grey-left ${branch.isActive ? 'line-orange' : ''}`)} />
                        {branch.isActive && (
                          <hr
                            className={classNames(
                              `line-grey-right ${branch.isActive && !isEmptyDivision ? 'line-orange' : ''}`,
                            )}
                          />
                        )}
                        <DivisionBox
                          handleOnclick={() => showDivision(index)}
                          handleUpdate={() => openEditBranch(branch)}
                          handleDelete={() => alertConfirmBranch(branch.id)}
                          index={index}
                          divisionBox={branch}
                          setting={true}
                          colorBorder={branch.isActive ? 'border-green' : 'border-grey'}
                          textcolor={branch.isActive ? 'color-active' : 'color-inactive'}
                          background={branch.isActive ? 'bg-active' : 'bg-inactive'}
                          colorCircle={branch.isActive ? 'border-green cirle-green' : 'border-grey cirle-grey'}
                          bgCircle={branch.isActive ? 'bg-green' : 'bg-grey'}
                          bgBorder={branch.isActive ? 'bg-green' : 'bg-grey'}
                          backgroundCode={branch.isActive ? 'bg-code-green' : 'bg-code-grey'}
                          disableDropdown={
                            dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW ? true : false
                          }
                        />
                      </li>
                    );
                  })}
                <li>
                  <hr
                    className={`line-grey-left ${
                      dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW && DISABLE_ROLE
                    }`}
                  />
                  <DivisionBox
                    handleOnclick={() => addBranch()}
                    divisionBox={addMoreBranch}
                    styleBorder="border-dashed"
                    colorBorder="border-grey"
                    textcolor="color-inactive"
                    background="bg-more"
                    colorCircle="border-grey cirle-more"
                    bgCircle="bg-grey"
                    disable={dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW ? true : false}
                  ></DivisionBox>
                </li>
              </ul>
            </Col>

            {/* division  */}
            <Col md="3" className="remove-pd-mg">
              <ul className="remove-pd-mg">
                {dataOrignal &&
                  dataOrignal.map((branch, i) => {
                    var checkIndex = branch.isActive ? i : 0;
                    return (
                      <React.Fragment key={i}>
                        {/* create index start for division col */}
                        {Array(checkIndex)
                          .fill(0)
                          .map((_, ti) => {
                            return <li key={ti}></li>;
                          })}
                        {/* map division box  */}
                        {branch.divisions
                          ?.filter((item) => item.total_member > 0)
                          ?.map((division, index) => {
                            return (
                              <div key={index}>
                                <li className={branch.isActive ? '' : 'none-division'}>
                                  {index === 0 && <div className="number-branch">{branch.sub_branch}</div>}
                                  <div
                                    className={classNames(
                                      `vertical-line
                                    ${division.isActiveVertical ? 'line-vertical-orange' : ''}`,
                                    )}
                                  ></div>
                                  <hr
                                    className={classNames(
                                      `line-grey-left
                                    ${branch.isActive ? '' : 'none-division'}
                                    ${division.isActive ? 'line-orange' : ''}`,
                                    )}
                                  />
                                  {division.isActive && (
                                    <hr
                                      className={classNames(
                                        `line-grey-right ${division.isActive && !isEmptyTeam ? 'line-orange' : ''}`,
                                      )}
                                    />
                                  )}
                                  <DivisionBox
                                    handleDelete={() => alertConfirmDivision(division.division_id)}
                                    handleUpdate={() => handleEditDivision(division)}
                                    handleOnclick={() => showTeam(index)}
                                    divisionBox={division}
                                    background={division.isActive ? 'bg-active' : 'bg-inactive'}
                                    textcolor={division.isActive ? 'color-active' : 'color-inactive'}
                                    setting={true}
                                    index={index}
                                    canShow={true}
                                    idShow={division.division_id}
                                    tooltip={division.isActive}
                                    name="divisiontooltip"
                                    colorBorder={division.isActive ? 'border-pink' : 'border-grey'}
                                    colorCircle={
                                      division.isActive ? 'border-pink cirle-pink' : 'border-grey cirle-grey'
                                    }
                                    bgCircle={division.isActive ? 'bg-pink' : 'bg-grey'}
                                    backgroundCode={division.isActive ? 'bg-code-pink' : 'bg-code-grey'}
                                    disableDropdown={
                                      dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW ? true : false
                                    }
                                  />
                                </li>
                              </div>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
                <li>
                  <hr
                    className={`line-grey-left ${
                      dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW && DISABLE_ROLE
                    }`}
                  />
                  <DivisionBox
                    handleOnclick={() => addDivision()}
                    divisionBox={addMoreDivision}
                    styleBorder="border-dashed"
                    colorBorder="border-grey"
                    textcolor="color-inactive"
                    background="bg-more"
                    colorCircle="border-grey cirle-more"
                    bgCircle="bg-grey"
                    disable={dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW ? true : false}
                  ></DivisionBox>
                </li>
              </ul>
            </Col>

            {/* team */}
            <Col md="3" className="remove-pd-mg">
              <ul className="remove-pd-mg">
                {dataOrignal &&
                  dataOrignal.map((branch, i) => {
                    var checkIndexBranch = branch.isActive ? i : 0;
                    //check display addmoreTeam box
                    return (
                      branch.divisions?.filter((item) => item.total_member > 0).length !== 0 &&
                      branch.divisions
                        ?.filter((item) => item.total_member > 0)
                        .map((division, index) => {
                          var checkIndexDivision = division.isActive ? index : 0;
                          return (
                            <React.Fragment key={index}>
                              {/* create index start for team col  */}
                              {division.isActive &&
                                Array(checkIndexBranch + checkIndexDivision)
                                  .fill(0)
                                  .map((_, ti) => {
                                    return <li key={ti}></li>;
                                  })}
                              {division.teams &&
                                division.teams.map((team, ii) => {
                                  return (
                                    <React.Fragment key={ii}>
                                      {/* create team box  */}
                                      <li key={ii} className={division.isActive ? '' : 'none-division'}>
                                        {ii === 0 && <div className="number-branch">{division.sub_branch}</div>}
                                        <div className="vertical-line line-vertical-orange"></div>
                                        <hr
                                          className={classNames(
                                            `line-grey-left ${division.isActive ? 'line-orange' : 'none-division'}`,
                                          )}
                                        />
                                        {
                                          <DivisionBox
                                            handleUpdate={() => openEditTeam(team)}
                                            handleDelete={() => alertConfirmTeam(team.team_id)}
                                            background={division.isActive ? 'bg-active' : 'bg-inactive'}
                                            textcolor={division.isActive ? 'color-active' : 'color-inactive'}
                                            divisionBox={team}
                                            canShow={true}
                                            idShow={division.division_id}
                                            tooltip={division.isActive}
                                            name="teamtooltip"
                                            boxRadius="team-radius"
                                            index={ii}
                                            setting={true}
                                            colorBorder="border-blue"
                                            colorCircle="border-blue cirle-blue"
                                            bgCircle="bg-blue"
                                            disableDropdown={
                                              dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW
                                                ? true
                                                : false
                                            }
                                          />
                                        }
                                      </li>
                                    </React.Fragment>
                                  );
                                })}
                            </React.Fragment>
                          );
                        })
                    );
                  })}
                <li className={classNames(`${isEmptyDivision && 'none-division'}`)}>
                  <hr
                    className={`line-grey-left ${
                      dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW && DISABLE_ROLE
                    }`}
                  />
                  <DivisionBox
                    handleOnclick={() => addTeam()}
                    divisionBox={addMoreTeam}
                    styleBorder="border-dashed"
                    colorBorder="border-grey"
                    textcolor="color-inactive"
                    background="bg-more"
                    colorCircle="border-grey cirle-more"
                    bgCircle="bg-grey"
                    disable={dataInforUser?.hr_permission?.divisions_permission === ROLE_VIEW ? true : false}
                  ></DivisionBox>
                </li>
              </ul>
            </Col>
          </Row>
        </>
      )}
      <ModalBranch
        onSubmit={handleSubmitBranch}
        handleInput={handleInputBranch}
        stateButton={stateButton}
        isShowModal={isShowModalBranch}
        textModal={textModal}
        formAddBranch={formAddBranch}
        toggle={() => setShowModalBranch(!isShowModalBranch)}
        toggleSubmit={() => setShowModalBranch(true)}
      />

      {isLoadingData ? (
        ''
      ) : (
        <ModalDivision
          isShowModal={isShowModalDivision}
          toggle={() => setShowModalDivision(!isShowModalDivision)}
          infoDivision={infoDivisionChange}
          infoBranch={infoBranch}
          bodExist={bodExist}
        />
      )}

      {isLoadingData ? (
        ''
      ) : (
        <ModalTeam
          isShowModal={isShowModalTeam}
          toggle={handleToggleModalTeam}
          isLoadingDetailTeam={isLoadingDetailTeam}
          infoTeam={infoTeam}
          infoDivision={infoDivision}
          infoBranch={infoBranch}
        />
      )}

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default DepartmentDiagram;
