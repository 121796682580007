import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import { Grid } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import 'react-confirm-alert/src/react-confirm-alert.css';

import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import { useDetailAssets } from 'hook/useAssets';
import { ACTION_NOT_VERIFY } from 'constants/Assets';
import { ACTION_HAND_OVER } from 'constants/Assets';
import { ACTION_RECALL } from 'constants/Assets';
import { ACTION_TRANSFER } from 'constants/Assets';
import { ACTION_REMOTE } from 'constants/Assets';
import { ACTION_IMPORT, ACTION_APPROVE, ACTION_CANCEL, ACTION_CHANGE_STATUS } from 'constants/Assets';
import Loading from 'components/Loading';
import { useHistoryAssets } from 'hook/useAssets';
import { FormatNumber } from 'helpers/format';
import { LIST_TYPE_GOOD } from 'constants/Assets';
import { LIST_TYPE_UNDER_WARRANTY } from 'constants/Assets';
import { LIST_TYPE_WAIT_FOR_LIQUIDATION } from 'constants/Assets';
import { LIST_TYPE_LIQUIDATION } from 'constants/Assets';
import { LIST_TYPE_BROKEN } from 'constants/Assets';
import {
  LIST_TYPE_MISSING,
  LIST_TYPE_WAIT_FOR_APPROVE,
  CANCEL,
  ACTION_CREATE,
  STATUS_ASSETS_WAITING_APPROVE,
  STATUS_ASSETS_CANCEL,
  STATUS_ASSETS_GOOD,
} from 'constants/Assets';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_BUTTON_ROLE, APPROVE } from 'constants/index.js';
import styles from './styles.module.scss';
import { apiReCallAssets } from 'api/assets.js';
import { HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import { USE_LIST_ASSETS } from 'constants/Assets';
import Tooltip from '@mui/material/Tooltip';

function ModalDetailAssets({
  isShowModal,
  setShowModal,
  setIsShowModalEditAssets,
  selectedValue,
  displayEdit,
  setShowAlert,
  setMessage,
  approveAssets,
  deleteAssets,
}) {
  const { t } = useTranslation();
  const dataInforUser = useContext(ThemContext);
  const queryClient = useQueryClient();

  // Convert type and action
  const convertAction = (type) => {
    if (type === ACTION_NOT_VERIFY) {
      return t('assets.notVerify');
    }
    if (type === ACTION_HAND_OVER) {
      return t('assets.handover');
    }
    if (type === ACTION_RECALL) {
      return t('assets.recall');
    }
    if (type === ACTION_TRANSFER) {
      return t('assets.transfer');
    }
    if (type === ACTION_REMOTE) {
      return t('assets.assetsActivity.editSTT');
    }
    if (type === ACTION_IMPORT) {
      return t('assets.import');
    }
    if (type === ACTION_CREATE) {
      return t('assets.create');
    }
    if (type === ACTION_APPROVE) {
      return t('assets.approve');
    }
    if (type === ACTION_CANCEL) {
      return t('assets.cancel');
    }
  };
  const convertType = (type) => {
    if (type === LIST_TYPE_GOOD) {
      return t('assets.good');
    }
    if (type === LIST_TYPE_UNDER_WARRANTY) {
      return t('assets.underWarranty');
    }
    if (type === LIST_TYPE_WAIT_FOR_LIQUIDATION) {
      return t('assets.waitForLiquidation');
    }
    if (type === LIST_TYPE_LIQUIDATION) {
      return t('assets.liquidation');
    }
    if (type === LIST_TYPE_BROKEN) {
      return t('assets.broken');
    }
    if (type === LIST_TYPE_MISSING) {
      return t('assets.missing');
    }
    if (type === LIST_TYPE_WAIT_FOR_APPROVE) {
      return t('assets.waitForApprove');
    }
    if (type === CANCEL) {
      return t('assets.cancel');
    }
  };

  // Data
  const { data: uDetailHistoryAssets, isLoading: isLoadingDetailHistoryAssets } = useHistoryAssets(
    selectedValue?.id || selectedValue?.asset_id,
  );
  const { data: uDetailAssets, isLoading: isLoadingDetailAssets } = useDetailAssets(
    selectedValue?.id || selectedValue?.asset_id,
  );

  const recallAssets = () => {
    confirmAlert({
      title: t('assets.areYouSureYouWantToRecoverThisProperty'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiReCallAssets({
              user_id: selectedValue?.user_id,
              asset_id: selectedValue?.id,
            });
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
              return;
            }
            if (response?.data.status === 200 || response?.data.status === 417) {
              setShowModal(!isShowModal);
              setMessage(response.data.messages);
              setShowAlert(true);
              await queryClient.invalidateQueries(USE_LIST_ASSETS);
            }
          },
        },
      ],
    });
  };

  const renderButton = () => {
    if (displayEdit && selectedValue.type === LIST_TYPE_WAIT_FOR_APPROVE) {
      if (dataInforUser?.hr_permission?.assets_permission === APPROVE) {
        return (
          <>
            <div>
              <ButtonComponent
                addClass={`${
                  dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                } create ${styles.customButtonEdit}`}
                text={t('assets.edit')}
                handleClick={() =>
                  dataInforUser?.hr_permission?.assets_permission !== ROLE_VIEW && setIsShowModalEditAssets(true)
                }
              />
              <ButtonComponent
                addClass={`${
                  dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                } create ${styles.customButtonEdit}`}
                text={t('masterData.roleMasterData.approval')}
                handleClick={() => {
                  approveAssets();
                }}
              />
            </div>
          </>
        );
      } else {
        return (
          <>
            <div>
              <ButtonComponent
                addClass={`${
                  dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                } create ${styles.customButtonEdit}`}
                text={t('assets.edit')}
                handleClick={() =>
                  dataInforUser?.hr_permission?.assets_permission !== ROLE_VIEW && setIsShowModalEditAssets(true)
                }
              />
            </div>
          </>
        );
      }
    } else {
      if (selectedValue.type !== CANCEL) {
        if (!selectedValue?.user_id) {
          return (
            <>
              <div>
                <ButtonComponent
                  addClass={`${
                    dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                  } create ${styles.customButtonEdit}`}
                  text={t('assets.edit')}
                  handleClick={() =>
                    dataInforUser?.hr_permission?.assets_permission !== ROLE_VIEW && setIsShowModalEditAssets(true)
                  }
                />
              </div>
            </>
          );
        } else {
          return (
            <>
              <div>
                <ButtonComponent
                  addClass={`${
                    dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                  } create ${styles.customButtonEdit}`}
                  text={t('assets.edit')}
                  handleClick={() =>
                    dataInforUser?.hr_permission?.assets_permission !== ROLE_VIEW && setIsShowModalEditAssets(true)
                  }
                />
                <ButtonComponent
                  addClass={`${
                    dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                  } create ${styles.customButtonEdit}`}
                  text={t('assets.recall')}
                  handleClick={() => dataInforUser?.hr_permission?.assets_permission !== ROLE_VIEW && recallAssets()}
                />
              </div>
            </>
          );
        }
      } else {
        return (
          <>
            <div>
              <ButtonComponent
                addClass={`${
                  dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                } create ${styles.customButtonEdit}`}
                text={t('assets.edit')}
                handleClick={() =>
                  dataInforUser?.hr_permission?.assets_permission !== ROLE_VIEW && setIsShowModalEditAssets(true)
                }
              />
              <ButtonComponent
                addClass={`${
                  dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                } create ${styles.customButtonEdit}`}
                text={t('assets.delete')}
                handleClick={() => deleteAssets()}
              />
            </div>
          </>
        );
      }
    }
  };

  return (
    <>
      <ModalComponent
        toggle={() => {
          setShowModal(!isShowModal);
        }}
        isShowModal={isShowModal}
        title={t('assets.detailAssets')}
        classNameAdd="modal-detail-assets"
      >
        {/* Header content */}
        <div className="assets-name-wrapper">
          <Tooltip className="department-tooltip" title={uDetailAssets?.name} placement="top-start">
            <span className="assets-name">{uDetailAssets?.name}</span>
          </Tooltip>
          {renderButton()}
        </div>

        {/* Main content */}
        <section>
          {isLoadingDetailAssets && <Loading addClass="mt-3 mb-3" />}
          {!isLoadingDetailAssets && uDetailAssets && (
            <Grid container className="assets-detail">
              {/* Row 1 */}
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.assetsCode')}</span>
                <div className="assets-detail-second">{uDetailAssets?.code}</div>
              </Grid>
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.type')}</span>
                <div className="assets-detail-second">{convertType(uDetailAssets?.type)}</div>
              </Grid>
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.inputDate')}</span>
                <div className="assets-detail-second">{uDetailAssets?.input_date}</div>
              </Grid>
              {/* Row 2 */}
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.user')}</span>
                <div className="assets-detail-second">
                  {uDetailAssets?.user ? (
                    <>
                      {uDetailAssets?.user?.user_name} - {uDetailAssets?.user?.division_name}
                    </>
                  ) : (
                    <>{t('common.notDataContract')}</>
                  )}
                </div>
              </Grid>
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.assetsPrice')}</span>
                <div className="assets-detail-second d-flex">
                  <FormatNumber value={uDetailAssets?.price} />
                  <span className="ml-1">{t('common.currency')}</span>
                </div>
              </Grid>
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.outDate')}</span>
                <div className="assets-detail-second">{uDetailAssets?.out_date}</div>
              </Grid>
              {/* Row 3 */}
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.category')}</span>
                <div className="assets-detail-second">{uDetailAssets?.asset_category_name}</div>
              </Grid>
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.assetsPeriod')}</span>
                <div className="assets-detail-second d-flex">
                  <FormatNumber value={uDetailAssets?.period} />
                  <span className="ml-1">{t('common.month')}</span>
                </div>
              </Grid>
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.description')}</span>
                <div className="assets-detail-second">
                  {uDetailAssets?.description ? uDetailAssets?.description : t('common.notDataContract')}
                </div>
              </Grid>
              {/* Row 4 */}
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.warehouseName')}</span>
                <div className="assets-detail-second">{uDetailAssets?.warehouse_name}</div>
              </Grid>
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.priceRent')}</span>
                <div className="assets-detail-second d-flex">
                  <FormatNumber
                    decimalScale={0}
                    noDecimalSeparator
                    value={Math.ceil(uDetailAssets?.price / uDetailAssets?.period)}
                  />
                  <span className="ml-1">{t('assets.VNDMonth')}</span>
                </div>
              </Grid>
              <Grid item xs={3} className="assets-detail-item">
                <span className="assets-detail-first d-block">{t('assets.rentCost')}</span>
                <div className="assets-detail-second d-flex">
                  {uDetailAssets?.rental_price > 0 ? (
                    <>
                      <FormatNumber
                        decimalScale={0}
                        noDecimalSeparator
                        value={uDetailAssets?.rental_price}
                      />
                      <span className="ml-1">{t('common.VND')}</span>
                    </>
                  ) : <span className="ml-1">{t('common.notDataContract')}</span>}
                </div>
              </Grid>
            </Grid>
          )}
        </section>

        {/* History content */}
        <section className="assets-history">
          <span className="assets-history-name">{t('assets.historyAssets')}</span>
          <div className="assets-history-table">
            <div className="table-list">
              <Table className="align-items-center table-hover" responsive>
                <thead>
                  <tr>
                    <th scope="col">{t('masterData.STT')}</th>
                    <th scope="col">{t('assets.act')}</th>
                    <th scope="col">{t('assets.objectAppend')}</th>
                    <th scope="col">{t('assets.day')}</th>
                    <th scope="col">{t('assets.time')}</th>
                    <th scope="col">{t('assets.warehouseName')}</th>
                    <th scope="col">{t('assets.manTakeRes')}</th>
                  </tr>
                </thead>
                <tbody>
                  {uDetailHistoryAssets?.length === 0 ? (
                    <tr>
                      <td colSpan={8}>
                        <div className="error-no-data">{t('common.notData')}</div>
                      </td>
                    </tr>
                  ) : (
                    uDetailHistoryAssets?.map((item, index) => (
                      <tr key={index} className="">
                        <td>{index + 1 > 9 ? index + 1 : <>0{index + 1}</>}</td>
                        <td>
                          {Number(item?.type) === ACTION_CHANGE_STATUS &&
                          Number(item?.previous_status) === STATUS_ASSETS_WAITING_APPROVE &&
                          Number(item?.current_status) === STATUS_ASSETS_GOOD
                            ? t('assets.approve')
                            : Number(item?.type) === ACTION_CHANGE_STATUS &&
                              Number(item?.previous_status) === STATUS_ASSETS_WAITING_APPROVE &&
                              Number(item?.current_status) === STATUS_ASSETS_CANCEL
                            ? t('common.cancel')
                            : convertAction(item?.type)}
                        </td>
                        <td>
                          {item?.user?.user_name ? (
                            <>
                              {item?.user?.user_name}
                              <div className="email-admin">{item?.user?.email}</div>
                            </>
                          ) : (
                            t('common.assets')
                          )}
                        </td>
                        <td>{item?.date}</td>
                        <td>{item?.time}</td>
                        <td>{item?.warehouse_name}</td>
                        <td className="d-flex flex-column align-items-start">
                          {item?.admin?.user_name}
                          <div className="email-admin">{item?.admin?.email}</div>
                        </td>
                      </tr>
                    ))
                  )}
                  {isLoadingDetailHistoryAssets && (
                    <tr>
                      <td colSpan={8}>
                        <Loading addClass="mt-5" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </section>
      </ModalComponent>
    </>
  );
}

export default ModalDetailAssets;
