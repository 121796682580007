import React from 'react';
import { useTranslation } from 'react-i18next';

import imgOTbackground from 'assets/img/theme/OT.png';
import imgOTclock from 'assets/img/theme/OTclock.png';
import iconLateIcons from 'assets/img/icons/late.png';
import iconTimeLate from 'assets/img/icons/time_late.png';
import iconMoney from 'assets/img/icons/money.png';
import imgAvatar from 'assets/img/theme/avatar_default.png';

import styles from './styles.module.scss';
import ProgressBar from 'components/ProgressBar/index.js';
import { handleOnError } from 'helpers';

const LayoutProfile = (props) => {
  const { uProfileUser, filterUser } = props;
  const { t } = useTranslation();

  const progressNow = uProfileUser?.data?.percent_profile;
  const progressBar = [
    {
      bgcolor: '#2ECC71',
      completed: progressNow,
    },
  ];

  return (
    <>
      {filterUser.length === 0 ? (
        <div className={styles.profileContentInfoAlert}>{t('boxInfoEmployee.alertNotInfo')}</div>
      ) : (
        filterUser.map((item, index) => (
          <div key={index} className={styles.avatarBox}>
            <div className={styles.avatarImg}>
              {uProfileUser && uProfileUser?.data?.user_information?.avatar ? (
                <img
                  onError={handleOnError}
                  alt="avatar"
                  className={styles.avatar}
                  src={uProfileUser && uProfileUser?.data?.user_information?.avatar}
                />
              ) : (
                <img alt="avatar" onError={handleOnError} className={styles.avatar} src={imgAvatar} />
              )}
            </div>
            <div className={styles.avatarName}>{uProfileUser && uProfileUser?.data?.user_information?.name}</div>
            <div className={styles.avatarPosition}>
              {uProfileUser && uProfileUser?.data?.user_information?.office_name}
            </div>
            <div className={styles.avatarComplete}>{t('boxInfoEmployee.completeProfile')}</div>
            <div className={styles.avatarBar}>
              {progressBar.map((item, idx) => (
                <ProgressBar key={idx} bgcolor={item.bgcolor} completed={item.completed} />
              ))}
              {progressNow}%
            </div>
            <div className={styles.avatarContent}>{t('employees.email')}</div>
            <div className={styles.avatarContentText}>
              {uProfileUser && uProfileUser?.data?.user_information?.email}
            </div>
            <div className={styles.avatarContent}>{t('boxInfoEmployee.userCode')}</div>
            <div className={styles.avatarContentText}>{uProfileUser && uProfileUser?.data?.user_information?.code}</div>
            <div className={styles.avatarContent}>{t('boxInfoEmployee.manager')}</div>

            {uProfileUser?.data?.manager ? (
              <div className={styles.avatarManager}> {uProfileUser?.data?.manager?.user_name}</div>
            ) : (
              <div className={styles.avatarContentText}>{t('infoEmployee.notHasInfo')}</div>
            )}
            <div className={styles.avatarContent}>{t('masterData.specialTimeKeeping.table.typeTimeKeeping')}</div>
            <div className={styles.avatarContentText}>
              {uProfileUser && uProfileUser?.data?.user_information?.special_timesheet}
            </div>
            <div className={styles.firstLineBox}>
              <div className={styles.miniBox}>
                <div>{uProfileUser?.data?.timesheet?.day_off}</div>
                <div>{t('boxInfoEmployee.dateOffForce')} </div>
              </div>
              <div className={styles.miniBox}>
                <div>{uProfileUser?.data?.timesheet?.leave_day}</div>
                <div>{t('boxInfoEmployee.dateOffed')} </div>
              </div>
            </div>
            <div className={styles.miniBox2}>
              <div>
                <img alt="imgOTbackground" src={imgOTbackground} />
                <img alt="imgOTclock" src={imgOTclock} />
              </div>

              <div className={styles.OTBox}>{uProfileUser?.data?.timesheet?.over_time}</div>
              <div>{t('boxInfoEmployee.hoursOT')} </div>
            </div>
            <div className={styles.miniBox3}>
              <div className={styles.leftMiniBox3}>
                <div>
                  <img alt="iconLateIcons" src={iconLateIcons} /> {t('boxInfoEmployee.timeLate')}
                </div>
                <div className={styles.hoursImg}>
                  <img alt="iconTimeLate" src={iconTimeLate} /> {t('boxInfoEmployee.minuteLate')}
                </div>
                <div className={styles.moneyImg}>
                  <img alt="iconMoney" src={iconMoney} /> {t('boxInfoEmployee.money')}
                </div>
              </div>
              <div className={styles.rightMiniBox3}>
                <div>{uProfileUser?.data?.timesheet?.total_late_day}</div>
                <div>{uProfileUser?.data?.timesheet?.late_time}</div>
                <div>{uProfileUser?.data?.timesheet?.fines}</div>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default LayoutProfile;
