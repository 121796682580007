import React from 'react';

export const InfoIcon = (props) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="16" cy="10" r="10" fill="#C9EAFF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C17.52 24 22 19.52 22 14C22 8.48 17.52 4 12 4C6.48 4 2 8.48 2 14C2 19.52 6.48 24 12 24ZM11 9L13 9L13 11L11 11L11 9ZM11 13L13 13L13 19L11 19L11 13Z"
      fill="#2D99FF"
    />
  </svg>
);

export const ArrowDownIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.76923 16L1.77515 7L17.7633 7L9.76923 16Z" fill="#909090" />
  </svg>
);

export const CalendarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_7033_33459)">
      <path
        d="M4.99946 0C4.60495 0 4.28516 0.319796 4.28516 0.714299V2.14286H5.71372V0.714299C5.71372 0.319796 5.39392 0 4.99946 0Z"
        fill="#909090"
      />
      <path
        d="M14.9995 0C14.605 0 14.2852 0.319796 14.2852 0.714299V2.14286H15.7137V0.714299C15.7138 0.319796 15.394 0 14.9995 0Z"
        fill="#909090"
      />
      <path
        d="M17.8571 2.14258H15.7143V4.99974C15.7143 5.39424 15.3945 5.71404 15 5.71404C14.6055 5.71404 14.2857 5.39424 14.2857 4.99974V2.14258H5.71427V4.99974C5.71427 5.39424 5.39447 5.71404 4.99997 5.71404C4.60547 5.71404 4.28567 5.39424 4.28567 4.99974V2.14258H2.14286C0.959388 2.14258 0 3.10197 0 4.28544V17.8569C0 19.0403 0.959388 19.9997 2.14286 19.9997H17.8571C19.0406 19.9997 20 19.0403 20 17.8569V4.28544C20 3.10197 19.0406 2.14258 17.8571 2.14258ZM18.5714 17.8569C18.5714 18.2514 18.2516 18.5712 17.8571 18.5712H2.14286C1.74835 18.5712 1.42856 18.2514 1.42856 17.8569V8.57115H18.5714V17.8569Z"
        fill="#909090"
      />
      <path
        d="M5.71317 10H4.28461C3.89011 10 3.57031 10.3198 3.57031 10.7143C3.57031 11.1088 3.89011 11.4286 4.28461 11.4286H5.71317C6.10768 11.4286 6.42747 11.1088 6.42747 10.7143C6.42747 10.3198 6.10768 10 5.71317 10Z"
        fill="#909090"
      />
      <path
        d="M10.7132 10H9.28462C8.89011 10 8.57031 10.3198 8.57031 10.7143C8.57031 11.1088 8.89011 11.4286 9.28462 11.4286H10.7132C11.1077 11.4286 11.4275 11.1088 11.4275 10.7143C11.4275 10.3198 11.1077 10 10.7132 10Z"
        fill="#909090"
      />
      <path
        d="M15.7132 10H14.2846C13.8901 10 13.5703 10.3198 13.5703 10.7143C13.5703 11.1088 13.8901 11.4286 14.2846 11.4286H15.7132C16.1077 11.4286 16.4275 11.1088 16.4275 10.7143C16.4275 10.3198 16.1076 10 15.7132 10Z"
        fill="#909090"
      />
      <path
        d="M5.71317 12.8574H4.28461C3.89011 12.8574 3.57031 13.1772 3.57031 13.5717C3.57031 13.9662 3.89011 14.286 4.28461 14.286H5.71317C6.10768 14.286 6.42747 13.9662 6.42747 13.5717C6.42747 13.1772 6.10768 12.8574 5.71317 12.8574Z"
        fill="#909090"
      />
      <path
        d="M10.7132 12.8574H9.28462C8.89011 12.8574 8.57031 13.1772 8.57031 13.5717C8.57031 13.9662 8.89011 14.286 9.28462 14.286H10.7132C11.1077 14.286 11.4275 13.9662 11.4275 13.5717C11.4275 13.1772 11.1077 12.8574 10.7132 12.8574Z"
        fill="#909090"
      />
      <path
        d="M15.7132 12.8574H14.2846C13.8901 12.8574 13.5703 13.1772 13.5703 13.5717C13.5703 13.9662 13.8901 14.286 14.2846 14.286H15.7132C16.1077 14.286 16.4275 13.9662 16.4275 13.5717C16.4274 13.1772 16.1076 12.8574 15.7132 12.8574Z"
        fill="#909090"
      />
      <path
        d="M5.71317 15.7148H4.28461C3.89011 15.7148 3.57031 16.0346 3.57031 16.4291C3.57031 16.8236 3.89011 17.1434 4.28461 17.1434H5.71317C6.10768 17.1434 6.42747 16.8236 6.42747 16.4291C6.42747 16.0346 6.10768 15.7148 5.71317 15.7148Z"
        fill="#909090"
      />
      <path
        d="M10.7132 15.7148H9.28462C8.89011 15.7148 8.57031 16.0346 8.57031 16.4291C8.57031 16.8236 8.89011 17.1434 9.28462 17.1434H10.7132C11.1077 17.1434 11.4275 16.8236 11.4275 16.4291C11.4275 16.0346 11.1077 15.7148 10.7132 15.7148Z"
        fill="#909090"
      />
      <path
        d="M15.7132 15.7148H14.2846C13.8901 15.7148 13.5703 16.0346 13.5703 16.4291C13.5703 16.8236 13.8901 17.1434 14.2846 17.1434H15.7132C16.1077 17.1434 16.4275 16.8236 16.4275 16.4291C16.4275 16.0346 16.1076 15.7148 15.7132 15.7148Z"
        fill="#909090"
      />
    </g>
    <defs>
      <clipPath id="clip0_7033_33459">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const SearchIcon = (props) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="10" r="10" fill="#C9EAFF" />
    <path
      d="M19.35 22.425L13.325 16.4C12.825 16.8333 12.2417 17.1708 11.575 17.4125C10.9083 17.6542 10.2 17.775 9.45 17.775C7.65 17.775 6.125 17.15 4.875 15.9C3.625 14.65 3 13.1417 3 11.375C3 9.60834 3.625 8.10001 4.875 6.85001C6.125 5.60001 7.64167 4.97501 9.425 4.97501C11.1917 4.97501 12.6958 5.60001 13.9375 6.85001C15.1792 8.10001 15.8 9.60834 15.8 11.375C15.8 12.0917 15.6833 12.7833 15.45 13.45C15.2167 14.1167 14.8667 14.7417 14.4 15.325L20.475 21.35C20.625 21.4833 20.7 21.6542 20.7 21.8625C20.7 22.0708 20.6167 22.2583 20.45 22.425C20.3 22.575 20.1167 22.65 19.9 22.65C19.6833 22.65 19.5 22.575 19.35 22.425ZM9.425 16.275C10.775 16.275 11.925 15.7958 12.875 14.8375C13.825 13.8792 14.3 12.725 14.3 11.375C14.3 10.025 13.825 8.87084 12.875 7.91251C11.925 6.95417 10.775 6.47501 9.425 6.47501C8.05833 6.47501 6.89583 6.95417 5.9375 7.91251C4.97917 8.87084 4.5 10.025 4.5 11.375C4.5 12.725 4.97917 13.8792 5.9375 14.8375C6.89583 15.7958 8.05833 16.275 9.425 16.275Z"
      fill="#2D99FF"
    />
  </svg>
);
