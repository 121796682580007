import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const YupValidateEditContract = () => {
  const { t } = useTranslation();
  const regexDate = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/g;

  const validationSchema = Yup.object().shape({
    code_contract: Yup.string()
      .required(t('messages.listContract.pleaseEnterContractCode'))
      .max(30, t('common.pleaseEnterLessThanThirtyCharacters')),
    type_contract: Yup.string().nullable().required(t('messages.thisIsARequiredField')),
    start_date: Yup.string()
      .nullable()
      .required(t('messages.thisIsARequiredField'))
      .matches(regexDate, t('messages.pleaseEnterTheCorrectFormatDate')),
    end_date: Yup.string()
      .nullable()
      .when(['type_contract'], {
        is: (typeContract) => {
          return Number(typeContract) !== 2;
        },
        then: Yup.string()
          .nullable()
          .required(t('messages.thisIsARequiredField'))
          .matches(regexDate, t('messages.pleaseEnterTheCorrectFormatDate')),
      }),
  });
  return validationSchema;
};
