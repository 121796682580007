import React from 'react';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

import styles from './style.module.scss';
import classNames from 'classnames';

function SideNavBar(props) {
  const { value, onChange, routes, scrollable, hasMarginBottom } = props;
  return (
    <Tabs
      value={value}
      onChange={onChange}
      aria-label="visible arrows tabs example"
      className={classNames(hasMarginBottom && styles.addedMarginTab, styles.sideTabMenu)}
      variant={scrollable && 'scrollable'}
      scrollButtons={scrollable ? true : false}
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': { opacity: 0.3 },
        },
      }}
    >
      {routes?.map((item, index) => (
        <Tab key={index} label={item?.name} />
      ))}
    </Tabs>
  );
}

export default SideNavBar;
