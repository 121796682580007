import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const YupValidate = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    data: Yup.array().of(
      Yup.object().shape({
        group_id: Yup.string().required(t('messages.thisIsARequiredField')),
        date: Yup.string().required(t('messages.thisIsARequiredField')),
      }).required(),
    ),
  });
  return validationSchema;
};

export default YupValidate;
