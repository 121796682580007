import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import defaultAvatar from 'assets/img/icons/avatar_default.svg';
import iconChecked from 'assets/img/icons/checked.png';

import './styles.scss';
import ButtonComponent from 'components/Buttons';
import InputField from 'components/Input/InputField';
import Loading from 'components/Loading';
import ModalComponent from 'components/Modal';
import SearchComponent from 'components/Search';

import { apiUpdateRolesTeam, apiUpdateRoleNameDivision } from 'api/department';

import { MAX_LENGTH_50 } from 'constants/index.js';
import { MODEL_TEAM } from 'constants/Department';
import { USE_DETAIL_TEAM, USE_DETAIL_DEPARTMENT } from 'constants/Department';

import { useSearchEmployeeByDivision } from 'hook/useDepartment.js';

import { removeSpecial, isEmptyObject } from 'helpers/index.js';
import { handleOnError } from 'helpers';

const DEFAULT_ROLE_MEMBER = {
  role: '',
  member: {},
  errorRole: '',
};

const ChooseRolesMember = (props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    idDivision,
    idTeam,
    isModalRolesMember,
    handleToggleManager,
    type,
    leader,
    listMemberRole,
    member,
    isAddNew,
    onHandelMember,
  } = props;

  const [stateButton, setStateButton] = useState(true);
  const [stateIsLoading, setStateIsLoading] = useState(false);
  const [keyWord, setKeyWord] = useState('');
  const [formMember, setFormMember] = useState(DEFAULT_ROLE_MEMBER);
  const [listMember, setListMember] = useState([]);

  const { data: uListMember, isLoading: isLoadingListMember } = useSearchEmployeeByDivision({
    key_word: keyWord,
    division_id: idDivision,
  });

  function setSearchListMember(members, member, lead, membersRole) {
    if (members) {
      let _listMember = [];
      let userIdMember = [];
      if (membersRole) {
        userIdMember = membersRole.map((item) => item.id);
      }
      if (!isEmptyObject(lead)) userIdMember.push(lead.user_id);

      members.forEach((item) => {
        if (!userIdMember.includes(item.user_id) || (!isEmptyObject(member) && member.id === item.user_id)) {
          _listMember.push({
            ...item,
            status: isEmptyObject(member) ? false : member.id === item.user_id ? true : false,
          });
        }
      });

      setListMember(_listMember);
    } else {
      setListMember([]);
    }
  }

  useEffect(() => {
    if (member) {
      setFormMember((prevState) => ({
        ...prevState,
        role: isEmptyObject(member) ? '' : member.role_name,
        member: isEmptyObject(member) ? {} : member,
        errorRole: '',
      }));
    } else {
      setFormMember(DEFAULT_ROLE_MEMBER);
    }
  }, [member]);

  useEffect(() => {
    if (!isModalRolesMember) {
      setKeyWord('');
      setStateButton(true);
      setStateIsLoading(false);
    }
    setSearchListMember(uListMember, member, leader, listMemberRole);
  }, [isModalRolesMember, uListMember, member, leader, listMemberRole]);

  useEffect(() => {
    if (
      formMember.role &&
      !isEmptyObject(formMember.member) &&
      (formMember.member.id !== member.id ||
        (formMember.member.id === member.id &&
          ((formMember.role !== member.role_name_team && member.role_name_team) ||
            (formMember.role !== member.role_name_division && member.role_name_division))))
    ) {
      setStateButton(false);
    } else {
      setStateButton(true);
    }
  }, [formMember, member]);

  const handleSearchManager = (keyword) => {
    setKeyWord(keyword);
  };

  const handleChooseRolesMember = (item) => {
    const selectItems = [...listMember];
    setListMember(
      selectItems.map((el) => (el.user_id === item.user_id ? { ...el, status: true } : { ...el, status: false })),
    );
    if (isEmptyObject(member) || (member && member.id !== item.user_id)) {
      let _member = { ...item, id: item.user_id };
      setFormMember({ ...formMember, member: _member });
    } else {
      setFormMember({ ...formMember, member: {} });
    }
  };

  const blockMember = (item, key) => (
    <Col md={12} key={key}>
      <div
        className={item.status ? 'manager-block-team-division choosen-manager' : 'manager-block-team-division'}
        onClick={() => handleChooseRolesMember(item)}
      >
        <div className="block-noicons pl-2">
          <div className=" avatar">
            <img
              onError={handleOnError}
              width="100%"
              height="100%"
              src={item?.avatar ? item.avatar : defaultAvatar}
              alt="avatar"
            />
          </div>
          <div className="contentEdit ml-2">
            <div className="name">{item?.name || item?.user_name}</div>
            <div className="position">
              <span>{item?.email}</span>
              <span> - </span>
              <span>{item?.position_name || item?.role_name_division}</span>
            </div>
          </div>
        </div>
        {item.status && <img width="15px" height="15px" src={iconChecked} alt="checked manager" className="mr-3" />}
      </div>
    </Col>
  );

  const { mutate: handlerSubmitUpdateRolesTeam } = useMutation(
    async (data) => {
      let _data = {
        user_id: formMember.member.user_id,
        user_id_deleted: member.id,
        role_name_team: formMember.role,
        team_id: idTeam,
      };
      if (formMember.member.id === member.id) delete _data.user_id_deleted;

      const result = await apiUpdateRolesTeam(idTeam, _data);
      return result;
    },
    {
      onSuccess: () => {
        setStateIsLoading(false);
        setStateButton(true);

        queryClient.invalidateQueries(USE_DETAIL_TEAM);
        queryClient.invalidateQueries(USE_DETAIL_DEPARTMENT);
        onHandelMember(formMember);
        handleToggleManager();
      },
      onError: () => {
        setStateIsLoading(false);
        setStateButton(true);
        handleToggleManager();
      },
    },
  );

  const { mutate: handlerSubmitUpdateRolesDivision } = useMutation(
    async (data) => {
      let _data = {
        user_id: formMember.member.user_id,
        user_deleted: member.id ? member.id : formMember.member.user_id,
        role_name_division: formMember.role,
      };

      const result = await apiUpdateRoleNameDivision(formMember.member.user_id, _data);
      return result;
    },
    {
      onSuccess: () => {
        setStateIsLoading(false);
        setStateButton(true);

        queryClient.invalidateQueries(USE_DETAIL_DEPARTMENT);
        onHandelMember(formMember);
        handleToggleManager();
      },
      onError: () => {
        setStateIsLoading(false);
        setStateButton(true);
        handleToggleManager();
      },
    },
  );

  const onSubmitRolesManage = (event) => {
    event.preventDefault();
    if (!isAddNew) {
      setStateIsLoading(true);
    }
    setStateButton(true);

    if (isAddNew) {
      handleToggleManager();
      onHandelMember(formMember);
    } else {
      if (type === MODEL_TEAM) {
        handlerSubmitUpdateRolesTeam();
      } else {
        handlerSubmitUpdateRolesDivision();
      }
    }
  };

  const handleInputRoles = (e) => {
    setFormMember({ ...formMember, role: removeSpecial(e.target.value) });
  };

  return (
    <ModalComponent
      toggle={handleToggleManager}
      isShowModal={isModalRolesMember}
      title={
        type === MODEL_TEAM
          ? isEmptyObject(member)
            ? t('divisionDiagram.divisionPopup.addRole')
            : t('divisionDiagram.divisionPopup.updateRole')
          : isEmptyObject(member)
            ? t('divisionDiagram.divisionPopup.addRole')
            : t('divisionDiagram.divisionPopup.updateRole')
      }
      classNameAdd="modalAdd"
    >
      <form onSubmit={onSubmitRolesManage}>
        <Row className="mt-2">
          <Col md="12">
            <div className="fieldset-group">
              <div className="form">
                <InputField
                  error={formMember.error_role}
                  defaultValue={formMember.role}
                  name="nameRole"
                  label={t('divisionDiagram.divisionPopup.nameRole')}
                  maxLength={MAX_LENGTH_50}
                  onHandleInput={handleInputRoles}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md="12">
            <SearchComponent
              defaultValue={keyWord}
              handleSearch={handleSearchManager}
              txtPlaceholder={t('divisionDiagram.divisionPopup.search')}
            />
          </Col>
        </Row>
        <Row className="mt-4 body-search">
          {isLoadingListMember ? (
            <Col md={12} className="d-flex justify-content-center">
              <Loading addClass="mt-2" />
            </Col>
          ) : listMember?.length ? (
            listMember?.map((item, key) => blockMember(item, key))
          ) : (
            <Col md={12} className="d-flex justify-content-center error-no-data">
              {t('common.notData')}
            </Col>
          )}
        </Row>
        <Row className="mt-5">
          <Col md="12">
            <ButtonComponent
              hasDisabled={stateButton}
              isLoading={isAddNew ? false : stateIsLoading}
              type="submit"
              addClass="button-add-department orange w-100"
              text={
                type === MODEL_TEAM
                  ? isEmptyObject(member)
                    ? t('divisionDiagram.divisionPopup.add')
                    : t('divisionDiagram.divisionPopup.btnUpdate')
                  : isEmptyObject(member)
                    ? t('divisionDiagram.divisionPopup.add')
                    : t('divisionDiagram.divisionPopup.btnUpdate')
              }
            />
          </Col>
        </Row>
      </form>
    </ModalComponent>
  );
};

export default ChooseRolesMember;
