import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Col, Row } from 'reactstrap';
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { useQueryClient } from 'react-query';

import { MenuItem } from '@mui/material';

import iconCareer from '../../../../assets/img/icons/career.png';
import iconDot from '../../../../assets/img/icons/dot.png';
import iconNext from '../../../../assets/img/icons/next.png';
import iconPrev from '../../../../assets/img/icons/prev.png';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';
import AlertComponent from 'components/Alert';
import ModalComponent from 'components/Modal';
import SelectField from 'components/ReactForm/SelectField';
import { useGetGroupUser } from 'hook/useEmployees';
import ButtonComponent from 'components/Buttons';
import { YupValidate } from './YupValidate/yupValidateContract.js';
import DateDay from 'components/ReactForm/DateDay';
import addRelative from 'assets/img/icons/circle_add.png';
import { apiCreateCareerStage } from 'api/profileEmployee';
import { HTTP_OK, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import { customDate } from 'helpers/formatDate';
import * as Constants from 'constants/ProfileEmployee.js';
import { useGetCareerStage } from 'hook/useProfileEmployee';
import Loading from 'components/Loading';
import styles from './../style.module.scss';
import classNames from 'classnames';

SwiperCore.use([Navigation]);

const Contract = (props) => {
  const { userId } = props;
  const { data: uGetCareerStage, isLoading: isLoadingGetCareerStage } = useGetCareerStage({ user_id: userId });

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dataInforUser = useContext(ThemContext);
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const { data: uGetGroupUser } = useGetGroupUser();
  // eslint-disable-next-line
  const [message, setMessage] = useState('');
  // eslint-disable-next-line
  const [stateButton, setStateButton] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(YupValidate()),
    mode: 'onChange'
  });
  const { remove, append, fields } = useFieldArray({
    control,
    name: "data"
  });

  useEffect(() => {
    reset({
      data: uGetCareerStage?.data
    });
    clearErrors();
  }, [uGetCareerStage, reset, clearErrors]);

  const closeModal = () => {
    setShowModalEdit(false);
    reset({
      data: uGetCareerStage?.data
    });
    clearErrors();
  }

  const handleSubmitForm = async (data) => {
    setStateButton(true);
    let response = {};
    let dataSubmit = data.data.map((item) => {return {...item, date: customDate(item.date, 'DD/MM/YYYY')}});

    data.data = dataSubmit;
    data.user_id = userId;

    response = await apiCreateCareerStage(data);
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }

    if (response?.data?.status === 422) {
      setMessage(t('common.startTimeMustNotOverLap'));
      setShowAlert(true);
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModalEdit(false);
      setStateButton(false);
      setMessage(t('common.editDataSuccess'));
      setShowAlert(true);
      await queryClient.invalidateQueries(Constants.USE_GET_CAREER_STAGE);
    }
    setStateButton(false);
  };

  return (
    <>
      <div className={styles.profileBoxContract}>
        <Row className={styles.rowTitle}>
          <Col xs="10">
            <div className={classNames(`${styles.profileTitle} w-100`)}>
              <img alt={iconCareer} className={styles.imgTitle} src={iconCareer} />
              {t('infoEmployee.titleCareer')}
            </div>
          </Col>
          <Col xs="2">
            {uGetCareerStage?.data && uGetCareerStage?.data?.length !== 0 && (
              <div
                className={classNames(
                  `${dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE} ${
                    styles.editDeleteButton
                  } mr--15`,
                )}
                onClick={() => {
                  if(dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE){
                    return ;
                  }
                  setShowModalEdit(true);
                }}
              >
                <HiPencil />
              </div>
            )}
          </Col>
        </Row>
        <div>
          <div className={styles.borderDashed}></div>
          {uGetCareerStage?.data && (uGetCareerStage?.data?.length === 0) | (uGetCareerStage?.data === null) ? (
            <div className={styles.profileContentInfoAlert}>{t('boxInfoEmployee.alertNotInfo')}</div>
          ) : (
            <Swiper
              modules={[Navigation]}
              spaceBetween={30}
              slidesPerView={3.005}
              breakpoints={{
                1366: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 2.4,
                },
                667: {
                  slidesPerView: 2,
                },
              }}
              navigation={{
                prevEl: '.prevCa',
                nextEl: '.nextCa',
              }}
              className={styles.salaryBoxParent}
            >
              {uGetCareerStage?.data &&
                uGetCareerStage?.data?.map((itemContract, index) => (
                  <SwiperSlide key={index} className={`${itemContract?.active ? styles.salaryBoxNow : styles.salaryBox} ${index === uGetCareerStage?.data?.length - 1 ? styles.salaryBoxActive : "" }`}>
                    <div>
                      <div className={`${styles.point} ${index === uGetCareerStage?.data?.length - 1 ? styles.pointActive : "" }`}></div>
                      <div className={styles.contentBox}>
                        <div className={styles.profileContentInfo}>
                          <img alt={iconDot} src={iconDot} />
                          {itemContract.date ? (
                            customDate(itemContract.date, 'DD/MM/YYYY')
                          ) : (
                            <span>{t('infoEmployee.notUpdate')}</span>
                          )}
                        </div>
                        <div className={styles.profileContentInfo}>
                          <img alt={iconDot} src={iconDot} />
                          {uGetCareerStage?.data[index + 1] ? (
                            customDate(uGetCareerStage?.data[index + 1]?.date, 'DD/MM/YYYY', '-1')
                          ) : (
                            <span>{t('infoEmployee.notUpdate')}</span>
                          )}
                        </div>
                      </div>
                      <div className={styles.profileContentInfo}>
                        <p className="text-bold mb-3">{t('common.employee') + ' ' + itemContract?.group_name}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}

              {uGetCareerStage?.data && uGetCareerStage?.data?.length <= 3 ? (
                ''
              ) : (
                <div className={`${styles.prevButton} prevCa`}>
                  <img alt={iconPrev} src={iconPrev} />
                </div>
              )}

              {uGetCareerStage?.data && uGetCareerStage?.data?.length <= 3 ? (
                ''
              ) : (
                <div className={`${styles.nextButton} nextCa`}>
                  <img alt={iconNext} src={iconNext} />
                </div>
              )}
            </Swiper>
          )}
          {isLoadingGetCareerStage && <Loading addClass="mt-10" />}
        </div>
      </div>
      {isShowModalEdit && uGetCareerStage?.data.length > 0 && (
        <ModalComponent
          toggle={() => closeModal()}
          isShowModal={isShowModalEdit}
          title={t('employees.editCategoryEmployees')}
          classNameAdd="modal-edit-contract-infor-employee"
        >
          <form className="mt-3" onSubmit={handleSubmit(handleSubmitForm)}>
            {fields?.map((i, ind) => {
              const fieldName = `data.${ind}`;
              setValue(`data.${ind}.id`, i?.id);
              return (
                <div className="mt-4 row-standard-index" key={ind}>
                  <Row className="mt-4">
                    <Col className="md-6 titleMilestones">{t('infoEmployee.milestonesOfChange') + ' ' + (ind + 1)}</Col>
                    <Col md={2}>
                      {ind !== 0 && (
                        <div
                          className={`${styles.editDeleteButtonChild}`}
                          onClick={() => remove(ind)}
                        >
                          <MdDelete />
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col className="md-6">
                      <div className="fieldset-group">
                        <SelectField
                          name={`${fieldName}.group_id`}
                          error={errors?.data?.[ind]?.group_id?.message}
                          label={t('infoEmployee.selectCategoryEmployees')}
                          defaultValue={i?.group_id ? Number(i?.group_id) : ''}
                          control={control}
                          useWatch={useWatch}
                          required
                          setValue={setValue}
                          register={register}
                          isShowModal={isShowModalEdit}
                          isRequired
                        >
                          {uGetGroupUser &&
                            uGetGroupUser?.data.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </SelectField>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col className="md-6">
                      <DateDay
                        label={t('infoEmployee.selectStartTime')}
                        name={`${fieldName}.date`}
                        error={errors?.data?.[ind]?.date?.message}
                        control={control}
                        setValue={setValue}
                        register={register}
                        isShowModal={isShowModalEdit}
                        defaultValue={i?.date || ''}
                        isRequired
                        isReadOnly
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
            <div
              className={styles.addRelative}
              onClick={() => {
                append({ id: null, group_id: '', date: '' });
              }}
            >
              <img alt={addRelative} className={styles.imgRelative} src={addRelative} />
              <span>{t('infoEmployee.addStage')}</span>
            </div>
            <Row className="mt-5">
              <Col className="md-6">
                <ButtonComponent
                  text={t('common.cancel')}
                  addClass="w-100 btn-bg-yellow2"
                  handleClick={() => closeModal()}
                />
              </Col>
              <Col className="md-6">
                <ButtonComponent
                  text={t('common.save')}
                  addClass="w-100"
                  type="submit"
                  handleClick={handleSubmit(handleSubmitForm)}
                  hasDisabled={stateButton}
                  isLoading={stateButton}
                />
              </Col>
            </Row>
          </form>
        </ModalComponent>
      )}
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};

export default Contract;
