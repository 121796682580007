import { InputAdornment } from '@mui/material';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';

import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import { CssTextField, IS_INPUT_SEARCH } from './constant';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, thousandSeparator, name, setValue, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
        setValue(name, values.floatValue);
      }}
      thousandSeparator={thousandSeparator ? true : false}
      isNumericString
      decimalScale={0}
    />
  );
});

function CustomInputNumber({
  label,
  maxLength,
  defaultValue,
  name,
  placeholder,
  control,
  register,
  errors,
  useWatch,
  required,
  setValue,
  type,
  setError,
  clearErrors,
  handleSearch,
  onHandelChange,
  thousandSeparator,
  disabled,
  isInteger,
}) {
  const showError = errors[name];
  // Style for custom input label

  // Watch value when typing --> change classnames
  const titleWatch = useWatch({
    control,
    name: name,
  });

  useEffect(() => {
    setValue(name, defaultValue);
    // eslint-disable-next-line
  }, [setValue, name]);

  const handelChange = (e) => {
    setValue(name, e.target.value);
    if (e.target.value) {
      clearErrors && clearErrors(name)
    }
    if (type === IS_INPUT_SEARCH) {
      handleSearch(e.target.value);
    }
    if (e.target.value && onHandelChange) {
      onHandelChange(e);
    }
  };

  return (
    <div>
      <Controller
        render={({ field }) => (
          <CssTextField
            inputProps={{
              maxLength: maxLength,
              thousandSeparator: thousandSeparator,
              name: name,
              setValue: setValue,
            }}
            InputProps={{
              startAdornment: type === IS_INPUT_SEARCH && (
                <InputAdornment position="start">
                  <i className={classNames(`fa fa-search`)} style={{ color: COLOR_CHECKBOX_CUSTOM }} />
                </InputAdornment>
              ),
              inputComponent: NumberFormatCustom,
            }}
            placeholder={type === IS_INPUT_SEARCH ? placeholder : undefined}
            {...register(name)}
            label={<React.Fragment>{placeholder && !titleWatch ? placeholder : label}</React.Fragment>}
            variant="outlined"
            required={required}
            error={showError ? true : false}
            name={name}
            value={titleWatch || ''}
            onChange={handelChange}
            disabled={disabled ? true : false}
            onKeyDown={(evt) => {
              if (isInteger) return evt.key === '-' && evt.preventDefault();
            }}
            // CSS
            labelspace={titleWatch === null || titleWatch === '' ? '-1px' : 0}
          />
        )}
        control={control}
        name={name}
      />
      <p className="error-input error">{showError && errors[`${name}`]?.message}</p>
    </div>
  );
}

export default CustomInputNumber;
