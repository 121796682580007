import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ModalEditContract from '../../Modals/EditContract.js';
import { isNotNullObject } from 'helpers/index.js';
import { TAB_LIST_CONTRACT } from 'constants/Contract.js';
import { USE_LIST_CONTRACT } from 'constants/Contract.js';

const EditContract = (props) => {
  const { onSetMessage, isShowModalEdit, onSetShowModal, formDataContract, txtTitleModal, txtBtnModal } = props;

  const { t } = useTranslation();
  return (
    <>
      {formDataContract && isNotNullObject(formDataContract) ? (
        <ModalEditContract
          formDataContract={{
            user_id: formDataContract.user_id,
            name: formDataContract.name,
            contract_id: formDataContract.contract_id,
            type_contract: formDataContract.contract_category_id,
            type_employee: formDataContract.group_id,
            code_contract: formDataContract.contract_code,
            start_date: moment(formDataContract.start_date, 'DD/MM/YYYY'),
            end_date: moment(formDataContract.end_date, 'DD/MM/YYYY'),
            sent_mail: formDataContract.contract_sent_mail,
            is_onboard: formDataContract.is_onboard,
          }}
          onSetMessage={onSetMessage}
          isShowModal={isShowModalEdit}
          onSetShowModal={onSetShowModal}
          txtTitleModal={txtTitleModal ?? t('contract.editContract')}
          txtBtnModal={txtBtnModal ?? t('common.update')}
          type={TAB_LIST_CONTRACT}
          keyCache={USE_LIST_CONTRACT}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default EditContract;
