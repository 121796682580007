import $ from 'jquery';
import moment from 'moment'
import { toasts } from '../index';
import { sendCustom } from 'helpers/axios';
import { getLocalStorage } from 'helpers/localStorage'
import { safeParseJSON } from 'helpers/json'
import avatarDefault from 'assets/img/icons/default-avatar.svg';

export const DEFAULT_NOT_REQUIRED = -1;

const cache = {}

export const FILTER_BY_MODULE = {
  TIME_SHEETS: 'timeSheets',
  LIST_EMPLOYEES: 'listEmployees',
  TIME_KEEPING_DETAIL: 'timeKeepingDetail',
}

export const handleOnError = (e) => {
  e.target.src = avatarDefault;
};

export const isNotNullObject = (object) => {
  return Object.entries(object).length !== 0;
};

export const isEmptyObject = (object) => {
  if (!object) return true;
  return Object.entries(object).toString() === '' || Object.entries(object).toString() === null;
};

export const isNumberAnode = (value) => {
  if (Number(value) !== 'NaN' && !value.includes('.') && value >= 0) return true;
  return false;
};

export const isFloatAnode = (value) => {
  if (/^(\d*)([.]\d{0,1})?$/.test(value)) return true;
  return false;
};

export const isValidEmail = (email) => {
  // eslint-disable-next-line
  return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
};

export const isNumberCurrency = (value) => {
  let _value = value.toString().replace(/,/g, '');
  _value = _value.toString().replace(/./g, '');
  if (/^(\d*)([,]\d{0,1})?$/.test(_value)) return true;
  return false;
};

export const getStrError = (value) => {
  if (!value) return;
  if (typeof value === 'string') {
    return value;
  }
  return value[0];
};

export const handleGetFirstCharacter = (str) => {
  let resultStr = '';
  str?.split(' ').map((item) => (resultStr += item.charAt(0).toUpperCase()));
  return resultStr;
};

export const checkValidate = (dataForm, dataValidate) => {
  let dataError = {};

  dataValidate.forEach((elements) => {
    elements.validate.forEach((validate) => {
      // check required
      if (validate?.required) {
        if (!dataForm[elements.name]) {
          dataError[elements.name_error] = validate.message;
          return;
        }
      }

      // check length
      if (validate?.length) {
        let str = dataForm[elements.name];
        if (typeof str === 'string' && str.length > validate.length) {
          dataError[elements.name_error] = validate.message;
          return;
        }
      }
    });
  });

  return dataError;
};

export const checkErrorResponse = (dataResponseMsg, dataValidate) => {
  let dataError = {};
  if (!dataResponseMsg) return dataError;

  dataValidate.forEach((elements) => {
    dataError[elements.name_error] = getStrError(dataResponseMsg?.[elements.name_error]);
  });

  return dataError;
};

export const removeSpecialCharacters = (value) => {
  return value.replace(/[&\\#@^|;_`,+()$~%.'":*?/1234567890<>={}-]/g, '');
};

export const removeSpecial = (value) => {
  if (!value) return value;
  return value.replace(/[&\\#@^|;_`,+()$~%.'":*?/!<>={}-]/g, '');
};

export const handleExportExcel = async (url, params, fileName, messageError) => {
  try {
    const res = await sendCustom({
      url,
      params,
      responseType: 'blob',
    });

    if (res.status === 422) {
      return toasts.error(messageError ? messageError : 'Vui lòng kiểm tra lại điều kiện lọc dữ liệu');
    }

    const blob = new Blob([res.data], { type: res.data.type });
    const link = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('download', fileName);
    a.setAttribute('href', link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {}
};

export const isZooming = () => {
  let px_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
  var newPx_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
  if (newPx_ratio === px_ratio) {
    var newWidth = $('table').width();
    $('.top').width(newWidth);
  }
};

export const handleScrollTopTable = () => {
  $('.wrapper-top').scroll(function () {
    $('.scroll-custom').scrollLeft($('.wrapper-top').scrollLeft());
  });
  $('.scroll-custom').scroll(function () {
    $('.wrapper-top').scrollLeft($('.scroll-custom').scrollLeft());
  });
};

export const bubbleSortDESCByDate = (array) => {
  for (let i = 0; i < array.length - 1; i++) {
    for (let j = 0; j < array.length - i - 1; j++) {
      if(array[j].birthday < array[j + 1].birthday)
      {
        let temp = array[j];
        array[j] = array[j + 1];
        array[j + 1] = temp;
      }   
    }
  }
  return array;
}

export function storeFilterModule(value, key) {
  try {
    const currentJsonValue = getFilterModule('@storeFilterModule')
    const currentValue = currentJsonValue ? safeParseJSON(currentJsonValue) : {}
    if (!currentValue[key]) {
      currentValue[key] = {}
    }
    currentValue[key] = value
    cache.filterByModule = JSON.stringify(currentValue)
    if (getLocalStorage()) {
      localStorage.setItem('@storeFilterModule', cache.filterByModule)
    }
  } catch (error) {
    alert(error.message)
  }
}

export function getFilterModule() {
  try {
    if (cache.filterByModule) {
      return cache.filterByModule
    }
    if (getLocalStorage()) {
      return localStorage.getItem('@storeFilterModule')
    }
  } catch (error) {
    alert(error.message)
  }
}

export function removeFilterModule(key = null) {
  try {
    if (key === null) {
      localStorage.removeItem('@storeFilterModule')
      delete cache.filterByModule
    } else {
      const currentJsonValue = localStorage.getItem('@storeFilterModule')
      const currentValue = currentJsonValue ? safeParseJSON(currentJsonValue) : {}
      if (currentValue[key]) {
        delete currentValue[key]
        cache.filterByModule = JSON.stringify(currentValue)
        if (getLocalStorage()) {
          localStorage.setItem('@storeFilterModule', cache.filterByModule)
        }
      }
    }
  } catch (error) {
    alert(error.message)
  }
}

export const checkIsBetweenTime = (begin, from, to, format = 'YYYY-MM', inclusivity = '[]') => {
  const timeBegin = moment(begin).format(format)
  const timeFrom = moment(from).format(format)
  const timeTo = moment(to).format(format)
  return moment(timeBegin).isBetween(timeFrom, timeTo, undefined, inclusivity)
}


export const removeVietnameseTones = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    // eslint-disable-next-line no-useless-escape
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' ',
  );
  return str;
};
