import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'antd'
import { Input } from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import { confirmAlert } from 'react-confirm-alert'
import moment from 'moment'
import classNames from 'classnames'
import { Stack, ButtonBase, RadioGroup, FormControlLabel, Radio, IconButton, Menu, MenuItem } from '@mui/material'
import { AiFillEye } from 'react-icons/ai'
import { HiPencil } from 'react-icons/hi'
import { IoIosList } from 'react-icons/io'
import { MdDelete } from 'react-icons/md'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import iconLoading from 'assets/img/icons/loading.png'
import { DEFAULT_PAGE_MUI, DEFAULT_LIMIT, PER_PAGE_OPTION, ROLE_EDIT } from 'constants/index'
import { ThemContext } from 'layouts/Admin'
import { ZERO } from 'constants/MasterData'
import { HTTP_OK } from 'constants/StatusCode'
import { COLOR_CHECKBOX_NOT_ACTIVE } from 'constants/ColorVariable'
import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable'
import { FILTER_STATUS_INSURANCE } from 'constants/Policy'
import { handleExportExcel } from 'helpers'
import { customDate } from 'helpers/formatDate'
import { useGetSocialInsurance } from 'hook/usePolicy'
import { apiDeleteSocialInsurance } from 'api/policy'
import { CONTROL_RADIO } from 'components/InputYupAndMui/constant'
import DateDayMonthComponent from 'components/DateTime/DateMonth.js'
import SearchComponent from 'components/Search'
import ModalRadio from 'components/ModalRadio'
import Alert from 'components/Alert'
import PolicyHeader from 'views/pages/Policy/PolicyHeader'
import ImportModal from 'views/pages/Policy/ImportModal'
import UpdateOrCreateInsurancePolicy from 'views/pages/Policy/InsurancePolicy/UpdateOrCreateInsurancePolicy'
import BasicSalaryChangeHistory from 'views/pages/Policy/InsurancePolicy/BasicSalaryChangeHistory'
import styles from 'views/pages/Policy/styles.module.scss'

const InsurancePolicy = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [openAction, setOpenAction] = useState(false)
  const [openHistorySalary, setOpenHistorySalary] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [currentValue, setCurrentValue] = useState({})
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [openImport, setOpenImport] = useState(false)
  const initialFilter = {
    status: null,
    keyword: '',
    month: moment().format('MM/YYYY'),
    division_id: null,
    page: DEFAULT_PAGE_MUI,
    per_page: DEFAULT_LIMIT,
  }
  const [filter, setFilter] = useState(initialFilter)
  const { data: socialInsurance, isLoading, refetch } = useGetSocialInsurance({
    ...filter,
    page: filter?.page + 1,
  })
  const dataInfoUser = useContext(ThemContext)

  const { control } = useForm({
    mode: 'onBlur',
  })

  useEffect(() => {
    if (!isOpen) {
      setCurrentValue({})
    }
  }, [isOpen])

  const columns = [
    {
      dataIndex: 'code',
      title: t('policy.table.employeeCode'),
    },
    {
      dataIndex: 'name',
      title: t('policy.table.employeeName'),
    },
    {
      dataIndex: 'social_insurance_code',
      title: t('policy.table.socialInsuranceCode'),
    },
    {
      dataIndex: 'division_name',
      title: t('policy.table.department'),
      render: (value) => <span>{value || t('common.notDataContract')}</span>,
    },
    {
      dataIndex: 'insurance_salary',
      title: t('policy.table.basicSalary'),
      render: (value) => <span>{new Intl.NumberFormat('de-DE').format(value)}</span>,
    },
    {
      dataIndex: 'status_label',
      title: t('policy.table.status'),
    },
    {
      dataIndex: 'time_range',
      title: t('policy.table.insurancePaymentMonth'),
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Stack direction="row">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-expanded={'true'}
            aria-haspopup="true"
            onClick={(event) => {
              setOpenAction(event.currentTarget)
              setCurrentValue(record)
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Stack>
      ),
      fixed: 'right',
      className: styles.actionGroup,
    },
  ]

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const onHandleDelete = () => {
    setOpenAction(null)
    confirmAlert({
      title: t('policy.messages.confirmAlertDeleteInsurance'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.delete'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteSocialInsurance(currentValue.id)
            if (response.status === HTTP_OK) {
              setAlertMessage(response.data.messages)
              refetch()
            }
          },
        },
      ],
    })
  }

  const onSelectStatus = (value) => {
    setFilter({ ...filter, status: value })
  }

  const onPageChange = (_, newPage) => {
    setFilter({ ...filter, page: newPage })
  }

  const onRowsPerPageChange = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, per_page: event.target.value })
  }

  return (
    <Box className="pb-5">
      <PolicyHeader
        onClickAdd={() => {
          setIsOpen(true)
          setCurrentValue({})
        }}
        onClickExport={() => handleExportExcel('/api/social-insurance/export', filter, `${t('policy.fileNameExport')}.xlsx`)}
        onClickImport={() => setOpenImport(true)}
        permission = {dataInfoUser?.hr_permission?.policy_permission}
      />
      <Grid container className="mt-3">
        <Grid item md={9} xs={12}>
          <Paper>
            <Table
              locale={{ emptyText: <span className="error-no-data">{t('common.notData')}</span> }}
              loading={isLoading}
              columns={columns}
              dataSource={socialInsurance?.users?.data ?? []}
              className={styles.table}
              pagination={false}
            />
            {socialInsurance?.users?.total > ZERO && (
              <TablePagination
                rowsPerPageOptions={PER_PAGE_OPTION}
                component="div"
                count={socialInsurance.users.total}
                rowsPerPage={filter.per_page}
                page={filter.page}
                className="table-pagination"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            )}
          </Paper>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={styles.boxFilter}>
            <div className={styles.text}>
              <SearchComponent
                txtPlaceholder={t('common.lookingForEmployees')}
                addClass="input-search-box"
                defaultValue={filter?.keyword}
                handleSearch={(value) => setFilter({ ...filter, keyword: value })}
              />
            </div>
            <div className={styles.paddingElementTop}>
              <DateDayMonthComponent
                addClass="from-date"
                name="month"
                valueDefault={typeof filter.month === 'string' ? moment(filter.month, 'MM/YYYY') : filter.month}
                onChangeHandle={(value) => {
                  setFilter({
                    ...filter,
                    month: customDate(value, 'MM/YYYY'),
                    page: DEFAULT_PAGE_MUI,
                  })
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                placeholder={t('policy.selectMonthToTrackInsuranceStatus')}
                sizeDate="small"
              />
            </div>
            <div className={classNames(`${styles.paddingElementTop}`)}>
              <Input
                type="text"
                name="selectStatus"
                className={styles.selectStatus}
                value={
                  filter.status !== null
                    ? FILTER_STATUS_INSURANCE.find((status) => status.value === Number(filter.status))?.labelName
                    : ''
                }
                placeholder={t('policy.selectStatus')}
                readOnly
                onClick={() => setIsOpenFilter(true)}
              />
              <ModalRadio
                title={t('policy.department')}
                items={FILTER_STATUS_INSURANCE}
                modal={isOpenFilter}
                footer={t('common.search')}
                defaultValue={filter.status !== null ? Number(filter.status) : null}
                toggle={() => setIsOpenFilter(!isOpenFilter)}
                onSubmit={onSelectStatus}
              />
            </div>
            <div className={classNames(`${styles.paddingElementTop}`)}>
              <FormControl component="fieldset">
                <FormLabel
                  focused={false}
                  className={classNames(styles.text, styles.borderTop, styles.paddingElementTop)}
                >
                  {t('policy.department')}
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value={0}
                        checked={!filter?.division_id}
                        onChange={() => {
                          setFilter({ ...filter, division_id: null, page: DEFAULT_PAGE_MUI })
                          window.scrollTo({ top: 0, behavior: 'smooth' })
                        }}
                        control={
                          <Radio
                            sx={{
                              color: COLOR_CHECKBOX_NOT_ACTIVE,
                              '&.Mui-checked': {
                                color: COLOR_CHECKBOX_CUSTOM,
                              },
                            }}
                          />
                        }
                        label={t('employees.all')}
                      />

                      {socialInsurance?.divisions?.map((item, index) => (
                        <div key={index} className="division d-flex justify-content-between">
                          <FormControlLabel
                            key={index}
                            value={item?.id}
                            onChange={(e) => {
                              setFilter({
                                ...filter,
                                division_id: e.target.value,
                                page: DEFAULT_PAGE_MUI,
                              })
                              window.scrollTo({ top: 0, behavior: 'smooth' })
                            }}
                            checked={Number(item?.id) === Number(filter?.division_id)}
                            control={CONTROL_RADIO}
                            label={item?.name}
                          />
                          <div className={styles.total}>{item.filter_member}</div>
                        </div>
                      ))}
                    </RadioGroup>
                  )}
                  name="status_accept"
                  control={control}
                  defaultValue={filter?.status_accept ? filter?.status_accept : 0}
                />
              </FormControl>
            </div>
            <div className={styles.borderTop}>
              <ButtonBase
                onClick={() => {
                  setFilter(initialFilter)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                className={classNames(styles.reset, styles.borderTop)}
                size="lg"
              >
                <img src={iconLoading} alt="Reset" width="20px" className={styles.imgReset} /> &emsp;
                {t('policy.button.reset')}
              </ButtonBase>
            </div>
          </div>
        </Grid>
      </Grid>
      <UpdateOrCreateInsurancePolicy
        open={isOpen}
        formData={currentValue}
        refetch={refetch}
        toggle={toggle}
        permission={dataInfoUser?.hr_permission?.policy_permission}
      />
      <BasicSalaryChangeHistory
        title={t('policy.basicSalaryChangeHistory')}
        open={openHistorySalary}
        data={currentValue?.basic_salaries}
        toggle={() => setOpenHistorySalary(!openHistorySalary)}
      />
      <Menu
        id="long-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={openAction}
        open={Boolean(openAction)}
        PaperProps={{
          style: {
            width: '25ch',
          },
        }}
        onClose={() => setOpenAction(null)}
      >
        <MenuItem
          onClick={() => {
            setOpenHistorySalary(true)
            setOpenAction(null)
          }}
        >
          <AiFillEye />
          {t('policy.table.action.historySalary')}
        </MenuItem>
        {dataInfoUser?.hr_permission?.policy_permission === ROLE_EDIT && currentValue.is_editable ? (
          <>
            <MenuItem
              onClick={() => {
                setIsOpen(true)
                setOpenAction(null)
              }}
            >
              <HiPencil />
              {t('policy.table.action.edit')}
            </MenuItem>
            <MenuItem onClick={onHandleDelete}>
              <MdDelete />
              {t('policy.table.action.delete')}
            </MenuItem>
          </>
        ) : (
          <MenuItem
            onClick={() => {
              setIsOpen(true)
              setOpenAction(null)
            }}
          >
            <IoIosList />
            {t('policy.table.action.seeDetail')}
          </MenuItem>
        )}
      </Menu>
      <Alert toggle={() => setAlertMessage('')} isShowAlert={!!alertMessage}>
        {alertMessage}
      </Alert>
      <ImportModal refetch={refetch} open={openImport} onClose={() => setOpenImport(!openImport)} />
    </Box>
  )
}

export default InsurancePolicy
