export const STATUS_OT_APPROVED = 1;
export const STATUS_OT_PEDDING = 2;
export const STATUS_OT_REFUSE = 3;

export const STATUS_REQUEST_APPROVED = 1;
export const STATUS_REQUEST_PEDDING = 2;
export const STATUS_REQUEST_REFUSE = 3;

export const STATUS_OT_DISPOSED = 1;
export const STATUS_OT_IN_PROCESS = 2;
export const STATUS_INDIVIDUAL_PROCESSED = 1;
export const STATUS_INDIVIDUAL_PROCESSING = 2;

export const TAB_DASHBOARD = 0;
export const TAB_TIMEKEEPING = 1;
export const TAB_REQUEST = 2;
export const TAB_OT_TABLE = 3;

export const USE_TOTAL_TIMEKEEPING_REQUEST = 'USE_TOTAL_TIMEKEEPING_REQUEST';
export const USE_TIMEKEEPING_REQUEST = 'USE_TIMEKEEPING_REQUEST';
export const USE_TYPE_REQUEST = 'USE_TYPE_REQUEST';
export const USE_TYPE_REQUEST_FILTER = 'USE_TYPE_REQUEST_FILTER';
export const USE_GET_CATEGORY_ID = 'USE_GET_CATEGORY_ID';
export const USE_LIST_TYPE_REQUEST_BY_CATEGORY_HR = 'USE_LIST_TYPE_REQUEST_BY_CATEGORY_HR';
export const USE_LIST_OT = 'USE_LIST_OT';
export const USE_LIST_PROJECTS = 'USE_LIST_PROJECTS';
export const USE_LIST_OT_DEMO = 'USE_LIST_OT_DEMO';
export const USE_LIST_DIVISION_TIMEKEEPING_DASHBOARD = 'USE_LIST_DIVISION_TIMEKEEPING_DASHBOARD';


export const STATUS_REQUEST_OPEN = 1;
export const STATUS_REQUEST_IN_PROGRESS = 2;
export const STATUS_REQUEST_REWORK = 3;
export const STATUS_REQUEST_COMPLETED = 4;
export const STATUS_REQUEST_RECALL = 6;
export const STATUS_REQUEST_DENY = 7;
export const ID_MAPPING_REQUEST = 7;

export const EXPORT_OT_GLOBAL = 2;
export const EXPORT_OT_DETAIL = 1;
export const EXPORT_OT_ALL = 3;
export const LENGTH_OF_LIST_OT_EXPORT = 5;

export const TAB_EXPORT_GLOBAL = 0;
export const TAB_EXPORT_DETAIL = 1;

export const LENGTH_OF_DATA_NULL = 0;
