/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import radio from 'assets/img/icons/radio.png';
import radioChecked from 'assets/img/icons/radio-checked.png';
import { USE_TYPE_GROUP } from 'constants/MasterData';
import { confirmAlert } from 'react-confirm-alert';
import './style.scss';

const CreateGroupAccount = (props) => {
  let { t } = useTranslation();
  const { type } = props;
  const handleRedirect = {
    filter: '',
    custom: '',
  };

  const notification = (typeNavLink) => {
    if (type !== typeNavLink) {
      confirmAlert({
        title: t('common.TheDataYouJustInstalledWillBeLostAreYouSureYouWantToChangeIt?'),
        buttons: [
          {
            label: t('common.comeBack'),
            className: 'btn-alert-cancel',
          },
          {
            label: t('common.change'),
            className: 'btn-alert-ok',
            onClick: () => {
              if (typeNavLink === USE_TYPE_GROUP.filter) {
                handleRedirect?.filter?.click();
              } else {
                handleRedirect?.custom?.click();
              }
            },
          },
        ],
      });
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="master-data-create-group-account">
      <NavLink
        to={{
          pathname: '/admin/master-data/create-filter/create',
        }}
        ref={(input) => {
          handleRedirect.filter = input;
        }}
      ></NavLink>
      <NavLink
        to={{
          pathname: '/admin/master-data/create-custom/create',
        }}
        ref={(input) => {
          handleRedirect.custom = input;
        }}
      ></NavLink>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" className="bread-crumbs">
          <NavLink to={{ pathname: '/admin/master-data/group-account' }}>{t('masterData.groupAccount')}</NavLink>
          <Typography color="text.primary" className="page-current">
            {props.detailGroupAccountFilter?.data?.name || t("common.create")}
          </Typography>
        </Breadcrumbs>
      </div>
      <div className="filter-custom">
        <div onClick={() => notification(USE_TYPE_GROUP.filter)}>
          <img className="radio" alt="radio" src={type === USE_TYPE_GROUP.filter ? radioChecked : radio} />
          <span className="create-filter">{t('masterData.groupAccountList.filter')}</span>
        </div>
        <div onClick={() => notification(USE_TYPE_GROUP.custom)}>
          <img alt="radio" className="radio" src={type === USE_TYPE_GROUP.custom ? radioChecked : radio} />
          <span className="custom">{t('masterData.groupAccountList.custom')}</span>
        </div>
      </div>
    </Box>
  );
};

export default React.memo(CreateGroupAccount);
