const Chart = require('chart.js');

// Parse global options
function parseOptions(parent, options) {
  for (var item in options) {
    if (typeof options[item] !== 'object') {
      parent[item] = options[item];
    } else {
      parseOptions(parent[item], options[item]);
    }
  }
}

Chart.pluginService.register({
  beforeDraw: function (chart) {
    if (chart.config.options.elements.center) {
      var width = chart.chart.width,
        height = chart.chart.height,
        ctx = chart.chart.ctx;
      ctx.restore();
      var fontSize = (height / 80).toFixed(2);
      ctx.font = fontSize + 'em sans-serif';
      ctx.fillStyle = '#BDBDBD';
      ctx.textBaseline = 'middle';
      var text = chart.config.options.elements.center.text,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  },
});

function gradientLine(canvas, label, data) {
  const ctx = canvas.getContext('2d');
  let gradient = ctx.createLinearGradient(0, 0, 0, 150);
  gradient.addColorStop(0, 'rgba(131, 207, 255, 1)');
  gradient.addColorStop(1, 'rgba(131, 207, 255, 0.308)');

  const result = {
    labels: label,
    datasets: [
      {
        backgroundColor: gradient,
        pointBackgroundColor: '#2D99FF',
        data: data,
      },
    ],
  };
  return result;
}

let optionsLine = {
  options: {
    events: [],
    global: {
      responsive: true,
      maintainAspectRatio: false,
      defaultColor: 'red',
      defaultFontColor: 'blue',
      defaultFontSize: 13,
      layout: {
        padding: 0,
      },
    },
    legend: { display: false },
    animation: {
      duration: 1,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;

        ctx.font = Chart.helpers.fontString(
          Chart.defaults.global.defaultFontSize,
          Chart.defaults.global.defaultFontStyle,
          Chart.defaults.global.defaultFontFamily,
        );
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        ctx.fillStyle = '#223354';

        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 20,
    },
    elements: {
      line: {
        tension: 0.4,
        borderWidth: 4,
        borderColor: '#2D99FF',
      },
      point: {
        borderColor: 'white',
        borderWidth: 2,
        radius: 5,
      },
    },
  },
};

let optionDoughnut = {
  options: {
    maintainAspectRatio: false,
    legend: {
      labels: {
        fontSize: 18,
      },
      display: false,
    },
    title: {
      display: false,
    },
    cutoutPercentage: 60,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          let total = 0;
          data.datasets[0].data.forEach((num) => {
            total += Number(num);
          });
          const currentValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const percentage = ((currentValue * 100) / total).toFixed(1) + '%';
          return `${currentValue} (${percentage})`;
        },
        title: (tooltipItems) => {
          return tooltipItems[0].label;
        },
      },
      backgroundColor: '#F3F7FB',
      borderColor: '#F3F7FB',
      borderWidth: 1,
      titleFontColor: 'black',
      titleFontStyle: 'bold',
      displayColors: false,
      bodyFontColor: 'black',
    },
  },
};

let optionDoughnut2 = {
  options: {
    legend: { display: false },
    title: { display: false },
    responsive: true,
    cutoutPercentage: 60,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          let total = 0;
          data.datasets[0].data.forEach((num) => {
            total += Number(num);
          });
          const currentValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const percentage = ((currentValue * 100) / total).toFixed(1) + '%';
          return `${currentValue} (${percentage})`;
        },
        title: (tooltipItems) => {
          return tooltipItems[0].label;
        },
      },
      backgroundColor: '#F3F7FB',
      borderColor: '#F3F7FB',
      borderWidth: 1,
      titleFontColor: 'black',
      titleFontStyle: 'bold',
      displayColors: false,
      bodyFontColor: 'black',
    },
  },
};

module.exports = {
  parseOptions,
  gradientLine,
  optionsLine,
  optionDoughnut,
  optionDoughnut2,
};
