import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import SideNavBar from 'components/SideNavBar';
import { DEPARTMENT_DIAGRAM } from 'constants/Department';
import { SKILL_DIAGRAM } from 'constants/Department';

const Nav = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory();
  const [value, setValue] = useState(DEPARTMENT_DIAGRAM);

  const routes = [
    {
      name: t('diagram.departmentDiagram'),
    },
    {
      name: t('diagram.skillDiagram'),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === DEPARTMENT_DIAGRAM) {
      history.push('/admin/departmenthandle');
    }
    if (newValue === SKILL_DIAGRAM) {
      history.push('/admin/skill-diagram');
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/admin/departmenthandle':
        setValue(DEPARTMENT_DIAGRAM);
        break;
      case '/admin/skill-diagram':
        setValue(SKILL_DIAGRAM);
        break;

      default:
        break;
    }
  }, [location.pathname]);

  return <SideNavBar routes={routes} onChange={handleChange} value={value} />;
};

export default Nav;
