import { sendGet, sendCustom, sendPost, sendDelete, sendPut } from '../helpers/axios';
import { sendGet as sendGetAms } from '../helpers/axiosAms';

export const apiDiagramDepartment = (params) => sendGet('/api/departmental', params);
export const apiDetailDepartment = (divisionId, params) => sendGet(`/api/division/${divisionId}`, params);
export const apiExportProjectsDivision = (data) =>
  sendCustom({
    url: '/api/division/project/export/excel',
    method: 'GET',
    responseType: 'blob',
    Accept: 'application/octet-stream',
    params: data,
  });

export const apiGetCompany = (params) => sendGet('/api/branch/company', params);
export const apiCreateBranch = (params) => sendPost('/api/branch', params);
export const apiUpdateBranch = (branchID, params) => sendPut(`/api/branch/${branchID}`, params);
export const apiDeleteBranch = (branchID) => sendDelete(`/api/branch/${branchID}`);

export const apiGetDivisionTeam = (params) => sendGet('/api/branch-division', params);
export const apiCreateTeam = (params) => sendPost('/api/team', params);
export const apiUpdateTeam = (teamID, params) => sendPut(`/api/team/${teamID}`, params);
export const apiDeleteTeam = (teamID) => sendDelete(`/api/delete-team/${teamID}`);

export const apiDeleteDivision = (idDivision) => sendDelete(`/api/division/${idDivision}`);
export const apiCreateDivision = (params) => sendPost('api/division', params);
export const apiCompanyMember = (params) => sendGet('api/company-member-without-leader', params);
export const apiUpdateDivision = (idDivision, params) => sendPut(`api/division/${idDivision}`, params);
export const apiGetDivision = (idDivision) => sendGet(`/api/division/${idDivision}`);
export const apiUpdateLeaderDivision = (userId, params) => sendPut(`api/division/leader-division/${userId}`, params);
export const apiUpdateRoleNameDivision = (userId, params) =>
  sendPut(`api/division/role-name-division/${userId}`, params);
export const apiDeleteRoleNameDivision = (userId) => sendPut(`api/division/delete-role-name-division/${userId}`);
export const apiGetProjectsDivision = (params) => sendGetAms('api/projects', params);
export const apiUploadAvatarDivision = (division_id, params) =>
  sendPost(`api/division/division-avatar/${division_id}`, params);

export const getSkillDiagram = (params) => sendGet('/api/diagram-skill', params);

export const apiOtherMember = (params) => sendGet(`/api/division/member-other/${params?.departmentId}`);
export const apiDetailTeam = (params) => sendGet('/api/get-user-team', params);
export const apiUpdateLeaderTeam = (teamId, params) => sendPut(`api/update/leader-team/${teamId}`, params);
export const apiUpdateRolesTeam = (teamId, params) => sendPut(`api/update/role-name/${teamId}`, params);
export const apiDeleteRoleName = (userId) => sendPut(`api/delete/role-name/${userId}`);
export const apiSearchEmployeeByDivision = (params) => sendGet(`api/get-user-division-member`, params);
