import React from 'react';
import { MdDelete } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { HTTP_INTERNAL_SERVER_ERROR, HTTP_OK, HTTP_EXPECTATION_FAILED, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import { sendDelete } from 'helpers/axios.js';

const Delete = (props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { linkDelete, keyQuery, onSetMessage, onSetShowAlert } = props;

  const alertConfirm = () => {
    confirmAlert({
      title: t('infoEmployee.youWantToDelete'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await sendDelete(linkDelete);

            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              onSetMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              onSetShowAlert(true);
            }

            if (response?.data?.status === HTTP_OK || response?.data?.status === HTTP_INTERNAL_SERVER_ERROR) {
              onSetMessage(response.data.messages);
              onSetShowAlert(true);
              if (keyQuery) queryClient.invalidateQueries(keyQuery);
            }

            if (response?.data?.status === HTTP_OK || response?.data?.status === HTTP_INTERNAL_SERVER_ERROR) {
              onSetMessage(response.data.messages);
              onSetShowAlert(true);
              if (keyQuery) queryClient.invalidateQueries(keyQuery);
            }

            if (response?.data?.status === HTTP_EXPECTATION_FAILED) {
              onSetMessage(response?.data?.messages);
              onSetShowAlert(true);
              queryClient.invalidateQueries(keyQuery);
            }
          },
        },
      ],
    });
  };

  return <MdDelete onClick={alertConfirm}></MdDelete>;
};

export default Delete;
