import $ from 'jquery';
import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Col, Row, Label } from 'reactstrap';
import { HiPencil } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import iconInfo from 'assets/img/icons/info.png';
import radio from 'assets/img/icons/radio.png';
import radioChecked from 'assets/img/icons/radio-checked.png';

import * as Constants from 'constants/ProfileEmployee.js';
import AlertComponent from 'components/Alert';
import ButtonComponent from 'components/Buttons';
import InputField from 'components/Input/InputField';
import ModalComponent from 'components/Modal';
import SelectComponent from 'components/Select/Select';
import styles from './../style.module.scss';
import './../styles.scss';
import { DATA_TYPE_NUMBER } from 'constants/index.js';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import { apiUpdateInforUser } from 'api/profileEmployee';
import { isNotNullObject, removeSpecialCharacters, DEFAULT_NOT_REQUIRED, isValidEmail } from 'helpers/index';
import { customDate } from 'helpers/formatDate';
import {
  GENDER_MALE,
  STATUS_ACTIVE,
  MARRIE,
  GENDER_FEMALE,
  UNMARRIE,
  NAME_FIELD_DIVISION,
  MAX_LENGTH_15,
  MAX_LENGTH_40,
  MAX_LENGTH_30,
  MAX_LENGTH_100,
  HR_TEAM,
  OTHER_TEAM,
  DATA_TEAM,
  NATIONALITY_VIETNAM,
  NATIONALITY_FOREIGN,
  NATIONALITY_NOT_SET
} from 'constants/ProfileEmployee.js';
import { useOptionEditProfile, useGetStatusTeamDivison } from 'hook/useProfileEmployee';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';
import { Checkbox, FormControlLabel } from '@mui/material';
import { WHEAT } from 'constants/ColorForm';
import { BG_POSITION } from 'constants/ColorMap';

const Infomation = (props) => {
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const { uProfileUser, contractNow } = props;
  const { t } = useTranslation();
  const [statusTeam, setStatusTeam] = useState({ division_id: '' });
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const [textModal] = useState({
    titleEdit: t('infoEmployee.modalTitleInforEdit'),
  });

  const hrTeam = [
    {
      team_id: DEFAULT_NOT_REQUIRED,
      team_name: t('employees.hrTeam'),
    },
  ];

  const otherTeam = [
    {
      team_id: DEFAULT_NOT_REQUIRED,
      team_name: t('employees.otherTeam'),
    },
  ];

  const nationalityLabel = (nationality) => {
    switch (nationality) {
      case NATIONALITY_VIETNAM:
        return (<>{t('infoEmployee.nationalityVietnam')}</>);
      case NATIONALITY_FOREIGN:
        return (<>{t('infoEmployee.nationalityForeign')}</>);  
      default:
        return (<></>);  
    }
  }
    

  const { data: uOptionEditProfile } = useOptionEditProfile();
  const { data: uStatusTeamDivision } = useGetStatusTeamDivison(statusTeam);

  const [dataTeam, setDataTeam] = useState([]);
  const [formSubmit, setFormSubmit] = useState({});
  const [dateBirthDay, setDateBirthDay] = useState('');
  const [dateRotation, setDateRotation] = useState('');
  const [message, setMessage] = useState('');
  const [focus, setFocus] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [stateButton, setStateButton] = useState(false);

  useEffect(() => {
    if (Number(uStatusTeamDivision) === HR_TEAM) {
      setDataTeam(hrTeam);
    }
    if (Number(uStatusTeamDivision) === OTHER_TEAM) {
      uOptionEditProfile?.data?.division.forEach((item) => {
        if (Number(formSubmit.division_id) === Number(item.division_id)) {
          setDataTeam([...otherTeam, ...item.team]);
        }
      });
    }
    if (Number(uStatusTeamDivision) === DATA_TEAM) {
      uOptionEditProfile?.data?.division.forEach((item) => {
        if (Number(formSubmit.division_id) === Number(item.division_id)) {
          setDataTeam(item.team);
        }
      });
    }
    // Handle Filter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uStatusTeamDivision, statusTeam]);

  useEffect(() => {
    setFormSubmit({
      name: uProfileUser?.data?.user_information?.name ? uProfileUser?.data?.user_information?.name : '',
      email: uProfileUser?.data?.user_information?.email ? uProfileUser?.data?.user_information?.email : '',
      personal_email: uProfileUser?.data?.user_information?.personal_email || '',
      nationality: uProfileUser?.data?.user_information?.nationality || NATIONALITY_NOT_SET,
      gender: uProfileUser?.data?.user_information?.gender ? uProfileUser?.data?.user_information?.gender : 0,
      tax_code: uProfileUser?.data?.user_information?.tax_code ? uProfileUser?.data?.user_information?.tax_code : '',
      social_insurance_code: uProfileUser?.data?.user_information?.social_insurance_code
        ? uProfileUser?.data?.user_information?.social_insurance_code
        : '',
      birthday: uProfileUser?.data?.user_information?.birthday
        ? moment(uProfileUser.data.user_information.birthday, 'DD/MM/YYYY')
        : '',
      phone: uProfileUser?.data?.user_information?.phone ? uProfileUser?.data?.user_information?.phone : '',
      address: uProfileUser?.data?.user_information?.address ? uProfileUser?.data?.user_information?.address : '',
      temp_address: uProfileUser?.data?.user_information?.address_temporary
        ? uProfileUser?.data?.user_information?.address_temporary
        : '',
      marital: uProfileUser?.data?.user_information?.marital ? uProfileUser?.data?.user_information?.marital : UNMARRIE,
      division_id: uProfileUser?.data.division?.division_id ? uProfileUser?.data?.division?.division_id : 0,
      position_id: uProfileUser?.data?.user_information?.position_id
        ? uProfileUser?.data?.user_information?.position_id
        : 0,
      office_id: uProfileUser?.data?.user_information?.office_id
        ? uProfileUser?.data?.user_information?.office_id
        : 0,  
      level_id: uProfileUser?.data?.user_information?.level_id ? uProfileUser?.data?.user_information?.level_id : 0,
      status: uProfileUser?.data?.user_information?.status ? uProfileUser.data.user_information.status : 0,
      team_id: uProfileUser?.data?.division?.team_id ? uProfileUser?.data?.division?.team_id : DEFAULT_NOT_REQUIRED,
      checked: null,
    });
    setStatusTeam({ division_id: uProfileUser?.data.division?.division_id });
    uProfileUser?.data?.user_information?.birthday &&
      setDateBirthDay(moment(uProfileUser.data.user_information.birthday, 'DD/MM/YYYY'));
  }, [uProfileUser, isShowModalEdit]);

  $('input[type=tel]').keydown(function () {
    return false;
  });

  const handleInput = (e, nameField) => {
    var teamID = {};
    if (
      (e.target.name === 'phone' && Number(e.target.value) !== 'NaN' && e.target.value >= 0) ||
      e.target.name !== 'phone'
    ) {
      const error = {};
      if (e.target.name === 'name') {
        e.target.value = removeSpecialCharacters(e.target.value);
        const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
        e.target.value = e.target.value.replace(regex, '');
        error.error_name = '';
      } else if (e.target.name === 'email') {
        error.error_email = '';
      } else if (e.target.name === 'personal_email') {
        error.error_personal_email = '';
      } else if (e.target.name === 'nationality') {
        error.error_nationality = '';      
      } else if (e.target.name === 'tax_code') {
        error.error_tax = '';
      } else if (e.target.name === 'social_insurance_code') {
        error.error_social_insurance_code = '';
      } else if (e.target.name === 'phone') {
        error.error_phone = '';
      } else if (e.target.name === 'address') {
        error.error_permanent_address = '';
      } else if (e.target.name === 'temp_address') {
        error.error_temporary_address = '';
      } else if (e.target.name === 'marital') {
        error.error_tax = '';
      } else if (e.target.name === 'division_id') {
        error.error_division = '';
        error.error_team = '';
        error.team_id = '';
      } else if (e.target.name === 'position_id') {
        error.error_position = '';
      } else if (e.target.name === 'office_id') {
        error.error_office = '';
      } else if (e.target.name === 'level_id') {
        error.error_level = '';
      } else if (e.target.name === 'status') {
        error.error_status = '';
      } else if (e.target.name === 'team_id') {
        error.error_team = '';
      } else if (e.target.name === 'date_rotation') {
        error.error_date_rotation = '';
      }

      if (nameField === Constants.NAME_FIELD_DIVISION) {
        teamID.team_id = 0;
      }

      setFormSubmit({
        ...formSubmit,
        ...error,
        ...teamID,
        [e.target.name]: e.target.value,
      });
    }

    if (nameField === NAME_FIELD_DIVISION) {
      setFormSubmit((prev) => {
        return { ...prev, team_id: 0 };
      });
      if (!e.target.value || e.target.value === '0') {
        setDataTeam([]);
      } else {
        setStatusTeam({ division_id: e.target.value });
      }
    }
  };

  const changeRadio = (nameField, value) => {
    setFormSubmit({
      ...formSubmit,
      [nameField]: value,
    });
  };

  function validate() {
    var error = {};
    var focus = true;
    if (!formSubmit.name) {
      error.error_name = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (formSubmit.name && formSubmit.name.length > MAX_LENGTH_40) {
      error.error_name = t('common.pleaseEnterLessThanFortyCharacters');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (!formSubmit.email) {
      error.error_email = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=email]').focus();
        focus = false;
      }
    }
    
    if (!isValidEmail(formSubmit.email)) {
      error.error_email = t('common.pleaseEnterCorrectEmailFormat');
      if (focus) {
        $('input[name=email]').focus();
        focus = false;
      }
    }
    if (formSubmit.personal_email && !isValidEmail(formSubmit.personal_email)) {
      error.error_personal_email = t('common.pleaseEnterCorrectEmailFormat');
      if (focus) {
        $('input[name=personal_email]').focus();
        focus = false;
      }
    }
    if (formSubmit.tax_code && formSubmit.tax_code.length > MAX_LENGTH_30) {
      error.error_tax = t('common.pleaseEnterLessThanThirtyCharacters');
      if (focus) {
        $('input[name=tax_code]').focus();
        focus = false;
      }
    }
    if (formSubmit.social_insurance_code && formSubmit.social_insurance_code.length > MAX_LENGTH_30) {
      error.error_tax = t('common.pleaseEnterLessThanThirtyCharacters');
      if (focus) {
        $('input[name=social_insurance_code]').focus();
        focus = false;
      }
    }
    if (!dateBirthDay) {
      error.error_birthday = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=birthday]').focus();
        focus = false;
      }
    }
    if (!dateRotation && formSubmit?.is_rotation) {
      error.error_date_rotation = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=date_rotation]').focus();
        focus = false;
      }
    }
    if (!formSubmit.division_id || formSubmit.division_id === '0') {
      error.error_division = t('common.pleaseSelectField');
    }
    if (!formSubmit.team_id || formSubmit.team_id === '0') {
      error.error_team = t('common.pleaseSelectField');
    }
    if (!formSubmit.position_id || formSubmit.position_id === '0') {
      error.error_position = t('common.pleaseSelectField');
    }
    if (!formSubmit.level_id || formSubmit.level_id === '0') {
      error.error_level = t('common.pleaseSelectField');
    }
    if (!formSubmit.office_id || formSubmit.office_id === '0') {
      error.error_office = t('common.pleaseSelectField');
    }

    if (isNotNullObject(error)) {
      setFormSubmit({
        ...formSubmit,
        ...error,
      });
      return false;
    } else {
      return true;
    }
  }
  const handleSubmit = async (event) => {
    setFormSubmit({
      ...formSubmit,
      error_name: '',
      error_tax: '',
      error_birthday: '',
      error_phone: '',
      error_permanent_address: '',
      error_temporary_address: '',
      error_division: '',
      error_team: '',
      error_level: '',
      error_position: '',
      error_office: '',
      error_email: '',
      error_personal_email: '',
      error_nationality: '',
      error_status: '',
      error_date_rotation: '',
    });
    event.preventDefault();
    setStateButton(true);
    if (!validate()) {
      setStateButton(false);
      return;
    }
    const data = {
      ...formSubmit,
      name: formSubmit.name.trim(),
      team_id: formSubmit.team_id > 0 ? formSubmit.team_id : '',
      level_id: formSubmit.level_id > 0 ? formSubmit.level_id : '',
      birthday: customDate(dateBirthDay, 'DD/MM/YYYY'),
      date_rotation: customDate(dateRotation, 'YYYY-MM-DD'),
    };
    if (data.nationality === NATIONALITY_NOT_SET) {
      delete data.nationality;
    }
    let response = {};
    response = await apiUpdateInforUser(data, uProfileUser?.data?.user_information?.user_id);
    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setFormSubmit({
        ...formSubmit,
        error_name: msg?.name
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_email: msg?.email
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_personal_email: msg?.personal_email
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_nationality: msg?.nationality
          ?.map((item) => {
            return item;
          })
          .join('\n'),  
        error_tax: msg?.tax_code
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_social_insurance_code: msg?.social_insurance_code
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_birthday: msg?.birthday
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_phone: msg?.phone
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_permanent_address: msg?.address
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_temporary_address: msg?.temp_address
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_division: msg?.division
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_team: msg?.team_id
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_level: msg?.level_id
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_position: msg?.position_id
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_office: msg?.office_id
          ?.map((item) => {
            return item;
          })
          .join('\n'),  
        error_status: msg?.status
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });
      if (msg?.name) {
        $('input[name=name]').focus();
      } else if (msg?.email) {
        $('input[name=email]').focus();
      } else if (msg?.personal_email) {
        $('input[name=personal_email]').focus();
      } else if (msg?.nationality) {
        $('input[name=nationality]').focus();      
      } else if (msg?.phone) {
        $('input[name=phone]').focus();
      } else if (msg?.tax_code) {
        $('input[name=tax_code]').focus();
      } else if (msg?.social_insurance_codeelse) {
        $('input[name=social_insurance_codeelse]').focus();
      }
      if (msg?.tel) {
        $('input[name=tel]').focus();
      } else if (msg?.division_id) {
        $('input[name=division_id]').focus();
      } else if (msg?.team_id) {
        $('input[name=team_id]').focus();
      } else if (msg?.position_id) {
        $('input[name=position_id]').focus();
      } else if (msg?.birthday) {
        $('input[name=birthday]').focus();
      } else if (msg?.address) {
        $('input[name=address]').focus();
      } else if (msg?.temp_address) {
        $('input[name=temp_address]').focus();
      } else if (msg?.team_id) {
        $('input[name=team_id]').focus();
      } else if (msg?.level_id) {
        $('input[name=level_id]').focus();
      } else if (msg?.status) {
        $('input[name=status]').focus();
      }
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModalEdit(false);
      setMessage(response.data.messages);
      setShowAlert(true);
      setStateButton(false);
      await queryClient.invalidateQueries(Constants.USE_USER_PROFILE);
    }
  };

  const handleClickEdit = () => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    setFormSubmit({
      name: uProfileUser?.data?.user_information?.name ? uProfileUser?.data?.user_information?.name : '',
      email: uProfileUser?.data?.user_information?.email ? uProfileUser?.data?.user_information?.email : '',
      personal_email: uProfileUser?.data?.user_information?.personal_email || '',
      nationality: uProfileUser?.data?.user_information?.nationality || NATIONALITY_NOT_SET,
      gender: uProfileUser?.data?.user_information?.gender ? uProfileUser?.data?.user_information?.gender : 0,
      tax_code: uProfileUser?.data?.user_information?.tax_code ? uProfileUser?.data?.user_information?.tax_code : '',
      social_insurance_codeelse: uProfileUser?.data?.user_information?.social_insurance_codeelse
        ? uProfileUser?.data?.user_information?.social_insurance_codeelse
        : '',
      birthday: uProfileUser?.data?.user_information?.birthday
        ? moment(uProfileUser.data.user_information.birthday, 'DD/MM/YYYY')
        : '',
      phone: uProfileUser?.data?.user_information?.phone ? uProfileUser?.data?.user_information?.phone : '',
      address: uProfileUser?.data?.user_information?.address ? uProfileUser?.data?.user_information?.address : '',
      temp_address: uProfileUser?.data?.user_information?.address_temporary
        ? uProfileUser?.data?.user_information?.address_temporary
        : '',
      marital: uProfileUser?.data?.user_information?.marital ? uProfileUser?.data?.user_information?.marital : UNMARRIE,
      division_id: uProfileUser?.data.division?.division_id ? uProfileUser?.data?.division?.division_id : 0,
      position_id: uProfileUser?.data?.user_information?.position_id
        ? uProfileUser?.data?.user_information?.position_id
        : 0,
      office_id: uProfileUser?.data?.user_information?.office_id
        ? uProfileUser?.data?.user_information?.office_id
        : 0,  
      level_id: uProfileUser?.data?.user_information?.level_id ? uProfileUser?.data?.user_information?.level_id : 0,
      status: uProfileUser?.data?.user_information?.status ? uProfileUser.data.user_information.status : 0,
      team_id: uProfileUser?.data?.division?.team_id ? uProfileUser?.data?.division?.team_id : DEFAULT_NOT_REQUIRED,
      error_name: '',
      error_tax: '',
      error_birthday: '',
      error_phone: '',
      error_permanent_address: '',
      error_temporary_address: '',
      error_division: '',
      error_team: '',
      error_level: '',
      error_position: '',
      error_office: '',
      error_email: '',
      error_personal_email: '',
      error_nationality: '',
      error_status: '',
    });
    setShowModalEdit(true);
  };

  return (
    <>
      <div className={styles.profileBoxInfo}>
        <Row className={styles.rowTitle}>
          <Col xs="11">
            <div className={styles.profileTitle}>
              <img alt={iconInfo} src={iconInfo} className="ml-13" />
              {t('infoEmployee.titleInfo')}
            </div>
          </Col>
          <Col xs="1">
            <div
              className={classNames(
                `${dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE} ${
                  styles.editDeleteButton
                } mr--15`,
              )}
            >
              <HiPencil onClick={handleClickEdit} />
            </div>
          </Col>
        </Row>
        <Grid container spacing={2} className={styles.styleInfoMui}>
          <Grid item md={12} lg={7}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.dateOfBirth')}</div>
            <div>
              {uProfileUser?.data?.user_information?.birthday === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                uProfileUser?.data?.user_information?.birthday
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5}>
            <div className={styles.col2InfoLeft}>{t('infoEmployee.nationality')}</div>
            <div className={classNames(` auto-scroll-hidden`)}>
              {uProfileUser && uProfileUser?.data?.user_information?.nationality === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                nationalityLabel(uProfileUser?.data?.user_information?.nationality)
              )}
            </div>
          </Grid>

          <Grid item md={12} lg={7} className={styles.styleBackground}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.gender')}</div>
            <div>
              {uProfileUser && uProfileUser?.data?.user_information?.gender === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : uProfileUser && uProfileUser?.data?.user_information?.gender === GENDER_MALE ? (
                <span>{t('infoEmployee.genderMale')}</span>
              ) : (
                <span>{t('infoEmployee.genderFemale')}</span>
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5} className={styles.styleBackground}>
            <div className={styles.col2InfoLeft}>{t('infoEmployee.status')}</div>
            <div>
              {uProfileUser && uProfileUser?.data?.user_information?.status === STATUS_ACTIVE ? (
                <span>{t('infoEmployee.statusActive')}</span>
              ) : (
                <span>{t('infoEmployee.statusUnactive')}</span>
              )}
            </div>
          </Grid>

          <Grid item md={12} lg={7}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.phone')}</div>
            <div>
              {uProfileUser && uProfileUser?.data?.user_information?.phone === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                uProfileUser && uProfileUser?.data?.user_information?.phone
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5}>
            <div className={styles.col2InfoLeft}>{t('infoEmployee.groupPersonal')}</div>
            <div>
              {uProfileUser && uProfileUser?.data?.user_information?.group === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                uProfileUser?.data?.user_information?.group
              )}
            </div>
          </Grid>

          <Grid item md={12} lg={7} className={styles.styleBackground}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.married')}</div>
            <div>
              {uProfileUser && uProfileUser?.data?.user_information?.marital === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : uProfileUser && uProfileUser?.data?.user_information?.marital === MARRIE ? (
                <span>{t('infoEmployee.statusMarried')}</span>
              ) : (
                <span>{t('infoEmployee.statusMarrie')}</span>
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5} className={styles.styleBackground}>
            <div className={styles.col2InfoLeft}>{t('infoEmployee.divisionAndGroup')}</div>
            <div>
              {uProfileUser && uProfileUser?.data?.division === null ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                uProfileUser?.data?.division.division_name
              )}
              {uProfileUser && uProfileUser?.data?.division?.team_name === undefined ? (
                <span></span>
              ) : (
                <span>
                  (
                  {(uProfileUser && uProfileUser?.data?.division?.team_name) ||
                    (!uProfileUser?.data?.division.team_name &&
                      dataTeam &&
                      dataTeam
                        .filter((item) => item.team_id === DEFAULT_NOT_REQUIRED)
                        .map((item) => item.team_name)
                        .join())}
                  )
                </span>
              )}
            </div>
          </Grid>

          <Grid item md={12} lg={7}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.addressTemporary')}</div>
            <div className={styles.tableCell}>
              {uProfileUser && uProfileUser?.data?.user_information?.address_temporary ? (
                uProfileUser.data.user_information.address_temporary
              ) : (
                <>{t('infoEmployee.notUpdate')}</>
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5}>
            <div className={styles.col2InfoLeft}>{t('employees.contract')}</div>
            <div>
              {contractNow ? (
                <span>{contractNow.contract_category_name}</span>
              ) : (<span>{t('infoEmployee.notUpdate')}</span>)
              }
            </div>
          </Grid>

          <Grid item md={12} lg={7} className={styles.styleBackground}>
            <div className={styles.col1InfoLeft}>{t('infoEmployee.address')}</div>
            <div className={styles.tableCell}>
              {uProfileUser && uProfileUser?.data?.user_information?.address ? (
                uProfileUser.data.user_information.address
              ) : (
                <span>{t('infoEmployee.notUpdate')}</span>
              )}
            </div>
          </Grid>
          <Grid item md={12} lg={5} className={styles.styleBackground}>
            <div className={styles.col2InfoLeft}>{t('employees.personalEmail')}</div>
            <div>
              {uProfileUser && !uProfileUser?.data?.user_information?.personal_email ? (
                <span>{t('infoEmployee.notUpdate')}</span>
              ) : (
                uProfileUser && uProfileUser?.data?.user_information?.personal_email
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>

      <ModalComponent
        toggle={() => setShowModalEdit(!isShowModalEdit)}
        isShowModal={isShowModalEdit}
        classNameAdd="edit-infor-user"
        title={textModal.titleEdit}
      >
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div>
            <InputField
              label={t('common.fullName')}
              variant="outlined"
              name="name"
              maxLength={MAX_LENGTH_40}
              defaultValue={formSubmit.name}
              onHandleInput={handleInput}
              isRequired={true}
              error={formSubmit.error_name}
            />
          </div>
          <div className="mt-3">
            <InputField
              label={t('employees.email')}
              variant="outlined"
              name="email"
              defaultValue={formSubmit?.email}
              onHandleInput={handleInput}
              isRequired={true}
              error={formSubmit.error_email}
            />
          </div>
          <div className="mt-3">
            <InputField
              label={t('employees.personalEmail')}
              variant="outlined"
              name="personal_email"
              defaultValue={formSubmit?.personal_email}
              onHandleInput={handleInput}
              error={formSubmit.error_personal_email}
            />
          </div>
          <div className="fieldset-group mt-3">
            <Label className={classNames(`${styles.gender} color-navy mb-2`)}> {t('employees.gender')}</Label>
            <img
              className={styles.radio}
              alt="radio"
              onClick={() => changeRadio('gender', 0)}
              src={formSubmit?.gender === GENDER_MALE ? radioChecked : radio}
            />
            <Label className={styles.cusor} onClick={() => changeRadio('gender', 0)}>
              {t('infoEmployee.genderMale')}
            </Label>
            <img
              alt="radio"
              onClick={() => changeRadio('gender', 1)}
              className={`${styles.radio} ${styles.right}`}
              src={formSubmit?.gender === GENDER_FEMALE ? radioChecked : radio}
            />
            <Label className={styles.cusor} onClick={() => changeRadio('gender', 1)}>
              {t('infoEmployee.genderFemale')}
            </Label>
          </div>
          <div className="fieldset-group mt-3">
            <div className={styles.formField}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                name={'birthday'}
                className={`${styles.formInput} ${formSubmit?.error_birthday && styles.fieldError}`}
                selected={dateBirthDay && new Date(dateBirthDay)}
                onChange={(date) => {
                  setDateBirthDay(date);
                  setFormSubmit({
                    ...formSubmit,
                    error_birthday: '',
                  });
                }}
                maxDate={new Date()}
                onFocus={() => {
                  !dateBirthDay && setFocus(true);
                }}
                onBlur={() => {
                  focus && setFocus(false);
                }}
              />
              <label
                for="name"
                className={`${(dateBirthDay || focus) && styles.inputFocusCustom} ${styles.formLabel} ${
                  formSubmit?.error_birthday && styles.labelError
                }`}
              >
                {t('common.birthday') + ' *'}
              </label>
            </div>
            <p className={`error ${styles.errorBirthday}`}>{formSubmit?.error_birthday}</p>
          </div>
          <div className="mt-3">
            <InputField
              label={t('infoEmployee.phone')}
              variant="outlined"
              name="phone"
              maxLength={MAX_LENGTH_15}
              defaultValue={formSubmit?.phone}
              onHandleInput={handleInput}
              error={formSubmit.error_phone}
              dataType={DATA_TYPE_NUMBER}
            />
          </div>
          <Row className="select-field">
            <Col md={12}>
              <div className="mt-3">
                <SelectComponent
                  name="nationality"
                  valueDefault={formSubmit?.nationality}
                  onHandleChangeSelect={handleInput}
                  error={formSubmit?.error_nationality}
                  addClass={'select-field-information'}
                  label={t('infoEmployee.nationality')}
                >
                  {formSubmit?.nationality === NATIONALITY_NOT_SET ? (<MenuItem value={NATIONALITY_NOT_SET} key={NATIONALITY_NOT_SET}>{t('infoEmployee.labelSelectNationality')}</MenuItem>) : (<></>)}
                  <MenuItem value={NATIONALITY_VIETNAM} key={NATIONALITY_VIETNAM}>{t('infoEmployee.nationalityVietnam')}</MenuItem>
                  <MenuItem value={NATIONALITY_FOREIGN} key={NATIONALITY_FOREIGN}>{t('infoEmployee.nationalityForeign')}</MenuItem>
                </SelectComponent>
              </div>
            </Col>
          </Row>
          <div className="mt-3">
            <InputField
              label={t('infoEmployee.permanentAddress')}
              variant="outlined"
              name="address"
              maxLength={MAX_LENGTH_100}
              defaultValue={formSubmit?.address}
              onHandleInput={handleInput}
              error={formSubmit.error_permanent_address}
            />
          </div>
          <div className="mt-3">
            <InputField
              label={t('infoEmployee.temporaryAddress')}
              variant="outlined"
              name="temp_address"
              maxLength={MAX_LENGTH_100}
              defaultValue={formSubmit?.temp_address}
              onHandleInput={handleInput}
              error={formSubmit.error_temporary_address}
            />
          </div>
          <div className="fieldset-group mt-3">
            <Label className={classNames(`${styles.gender} color-navy mb-3`)}> {t('infoEmployee.married')}</Label>
            <img
              className={styles.radio}
              onClick={() => changeRadio('marital', 1)}
              alt="radio"
              src={formSubmit?.marital === MARRIE ? radioChecked : radio}
            />
            <Label className={styles.cusor} onClick={() => changeRadio('marital', 1)}>
              {t('infoEmployee.statusMarried')}
            </Label>
            <img
              alt="radio"
              className={`${styles.radio} ${styles.right}`}
              onClick={() => changeRadio('marital', 0)}
              src={formSubmit?.marital === UNMARRIE ? radioChecked : radio}
            />
            <Label className={styles.cusor} onClick={() => changeRadio('marital', 0)}>
              {t('infoEmployee.statusMarrie')}
            </Label>
          </div>
          <Row className="select-field">
            <Col md={6}>
              <div className="mt-3">
                <SelectComponent
                  name="division_id"
                  valueDefault={formSubmit.division_id}
                  onHandleChangeSelect={(e) => handleInput(e, NAME_FIELD_DIVISION)}
                  error={formSubmit?.error_division}
                  addClass={'select-field-information'}
                  label={t('employees.department')}
                >
                  <MenuItem value="0">{t('infoEmployee.selectDivision')}</MenuItem>
                  {uOptionEditProfile?.data?.division &&
                    uOptionEditProfile?.data?.division.map((item, index) => {
                      return (
                        <MenuItem value={item.division_id} key={index}>
                          {item.division_name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <SelectComponent
                  name="team_id"
                  valueDefault={formSubmit.team_id}
                  onHandleChangeSelect={handleInput}
                  error={formSubmit?.error_team}
                  addClass={'select-field-information'}
                  label={t('infoEmployee.subordinateGroup')}                
                >
                  <MenuItem value="0">{t('infoEmployee.selectSubordinateGroup')}</MenuItem>
                  {dataTeam &&
                    dataTeam.map((item, index) => {
                      return (
                        <MenuItem value={item.team_id} key={index}>
                          {item.team_name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
            </Col>
            <Col md={6} className={styles.rotation}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formSubmit?.is_rotation ? true : false}
                    onChange={(e) => {
                      setFormSubmit({
                        ...formSubmit,
                        is_rotation: e.target.checked ? 1 : null,
                      });
                    }}
                    sx={{
                      color: WHEAT,
                      '&.Mui-checked': {
                        color: BG_POSITION[0],
                      },
                    }}
                  />
                }
                label={t('infoEmployee.confirmedPersonnelRotation')}
              />
            </Col>
            <Col md={6}>
              <div className="fieldset-group mt-3">
                <div className={styles.formField}>
                  <DatePicker
                    readOnly={formSubmit?.is_rotation ? false : true}
                    dateFormat="dd/MM/yyyy"
                    name={'date_rotation'}
                    className={`${styles.formInput} ${formSubmit?.error_date_rotation && styles.fieldError}`}
                    selected={dateRotation && new Date(dateRotation)}
                    onChange={(date: Date) => {
                      setDateRotation(date);
                      setFormSubmit({
                        ...formSubmit,
                        error_date_rotation: '',
                      });
                    }}
                    onFocus={() => {
                      !dateRotation && setFocus(true);
                    }}
                    onBlur={() => {
                      focus && setFocus(false);
                    }}
                  />
                  <label
                    for="name"
                    className={`${(dateRotation || focus) && styles.inputFocusCustom} ${styles.formLabel} ${
                      formSubmit?.error_date_rotation && styles.labelError
                    }`}
                  >
                    {t('employees.chooseDateToRecordTheRotation')}
                    {formSubmit?.is_rotation && '*'}
                  </label>
                </div>
                <p className={`error ${styles.errorBirthday}`}>{formSubmit?.error_date_rotation}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <SelectComponent
                  name="office_id"
                  valueDefault={formSubmit.office_id}
                  onHandleChangeSelect={handleInput}
                  error={formSubmit?.error_office}
                  addClass={'select-field-information'}
                  label={t('employees.office')}
                >
                  <MenuItem value="0">{t('employees.filterOffice')}</MenuItem>
                  {uOptionEditProfile?.data?.position &&
                    uOptionEditProfile?.data?.position.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">              
                <SelectComponent
                  name="level_id"
                  valueDefault={formSubmit.level_id}
                  onHandleChangeSelect={handleInput}
                  error={formSubmit?.error_level}
                  addClass={'select-field-information'}
                  label={t('employees.level')}
                >
                  <MenuItem value={0}>{t('infoEmployee.selectLevel')}</MenuItem>
                  {uOptionEditProfile?.data?.level &&
                    uOptionEditProfile?.data?.level.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">               
                <SelectComponent
                  name="position_id"
                  valueDefault={formSubmit.position_id}
                  onHandleChangeSelect={handleInput}
                  error={formSubmit?.error_position}
                  addClass={'select-field-information'}
                  label={t('employees.position')}
                >
                  <MenuItem value="0">{t('employees.filterPosition')}</MenuItem>
                  {uOptionEditProfile?.data?.position &&
                    uOptionEditProfile?.data?.position.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={6}>
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalEdit(false)}
              />
            </Col>
            <Col md={6}>
              <ButtonComponent
                text={t('infoEmployee.saveEdit')}
                addClass="w-100"
                handleClick={() => setShowModalEdit(true)}
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>
    </>
  );
};

export default Infomation;
