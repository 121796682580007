import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect, useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import { debounce } from '@material-ui/core';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import YupValidate from './yupValidate';
import Loading from 'components/Loading';
import UploadAvatar from './UploadAvatar';
import CustomAutoComplete from 'components/InputYupAndMui/CustomAutoComplete';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import { MAX_LENGTH_30 } from 'constants/ProfileEmployee';
import ButtonComponent from 'components/Buttons/index.js';
import MenuCreateFilter from './CreateGroupAccount.js';
import { USE_TYPE_GROUP, USE_DETAIL_GROUP_ACCOUNT_FILTER } from 'constants/MasterData';
import {
  HTTP_OK,
  HTTP_NOT_ACCESS,
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_EXPECTATION_FAILED,
} from 'constants/StatusCode.js';
import AlertComponent from './../../../../components/Alert';
import {
  useListMemberCreateGroupAccountFilter,
  useListDivisionCreateGroupAccountFilter,
  useListPositionCreateGroupAccountFilter,
  useDetailGroupAccount,
} from 'hook/useMasterData.js';
import { apiAddCreateGroupAccountFilter, apiUpdateCreateGroupAccountFilter } from 'api/masterData';
import './style.scss';

const CreateGroupAccountFilter = () => {
  let { t } = useTranslation();
  const queryClient = useQueryClient();
  const params = useParams();
  let contractId = params.contractId;

  const [isShowAlert, setShowAlert] = useState(false);
  const [textMessage, setMessage] = useState('');
  const [stateButton, setStateButton] = useState(false);

  const [linkSuccess, setLinkSuccess] = useState('');

  const [filterDivision, setFilterDivision] = useState({
    key_word: '',
  });
  const [filterPosition, setFilterPosition] = useState({ division_id: '', key_word: '' });
  const [filterListMember, setFilterListMember] = useState({ division_id: [], position_id: [] });
  const [dataForm, setDataForm] = useState({ type: '1', file: '', list_user: [] });
  const [error, setError] = useState({ name: '', file: '' });

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(YupValidate()),
    mode: 'onBlur',
  });

  const [arrayDeleted, setArrayDeleted] = useState({
    arr: [],
  });

  const watchDivision = useWatch({
    control,
    name: 'division_id',
  });

  const watchPosition = useWatch({
    control,
    name: 'position_id',
  });

  useEffect(() => {
    setFilterPosition((prev) => {
      return { ...prev, division_id: watchDivision };
    });
    setFilterListMember((prev) => {
      return { ...prev, division_id: watchDivision };
    });
  }, [watchDivision]);

  useEffect(() => {
    setFilterListMember((prev) => {
      return { ...prev, position_id: watchPosition };
    });
  }, [watchPosition]);

  const addItemsDelete = (item) => {
    var newArray = arrayDeleted.arr.slice();
    newArray.push(item);
    setArrayDeleted({ arr: newArray });
  };

  const { data: detailGroupAccountFilter, isLoading: isLoadingDetailGroupAccountFilter } =
    useDetailGroupAccount(contractId);

  const { data: listMemberCreateGroupAccountFilter, isLoading: isLoadingListMemberCreateGroupAccountFilter } =
    useListMemberCreateGroupAccountFilter(filterListMember, isLoadingDetailGroupAccountFilter);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchDivision = useCallback(
    debounce((value) => {
      setFilterDivision({ ...filterDivision, key_word: value });
    }, 1000),
  );
  // Handle case filter all user when list user search change
  useEffect(() => {
    setFilterDivision({
      ...filterDivision,
      key_word: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDivision]);

  const { data: listDivisionCreateGroupAccountFilter, isLoading: isLoadingListDivisionCreateGroupAccountFilter } =
    useListDivisionCreateGroupAccountFilter(filterDivision);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchPosition = useCallback(
    debounce((value) => {
      setFilterPosition({ ...filterPosition, key_word: value });
    }, 1000),
  );
  // Handle case filter all user when list user search change
  useEffect(() => {
    setFilterPosition({
      ...filterPosition,
      division_id: watchDivision,
      key_word: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPosition]);

  const { data: listPositionCreateGroupAccountFilter, isLoading: isLoadingListPositionCreateGroupAccountFilter } =
    useListPositionCreateGroupAccountFilter(filterPosition, isLoadingDetailGroupAccountFilter);

  useEffect(() => {
    if (detailGroupAccountFilter?.data) {
      const position = [];
      const division = [];
      detailGroupAccountFilter?.data?.position_id &&
        detailGroupAccountFilter?.data?.position_id.forEach((item) => {
          position.push(item.id);
        });
      detailGroupAccountFilter?.data?.division_id &&
        detailGroupAccountFilter?.data?.division_id.forEach((item) => {
          division.push(item.id);
        });
      setDataForm((prev) => {
        let data = [];
        detailGroupAccountFilter?.data?.list_user.forEach((item) => {
          data.push(item.user_id);
        });
        return {
          ...prev,
          list_user: data,
        };
      });
      setFilterListMember(() => {
        return {
          position_id: position,
          division_id: division,
        };
      });
      setFilterPosition((prev) => {
        return {
          ...prev,
          position_id: detailGroupAccountFilter?.data?.position_id ? detailGroupAccountFilter?.data?.position_id : [],
        };
      });
    }
  }, [detailGroupAccountFilter]);

  const handleUserInActive = (idUser) => {
    let data = [];
    if (dataForm.list_user.indexOf(idUser) >= 0) {
      dataForm.list_user.forEach((id) => {
        if (id !== idUser) {
          data.push(id);
        }
      });
    } else {
      dataForm.list_user.push(idUser);
      data = dataForm.list_user;
    }
    setDataForm({ ...dataForm, list_user: data });
  };

  const handleUploadImg = (avatar) => {
    setDataForm({ ...dataForm, file: avatar });
  };

  const onSubmit = async (data) => {
    setError({ name: '', file: '' });
    setStateButton(true);

    const fb = new FormData();
    fb.append('type', dataForm?.type);
    if (dataForm?.file[0]) {
      fb.append('file', dataForm?.file[0] ? dataForm?.file[0] : '');
    }

    dataForm?.list_user.forEach((item) => {
      listMemberCreateGroupAccountFilter.forEach((it) => {
        if (Number(it.user_id) === Number(item)) {
          fb.append('list_user[]', item);
        }
      });
    });

    data?.position_id.forEach((item) => {
      fb.append('position_id[]', item);
    });

    data?.division_id.forEach((item) => {
      fb.append('division_id[]', item);
    });

    fb.append('name', data?.name);
    let response = {};

    if (detailGroupAccountFilter) {
      response = await apiUpdateCreateGroupAccountFilter(contractId, fb);
    } else {
      response = await apiAddCreateGroupAccountFilter(fb);
    }

    if (
      (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY ||
        response?.data?.status === HTTP_INTERNAL_SERVER_ERROR ||
        response?.data?.status === HTTP_EXPECTATION_FAILED) &&
      response?.data?.messages
    ) {
      if (response.data.messages?.name) {
        setError({ ...error, name: response.data.messages?.name[0] });
        $('input[name=name]').focus();
      }
      if (response.data.messages?.file) {
        setError({ ...error, file: response.data.messages?.file[0] });
      }
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setLinkSuccess('/admin/master-data/group-account');
      setMessage(response.data.messages);
      setShowAlert(true);
      setStateButton(false);
      queryClient.invalidateQueries(USE_DETAIL_GROUP_ACCOUNT_FILTER);
    }
    setStateButton(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="master-data-create-filter">
      <MenuCreateFilter type={USE_TYPE_GROUP.filter} detailGroupAccountFilter={detailGroupAccountFilter} />
      {isLoadingDetailGroupAccountFilter && contractId && <Loading addClass="mt-2 loading-custom" />}
      {!isLoadingDetailGroupAccountFilter && (
        <>
          <div className="save">
            <ButtonComponent
              text={t('common.save')}
              addClass="w-100"
              handleClick={handleSubmit(onSubmit)}
              type="submit"
              hasDisabled={stateButton}
              isLoading={stateButton}
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className="filter">
                <h1>{t('masterData.groupAccountList.inforGroup')}</h1>
                <UploadAvatar
                  dataImage={detailGroupAccountFilter?.data?.avatar ? [detailGroupAccountFilter?.data?.avatar] : ['']}
                  onChangeImage={handleUploadImg}
                />
                <p className="error images">{error.file && error.file}</p>
                <div className="mt-2 mb-4 field-name">
                  <CustomInputYup
                    placeholder={t('masterData.groupAccountList.nameGroup')}
                    label={t('masterData.groupAccountList.nameGroup')}
                    name="name"
                    control={control}
                    register={register}
                    errors={errors}
                    useWatch={useWatch}
                    required
                    setValue={setValue}
                    maxLength={MAX_LENGTH_30}
                    defaultValue={detailGroupAccountFilter?.data?.name && detailGroupAccountFilter?.data?.name}
                  />
                  <p className="error">{error.name && error.name}</p>
                </div>
                <div className="mt-3 mb-3 division">
                  <CustomAutoComplete
                    valueAutoFill={listDivisionCreateGroupAccountFilter}
                    placeholder={t('masterData.groupAccountList.department')}
                    label={t('masterData.groupAccountList.department')}
                    name="division_id"
                    control={control}
                    register={register}
                    errors={errors}
                    useWatch={useWatch}
                    setValue={setValue}
                    addItemsDelete={addItemsDelete}
                    defaultValue={
                      detailGroupAccountFilter?.data?.division_id ? detailGroupAccountFilter?.data?.division_id : null
                    }
                    handleSearch={handleSearchDivision}
                    isLoading={isLoadingListDivisionCreateGroupAccountFilter}
                  />
                </div>
                <div className="mt-3 mb-3 position">
                  <CustomAutoComplete
                    valueAutoFill={listPositionCreateGroupAccountFilter}
                    placeholder={t('masterData.groupAccountList.position')}
                    label={t('masterData.groupAccountList.position')}
                    name="position_id"
                    control={control}
                    register={register}
                    errors={errors}
                    useWatch={useWatch}
                    setValue={setValue}
                    addItemsDelete={addItemsDelete}
                    defaultValue={
                      detailGroupAccountFilter?.data?.position_id ? detailGroupAccountFilter?.data?.position_id : null
                    }
                    handleSearch={handleSearchPosition}
                    isLoading={isLoadingListPositionCreateGroupAccountFilter}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={9}>
              <h1 className="title-list-member">{t('masterData.groupAccountList.member')}</h1>
              <TableContainer component={Paper} className="table-list">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="header">
                      <TableCell>{t('masterData.groupAccountList.name')}</TableCell>
                      <TableCell>{t('masterData.groupAccountList.email')}</TableCell>
                      <TableCell>{t('masterData.groupAccountList.position')}</TableCell>
                      <TableCell>{t('masterData.groupAccountList.division')}</TableCell>
                      <TableCell>{t('masterData.groupAccountList.dateCreate')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listMemberCreateGroupAccountFilter &&
                      !isLoadingListMemberCreateGroupAccountFilter &&
                      listMemberCreateGroupAccountFilter.map((item) => (
                        <TableRow key={item.user_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>{item.user_name}</TableCell>
                          <TableCell>{item.email}</TableCell>
                          <TableCell>{item.position_name}</TableCell>
                          <TableCell>{item.division_name}</TableCell>
                          <TableCell>{item.onboard_date}</TableCell>
                          <TableCell>
                            <div className="col-action d-flex float-right">
                              <Switch
                                checked={
                                  Array.isArray(dataForm.list_user) && dataForm.list_user.indexOf(item.user_id) < 0
                                    ? true
                                    : false
                                }
                                onChange={() => handleUserInActive(item.user_id)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    {!listMemberCreateGroupAccountFilter && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Loading addClass="mt-2" />
                        </TableCell>
                      </TableRow>
                    )}
                    {listMemberCreateGroupAccountFilter && listMemberCreateGroupAccountFilter.length < 1 && (
                      <TableRow>
                        <TableCell className="error-no-data" colSpan={5}>
                          {t('common.notData')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert} link={linkSuccess}>
            {textMessage}
          </AlertComponent>
        </>
      )}
    </form>
  );
};

export default CreateGroupAccountFilter;
