import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';

import styles from './styles.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const AlertComponent = (props) => {
  const { children, toggle, isShowAlert, classNameAdd, link } = props;

  return (
    <>
      <Dialog
        open={isShowAlert}
        onClose={toggle}
        TransitionComponent={Transition}
        keepMounted
        className={classNames(`${styles.modalAlert} ${classNameAdd && classNameAdd}`)}
      >
        <div className="modal-body text-center">{children}</div>
        <div className="modal-footer">
          {link && (
            <NavLink
              to={{
                pathname: link,
              }}
            >
              <Button data-dismiss="modal" type="button" onClick={toggle}>
                Done
              </Button>
            </NavLink>
          )}
          {!link && (
            <Button data-dismiss="modal" type="button" onClick={toggle}>
              Done
            </Button>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default AlertComponent;
