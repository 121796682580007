import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import iconStatistical from 'assets/img/icons/timekeeping/statistical.svg';
import iconTime from 'assets/img/icons/timekeeping/time.svg';
import iconTimeOT from 'assets/img/icons/timekeeping/time_ot.svg';

import DateMonthComponent from 'components/DateTime/DateMonth.js';
import DateYearComponent from 'components/DateTime/Year.js';
import SelectComponent from 'components/Select/Select';
import Loading from 'components/Loading';

import TimeKeeping from './../index.js';
import styles from './style.module.scss';
import ChartDoughnut from './ChartDoughnut.js';

import InfoRight from './InfoRight.js';

import { useListDivision } from 'hook/useTimekeeping';
import ChartBar from './ChartBar.js';

import {
  useTotalTimekeepingYear,
  useTotalTimekeepingMonth,
  useListLateTimeAllCompany,
  useGetLateTimeOTMonthAllCompany,
  useGetOverTImeProjectMonth,
  useGetOTAllCompany,
} from 'hook/useTimeSheet.js';
import { NOT_DATA } from 'constants/index.js';
import { formatHourNumber } from 'helpers/format.js';

const DashboardTimeKeeping = ({ isDashboard }) => {
  const { t } = useTranslation();

  const [filterLateOT, setFilterLateOT] = useState({ date: moment() });
  const [filterCompany, setFilerCompany] = useState({ date: moment(), division_id: 0 });
  const { data: listDivision } = useListDivision({});

  const [listLateOTCompany, setLateOtCompany] = useState([]);
  const [listLateOTMonthCompany, setListLateOTMonthCompany] = useState([]);

  const { data: dataTotalMonth } = useTotalTimekeepingMonth({
    ...filterLateOT,
    date: filterLateOT.date.format('MM/YYYY'),
  });
  const { data: dataLateTimeOTCompany, isLoading: isLoadingLateTimeOTCompany } = useGetLateTimeOTMonthAllCompany({
    date: filterLateOT.date.format('MM/YYYY'),
  });
  const { data: dataOverTimeProject, isLoading: isDataOverTimeProject } = useGetOverTImeProjectMonth({
    date: filterLateOT.date.format('MM/YYYY'),
  });

  const { data: dataTotalYear } = useTotalTimekeepingYear({
    ...filterCompany,
    date: filterCompany.date.format('YYYY'),
  });
  const { data: dataLateTimeCompany, isLoading: isLoadingLateTimeCompany } = useListLateTimeAllCompany({
    date: filterCompany.date.format('YYYY'),
  });

  const { data: dataOTCompany, isLoading: isLoadingOTCompany } = useGetOTAllCompany({
    ...filterCompany,
    date: filterCompany.date.format('YYYY'),
  });

  const handelDivisionPosition = (event) => {
    if (event.target.value === 0) {
      setFilerCompany({ ...filterCompany, division_id: 0 });
    } else {
      setFilerCompany({ ...filterCompany, division_id: [event.target.value] });
    }
  };

  const handleDateCompany = (event) => {
    setFilerCompany({ ...filterCompany, date: moment(event) });
  };

  const handleDateMonth = (event) => {
    setFilterLateOT({ ...filterLateOT, date: moment(event) });
  };

  useEffect(() => {
    if (dataLateTimeCompany) {
      let _data = [];
      dataLateTimeCompany.forEach((item) => {
        _data.push({
          ...item,
          total_time: item?.late_time,
          money: item?.fines,
        });
      });
      setLateOtCompany(_data);
    } else {
      setLateOtCompany([]);
    }
  }, [dataLateTimeCompany]);

  useEffect(() => {
    if (dataLateTimeOTCompany) {
      setListLateOTMonthCompany(dataLateTimeOTCompany);
    } else {
      setListLateOTMonthCompany([]);
    }
  }, [dataLateTimeOTCompany]);

  return (
    <div className="scroll ct-form-controller-filter mb-3">
      {!isDashboard && (
        <div className="nav-fixed pt-0">
          <TimeKeeping />
        </div>
      )}

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {!isDashboard && (
            <Grid item xs={12}>
              <div className={styles.filterDate}>
                <p>
                  {t('common.month')} {filterLateOT.date.format('MM/YYYY')}
                </p>
                <div className="w-170">
                  <DateMonthComponent
                    label=""
                    name="date"
                    valueDefault={filterLateOT.date}
                    sizeDate="small"
                    onChangeHandle={handleDateMonth}
                  />
                </div>
              </div>
            </Grid>
          )}

          <Grid item lg={7} md={12} sm={12}>
            <Box className={classNames(`border-show pd-15 h-100 ${styles.boxStatisticTimeLastOT}`)}>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="d-flex">
                          <img src={iconStatistical} alt={iconStatistical} className="icon" />
                          <h4 className="title-body">{t('timekeeping.dashboard.statisticsOfTardyHoursAndOT')}</h4>
                        </div>

                        {isDashboard && (
                          <div className="w-170 ml-4">
                            <DateMonthComponent
                              label=""
                              name="input_date"
                              valueDefault={filterLateOT.date}
                              onChangeHandle={handleDateMonth}
                              sizeDate="small"
                            />
                          </div>
                        )}
                      </div>

                      <div className="text-right d-flex">
                        <div className={styles.bgTimeLate}>
                          <p></p> {t('timekeeping.dashboard.approvedLateHours')}
                        </div>
                        <div className={styles.bgTimeLateUnApprove}>
                          <p></p> {t('timekeeping.dashboard.unApproveLateHours')}
                        </div>
                        <div className={styles.bgTimeOT}>
                          <p></p> {t('timekeeping.dashboard.OT')}
                        </div>
                      </div>
                    </div>
                    <p className={styles.hoursTitle}>{t('timekeeping.hours')}</p>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.info}>
                    {isLoadingLateTimeOTCompany ? (
                      <div className={styles.loadingLateOTMonth}>
                        <Loading />
                      </div>
                    ) : listLateOTMonthCompany ? (
                      <ChartBar data={listLateOTMonthCompany} />
                    ) : (
                      <div className={styles.errorNoData}>{t('common.notData')}</div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {' '}
                  <p className={styles.divisionTitle}>{t('division.Division')}</p>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item lg={2} md={6} sm={6}>
            <Box className={classNames(`border-show pd-15 h-100 ${styles.boxRatingTimeOt}`)}>
              <Grid item xs={12}>
                <div className="">
                  <div className="d-flex">
                    <h4 className="title-body">{t('timekeeping.dashboard.timeRatingOTOfTheProject')}</h4>
                  </div>
                  <div className="body-info">
                    <ul className={styles.ratingTimeOt}>
                      {isDataOverTimeProject ? (
                        <li>
                          <Loading addClass="mt-5" />
                        </li>
                      ) : dataOverTimeProject?.length ? (
                        <>
                          {dataOverTimeProject.map((item) => (
                            <li className="d-flex justify-content-between" key={item.project_id}>
                              <p className={styles.project}>{item.name}</p>
                              <p className={styles.time}>{formatHourNumber(item?.over_times)}h</p>
                            </li>
                          ))}
                        </>
                      ) : (
                        <li className="error fs-14 text-center">{t('timekeeping.dashboard.noProjectsOT')}</li>
                      )}
                    </ul>
                  </div>
                </div>
              </Grid>
            </Box>
          </Grid>

          <Grid item lg={3} md={6} sm={6}>
            <InfoRight data={dataTotalMonth} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classNames(`mt-4 ${styles.filterDate}`)}>
              <p>
                {filterCompany.date.format('YYYY')} {t('timekeeping.dashboard.allCompany')}
              </p>
              <div className="w-170">
                <DateYearComponent
                  label=""
                  name="date"
                  sizeDate="small"
                  valueDefault={filterCompany.date}
                  onChangeHandle={handleDateCompany}
                />
              </div>
            </div>
          </Grid>
          <Grid item lg={9} md={9} sm={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item lg={6} md={6} sm={12}>
                <Box className={classNames(`border-show pd-15 ${styles.boxChartDoughnut} h-100`)}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <div className="body-top">
                        <div className="d-flex">
                          <img src={iconTime} alt={iconTime} className="icon" />
                          <h4 className="title-body">{t('timekeeping.dashboard.numberOfMinutesLateByDepartment')}</h4>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        {isLoadingLateTimeCompany ? (
                          <Loading />
                        ) : (
                          <ChartDoughnut
                            data={listLateOTCompany.map((item) => item).filter((it) => it.total_time > NOT_DATA)}
                            unit="m"
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <Box className={classNames(`border-show pd-15 ${styles.boxChartDoughnut} h-100`)}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <div className="body-top">
                        <div className="d-flex">
                          <img src={iconTimeOT} alt={iconTimeOT} className="icon" />
                          <h4 className="title-body">{t('timekeeping.dashboard.numberOfOTHoursByDepartment')}</h4>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <div>
                          {isLoadingOTCompany ? (
                            <Loading />
                          ) : (
                            <ChartDoughnut
                              data={dataOTCompany?.map((item) => item).filter((it) => it.total_time > NOT_DATA)}
                              unit="h"
                            />
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} md={3} sm={12}>
            <Box className="h-100 d-flex flex-column justify-content-between">
              <SelectComponent
                name="division_id"
                valueDefault={filterCompany.division_id}
                addClass="w-100 mb-3"
                onHandleChangeSelect={handelDivisionPosition}
              >
                <MenuItem value={0}>{t('common.all')}</MenuItem>
                {listDivision &&
                  listDivision?.divisions?.map((division, key) => (
                    <MenuItem key={key} value={division.id}>
                      {division.name}
                    </MenuItem>
                  ))}
              </SelectComponent>
              <InfoRight data={dataTotalYear} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default DashboardTimeKeeping;
