import { useQuery } from 'react-query';

import { HTTP_OK } from 'constants/StatusCode';
import { USE_LIST_DIVISION } from './../constants/ListEmployees';
import {
  apiGetMoneyLate,
  apiListWarehouse,
  apiCategoryListContract,
  apiCategoryListPosition,
  apiListCategoryEmployees,
  apiGetSkill,
  apiGetAssetCategory,
  apiListSchedule,
  apiShowBlockTime,
  apiGetCategoryCertificate,
  apiGetCertificate,
  apiGetHoliday,
  apiListOverTime,
  apiGetTimeSpecialKeeping,
  apiGetUserPositionContract,
  apiGetGroupAccount,
  apiGetMemberCreateGroupAccountFilter,
  apiGetDivisionCreateGroupAccountFilter,
  apiGetPositionCreateGroupAccountFilter,
  apiGetAllDivision,
  apiGetAllDivisionPosition,
  apiGetCommunityAddMemberCustom,
  apiGetDetailGroupAccount,
  apiGetListMappingRequest,
  apiGetListWorkflow,
  apiGetListCategory,
  apiGetListRole,
  apiGetListUserDetailRole,
  apiGetDetailRole,
  apiCheckUserRole,
  apiGetBreakTime
} from './../api/masterData';
import { apiGetHrListType, apiGetHrListCategory } from './../api/masterDataBwf';
import * as Constants from './../constants/MasterData.js';
import { apiGetPosition } from 'api/employees';
import { apiGetLevel } from 'api/masterData';

export function useCheckUserRole(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_CHECK_USER_ROLE, params], async () => {
    const response = await apiCheckUserRole(params);
    return response.data;
  },
    {
      enabled: params.user_id ? true : false,
    }
  );

  return { ...response, error, isLoading };
}

export function useGetBreakTime(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_BREAK_TIME, params], async () => {
    const response = await apiGetBreakTime(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useGetDetailRole(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_DETAIL_ROLE, params], async () => {
    const response = await apiGetDetailRole(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useListUserDatailRole(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_USER_DETAIL_ROLE, params], async () => {
    const response = await apiGetListUserDetailRole(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useListRole(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_ROLE, params], async () => {
    const response = await apiGetListRole(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useGetHrListType() {
  const { data, error, isLoading } = useQuery(Constants.USE_LIST_HR_LIST_TYPE, async () => {
    const response = await apiGetHrListType();
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useGetHrListCategory() {
  const { data, error, isLoading } = useQuery(Constants.USE_LIST_HR_LIST_CATEGORY, async () => {
    const response = await apiGetHrListCategory();
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useGetMoneyLate(params) {
  const { data, error, isLoading } = useQuery(Constants.USE_MONEY_LATE, async () => {
    const response = await apiGetMoneyLate(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useListWarehouse(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_WAREHOUSE_LIST, params], async () => {
    const response = await apiListWarehouse(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useListCategoryAsset(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_CATEGORY_ASSET, params], async () => {
    const response = await apiGetAssetCategory(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useListCertificate(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_CERTIFICATE, params], async () => {
    const response = await apiGetCertificate(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useListCategoryCertificate(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_CATEGORY_CERTIFICATE, params], async () => {
    const response = await apiGetCategoryCertificate(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useListCategoryContract(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_CATEGORY_CONTRACT, params], async () => {
    const response = await apiCategoryListContract(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useListCategoryPosition(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_CATEGORY_POSITION, params], async () => {
    const response = await apiCategoryListPosition(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useListCategoryEmployees(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_CATEGORY_EMPLOYEES, params], async () => {
    const response = await apiListCategoryEmployees(params);
    return response.data;
  });
  return { ...response, error, isLoading };
}

export function useGetSkill(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_SKILL, params], async () => {
    const response = await apiGetSkill(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetPosition(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([USE_LIST_DIVISION, params], async () => {
    const response = await apiGetPosition(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListSchedule(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_SCHEDULE, params], async () => {
    const response = await apiListSchedule(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useShowBlockTime(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_BLOCK_TIME, params], async () => {
    const response = await apiShowBlockTime(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetLevel(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_LEVEL, params], async () => {
    const response = await apiGetLevel(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetHoliday(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_HOLIDAY, params], async () => {
    const response = await apiGetHoliday(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListOverTime(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_OVER_TIME, params], async () => {
    const response = await apiListOverTime(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

// Special TimeKeeping
export function useSpecialTimeKeeping(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_SPECIAL_TIME_KEEPING, params], async () => {
    const response = await apiGetTimeSpecialKeeping(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListUserPositionContract(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_USER_POSITION, params], async () => {
    const response = await apiGetUserPositionContract(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListGroupAccount(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_GROUP_ACCOUNT, params], async () => {
    const response = await apiGetGroupAccount(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListMemberCreateGroupAccountFilter(params, isLoadingDetailGroupAccountFilter) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_MEMBER_CREATE_GROUP_ACCOUNT_FILTER, params], async () => {
    if (!isLoadingDetailGroupAccountFilter) {
      const response = await apiGetMemberCreateGroupAccountFilter(params);
      return response.data;
    }
  });

  return { ...response, error, isLoading };
}

export function useListDivisionCreateGroupAccountFilter(params) {
  const { data, error, isLoading } = useQuery(
    [Constants.USE_LIST_DIVISION_CREATE_GROUP_ACCOUNT_FILTER, params],
    async () => {
      const response = await apiGetDivisionCreateGroupAccountFilter(params);
      if (response.status === HTTP_OK) {
        return response.data.data;
      }
      return null;
    },
  );

  return { data, error, isLoading };
}

export function useListPositionCreateGroupAccountFilter(params, isLoadingDetailGroupAccountFilter) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_POSITION_CREATE_GROUP_ACCOUNT_FILTER, params], async () => {
    if (!isLoadingDetailGroupAccountFilter) {
      const response = await apiGetPositionCreateGroupAccountFilter(params);
      return response.data;
    }
  });

  return { ...response, error, isLoading };
}

export function useListAllDivision(isCallApi, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_LIST_ALL_DIVISION, params],
    async () => {
      const response = await apiGetAllDivision(params);
      return response.data;
    },
    {
      enabled: isCallApi ? true : false,
    },
  );

  return { ...response, error, isLoading };
}

export function useListAllDivisionPosition(isCallApi, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_LIST_ALL_DIVISION_POSITION, params],
    async () => {
      const response = await apiGetAllDivisionPosition(params);
      return response.data;
    },
    {
      enabled: isCallApi ? true : false,
    },
  );

  return { ...response, error, isLoading };
}

export function useListCommunityAddMemberCustom(isCallApi, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_LIST_ALL_MEMBER_COMMUNITY_CUSTOM, params],
    async () => {
      const response = await apiGetCommunityAddMemberCustom(params);
      return response?.data;
    },
    {
      enabled: isCallApi ? true : false,
    },
  );

  return { ...response, error, isLoading };
}

export function useDetailGroupAccount(ID) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_DETAIL_GROUP_ACCOUNT_FILTER, ID], async () => {
    if (ID !== 'create') {
      const response = await apiGetDetailGroupAccount(ID);
      if (response.status === HTTP_OK) {
        return response;
      }
      return null;
    }
  });

  return { ...response, error, isLoading };
}

export function useListMappingRequest(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_MAPPING_REQUEST, params], async () => {
    const response = await apiGetListMappingRequest(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListWorkflow(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_WORKFLOW, params], async () => {
    const response = await apiGetListWorkflow(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListCategory(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_CATEGORY, params], async () => {
    const response = await apiGetListCategory(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}
