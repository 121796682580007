/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect, useState } from 'react';
import {
  ButtonBase,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  RadioGroup,
  TablePagination,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import AddIcon from '@mui/icons-material/Add';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { Controller, useForm, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import { debounce } from '@material-ui/core';

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import Assets from '../index';
import SearchComponent from 'components/Search';
import ButtonComponent from 'components/Buttons';
import DateDayMonthComponent from 'components/DateTime/DateMonth.js';
import * as Constants from 'constants/index.js';
import * as StatusCode from 'constants/StatusCode';
import iconLoading from 'assets/img/icons/loading.png';
import './style.scss';
import TotalIcon from 'assets/img/icons/total-icon.svg';
import HourglassIcon from 'assets/img/icons/hourglass-icon.svg';
import CheckedNavyIcon from 'assets/img/icons/checked-navy.svg';
import CustomInputDate from 'components/InputYupAndMui/CustomInputDate';
import { convertFormatTimeSend } from 'views/pages/MasterData/SpecialTimekeeping/ultil';
import { transferStatus, transferStatusToColor, transferStatusToBackgroundColor } from './ultil';
import {
  useGetGetRequestTable,
  useGetCategoryId,
  useGetTotalTimekeepingRequest,
  useGetListTypeOfCategoryHr,
} from 'hook/useTimekeeping';
import Loading from 'components/Loading';
import {
  STATUS_REQUEST_OPEN,
  STATUS_REQUEST_IN_PROGRESS,
  STATUS_REQUEST_REWORK,
  STATUS_REQUEST_COMPLETED,
  STATUS_REQUEST_RECALL,
  STATUS_REQUEST_DENY,
  ID_MAPPING_REQUEST,
} from 'constants/TimeKeeping';
import { CONTROL_RADIO } from 'components/InputYupAndMui/constant';
import { TooltipContent, StyledTooltip } from './TooltipInfo';
import IconCalendar from 'assets/img/icons/icon-calendar.svg';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import configs from 'config';
import { confirmAlert } from 'react-confirm-alert';
import { apiRecallRequest } from 'api/timesheet';
import AlertComponent from 'components/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useQueryClient } from 'react-query';
import { USE_TIMEKEEPING_REQUEST } from 'constants/TimeKeeping';
import { BiLinkExternal } from 'react-icons/bi';
import { useListAllDivision } from 'hook/useTimeSheet';
import { handleExportExcel } from 'helpers';

function RequestTimeKeeping() {
  const { t } = useTranslation();
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const queryClient = useQueryClient();
  const [listTypeOfCategory, setListTypeOfCategory] = useState([]);
  const [listDivision, setListDivision] = useState([]);
  const { data: uListDivisions } = useListAllDivision();
  const [isModals, setIsModals] = useState({ division: false, requestType: false });
  const [dataFilterBackup, setDataFilterBackup] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // eslint-disable-next-line no-unused-vars
  const [selectedValue, setSelectedValue] = useState();
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);
  const [filter, setFilter] = useState({
    key_word: null,
    category_id: null,
    type: null,
    month: moment().format('MM/YYYY'),
    start_date: null,
    end_date: null,
    status: null,
    page: Constants.DEFAULT_PAGE_MUI,
    per_page: Constants.DEFAULT_LIMIT,
  });

  useEffect(() => {
    if (uListDivisions) {
      const division = uListDivisions?.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));

      setListDivision([
        {
          id: undefined,
          name: t('contract.all'),
          status: false,
          value: Constants.ALL,
        },
        ...division,
      ]);
    }
  }, [uListDivisions, t]);

  const STATUS_REQUEST = [
    {
      value: STATUS_REQUEST_OPEN,
      labelName: t('timekeeping.request.statusOpen'),
    },
    {
      value: STATUS_REQUEST_IN_PROGRESS,
      labelName: t('timekeeping.request.statusInProgress'),
    },
    {
      value: STATUS_REQUEST_COMPLETED,
      labelName: t('timekeeping.request.statusCompleted'),
    },
    {
      value: STATUS_REQUEST_DENY,
      labelName: t('timekeeping.request.statusDeny'),
    },
    {
      value: STATUS_REQUEST_REWORK,
      labelName: t('timekeeping.request.statusRework'),
    },
    {
      value: STATUS_REQUEST_RECALL,
      labelName: t('timekeeping.request.statusRecall'),
    },
  ];

  // Watch value autocomplete user
  const watchValueEndDate = useWatch({
    control,
    name: 'end_date',
    defaultValue: null,
  });

  const { data: idCategory } = useGetCategoryId(ID_MAPPING_REQUEST);
  // Data
  const { data: uGetTotalTimekeepingRequest } = useGetTotalTimekeepingRequest({
    ...filter,
    page: null,
    per_page: null,
    category_id: idCategory?.data?.code,
  });

  const { data: uGetGetRequestTable, isLoading: isLoadingGetGetRequestTable } = useGetGetRequestTable({
    ...filter,
    page: filter?.page + 1,
    category_id: idCategory?.data?.code,
  });

  const { data: uListTypeOfCategory } = useGetListTypeOfCategoryHr({
    category_id: idCategory?.data?.code,
  });

  function handleCheck(items, index) {
    let isCheckAll = true;
    if (index === 0) {
      isCheckAll = !items[0].status;
      items.forEach((item, ind) => (ind === 0 ? (item.status = isCheckAll) : (item.status = false)));
    } else {
      items.forEach(function (item, i) {
        if (((i !== index && item.status === false) || (i === index && item.status === true)) && i !== 0) {
          isCheckAll = false;
        }
      });
      if (isCheckAll) {
        items.forEach((item, ind) => (ind === 0 ? (item.status = true) : (item.status = false)));
      } else {
        items.forEach(function (item, i) {
          if (i === 0) {
            item.status = isCheckAll;
          } else if (index === i) {
            item.status = !item.status;
          }
        });
      }
    }

    return [...items];
  }

  const changeSelectDivision = (item) => {
    setListDivision(function (list) {
      const index = list?.indexOf(item);
      return handleCheck(list, index);
    });
  };

  const changeSelectListTypeOfCategory = (item) => {
    setListTypeOfCategory(function (listTypeOfCategory) {
      const index = listTypeOfCategory.indexOf(item);
      return handleCheck(listTypeOfCategory, index);
    });
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
  };

  // Filter by who send request
  const handleSearchNameWhoSend = (newValue) => {
    setFilter({
      ...filter,
      key_word: newValue,
    });
  };

  // Filter by start time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterByStartTime = useCallback(
    debounce((newValue) => {
      setFilter({
        ...filter,
        start_date: convertFormatTimeSend(newValue, 'DD/MM/yyyy'),
      });
    }),
  );

  // Filter by end time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterByEndTime = useCallback(
    debounce((newValue) => {
      setFilter({
        ...filter,
        end_date: convertFormatTimeSend(newValue, 'DD/MM/yyyy'),
      });
    }),
  );

  // Reset
  const handleReset = () => {
    setFilter((prev) => {
      return {
        key_word: null,
        category_id: prev.category_id,
        type: null,
        month: moment().format('MM/YYYY'),
        start_date: null,
        end_date: null,
        status: null,
        page: Constants.DEFAULT_PAGE_MUI,
        per_page: Constants.DEFAULT_LIMIT,
      };
    });
    listTypeOfCategory.forEach((typeOfCategory) => (typeOfCategory.status = false));
    setValue('start_date', null);
    setValue('end_date', null);
  };

  useEffect(() => {
    if (uListTypeOfCategory) {
      const listTypeOfCategory = uListTypeOfCategory?.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));

      setListTypeOfCategory([
        {
          id: undefined,
          name: t('contract.all'),
          status: false,
          value: Constants.ALL,
        },
        ...listTypeOfCategory,
      ]);
    }
  }, [uListTypeOfCategory, t]);

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const renderMenuItem = () => {
    return (
      <>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            confirmAlert({
              title: t('messages.warningDelete'),
              message: t('messages.confirmDeleteRequest'),
              buttons: [
                {
                  label: t('common.cancel'),
                  className: 'btn-alert-cancel',
                },
                {
                  label: t('common.ok'),
                  className: 'btn-alert-ok',
                  onClick: async () => {
                    const result = await apiRecallRequest(selectedValue.id);
                    if (result) {
                      setMessage(
                        result.data.code === StatusCode.HTTP_OK
                          ? t('timekeeping.request.removeSuccessfully')
                          : result.data.message,
                      );
                      setShowAlert(true);
                      queryClient.invalidateQueries(USE_TIMEKEEPING_REQUEST);
                    }
                  },
                },
              ],
            });
          }}
        >
          <DeleteIcon />
          {t('timekeeping.request.reMove')}
        </MenuItem>
      </>
    );
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    setSelectedValue(item);
  };

  const handleExport = () => {
    handleExportExcel('/api/time-sheet/request/export', {...filter,
      page: null,
      per_page: null,
      category_id: idCategory?.data?.code}, t('timekeeping.request.exportFileName'));
  }

  return (
    <>
      <Grid container className="list-assets request-timekeeping">
        <Grid container className="nav-fixed pt-0">
          <Grid item md={6} xs={12}>
            <Assets init={6} />
          </Grid>
          <Grid item md={6} xs className="button-group">
            <a href={`${configs.DOMAIN_WORK_FOLLOW}/request`} target="blank" className="pr-2">
              <ButtonComponent
                addClass="create-request"
                icon={<AddIcon />}
                text={t('assets.assetsListRequests.createRequest')}
              />
            </a>
          
            <ButtonComponent
              addClass="create-request"
              icon={<BiLinkExternal />}
              text={t('timekeeping.request.export')}
              handleClick={handleExport}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={8} lg={9} className="asset-main">
            <div className="profile-box table-list table-border-show">
              <Paper>
                <TableContainer className="scroll-custom">
                  <Table aria-label="sticky table" className="table-scroll">
                    <TableHead className="asset-header">
                      <TableRow className="asset-header-row">
                        <TableCell>{t('timekeeping.request.STT')}</TableCell>
                        <TableCell>{t('timekeeping.request.sendBy')}</TableCell>
                        <TableCell>{t('employees.email')}</TableCell>
                        <TableCell>{t('timekeeping.request.title')}</TableCell>
                        <TableCell>{t('timekeeping.request.dayRequest')}</TableCell>
                        <TableCell>{t('timekeeping.request.status')}</TableCell>
                        <TableCell>{t('timekeeping.request.createdDay')}</TableCell>
                        <TableCell className="short"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoadingGetGetRequestTable && (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Loading addClass="mt-3 mb-3" />
                          </TableCell>
                        </TableRow>
                      )}
                      {uGetGetRequestTable &&
                        uGetGetRequestTable?.data?.map((item, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              {/* STT */}
                              <TableCell>
                                <a
                                  href={`${configs.DOMAIN_WORK_FOLLOW}/request?request_id=${item?.id}`}
                                  target="_blank"
                                  ref={(input) => {
                                    item.handelClick = input;
                                  }}
                                  className="link-work-fl"
                                >
                                  {index > 8 ? index + 1 : `0${index + 1}`}
                                </a>
                              </TableCell>
                              {/* Sending message */}
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                <span className="d-block bolder-500">
                                  {item.user_name ? item.user_name : t('common.notDataContract')}
                                </span>
                              </TableCell>
                              {/* Email */}
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                <Tooltip title={item.email}>
                                  <span className="added-info">
                                    {item.email ? item.email : t('common.notDataContract')}
                                  </span>
                                </Tooltip>
                              </TableCell>
                              {/* Title */}
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                <div>
                                  <span className="d-block bolder-500">
                                    {item.name ? item.name : t('common.notDataContract')}
                                  </span>
                                  <span className="added-info">
                                    {item.type_name ? item.type_name : t('common.notDataContract')}
                                  </span>
                                </div>
                              </TableCell>
                              {/* Day request */}
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={item?.detail && <TooltipContent item={item?.detail} />}
                                  className={item?.detail && 'border-bottom-tooltip-none'}
                                >
                                  <section className="d-flex">
                                    <div className="number-request-day">
                                      {item?.detail?.length > 1 && (
                                        <span className="number">{item?.detail?.length}</span>
                                      )}
                                      <img src={IconCalendar} alt="" />
                                    </div>
                                    <div>
                                      <span className="d-block bolder-500">
                                        {item?.detail[0] ? item?.detail[0]?.hr_date_log : t('common.notDataContract')}
                                      </span>
                                      <div className="added-info straight">
                                        {((item?.detail[0]?.hr_time_morning_log === 'Ca sáng' &&
                                          item?.detail[0]?.hr_time_afternoon_log === 'Ca chiều') ||
                                          (item?.detail[0]?.hr_time_morning_log?.length <= 8 &&
                                            item?.detail[0]?.hr_time_afternoon_log?.length <= 8)) && (
                                          <>
                                            {item?.detail[0]?.hr_time_morning_log &&
                                              (item?.detail[0]?.hr_time_morning_log === 'Ca sáng'
                                                ? item?.detail[0]?.hr_time_morning_log
                                                : item?.detail[0]?.hr_time_morning_log)}

                                            {item?.detail[0]?.hr_time_morning_log &&
                                              item?.detail[0]?.hr_time_afternoon_log && (
                                                <span className="seperate">{'-'}</span>
                                              )}
                                            <span>
                                              {item?.detail[0]?.hr_time_afternoon_log &&
                                                (item?.detail[0]?.hr_time_afternoon_log === 'Ca chiều'
                                                  ? item?.detail[0]?.hr_time_afternoon_log
                                                  : item?.detail[0]?.hr_time_afternoon_log)}
                                            </span>
                                          </>
                                        )}
                                        {item?.detail[0]?.hr_time_morning_log !== 'Ca sáng' &&
                                          item?.detail[0]?.hr_time_afternoon_log !== 'Ca chiều' &&
                                          (item?.detail[0]?.hr_time_morning_log?.length > 8 ||
                                            item?.detail[0]?.hr_time_afternoon_log?.length > 8) && (
                                            <p>
                                              {item?.detail[0]?.hr_time_morning_log ||
                                                item?.detail[0]?.hr_time_afternoon_log}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                  </section>
                                </StyledTooltip>
                              </TableCell>
                              {/* Status */}
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item.status ? (
                                  <span className={classNames(transferStatusToColor(item?.status))}>
                                    {transferStatus(item?.status)}
                                  </span>
                                ) : (
                                  t('common.notDataContract')
                                )}
                              </TableCell>
                              {/* Date */}
                              <TableCell
                                onClick={() => {
                                  item.handelClick.click();
                                }}
                              >
                                {item.date ? item.date : t('common.notDataContract')}
                              </TableCell>
                              {item?.status === STATUS_REQUEST_COMPLETED ? (
                                <TableCell>
                                  <div className="floatRight">
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls="long-menu"
                                      aria-expanded={open ? 'true' : undefined}
                                      aria-haspopup="true"
                                      onClick={(event) => handleClick(event, item)}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              ) : (
                                <TableCell></TableCell>
                              )}
                            </TableRow>
                          );
                        })}

                      {uGetGetRequestTable?.total === 0 && (
                        <tr>
                          <td colSpan={8} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {uGetGetRequestTable?.total > 0 && (
                  <TablePagination
                    rowsPerPageOptions={Constants.PER_PAGE_OPTION}
                    component="div"
                    count={uGetGetRequestTable?.total}
                    rowsPerPage={filter?.per_page}
                    page={filter?.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="table-pagination"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                  />
                )}
              </Paper>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: '220px',
                    width: '20ch',
                  },
                }}
              >
                {renderMenuItem()}
              </Menu>
            </div>
          </Grid>
          <Grid item md={4} lg={3}>
            <section className="box-filter">
              {/* Date */}
              <div className="filter-by-date">
                <DateDayMonthComponent
                  label=""
                  name="date"
                  valueDefault={typeof filter.month === 'string' ? moment(filter.month, 'MM/YYYY') : filter.month}
                  onChangeHandle={(value) => {
                    setFilter({
                      ...filter,
                      month: moment(value).format('MM/YYYY'),
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  placeholder={t('assets.dateAdded')}
                  sizeDate="small"
                  max={moment().toDate()}
                />
              </div>

              {/* Statistical */}
              <div className="statistical">
                {/* Total */}
                <div className="statistical-item special-item">
                  <div className="d-flex align-items-center">
                    <img src={TotalIcon} alt="" />
                    <span className="statistical-item-title">{t('timekeeping.request.totalRequest')}</span>
                  </div>
                  <b className="statistical-item-title">{uGetTotalTimekeepingRequest?.total_request}</b>
                </div>
                {/* Request processing */}
                <div className="statistical-item hourglass-padding">
                  <div className="d-flex align-items-center">
                    <img src={HourglassIcon} alt="" />
                    <span className="statistical-item-title">{t('timekeeping.request.numberRequestInprogress')}</span>
                  </div>
                  <b className="statistical-item-title">
                    {uGetTotalTimekeepingRequest?.request_processing +
                      uGetTotalTimekeepingRequest?.request_open +
                      uGetTotalTimekeepingRequest?.request_remake}
                  </b>
                </div>
                {/* Completed request */}
                <div className="statistical-item">
                  <div className="d-flex align-items-center">
                    <img src={CheckedNavyIcon} alt="" />
                    <span className="statistical-item-title">{t('timekeeping.request.numberRequestAccepted')}</span>
                  </div>
                  <b className="statistical-item-title">
                    {uGetTotalTimekeepingRequest?.request_completed +
                      uGetTotalTimekeepingRequest?.request_deny +
                      uGetTotalTimekeepingRequest?.request_recall}
                  </b>
                </div>
              </div>

              <div className="padding-element-filter">
                <SearchComponent
                  txtPlaceholder={t('timekeeping.request.findNameSend')}
                  addClass="input-search-box"
                  defaultValue={filter?.key_word}
                  handleSearch={handleSearchNameWhoSend}
                />
              </div>
              <div className={classNames(`text padding-element-filter`)}>
                <InputBase
                  type="text"
                  name="division_id"
                  readOnly
                  className={classNames('exampleSelectMulti', listDivision && 'text-input-dark')}
                  onClick={() => {
                    setIsModals((prev) => {
                      return { ...prev, division: true };
                    });
                    setDataFilterBackup(JSON.parse(JSON.stringify(listDivision)));
                  }}
                  value={
                    listDivision &&
                    (!listDivision[0]?.status
                      ? listDivision
                          .map((item, i) =>
                            item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                          )
                          .filter((it) => it)
                      : t('employees.all'))
                  }
                  placeholder={t('contract.contractRetire.division')}
                />
                <ModalCommon
                  items={listDivision}
                  onSubmit={() => {
                    let datas = [];
                    listDivision.forEach((it) => {
                      it.status && it.id && datas.push(it.id);
                    });
                    setFilter({
                      ...filter,
                      division_id: datas,
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    setIsModals(Object.assign({}, isModals, { division: false }));
                  }}
                  onClick={changeSelectDivision}
                  toggle={() => {
                    setIsModals(Object.assign({}, isModals, { division: !isModals.division }));
                    setListDivision([...dataFilterBackup]);
                  }}
                  modal={isModals.division}
                  title={t('timekeeping.request.type')}
                  footer={t('common.search')}
                />
              </div>
              <div className={classNames(`text padding-element-filter`)}>
                <InputBase
                  type="text"
                  name="type"
                  readOnly
                  className={classNames('exampleSelectMulti', listTypeOfCategory && 'text-input-dark')}
                  onClick={() => {
                    setIsModals((prev) => {
                      return { ...prev, requestType: true };
                    });
                    setDataFilterBackup(JSON.parse(JSON.stringify(listTypeOfCategory)));
                  }}
                  value={
                    listTypeOfCategory &&
                    (!listTypeOfCategory[0]?.status
                      ? listTypeOfCategory
                          .map((item, i) =>
                            item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                          )
                          .filter((it) => it)
                      : t('employees.all'))
                  }
                  placeholder={t('timekeeping.request.type')}
                />
                <ModalCommon
                  items={listTypeOfCategory}
                  onSubmit={() => {
                    let datas = [];
                    listTypeOfCategory.forEach((it) => {
                      it.status && it.id && datas.push(it.id);
                    });
                    setFilter({
                      ...filter,
                      type: datas,
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    setIsModals(Object.assign({}, isModals, { requestType: false }));
                  }}
                  onClick={changeSelectListTypeOfCategory}
                  toggle={() => {
                    setIsModals(Object.assign({}, isModals, { requestType: !isModals.requestType }));
                    setListTypeOfCategory([...dataFilterBackup]);
                  }}
                  modal={isModals.requestType}
                  title={t('timekeeping.request.type')}
                  footer={t('common.search')}
                />
              </div>
              {/* Date start and end */}
              <div className="d-flex padding-element-filter justify-content-between align-items-center">
                <div className="date-filter">
                  <CustomInputDate
                    control={control}
                    label={t('masterData.placeholderStartDate')}
                    defaultValue={null}
                    name="start_date"
                    register={register}
                    useWatch={useWatch}
                    setValue={setValue}
                    errors={errors}
                    max={watchValueEndDate}
                    handleChange={handleFilterByStartTime}
                  />
                </div>
                <span className="ml-1 mr-1">~</span>
                <div className="date-filter">
                  <CustomInputDate
                    control={control}
                    label={t('masterData.placeholderEndDate')}
                    defaultValue={null}
                    name="end_date"
                    register={register}
                    useWatch={useWatch}
                    setValue={setValue}
                    errors={errors}
                    min={moment(filter?.start_date, 'DD/MM/yyyy').toDate()}
                    handleChange={handleFilterByEndTime}
                  />
                </div>
              </div>

              <div>
                <FormControl component="fieldset">
                  <FormLabel className={classNames(`text padding-element-filter border-top padding-element-top`)}>
                    {t('timekeeping.request.status')}
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup {...field} value={filter?.status ? filter?.status : 0}>
                        <FormControlLabel
                          value={0}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              status: null,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={CONTROL_RADIO}
                          label={t('employees.all')}
                        />
                        {STATUS_REQUEST?.map((item, index) => (
                          <div key={index} className="division d-flex justify-content-between">
                            <FormControlLabel
                              key={index}
                              value={item?.value}
                              onChange={(e) => {
                                setFilter({
                                  ...filter,
                                  status: e.target.value,
                                  page: Constants.DEFAULT_PAGE_MUI,
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                              }}
                              control={CONTROL_RADIO}
                              label={item?.labelName}
                            />
                            <div
                              className={classNames(
                                `dot-status-accept ${transferStatusToBackgroundColor(item?.value)}`,
                              )}
                            ></div>
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                    name="status"
                    control={control}
                    defaultValue={filter?.status ? filter?.status : 0}
                  />
                </FormControl>
              </div>
              <div className="border-top">
                <ButtonBase onClick={() => handleReset()} className={classNames(`reset border-top`)} size="lg">
                  <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                  {t('common.reset')}
                </ButtonBase>
              </div>
            </section>
          </Grid>
        </Grid>
      </Grid>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
}

export default RequestTimeKeeping;
