import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FormControlLabel } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import styles from './styles.module.scss';
import { WHEAT } from 'constants/ColorForm';
import { YELLOW } from 'constants/ColorForm';

const ModalRadio = (props) => {
  const { toggle, modal, items, title, onSubmit, defaultValue } = props;
  const [valueRadio, setValueRadio] = useState();

  const handleClear = () => {
    onSubmit(defaultValue);
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={() => {
          toggle();
          handleClear();
        }}
        className={`${styles.modalCommon} modal-dialog-centered`}
      >
        <ModalHeader className={styles.modalTitle} toggle={toggle}>
          {title}
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          <RadioGroup defaultValue={defaultValue}>
            {items?.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                onChange={(e) => {
                  setValueRadio(e.target.value);
                }}
                control={
                  <Radio
                    sx={{
                      color: WHEAT,
                      '&.Mui-checked': {
                        color: YELLOW,
                      },
                    }}
                  />
                }
                label={item.labelName}
              />
            ))}
          </RadioGroup>
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button
            onClick={() => {
              toggle();
              onSubmit(valueRadio);
            }}
            className={styles.buttonSubmit}
          >
            {props.footer}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalRadio;
