import React, { useState, useContext } from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import MenuItem from '@mui/material/MenuItem';
import { HiPencil } from 'react-icons/hi';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import Box from '@mui/material/Box';
import moment from 'moment';
import $ from 'jquery';

import * as Constants from 'constants/ProfileEmployee.js';
import styles from './../style.module.scss';
import AlertComponent from 'components/Alert';
import ModalComponent from 'components/Modal';
import DateDayComponent from 'components/DateTime/DateDay.js';
import SelectComponent from 'components/Select/Select.js';
import { getTypeRelative, getGenderEmployee } from 'helpers/profileEmployee.js';
import ButtonComponent from 'components/Buttons';
import { isNotNullObject } from 'helpers/index';
import { customDate, getStartOfMonth, getEndOfMonth } from 'helpers/formatDate';
import { apiCreateRelative, apiUpdateRelative, apiDeleteRelative } from 'api/profileEmployee';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import addRelative from 'assets/img/icons/circle_add.png';
import InputField from 'components/Input/InputField';
import iconRelative from 'assets/img/icons/relative.png';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';
import { DATA_TYPE_NUMBER } from 'constants/index.js';
import { Checkbox, FormControlLabel } from '@mui/material';
import DateMonth from 'components/DateTime/DateMonth';

const Relative = (props) => {
  const { uProfileUser, userId } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [isShowModalAdd, setShowModalAdd] = useState(false);
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const emptyErrors = {
    error_name: '', 
    error_birthday: '', 
    error_gender: '', 
    error_type: '', 
    error_phone: '',
    error_is_dependent: '',
    error_dependent_periods: [],
    error_all_periods: ''
  }

  const [formAddRelative, setformAddRelative] = useState({
      name: '',
      type: '',
      gender: '',
      phone: '',
      birthday: moment(),
      user_id: userId,
      is_dependent: false,
      dependent_periods: [],
      error_name: '',
      error_birthday: '',
      error_type: '',
      error_gender: '',
      error_phone: '',
      error_is_dependent: '',
      error_dependent_periods: [],
      error_all_periods: ''
    });

  const relatives = [
    { id: Constants.TYPE_RELATIVE_FATHER, name: t('infoEmployee.father') },
    { id: Constants.TYPE_RELATIVE_MOTHER, name: t('infoEmployee.mother') },
    { id: Constants.HUSBAND, name: t('infoEmployee.hubsband') },
    { id: Constants.TYPE_RELATIVE_WIFE, name: t('infoEmployee.wife') },
    { id: Constants.TYPE_RELATIVE_CHILD, name: t('infoEmployee.childrent') },
    { id: Constants.TYPE_RELATIVE_SIBLINGS, name: t('infoEmployee.siblings') },
  ];

  const [stateButton, setStateButton] = useState(false);

  const [textModal] = useState({
    titleAdd: t('infoEmployee.modalTitleRelativeAdd'),
    titleEdit: t('infoEmployee.modalTitleRelativeEdit'),
  });

  function onclickEditRelative(relative) {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    
    setformAddRelative({
      id: relative.id,
      name: relative.name,
      type: relative.type ? relative.type : '',
      gender: relative.gender ? relative.gender : 0,
      phone: relative.phone || '',
      birthday: relative?.birthday ? moment(relative.birthday, 'DD/MM/YYYY') : '',
      user_id: userId,
      is_dependent: relative.is_dependent === 1 || false,
      dependent_periods: relative?.dependent_periods ? relative?.dependent_periods.map(
        (value) => {
          return {
            'start_date': value?.start_date ? moment(value.start_date, 'DD/MM/YYYY') : '',
            'end_date': value?.end_date ? moment(value.end_date, 'DD/MM/YYYY') : '',
          };
        }
      ): [],     
      error_name: '',
      error_birthday: '',
      error_type: '',
      error_gender: '',
      error_is_dependent: '',
      error_dependent_periods: [],
      error_all_periods: ''
    });
    setShowModalEdit(true);
  }

  function resetError() { 
    setformAddRelative(
      { ...formAddRelative, 
        ...emptyErrors
      }
    );
  }

  function validate() {
    var focus = true;
    var error = {};
    if (!formAddRelative.name) {
      error.error_name = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (formAddRelative.phone && formAddRelative.phone.length < Constants.MAX_LENGTH_10) {
      error.error_phone = t('messages.pleaseEnterPhoneAgain');
      if (focus) {
        $('input[name=phone]').focus();
        focus = false;
      }
    }
    if (!formAddRelative.type) {
      error.error_type = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=type]').focus();
        focus = false;
      }
    }
    if (formAddRelative.gender === '') {
      error.error_gender = t('common.pleaseFillOutThisField');
    }
    if (!formAddRelative.birthday) {
      error.error_birthday = t('common.pleaseFillOutThisField');
    }
    if (formAddRelative.is_dependent) {
      if (formAddRelative.dependent_periods.length === 0) {
        error.error_all_periods = t('common.pleaseFillOutThisField');
      }
      const error_dependent_periods = formAddRelative.dependent_periods.map((period, index) => {
        const result = {};
        if (period.start_date === '') {
          result.start_date = t('common.pleaseFillOutThisField');
        }
        if (period.end_date === '' && index < formAddRelative.dependent_periods.length - 1) {
          result.end_date = t('common.pleaseFillOutThisField');
        }
        return result;
      });
      if (!error_dependent_periods.every((period_error) => {
        return JSON.stringify(period_error) === '{}';
      })) {
        error.error_dependent_periods = error_dependent_periods;
      }
    }
    
    if (isNotNullObject(error)) {
      setformAddRelative({
        ...formAddRelative,
        ...emptyErrors,
        ...error,
      });
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = async (event) => {
    resetError();
    event.preventDefault();
    setStateButton(true);
    if (!validate()) {
      setStateButton(false);
      return;
    }

    let response = {};
    const data = {
      name: formAddRelative.name.trim(),
      phone: formAddRelative.phone.trim(),
      gender: formAddRelative.gender,
      birthday: customDate(formAddRelative.birthday, 'DD/MM/YYYY'),
      type: formAddRelative.type,
      user_id: userId,
      is_dependent: formAddRelative.is_dependent,
      dependent_periods: formAddRelative.is_dependent ? formAddRelative.dependent_periods.map((period) => {
        return {
          'start_date': period.start_date !== '' ? getStartOfMonth(period.start_date, 'DD/MM/YYYY') : '',
          'end_date': period.end_date !== '' ? getEndOfMonth(period.end_date, 'DD/MM/YYYY') : ''
        };
      }) : []
    };

    if (isShowModalAdd) {
      response = await apiCreateRelative(data);
    } else {
      response = await apiUpdateRelative(data, formAddRelative.id);
    }
    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setformAddRelative({
        ...formAddRelative,
        error_name: msg?.name
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_phone: msg?.phone
        ?.map((item) => {
          return item;
        })
        .join('\n'),
        error_birthday: msg?.birthday
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_type: msg?.type
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_gender: msg?.gender
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_is_dependent: msg?.is_dependent?.join('\n'),
        error_dependent_periods: formAddRelative?.dependent_periods?.map((period, index) => {
            return {
              'start_date': msg?.['dependent_periods.' + index + '.start_date']?.join('\n'),
              'end_date': msg?.['dependent_periods.' + index + '.end_date']?.join('\n')
            };
          }),
        error_all_periods: msg?.all_periods?.join('\n')
      });
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModalAdd(false);
      setShowModalEdit(false);
      setStateButton(false);
      setMessage(response.data.messages);
      setShowAlert(true);
      setformAddRelative({});
      await queryClient.invalidateQueries(Constants.USE_USER_PROFILE);
    }
    setStateButton(false);
    resetError();
  };

  const alertConfirm = (id) => {
    confirmAlert({
      title: t('infoEmployee.youWantToDelete'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteRelative({ user_id: userId }, id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(Constants.USE_USER_PROFILE);
            }
          },
        },
      ],
    });
  };
  const handleInput = (e, item) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const additionFields = {};
    const error = {};
    if (e.target.name === 'name') {
      error.error_name = '';
    } else if (e.target.name === 'type') {
      error.error_type = '';
    } else if (e.target.name === 'gender') {
      error.error_gender = '';
    } else if (e.target.phone === 'phone') {
      error.error_phone = '';
    } else if (e.target.name === 'is_dependent') {
      error.error_is_dependent = '';
      if (value && formAddRelative.dependent_periods.length === 0) {
        additionFields.dependent_periods = [
            {
              start_date: '',
              end_date: ''
            }
          ];
      }
    }
    
    setformAddRelative({
      ...formAddRelative,
      ...error,
      [e.target.name]: value,
      ...additionFields
    });
  };

  const handleDependentDate = (value, property, periodIndex, formItem) => {
    if (value) {
      const periods = formAddRelative.dependent_periods;
      const currentPeriod = formAddRelative.dependent_periods[periodIndex];
      
      const newPeriod = property === 'start_date' ? {
        'start_date': moment(value).startOf('month'),
        'end_date': currentPeriod.end_date
      } : {
        'start_date': currentPeriod.start_date,
        'end_date': moment(value).endOf('month')
      };
      setformAddRelative({
        ...formAddRelative,
        dependent_periods: [...periods.slice(0, periodIndex), newPeriod, ...periods.slice(periodIndex + 1, periods.length)]
      });
    }
  }

  const handleDate = (value, item) => {
    if (value) {
      setformAddRelative({
        ...formAddRelative,
        birthday: value,
        error_birthday: '',
      });
    } else {
      setformAddRelative({
        ...formAddRelative,
        birthday: '',
      });
    }
  };

  const addDependentPeriod = () => {
    setformAddRelative({
      ...formAddRelative,
      dependent_periods: [...formAddRelative.dependent_periods, {'start_date': '', 'end_date': ''}]
    });
  }

  const removeDependentPeriod = (index) => {   
    const periods = formAddRelative.dependent_periods;
    const errors = [...formAddRelative.error_dependent_periods];
    const numOfPeriods = periods.length;
    
    // reset error 
    if (typeof(errors[index - 1]) !== 'undefined' && errors[index - 1].end_date !== ''
      && periods[index - 1].end_date !== '' && periods[index].start_date !== '' && moment(periods[index - 1].end_date) >= moment(periods[index - 1].start_date)
      && (periods[index - 1].start_date !== '' && moment(periods[index - 1].end_date) > moment(periods[index].start_date))) {
      errors[index - 1].end_date = '';
    }

    if (index < numOfPeriods - 1 && typeof(errors[index + 1]) !== 'undefined' && errors[index + 1].start_date !== ''
      && periods[index + 1].start_date !== '' && periods[index].end_date !== '' && moment(periods[index + 1].start_date) < moment(periods[index].end_date) 
      && ((periods[index + 1].end_date !== '' && moment(periods[index + 1].start_date) <= moment(periods[index + 1].end_date)) || (periods[index + 1].end_date === ''))
      ) {
      errors[index + 1].start_date = '';
    }
    setformAddRelative({
      ...formAddRelative,
      dependent_periods: [...periods.slice(0, index), ...periods.slice(index + 1, numOfPeriods)],
      error_dependent_periods: errors.length > 0 ? [...errors.slice(0, index), ...errors.slice(index + 1, errors.length)] : []
    });
  }

  const onclickAddRelative = () => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    setformAddRelative(
      {
        name: '',
        gender: '',
        type: '',
        phone: '',
        user_id: userId,
        birthday: '',
        is_dependent: false,
        dependent_periods: [],
        error_name: '',
        error_birthday: '',
        error_type: '',
        error_gender: '',
        error_is_dependent: '',
        error_dependent_periods: []
      },
    );
    setShowModalAdd(true);
  };

  return (
    <>
      <div className={styles.profileBoxBaby}>
        <Row className={styles.rowTitle}>
          <Col xs="10">
            <div className={styles.profileTitle}>
              <img alt={iconRelative} className={styles.imgTitle} src={iconRelative} />
              {t('infoEmployee.titleRelative')}
            </div>
          </Col>
          <Col xs="2">
            {uProfileUser?.data?.relative && uProfileUser?.data?.relative?.length !== 0 && (
              <div
                className={classNames(
                  `${dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE} ${styles.editDeleteButton
                  } mr--15`,
                )}
                onClick={() => onclickAddRelative()}
              >
                <IoIosAdd />
              </div>
            )}
          </Col>
        </Row>
        <div className={classNames(styles.salaryBoxParent, styles.tableRelative)}>
          {(uProfileUser && uProfileUser?.data?.relative?.length === 0) | (uProfileUser?.relative === null) ? (
            <div className={styles.achievementNull}>
              <div
                className={classNames(
                  `${dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE} ${styles.editDeleteButton
                  } ${styles.editDeleteButton}`,
                )}
                onClick={() => onclickAddRelative()}
              >
                <IoIosAdd />
              </div>
              <div className={styles.profileContentInfoAlert}>{t('infoEmployee.addRelative')}</div>
            </div>
          ) : (
            <div className="table-list">
              <table className="table align-items-center table-hover">
                <thead>
                  <tr>
                    <th className={styles.profileContentInfo}>{t('common.fullName')}</th>
                    <th className={styles.profileContentInfo}>{t('infoEmployee.relationship')}</th>
                    <th className={styles.profileContentInfo}>{t('employees.gender')}</th>
                    <th className={styles.profileContentInfo}>{t('common.birthday')}</th>
                    <th className={styles.profileContentInfo}>{t('common.phone')}</th>
                    <th className={styles.profileContentInfo}>{t('infoEmployee.labelDependPerson')}</th>
                    <th className={styles.profileContentInfo}>{t('infoEmployee.labelDependPersonStartDate')}</th>
                    <th className="col-delete-edit"></th>
                  </tr>
                </thead>
                <tbody>
                  {uProfileUser &&
                    uProfileUser?.data?.relative?.map((itemRelative, index) => (
                      <tr key={index}>
                        <td className={styles.profileContentInfo}>{itemRelative.name}</td>
                        <td className={styles.profileContentInfo}>{t(getTypeRelative(itemRelative.type))}</td>
                        <td className={classNames(`${styles.profileContentInfo} text-bold`)}>
                          {' '}
                          {t(getGenderEmployee(itemRelative.gender))}
                        </td>
                        <td className={styles.profileContentInfo}>{itemRelative.birthday}</td>
                        <td className={styles.profileContentInfo}>{itemRelative.phone}</td>
                        <td className={styles.profileContentInfo}>{itemRelative.is_dependent ? t('common.yes') : t('common.no')}</td>
                        <td className={styles.profileContentInfo}>{itemRelative.is_dependent && 
                          itemRelative.dependent_periods.length > 0 ? 
                          <DependentPeriodList periods = {itemRelative.dependent_periods} />
                          
                           : t('common.notDataContract')}
                        </td>
                        <td>
                          <div className="d-flex">
                            <div
                              onClick={() => onclickEditRelative(itemRelative)}
                              className={`${styles.editDeleteButtonChild} ${dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                                }`}
                            >
                              <HiPencil />
                            </div>
                            <div
                              onClick={() =>
                                dataInforUser?.hr_permission?.users_permission !== ROLE_VIEW &&
                                alertConfirm(itemRelative.id)
                              }
                              className={`${styles.editDeleteButtonChild} ${dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                                }`}
                            >
                              <MdDelete />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {uProfileUser?.relative && uProfileUser?.relative?.length === 0 && (
                <div className={styles.achievementNull}>
                  <div className={styles.editDeleteButton} onClick={() => setShowModalAdd(true)}>
                    <IoIosAdd />
                  </div>
                  <div className={styles.profileContentInfoAlert}>{t('infoEmployee.addRelative')}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ModalComponent
        toggle={() => setShowModalEdit(!isShowModalEdit)}
        isShowModal={isShowModalEdit}
        title={textModal.titleEdit}
        fullWidth={true}
      >
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className="record">
            <div className="mt-4">
              <InputField
                label={t('common.fullName')}
                variant="outlined"
                name="name"
                maxLength={Constants.MAX_LENGTH_30}
                defaultValue={formAddRelative.name}
                value={formAddRelative.name}
                onHandleInput={handleInput}
                isRequired={true}
                error={formAddRelative.error_name}
              />
            </div>
            <div className="fieldset-group mt-4 select-category">
              <SelectComponent
                label={t('infoEmployee.selectRelative')}
                name="type"
                addClass="relative-field"
                valueDefault={formAddRelative.type ? formAddRelative.type : 0}
                onHandleChangeSelect={handleInput}
                isRequired={true}
                error={formAddRelative.error_type}
              >
                {relatives &&
                  relatives.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </SelectComponent>
            </div>
            <div className="fieldset-group mt-4 select-category">
              <SelectComponent
                label={t('infoEmployee.gender')}
                name="gender"
                valueDefault={formAddRelative.gender ? formAddRelative.gender : 0}
                onHandleChangeSelect={handleInput}
                isRequired={true}
                error={formAddRelative.error_gender}
              >
                <MenuItem value={Constants.GENDER_MALE}>{t('common.male')}</MenuItem>
                <MenuItem value={Constants.GENDER_FEMALE}>{t('common.female')}</MenuItem>
              </SelectComponent>
            </div>
            <div className="fieldset-group mt-4">
              <DateDayComponent
                label={t('common.birthday')}
                valueDefault={formAddRelative.birthday}
                onChangeHandle={handleDate}
                isRequired={true}
                error={formAddRelative?.error_birthday}
              />
            </div>
            <div className="mt-4">
              <InputField
                label={t('common.phone')}
                variant="outlined"
                name="phone"
                maxLength={Constants.MAX_LENGTH_15}
                defaultValue={formAddRelative.phone}
                value={formAddRelative.phone}
                onHandleInput={handleInput}
                error={formAddRelative.error_phone}
                dataType={DATA_TYPE_NUMBER}
              />
            </div>
            <div className="mt-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formAddRelative.is_dependent ?? false}
                    onChange={handleInput}
                    name="is_dependent"
                  />
                }
                label={t('infoEmployee.labelIsDependent')}
              />
            </div>
            
            {
              formAddRelative.is_dependent &&
              <>
                <Row className="mt-2">
                    <Col className="xs-6">
                      <div className="title-project">{t('infoEmployee.dependentInfo')}</div>
                      <div className="error-input error">{formAddRelative.error_all_periods}</div>
                    </Col>
                </Row>
                {
                  formAddRelative.dependent_periods &&
                  formAddRelative.dependent_periods.map((period, periodIndex) => {
                    return (
                      <Row className="mt-3">
                        <Col xs={{ size: 11 }}>
                          <Row>
                            <Col xs={{ size: 6 }}>
                              <div>
                                <DateMonth
                                  label={t('common.startTime')}
                                  valueDefault={
                                    typeof period.start_date === 'string' && period.start_date !== ''
                                      ? moment(period.start_date, 'DD/MM/YYYY')
                                      : period.start_date
                                  }
                                  onChangeHandle={(value) => handleDependentDate(value, 'start_date', periodIndex)}
                                  isRequired={true}
                                  error={formAddRelative.error_dependent_periods?.[periodIndex]?.start_date}
                                  placeholder={t('common.startTime')}
                                />
                              </div>
                            </Col>
                            <Col xs={{ size: 6 }}>
                              <div>
                                <DateMonth
                                  label={t('common.endTime')}
                                  valueDefault={
                                    typeof period.end_date === 'string' && period.end_date !== ''
                                      ? moment(period.end_date, 'DD/MM/YYYY')
                                      : period.end_date
                                  }
                                  onChangeHandle={(value) => handleDependentDate(value, 'end_date', periodIndex)}
                                  isRequired={ periodIndex < formAddRelative.dependent_periods.length - 1 }
                                  error={formAddRelative.error_dependent_periods?.[periodIndex]?.end_date}
                                  placeholder={t('common.endTime')}
                                />
                              </div>
                              
                            </Col>
                          </Row>
                        </Col>
                        
                        <Col xs={{ size: 1}}>
                          {periodIndex !== 0 && (
                          <div
                            className={`${styles.editDeleteButtonChild}`}
                            onClick={() => removeDependentPeriod(periodIndex)}
                          >
                            <MdDelete />
                          </div>
                          )}
                        </Col>
                      </Row>
                    )
                  })
                }
                <div className={styles.addRelative} onClick={addDependentPeriod}>
                  <img alt={addRelative} className={styles.imgRelative} src={addRelative} />
                  <span>{t('infoEmployee.addDependentPeriod')}</span>
                </div>
              </>
            }     
          </div>
          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalEdit(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={t('infoEmployee.saveEdit')}
                addClass="w-100"
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>

      <ModalComponent
        toggle={() => setShowModalAdd(!isShowModalAdd)}
        isShowModal={isShowModalAdd}
        title={textModal.titleAdd}
        classNameAdd="relative scroll"
        fullWidth={true}
      >
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className="record">
            <div className="mt-4">
              <InputField
                label={t('common.fullName')}
                variant="outlined"
                name="name"
                maxLength={Constants.MAX_LENGTH_30}
                defaultValue={formAddRelative.name}
                value={formAddRelative.name}
                onHandleInput={handleInput}
                isRequired={true}
                error={formAddRelative.error_name}
              />
            </div>
            <div className="fieldset-group mt-4 select-category">
              <SelectComponent
                label={t('infoEmployee.selectRelative')}
                name="type"
                addClass="relative-field"
                valueDefault={formAddRelative.type}
                onHandleChangeSelect={handleInput}
                isRequired={true}
                error={formAddRelative.error_type}
              >
                {relatives &&
                  relatives.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </SelectComponent>
            </div>
            <div className="fieldset-group mt-4 select-category">
              <SelectComponent
                label={t('infoEmployee.gender')}
                name="gender"
                valueDefault={formAddRelative.gender}
                onHandleChangeSelect={handleInput}
                isRequired={true}
                error={formAddRelative.error_gender}
              >
                <MenuItem value={Constants.GENDER_MALE}>{t('common.male')}</MenuItem>
                <MenuItem value={Constants.GENDER_FEMALE}>{t('common.female')}</MenuItem>
              </SelectComponent>
            </div>
            <div className="fieldset-group mt-4">
              <DateDayComponent
                label={t('common.birthday')}
                valueDefault={formAddRelative.birthday}
                onChangeHandle={handleDate}
                isRequired={true}
                error={formAddRelative?.error_birthday}
              />
            </div>
            <div className="mt-4">
              <InputField
                label={t('common.phone')}
                variant="outlined"
                name="phone"
                maxLength={Constants.MAX_LENGTH_15}
                defaultValue={formAddRelative.phone}
                value={formAddRelative.phone}
                onHandleInput={handleInput}
                error={formAddRelative.error_phone}
                dataType={DATA_TYPE_NUMBER}
              />
            </div>
            <div className="mt-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formAddRelative.is_dependent ?? false}
                    onChange={handleInput}
                    name="is_dependent"
                  />
                }
                label={t('infoEmployee.labelIsDependent')}
              />
            </div>
            {
              formAddRelative.is_dependent &&
              <>
                <Row className="mt-2">
                    <Col className="md-6">
                      <div className="title-project">{t('infoEmployee.dependentInfo')}</div>
                      <div className="error-input error">{formAddRelative.error_all_periods}</div>
                    </Col>
                </Row>
                {
                  formAddRelative.dependent_periods &&
                  formAddRelative.dependent_periods.map((period, periodIndex) => {
                    return (
                      <Row className="mt-3">
                        <Col sm={{ size: 11 }}>
                          <Row>
                            <Col sm={{ size: 6 }}>
                              <div>
                                <DateMonth
                                  label={t('common.startTime')}
                                  valueDefault={
                                    typeof period.start_date === 'string' && period.start_date !== ''
                                      ? moment(period.start_date, 'DD/MM/YYYY')
                                      : period.start_date
                                  }
                                  onChangeHandle={(value) => handleDependentDate(value, 'start_date', periodIndex)}
                                  isRequired={true}
                                  error={formAddRelative.error_dependent_periods?.[periodIndex]?.start_date}
                                  placeholder={t('common.startTime')}
                                />
                              </div>
                            </Col>
                            <Col sm={{ size: 6 }}>
                              <div>
                                <DateMonth
                                  label={t('common.endTime')}
                                  valueDefault={
                                    typeof period.end_date === 'string' && period.end_date !== ''
                                      ? moment(period.end_date, 'DD/MM/YYYY')
                                      : period.end_date
                                  }
                                  onChangeHandle={(value) => handleDependentDate(value, 'end_date', periodIndex)}
                                  error={formAddRelative.error_dependent_periods?.[periodIndex]?.end_date}
                                  isRequired={ periodIndex < formAddRelative.dependent_periods.length - 1 }
                                  placeholder={t('common.endTime')}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        
                        <Col sm={{ size: 1}}>
                          {periodIndex !== 0 && (
                          <div
                            className={`${styles.editDeleteButtonChild}`}
                            onClick={() => removeDependentPeriod(periodIndex)}
                          >
                            <MdDelete />
                          </div>
                          )}
                        </Col>
                      </Row>
                    )
                  })
                }
                <div className={styles.addRelative} onClick={addDependentPeriod}>
                  <img alt={addRelative} className={styles.imgRelative} src={addRelative} />
                  <span>{t('infoEmployee.addDependentPeriod')}</span>
                </div>
              </>
            }     
            
          </div>
          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalAdd(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={t('common.save')}
                addClass="w-100"
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};

const DependentPeriodList = (props) => {
  const { t } = useTranslation();
  const { periods } = props;
  const [showAll, setShowAll] = useState(false);
  const displayPeriods = !showAll ? periods.slice(0, 2) : periods;
  return (
    <>
        {  
          displayPeriods.map((period) => {
            return <div>
              {customDate(moment(period.start_date, 'DD/MM/YYYY'), 'MM/YYYY')}
              &nbsp;~&nbsp;
              {period.end_date !== '' ? customDate(moment(period.end_date, 'DD/MM/YYYY'), 'MM/YYYY') : t('common.notDataContract')}
            </div>
          })
          
        }
        {(periods.length > 2) ? <button className={styles.buttonAsLink} onClick = {() => setShowAll(!showAll)}>
          { showAll ? t('common.showLess') : t('common.showMore') }
        </button> : ''}

    </>
  )
};

export default Relative;
