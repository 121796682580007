import moment from 'moment';

/* eslint-disable react-hooks/rules-of-hooks */
export const convertTypeTimeKeeping = (type) => {
  let result = '';
  if (type === 1) {
    result = 'masterData.specialTimeKeeping.type1';
  } else {
    result = 'masterData.specialTimeKeeping.type2';
  }
  return result;
};

export const convertFormatTimeSend = (name, type) => {
  return moment(name).format(type);
};
