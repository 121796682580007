import React from 'react';
import { useTranslation } from 'react-i18next';

import CreateContract from './../Modals/CreateContract.js';
import { TAB_LIST_CONTRACT_WAITING } from 'constants/Contract.js';
import { USE_LIST_WAIT_CONTRACT } from 'constants/Contract.js';

const CreateWaitingContract = (props) => {
  const { onSetMessage, isShowModalAdd, onSetShowModal, formDataContract } = props;

  const { t } = useTranslation();

  return (
    <>
      <CreateContract
        onSetMessage={onSetMessage}
        isShowModal={isShowModalAdd}
        onSetShowModal={onSetShowModal}
        txtTitleModal={t('contract.createContract')}
        txtBtnModal={t('common.add')}
        type={TAB_LIST_CONTRACT_WAITING}
        keyCache={USE_LIST_WAIT_CONTRACT}
        user={{ user_id: formDataContract.user_id, user_name: formDataContract.name }}
      />
    </>
  );
};

export default CreateWaitingContract;
