import { useQuery } from 'react-query';

import { HTTP_OK } from './../constants/StatusCode.js';
import {
  apiCompanyMember,
  apiSearchEmployeeByDivision,
  apiDiagramDepartment,
  apiGetCompany,
  apiGetDivisionTeam,
  apiGetDivision,
  apiDetailDepartment,
  apiGetProjectsDivision,
  getSkillDiagram,
  apiOtherMember,
  apiDetailTeam,
} from './../api/department';
import * as Constants from './../constants/Department.js';

export function useDiagramDepartment(params) {
  const { data: response, isLoading } = useQuery([Constants.USE_DIAGRAM_DEPARTMENT, params], async () => {
    const response = await apiDiagramDepartment(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, isLoading };
}

export function useGetCompany(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_BRANCH_DEPARTMENT, params], async () => {
    const response = await apiGetCompany(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useDetailDepartment(divisionId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_DETAIL_DEPARTMENT, divisionId], async () => {
    if (!divisionId) return;
    const response = await apiDetailDepartment(divisionId, params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return { ...response, error, isLoading };
}

export function useGetDivisionTeam(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_DIVISION_TEAM, params], async () => {
    const response = await apiGetDivisionTeam(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListMemberDepartment(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LEADER_DEPARTMENT, params], async () => {
    if (params && !params.key_word) params = {};
    const response = await apiCompanyMember(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetInfoDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_DETAIL_DIVISION, params], async () => {
    const response = await apiGetDivision(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListProjectsDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_PROJECTS_DIVISION, params], async () => {
    const response = await apiGetProjectsDivision(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetSkillDiagram(params) {
  const { data, isLoading, error } = useQuery([Constants.USE_SKILL_DIAGRAM, params], async () => {
    const response = await getSkillDiagram(params);

    return response?.data;
  });

  return { ...data, isLoading, error };
}

export function useOtherMember(params) {
  const { data: response } = useQuery([Constants.USE_OTHER_MEMBER, params], async () => {
    const response = await apiOtherMember(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response };
}

export function useDetailTeam(params) {
  const { data: response, isLoading } = useQuery([Constants.USE_DETAIL_TEAM, params], async () => {
    if (params?.team_id) {
      const response = await apiDetailTeam(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    }
    return null;
  });

  return { ...response, isLoading };
}

export function useSearchEmployeeByDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_MEMBER_TEAM, params], async () => {
    if (!params || params?.division_id === '') return;
    if (params?.key_word === '' || params?.key_word === null) delete params.key_word;

    if (params?.division_id) {
      const response = await apiSearchEmployeeByDivision(params);

      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    }
    return null;
  });

  return { ...response, error, isLoading };
}
