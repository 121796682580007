import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { CATEGORY_CERTIFICATE, MANAGER_CERTIFICATE } from 'constants/MasterData';

import './style.scss';

const Certificate = (props) => {
  const { type } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <ul className="navbar-master-data-certificate">
          <li>
            <NavLink to={{
              pathname: '/admin/master-data/category-certificate',
            }} className={classNames(`link ${type === CATEGORY_CERTIFICATE && 'isActive'}`)}>
              <span className={classNames(`navItem text-bold`)}>{t('masterData.categoryCertificate')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={{
              pathname: '/admin/master-data/manager-certificate',
            }} className={classNames(`link ${type === MANAGER_CERTIFICATE && 'isActive'}`)}>
              <span className={classNames(`navItem text-bold`)}>{t('masterData.certificateName')}</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Certificate;
