import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import './style.scss';
import { DASHBOARD } from 'constants/Assets';
import { ASSETS_LIST } from 'constants/Assets';
import { REQUEST_LIST } from 'constants/Assets';
import { RENTAL_TABLE } from 'constants/Assets';
import { ACTION } from 'constants/Assets';
import { EXPORT_IMPORT_INVENTORY } from 'constants/Assets';
import { DEPRECIATION_TABLE } from 'constants/Assets';
import SideNavBar from 'components/SideNavBar';

const Assets = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory();
  const [value, setValue] = useState(DASHBOARD);

  const routes = [
    {
      name: t('common.dashboard'),
    },
    {
      name: t('assets.listAssets'),
    },
    {
      name: t('assets.listRequests'),
    },
    {
      name: t('assets.rentalPriceListName'),
    },
    {
      name: t('assets.work'),
    },
    {
      name: t('assets.assetsExportImportInventory.name'),
    },
    {
      name: t('assets.depreciationTable'),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === DASHBOARD) {
      history.push('/admin/assets/dashboard');
    }
    if (newValue === ASSETS_LIST) {
      history.push('/admin/assets/list-assets');
    }
    if (newValue === REQUEST_LIST) {
      history.push('/admin/assets/list-request');
    }
    if (newValue === RENTAL_TABLE) {
      history.push('/admin/assets/rental-price-list/brief');
    }
    if (newValue === ACTION) {
      history.push('/admin/assets/activity');
    }
    if (newValue === EXPORT_IMPORT_INVENTORY) {
      history.push('/admin/assets/export-import-inventory');
    }
    if (newValue === DEPRECIATION_TABLE) {
      history.push('/admin/assets/depreciation');
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/admin/assets/dashboard':
        setValue(DASHBOARD);
        break;
      case '/admin/assets/list-assets':
        setValue(ASSETS_LIST);
        break;
      case '/admin/assets/list-request':
        setValue(REQUEST_LIST);
        break;
      case '/admin/assets/rental-price-list/brief':
        setValue(RENTAL_TABLE);
        break;
      case '/admin/assets/rental-price-list/detail':
        setValue(RENTAL_TABLE);
        break;
      case '/admin/assets/activity':
        setValue(ACTION);
        break;
      case '/admin/assets/export-import-inventory':
        setValue(EXPORT_IMPORT_INVENTORY);
        break;
      case '/admin/assets/depreciation':
        setValue(DEPRECIATION_TABLE);
        break;

      default:
        break;
    }
  }, [location.pathname]);

  return <SideNavBar scrollable={true} routes={routes} onChange={handleChange} value={value} />;
};

export default Assets;
