import Departmenthandle from 'views/pages/DepartmentHandel';
import PolicyOfTax from 'views/pages/Policy/PolicyOfTax';
import InsurancePolicy from 'views/pages/Policy/InsurancePolicy';
import DashboardEmployee from 'views/pages/Employees/Dashboard';
import EmployeesList from 'views/pages/Employees/List';
import ListStandbyEmployees from 'views/pages/Employees/Standby';
import Request from 'views/pages/Employees/Request';

import DashboardAsset from 'views/pages/Asset/Dashboard';
import ListAsset from 'views/pages/Asset/ListAssets';
import RentalPriceList from 'views/pages/Asset/RentalPriceList';

import ProfileUser from 'views/pages/ProfileEmployees/Profile';
import UserAssets from 'views/pages/ProfileEmployees/Asset';

import SpecialTimekeeping from 'views/pages/MasterData/SpecialTimekeeping';
import DataOther from 'views/pages/MasterData/DataOther';
import Warehouse from 'views/pages/MasterData/Warehouse';
import CategoryCertificate from 'views/pages/MasterData/Certificate/CategoryCertificate';
import ManagerCertificate from 'views/pages/MasterData/Certificate/ManagerCertificate';
import CategoryAsset from 'views/pages/MasterData/CategoryAsset';
import CategoryContract from 'views/pages/MasterData/CategoryContact';
import CategoryPosition from 'views/pages/MasterData/CategoryPosition';
import CategoryEmployees from 'views/pages/MasterData/CategoryEmployees';
import ManageOverTime from 'views/pages/MasterData/ManageOverTime';
import Skill from 'views/pages/MasterData/Skill';
import Level from 'views/pages/MasterData/Level';
import Role from 'views/pages/MasterData/Role';
import GroupAccount from 'views/pages/MasterData/GroupAccount';
import Holiday from 'views/pages/MasterData/Holiday';
import CreateFilter from 'views/pages/MasterData/GroupAccount/CreateFilter';
import CreateCustom from 'views/pages/MasterData/GroupAccount/CreateCustom';
import DetailUser from 'views/pages/MasterData/Role/Detail/Users';
import DetailRole from 'views/pages/MasterData/Role/Detail/Role';
import MappingRequest from 'views/pages/MasterData/MappingRequest';

import DepartmentDetail from 'views/pages/DepartmentHandel/Detail';

import Contract from 'views/pages/Contracts/Contract';
import DashboardContract from 'views/pages/Contracts/Dashboard';
import Retire from 'views/pages/Contracts/Retire';
import WaitingContract from 'views/pages/Contracts/WaitingContract';
import ListRequest from 'views/pages/Asset/ListRequest';

import SkillDiagram from 'views/pages/DepartmentHandel/SkillDiagram';
import DepreciationTable from 'views/pages/Asset/DepreciationTable';
import ActivityAssets from 'views/pages/Asset/Activity';

import DashboardTimeKeeping from 'views/pages/TimeKeeping/Dashboard';
import TimeSheets from 'views/pages/TimeKeeping/TimeSheets';
import ListOT from 'views/pages/TimeKeeping/ListOT';
import RequestTimeKeeping from 'views/pages/TimeKeeping/Request';

import Calendar from 'views/pages/IndividualTimeKeeping/Calendar';
import IndividualListRequests from 'views/pages/IndividualTimeKeeping/ListRequests';
import IndividualListOT from 'views/pages/IndividualTimeKeeping/ListOT';
import ExportImportInventory from 'views/pages/Asset/ExportImportInventory';
import Dashboard from 'views/pages/Dashboard';
import PageNotAccess from 'views/pages/PageNotAccess';
import CommingSoon from 'components/CommingSoon';

import EducationProgram from 'views/pages/Training/EducationProgram';
import InCommitment from 'views/pages/Training/InCommitment';
import EndCommitment from 'views/pages/Training/EndCommitment';
import ProgramDetails from 'views/pages/Training/ProrgamDetails';
var routesMenu = [
  {
    path: '/index',
    name: 'menu.dashboard',
    icon: '',
    component: Dashboard,
    layout: '/admin',
    permission: 'dashboard',
  },
  {
    path: '/departmenthandle',
    name: 'menu.DepartmentDiagram',
    icon: '',
    component: Departmenthandle,
    layout: '/admin',
    links: ['/department/detail/*', '/skill-diagram'],
    permission: 'divisions_permission',
  },
  {
    path: '/employees/dashboard',
    name: 'menu.employees',
    icon: '',
    component: DashboardEmployee,
    layout: '/admin',
    links: [
      '/employees/list',
      '/employees/profile/*',
      '/employees/assets/*',
      '/employees/projects/*',
      '/employees/request',
      '/employees/standby'
    ],
    permission: 'users_permission',
  },
  {
    path: '/contract/dashboard',
    name: 'menu.contacts',
    icon: '',
    component: DashboardContract,
    layout: '/admin',
    links: ['/contract/dashboard', '/contract/contract', '/contract/retire', '/contract/waiting-contract'],
    permission: 'contracts_permission',
  },
  {
    path: '/timekeeping/dashboard',
    name: 'menu.timekeeping',
    icon: '',
    component: DashboardTimeKeeping,
    layout: '/admin',
    links: [
      '/timekeeping/dashboard',
      '/timekeeping/time-sheets',
      '/timekeeping/request',
      '/timekeeping/list-ot',
      '/timekeeping/individual/list-requests/*',
      '/timekeeping/individual/list-ot/*',
      '/timekeeping/individual/calendar/*',
    ],
    permission: 'time_sheet_permission',
  },
  {
    path: '/training/program',
    name: 'menu.training',
    icon: '',
    component: EducationProgram,
    layout: '/admin',
    links: ['/training/program', '/training/in-commitment', '/training/end-of-commitment','/training/program-details/*'],
    permission: 'users_permission',
  },
  {
    path: '/assets/dashboard',
    name: 'menu.assets',
    icon: '',
    component: DashboardAsset,
    layout: '/admin',
    links: [
      '/assets/list-assets',
      '/assets/list-request',
      '/assets/rental-price-list/brief',
      '/assets/rental-price-list/detail',
      '/assets/depreciation',
      '/assets/activity',
      '/assets/export-import-inventory',
    ],
    permission: 'assets_permission',
  },
  {
    path: '/policy/insurance',
    name: 'menu.policy',
    icon: '',
    component: InsurancePolicy,
    layout: '/admin',
    links: [
      '/policy/insurance',
      '/policy/tax'
    ],
    permission: 'policy_permission'
  },
  {
    path: '/master-data/data-other',
    name: 'menu.masterData',
    icon: '',
    component: DataOther,
    layout: '/admin',
    links: [
      '/master-data/data-other',
      '/master-data/holiday',
      '/master-data/warehouse',
      '/master-data/category-asset',
      '/master-data/category-contact',
      '/master-data/position',
      '/master-data/type-employees',
      '/master-data/category-certificate',
      '/master-data/special-time-keeping',
      '/master-data/group-account',
      '/master-data/create-group-account',
      '/master-data/create-filter/*',
      '/master-data/create-custom/*',
      '/master-data/category-certificate',
      '/master-data/manager-certificate',
      '/master-data/mapping-request',
      '/master-data/role',
      '/master-data/detail-users',
      '/master-data/detail-role',
    ],
    permission: 'master_data_permission',
  },
];

var routeNav = [
  {
    path: '/employees/dashboard',
    name: 'common.dashboard',
    icon: '',
    component: DashboardEmployee,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/departmenthandle',
    name: 'menu.DepartmentDiagram',
    icon: '',
    component: Departmenthandle,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/skill-diagram',
    name: 'menu.skillDiagram',
    icon: '',
    component: SkillDiagram,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/employees/list',
    name: 'employees.listEmployee',
    icon: '',
    component: EmployeesList,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/employees/standby',
    name: 'employees.listStandbyEmployee',
    icon: '',
    component: ListStandbyEmployees,
    layout: '/admin',
    class: 'hidden',
  },

  // Training
  {
    path: '/training/program',
    name: 'training.educationProgram',
    icon: '',
    component: EducationProgram,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/training/in-commitment',
    name: 'training.inCommitment',
    icon: '',
    component: InCommitment,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/training/end-of-commitment',
    name: 'training.endOfCommitment',
    icon: '',
    component: EndCommitment,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/training/program-details/:id',
    name: 'training.educationProgram',
    icon: '',
    component: ProgramDetails,
    layout: '/admin',
    class: 'hidden',
  },
  // Assets
  {
    path: '/assets/list-assets',
    name: 'assets.listAssets',
    icon: '',
    component: ListAsset,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/assets/rental-price-list/brief',
    name: 'assets.listAssets',
    component: RentalPriceList,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/assets/rental-price-list/detail',
    name: 'assets.listAssets',
    component: RentalPriceList,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/assets/list-request',
    name: 'assets.listRequests',
    icon: '',
    component: ListRequest,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/assets/depreciation',
    name: 'assets.depreciationTable',
    icon: '',
    component: DepreciationTable,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/assets/activity',
    name: 'assets.work',
    icon: '',
    component: ActivityAssets,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/assets/export-import-inventory',
    name: 'assets.assetsExportImportInventory.name',
    icon: '',
    component: ExportImportInventory,
    layout: '/admin',
    class: 'hidden',
  },

  // Employee
  {
    path: '/employees/assets/:userId',
    name: 'infoEmployee.tabEmployeeAsset',
    component: UserAssets,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/employees/profile/:userId',
    name: 'profile',
    icon: '',
    component: ProfileUser,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/employees/request',
    name: 'request',
    icon: '',
    component: Request,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/department/detail/:departmentId',
    name: 'detailDepartment',
    icon: '',
    component: DepartmentDetail,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/employees/projects/:userId',
    name: 'projects',
    icon: '',
    component: CommingSoon,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/employees/monthly-evaluation',
    name: 'monthlyEvaluation',
    icon: '',
    component: CommingSoon,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/special-time-keeping',
    name: 'masterData.specialTimeKeeping.name',
    icon: '',
    component: SpecialTimekeeping,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/data-other',
    name: 'masterData.dataOther',
    icon: '',
    component: DataOther,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/holiday',
    name: 'masterData.Holiday',
    icon: '',
    component: Holiday,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/warehouse',
    name: 'masterData.warehouse',
    icon: '',
    component: Warehouse,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/category-asset',
    name: 'masterData.categoryAsset',
    icon: '',
    component: CategoryAsset,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/category-certificate',
    name: 'masterData.categoryCertificate',
    icon: '',
    component: CategoryCertificate,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/manager-certificate',
    name: 'masterData.managerCertificate',
    icon: '',
    component: ManagerCertificate,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/category-contact',
    name: 'masterData.typeOfContact',
    icon: '',
    component: CategoryContract,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/position',
    name: 'employees.position',
    icon: '',
    component: CategoryPosition,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/type-employees',
    name: 'masterData.typeOfPersonnel',
    icon: '',
    component: CategoryEmployees,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/skill',
    name: 'employees.skill',
    icon: '',
    component: Skill,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/level',
    name: 'employees.level',
    icon: '',
    component: Level,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/role',
    name: 'employees.role',
    icon: '',
    component: Role,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/group-account',
    name: 'employees.groupAccount',
    icon: '',
    component: GroupAccount,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/over-time',
    name: 'masterData.overTime.manageOverTime',
    icon: '',
    component: ManageOverTime,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/mapping-request',
    name: 'masterData.mappingRequest',
    icon: '',
    component: MappingRequest,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/contract/dashboard',
    name: 'contract.dashboard',
    icon: '',
    component: DashboardContract,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/contract/contract',
    name: 'contract.contract',
    icon: '',
    component: Contract,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/contract/retire',
    name: 'contract.retire',
    icon: '',
    component: Retire,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/contract/waiting-contract',
    name: 'contract.waitingContract',
    icon: '',
    component: WaitingContract,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/create-filter/:contractId',
    name: 'contract.waitingContract',
    icon: '',
    component: CreateFilter,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/create-custom/:contractId',
    name: 'contract.waitingContract',
    icon: '',
    component: CreateCustom,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/detail-users',
    name: 'contract.waitingContract',
    icon: '',
    component: DetailUser,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/master-data/detail-role',
    name: 'contract.waitingContract',
    icon: '',
    component: DetailRole,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/timekeeping/time-sheets',
    name: 'timekeeping.timeSheets',
    icon: '',
    component: TimeSheets,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/timekeeping/request',
    name: 'timekeeping.request.name',
    icon: '',
    component: RequestTimeKeeping,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/timekeeping/list-ot',
    name: 'timekeeping.listOT',
    icon: '',
    component: ListOT,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/timekeeping/individual/calendar/:userId',
    name: 'timekeeping.calendar',
    icon: '',
    component: Calendar,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/timekeeping/individual/list-requests/:userId',
    name: 'timekeeping.individual.requests',
    icon: '',
    component: IndividualListRequests,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/timekeeping/individual/list-ot/:userId',
    name: 'timekeeping.individual.ot',
    icon: '',
    component: IndividualListOT,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/policy/insurance',
    name: 'policy.insurancePolicy',
    icon: '',
    component: InsurancePolicy,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/policy/tax',
    name: 'policy.policyOfTax',
    icon: '',
    component: PolicyOfTax,
    layout: '/admin',
    class: 'hidden',
  },
  {
    path: '/page-not-access',
    name: 'common.pageNotAccess',
    icon: '',
    component: PageNotAccess,
    layout: '/admin',
    class: 'hidden',
  },
];

const routes = [...routesMenu, ...routeNav];

export default routes;
