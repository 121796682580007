import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import iconChecked from 'assets/img/icons/checked.png';
import defaultAvatar from 'assets/img/icons/avatar_default.svg';

import './styles.scss';
import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import ModalComponent from 'components/Modal';
import SearchComponent from 'components/Search';
import { apiUpdateLeaderTeam, apiUpdateLeaderDivision } from 'api/department';
import { MODEL_TEAM } from 'constants/Department';
import { USE_DETAIL_TEAM, USE_DETAIL_DEPARTMENT } from 'constants/Department';
import { useSearchEmployeeByDivision, useListMemberDepartment } from 'hook/useDepartment.js';
import { isEmptyObject } from 'helpers';
import { handleOnError } from 'helpers';

const ChooseManager = (props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    idDivision,
    idTeam,
    isShowModalManager,
    type,
    leader,
    listMemberRole,
    isAddNew,
    onHandelManage,
    handleToggleManager,
  } = props;

  const [stateButton, setStateButton] = useState(true);
  const [stateIsLoading, setStateIsLoading] = useState(false);
  const [keyWord, setKeyWord] = useState('');
  const [listMember, setListMember] = useState([]);
  const [manage, setManage] = useState({});

  const [searchAll, setSearchALl] = useState({ key_word: '123123' });
  const [searchDivision, setSearchDivision] = useState('');

  const { data: uListMemberDivision, isLoading: isLoadingListMember } = useSearchEmployeeByDivision(searchDivision);
  const { data: uListMemberAll, isLoading: isLoadingListMemberAll } = useListMemberDepartment(searchAll);

  function setSearchListMember(members, lead, listMemberRole) {
    if (members) {
      let _listMember = [];
      let userIdMember = [];
      if (listMemberRole) {
        userIdMember = listMemberRole.map((item) => item.user_id);
      }

      members.forEach((item) => {
        if (!userIdMember.includes(item.user_id)) {
          _listMember.push({
            ...item,
            status: lead ? (lead.id === item.user_id ? true : false) : false,
          });
        }
      });
      setListMember(_listMember);
    } else {
      setListMember([]);
    }
  }

  useEffect(() => {
    if (type === MODEL_TEAM) {
      setSearchDivision({ key_word: keyWord, division_id: idDivision });
    } else {
      setSearchALl({ key_word: keyWord });
    }
  }, [keyWord, type, idDivision]);

  useEffect(() => {
    if (!isShowModalManager) {
      setKeyWord('');
      setStateButton(true);
      setStateIsLoading(false);
    }

    if (type === MODEL_TEAM) {
      setSearchListMember(uListMemberDivision, leader, listMemberRole);
    } else {
      setSearchListMember(uListMemberAll, leader, listMemberRole);
    }
  }, [isShowModalManager, uListMemberDivision, uListMemberAll, leader, listMemberRole, type]);

  const handleSearchManager = (keyword) => {
    setKeyWord(keyword);
  };

  const handleChooseManager = (item) => {
    const selectItems = [...listMember];
    setListMember(
      selectItems.map((el) => (el.user_id === item.user_id ? { ...el, status: true } : { ...el, status: false })),
    );
    if (isEmptyObject(leader) || (leader && leader.id !== item.user_id)) {
      setStateButton(false);
      let _manage = { ...item, id: item.user_id };
      setManage(_manage);
    } else {
      setStateButton(true);
    }
  };

  const blockMember = (item, key) => (
    <Col md={12} key={key}>
      <div
        className={item.status ? 'manager-block-team-division choosen-manager' : 'manager-block-team-division'}
        onClick={() => handleChooseManager(item)}
      >
        <div className="block-noicons pl-2">
          <div className=" avatar">
            <img
              onError={handleOnError}
              width="100%"
              height="100%"
              src={item?.avatar ? item.avatar : defaultAvatar}
              alt="avatar"
            />
          </div>
          <div className="contentEdit ml-2">
            <div className="name">{item?.name || item?.user_name}</div>
            <div className="position">
              <span>{item?.email}</span>
              <span> - </span>
              <span>{item?.position_name || item?.role_name_division}</span>
            </div>
          </div>
        </div>
        {item.status && <img width="15px" height="15px" src={iconChecked} alt="checked manager" className="mr-3" />}
      </div>
    </Col>
  );

  const { mutate: handlerSubmitUpdateLeaderTeam } = useMutation(
    async (data) => {
      const result = await apiUpdateLeaderTeam(idTeam, {
        user_id: manage.user_id,
        team_id: idTeam,
      });
      return result;
    },
    {
      onSuccess: () => {
        setStateIsLoading(false);
        setStateButton(true);
        onHandelManage(manage);
        queryClient.invalidateQueries(USE_DETAIL_TEAM);
        handleToggleManager();
      },
      onError: () => {
        setStateIsLoading(false);
        setStateButton(true);
        handleToggleManager();
      },
    },
  );

  const { mutate: handlerSubmitUpdateLeaderDivision } = useMutation(
    async (data) => {
      const result = await apiUpdateLeaderDivision(idDivision, {
        user_id: manage.user_id,
      });
      return result;
    },
    {
      onSuccess: () => {
        setStateIsLoading(false);
        setStateButton(true);
        onHandelManage(manage);

        queryClient.invalidateQueries(USE_DETAIL_DEPARTMENT);
        handleToggleManager();
      },
      onError: () => {
        setStateIsLoading(false);
        setStateButton(true);
        handleToggleManager();
      },
    },
  );

  const onSubmitManage = (event) => {
    event.preventDefault();
    if (!isAddNew) {
      setStateIsLoading(true);
    }
    setStateButton(true);

    if (isAddNew) {
      handleToggleManager();
      onHandelManage(manage);
    } else {
      if (type === MODEL_TEAM) {
        handlerSubmitUpdateLeaderTeam();
      } else {
        handlerSubmitUpdateLeaderDivision();
      }
    }
  };

  return (
    <ModalComponent
      toggle={handleToggleManager}
      isShowModal={isShowModalManager}
      title={
        type === MODEL_TEAM
          ? isEmptyObject(leader)
            ? t('divisionDiagram.divisionPopup.addLeader')
            : t('divisionDiagram.divisionPopup.updateLeader')
          : t('divisionDiagram.divisionDetail.headTitle')
      }
      classNameAdd="modalAdd"
    >
      <form onSubmit={onSubmitManage}>
        <Row className="mt-4">
          <Col md="12">
            <SearchComponent
              defaultValue={keyWord}
              handleSearch={handleSearchManager}
              txtPlaceholder={t('divisionDiagram.divisionPopup.search')}
            />
          </Col>
        </Row>
        <Row className="mt-4 body-search">
          {isLoadingListMember || isLoadingListMemberAll ? (
            <Col md={12} className="d-flex justify-content-center">
              <Loading addClass="mt-2" />
            </Col>
          ) : listMember?.length ? (
            listMember?.map((item, key) => blockMember(item, key))
          ) : (
            <Col md={12} className="d-flex justify-content-center error-no-data">
              {t('common.notData')}
            </Col>
          )}
        </Row>
        <Row className="mt-5">
          <Col md="12">
            <ButtonComponent
              hasDisabled={stateButton}
              isLoading={isAddNew ? false : stateIsLoading}
              type="submit"
              addClass="button-add-department orange w-100"
              text={
                type === MODEL_TEAM
                  ? isEmptyObject(leader)
                    ? t('divisionDiagram.divisionPopup.btnAddLeader')
                    : t('divisionDiagram.divisionPopup.btnAddLeader')
                  : isEmptyObject(leader)
                  ? t('divisionDiagram.divisionPopup.chooseManager')
                  : t('divisionDiagram.divisionPopup.updateManager')
              }
            />
          </Col>
        </Row>
      </form>
    </ModalComponent>
  );
};

export default ChooseManager;
