import { useQuery } from "react-query";

import { apiSearchDashboard } from "api/dashboard";
import { HTTP_OK } from "constants/StatusCode";
import * as Constants from './../constants/Dashboard';

export function useSearchDashboard(params) {
  const { data: response, isLoading, error } = useQuery([Constants.USE_SEARCH_DASHBOARD, params], async () => {
    const response = await apiSearchDashboard(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, isLoading, error };
}
