import React from 'react';
import './../style.scss';
import Training, { DATE_FORMAT } from '..';
import { Card, Button, Table } from 'antd';
import { InfoIcon } from '../icon';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DEFAULT_PAGE, DEFAULT_LIMIT } from 'constants/index';
import { REFUND_FULL, FIELD_KEY, IN_COMMIT_TYPE } from 'constants/Training';
import { useGetAllUsersCourse, useGetCourses } from 'hook/useTraining';
import { customDate } from 'helpers/formatDate';
import CustomPagination from '../CustomPagination';
import { MdDelete, MdEdit } from 'react-icons/md';
import { TrainingProvider } from '../Context/TrainingProvider';
import FilterMember from './FilterMember';
import UpdateMember from './UpdateMember';
import CancelCommit from './CancelCommit';

const InCommitment = ({ isDashboard }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({
    page: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT,
    list_type: IN_COMMIT_TYPE
  });

  const filterCourse = {
    fields: [FIELD_KEY.NAME, FIELD_KEY.ID]
  };

  const [isDisable, setIsDisable] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenCancel, setIsCancelCommit] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});  
  const { user_courses, isLoading, refetch } = useGetAllUsersCourse(filter);
  const { courses } = useGetCourses(filterCourse);

  const columns = [
    {
      title: t('training.index'),
      dataIndex: FIELD_KEY.INDEX,
      key: FIELD_KEY.INDEX,
      align: 'center',
      width: 60,
      render: (_, record, index) => index + filter.limit * (filter.page - 1) + 1,
    },
    {
      title: t('training.userCode'),
      dataIndex: FIELD_KEY.CODE,
      key: FIELD_KEY.CODE,
      width: 100,
    },
    {
      title: t('training.fullName'),
      dataIndex: FIELD_KEY.NAME,
      key: FIELD_KEY.NAME,
      width: 140,
    },
    {
      title: t('training.division'),
      dataIndex: FIELD_KEY.DIVISION,
      key: FIELD_KEY.DIVISION,
      width: 85,
      render: (value) => (value?.name)
    },
    {
      title: t('training.position_'),
      dataIndex: FIELD_KEY.POSITION,
      key: FIELD_KEY.POSITION,
      width: 95,
      render: (value) => (value?.name)
    },
    {
      title: t('training.programName'),
      dataIndex: FIELD_KEY.COURSE,
      key: FIELD_KEY.PROGRAM_NAME,
      width: 140,
      render: (value) => (value?.name)
    },
    {
      title: t('training.startDate'),
      dataIndex: FIELD_KEY.START_DATE,
      key: FIELD_KEY.START_DATE,
      render: (value) => (value ? customDate(value, DATE_FORMAT) : '-'),
      width: 125,
      align: 'center'
    },
    {
      title: t('training.endDate'),
      dataIndex: FIELD_KEY.END_DATE,
      key: FIELD_KEY.END_DATE,
      render: (value) => (value ? customDate(value, DATE_FORMAT) : '-'),
      width: 125,
      align: 'center'
    },
    {
      title: t('training.monthCommit1'),
      dataIndex: FIELD_KEY.COURSE,
      key: FIELD_KEY.MONTH_COMMIT,
      width: 100,
      align: 'center',
      render: (value) => (value?.month_commit)
    },
    {
      title: t('training.supportCost1'),
      dataIndex: FIELD_KEY.SUPPORT_COST,
      key: FIELD_KEY.SUPPORT_COST,
      width: 120,
      render: (value) => value.toLocaleString()?.replace('.', ','),
      align: 'center'
    },
    {
      title: t('training.rateCommit'),
      dataIndex: FIELD_KEY.COURSE,
      key: FIELD_KEY.RATE_COMMIT,
      render: (value) => {
        const rate = value.rate_commit;
        const content = rate === REFUND_FULL ? t('training.refundFull') : t('training.refundByPercent');
        const css = rate === REFUND_FULL ? 'refund-full' : 'refund-percent';
        return <span className={css}>{content}</span>;
      },
      width: 160,
    },
    {
      align: 'center',
      width: 80,
      render: (_, record) => (
        <div className="flex items-center">
          <Button
            className="button edit-course"
            icon={
              <MdEdit
                size={24}
                color="#909090"
                onClick={() => {
                  setIsDisable(true);
                  setIsOpenUpdate(true);
                  setSelectedMember(record);
                }}
              />
            }
          />
          <Button
            className="button delete-course"
            icon={<MdDelete size={24} color="#909090" />}
            disabled={isDisable}
            onClick={() => {
                setSelectedMember(record);
                setIsCancelCommit(true);
              }
            }
          />
        </div>
      ),
    },
  ];

  return (
    <TrainingProvider>
      <div className="scroll ct-form-controller-filter mb-3 member-list">
        {!isDashboard && (
          <div className="nav-fixed">
            <Training />
          </div>
        )}
        <Card
          className="card"
          title={
            <span className="title">
              <InfoIcon />
              {t('training.inCommitmentMember')}
            </span>
          }
          extra={
            <span>
              <FilterMember filter={filter} setFilter={setFilter} courses={courses}/>
            </span>
          }
        >
          <Table
            columns={columns}
            dataSource={user_courses?.data || []}
            loading={isLoading}
            rowKey={'id'}
            pagination={false}
            scroll={{ x: 'max-content', y: 500 }}
            footer={() => (
              <CustomPagination
                totalPage={user_courses?.total}
                currentPage={filter.page}
                limit={filter.limit}
                from={1}
                to={user_courses?.to}
                onChangePage={(page, limit) => setFilter({ ...filter, page, limit })}
              />
            )}
          />
        </Card>
        {isOpenUpdate && 
          <UpdateMember
            isOpen={isOpenUpdate}
            setIsOpen={setIsOpenUpdate}
            refetch={refetch}
            setIsDisable={setIsDisable}
            selectedMember={selectedMember}
          />
        }
        {isOpenCancel && 
          <CancelCommit 
            isOpen={isOpenCancel}
            setIsOpen={setIsCancelCommit}
            refetch={refetch}
            selectedMember={selectedMember}
          />
        }
      </div>
    </TrainingProvider>
  );
};

export default InCommitment;
