import { Button, DatePicker, Form, Input, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, CalendarIcon } from '../icon';
import { FIELD_KEY, typeProgramOptions, rateCommitOptions } from 'constants/Training';
import { DATE_FORMAT, DATE_FORMAT_YMD } from '..';
import { AiOutlineSearch } from 'react-icons/ai';
import { DEFAULT_PAGE, DEFAULT_LIMIT } from 'constants/index';

const FilterProgram = ({ filter, setFilter }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const mapOptions = (options) =>
    options.map((option) => ({
      ...option,
      label: t(option.label),
    }));

  const handleOnFinish = (values) => {
    form.setFieldsValue({ ...values, name: values.name && values.name.trim() });

    setFilter({
      ...filter,
      ...values,
      name: values.name ? values.name.trim() : undefined,
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
      start_date: values.start_date && values.start_date.format(DATE_FORMAT_YMD),
      end_date: values.end_date && values.end_date.format(DATE_FORMAT_YMD),
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <Form
        form={form}
        name="filter-program"
        layout="vertical"
        size="large"
        className="materialStyle form filter-form"
        onFinish={handleOnFinish}
      >
        <Form.Item name={FIELD_KEY.NAME} className="program-name" label={t('training.findProgramName')}>
          <Input allowClear prefix={<AiOutlineSearch size={20} color="#FEA628" />} />
        </Form.Item>
        <Form.Item name={FIELD_KEY.TYPE} label={t('training.programType')}>
          <Select allowClear options={mapOptions(typeProgramOptions)} suffixIcon={<ArrowDownIcon />} />
        </Form.Item>
        <Form.Item name={FIELD_KEY.RATE_COMMIT} label={t('training.rateCommit')}>
          <Select allowClear options={mapOptions(rateCommitOptions)} suffixIcon={<ArrowDownIcon />} />
        </Form.Item>
        <Form.Item name={FIELD_KEY.START_DATE} label={t('training.startDate')}>
          <DatePicker
            allowClear
            suffixIcon={<CalendarIcon />}
            placeholder=""
            format={DATE_FORMAT}
            disabledDate={(day) => {
              const endDate = form.getFieldValue(FIELD_KEY.END_DATE);
              return day.isSameOrAfter(endDate) && day && endDate;
            }}
          />
        </Form.Item>
        <Form.Item name={FIELD_KEY.END_DATE} label={t('training.endDate')}>
          <DatePicker
            allowClear
            suffixIcon={<CalendarIcon />}
            placeholder=""
            format={DATE_FORMAT}
            disabledDate={(day) => {
              const startDate = form.getFieldValue(FIELD_KEY.START_DATE);
              return day.isSameOrBefore(startDate) && day && startDate;
            }}
          />
        </Form.Item>
      </Form>
      <div className="filter-button">
        <Button
          className="button refresh"
          onClick={() => {
            form.resetFields();
            setFilter({ page: DEFAULT_PAGE, limit: DEFAULT_LIMIT });
          }}
        >
          {t('common.refresh')}
        </Button>
        <Button className="button search" onClick={() => form.submit()}>
          {t('dashboard.search')}
        </Button>
      </div>
    </div>
  );
};

export default FilterProgram;
