import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { WHEAT } from 'constants/ColorForm';
import { BG_POSITION } from 'constants/ColorMap';

function CustomRadioInput({ control, radioList, defaultValue, name, setValue }) {
  return (
    <Controller
      render={({ field }) => (
        <RadioGroup {...field}>
          {radioList.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.value}
              onChange={(e) => setValue(name, e.target.value)}
              control={
                <Radio
                  sx={{
                    color: WHEAT,
                    '&.Mui-checked': {
                      color: BG_POSITION[0],
                    },
                  }}
                />
              }
              label={item.labelName}
            />
          ))}
        </RadioGroup>
      )}
      name={name}
      control={control}
      defaultValue={`${defaultValue}`}
    />
  );
}

export default CustomRadioInput;
