import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import React from 'react';
import classNames from 'classnames';
import { ModalBody } from 'reactstrap';
import styles from './styles.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} direction="up" />;
});

const PopupImage = (props) => {
  const { children, toggle, isShowModal, classNameAdd } = props;
  return (
    <>
      <Dialog
        open={isShowModal}
        onClose={toggle}
        TransitionComponent={Transition}
        keepMounted
        className={classNames(`${styles.ctDialog} ${classNameAdd && classNameAdd}`)}
      >
        <ModalBody className={styles.modalBody}>{children}</ModalBody>
      </Dialog>
    </>
  );
};

export default PopupImage;