import React from 'react';
import { useTranslation } from 'react-i18next';

import Sidebar from 'components/Sidebar';

import iconClock from './../../../assets/img/icons/clock.png';
import iconLaptop from './../../../assets/img/icons/laptop.png';
import iconEmployees from './../../../assets/img/icons/employees_black.png';
import iconSetting from './../../../assets/img/icons/settings.svg';

const SidebarMasterData = () => {
  const { t } = useTranslation();

  const dataRoutes = [
    {
      title: {
        icon: iconClock,
        name: t('masterData.ManageTimekeeping'),
      },
      nav: [
        {
          path: '/admin/master-data/special-time-keeping',
          name: t('masterData.specialTimeKeeping.name'),
        },
        {
          path: '/admin/master-data/data-other',
          name: t('masterData.dataOther'),
        },
        {
          path: '/admin/master-data/holiday',
          name: t('masterData.Holiday'),
        },
        {
          path: '/admin/master-data/over-time',
          name: t('masterData.overTime.manageOverTime'),
        },
      ],
    },
    {
      title: {
        icon: iconLaptop,
        name: t('masterData.equipmentManagement'),
      },
      nav: [
        {
          path: '/admin/master-data/category-asset',
          name: t('masterData.typesOfEquipment'),
        },
        {
          path: '/admin/master-data/warehouse',
          name: t('masterData.warehouse'),
        },
      ],
    },
    {
      title: {
        icon: iconEmployees,
        name: t('masterData.humanResourceManagement'),
      },
      nav: [
        {
          path: '/admin/master-data/position',
          name: t('employees.position'),
        },
        {
          path: '/admin/master-data/category-contact',
          name: t('masterData.typeOfContact'),
        },
        {
          path: '/admin/master-data/type-employees',
          name: t('masterData.typeOfPersonnel'),
        },
        {
          path: '/admin/master-data/skill',
          name: t('masterData.skill'),
        },
        {
          path: '/admin/master-data/category-certificate',
          name: t('masterData.certificateTitle'),
          link: ['/admin/master-data/manager-certificate', '/admin/master-data/category-certificate'],
        },
        {
          path: '/admin/master-data/level',
          name: t('masterData.level'),
        },
        {
          path: '/admin/master-data/group-account',
          name: t('masterData.groupAccount'),
        },
        {
          path: '/admin/master-data/role',
          name: t('masterData.role'),
          link: ['/admin/master-data/detail-users', '/admin/master-data/detail-role'],
        },
      ],
    },
    {
      title: {
        icon: iconSetting,
        name: t('masterData.settings'),
      },
      nav: [
        {
          path: '/admin/master-data/mapping-request',
          name: t('masterData.mappingRequest'),
        }
      ]
    }
  ];

  return (
    <div>
      <Sidebar dataRoutes={dataRoutes} />
    </div>
  );
};

export default SidebarMasterData;
