import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Col, Row, Table, Input } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { gradientLine, optionsLine } from './../../../../variables/ctChats.js';

import iconCalendar from 'assets/img/icons/calendar.png';
import iconChart from 'assets/img/icons/chart.png';
import avatarDefault from 'assets/img/icons/avatar_default.svg';

import './style.scss';
import DateDayComponent from 'components/DateTime/DateDay.js';
import YearPicker from 'components/DateTime/Year';
import { customDate } from 'helpers/formatDate.js';
import { useCelebrationEmployee, useGetTotalEmployeesByMonth } from 'hook/useEmployees.js';
import Loading from 'components/Loading/index.js';
import { OPTION_ALL } from 'constants/index.js';
import iconEmployee from 'assets/img/icons/employees.png';
import iconGender from 'assets/img/icons/gender.png';
import {
  SENIORITY_UNDER_SIX_MONTHS,
  SENIORITY_SIX_TO_TWELVE_MONTHS_MONTHS,
  SENIORITY_ONE_TO_TWO_YEARS_MONTHS,
  SENIORITY_OVER_TWO_YEARS_MONTHS,
} from 'constants/ProfileEmployee';
import { handleOnError } from 'helpers/index.js';
import { useSeniorityAndGender } from 'hook/useEmployees.js';

const Session = (props) => {
  const { t } = useTranslation();
  const { listDivision } = props;
  const [filterCelebration, setFilterCelebration] = useState(moment());
  const [filterTotalEmployeesByMonth, setFilterTotalEmployeesByMonth] = useState({
    year: moment().format('Y'),
    division_id: null,
  });
  const [dataMap, setDataMap] = useState([]);
  const { data: listCelebrationEmployees, isLoading: isLoadingCelebrationEmployees } = useCelebrationEmployee({
    date: customDate(filterCelebration, 'DD/MM/YYYY'),
  });
  const [maxEmployee, setMaxEmployee] = useState(0);

  const { data: uGetTotalEmployeesByMonth } = useGetTotalEmployeesByMonth(filterTotalEmployeesByMonth);

  const labelsMap = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const optionsMap = optionsLine.options;

  const sampleData = (canvas) => {
    return gradientLine(canvas, labelsMap, dataMap);
  };

  useEffect(() => {
    let datas = [];
    let isData = false;
    let max = 0;
    labelsMap?.forEach((month) => {
      isData = false;
      uGetTotalEmployeesByMonth?.data?.forEach((data) => {
        if (Number(moment(data?.date, 'DD-MM-YYYY').format('MM')) === Number(month)) {
          datas.push(data?.total);
          if (Number(data?.total) > 0 && Number(max) < Number(data?.total)) {
            max = data?.total;
          }
          isData = true;
        }
      });
      if (!isData) {
        datas.push(t('common.noData'));
      }
    });
    setMaxEmployee((Number(String(max / 100).slice(0, String(max / 100).indexOf('.'))) + 1) * 100);
    setDataMap([...datas]);
    // eslint-disable-next-line
  }, [uGetTotalEmployeesByMonth]);

  optionsMap.scales = {
    yAxes: [
      {
        gridLines: {
          offsetGridLines: true,
          display: true,
          borderDash: [6, 2],
        },
        ticks: {
          max: maxEmployee,
          min: 0,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          color: '#E7E7E7',
          display: true,
          borderDash: [2, 5],
        },
      },
    ],
  };

  const handelFilterCelebration = (value) => {
    setFilterCelebration(value);
  };

  const { data: statisticalSeniorityGender } = useSeniorityAndGender();

  let totalMale = statisticalSeniorityGender ? statisticalSeniorityGender.total_male : 0;
  let totalFemale = statisticalSeniorityGender ? statisticalSeniorityGender.total_female : 0;
  let totalGender = totalMale + totalFemale;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={12} lg="5" mt={3}>
            <div className="employees-map h-100">
              <div className="box-session border-show box-show-none" style={{ minHeight: '100%' }}>
                <Row>
                  <Col className="col-md-6">
                    <div className="d-flex">
                      <img src={iconChart} alt={iconChart} className="icon" />
                      <h4 className="title-body">{t('employees.PersonnelChangeChartByDivision')}</h4>
                    </div>
                  </Col>
                  <Col className="col-md-6">
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      onChange={(value) => {
                        setFilterTotalEmployeesByMonth({
                          ...filterTotalEmployeesByMonth,
                          division_id: value.target.value > OPTION_ALL ? value.target.value : null,
                        });
                      }}
                      className="input-border filter-employee-division"
                    >
                      <option value={OPTION_ALL}>{t('dashboard.all')}</option>
                      {listDivision &&
                        listDivision?.divisions?.map((division, key) => (
                          <option key={key} value={division.id}>
                            {division.name}
                          </option>
                        ))}
                    </Input>
                  </Col>
                  <Col className="col-md-6"></Col>
                  <Col className="col-md-6">
                    <YearPicker
                      label=""
                      name="year"
                      valueDefault={filterTotalEmployeesByMonth.year}
                      onChangeHandle={(value) =>
                        setFilterTotalEmployeesByMonth({
                          ...filterTotalEmployeesByMonth,
                          year: customDate(value, 'YYYY'),
                        })
                      }
                      placeholder={moment().format('Y')}
                      sizeDate="small"
                      classNameAdd="filter-employee-division"
                    />
                  </Col>
                </Row>
                <Row className="chart-js-line">
                  <label className="employees">{t('common.employees')}</label>
                  <Line data={sampleData} options={optionsLine.options} />
                  <label className="month">{t('common.month')}</label>
                </Row>
              </div>
            </div>
          </Grid>
          <Grid item md={6} lg="3" mt={3}>
            <div className="info-employee statistical-gender box-show-none h-100 d-flex flex-column">
              <div className="box-session border-show total-employees">
                <div className="card-body-top">
                  <div className="d-flex">
                    <img src={iconEmployee} alt={iconEmployee} className="icon" />
                    <h4 className="title-body">{t('employees.NumberOfEmployee')}</h4>
                  </div>
                  <Row>
                    <Col className="col-md-6">
                      <NavLink
                        to={{
                          pathname: '/admin/employees/list',
                          state: {
                            filterSeniority: SENIORITY_UNDER_SIX_MONTHS,
                          },
                        }}
                        className="link-to-employees"
                      >
                        <div className="box mra-5 block">
                          <p className="title color-navy">{t('employees.LessThan6Months')}</p>
                          <p className="info">
                            <span className="color-yellow">
                              {statisticalSeniorityGender ? statisticalSeniorityGender.under_six_month : 0}
                            </span>
                          </p>
                        </div>
                      </NavLink>
                    </Col>
                    <Col className="col-md-6">
                      <NavLink
                        to={{
                          pathname: '/admin/employees/list',
                          state: {
                            filterSeniority: SENIORITY_SIX_TO_TWELVE_MONTHS_MONTHS,
                          },
                        }}
                        className="link-to-employees"
                      >
                        <div className="box mla-5 block">
                          <p className="title color-navy">{t('employees.6-12Months')}</p>
                          <p className="info">
                            <span className="color-yellow">
                              {statisticalSeniorityGender ? statisticalSeniorityGender.six_to_twelve_month : 0}
                            </span>
                          </p>
                        </div>
                      </NavLink>
                    </Col>
                    <Col className="col-md-6">
                      <NavLink
                        to={{
                          pathname: '/admin/employees/list',
                          state: {
                            filterSeniority: SENIORITY_ONE_TO_TWO_YEARS_MONTHS,
                          },
                        }}
                        className="link-to-employees"
                      >
                        <div className="box mra-5 block">
                          <p className="title color-navy">{t('employees.1-2Years')}</p>
                          <p className="info">
                            <span className="color-yellow">
                              {statisticalSeniorityGender ? statisticalSeniorityGender.one_to_two_year : 0}
                            </span>
                          </p>
                        </div>
                      </NavLink>
                    </Col>
                    <Col className="col-md-6">
                      <NavLink
                        to={{
                          pathname: '/admin/employees/list',
                          state: {
                            filterSeniority: SENIORITY_OVER_TWO_YEARS_MONTHS,
                          },
                        }}
                        className="link-to-employees"
                      >
                        <div className="box mla-5 block">
                          <p className="title color-navy">{t('employees.Over2Years')}</p>
                          <p className="info">
                            <span className="color-yellow">
                              {statisticalSeniorityGender ? statisticalSeniorityGender.since_two_year : 0}
                            </span>
                          </p>
                        </div>
                      </NavLink>
                    </Col>
                  </Row>
                </div>
              </div>

              <div style={{ flex: 1 }} />

              <div className="box-session border-show mt-3">
                <div className="card-body-top">
                  <div className="d-flex">
                    <img src={iconGender} alt={iconGender} className="icon" />
                    <h4 className="title-body title-body-height">{t('employees.StatisticsBySex')}</h4>
                  </div>
                  <Table>
                    <tbody>
                      <tr>
                        <td className="text-center border-right w-50">
                          <div className="info-gender">
                            <div className="img-man">
                              <div className="color-blue1">{totalMale}</div>
                              <div className="color-blue1 fs-16">
                                {t('common.male')}{' '}
                                <span>
                                  {totalGender ? `(${((totalMale / (totalMale + totalFemale)) * 100).toFixed()}%)` : ''}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="info-gender">
                            <div className="img-woman">
                              <div className="color-red">{totalFemale} </div>
                              <div className="color-red fs-16">
                                {t('common.female')}{' '}
                                {totalGender ? `(${((totalFemale / (totalMale + totalFemale)) * 100).toFixed()}%)` : ''}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} lg="4" mt={3}>
            <div className="celebrate-list h-100 employee-list box-show-none">
              <div className="box-session border-show box-list">
                <div className="card-body-top">
                  <Row>
                    <Col className="col-md-6">
                      <div className="d-flex">
                        <img src={iconCalendar} alt={iconCalendar} className="icon" />
                        <h4 className="title-body">{t('employees.CelebratingEmployeeMilestones')}</h4>
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <DateDayComponent
                        label=""
                        valueDefault={filterCelebration}
                        onChangeHandle={handelFilterCelebration}
                      />
                    </Col>
                  </Row>
                  <div className="text-employee info-top-body">
                    <p className="text-truncate">{t('employees.TotalForTheDay')}</p>
                    <p className="number-employee">
                      {listCelebrationEmployees?.data?.length ? listCelebrationEmployees?.data?.length : 0}
                    </p>
                  </div>
                </div>
                <div className="ct-scroll-table">
                  <Table className="align-items-center border-grey table-fixed table-hover">
                    <tbody>
                      {listCelebrationEmployees &&
                        listCelebrationEmployees.data.map((celebrationEmployees, index) => (
                          <tr key={index}>
                            <td className="">
                              <NavLink
                                to={{
                                  pathname: '/admin/employees/profile/' + celebrationEmployees.user_id,
                                }}
                              >
                                <div className="employee-info">
                                  <div className="avatar ct-avatar">
                                    <img
                                      onError={handleOnError}
                                      src={celebrationEmployees.avatar ? celebrationEmployees.avatar : avatarDefault}
                                      alt={celebrationEmployees.avatar ? celebrationEmployees.avatar : avatarDefault}
                                    />
                                  </div>
                                  <div className="name-division">
                                    <p className="name">{celebrationEmployees.name}</p>
                                    <p className="division">
                                      {' '}
                                      {celebrationEmployees.position_name} - {celebrationEmployees.division_name}
                                    </p>
                                  </div>
                                </div>
                              </NavLink>
                            </td>
                            <td className="text-right text-date">
                              {celebrationEmployees.year} {t('common.year')}
                            </td>
                          </tr>
                        ))}
                      {isLoadingCelebrationEmployees && (
                        <tr>
                          <td colSpan={3}>
                            <Loading addClass="mt-2" />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Session;
