import { CANCEL } from 'constants/Assets';
import { LIST_TYPE_GOOD } from 'constants/Assets';
import { sendGet, sendPost, sendPut, sendCustom, sendDelete } from '../helpers/axios';

export const apiGetListCategoryAssets = (params) => sendGet('/api/asset-category', params);
export const apiGetListGenerateCode = (id, params) =>
  sendGet(`/api/asset/asset-generate-code?asset_category_id=${id}`, params);
export const apiGetListHrWarehouse = (params) => sendGet('/api/hr-warehouse', params);
export const apiCreateAssets = (params) => sendPost('/api/asset', params);
export const apiEditAssets = (assetsId, params) => sendPut(`/api/asset/${assetsId}`, params);
export const apiDetailHistoryAssets = (assetsId, params) => sendGet(`/api/asset/${assetsId}/history`, params);
export const apiDetailAssets = (assetsId, params) => sendGet(`/api/asset/${assetsId}/detail`, params);

export const apiImportAssets = (params) => sendPost(`/api/asset/import`, params);
export const apiImportAssetsForRoleEdit = (params) => sendPost(`/api/import-asset`, params);
export const apiListAssets = (params) => sendGet('/api/list-asset', params);
export const apiListCategoryAssets = (params) => sendGet('/api/count-asset-category', params);
export const apiListTypeAssets = (params) => sendGet('/api/count-asset-type', params);
export const apiListWareHouse = (params) => sendGet('/api/hr-warehouse', params);
export const apiDownloadTemplateImportAssets = (data) =>
  sendCustom({
    url: '/api/asset/download/template/import?link=https://acms-s3.s3.ap-southeast-1.amazonaws.com/template_import_asset.xlsx',
    method: 'GET',
    responseType: 'blob',
    Accept: 'application/octet-stream',
    params: data,
  });

export const apiListBriefRentPrice = (params) => sendGet('/api/asset/list-brief-rent-price', params);
export const apiListDetailRentPrice = (params) => sendGet('/api/asset/list-detail-rent-price', params);
export const apiTotalAssetUsingOfDivison = (params) => sendGet('/api/asset/total-asset-using-of-division', params);

// Depreciation table
export const apiListAssetsDepreciation = (params) => sendGet('/api/asset-table-depreciation', params);
export const apiListCategoryDepreciation = (params) => sendGet('/api/asset-table-depreciation-category', params);

// History assets
export const apiHistoryAssets = (pageParam, params) =>
  sendGet(`/api/asset/history?page=${pageParam}&per_page=16`, params);

export const apiGetTotalAssetDashBoard = (params) => sendGet('/api/asset-dashboard-total', params);
export const apiGetTotalAssetCategory = (params) => sendGet('/api/asset-dashboard-category', params);
export const apiGetTotalAssetDivision = (params) => sendGet('/api/asset-of-division', params);
export const apiGetTotalAssetUser = (params) => sendGet('/api/asset-of-user', params);

// Export import inventory
export const apiGetExportImportInventory = (params) => sendGet('/api/table-asset-in-out', params);
export const apiGetListRequestAssets = (params) => sendGet('/api/asset/hr-request', params);
export const apiGetTotalListRequestAssets = (params) => sendGet('/api/asset/hr-request/total', params);
export const apiReCallAssets = (params) => sendPost('/api/asset/recover-asset', params);
export const apiApproveAssets = (params) => sendPut(`/api/asset/approve/${params?.id}`, {
  ...params,
  type : LIST_TYPE_GOOD,
});
export const apiCancelAssets = (params) => sendPut(`/api/asset/${params?.id}`, {
  ...params,
  type : CANCEL,
});

export const apiDeleteAssets = (params) => sendDelete(`/api/asset/${params?.id}`, {
  ...params,
  type : CANCEL,
});
export const apiReCallAllAssets = (params) => sendPost('/api/asset/recover/user', params);
export const apiGetAssetRentalPrice = (params) => sendGet('/api/asset-rental-price/list', params)
export const apiGetAssetRentalPriceAll = (params) => sendGet('/api/asset-rental-price/all', params)
export const apiDeleteAssetRentalPrice = (id) => sendDelete(`/api/asset-rental-price/${id}/delete`)
export const apiCreateAssetRentalPrice = (data) => sendPost(`/api/asset-rental-price/create`, data)
export const apiUpdateAssetRentalPrice = (id, data) => sendPut(`/api/asset-rental-price/${id}/update`, data)
export const apiImportAssetRentalPrice = (data, additionalConfig) => sendCustom({
  url: '/api/asset-rental-price/import',
  method: 'POST',
  Accept: 'application/octet-stream',
  data,
  ...additionalConfig,
})
