import { Row, Input, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import iconArrowLeft from 'assets/img/icons/arrow-left.png';
import iconArrowRight from 'assets/img/icons/arrow-right.png';
import { PER_PAGE_20, PER_PAGE_40, PER_PAGE_60 } from 'constants/ListEmployees';

import styles from './styles.module.scss';

const PerPage = (props) => {
  const { t } = useTranslation();
  const { data, page, perpage, changePerPage, changePage, classAdd } = props;

  const changePageComponent = (value) => {
    changePage(value);
  };

  return (
    <>
      <Row className={classAdd ? classAdd : ''} sm="12">
        <Col sm="6" className={styles.perPage}>
          <p className={styles.inforTotal}>
            {(data ? data.from : 0) + ' - ' + (data ? data.to : 0) + ' from ' + (data ? data.total : 0)}
          </p>
        </Col>
        <Col sm="6" className={styles.arrowPage}>
          <span className={styles.dropPerPage}>
            {data && data.total && data.total > PER_PAGE_20 && (
              <>
                <span className={styles.textRowPerPage}>{t('employees.rowsPerPage')}</span>
                <Input
                  type="select"
                  onChange={changePerPage}
                  className={`${styles.perpage} ${(data.to - data.from) % 2 !== 0 ? '' : styles.custom}`}
                  defaultValue={perpage}
                >
                  <option value={PER_PAGE_20}>{20}</option>
                  <option value={PER_PAGE_40}>{40}</option>
                  <option value={PER_PAGE_60}>{60}</option>
                </Input>
              </>
            )}
          </span>
          {data && data.total > perpage && (
            <>
              <span>
                <img
                  src={iconArrowLeft}
                  alt="arrow"
                  onClick={() => changePageComponent(page >= 2 && page - 1)}
                  className={styles.arrowLeft}
                />
              </span>
              <span>
                <img
                  src={iconArrowRight}
                  alt="arrow"
                  onClick={() => changePageComponent(page * perpage < data.total && page + 1)}
                  className={styles.arrowRight}
                />
              </span>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default PerPage;
