export const EXPORT = 'export';
export const EMPLOYEE_CHILDREN = 'employeeChildren';
export const GENDER = 'gender';
export const SENIORITY = 'seniority';
export const POSITION = 'position';
export const STATUS = 'status';
export const SKILL = 'skill';
export const LEVEL = 'level';
export const CERTIFICATE = 'certificate';
export const ADD_EMPLOYEE = 'addEmployee';
export const ALL = 'all';
export const IMPORT = 'importEmployee';
export const GROUP = 'group';

export const USE_LIST_DIVISION = 'list_division';
export const USE_LIST_USERS = 'list_users';
export const USE_LIST_POSITION = 'list_position';
export const USE_LIST_LEVEL = 'list_level';
export const USE_LIST_SKILL = 'list_skill';
export const USE_LIST_CERTIFICATE = 'list_certificate';
export const USE_REQUEST_GET_LIST_USER = 'request_get_list_user';

export const USE_GET_STATUS_TEAM_OF_DIVISION = 'use_get_status_team_of_division';
export const USE_GET_TOTAL_EMPLOYEES_BY_MONTH = 'USE_GET_TOTAL_EMPLOYEES_BY_MONTH';
export const USE_LIST_EMPLOYEES_GET_GROUP_USER = 'USE_LIST_EMPLOYEES_GET_GROUP_USER';
export const USE_LIST_STANDBY_EMPLOYEES = 'USE_LIST_STANDBY_EMPLOYEES';

export const EMPLOYEE_DASHBOARD = 0;
export const EMPLOYEE_LIST = 1;
export const EMPLOYEE_STANDBY = 2;

export const PER_PAGE_20 = 20;
export const PER_PAGE_40 = 40;
export const PER_PAGE_60 = 60;

export const NONE_MONTH = -1;
export const LESS_THAN_6_MONTH = 0.5;
export const MONTHS_6_TO_12 = 1;
export const YEAR_1_TO_2 = 2;

export const BY_MONTH = 1;
export const BY_YEAR = 2;

export const EMPLOYEE_DASHBOARD_PATH = '/admin/employees/dashboard';
export const EMPLOYEE_LIST_PATH = '/admin/employees/list';
export const EMPLOYEE_STANDBY_PATH = '/admin/employees/standby';
export const INIT_FOR_PAGING = 0;