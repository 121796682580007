import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { HTTP_OK } from 'constants/StatusCode';
import { apiSendMailOT } from 'api/sendMail';
import ButtonComponent from 'components/Buttons';
import ModalComponent from 'components/Modal';
import AlertComponent from 'components/Alert/index.js';
import styles from './styles.module.scss';

const editorConfiguration = {
  toolbar: ['heading', 'bold', 'italic', 'numberedList', 'undo', 'redo'],
};

const SendMailOT = (props) => {
  const { t } = useTranslation();
  const { isShowModal, setIsShowModal, textModal, overtimes } = props;
  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');

  const handleCKeditorState = (_, editor) => {
    const textEditor = editor.getData();
    setContent(textEditor);
  };

  const handleSubmitForm = async () => {
    setIsLoading(true);
    const _overtimes = [];
    for (const overtime of overtimes) {
      _overtimes.push({
        user_id: overtime.user_id,
        email: overtime.email,
        user_name: overtime.user_name,
        project_name: overtime.project_name,
        ...overtime.overtime_date[0],
      });
    }
    const response = await apiSendMailOT({
      overtimes: _overtimes,
      content_email: content || '',
    });
    if (response?.status === HTTP_OK) {
      setMessage(t('timekeeping.sendMailSuccess'));
      setShowAlert(true);
      setIsLoading(false);
    } else {
      setMessage(t('timekeeping.sendMailFailed'));
      setShowAlert(true);
      setIsLoading(false);
    }
  };
  return (
    <>
      <ModalComponent
        title={textModal}
        isShowModal={isShowModal}
        classNameAdd={styles.sendMailModal}
        toggle={() => setIsShowModal(!isShowModal)}
      >
        <form>
          <div className={styles.header}>
            <div>
              <div className={styles.titleHeader}>{t('timekeeping.information')}</div>
              <div className={styles.titleHoursOT}>
                {t('timekeeping.OT.totalOTHoursMultiplier')} :{' '}
                {overtimes.reduce((init, current) => init + current.overtime_date[0].value, 0)}
              </div>
            </div>
          </div>
          <div className={styles.mainContent}>
            <div className={styles.textContent}>{t('timekeeping.OT.contentMailOT')}</div>
            <div>
              <CKEditor
                editor={ClassicEditor}
                data={''}
                config={editorConfiguration}
                onChange={(event, editor) => handleCKeditorState(event, editor)}
              />
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.bodyFooter}>{t('timekeeping.OT.templateOT')}</div>
            <TableContainer>
              <Table>
                <TableHead className={styles.footerHeaderTable}>
                  <TableRow className={styles.footerHeaderTableRow}>
                    <TableCell>{t('timekeeping.OT.tableSendMail.date')}</TableCell>
                    <TableCell>{t('timekeeping.OT.tableSendMail.time')}</TableCell>
                    <TableCell>{t('timekeeping.OT.tableSendMail.project')}</TableCell>
                    <TableCell>{t('timekeeping.OT.tableSendMail.actualOTHours')}</TableCell>
                    <TableCell>{t('timekeeping.OT.tableSendMail.numberOfOTHoursMultiplier')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {overtimes.map((overtime, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{overtime.overtime_date[0].hr_date_log}</TableCell>
                      <TableCell>
                        {overtime.overtime_date[0].hr_start_time_log +
                          ' - ' +
                          overtime.overtime_date[0].hr_end_time_log}
                      </TableCell>
                      <TableCell>{overtime.project_name}</TableCell>
                      <TableCell>{overtime.overtime_date[0].total}</TableCell>
                      <TableCell>{overtime.overtime_date[0].value}</TableCell>
                    </TableRow>
                  ))}
                  {!overtimes?.length ? (
                    <TableRow>
                      <TableCell colSpan={5} className="error-no-data text-center">
                        {t('common.notData')}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.btnBottom}>
              <ButtonComponent
                text={t('timekeeping.cancelMail')}
                addClass={styles.cancel}
                handleClick={() => setIsShowModal(false)}
              />
              <ButtonComponent
                type="submit"
                text={t('timekeeping.sendMail')}
                addClass={styles.submit}
                hasDisabled={isLoading}
                isLoading={isLoading}
                handleClick={handleSubmitForm}
              />
            </div>
          </div>
        </form>
      </ModalComponent>
      <AlertComponent
        toggle={() => {
          setShowAlert(!isShowAlert);
          setIsShowModal(false);
        }}
        isShowAlert={isShowAlert}
      >
        {message}
      </AlertComponent>
    </>
  );
};

export default SendMailOT;
