import '../style.scss';
import { MdClose } from 'react-icons/md';
import { Form, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_YMD } from '..';
import { FIELD_KEY, CERTIFICATE, COURSE_OUTSIDE_COMPANY } from 'constants/Training';
import { useMutation } from 'react-query';
import { apiUpdateCourse } from 'api/training';
import { useEffect } from 'react';
import moment from 'moment';
import FormProgram from './FormProgram';

const UpdateProgram = ({ isOpen, setIsOpen, refetch, setIsDisable, selectedCourse }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    let fields = {
      ...selectedCourse,
      [FIELD_KEY.START_DATE]: selectedCourse.start_date ? moment(selectedCourse.start_date):'',
      [FIELD_KEY.END_DATE]: selectedCourse.end_date ? moment(selectedCourse.end_date):'',
    };

    form.setFieldsValue(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isOpen, selectedCourse]);

  const updateCourseMutation = useMutation((data) => apiUpdateCourse(data.payload, data.id), {
    onSuccess: (res) => {
      message.success(res.data.messages);
      refetch();
      handleCancel();
    },
    onError: (error) => {
      message.error(error.response.data.messages);
    },
  });

  const handleFinish = (values) => {
    const type = values[FIELD_KEY.TYPE];

    let payload = {
      type,
      month_commit: values.month_commit,
      support_cost: values.support_cost,
      rate_commit: values.rate_commit,
      description: values.description?.trim(),
    };

    if (type === CERTIFICATE) {
      payload = {
        ...payload,
        certificate_id: values.certificate_id,
        certificate_catalog: values.certificate_catalog,
      };
    } else {
      payload = {
        ...payload,
        name: values.name?.trim(),
        start_date: values.start_date.format(DATE_FORMAT_YMD),
        end_date: values.end_date.format(DATE_FORMAT_YMD),
      };

      if (type === COURSE_OUTSIDE_COMPANY) {
        payload = {
          ...payload,
          location: values.location?.trim(),
        };
      }
    }

    updateCourseMutation.mutate({ payload, id: selectedCourse.id });
  };

  const handleCancel = () => {
    setIsDisable(false);
    setIsOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      wrapClassName={'custom-modal'}
      title={t('training.updateProgram')}
      closeIcon={<MdClose color="#fea628" size={24} />}
      cancelButtonProps={{ className: 'btn-cancel' }}
      cancelText={t('common.cancel')}
      okButtonProps={{ className: 'btn-ok', loading: updateCourseMutation.isLoading }}
      okText={t('common.save')}
      onCancel={handleCancel}
      onOk={() => {
        form.submit();
      }}
      visible={isOpen}
      centered
      width={470}
    >
      <FormProgram
        form={form}
        name={'update-program'}
        handleFinish={handleFinish}
        type={selectedCourse[FIELD_KEY.TYPE]}
        certCatalog={selectedCourse[FIELD_KEY.CERTIFICATE_CATALOG]}
      />
    </Modal>
  );
};

export default UpdateProgram;
