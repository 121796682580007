import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import NavComponent from './../../../components/Nav';
import styles from './style.module.scss';

const Nav = () => {
  const { t } = useTranslation();

  const params = useParams();

  const userId = params.userId;

  const routes = [
    {
      path: `/admin/employees/profile/${userId}`,
      name: t('infoEmployee.tabEmployeeInfo'),
      tab: 1,
    },
    {
      path: `/admin/employees/assets/${userId}`,
      name: t('infoEmployee.tabEmployeeAsset'),
      tab: 2,
    },
    {
      path: `/admin/employees/projects/${userId}`,
      name: t('infoEmployee.tabEmployeeProject'),
      tab: 3,
    },
    {
      path: '/admin/employees/monthly-evaluation',
      name: t('infoEmployee.tabEmployeeME'),
      tab: 4,
    },
  ];

  return (
    <div className={styles.navMenuProfile}>
      <NavComponent routes={routes} />
    </div>
  );
};

export default Nav;
