import React from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Breadcrumb = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.containerRoot}>
      <div className={styles.linkBread}>
        <NavLink to={{ pathname: '/admin/index' }}>{t('common.hr')}</NavLink>
        &nbsp; / &nbsp;
        <NavLink to={{ pathname: '/admin/timekeeping/time-sheets' }}>{t('masterData.ManageTimekeeping')}</NavLink>
        &nbsp; / &nbsp;
        <div className={styles.pageCurrent}>{t('timekeeping.timeSheetDetail')}</div>
      </div>
    </div>
  );
};

export default Breadcrumb;
