import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { BiLinkExternal } from 'react-icons/bi';
import { IoIosAdd } from 'react-icons/io';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputIcon from '@mui/icons-material/Input';
import Policy from 'views/pages/Policy';
import styles from 'views/pages/Policy/styles.module.scss';
import { ROLE_VIEW, ROLE_EDIT, DISABLE_BUTTON_ROLE } from 'constants/index.js';

const PolicyHeader = ({ onClickImport, onClickExport, onClickAdd, permission }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container className="nav-fixed pb-4">
        <Grid container className="align-items-center">
          <Grid item md={6} xs={12}>
            <Policy />
          </Grid>
          <Grid item md={6} xs className={styles.groupButton}>
            <Button className={`${permission !== ROLE_VIEW && styles.ctButton} ${permission === ROLE_VIEW && DISABLE_BUTTON_ROLE}`}
              disabled={permission !== ROLE_EDIT} onClick={onClickImport}>
              <InputIcon />
              {t('common.import')}
            </Button>
            <Button className={styles.ctButton} onClick={onClickExport}>
              <BiLinkExternal />
              {t('common.export')}
            </Button>
            {typeof(onClickAdd) !== 'undefined' && <Button className={`${permission !== ROLE_VIEW && styles.ctButton} ${permission === ROLE_VIEW && DISABLE_BUTTON_ROLE}`}
              disabled={permission !== ROLE_EDIT} onClick={onClickAdd}>
              <IoIosAdd />
              {t('common.labelCreate')}
            </Button>}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PolicyHeader;
