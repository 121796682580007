import { sendCustom, sendGet } from '../helpers/axios';

export const apiSeniorityAndGender = (params) => sendGet('/api/user-dashboard', params);
export const apiListBirthday = (params) => sendGet('/api/user-dashboard/list-birthday', params);
export const apiTotalEmployees = (params) => sendGet('/api/user-dashboard/division-member', params);
export const apiCelebrationEmployee = (params) => sendGet('/api/list-user-anniversary', params);
export const apiPositionLevel = (params) => sendGet('/api/user-dashboard/position-level', params);

export const apiGetUsers = (params) => sendGet('/api/user/list-mongo', params);
export const apiGetPosition = (params) => sendGet('/api/list/position', params);
export const apiGetLevel = (params) => sendGet('/api/list/level', params);
export const apiGetSkill = (params) => sendGet('/api/list/skill', params);
export const apiGetGroupUser = (params) => sendGet('/api/group-users', params);
export const apiGetCertificate = (params) => sendGet('/api/list/certificate', params);
export const apiGetDivision = (params) => sendGet('/api/total/list/user', params);
export const apiGetRequestListUser = (params) => sendGet('/api/request/list-users', params);
export const apiGetStatusTeamOfDivision = (params) =>
  sendGet('/api/check-division-team?division_id=' + params.division_id, params);
export const apiDownloadTemplateImportUser = (data) =>
  sendCustom({
    url: '/api/download/template',
    method: 'GET',
    responseType: 'blob',
    Accept: 'application/octet-stream',
    params: data,
  });

export const apiGetTotalEmployeesByMonth = (params) => sendGet(`/api/personnel-change`, params);
