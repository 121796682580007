import React, { useContext } from 'react';
import { Col, Row, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

import './style.scss';
import DateDayMonthComponent from 'components/DateTime/DateDayMonth.js';
import ButtonComponent from 'components/Buttons';
import { TIME_DATA_OTHER } from 'constants/MasterData.js';
import { NOT_DATA } from 'constants/index.js';
import iconAddCircle from 'assets/img/icons/circle_add.png';
import iconDeleteCircle from 'assets/img/icons/circle_delete.png';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';
import TimeComponentCustom from 'components/DateTime/TimeComponentCustom';

const formatTime = 'HH:mm';

const NEW_DATA_OTHER = {
  id: 0,
  start_date: null,
  end_date: null,
  hour_start_morning: moment(TIME_DATA_OTHER.hour_start_morning, formatTime),
  hour_end_morning: moment(TIME_DATA_OTHER.hour_end_morning, formatTime),
  hour_start_afternoon: moment(TIME_DATA_OTHER.hour_start_afternoon, formatTime),
  hour_end_afternoon: moment(TIME_DATA_OTHER.hour_end_afternoon, formatTime),
};

const BoxDataOther = (props) => {
  const { t } = useTranslation();
  const dataInforUser = useContext(ThemContext);
  const { listSchedule, onChangeWorkingTime, onDelete } = props;

  const handleChangeAdd = () => {
    onChangeWorkingTime([...listSchedule, { ...NEW_DATA_OTHER }]);
  };

  const handleChangeInput = (date, index, name) => {
    let _data = [
      ...listSchedule.filter((item, key) => {
        if (key === index) {
          item[name] = date;
        }
        return item;
      }),
    ];
    onChangeWorkingTime(_data);
  };

  const handleChangeDelete = (index) => {
    if (listSchedule.length > 1) {
      onChangeWorkingTime([...listSchedule.filter((item, key) => key !== index)]);
    }
  };

  const handleChangeDeleteData = (id) => {
    onDelete(id);
  };

  return (
    <div>
      {listSchedule &&
        listSchedule.map((item, index) => {
          let _hr = '';
          if (index >= 1) {
            _hr = <hr />;
          }

          return (
            <div key={index}>
              {_hr}
              <Row>
                <Col lg={6} xl={5}>
                  <div className="box-content">
                    <Label className="text-bold color-navy">{t('masterData.timeWork') + ' ' + (index + 1)}</Label>
                    <FormGroup>
                      <Label className="text-bold color-navy">{t('common.month')}</Label>
                      <Row>
                        <Col className="col-md-6">
                          <DateDayMonthComponent
                            label=""
                            valueDefault={
                              typeof item.start_date === 'string' ? moment(item.start_date, 'DD/MM') : item.start_date
                            }
                            onChangeHandle={(date) => handleChangeInput(date, index, 'start_date')}
                            error={item?.start_date_error}
                            placeholder={t('masterData.placeholderStartDate')}
                            disable={index === NOT_DATA ? true : false}
                          />
                        </Col>
                        <Col className="col-md-6">
                          <DateDayMonthComponent
                            label=""
                            valueDefault={
                              typeof item.end_date === 'string' ? moment(item.end_date, 'DD/MM') : item.end_date
                            }
                            onChangeHandle={(date) => handleChangeInput(date, index, 'end_date')}
                            error={item?.end_date_error}
                            placeholder={t('masterData.placeholderEndDate')}
                            disable={dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW ? true : false}
                          />
                        </Col>
                        <Col className="col-md-12 error">{item?.date_error}</Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="mt-3">
                      <Label className="text-bold color-navy">{t('masterData.morningShift')}</Label>
                      <Row>
                        <Col className="col-md-6">
                          <TimeComponentCustom
                            label=""
                            placeholder={t('masterData.placeholderStartTime')}
                            format="HH:mm"
                            handleChangeTime={(time) =>
                              handleChangeInput(time ? moment(time).format('HH:mm') : null, index, 'hour_start_morning')
                            }
                            value={item.hour_start_morning ? moment(item.hour_start_morning, formatTime) : null}
                            error={item?.hour_start_morning_error}
                            disable={dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW ? true : false}
                          />
                        </Col>
                        <Col className="col-md-6 color-navy">
                          <TimeComponentCustom
                            label=""
                            placeholder={t('masterData.placeholderEndTime')}
                            format="HH:mm"
                            handleChangeTime={(time) =>
                              handleChangeInput(time ? moment(time).format('HH:mm') : null, index, 'hour_end_morning')
                            }
                            value={item.hour_end_morning ? moment(item.hour_end_morning, formatTime) : null}
                            error={item?.hour_end_morning_error}
                            disable={dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW ? true : false}
                          />
                        </Col>
                        <Col className="col-md-12 error">{item?.time_error}</Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="mt-3">
                      <Label className="text-bold color-navy">{t('masterData.afternoonShift')}</Label>
                      <Row>
                        <Col className="col-md-6">
                          <TimeComponentCustom
                            label=""
                            placeholder={t('masterData.placeholderStartTime')}
                            format="HH:mm"
                            handleChangeTime={(time) =>
                              handleChangeInput(
                                time ? moment(time).format('HH:mm') : null,
                                index,
                                'hour_start_afternoon',
                              )
                            }
                            value={item.hour_start_afternoon ? moment(item.hour_start_afternoon, formatTime) : null}
                            error={item?.hour_start_afternoon_error}
                            disable={dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW ? true : false}
                          />
                        </Col>
                        <Col className="col-md-6">
                          <TimeComponentCustom
                            label=""
                            placeholder={t('masterData.placeholderEndTime')}
                            format="HH:mm"
                            handleChangeTime={(time) =>
                              handleChangeInput(time ? moment(time).format('HH:mm') : null, index, 'hour_end_afternoon')
                            }
                            // setValue={setValue}
                            value={item.hour_end_afternoon ? moment(item.hour_end_afternoon, formatTime) : null}
                            error={item?.hour_end_afternoon_error}
                            disable={dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW ? true : false}
                          />
                        </Col>
                        <Col className="col-md-12 error">{item?.time_afternoon_error}</Col>
                      </Row>
                    </FormGroup>
                  </div>
                </Col>
              </Row>

              {index >= 1 && (
                <Row>
                  <Col lg={6} xl={5} className="mt-3">
                    <ButtonComponent
                      text={t('masterData.clearWorkingTime')}
                      addClass={`${
                        dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                      } button-delete-box`}
                      imgIcon={iconDeleteCircle}
                      handleClick={() => {
                        if (dataInforUser?.hr_permission?.master_data_permission !== ROLE_VIEW) {
                          item.id ? handleChangeDeleteData(item.id) : handleChangeDelete(index);
                        }
                      }}
                    />
                  </Col>
                </Row>
              )}
            </div>
          );
        })}

      <Row>
        <Col lg={6} xl={5} className="mt-3">
          <ButtonComponent
            text={t('masterData.addWorkingTime')}
            addClass={`${
              dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
            } button-add-box`}
            imgIcon={iconAddCircle}
            handleClick={dataInforUser?.hr_permission?.master_data_permission !== ROLE_VIEW && handleChangeAdd}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BoxDataOther;
