import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Table, Row, Form, Label } from 'reactstrap';
import { useQueryClient } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import { IoIosAdd } from 'react-icons/io';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import $ from 'jquery';

import Sidebar from './../Sidebar.js';
import SearchComponent from 'components/Search';
import ButtonComponent from 'components/Buttons';
import './style.scss';
import ModalComponent from 'components/Modal/index.js';
import Loading from 'components/Loading';
import {
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_OK,
  HTTP_NOT_ACCESS,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_EXPECTATION_FAILED,
} from 'constants/StatusCode.js';
import AlertComponent from 'components/Alert/index.js';
import { apiCreateHoliday, apiUpdateHoliday, apiDeleteHoliday } from 'api/masterData';
import { USE_LIST_HOLIDAY } from 'constants/MasterData';
import { useGetHoliday } from 'hook/useMasterData.js';
import { customDate } from 'helpers/formatDate.js';
import DateYearComponent from 'components/DateTime/DateYear.js';
import { MAX_LENGTH_20 } from 'constants/ProfileEmployee.js';
import { isNotNullObject } from 'helpers/index';
import InputField from 'components/Input/InputField.js';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE, DISABLE_BUTTON_ROLE } from 'constants/index.js';

const DEFAULT_FORM_DATA = {
  id: 0,
  name: '',
  start_date: null,
  end_date: null,
  error_name: '',
  error_start: '',
  error_end: '',
};

const DEFAULT_FORM_DATA_ERROR = {
  error_name: '',
  error_start: '',
  error_end: '',
};

const Holiday = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isShowModal, setShowModal] = useState(false);
  const [stateButton, setStateButton] = useState(false);
  const dataInforUser = useContext(ThemContext);
  const [filter, setFilter] = useState({
    filter: moment().format('YYYY'),
    key_word: '',
  });
  const [focus, setFocus] = useState({ start_date: false, end_date: false });
  const { data: uListHoliday, isLoading: isLoadingListHoliday } = useGetHoliday(filter);
  const scrollBottom = useRef(null)
  const [formAdd, setFormAdd] = useState(DEFAULT_FORM_DATA);

  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [textModal, setTextModal] = useState({
    title: t('masterData.moreHoliday'),
    btn_submit: t('common.add'),
  });

  const handleSearch = (value) => {
    if (value.length > MAX_LENGTH_20) {
      return;
    }
    setFilter({ ...filter, key_word: value });
  };

  const handleSelectSearch = (value) => {
    value && setFilter({ ...filter, filter: customDate(value, 'YYYY') });
  };

  const openAddHoliday = () => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setShowModal(true);
    setFormAdd({
      ...formAdd,
      ...DEFAULT_FORM_DATA,
    });
    setTextModal({
      title: t('masterData.moreHoliday'),
      btn_submit: t('common.add'),
    });
  };

  function openEditHoliday(holiday) {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setShowModal(true);
    setTextModal({
      title: t('masterData.updatingHoliday'),
      btn_submit: t('common.update'),
    });
    setFormAdd({
      ...formAdd,
      id: holiday.id,
      name: holiday.name,
      start_date: moment(holiday.start_date, 'DD/MM/YYYY'),
      end_date: moment(holiday.end_date, 'DD/MM/YYYY'),
      ...DEFAULT_FORM_DATA_ERROR,
    });
  }

  const handleInput = (e) => {
    setFormAdd({ ...formAdd, [e.target.name]: e.target.value, error_name: '' });
  };

  const alertConfirm = (id) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    confirmAlert({
      title: t('infoEmployee.youWantToDelete'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteHoliday(id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_LIST_HOLIDAY);
            }

            if (
              response?.data.status === HTTP_INTERNAL_SERVER_ERROR ||
              response?.data.status === HTTP_EXPECTATION_FAILED
            ) {
              setMessage(response?.data?.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_LIST_HOLIDAY);
            }
          },
        },
      ],
    });
  };

  function validate() {
    var messages = {};
    var focus = true;
    if (!formAdd.name) {
      messages.error_name = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (formAdd.name.length > MAX_LENGTH_20) {
      messages.error_name = t('common.pleaseEnterLessThanTwentyCharacters');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (!formAdd.start_date) {
      messages.error_start = t('common.pleaseSelectField');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (!formAdd.end_date) {
      messages.error_end = t('common.pleaseSelectField');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (isNotNullObject(messages)) {
      setFormAdd({
        ...formAdd,
        ...messages,
      });
      return false;
    }
    return true;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormAdd({ ...formAdd, ...DEFAULT_FORM_DATA_ERROR });
    setStateButton(true);

    if (!validate()) {
      setStateButton(false);
      return;
    }

    setFormAdd({ ...formAdd, ...DEFAULT_FORM_DATA_ERROR });

    const data = {
      name: formAdd.name.trim(),
      start_date: customDate(formAdd.start_date, 'DD/MM/YYYY'),
      end_date: customDate(formAdd.end_date, 'DD/MM/YYYY'),
    };

    let response = {};
    if (!formAdd.id) {
      response = await apiCreateHoliday(data);
    } else {
      response = await apiUpdateHoliday(formAdd.id, data);
    }

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      var focus = true;
      if (msg?.name) {
        if (focus) {
          $('input[name=name]').focus();
          focus = false;
        }
      } else if (msg?.start_date) {
        if (focus) {
          $('input[name=start_date]').focus();
          focus = false;
        }
      } else if (msg?.end_date) {
        if (focus) {
          $('input[name=end_date]').focus();
          focus = false;
        }
      }
      setFormAdd({
        ...formAdd,
        error_name: msg?.name
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_start: msg?.start_date
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_end: msg?.end_date
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModal(false);
      setStateButton(false);
      setFormAdd({
        ...formAdd,
        ...DEFAULT_FORM_DATA,
      });
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_LIST_HOLIDAY);
    }
    setTextModal({
      ...textModal,
      title: t('masterData.moreData'),
      btn_submit: t('common.add'),
    });
    setStateButton(false);
  };

  return (
    <div className="holiday-master-data">
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>
        <Col lg={9} xl={10}>
          <Row>
            <Col className="md-12">
              <div className="box-group d-flex float-right">
                <div className="calendar">
                  <DateYearComponent
                    label=""
                    name="start_date"
                    valueDefault={typeof filter.filter === 'string' ? moment(filter.filter, 'YYYY') : filter.filter}
                    onChangeHandle={(value) => handleSelectSearch(value)}
                    placeholder={t('contract.contractNav.chooseStartDate')}
                    sizeDate="small"
                  />
                </div>
                <SearchComponent
                  txtPlaceholder={t('employees.searchName')}
                  addClass="input-search-box"
                  handleSearch={handleSearch}
                  maxLength={MAX_LENGTH_20}
                  defaultValue={filter.key_word}
                />
                <ButtonComponent
                  text={t('common.add')}
                  icon={<IoIosAdd />}
                  className="button-add"
                  addClass={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                    } ml-3`}
                  handleClick={openAddHoliday}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="md-12">
              <div className="table-list">
                <Table className="align-items-center table-hover" responsive>
                  <thead>
                    <tr>
                      <th scope="col">{t('masterData.STT')}</th>
                      <th scope="col">{t('masterData.nameHoliday')}</th>
                      <th scope="col">{t('masterData.Holiday')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {uListHoliday?.data?.length === 0 ? (
                      <tr>
                        <td colSpan={4}>
                          <div className="error-no-data">{t('common.notData')}</div>
                        </td>
                      </tr>
                    ) : (
                      uListHoliday?.data?.map((holiday, index) => (
                        <tr key={index}>
                          <td>{index + 1 > 9 ? index + 1 : <>0{index + 1}</>}</td>
                          <td className="text-bold">{holiday.name}</td>
                          <td className="text-bold">
                            {holiday.start_date === holiday.end_date ? (
                              holiday.start_date
                            ) : (
                              <>
                                {holiday.start_date} - {holiday.end_date}
                              </>
                            )}
                          </td>
                          <td>
                            <div className={`col-action d-flex float-right`}>
                              <div
                                className={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                  } edit mr-2`}
                                onClick={() => openEditHoliday(holiday)}
                              >
                                <HiPencil />
                              </div>
                              <div
                                className={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                  } remove mr-3`}
                                onClick={() => alertConfirm(holiday.id)}
                              >
                                <MdDelete />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                    {isLoadingListHoliday && (
                      <tr>
                        <td colSpan={4}>
                          <Loading addClass="mt-2" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalComponent
        classNameCustom={'modal-custom-holiday'}
        toggle={() => setShowModal(!isShowModal)}
        isShowModal={isShowModal}
        title={textModal.title}
      >
        <Form className="mt-3" onSubmit={handleSubmit}>
          <div className="fieldset-group mt-4 mb-4">
            <InputField
              label={t('masterData.nameHoliday')}
              isRequired={true}
              variant="outlined"
              name="name"
              defaultValue={formAdd.name}
              maxLength={MAX_LENGTH_20}
              onHandleInput={handleInput}
              error={formAdd.error_name}
            />
          </div>
          <Label className="text-bold color-navy title-hodiday"> {t('masterData.Holiday')}</Label>
          <Row>
            <Col className="md-6">
              <div className={'form-field'}>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className={`form-input ${formAdd?.error_start && 'field-error'}`}
                  selected={formAdd.start_date && new Date(formAdd.start_date)}
                  onChange={(date: Date) => {
                    setFormAdd({ ...formAdd, start_date: date, error_start: '' });
                  }}
                  onFocus={() => {
                    setTimeout(() => {
                      scrollBottom.current?.scrollIntoView({ behavior: "smooth" })
                    }, 100)
                    !formAdd.start_date && setFocus({ start_date: true });
                  }}
                  onBlur={() => {
                    focus?.start_date && setFocus({ start_date: false });
                  }}
                />
                <label
                  for="name"
                  className={`${(formAdd.start_date || focus?.start_date) && 'input-focus-custom'} ${'form-label'} ${formAdd?.error_start && 'label-error'
                    }`}
                >
                  {t('masterData.startDate') + ' *'}
                </label>
              </div>
              <p className={`error error-birthday`}>{formAdd?.error_start}</p>
            </Col>
            <Col className="md-6">
              <div className={'form-field'}>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className={`form-input ${formAdd?.error_end && 'field-error'}`}
                  selected={formAdd.end_date && new Date(formAdd.end_date)}
                  onChange={(date: Date) => {
                    setFormAdd({ ...formAdd, end_date: date, error_end: '' });
                  }}
                  onFocus={() => {
                    setTimeout(() => {
                      scrollBottom.current?.scrollIntoView({ behavior: "smooth" })
                    }, 100)
                    !formAdd.end_date && setFocus({ end_date: true });
                  }}
                  onBlur={() => {
                    focus?.end_date && setFocus({ end_date: false });
                  }}
                  minDate={new Date(formAdd.start_date) || null}
                />
                <label
                  for="name"
                  className={`${(formAdd.end_date || focus?.end_date) && 'input-focus-custom'} ${'form-label'} ${formAdd?.error_end && 'label-error'
                    }`}
                >
                  {t('masterData.endDate') + ' *'}
                </label>
              </div>
              <p className={`error error-birthday`}>{formAdd?.error_end}</p>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModal(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="w-100"
                handleClick={() => setShowModal(true)}
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
          <div ref={scrollBottom}></div>
        </Form>
      </ModalComponent>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default Holiday;
