import { Select, Space } from 'antd';
import classNames from 'classnames';
import { FaCaretDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import '../style.scss';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE, DEFAULT_LIMIT, PER_COURSE_PAGE_OPTION } from 'constants/index';

const { Option } = Select;

const CustomPagination = (props) => {
  const { t } = useTranslation();
  const { loading, totalPage, currentPage, limit, from, to, onChangePage } = props;

  if (loading || !totalPage) {
    return null;
  }

  const lastPage = Math.ceil(totalPage / limit);

  return (
    <div className={'pagination'}>
      <span>
        {from} - {to} {t('common.pagination.from')} {totalPage}
      </span>
      <div className="flex items-center">
        <span>{t('common.pagination.rowsPerPage')}</span>
        <Select
          value={limit}
          defaultValue={DEFAULT_LIMIT}
          suffixIcon={<FaCaretDown size={15} color="#8D8D8D" />}
          onChange={(limit) => onChangePage(DEFAULT_PAGE, limit)}
        >
          {PER_COURSE_PAGE_OPTION.map((size) => (
            <Option key={size} value={size}>
              {size}
            </Option>
          ))}
        </Select>
        <Space size={20} className="flex mr-4">
          <button
            className={classNames(
              'button',
              { 'cursor-no-drop': currentPage === DEFAULT_PAGE },
              { 'opacity-50': currentPage === DEFAULT_PAGE },
              { 'cursor-pointer': currentPage !== DEFAULT_PAGE },
            )}
            disabled={currentPage === DEFAULT_PAGE}
            onClick={() => onChangePage(currentPage - DEFAULT_PAGE, limit)}
          >
            <FaChevronLeft color="#FEA628" />
          </button>
          <button
            className={classNames(
              'button',
              { 'cursor-no-drop': currentPage === lastPage },
              { 'opacity-50': currentPage === lastPage },
              { 'cursor-pointer': currentPage !== lastPage },
            )}
            disabled={currentPage === lastPage}
            onClick={() => onChangePage(currentPage + DEFAULT_PAGE, limit)}
          >
            <FaChevronRight color="#FEA628" />
          </button>
        </Space>
      </div>
    </div>
  );
};

export default CustomPagination;
