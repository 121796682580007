import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import React, { useContext } from 'react';
import axios from 'axios';
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledDropdown,
  Media,
} from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';

import styles from './styles.module.scss';
import classNames from 'classnames';

import configs from 'config';
import Notification from 'components/NotificationAgain';
import { handleOnError, removeFilterModule } from 'helpers';
import storageFC from 'helpers/storage';
import HomeMini from 'components/HomeMini';
import { ThemContext } from 'layouts/Admin';
import logoutIcon from 'assets/img/icons/logout.svg';
import avatarDefault from 'assets/img/icons/default-avatar.svg';
import { ROLE_NOT_VIEW } from 'constants/index.js';

const Header = (props) => {
  const { t } = useTranslation();
  const { routes } = props;
  const location = useLocation();
  const dataInforUser = useContext(ThemContext);
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const getNavStyles = (layout, links) => {
    let isActive = '';
    if (links) {
      links.forEach((link) => {
        let urlLink = layout + link;
        if (
          urlLink === location.pathname ||
          (urlLink.indexOf('*') > 0 && location.pathname.indexOf(urlLink.slice(0, -1)) >= 0)
        ) {
          isActive = 'active';
        }
      });
    }
    return isActive;
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      let isNotDisplay = false;
      if (dataInforUser?.hr_permission) {
        switch (prop.permission) {
          case 'dashboard':
            if (
              dataInforUser?.hr_permission?.users_permission === ROLE_NOT_VIEW &&
              dataInforUser?.hr_permission?.time_sheet_permission === ROLE_NOT_VIEW &&
              dataInforUser?.hr_permission?.assets_permission === ROLE_NOT_VIEW &&
              dataInforUser?.hr_permission?.contracts_permission === ROLE_NOT_VIEW
            ) {
              isNotDisplay = true;
            }
            break;
          case 'assets_permission':
            if (dataInforUser?.hr_permission?.assets_permission === ROLE_NOT_VIEW) {
              isNotDisplay = true;
            }
            break;
          case 'contracts_permission':
            if (dataInforUser?.hr_permission?.contracts_permission === ROLE_NOT_VIEW) {
              isNotDisplay = true;
            }
            break;
          case 'divisions_permission':
            if (dataInforUser?.hr_permission?.divisions_permission === ROLE_NOT_VIEW) {
              isNotDisplay = true;
            }
            break;
          case 'master_data_permission':
            if (dataInforUser?.hr_permission?.master_data_permission === ROLE_NOT_VIEW) {
              isNotDisplay = true;
            }
            break;
          case 'time_sheet_permission':
            if (dataInforUser?.hr_permission?.time_sheet_permission === ROLE_NOT_VIEW) {
              isNotDisplay = true;
            }
            break;
          case 'users_permission':
            if (dataInforUser?.hr_permission?.users_permission === ROLE_NOT_VIEW) {
              isNotDisplay = true;
            }
            break;
          case 'policy_permission':
            if (typeof(dataInforUser?.hr_permission?.policy_permission) === 'undefined' || dataInforUser?.hr_permission?.policy_permission === ROLE_NOT_VIEW) {
              isNotDisplay = true;
            }
            break;  
          default:
            break;
        }
      } else {
        isNotDisplay = true;
      }

      if (isNotDisplay) {
        return '';
      } else {
        return (
          <NavItem key={key} className={prop?.class}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              activeClassName="active"
              className={classNames(`${prop?.class} ${getNavStyles(prop.layout, prop.links)}`)}
            >
              {prop.icon ? <i className={prop.icon} /> : ''}
              {t(prop.name)}
            </NavLink>
          </NavItem>
        );
      }
    });
  };

  const logout = () => {
    confirmAlert({
      title: t('common.areYouSureYouWantToSignOut'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.confirm'),
          className: 'btn-alert-ok',
          onClick: async () => {
            axios
              .post(
                `${configs.API_DOMAIN_SSO}/api/logout`,
                {
                  token: storageFC.getItem('token'),
                },
                {
                  headers: { Authorization: `Bearer ${storageFC.getItem('token')}` },
                },
              )
              .then((res) => {
                storageFC.setToken('');
                removeFilterModule()
                window.location.href = configs.API_DOMAIN_LOGIN;
              })
              .catch((error) => {
                console.log(error);
              });
          },
        },
      ],
    });
  };

  return (
    <>
      <div className={classNames(`header bg-menu ${styles.boxMenu}`)}>
        <Container fluid className={styles.menu}>
          <div className="header-body">
            <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="lg">
              <>
                <NavbarBrand to="/" tag={Link}>
                  <div className={styles.logo}>
                    <img alt="logo" src={require('../../assets/img/icons/logo.png').default} />
                    <h4>Bo</h4>
                  </div>
                </NavbarBrand>
                <button className="navbar-toggler" id="navbar-collapse-main">
                  <span className="navbar-toggler-icon" />
                </button>
                <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
                  <div className={styles.menuLeft}>{dataInforUser && <Nav navbar>{createLinks(routes)}</Nav>}</div>
                  <Nav className={`ml-auto ${styles.subMenu}`} navbar>
                    <UncontrolledDropdown nav>
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm rounded-circle">
                          <img onError={handleOnError} alt="..." src={dataInforUser?.avatar ? dataInforUser?.avatar : avatarDefault} />
                        </span>
                      </Media>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                      <Notification toggleDrawer={toggleDrawer} state={state} />
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                      <HomeMini />
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                      {storageFC.getItem('token') && (
                        <span className={styles.logout} onClick={logout}>
                          <img alt={logoutIcon} src={logoutIcon} />
                        </span>
                      )}
                    </UncontrolledDropdown>
                  </Nav>
                </UncontrolledCollapse>
              </>
            </Navbar>
          </div>
        </Container>
      </div>
      <div className={styles.paddingTop}></div>
    </>
  );
};

export default Header;
