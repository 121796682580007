import { useQuery } from 'react-query';

import { HTTP_OK } from 'constants/StatusCode';
import { USE_GET_NOTIFICATIONS, USE_TOTAL_GET_NOTIFICATIONS } from 'constants/index.js';
import { getNotifications, getTotalNotifications } from '../api/notification';

export function useGetNotifications(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [USE_GET_NOTIFICATIONS, params],
    async () => {
      const response = await getNotifications(params);
      if (response?.status === HTTP_OK) {
        return response;
      }
      return null;
    },
    {
      enabled: params.user_id ? true : false,
    },
  );

  return { ...response, error, isLoading };
}

export function useGetTotalNotifications(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [USE_TOTAL_GET_NOTIFICATIONS, params],
    async () => {
      const response = await getTotalNotifications(params);
      if (response?.status === HTTP_OK) {
        return response;
      }
      return null;
    },
    {
      enabled: params.user_id ? true : false,
    },
  );

  return { ...response, error, isLoading };
}
