import { Col, Row, Form } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import $ from 'jquery';

import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import AlertComponent from 'components/Alert';
import ProgressBar from 'components/ProgressBar/index.js';
import Import from 'assets/img/icons/import.png';
import IconImport from 'assets/img/icons/icon-import.png';
import Invalid from 'assets/img/icons/file-import-invalid.png';
import Illegal from 'assets/img/icons/file-import-illegal.png';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_INTERNAL_SERVER_ERROR, HTTP_NOT_ACCESS } from '../../../../constants/StatusCode.js';
import { USE_LIST_USERS, USE_LIST_DIVISION } from '../../../../constants/ListEmployees';
import { apiDownloadTemplateImportContract, apiImportContract } from '../../../../api/contract';

import './style.module.scss';

const ImportEmployees = (props) => {
  const queryClient = useQueryClient();
  const { toggle, modal } = props;
  const { t } = useTranslation();
  const [textModal] = useState({
    titleEdit: t('contract.importContract'),
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState([]);

  const importText = t('common.import');
  const [progress, setProgress] = useState(0);
  const [isShowAlert, setShowAlert] = useState(false);
  const [notifiUpload, setNotifiUpload] = useState({ status: importText, name: '', file: '' });
  const [stateButton, setStateButton] = useState(false);

  useEffect(() => {
    setProgress(0);
    setError([]);
    setNotifiUpload({ status: importText, name: '', file: '' });
    $('#imageImport').val('');
  }, [modal, importText]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStateButton(true);
    if (!notifiUpload.file) {
      setStateButton(false);
      setNotifiUpload({ ...notifiUpload, error_file: t('common.pleaseFillOutThisFieldImport') });
      return;
    }
    setNotifiUpload({ ...notifiUpload, status: t('common.importing') });
    const fb = new FormData();
    fb.append('file', notifiUpload.file);

    let response = {};
    response = await apiImportContract(fb);
    if (
      (Number(response?.data?.status) === HTTP_UNPROCESSABLE_ENTITY ||
        Number(response?.data?.status) === HTTP_INTERNAL_SERVER_ERROR) &&
      response?.data?.messages
    ) {
      setError(response?.data?.messages);
      setNotifiUpload({ ...notifiUpload, file: '', name: '', status: importText });
      $('#imageImport').val('');
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setProgress(100);
      $('#imageImport').val('');
      setNotifiUpload({ status: importText, name: '', file: '' });
      await queryClient.invalidateQueries(USE_LIST_USERS);
      await queryClient.invalidateQueries(USE_LIST_DIVISION);
      setInterval(success(response), 1000);
    }
    setStateButton(false);
  };

  const success = async (response) => {
    toggle(false);
    setMessage(response.data.messages);
    setShowAlert(true);
    setStateButton(false);
  };

  const handleUpload = (event) => {
    if (event.target.files[0]) {
      setError([]);
      setNotifiUpload({
        ...notifiUpload,
        name: event.target.files[0].name,
        file: event.target.files[0],
        error_file: '',
      });
    }
  };

  const dowloadTemplate = async () => {
    var response = await apiDownloadTemplateImportContract();
    const url = await window.URL.createObjectURL(new Blob([response.data], { type: `application/octet-stream` }));
    const link = document.createElement('a');
    link.href = url;
    link.download = t('employees.templateImport');
    link.click();
    link.remove();
  };

  return (
    <>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>

      <ModalComponent toggle={toggle} isShowModal={modal} classNameAdd="import-contract" title={textModal.titleEdit}>
        <Form className="mt-3" onSubmit={handleSubmit}>
          <div className={`fieldset-group mt-4 import`}>
            <input
              type="file"
              id="imageImport"
              name="file_import[]"
              onChange={(event) => handleUpload(event)}
              style={{ display: 'none' }}
              accept=".xlsx"
            />
            <label htmlFor="imageImport" className="labelImport">
              <img src={IconImport} alt="Import" className="iconImport infor" />
              <p className="notifi infor">{t('employees.notificationImport')}</p>
              <p className="format infor">{t('employees.formatImport')}</p>
              <img src={Import} alt="Import" className="imgImport" />
            </label>
          </div>
          <div className="fieldset-group mt-3">
            <p className="download-template" onClick={dowloadTemplate}>
              {t('employees.notificationImportUser')}
            </p>
            <label className="inforImport">{' ' + notifiUpload.status + ': ' + notifiUpload.name} </label>
            <span className="progress-percent">{progress}%</span>
            <ProgressBar classNameAdd="progress-import" bgcolor="#2ECC71" completed={progress} />
          </div>
          {notifiUpload?.error_file && <p className="error">{notifiUpload?.error_file}</p>}
          {Array.isArray(error) &&
            error.length > 0 &&
            error.map((item, i) => {
              return (
                <p key={i} className="error">
                  {
                    <img
                      src={Array.isArray(error) && error.length > 0 ? Illegal : Invalid}
                      alt="FileInvalid"
                      className="fileInvalid"
                    />
                  }
                  {item.line &&
                    item.message &&
                    t('common.line') +
                    ' ' +
                    item.line +
                    ', ' +
                    t('common.column') +
                    ' ' +
                    item.column +
                    ': ' +
                    item.message}
                  {!item.line && !item.message && item}
                </p>
              );
            })}
          {!Array.isArray(error) && error?.field && (
            <p className="error">
              <img src={Illegal} alt="FileInvalid" className="fileInvalid" />
              {error.field}
            </p>
          )}
          {!Array.isArray(error) && error?.file && (
            <p className="error">
              <img src={Illegal} alt="FileInvalid" className="fileInvalid" />
              {error.file[0]}
            </p>
          )}
          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent text={t('common.cancel')} addClass="w-100 btn-bg-yellow2" handleClick={toggle} />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={t('common.import')}
                addClass="w-100"
                type="submit"
                onClick={handleSubmit}
                isLoading={stateButton}
                hasDisabled={stateButton}
              />
            </Col>
          </Row>
        </Form>
      </ModalComponent>
    </>
  );
};

export default ImportEmployees;
