import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import { Col, Row, Input, Form } from 'reactstrap';
import { HiPencil } from 'react-icons/hi';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';

import iconBonusCertificate from 'assets/img/icons/achivements.png';
import styles from './../style.module.scss';
import AlertComponent from 'components/Alert';
import ButtonComponent from 'components/Buttons';
import ModalComponent from 'components/Modal';
import UploadImages from 'components/UploadImages';
import { HTTP_OK, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { HTTP_UNPROCESSABLE_ENTITY, HTTP_EXPECTATION_FAILED } from 'constants/StatusCode';
import { USE_USER_PROFILE, NAME_CERTIFICATE_OTHER } from 'constants/ProfileEmployee';
import { apiDeleteCertificate, apiCreateCertificate, apiUpdateCertificate } from 'api/profileEmployee.js';
import { useListCategoryCertificate, useListCertificate, useGetCertificateDetail } from 'hook/useProfileEmployee.js';
import DateDayMonthComponent from 'components/DateTime/DateDay.js';
import moment from 'moment';
import { customDate } from 'helpers/formatDate';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';

const KEY_SELECT_CATEGORY_DEFAULT = 'category-certificate';
const KEY_SELECT_CATEGORY = 'other';

const Achievement = (props) => {
  const { uProfileUser, userId } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const DATA_POPUP_FORM = {
    id: '',
    img: '',
    certificate_id: '',
    user_id: userId,
    name: '',
    category_error: '',
    certificate_error: '',
    certificate_category_id: '',
    certificate_category_name: '',
    issue_date: null,
    img_error: '',
    issue_date_error: '',
  };

  const [isShowModalAchievement, setShowModalAchievement] = useState(false);
  const [numberClickAddModal, setNumberClickAddModal] = useState(0);
  const [isBtnSubmit, setIsBtnSubmit] = useState(false);
  const [numberClick, setNumberClick] = useState(1);
  const [isShowPerview, setShowPerview] = useState(false);
  const [showPerview, setContentShowPerview] = useState({});
  const [isShowInputCertificate, setShowInputCertificate] = useState(true);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [idCategoryCertificate, setIdCategoryCertificate] = useState(KEY_SELECT_CATEGORY_DEFAULT);
  const [idUserCertificateDetail, setIdUserCertificateDetail] = useState(null);
  const [formCertificate, setFormCertificate] = useState(DATA_POPUP_FORM);
  const { data: listCategoryCertificate } = useListCategoryCertificate();
  const { data: listCertificate } = useListCertificate(idCategoryCertificate);
  const { data: userCertificateDetail } = useGetCertificateDetail(idUserCertificateDetail);
  const [actionType, setActionType] = useState("add");
  const [textModalCertificate, setTextModalCertificate] = useState({
    title: t('infoEmployee.addCertificate'),
    button: t('common.add'),
  });

  const handleShowModalAddCertificate = () => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    setActionType("add");
    setIdCategoryCertificate(KEY_SELECT_CATEGORY_DEFAULT);
    setNumberClickAddModal(numberClickAddModal + 1);
    setTextModalCertificate({
      title: t('infoEmployee.addCertificate'),
      button: t('common.add'),
    });
    let _dataForm = { ...DATA_POPUP_FORM };
    _dataForm.certificate_id = formCertificate.certificate_id;
    if (listCertificate && listCertificate.length) _dataForm.certificate_id = listCertificate[0].id;
    else _dataForm.certificate_id = formCertificate.certificate_id;

    if (_dataForm.certificate_id === KEY_SELECT_CATEGORY) setShowInputCertificate(false);
    else setShowInputCertificate(true);

    setFormCertificate(_dataForm);
    setShowModalAchievement(!isShowModalAchievement);
  };

  const handleSelectCategoryCertificate = (select) => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    let idCategoryCertificate = ''
    let nameCategoryCertificate = ''

    if (!isNaN(select.target.value)) {
      listCategoryCertificate.forEach((item) => {
        if (Number(item.id) === Number(select.target.value)) {
          idCategoryCertificate = item.id
          nameCategoryCertificate = item.name
        }
      })
    }

    setActionType("add");
    setFormCertificate({
      ...formCertificate,
      certificate_category_id: idCategoryCertificate,
      certificate_category_name: nameCategoryCertificate,
      category_error: '',
      certificate_error: '',
      name: '',
    });
    setIdCategoryCertificate(select.target.value);

    if (select.target.value === KEY_SELECT_CATEGORY) {
      setShowInputCertificate(false);
    } else {
      setShowInputCertificate(true);
    }
  };

  const handleSelectCertificate = (event) => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    if(event.target.value.trim().length < 3 && event.target.type === 'text'){
      setFormCertificate({
        ...formCertificate,
        [event.target.name]: event.target.type !== 'text' ? Number(event.target.value) : event.target.value,
        certificate_error: t("messages.minLengthCertificateName"),
      });
      return;
    }
    if(event.target.value.trim().length >= 255){
      return;
    }
    setFormCertificate({
      ...formCertificate,
      [event.target.name]: event.target.type !== 'text' ? Number(event.target.value) : event.target.value,
      certificate_error: '',
    });
  };

  const handleImageChange = (fileImage) => {
    setFormCertificate({
      ...formCertificate,
      img: fileImage,
      img_error: '',
    });
  };

  const handleSubmitCertificate = async (event) => {
    event.preventDefault();
    setIsBtnSubmit(true);

    let _errors = {
      certificate_error: '',
      category_error: '',
      img_error: '',
      issue_date_error: '',
    };

    if (!formCertificate.issue_date) {
      _errors.issue_date_error = t('messages.thisIsARequiredField')
    }

    if (idCategoryCertificate === KEY_SELECT_CATEGORY_DEFAULT) {
      _errors.category_error = t('messages.thisIsARequiredField')
    }

    if (
      (idCategoryCertificate === KEY_SELECT_CATEGORY && !formCertificate.name) ||
      (idCategoryCertificate !== KEY_SELECT_CATEGORY && !formCertificate.certificate_id && !formCertificate.name)
    ) {
      _errors.certificate_error = t('messages.thisIsARequiredField')
    }

    if (!formCertificate.img) {
      _errors.img_error = t('messages.thisIsARequiredField')
    }

    if (Object.values(_errors).some(e => e)) {
      setIsBtnSubmit(false)
      setFormCertificate({ ...formCertificate, ..._errors })
      return
    }

    const fb = new FormData();
    fb.append('id', formCertificate.id);
    fb.append('certificate_category_id', formCertificate.certificate_category_id || "");
    fb.append('certificate_category_name', formCertificate.certificate_category_name || "");
    fb.append('issue_date', formCertificate.issue_date || "");
    fb.append('user_id', userId);
    if (idCategoryCertificate === KEY_SELECT_CATEGORY) {
      fb.append('name', formCertificate.name.trim());
    } else {
      fb.append('certificate_id', formCertificate.certificate_id);
    }
    if (formCertificate.img) {
      if (typeof formCertificate.img === 'string') {
        fb.append('img', formCertificate.img);
      } else {
        fb.append('img', formCertificate.img, formCertificate.img.name);
      }
    }

    let response = {};
    if (formCertificate.id) {
      response = await apiUpdateCertificate(fb);
      setIsBtnSubmit(false);
    } else {
      response = await apiCreateCertificate(fb);
      setIsBtnSubmit(false);
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
    }
    if (response && response?.data.status === HTTP_OK) {
      setShowModalAchievement(false);
      await queryClient.invalidateQueries(USE_USER_PROFILE);
      setMessage(response.data.messages);
      setIdUserCertificateDetail(null);
      setShowAlert(true);
      $(".uploadImages input").val("");
      setShowInputCertificate(true);
      setFormCertificate(DATA_POPUP_FORM);
      setActionType("edit");
    }

    if (
      response &&
      (response?.data.status === HTTP_UNPROCESSABLE_ENTITY || response?.data.status === HTTP_EXPECTATION_FAILED)
    ) {
      if (response?.data?.messages?.certificate_id)
        _errors.certificate_error = response.data.messages.certificate_id[0];
        if (response?.data?.messages?.name)
        _errors.certificate_error = response.data.messages.name[0];
      if (response?.data?.messages?.img) _errors.img_error = response.data.messages.img[0];

      setFormCertificate({ ...formCertificate, ..._errors });
    }
  };

  const alertConfirmDeleteAchievements = (certificateId) => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    confirmAlert({
      title: t('common.doYouReallyWantToDeletedData?'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteCertificate(certificateId);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              await queryClient.invalidateQueries(USE_USER_PROFILE);
            }
          },
        },
      ],
    });
  };
  const handleFilterIssueDate = (value) => {
    setFormCertificate({ ...formCertificate, issue_date: customDate(value, 'DD/MM/YYYY'), issue_date_error: '' });
  };

  const handleShowPopupEdit = (id) => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    setActionType("edit");
    setFormCertificate({
      ...formCertificate,
      certificate_category_id: id.certificate_category_id,
      certificate_category_name: id.certificate_category_name,
    });
    setIdUserCertificateDetail(id.user_certificate_id);
    setTextModalCertificate({
      title: t('infoEmployee.updateCertificate'),
      button: t('common.update'),
    });
    setNumberClick(numberClick + 1);
  };

  useEffect(() => {
    if (userCertificateDetail) {
      setFormCertificate((prevState) => ({
        ...prevState,
        id: userCertificateDetail.id,
        img: userCertificateDetail.image,
        name: userCertificateDetail.user_certificate_name,
        certificate_id: userCertificateDetail.certificate_id,
        certificate_error: '',
        category_error: '',
        issue_date: userCertificateDetail.issue_date,
        img_error: '',
      }));
      setIdCategoryCertificate(
        userCertificateDetail.certificate_category_id === null
          ? KEY_SELECT_CATEGORY
          : userCertificateDetail.certificate_category_id,
      );
      setShowModalAchievement(true);
    }
  }, [userCertificateDetail, numberClick]);

  useEffect(() => {
    if(actionType === "add"){
      if (listCertificate && listCertificate.length) {
        setFormCertificate((prevState) => ({
          ...prevState,
          certificate_id: listCertificate[0].id,
        }));
      } else {
        setFormCertificate((prevState) => ({
          ...prevState,
          certificate_id: null,
        }));
      }
    }else{
      setFormCertificate((prevState) => ({
        ...prevState,
        certificate_id: formCertificate.certificate_id,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCertificate, actionType]);

  useEffect(() => {
    if (!isShowModalAchievement) {
      setFormCertificate(DATA_POPUP_FORM)
    }
    // eslint-disable-next-line
  }, [isShowModalAchievement])

  const onPerviewAchievement = (certificate) => {
    if (certificate?.image) {
      setShowPerview(true);
      setContentShowPerview(certificate);
    }
  };

  return (
    <>
      <div className={styles.profileBoxAchie}>
        <Row className={styles.rowTitle}>
          <Col xs="11">
            <div className={styles.profileTitle}>
              <img alt={iconBonusCertificate} className={styles.imgTitle} src={iconBonusCertificate} />
              {t('infoEmployee.bonusCertificate')}
            </div>
          </Col>
          <Col xs="1">
            {uProfileUser?.data?.user_certificate?.length !== 0 && (
              <div
                className={classNames(
                  `${styles.editDeleteButton} mr--15 ${
                    dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                  }`,
                )}
                onClick={handleShowModalAddCertificate}
              >
                <IoIosAdd />
              </div>
            )}
          </Col>
        </Row>

        {uProfileUser?.data?.user_certificate?.length ? (
          uProfileUser.data.user_certificate.map((certificate, index) => (
            <div key={index}>
              <div className={classNames(`${styles.profileTitleChild} ml-40`)}>
                {certificate.name === NAME_CERTIFICATE_OTHER ? t('employees.otherCase') : certificate.name}
              </div>
              <div className={styles.salaryBoxParent}>
                <Row>
                  {certificate?.data?.length &&
                    certificate.data.map((itemCertificate, index) => (
                      <Col key={index} sm={12} lg={6}>
                        <div className={classNames(`${styles.certificationBox} ${styles.certificationBoxNew}`)}>
                          <div
                            className={classNames(`${styles.leftCerti} ${styles.imgCertificate}`)}
                            onClick={() => onPerviewAchievement(itemCertificate)}
                          >
                            {itemCertificate.image && <img alt={itemCertificate.image} src={itemCertificate.image} />}
                          </div>
                          <div className={styles.rightCerti}>
                            <div className={styles.profileContentInfo}>
                              {itemCertificate.certificate_name} {itemCertificate.user_certificate_name}
                            </div>
                            <div className={styles.profileContentInfo}>
                              {t('infoEmployee.issueDateCertificate')}: {itemCertificate.date}
                            </div>
                            <div></div>
                            <div className="clear-both">
                              <div
                                className={`${styles.editDeleteButtonChild} ${
                                  dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                                }`}
                              >
                                <HiPencil onClick={() => handleShowPopupEdit(itemCertificate)} />
                              </div>
                              <div
                                className={`${styles.editDeleteButtonChild} ${
                                  dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                                }`}
                              >
                                <MdDelete
                                  onClick={() => alertConfirmDeleteAchievements(itemCertificate.user_certificate_id)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          ))
        ) : (
          <div>
            <div className={classNames(`${styles.achievementNull} ${styles.achievementNullCenter}`)}>
              <div
                className={classNames(
                  `${dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE} ${
                    styles.editDeleteButton
                  }`,
                )}
                onClick={handleShowModalAddCertificate}
              >
                <IoIosAdd />
              </div>
              <div className={styles.profileContentInfoAlert}>{t('infoEmployee.addCertificate')}</div>
            </div>
          </div>
        )}

        <ModalComponent
          toggle={() => setShowModalAchievement(!isShowModalAchievement)}
          isShowModal={isShowModalAchievement}
          title={textModalCertificate.title}
        >
          <Form onSubmit={handleSubmitCertificate}>
            <Row>
              <Col md={12}>
                <Input
                  type="select"
                  name="category_certificate_id"
                  className="input-border"
                  onChange={handleSelectCategoryCertificate}
                  value={idCategoryCertificate}
                >
                  <option value={KEY_SELECT_CATEGORY_DEFAULT}>{t('infoEmployee.categoryCertificateVIE')}</option>
                  {listCategoryCertificate &&
                    listCategoryCertificate.map((categoryAchievement) => (
                      <option value={categoryAchievement.id} key={categoryAchievement.id}>
                        {categoryAchievement.name}
                      </option>
                    ))}
                  <option value={KEY_SELECT_CATEGORY}>{t('employees.otherCase')}</option>
                </Input>
                <p className="error">{formCertificate.category_error}</p>
              </Col>
              <Col md={12} className="mt-3">
                {isShowInputCertificate &&
                idCategoryCertificate !== KEY_SELECT_CATEGORY &&
                idCategoryCertificate !== KEY_SELECT_CATEGORY_DEFAULT ? (
                  <Input
                    type="select"
                    name="certificate_id"
                    className="input-border"
                    onChange={handleSelectCertificate}
                    value={
                      formCertificate.certificate_id ? formCertificate.certificate_id : t('masterData.certificateName')
                    }
                  >
                    {listCertificate &&
                      listCertificate.map((certificateMap) => (
                        <option value={certificateMap.id} key={certificateMap.id}>
                          {certificateMap.name}
                        </option>
                      ))}
                  </Input>
                ) : (
                  <Input
                    type="input"
                    name="name"
                    className="input-border"
                    disabled={idCategoryCertificate === KEY_SELECT_CATEGORY_DEFAULT}
                    onChange={handleSelectCertificate}
                    value={formCertificate.name}
                    placeholder={t('infoEmployee.certificateName')}
                  />
                )}
                {idCategoryCertificate !== KEY_SELECT_CATEGORY_DEFAULT && (
                  <p className="error">{formCertificate.certificate_error}</p>
                )}
              </Col>
              <Col md={12} className="mt-3">
                <DateDayMonthComponent
                  placeholder={t('employees.issueDateCertificate')}
                  valueDefault={
                    typeof formCertificate.issue_date === 'string'
                      ? moment(formCertificate.issue_date, 'DD/MM/YYYY')
                      : formCertificate.issue_date
                  }
                  clearable
                  error={formCertificate.issue_date_error}
                  onChangeHandle={handleFilterIssueDate}
                />
              </Col>
              <Col md={12} className="mt-3">
                <UploadImages
                  dataImage={[formCertificate.img]}
                  numberMax={1}
                  onChangeImage={handleImageChange}
                  isModal={isShowModalAchievement}
                />
                <p className={styles.noteImage}>{t('infoEmployee.certificateImagePlaceholder')}</p>
                <p className={`${styles.messageError} error`}>{formCertificate.img_error}</p>
              </Col>
            </Row>
            <div className="mt-5">
              <Row>
                <Col className="md-6">
                  <ButtonComponent
                    text={t('common.cancel')}
                    addClass="w-100 btn-bg-yellow2"
                    handleClick={() => setShowModalAchievement(!isShowModalAchievement)}
                  />
                </Col>
                <Col className="md-6">
                  <ButtonComponent
                    text={textModalCertificate.button}
                    addClass="w-100"
                    type="submit"
                    hasDisabled={isBtnSubmit}
                    isLoading={isBtnSubmit}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </ModalComponent>

        <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
          {message}
        </AlertComponent>

        <Modal
          isOpen={isShowPerview}
          toggle={() => setShowPerview(!isShowPerview)}
          className={`${styles.modalCommonPerview} modal-dialog-centered`}
        >
          <ModalHeader className={styles.modalTitle} toggle={() => setShowPerview(!isShowPerview)}>
            {props.title}
          </ModalHeader>
          <ModalBody className={styles.modalBody}>
            <div className={styles.img}>
              <img src={showPerview?.image} alt={showPerview?.image ? showPerview?.image : 'errors'} />
            </div>
            <div className={styles.content}>
              <p className={classNames(`${styles.title} text-bold`)}>
                {showPerview?.certificate_name} {showPerview?.user_certificate_name}
              </p>
              <p className={styles.time}>
                {t('infoEmployee.issueDateCertificate')}: {showPerview?.date}
              </p>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Achievement;
