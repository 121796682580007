import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import SideNavBar from 'components/SideNavBar';
import { DASHBOARD } from 'constants/Assets';
import { CONTRACT_WORK } from 'constants/Contract';
import { RETIRE_DECISION } from 'constants/Contract';
import { CONTRACT_WAITING } from 'constants/Contract';

const NavContract = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory();
  const [value, setValue] = useState(DASHBOARD);

  const routes = [
    {
      name: t('common.dashboard'),
    },
    {
      name: t('contract.contract'),
    },
    {
      name: t('contract.retire'),
    },
    {
      name: t('contract.waitingContract'),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === DASHBOARD) {
      history.push('/admin/contract/dashboard');
    }
    if (newValue === CONTRACT_WORK) {
      history.push('/admin/contract/contract');
    }
    if (newValue === RETIRE_DECISION) {
      history.push('/admin/contract/retire');
    }
    if (newValue === CONTRACT_WAITING) {
      history.push('/admin/contract/waiting-contract');
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/admin/contract/dashboard':
        setValue(DASHBOARD);
        break;
      case '/admin/contract/contract':
        setValue(CONTRACT_WORK);
        break;
      case '/admin/contract/retire':
        setValue(RETIRE_DECISION);
        break;
      case '/admin/contract/waiting-contract':
        setValue(CONTRACT_WAITING);
        break;

      default:
        break;
    }
  }, [location.pathname]);

  return <SideNavBar routes={routes} onChange={handleChange} value={value} hasMarginBottom />;
};

export default NavContract;
