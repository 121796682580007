import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import YupValidate from './yupValidate';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Loading from 'components/Loading';
import UploadAvatar from './UploadAvatar';
import { MdDelete } from 'react-icons/md';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import { MAX_LENGTH_30 } from 'constants/ProfileEmployee';
import ButtonComponent from 'components/Buttons/index.js';
import MenuCreateFilter from './CreateGroupAccount.js';
import PopupGroupCustom from './Custom/PopupGroupCustom';
import { USE_TYPE_GROUP } from 'constants/MasterData';
import { IoIosAdd } from 'react-icons/io';
import $ from 'jquery';
import {
  HTTP_OK,
  HTTP_NOT_ACCESS,
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_EXPECTATION_FAILED,
} from 'constants/StatusCode.js';
import AlertComponent from './../../../../components/Alert';
import { confirmAlert } from 'react-confirm-alert';
import { useDetailGroupAccount } from 'hook/useMasterData.js';
import { apiAddCreateGroupAccountFilter, apiUpdateCreateGroupAccountFilter } from 'api/masterData';
import './style.scss';
import { useParams } from 'react-router';

const CreateGroupAccountFilter = () => {
  let { t } = useTranslation();
  const params = useParams();
  const contractId = params.contractId;

  const [isShowAlert, setShowAlert] = useState(false);
  const [textMessage, setMessage] = useState('');
  const [stateButton, setStateButton] = useState(false);
  const [isModalPopupCustom, setIsOpenPopupCustom] = useState(false);
  const [dataToSave, setDataToSave] = useState([]);

  const [linkSuccess, setLinkSuccess] = useState('');
  const [dataForm, setDataForm] = useState({ type: '2', file: '' });
  const [error, setError] = useState({ name: '', file: '' });

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(YupValidate()),
    mode: 'onBlur',
  });

  let { data: detailGroupAccountCustom, isLoading: isLoadingdetailGroupAccountCustom } =
    useDetailGroupAccount(contractId);

  if (contractId === 'create') {
    detailGroupAccountCustom = '';
    isLoadingdetailGroupAccountCustom = false;
  }

  useEffect(() => {
    if (detailGroupAccountCustom?.data?.list_user) {
      setDataToSave(detailGroupAccountCustom?.data?.list_user);
    }
  }, [detailGroupAccountCustom]);

  const handleUploadImg = (avatar) => {
    setDataForm({ ...dataForm, file: avatar });
  };

  const onSubmit = async (data) => {
    setError({ name: '', file: '' });
    setStateButton(true);

    const fb = new FormData();

    fb.append('type', dataForm?.type);
    if (dataForm?.file[0]) {
      fb.append('file', dataForm?.file[0] ? dataForm?.file[0] : '');
    }

    dataToSave.forEach((item) => {
      fb.append('list_user[]', item.user_id);
    });

    fb.append('name', data?.name);
    let response = {};

    if (detailGroupAccountCustom) {
      response = await apiUpdateCreateGroupAccountFilter(contractId, fb);
    } else {
      response = await apiAddCreateGroupAccountFilter(fb);
    }

    if (
      (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY ||
        response?.data?.status === HTTP_INTERNAL_SERVER_ERROR ||
        response?.data?.status === HTTP_EXPECTATION_FAILED) &&
      response?.data?.messages
    ) {
      if (response.data.messages?.name) {
        setError({ ...error, name: response.data.messages?.name[0] });
        $('input[name=name]').focus();
      }
      if (response.data.messages?.file) {
        setError({ ...error, file: response.data.messages?.file[0] });
      }
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setLinkSuccess('/admin/master-data/group-account');
      setMessage(response.data.messages);
      setShowAlert(true);
      setStateButton(false);
    }
    setStateButton(false);
  };

  const alertConfirm = (item) => {
    confirmAlert({
      title: t('common.doYouWantToDeletedData?'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const index = dataToSave.indexOf(item);
            const data = [];
            dataToSave.forEach((it, i) => {
              if (i !== index) {
                data.push(it);
              }
            });
            setDataToSave([...data]);
          },
        },
      ],
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="master-data-create-filter">
      <MenuCreateFilter type={USE_TYPE_GROUP.custom} detailGroupAccountFilter={detailGroupAccountCustom}/>
      {isLoadingdetailGroupAccountCustom && contractId && <Loading addClass="mt-2 loading-custom" />}
      {!isLoadingdetailGroupAccountCustom && (
        <>
          <div className="save">
            <ButtonComponent
              text={t('common.save')}
              addClass="w-100"
              handleClick={handleSubmit(onSubmit)}
              type="submit"
              hasDisabled={stateButton}
              isLoading={stateButton}
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className="filter">
                <h1>{t('masterData.groupAccountList.inforGroup')}</h1>
                <UploadAvatar
                  dataImage={detailGroupAccountCustom?.data?.avatar ? [detailGroupAccountCustom?.data?.avatar] : ['']}
                  onChangeImage={handleUploadImg}
                />
                <p className="error images">{error.file && error.file}</p>
                <div className="mt-2 mb-4 field-name">
                  <CustomInputYup
                    placeholder={t('masterData.groupAccountList.nameGroup')}
                    label={t('masterData.groupAccountList.nameGroup')}
                    name="name"
                    control={control}
                    register={register}
                    errors={errors}
                    useWatch={useWatch}
                    required
                    setValue={setValue}
                    maxLength={MAX_LENGTH_30}
                    defaultValue={detailGroupAccountCustom?.data?.name && detailGroupAccountCustom?.data?.name}
                  />
                  <p className="error">{error.name && error.name}</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={9}>
              <div className="infor-on-table">
                <h1 className="title-list-member">{t('masterData.groupAccountList.member')}</h1>
                <ButtonComponent
                  text={t('masterData.groupAccountList.addMember')}
                  icon={<IoIosAdd />}
                  addClass="ml-3 d-flex align-items-center"
                  className="button-add"
                  handleClick={() => setIsOpenPopupCustom(true)}
                />
              </div>
              <TableContainer component={Paper} className="table-list">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="header">
                      <TableCell>{t('masterData.groupAccountList.name')}</TableCell>
                      <TableCell>{t('masterData.groupAccountList.email')}</TableCell>
                      <TableCell>{t('masterData.groupAccountList.position')}</TableCell>
                      <TableCell>{t('masterData.groupAccountList.division')}</TableCell>
                      <TableCell>{t('masterData.groupAccountList.dateCreate')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataToSave &&
                      dataToSave.map((item) => (
                        <TableRow key={item.user_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>{item.user_name}</TableCell>
                          <TableCell>{item.email}</TableCell>
                          <TableCell>{item.position_name}</TableCell>
                          <TableCell>{item.division_name}</TableCell>
                          <TableCell>{item.onboard_date}</TableCell>
                          <TableCell>
                            <div className="col-action d-flex float-right">
                              <div className="remove mr-3">
                                <MdDelete onClick={() => alertConfirm(item)} />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    {dataToSave && dataToSave.length < 1 && (
                      <TableRow>
                        <TableCell className="error-no-data" colSpan={5}>
                          {t('common.notData')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert} link={linkSuccess}>
            {textMessage}
          </AlertComponent>
          <PopupGroupCustom
            dataOld={dataToSave}
            setDataToSave={setDataToSave}
            isShowModal={isModalPopupCustom}
            setShowModal={setIsOpenPopupCustom}
          />
        </>
      )}
    </form>
  );
};

export default CreateGroupAccountFilter;
