import { Checkbox, FormControlLabel, Paper, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { handleExportExcel } from 'helpers';
import { WHEAT, YELLOW } from 'constants/ColorForm';
import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import {
  STATUS_REQUEST_IN_PROGRESS,
  STATUS_REQUEST_COMPLETED,
  EXPORT_OT_GLOBAL,
  EXPORT_OT_DETAIL,
  EXPORT_OT_ALL,
  LENGTH_OF_LIST_OT_EXPORT,
  TAB_EXPORT_GLOBAL,
  TAB_EXPORT_DETAIL,
  STATUS_REQUEST_OPEN,
  STATUS_REQUEST_REWORK,
  STATUS_REQUEST_RECALL,
  STATUS_REQUEST_DENY,
} from 'constants/TimeKeeping';
import Loading from 'components/Loading';
import { transferStatusToColor } from '../Request/ultil';
import { LENGTH_OF_DATA_NULL } from 'constants/TimeKeeping';
import moment from 'moment';

function ModalExportOt(props) {
  const { isShowModal, setShowModal, filter, page, perPage, uDataOTExport, isLoadingOT } = props;
  const { t } = useTranslation();
  const [messages, setMessages] = useState('');
  const [filterExport, setFilterExport] = useState({ type: 1 });

  const [dataExport, setDataExport] = useState([]);
  const [dataExportDetail, setDataExportDetail] = useState([]);

  useEffect(() => {
    if (uDataOTExport) {
      const data = [];
      const data_detail = [];
      const length_over_times = uDataOTExport?.data?.over_times.length;
      const length_detail = uDataOTExport?.data?.detail_over_times?.length;
      for (let i = 0; i < LENGTH_OF_LIST_OT_EXPORT; i++) {
        i < length_over_times && data.push(uDataOTExport?.data?.over_times[i]);
        i < length_detail && data_detail.push(uDataOTExport?.data?.detail_over_times[i]);
      }
      setDataExport(data);
      setDataExportDetail(data_detail);
    }
  }, [uDataOTExport]);

  const [checked, setChecked] = useState([true, true]);
  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[TAB_EXPORT_DETAIL]]);
  };

  const handleChange3 = (event) => {
    setChecked([checked[TAB_EXPORT_GLOBAL], event.target.checked]);
  };

  useEffect(() => {
    if (filterExport) {
      delete filter.page;
      delete filter.per_page;
      if (checked[TAB_EXPORT_GLOBAL] && !checked[TAB_EXPORT_DETAIL]) {
        setFilterExport({ type: EXPORT_OT_GLOBAL, ...filter });
        setMessages('');
      } else if (!checked[TAB_EXPORT_GLOBAL] && checked[TAB_EXPORT_DETAIL]) {
        setFilterExport({ type: EXPORT_OT_DETAIL, ...filter });
        setMessages('');
      } else if (checked[TAB_EXPORT_GLOBAL] && checked[TAB_EXPORT_DETAIL] && dataExport.length > 0) {
        setFilterExport({ type: EXPORT_OT_ALL, ...filter });
        setMessages('');
      } else if (checked[TAB_EXPORT_GLOBAL] && checked[TAB_EXPORT_DETAIL] && dataExport.length === 0) {
        setFilterExport({ type: EXPORT_OT_ALL, ...filter });
      } else {
        setMessages(t('timekeeping.OT.pleaseChooseTypeOfExportOT'));
      }
      filter.page = page;
      filter.per_page = perPage;
    }
    // eslint-disable-next-line
  }, [checked, filter]);

  const getStatus = (status) => {
    if (status === STATUS_REQUEST_OPEN) {
      return <div className={transferStatusToColor(STATUS_REQUEST_OPEN)}>{t('timekeeping.request.statusOpen')}</div>;
    } else if (status === STATUS_REQUEST_IN_PROGRESS) {
      return (
        <div className={transferStatusToColor(STATUS_REQUEST_IN_PROGRESS)}>
          {t('timekeeping.request.statusInProgress')}
        </div>
      );
    } else if (status === STATUS_REQUEST_COMPLETED) {
      return (
        <div className={transferStatusToColor(STATUS_REQUEST_COMPLETED)}>
          {t('timekeeping.request.statusCompleted')}
        </div>
      );
    } else if (status === STATUS_REQUEST_DENY) {
      return <div className={transferStatusToColor(STATUS_REQUEST_DENY)}>{t('timekeeping.request.statusDeny')}</div>;
    } else if (status === STATUS_REQUEST_REWORK) {
      return (
        <div className={transferStatusToColor(STATUS_REQUEST_REWORK)}>{t('timekeeping.request.statusRework')}</div>
      );
    } else if (status === STATUS_REQUEST_RECALL) {
      return (
        <div className={transferStatusToColor(STATUS_REQUEST_RECALL)}>{t('timekeeping.request.statusRecall')}</div>
      );
    } else {
      return '';
    }
  };

  const handleExport = async () => {
    const exportDate = moment(new Date()).format('DD-MM-YYYY-HH-mm');
    if (checked[TAB_EXPORT_GLOBAL] !== checked[TAB_EXPORT_DETAIL]) {
      await handleExportExcel(
        'api/time-sheet/export/list-OT',
        filterExport,
        filterExport.type === 1 ? `Bảng OT chi tiết_${exportDate}.xlsx` : `Bảng OT tổng hợp_${exportDate}.xlsx`,
      );
    } else {
      await handleExportExcel(
        'api/time-sheet/export/list-OT',
        { ...filterExport, type: 1 },
        `Bảng OT chi tiết_${exportDate}.xlsx`,
      );
      await handleExportExcel(
        'api/time-sheet/export/list-OT',
        { ...filterExport, type: 2 },
        `Bảng OT tổng hợp_${exportDate}.xlsx`,
      );
    }
  };

  const getText = (value) => {
    if (value !== null) {
      return value;
    }
    return <>{t('infoEmployee.notUpdate')}</>;
  };

  return (
    <ModalComponent
      toggle={() => {
        setShowModal(!isShowModal);
      }}
      isShowModal={isShowModal}
      title={t('timekeeping.OT.tableOtExport')}
      classNameAdd="modal-export-ot"
    >
      <section>
        <div className="export-ot-option">
          <FormControlLabel
            label={t('timekeeping.OT.syntheticOptionExport')}
            control={
              <Checkbox
                checked={checked[TAB_EXPORT_GLOBAL]}
                onChange={handleChange2}
                sx={{
                  color: WHEAT,
                  '&.Mui-checked': {
                    color: YELLOW,
                  },
                }}
              />
            }
          />
          <FormControlLabel
            label={t('timekeeping.OT.detailOptionExport')}
            control={
              <Checkbox
                checked={checked[TAB_EXPORT_DETAIL]}
                onChange={handleChange3}
                sx={{
                  color: WHEAT,
                  '&.Mui-checked': {
                    color: YELLOW,
                  },
                }}
              />
            }
          />
        </div>

        {/* Demo table */}
        <div className="demo-table list-OT">
          <div className="messageExport">{messages}</div>
          <div className="profile-box table-list table-border-show">
            {/* Table 1: Synthetic OT table */}
            {checked[TAB_EXPORT_GLOBAL] === true && checked[TAB_EXPORT_DETAIL] === false && (
              <Paper>
                <TableContainer className={`${true && 'scroll-custom'}`}>
                  <Table aria-label="sticky table" className={`${true && 'table-scroll'}`}>
                    <TableHead className="asset-header">
                      <TableRow className="asset-header-row">
                        <TableCell>{t('contract.contractDetail.code')}</TableCell>
                        <TableCell>{t('masterData.groupAccountList.nameEmployee')}</TableCell>
                        <TableCell>{t('division.Division')}</TableCell>
                        <TableCell>
                          {t('timekeeping.OT.timeOTON')} <br></br> {t('timekeeping.OT.factorIncluded')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoadingOT && (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Loading addClass="mt-3 mb-3" />
                          </TableCell>
                        </TableRow>
                      )}
                      {dataExportDetail &&
                        dataExportDetail?.map((item, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              <TableCell>{getText(item?.code)}</TableCell>
                              <TableCell>
                                <div className="linkRequest">{getText(item?.user_name)}</div>
                                <div className="email">{getText(item?.email)}</div>
                              </TableCell>
                              <TableCell>{getText(item?.division_name)}</TableCell>

                              <TableCell>{getText(item?.over_times)}</TableCell>
                            </TableRow>
                          );
                        })}
                      {dataExportDetail?.length === LENGTH_OF_DATA_NULL && (
                        <tr>
                          <td colSpan={4} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}

            {/* Table 2: Detail OT table */}
            {checked[TAB_EXPORT_DETAIL] === true && checked[TAB_EXPORT_GLOBAL] === false && (
              <Paper>
                <TableContainer className={`${true && 'scroll-custom'}`}>
                  <Table aria-label="sticky table" className={`${true && 'table-scroll'}`}>
                    <TableHead className="asset-header">
                      <TableRow className="asset-header-row">
                        <TableCell>{t('contract.contractDetail.code')}</TableCell>
                        <TableCell colSpan={2}>{t('masterData.groupAccountList.nameEmployee')}</TableCell>
                        <TableCell>{t('division.Division')}</TableCell>
                        <TableCell>{t('timekeeping.OT.project')}</TableCell>
                        <TableCell>{t('timekeeping.OT.date')}</TableCell>
                        <TableCell>{t('timekeeping.OT.timeOt')}</TableCell>
                        <TableCell>{t('timekeeping.OT.otType')}</TableCell>
                        <TableCell>{t('timekeeping.OT.workTime')}</TableCell>
                        <TableCell>{t('timekeeping.OT.workTimeOtOn')}</TableCell>
                        <TableCell>{t('assets.type')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoadingOT && (
                        <TableRow>
                          <TableCell colSpan={11}>
                            <Loading addClass="mt-3 mb-3" />
                          </TableCell>
                        </TableRow>
                      )}
                      {dataExport &&
                        dataExport?.map((item, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              <TableCell>{getText(item?.code)}</TableCell>
                              <TableCell colSpan={2}>
                                <div className="linkRequest">{getText(item?.user_name)}</div>
                                <div className="email">{getText(item?.email)}</div>
                              </TableCell>
                              <TableCell>{getText(item?.division_name)}</TableCell>
                              <TableCell>{getText(item?.project_name)}</TableCell>
                              <TableCell>{getText(item?.hr_date_log)}</TableCell>
                              <TableCell>
                                <div className="linkRequest">
                                  {getText(item?.hr_start_time_log)} - {getText(item?.hr_end_time_log)}
                                </div>
                                <div className="email">{t('masterData.overTime.shiftOfTheDay')}</div>
                              </TableCell>
                              <TableCell>{getText(item?.type_name)}</TableCell>
                              <TableCell>{getText(item?.total)}</TableCell>
                              <TableCell>{getText(item?.value)}</TableCell>
                              <TableCell>{item?.status ? getStatus(item?.status) : getText()}</TableCell>
                            </TableRow>
                          );
                        })}
                      {dataExport?.length === LENGTH_OF_DATA_NULL && (
                        <tr>
                          <td colSpan={11} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
            {/* Table 3: Cả hai */}
            {checked[TAB_EXPORT_DETAIL] === true && checked[TAB_EXPORT_GLOBAL] === true && (
              <Paper>
                <TableContainer className={`${true && 'scroll-custom'}`}>
                  <Table aria-label="sticky table" className={`${true && 'table-scroll'}`}>
                    <TableHead className="asset-header">
                      <TableRow className="asset-header-row">
                        <TableCell>{t('contract.contractDetail.code')}</TableCell>
                        <TableCell colSpan={2}>{t('masterData.groupAccountList.nameEmployee')}</TableCell>
                        <TableCell>{t('division.Division')}</TableCell>
                        <TableCell>{t('timekeeping.OT.project')}</TableCell>
                        <TableCell>{t('timekeeping.OT.date')}</TableCell>
                        <TableCell>{t('timekeeping.OT.timeOt')}</TableCell>
                        <TableCell>{t('timekeeping.OT.otType')}</TableCell>
                        <TableCell>{t('timekeeping.OT.workTime')}</TableCell>
                        <TableCell>{t('timekeeping.OT.workTimeOtOn')}</TableCell>
                        <TableCell>{t('assets.type')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoadingOT && (
                        <TableRow>
                          <TableCell colSpan={11}>
                            <Loading addClass="mt-3 mb-3" />
                          </TableCell>
                        </TableRow>
                      )}
                      {dataExport &&
                        dataExport?.map((item, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              <TableCell>{getText(item?.code)}</TableCell>
                              <TableCell colSpan={2}>
                                <div className="linkRequest">{getText(item?.user_name)}</div>
                                <div className="email">{getText(item?.email)}</div>
                              </TableCell>
                              <TableCell>{getText(item?.division_name)}</TableCell>
                              <TableCell>{getText(item?.project_name)}</TableCell>
                              <TableCell>{getText(item?.hr_date_log)}</TableCell>
                              <TableCell>
                                <div className="linkRequest">
                                  {getText(item?.hr_start_time_log)} - {getText(item?.hr_end_time_log)}
                                </div>
                                <div className="email">{t('masterData.overTime.shiftOfTheDay')}</div>
                              </TableCell>
                              <TableCell>{getText(item?.type_name)}</TableCell>
                              <TableCell>{getText(item?.total)}</TableCell>
                              <TableCell>{getText(item?.value)}</TableCell>
                              <TableCell>{item?.status ? getStatus(item?.status) : getText()}</TableCell>
                            </TableRow>
                          );
                        })}
                      {dataExport?.length === LENGTH_OF_DATA_NULL && (
                        <tr>
                          <td colSpan={11} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </div>
        </div>

        {/* Bottom button */}
        <div className="mt-5 d-flex justify-content-center">
          <ButtonComponent
            text={t('common.export')}
            addClass="w-50 btn-submit"
            type="submit"
            handleClick={() => handleExport()}
          />
        </div>
      </section>
    </ModalComponent>
  );
}

export default ModalExportOt;
