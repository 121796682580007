import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert'
import classNames from 'classnames';
import moment from 'moment';
import { MdDelete } from 'react-icons/md';
import { FormControlLabel, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import circleAdd from 'assets/img/icons/circle_add.png';
import avatarDefault from 'assets/img/icons/avatar_default.svg';
import { HTTP_OK } from 'constants/StatusCode';
import { WHEAT } from 'constants/ColorForm';
import { BG_POSITION } from 'constants/ColorMap';
import { ROLE_EDIT } from 'constants/index';
import { INCREASE_STATUS, DECREASE_STATUS, STATUS_NOT_DECREASE, NOT_RECALL, IS_RECALL } from 'constants/Policy';
import { apiAddSocialInsurance, apiEditSocialInsurance, apiCheckExistsUser } from 'api/policy';
import { handleOnError } from 'helpers';
import { useListUserInfoActive } from 'hook/useUser';
import Alert from 'components/Alert';
import ModalComponent from 'components/Modal';
import InputField from 'components/Input/InputField';
import Button from 'components/Buttons';
import SelectField from 'components/Select/Select';
import DateDayMonth from 'components/DateTime/DateMonth';
import styleSelect from 'components/Select/style.module.scss';
import styles from './styles.module.scss';

const formatFields = ['increase_date', 'decrease_date', 'recall_start_date', 'recall_end_date', 'basic_salaries'];

const UpdateOrCreateInsurancePolicy = ({ open, formData, refetch, toggle, permission }) => {
  const { t } = useTranslation();
  const { data: users } = useListUserInfoActive();
  const [alertMessage, setAlertMessage] = useState('');

  const initialInputValue = {
    user_id: '',
    social_insurance_code: '',
    increase_status: '',
    increase_date: '',
    is_recall: 0,
    decrease_status: STATUS_NOT_DECREASE,
    decrease_date: '',
    recall_start_date: '',
    recall_end_date: '',
    basic_salaries: [
      {
        insurance_salary: '',
        start_date: '',
        error_insurance_salary: '',
        error_start_date: '',
      },
    ],
  };
  const [inputValue, setInputValue] = useState(initialInputValue);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (formData?.id) {
      const basicSalaries = formData.basic_salaries.map((salary) => ({
        ...salary,
        start_date: getFormatDate(salary.start_date),
      }));
      formatFields.forEach((field) => (formData[field] = getFormatDate(formData[field])));
      setInputValue({ ...formData, basic_salaries: basicSalaries });
    } else {
      setInputValue({ ...initialInputValue, is_editable: true });
    }
    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    if (!open) {
      setInputValue(initialInputValue);
    }
    // eslint-disable-next-line
  }, [open]);

  const onSalaryFluctuations = (index) => {
    const basicSalaries = [...inputValue.basic_salaries];
    if (index === null) {
      basicSalaries.push({ insurance_salary: null, start_date: null });
      setInputValue({ ...inputValue, basic_salaries: basicSalaries });
    } else {
      confirmAlert({
        title: t('policy.messages.confirmAlertDeleteBasicSalary'),
        buttons: [
          {
            label: t('common.cancel'),
            className: 'btn-alert-cancel',
          },
          {
            label: t('common.delete'),
            className: 'btn-alert-ok',
            onClick: async () => {
              basicSalaries.splice(index, 1);
              setInputValue({ ...inputValue, basic_salaries: basicSalaries });
            },
          },
        ],
      })
    }
  };

  const checkUserExists = async (userId, field) => {
    if (field === 'user_id') {
      const response = await apiCheckExistsUser({ [field]: userId, action: 'create' })
      if (response.status !== HTTP_OK) {
        setError({ ...error, user_id: response.data.errors[field].toString() })
        setInputValue({ ...inputValue, [field]: userId });
        return false
      }
      return true
    }
    return true
  }

  const onChangeInputValue = async (value, index, field) => {
    const basicSalaries = [...inputValue.basic_salaries];
    if (index === null) {
      const _inputValue = { ...inputValue }
      if (typeof value === 'boolean') {
        _inputValue[field] = value ? IS_RECALL : NOT_RECALL;
        if (value) {
          _inputValue.decrease_date = null
          _inputValue.decrease_status = STATUS_NOT_DECREASE
        } else {
          _inputValue.recall_start_date = null
          _inputValue.recall_end_date = null
        }
        delete error.decrease_date
        delete error.recall_start_date
        delete error.recall_end_date
      } else {
        if (field === 'user_id') {
          delete error.increase_date
        } else if (field === 'increase_date') {
          basicSalaries[0]['start_date'] = value
        }
        _inputValue[field] = value;
      }
      const validUser = await checkUserExists(value, field)
      if (!validUser) {
        return
      }
      setError({ ...error, [field]: '' });
      setInputValue(_inputValue);
    } else {
      basicSalaries[index][field] = field === 'start_date' ? moment(value).format('YYYY/MM/DD') : value;
      delete basicSalaries[index][`error_${field}`];
      setInputValue({ ...inputValue, basic_salaries: basicSalaries });
    }
  };

  const onCloseModal = () => {
    setInputValue(initialInputValue);
    setError({});
    toggle();
  };

  const onErrors = (errors) => {
    for (const key in errors) {
      if (errors[key] instanceof Array) {
        if (key === 'basic_salaries') {
          const errorBasicSalaries = [...inputValue.basic_salaries]
          errors[key].forEach((error, index) => {
            errorBasicSalaries[index].error_start_date = error.start_date.toString()
          })
          setInputValue({ ...inputValue, basic_salaries: errorBasicSalaries })
        } else {
          setError({ ...error, [key]: errors[key].toString() })
        }
      } else {
        setError({ ...error, [key]: errors[key].toString() })
      }
    }
  }

  const onSubmitValue = async () => {
    const errors = validateForm();
    if (
      Object.values(errors).some((error) => error) ||
      inputValue.basic_salaries.some((inputError) => inputError.error_insurance_salary || inputError.error_start_date)
    ) {
      setError(errors);
      return;
    }
    formatFields.forEach((field) => {
      if (inputValue[field] instanceof Array) {
        inputValue[field].forEach((input) => {
          if (moment(input.start_date, 'DD/MM/YYYY', true).isValid()) {
            input.start_date = moment(input.start_date, 'DD/MM/YYYY').startOf('month').format('DD/MM/YYYY');
          } else {
            input.start_date = moment(input.start_date, 'YYYY/MM/DD').startOf('month').format('DD/MM/YYYY');
          }
          input.insurance_salary = input.insurance_salary.toString().replaceAll(/[.,]/g, '');
        });
      } else {
        if (moment(inputValue[field], 'DD/MM/YYYY', true).isValid()) {
          inputValue[field] = inputValue[field] ? moment(inputValue[field], 'DD/MM/YYYY').startOf('month').format('DD/MM/YYYY') : null;
        } else {
          inputValue[field] = inputValue[field] ? moment(inputValue[field], 'YYYY/MM/DD').startOf('month').format('DD/MM/YYYY') : null;
        }
      }
    });
    setIsLoading(true);
    try {
      let response;
      if (inputValue?.id) {
        response = await apiEditSocialInsurance(inputValue, inputValue.id);
      } else {
        response = await apiAddSocialInsurance(inputValue);
      }
      if (response.data.status === HTTP_OK || response.status === HTTP_OK) {
        setIsLoading(false);
        onCloseModal();
        setAlertMessage(t('policy.messages.confirmAlertAddInsurance'));
        refetch();
      } else {
        setIsLoading(false);
        onErrors(response.data.errors)
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    const errors = { ...error };
    if (!inputValue.user_id) {
      errors.user_id = t('policy.errors.pleaseSelectTheNameOfTheInsuredPerson');
      if (inputValue.increase_date) {
        errors.increase_date = t('policy.errors.pleaseSelectInsuranceCoverageFirst');
      }
    }
    if (!inputValue.social_insurance_code) {
      errors.social_insurance_code = t('policy.errors.pleaseFillInThisField');
    }
    if (!inputValue.increase_status) {
      errors.increase_status = t('policy.errors.pleaseSelectTheInsuranceIncreaseNotificationStatus');
    }
    if (!inputValue.increase_date) {
      errors.increase_date = t('policy.errors.pleaseSelectTheMonthToReportTheIncreaseInInsurance');
    }
    if (
      inputValue.decrease_status !== STATUS_NOT_DECREASE &&
      !inputValue.decrease_date &&
      inputValue.increase_date &&
      !inputValue.is_recall
    ) {
      errors.decrease_date = t('policy.errors.pleaseSelectTheMonthToReportTheDiscount');
    }
    if (inputValue.is_recall) {
      if (!inputValue.recall_start_date) {
        errors.recall_start_date = t('policy.errors.pleaseSelectTheMonthToStartArrears');
      }
      if (!inputValue.recall_end_date) {
        errors.recall_end_date = t('policy.errors.pleaseSelectTheMonthToEndArrears');
      }
    }
    inputValue.basic_salaries.forEach((salary, index) => {
      const inputError = [...inputValue.basic_salaries];
      if (!salary.insurance_salary) {
        inputError[index].error_insurance_salary = t('common.thisIsObligation');
      }
      if (index && !salary.start_date) {
        inputError[index].error_start_date = t('common.thisIsObligation');
      }
    });
    return errors;
  };

  const currentUser = users?.data.find((user) => user.user_id === inputValue.user_id);
  const isDetail = formData.id && (!formData.is_editable || permission !== ROLE_EDIT);

  const getFormatDate = (date, currentFormat = 'DD/MM/YYYY', format = 'YYYY/MM') => {
    return moment(date, currentFormat, true).isValid() ? moment(date, currentFormat).format(format) : date;
  };

  return (
    <ModalComponent
      title={t('policy.formInsurance.employeesParticipatingInInsurance')}
      isShowModal={open}
      fullWidth={true}
      toggle={onCloseModal}
    >
      <form>
        <Box>
          <span className={styles.itemTitle}>{t('policy.formInsurance.insuranceParticipationInformation')}</span>
        </Box>
        <Box className="mt-3">
          <div className={classNames(styleSelect.selectComponent, styleSelect.selectComponentAuto)}>
            {currentUser && inputValue.user_id ? (
              <Autocomplete
                value={currentUser}
                disabled={isDetail}
                disableClearable
                autoHighlight
                getOptionLabel={(option) => {
                  const user = users?.data.find((user) => user.user_id === inputValue.user_id);
                  if (user?.name) {
                    return option.name;
                  }
                  return '';
                }}
                options={users?.data}
                size="small"
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.user_id}>
                    <img
                      onError={handleOnError}
                      loading="lazy"
                      src={option.avatar || avatarDefault}
                      alt={option.avatar}
                      className="avatar bg-transparent"
                    />
                    <div>
                      <p>{option.name}</p>
                      <p>{option.email}</p>
                    </div>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!!error.user_id}
                    helperText={error.user_id}
                    label={
                      <Box component="span" fontSize={14}>
                        {t('policy.formInsurance.firstAndLastName')}
                      </Box>
                    }
                    name="user_id"
                    type="search"
                  />
                )}
                onChange={(_, item) => onChangeInputValue(item.user_id, null, 'user_id')}
              />
            ) : null}
            {!inputValue.user_id ? (
              <Autocomplete
                disableClearable
                autoHighlight
                options={users?.data}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.user_id}>
                    <img
                      onError={handleOnError}
                      loading="lazy"
                      src={option.avatar || avatarDefault}
                      alt={option.avatar}
                      className="avatar bg-transparent"
                    />
                    <div>
                      <p>{option.name}</p>
                      <p>{option.email}</p>
                    </div>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!!error.user_id}
                    helperText={error.user_id}
                    label={
                      <Box component="span" fontSize={14}>
                        {t('policy.formInsurance.firstAndLastName')}
                      </Box>
                    }
                    inputProps={{ ...params.inputProps }}
                    name="user_id"
                    type="search"
                  />
                )}
                onChange={(_, item) => onChangeInputValue(item.user_id, null, 'user_id')}
              />
            ) : null}
          </div>
        </Box>
        <Box className="mt-3">
          <InputField
            isRequired
            disabled={isDetail}
            maxLength={30}
            label={t('policy.formInsurance.insuranceCode')}
            error={error.social_insurance_code}
            defaultValue={inputValue.social_insurance_code}
            onHandleInput={(e) => onChangeInputValue(e.target.value, null, 'social_insurance_code')}
          />
        </Box>
        <Box className="mt-3">
          <SelectField
            name="increase_status"
            label={t('policy.formInsurance.newRisingStatus')}
            error={error.increase_status}
            disabled={isDetail}
            valueDefault={inputValue.increase_status}
            onHandleChangeSelect={(e) => onChangeInputValue(e.target.value, null, 'increase_status')}
          >
            {INCREASE_STATUS.map((status, index) => (
              <MenuItem key={index} value={status.id}>
                {status.name}
              </MenuItem>
            ))}
          </SelectField>
        </Box>
        <Box className="mt-3">
          <DateDayMonth
            addClass="from-date"
            name="increase_date"
            error={error.increase_date}
            classError={styles.error}
            disable={isDetail}
            valueDefault={getFormatDate(inputValue.increase_date)}
            placeholder={t('policy.formInsurance.monthlyNewspaperIncrease')}
            sizeDate="medium"
            onChangeHandle={(value) => onChangeInputValue(value, null, 'increase_date')}
          />
        </Box>
        <Box>
          <FormControlLabel
            disabled={isDetail}
            control={
              <Checkbox
                onChange={(e) => onChangeInputValue(e.target.checked, null, 'is_recall')}
                sx={{
                  color: WHEAT,
                  '&.Mui-checked': {
                    color: BG_POSITION[0],
                  },
                }}
                checked={inputValue.is_recall ? true : false}
              />
            }
            label={
              <Box component="span" fontSize={14}>
                {t('policy.formInsurance.confirmCharged')}
              </Box>
            }
          />
        </Box>
        <Box className="mt-1">
          {inputValue.is_recall ? (
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <DateDayMonth
                  addClass="from-date"
                  name="recall_start_date"
                  disable={isDetail}
                  valueDefault={getFormatDate(inputValue.recall_start_date)}
                  error={error.recall_start_date}
                  classError={styles.error}
                  placeholder={t('policy.formInsurance.collectedFrom')}
                  sizeDate="medium"
                  min={moment(inputValue.increase_date, 'MM/YYYY').add(1, 'M').toDate()}
                  onChangeHandle={(value) => onChangeInputValue(value, null, 'recall_start_date')}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <DateDayMonth
                  addClass="from-date"
                  name="recall_end_date"
                  disable={isDetail}
                  valueDefault={getFormatDate(inputValue.recall_end_date)}
                  error={error.recall_end_date}
                  classError={styles.error}
                  placeholder={t('policy.formInsurance.collectionTo')}
                  sizeDate="medium"
                  min={moment(inputValue.recall_start_date, 'MM/YYYY').toDate()}
                  onChangeHandle={(value) => onChangeInputValue(value, null, 'recall_end_date')}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Box>
                <SelectField
                  label={t('policy.formInsurance.insuranceReductionStatus')}
                  valueDefault={inputValue.decrease_status}
                  disabled={isDetail}
                  onHandleChangeSelect={(e) => onChangeInputValue(e.target.value, null, 'decrease_status')}
                >
                  {DECREASE_STATUS.map((status, index) => (
                    <MenuItem key={index} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Box>
              <Box className="mt-3">
                <DateDayMonth
                  addClass="from-date"
                  name="decrease_date"
                  valueDefault={getFormatDate(inputValue.decrease_date)}
                  error={error.decrease_date}
                  classError={styles.error}
                  disable={!inputValue.increase_date || inputValue.decrease_status === STATUS_NOT_DECREASE || isDetail}
                  placeholder={t('policy.formInsurance.monthlyNewspaperReduced')}
                  sizeDate="medium"
                  min={
                    moment(inputValue.increase_date, 'DD/MM/YYYY', true).isValid()
                      ? moment(inputValue.increase_date, 'DD/MM/YYYY').add(1, 'M').toDate()
                      : moment(inputValue.increase_date, 'MM/YYYY').add(1, 'M').toDate()
                  }
                  onChangeHandle={(value) => onChangeInputValue(value, null, 'decrease_date')}
                />
              </Box>
            </>
          )}
        </Box>
        <Box className={styles.salarySection}>
          <span className={styles.itemTitle}>{t('policy.formInsurance.basicSalary')}</span>
        </Box>
        <Box className="mt-3">
          {inputValue.basic_salaries.map((item, index) => (
            <Box key={index} className="mt-2">
              <Grid container spacing={2}>
                <Grid item md={5.5} xs={12}>
                  <InputField
                    name="insurance_salary"
                    label={
                      index ? t('policy.formInsurance.newClosingLevel') : t('policy.formInsurance.initialClosingRate')
                    }
                    disabled={isDetail}
                    isFormatNumber
                    isInteger
                    dataType="number"
                    maxLength={11}
                    error={item.error_insurance_salary}
                    defaultValue={item.insurance_salary || ''}
                    onHandleInput={(e) => onChangeInputValue(e.target.value, index, 'insurance_salary')}
                  />
                </Grid>
                <Grid item md={5.5} xs={12}>
                  <DateDayMonth
                    addClass="from-date"
                    name="start_date"
                    valueDefault={getFormatDate(item.start_date)}
                    placeholder={t('policy.formInsurance.selectStartTime')}
                    error={item.error_start_date}
                    classError={styles.error}
                    sizeDate="medium"
                    disable={!index || isDetail}
                    min={moment(inputValue.basic_salaries[index - 1]?.start_date, 'YYYY/MM').add(1, 'M').toDate()}
                    max={moment(inputValue.basic_salaries[index + 1]?.start_date, 'YYYY/MM').subtract(1, 'M').toDate()}
                    onChangeHandle={(value) => onChangeInputValue(value, index, 'start_date')}
                  />
                </Grid>
                {index ? (
                  <Grid
                    item
                    md={1}
                    xs={12}
                    className={classNames({ [styles.pointerEvents]: isDetail })}
                    onClick={() => onSalaryFluctuations(index)}
                  >
                    <div className={styles.iconDelete}>
                      <MdDelete />
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          ))}
        </Box>
        <div
          className={classNames(styles.addItems, { [styles.pointerEvents]: isDetail })}
          onClick={() => onSalaryFluctuations(null)}
        >
          <img alt={circleAdd} className={styles.icon} src={circleAdd} />
          <span className={styles.textAdd}>{t('policy.formInsurance.addSalaryFluctuations')}</span>
        </div>
        <Box className="mt-4">
          <Grid container spacing={2}>
            <Grid item md={isDetail ? 12 : 6} xs={12}>
              <Button
                text={isDetail ? t('common.close') : t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={onCloseModal}
              />
            </Grid>
            {isDetail ? null : (
              <Grid item md={6} xs={12}>
                <Button addClass="w-100" text={t('common.save')} isLoading={isLoading} handleClick={onSubmitValue} />
              </Grid>
            )}
          </Grid>
        </Box>
      </form>
      <Alert toggle={() => setAlertMessage('')} isShowAlert={!!alertMessage}>
        {alertMessage}
      </Alert>
    </ModalComponent>
  );
};

export default UpdateOrCreateInsurancePolicy;
