import { DatePicker, Form, InputNumber, Modal, Select, message } from 'antd';
import { FIELD_KEY, COURSE_OUTSIDE_COMPANY, COURSE_IN_COMPANY } from 'constants/Training';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { ArrowDownIcon, CalendarIcon } from '../icon';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import { DATE_FORMAT, DATE_FORMAT_YMD } from '..';
import { useMutation } from 'react-query';
import { apiAddMemberToCourse } from 'api/training';
import moment from 'moment';
import { removeVietnameseTones } from 'helpers';
import { HTTP_CREATED } from 'constants/StatusCode';

const AddMember = ({ isOpen, setIsOpen, selectedCourse, users ,refetch}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  useEffect(() => {
    const typeProgram = selectedCourse.type;
    let fields;

    switch (typeProgram) {
      case COURSE_IN_COMPANY:
        fields = {
          [FIELD_KEY.START_DATE]: selectedCourse.end_date ? moment(selectedCourse.end_date):'',
          [FIELD_KEY.END_DATE]: selectedCourse.end_date ? moment(selectedCourse.end_date).add(selectedCourse.month_commit, 'M'):'',
        };
        break;

      case COURSE_OUTSIDE_COMPANY:
        fields = {
          [FIELD_KEY.SUPPORT_COST]: selectedCourse.support_cost
        };
        break;

      default:
        fields = {};
    };
    
    form.setFieldsValue(fields);
  }, [form, isOpen, selectedCourse]);
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: t('messages.thisIsARequiredField'),
    whitespace: t('messages.thisIsARequiredField'),
    number: {
      range: t('messages.pleaseEnterValueBetween', { min: '${min}', max: '${max}' }),
      min: t('messages.pleaseEnterValueGreaterOrEqual', { min: '${min}' }),
    },
    types: {
      integer: t('messages.thisIsNotAnInteger')
    },
  };

  const addMemberMutation = useMutation((payload) => apiAddMemberToCourse(payload), {
    onSuccess: (res) => {
      const status = res?.status;
      refetch();
      if (status === HTTP_CREATED) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }

      handleCancel();
    },
    onError: (error) => {
      message.error(error.response.data.messages);
    },
  });
  const handleCancel = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const handleFinish = (values) => {
    const payload = {
      ...values,
      start_date: values.start_date.format(DATE_FORMAT_YMD),
      end_date: values.end_date.format(DATE_FORMAT_YMD),
      course_id: selectedCourse.id,
    };

    addMemberMutation.mutate(payload);
  };

  const handleDateChange = (value) => {
    if (selectedCourse.type === COURSE_IN_COMPANY) {
      form.setFieldsValue({
        [FIELD_KEY.END_DATE]: value ? moment(value).add(selectedCourse.month_commit, 'M') : null
      });
    }
  }

  return (
    <Modal
      wrapClassName={'custom-modal'}
      title={t('training.addMember')}
      closeIcon={<MdClose color="#fea628" size={24} />}
      cancelButtonProps={{ className: 'btn-cancel' }}
      cancelText={t('common.cancel')}
      okButtonProps={{ className: 'btn-ok', loading: false }}
      okText={t('common.save')}
      onCancel={handleCancel}
      onOk={() => {
        form.submit();
      }}
      visible={isOpen}
      width={650}
      style={{top: '110px'}}
    >
      <Form
        form={form}
        name="add-member"
        layout="vertical"
        size="large"
        className="materialStyle form"
        validateMessages={validateMessages}
        onFinish={handleFinish}
      >
        <Form.Item name={FIELD_KEY.USER_IDS} label={t('training.chooseMember')} rules={[{ required: true }]}>
          <Select
            mode="multiple"
            optionLabelProp="label"
            filterOption={(inputValue, option) =>
              (removeVietnameseTones(option?.label?.toLowerCase()) ?? '').includes(
                removeVietnameseTones(inputValue?.toLowerCase()),
              )
            }
            maxTagCount={'responsive'}
            suffixIcon={<ArrowDownIcon />}
            allowClear
            showArrow 
          >
            {users
              ? users.map((user) => (
                  <Select.Option key={user.user_id} value={user.user_id} label={user.name}>
                    <div className="option">
                      <img src={user.avatar || defaultAvatar} alt="" width={30} height={30} />

                      <div className="flex flex-col">
                        <span>{user.name}</span>
                        <span>{user.email}</span>
                      </div>
                    </div>
                  </Select.Option>
                ))
              : []}
          </Select>
        </Form.Item>
        {selectedCourse.type === COURSE_OUTSIDE_COMPANY && (
          <>  
            <Form.Item
              name={FIELD_KEY.SUPPORT_COST}
              label={t('training.supportCost')}
              rules={[
                { required: true },
                {
                  type: 'integer',
                  min: 0,
                },
              ]}
            >
              <InputNumber controls={false} />
            </Form.Item>
          </>
        )}
        <div className='flex gap-4'>
          <Form.Item name={FIELD_KEY.START_DATE} label={t('training.startDateCommit')} rules={[{ required: true }]} >
            <DatePicker 
              format={DATE_FORMAT} 
              suffixIcon={<CalendarIcon />} 
              placeholder="" 
              onChange={handleDateChange}
              disabledDate={(day) => {
                const endDate = form.getFieldValue(FIELD_KEY.END_DATE);
                return day.isSameOrAfter(endDate) && day && endDate;
              }}
            />
          </Form.Item>
          <Form.Item name={FIELD_KEY.END_DATE} label={t('training.endDateCommit')} rules={[{ required: true }]}>
            <DatePicker 
              format={DATE_FORMAT} 
              suffixIcon={<CalendarIcon />} 
              placeholder="" 
              disabledDate={(day) => {
                const startDate = form.getFieldValue(FIELD_KEY.START_DATE);
                return day.isSameOrBefore(startDate) && day && startDate;
              }}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default AddMember;
