import { Col, Row } from 'reactstrap';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosAdd } from 'react-icons/io';
import { useQueryClient } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';

import iconCv from 'assets/img/icons/cv_img.png';
import iconRecycle from 'assets/img/icons/recycle.png';
import iconDownload from 'assets/img/icons/download.png';
import { apiDeleteCV, apiCreateCV } from 'api/profileEmployee';
import AlertComponent from 'components/Alert';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import styles from './../style.module.scss';
import { USE_USER_PROFILE } from 'constants/ProfileEmployee';
import { apiDownloadByLink } from 'api/profileEmployee';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';
import storageFC from 'helpers/storage';

const CV = (props) => {
  const { uProfileUser, userId } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const DATA_POPUP_FORM = {
    id: null,
    file: '',
    type: '',
    user_id: userId,
    img_error: '',
  };

  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [formCV, setFormCV] = useState(DATA_POPUP_FORM);

  const onUploadImg = async (event) => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    const data = {
      file: event.target.files[0],
      type: 9,
      user_id: userId,
    };

    const fb = new FormData();
    fb.append('user_id', data.user_id);
    fb.append('type', data.type);
    fb.append('file', data.file);
    if (data?.file?.size / 1024 / 1024 > 2) {
      setMessage(t('messages.profileEmployee.outOfSize'));
      setShowAlert(true);
      return;
    }

    let response = {};
    response = await apiCreateCV(fb);

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setMessage(
        msg?.file
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      );
      setShowAlert(true);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
    }
    if (response && response?.data?.status === HTTP_OK) {
      setFormCV({ ...formCV, id: null, file: '', type: '', img_error: '' });
      setMessage(response.data.messages);
      setShowAlert(true);
      await queryClient.invalidateQueries(USE_USER_PROFILE);
    }
  };

  const alertConfirm = (id) => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    confirmAlert({
      title: t('infoEmployee.youWantToDelete'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteCV({ user_id: userId }, id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response && response.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              await queryClient.invalidateQueries(USE_USER_PROFILE);
            }
          },
        },
      ],
    });
  };

  const downloadCV = async (link, name) => {
    fetch(apiDownloadByLink(link), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'Authorization': `Bearer ${storageFC.getToken()}`
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <>
      <div className={styles.profileBoxCV}>
        <Row className={styles.rowTitle}>
          <Col xs="11">
            <div className={styles.profileTitle}>
              <img alt={iconCv} className={styles.imgTitle} src={iconCv} />
              {t('infoEmployee.titleCV')}
            </div>
          </Col>
          <Col xs="1">
            {uProfileUser?.data?.identification?.career_profile?.length && (
              <div className={styles.editDeleteButton}>
                {dataInforUser?.hr_permission?.users_permission !== ROLE_VIEW && (
                  <input
                    id="uploadCV"
                    type="file"
                    name="images[]"
                    style={{ display: 'none' }}
                    accept=".pdf"
                    onChange={(event) => onUploadImg(event)}
                  />
                )}
                <label
                  htmlFor={'uploadCV'}
                  className={`${styles.buttonFile} ${dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                    }`}
                >
                  <IoIosAdd />
                </label>
                <p className="error">{formCV.img_error}</p>
              </div>
            )}
          </Col>
        </Row>
        <div>
          <div className={styles.salaryBoxParent}>
            {uProfileUser &&
              (uProfileUser?.data?.identification?.career_profile?.length === 0) |
              (uProfileUser?.data?.identification?.career_profile === null) ? (
              <div className={styles.achievementNull}>
                <div className={styles.editDeleteButton}>
                  {dataInforUser?.hr_permission?.users_permission !== ROLE_VIEW && (
                    <input
                      id="uploadCV"
                      type="file"
                      name="images[]"
                      style={{ display: 'none' }}
                      accept=".pdf"
                      onChange={(event) => onUploadImg(event)}
                    />
                  )}
                  <label
                    htmlFor={'uploadCV'}
                    className={`${styles.buttonFile} ${dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                      }`}
                  >
                    <IoIosAdd />
                  </label>
                  <p className="error">{formCV.img_error}</p>
                </div>
                <div className={styles.profileContentInfoAlert}>{t('infoEmployee.addCV')}</div>
              </div>
            ) : (
              uProfileUser &&
              uProfileUser?.data?.identification?.career_profile?.map((val, index) => (
                <div key={index} className={styles.contentCV}>
                  <Col>
                    <Col md="12" className={styles.displayContentCV}>
                      <div className={styles.fixParent}>
                        <embed src={val.link} width="100" height="100" alt="pdf" className={styles.pdfThumb} />
                        <div className={styles.fix}></div>
                        <div className={styles.fixBottom}></div>
                      </div>
                      <div>
                        <div className={classNames(`${styles.profileContentInfo} text-nowrap`)}>{val.name}</div>
                        <div className={styles.profileContentInfo}>
                          PDF {val.size}kB | {t('infoEmployee.dateRefresh')}: {val.created_at}
                        </div>
                        <div className={styles.displayButtonCV}>
                          <div
                            className={`${styles.editDeleteCV} ${dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                              }`}
                            onClick={() => alertConfirm(val.id)}
                          >
                            <img alt={iconRecycle} src={iconRecycle} />
                          </div>
                          <div className={styles.editDeleteCV}>
                            <img alt={iconDownload} src={iconDownload} onClick={() => downloadCV(val.link, val.name)} />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Col>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};

export default CV;
