import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './style.module.scss';

const IndividualTimeKeeping = (props) => {
  const { t } = useTranslation();
  const { userId } = props;
  const routes = [
    {
      path: '/admin/timekeeping/individual/calendar/' + userId,
      name: t('timekeeping.calendar'),
    },
    {
      path: '/admin/timekeeping/individual/list-requests/' + userId,
      name: t('timekeeping.individual.requests'),
    },
    {
      path: '/admin/timekeeping/individual/list-ot/' + userId,
      name: t('timekeeping.individual.ot'),
    },
  ];

  return (
    <div>
      <div>
        <ul className={styles.navbar}>
          {routes &&
            routes.map((route, key) => {
              return (
                <li key={key}>
                  <NavLink
                    to={{
                      pathname: route.path,
                    }}
                    className={styles.link}
                    activeClassName={styles.isActive}
                  >
                    <span className={classNames(`${styles.navItem} text-bold`)}>{route.name}</span>
                  </NavLink>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default IndividualTimeKeeping;
