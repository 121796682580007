import React, { useState } from 'react';
import moment from 'moment';
import { Col, Row, Table, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import './style.scss';
import iconBirthday from 'assets/img/icons/birthday.png';
import iconEmployee from 'assets/img/icons/employees.png';
import DateMonthComponent from 'components/DateTime/DateMonth.js';
import DateYearComponent from 'components/DateTime/DateYear.js';
import SelectComponent from 'components/Select/Select';
import Employees from './../index.js';
import Loading from 'components/Loading';
import Session2 from './session2';
import Session3 from './session3';
import { customDate } from 'helpers/formatDate.js';
import { useListDivision, useListBirthday, useTotalEmployees } from 'hook/useEmployees.js';
import { BY_MONTH } from 'constants/ListEmployees';
import { BY_YEAR } from 'constants/ListEmployees';
import ListBirthDay from './ListBirthDay';

const DashboardEmployees = ({ isDashboard }) => {
  const { t } = useTranslation();
  const [monthBirthday, setMonthBirthday] = useState(moment().format('M'));
  const [dateEmployeeTotal, setDateEmployeeTotal] = useState(moment().subtract(1, 'months'));
  const [type, setType] = useState(1);
  const { data: listDivision } = useListDivision({});
  const { data: listBirthday, isLoading: isLoadingBirthday } = useListBirthday({
    filter_month: monthBirthday,
  });
  const { data: listTotalEmployees, isLoading: isLoadingTotalEmployees } = useTotalEmployees({
    filter_date:
      Number(type) === Number(BY_MONTH)
        ? customDate(dateEmployeeTotal, 'MM/YYYY')
        : customDate(dateEmployeeTotal, 'YYYY'),
    type: type,
  });

  const handelFilterBirthday = (event) => {
    setMonthBirthday(event.target.value);
  };

  const handelFilterTotalEmployees = (value) => {
    setDateEmployeeTotal(value);
  };

  const checkIsBirthday = (birthday) => {
    if (birthday) {
      const birthdayDate = birthday.split("/")[0];
      const birthdayMonth = birthday.split("/")[1];
      const today = moment();
      if (Number(birthdayDate) === today.date() && Number(birthdayMonth) === today.month() + 1) {
        return "birthdayActive"
      }
    }
    return ""
  }

  const numberTurnOver = () => {
    return Number(
      (Number(
        listTotalEmployees?.data?.reduce((value, total) => {
          return Number(value ?? 0) + Number(total?.checkout ?? 0);
        }, 0) || 0,
      ) /
        ((Number(
          listTotalEmployees?.data?.reduce((value, total) => {
            return Number(value ?? 0) + Number(total?.member_previous_term ?? 0);
          }, 0) || 0,
        ) +
          Number(
            listTotalEmployees?.data?.reduce((value, total) => {
              return Number(value ?? 0) + Number(total?.member_end_term ?? 0);
            }, 0) || 0,
          )) /
          2)) *
      100,
    ).toFixed(2)
  }

  return (
    <div className="scroll ct-form-controller-filter mb-3 dashboard-employees">
      {!isDashboard && (
        <div className="nav-fixed">
          <Employees />
        </div>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={12} lg="8">
            <div className="statistical box-show-none">
              <div className="box-session border-show box-list">
                <div className="card-body-top">
                  <Row>
                    <Col className="col-md-6">
                      <div className="d-flex">
                        <img src={iconEmployee} alt={iconEmployee} className="icon" />
                        <h4 className="title-body">{t('employees.StatisticsOfPersonnelInTheCompany')}</h4>
                      </div>
                    </Col>
                    <Col className="col-md-3">
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        className="input-border filter-employee-division"
                        onChange={(e) => {
                          setType(e.target.value > 0 ? e.target.value : null);
                        }}
                      >
                        <option value={BY_MONTH}>{t('employees.monthly')}</option>
                        <option value={BY_YEAR}>{t('employees.byYear')}</option>
                      </Input>
                    </Col>
                    <Col className="col-md-3">
                      {Number(type) === BY_MONTH && (
                        <DateMonthComponent
                          label=""
                          valueDefault={dateEmployeeTotal}
                          onChangeHandle={handelFilterTotalEmployees}
                        />
                      )}
                      {Number(type) === BY_YEAR && (
                        <DateYearComponent
                          label=""
                          valueDefault={dateEmployeeTotal}
                          onChangeHandle={handelFilterTotalEmployees}
                        />
                      )}
                    </Col>
                  </Row>
                  <div className="text-employee info-top-body">
                    <p className="text-truncate">{t('common.employee')}</p>
                    <p className="number-employee">{listTotalEmployees ? listTotalEmployees.total : 0}</p>
                  </div>
                </div>
                <Table className="align-items-center border-grey table-fixed table-hover employees">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="border-right pl-3 title-division">
                        <div className="text-left">{t('masterData.groupAccountList.division')}</div>
                      </th>
                      <th scope="col" className="border-right">
                        <div className="text-center title-inth">
                          <p>{t('employees.LeaveTheCompany')}</p>
                          <p className="color-red">
                            {listTotalEmployees?.data?.reduce((value, total) => {
                              return Number(value ?? 0) + Number(total?.checkout ?? 0);
                            }, 0) || 0}
                          </p>
                        </div>
                      </th>
                      <th scope="col" className="border-right">
                        <div className="text-center title-inth">
                          <p>{t('employees.NewEmployee')}</p>
                          <p className="color-green">
                            {listTotalEmployees?.data?.reduce((value, total) => {
                              return Number(value ?? 0) + Number(total?.check_in ?? 0);
                            }, 0) || 0}
                          </p>
                        </div>
                      </th>
                      <th scope="col" className="border-right">
                        <div className="text-center title-inth">
                          <p>{t('employees.increaseInternalCirculation')}</p>
                          <p className="color-green">
                            {listTotalEmployees?.data?.reduce((value, total) => {
                              return Number(value ?? 0) + Number(total?.member_in ?? 0);
                            }, 0) || 0}
                          </p>
                        </div>
                      </th>
                      <th scope="col" className="border-right">
                        <div className="text-center title-inth">
                          <p>{t('employees.reduceInternalTurnover')}</p>
                          <p className="color-red">
                            {listTotalEmployees?.data?.reduce((value, total) => {
                              return Number(value ?? 0) + Number(total?.member_out ?? 0);
                            }, 0) || 0}
                          </p>
                        </div>
                      </th>
                      <th scope="col" className="border-right">
                        <div className="text-center title-inth">
                          <p>{t('employees.beginningBalance')}</p>
                          <p className="color-residual">
                            {listTotalEmployees?.data?.reduce((value, total) => {
                              return Number(value ?? 0) + Number(total?.member_previous_term ?? 0);
                            }, 0) || 0}
                          </p>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="text-center title-inth">
                          <p>{t('employees.endingBalance')}</p>
                          <p className="color-residual">
                            {listTotalEmployees?.data?.reduce((value, total) => {
                              return Number(value ?? 0) + Number(total?.member_end_term ?? 0);
                            }, 0) || 0}
                          </p>
                        </div>
                      </th>
                      <th scope="col" className="turnover">
                        <div className="text-center title-inth">
                          <p>{t('employees.turnoverRate')}</p>
                          <p>
                            {isNaN(numberTurnOver()) ? 0 : numberTurnOver()}
                            {'%'}
                          </p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listTotalEmployees &&
                      listTotalEmployees?.data?.map((totalDivision, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-bold pl-3">{totalDivision.name || 0}</td>
                            <td className="text-center">{totalDivision.checkout || 0}</td>
                            <td className="text-center">{totalDivision.check_in || 0}</td>
                            <td className="text-center">{totalDivision.member_in || 0}</td>
                            <td className="text-center">{totalDivision.member_out || 0}</td>
                            <td className="text-center">{totalDivision.member_previous_term || 0}</td>
                            <td className="text-center">{totalDivision.member_end_term || 0}</td>
                            <td className="text-center">
                              {Number(totalDivision.turn_over ?? 0).toFixed(2) || 0}
                              {'%'}
                            </td>
                          </tr>
                        );
                      })}
                    {isLoadingTotalEmployees && (
                      <tr>
                        <td colSpan={8}>
                          <Loading addClass="mt-2" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </Grid>
          <Grid item lg="4" md={12} className="width-100">
            <div className="employee-birthday-list employee-list box-show-none">
              <div className="box-session border-show box-list">
                <div className="card-body-top">
                  <Row>
                    <Col className="col-md-7">
                      <div className="d-flex">
                        <img src={iconBirthday} alt={iconBirthday} className="icon" />
                        <h4 className="title-body">{t('employees.NumberOfEmployeesBirthday')}</h4>
                      </div>
                    </Col>
                    <Col className="col-md-5">
                      <SelectComponent
                        name="monthBirthday"
                        valueDefault={monthBirthday}
                        onHandleChangeSelect={handelFilterBirthday}
                      >
                        {Array(12)
                          .fill(0)
                          .map((_, index) => (
                            <MenuItem key={index} value={index + 1}>
                              {t('common.month')} {index + 1}
                            </MenuItem>
                          ))}
                      </SelectComponent>
                    </Col>
                  </Row>
                  <div className="text-employee info-top-body">
                    <p className="text-truncate">{t('employees.TotalForThisMonth')}</p>
                    <p className="number-employee">{listBirthday ? listBirthday.total : 0}</p>
                  </div>
                </div>
                <ListBirthDay
                  isLoadingBirthday={isLoadingBirthday}
                  listBirthday={listBirthday}
                  checkIsBirthday={checkIsBirthday}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Session2 listDivision={listDivision} />

        <Session3 listDivision={listDivision} />
      </Box>
    </div>
  );
};

export default DashboardEmployees;
