import { DatePicker, Form, Input, Select } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, CalendarIcon } from '../icon';
import { FIELD_KEY } from 'constants/Training';
import { MONTH_FORMAT, MONTH_FORMAT_YM } from '..';
import { AiOutlineSearch } from 'react-icons/ai';
import { DEFAULT_PAGE, DEFAULT_LIMIT } from 'constants/index';
import { removeVietnameseTones } from 'helpers';
import { debounce } from '@mui/material';

const FilterMember = ({ filter, setFilter, courses }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnChange = useCallback(
    debounce((value) => {
      const key = Object.keys(value)[0];
      if(key === 'keyword') {
        form.setFieldsValue({ keyword: value.keyword && value.keyword.trim() });
      }
      const values = form.getFieldValue();

      setFilter({
        ...filter,
        ...values,
        keyword: values.keyword && values.keyword.trim(),
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
        start_month: values.start_month && values.start_month.format(MONTH_FORMAT_YM),
        end_month: values.end_month && values.end_month.format(MONTH_FORMAT_YM),
      });
    }, 500)
  );

  const mapOptions = (options) =>
    options
      ? options.map((option) => ({
          value: option.value ? option.value : option.id,
          ...option,
          label: option.label ? t(option.label) : option.name,
        }))
      : [];

  return (
    <div className="flex flex-col gap-4">
      <Form
        form={form}
        name="filter-program"
        layout="vertical"
        size="large"
        className="materialStyle form filter-form"
        onValuesChange={handleOnChange}
      >
        <Form.Item name={FIELD_KEY.KEY_WORD} className="program-name" label={t('training.findMember')}>
          <Input allowClear prefix={<AiOutlineSearch size={20} color="#FEA628" />} />
        </Form.Item>  
        <Form.Item name={FIELD_KEY.COURSE_ID} label={t('training.programName')}>
          <Select 
              showSearch 
              allowClear 
              optionLabelProp="label"
              options={mapOptions(courses)} 
              suffixIcon={<ArrowDownIcon />} 
              filterOption={(inputValue, option) =>
                (removeVietnameseTones(option?.label?.toLowerCase()) ?? '').includes(
                  removeVietnameseTones(inputValue?.toLowerCase()),
                )
              }
          />
        </Form.Item>
        <Form.Item name={FIELD_KEY.START_MONTH} label={t('training.startMonth')}>
          <DatePicker
            allowClear
            suffixIcon={<CalendarIcon />}
            placeholder=""
            format={MONTH_FORMAT}
            picker="month"
            disabledDate={(day) => {
              const endMonth = form.getFieldValue(FIELD_KEY.END_MONTH);
              return endMonth && day && day.isSameOrAfter(endMonth);
            }}
          />
        </Form.Item>
        <Form.Item name={FIELD_KEY.END_MONTH} label={t('training.endMonth')}>
          <DatePicker
            allowClear
            suffixIcon={<CalendarIcon />}
            placeholder=""
            format={MONTH_FORMAT}
            picker="month"
            disabledDate={(day) => {
              const startMonth = form.getFieldValue(FIELD_KEY.START_MONTH);
              return startMonth && day && day.isSameOrBefore(startMonth);
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default FilterMember;
