import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import SideNavBar from 'components/SideNavBar';
import * as Constants from 'constants/ListEmployees';

const Employees = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory();
  const [value, setValue] = useState(Constants.EMPLOYEE_DASHBOARD);

  const routes = [
    {
      name: t('common.dashboard'),
    },
    {
      name: t('employees.listEmployee'),
    },
    {
      name: t('employees.listStandbyEmployee'),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let historyPath = '';
    switch (newValue) {
      case Constants.EMPLOYEE_DASHBOARD:
        historyPath = Constants.EMPLOYEE_DASHBOARD_PATH;
        break;
      case Constants.EMPLOYEE_LIST:
        historyPath = Constants.EMPLOYEE_LIST_PATH;
        break;
      case Constants.EMPLOYEE_STANDBY:
        historyPath = Constants.EMPLOYEE_STANDBY_PATH;
        break;
      default:
        break;    
    }
    history.push(historyPath);
  };

  useEffect(() => {
    switch (location.pathname) {
      case Constants.EMPLOYEE_DASHBOARD_PATH:
        setValue(Constants.EMPLOYEE_DASHBOARD);
        break;
      case Constants.EMPLOYEE_LIST_PATH:
        setValue(Constants.EMPLOYEE_LIST);
        break;
      case Constants.EMPLOYEE_STANDBY_PATH:
        setValue(Constants.EMPLOYEE_STANDBY);
        break;  

      default:
        break;
    }
  }, [location.pathname]);

  return <SideNavBar routes={routes} onChange={handleChange} value={value} hasMarginBottom />;
};

export default Employees;
