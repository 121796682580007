import React, { useEffect, useState } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { HiPencil } from 'react-icons/hi';
import { BiCalendar } from "react-icons/bi";
import { MdDelete } from 'react-icons/md';
import { Row, Col, Form, Label } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { IoIosAdd } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import '@fortawesome/fontawesome-free/css/all.min.css';

import './styles.scss';
import './styles_modal_division.scss';
import Avatar from 'assets/img/icons/avatar_default.svg';
import AvatarUpload from 'assets/img/icons/avatarUpload.png';
import AlertComponent from 'components/Alert';
import ButtonComponent from 'components/Buttons';
import InputField from 'components/Input/InputField';
import Loading from 'components/Loading';
import ModalComponent from 'components/Modal';
import CustomCheckboxYup from 'components/InputYupAndMui/CustomCheckboxYup';
import { apiDeleteRoleNameDivision, apiCreateDivision, apiUpdateDivision } from 'api/department';
import { useDetailDepartment } from 'hook/useDepartment';
import ModalManager from './ModalManager/index';
import ModalRolesMember from './ModalRolesMember';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { MAX_LENGTH_50, MAX_LENGTH_1000 } from 'constants/index.js';
import { MODEL_DIVISION } from 'constants/Department';
import { USE_DIAGRAM_DEPARTMENT, USE_DETAIL_DEPARTMENT } from 'constants/Department';
import { TYPE_NOT_BDO, TYPE_BDO, USE_LEADER_DEPARTMENT } from 'constants/Department';
import { isEmptyObject, getStrError } from 'helpers';
import { sendDate, dateResponse } from 'helpers/formatDate';
import { FORMAT_IMAGES } from 'constants/index';
import { handleOnError } from 'helpers';
import DatePicker from 'react-datepicker';

const DEFAULT_ERRORS_DATA_DIVISION = {
  error_name: '',
  error_file: '',
  error_founding: '',
  error_des: '',
  error_parent: '',
  error_manager: '',
};

const DEFAULT_FORM_DATA_DIVISION = {
  id: 0,
  file: null,
  avatar: null,
  name: '',
  founding_at: null,
  is_bdo: false,
  description: '',
  parent_id: 0,
  ...DEFAULT_ERRORS_DATA_DIVISION,
};

const CHECK_DEFAULT = {
  isDeleted: false,
  number: -1,
};

const ModalTeam = (props) => {
  const { t } = useTranslation();
  const { toggle, isShowModal, infoBranch, infoDivision, bodExist } = props;
  const queryClient = useQueryClient();

  const { data: dataDetailDivision, isLoading: isLoadingDivision } = useDetailDepartment(infoDivision?.division_id);

  const [formDivision, setFormDivision] = useState(DEFAULT_FORM_DATA_DIVISION);
  const [stateButton, setStateButton] = useState(false);
  const [editMember, setEditMember] = useState({});
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [loadDelete, setLoadDelete] = useState(CHECK_DEFAULT);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (dataDetailDivision) {
      let _leader = {};
      if (dataDetailDivision?.manager) {
        _leader = { ...dataDetailDivision.manager, id: dataDetailDivision.manager.user_id };
      }
      setFormDivision((prevState) => ({
        ...prevState,
        ...dataDetailDivision?.division,
        founding_at: dataDetailDivision?.division?.date ? dateResponse(dataDetailDivision.division.date) : null,
        leader: _leader,
        member: dataDetailDivision?.custom_role_division ? dataDetailDivision?.custom_role_division : [],
        is_bdo: dataDetailDivision?.division.level ? true : false,
        ...DEFAULT_ERRORS_DATA_DIVISION,
      }));
    } else {
      setFormDivision(DEFAULT_FORM_DATA_DIVISION);
      setEditMember({});
    }
    if (infoDivision?.id === 0) {
      setFormDivision(DEFAULT_FORM_DATA_DIVISION);
      setEditMember({});
    }
  }, [dataDetailDivision, infoDivision, isShowModal]);

  const handleInputDivision = (e) => {
    const error = {};
    if (e.target.name === 'major') {
      error.error_major = '';
    }

    setFormDivision({
      ...formDivision,
      [e.target.name]: e.target.value,
      ...error,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStateButton(true);

    if (!validateDivision()) {
      setStateButton(false);
      return;
    }

    const data = {
      name: formDivision.name.trim(),
      file: formDivision?.file,
      founding_at: formDivision.founding_at ? sendDate(formDivision.founding_at) : '',
      description: formDivision?.description ? formDivision.description.trim() : '',
      user_id: isEmptyObject(formDivision?.leader) ? '' : formDivision.leader?.id,
      parent_id: infoBranch?.branch_id,
      level: formDivision.is_bdo ? TYPE_BDO : TYPE_NOT_BDO,
    };
    if (!formDivision.description) delete data.description;
    if (!formDivision.founding_at) delete data.founding_at;
    if (!formDivision.file || formDivision.id) delete data.file;

    const fb = new FormData();
    Object.keys(data).forEach(function (key) {
      fb.append(key, data[key]);
    });

    let response = {};
    if (!formDivision.id) {
      response = await apiCreateDivision(fb);
    } else {
      response = await apiUpdateDivision(formDivision.id, data);
    }

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setFormDivision({
        ...formDivision,
        error_name: getStrError(msg?.name),
        error_founding: getStrError(msg?.founding_at),
        error_des: getStrError(msg?.description),
        error_file: getStrError(msg?.file),
      });
      setStateButton(false);
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      toggle();
      setStateButton(false);
      setFormDivision({
        ...formDivision,
        ...DEFAULT_FORM_DATA_DIVISION,
      });
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_DETAIL_DEPARTMENT);
      queryClient.invalidateQueries(USE_DIAGRAM_DEPARTMENT);
      queryClient.invalidateQueries(USE_LEADER_DEPARTMENT);
    }
  };

  function validateDivision() {
    var errors = {};
    if (!formDivision.name) {
      errors.error_name = t('common.pleaseFillOutThisField');
    }
    if (formDivision.name?.length > MAX_LENGTH_50) {
      errors.error_name = t('common.pleaseEnterLessThanFiftyCharacters');
    }

    if (!isEmptyObject(errors)) {
      setFormDivision({
        ...formDivision,
        ...errors,
      });
      return false;
    }
    return true;
  }

  //====== modal manger and roles =====
  const [isModalManager, setShowModalManager] = useState(false);
  const [isModalRolesMember, setModalRolesMember] = useState(false);

  const handleShowManager = () => {
    setShowModalManager(!isModalManager);
  };
  const handelChangeManage = (manage) => {
    setFormDivision({ ...formDivision, leader: manage });
  };

  const handleShowRolesMember = () => {
    if (infoDivision?.id === 0) {
      setMessage(t('messages.department.messageNotChooseRoleDivision'));
      setShowAlert(true);
    } else {
      setEditMember({});
      setModalRolesMember(!isModalManager);
    }
  };

  const handleEditMember = (member) => {
    let _member = { ...member, id: member.user_id, role_name: member.role_name_division };
    setEditMember(_member);
    setModalRolesMember(!isModalManager);
  };

  const onHandelMember = (member) => {
    let _members = [...formDivision.member];
    if (isEmptyObject(editMember)) {
      _members.push({
        ...member.member,
        role_name_division: member.role,
      });
    } else {
      formDivision.member.forEach((item, key) => {
        if (item.user_id === editMember.user_id) {
          _members[key] = { ...member.member };
          _members[key].role_name_division = member.role;
        }
      });
    }

    setFormDivision({ ...formDivision, member: _members });
  };

  // ============= deleted role ==============
  const handleDeleteRole = (event, member, key) => {
    alertConfirmDeleteRoleName(member, key);
  };

  const { mutate: handelDeleteRolesName } = useMutation(
    async (id) => {
      const result = await apiDeleteRoleNameDivision(id);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(USE_DIAGRAM_DEPARTMENT);
        queryClient.invalidateQueries(USE_DETAIL_DEPARTMENT);
        setLoadDelete(CHECK_DEFAULT);
      },
      onError: () => {
        setLoadDelete(CHECK_DEFAULT);
      },
    },
  );

  const alertConfirmDeleteRoleName = async (memberEdit, key) => {
    confirmAlert({
      title: t('divisionDiagram.divisionPopup.confirmDel'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.done'),
          className: 'btn-alert-ok',
          onClick: async () => {
            if (formDivision?.id) {
              setLoadDelete({
                isDeleted: true,
                number: key,
              });
              handelDeleteRolesName(memberEdit.user_id);
            } else {
              let roleMember = formDivision.member.filter((item) => {
                return memberEdit.user_id !== item?.user_id;
              });
              setFormDivision({ ...formDivision, member: roleMember });
            }
          },
        },
      ],
    });
  };

  const onUploadImg = async (event) => {
    if (event && event.target.files && event.target.files[0]) {
      const data = event.target.files[0];

      const extensionFile = data.name.split('.').pop();
      const sizeFile = data.size;
      let _error = '';

      if (FORMAT_IMAGES.indexOf(extensionFile) < 0) {
        _error = t('messages.pleaseUploadImagesInTheCorrectFormat');
      } else if (sizeFile >= 1024 * 1024 * 10) {
        _error = t('messages.pleaseUploadImagesUnder10mb');
      }

      if (_error) {
        setFormDivision({ ...formDivision, error_file: _error });
        return;
      }

      setFormDivision({ ...formDivision, file: data, avatar: URL.createObjectURL(data), error_file: '' });
    }
  };

  const { control, register } = useForm({
    mode: 'onBlur',
  });

  const handelClickBod = (event) => {
    setFormDivision({ ...formDivision, is_bdo: event.target.checked });

    if (bodExist?.is_bod && formDivision.id !== bodExist.division_id && event.target.checked) {
      confirmAlert({
        title: t('divisionDiagram.divisionPopup.doYouWantToChangeThisSoThatTheDepartmentTheBOD?'),
        buttons: [
          {
            label: t('common.cancel'),
            className: 'btn-alert-cancel',
            onClick: () => {
              setFormDivision({ ...formDivision, is_bdo: false });
            },
          },
          {
            label: t('common.done'),
            className: 'btn-alert-ok',
          },
        ],
      });
    } else {
    }
  };

  return (
    <>
      <ModalComponent
        toggle={toggle}
        isShowModal={isShowModal}
        classNameAdd="modalAddDivision"
        title={
          formDivision?.id
            ? t('divisionDiagram.divisionPopup.editDepartment')
            : t('divisionDiagram.divisionPopup.addDepartment')
        }
      >
        {isLoadingDivision ? (
          <Loading />
        ) : (
          <Form className="mt-2" onSubmit={handleSubmit}>
            {formDivision.id ? (
              ''
            ) : (
              <Row>
                <Col md="12">
                  <h6 className="titleField">{t('divisionDiagram.divisionPopup.avatar')}</h6>
                </Col>
                <Col md="12" className="avatarUpload">
                  <input
                    id="uploadAvatarDivision"
                    type="file"
                    name="images[]"
                    className="input-upload hidden"
                    accept=".jpg,.jpeg,.png,.gif,.svg"
                    onChange={(event) => onUploadImg(event)}
                  />
                  <label htmlFor="uploadAvatarDivision">
                    <img
                      onError={handleOnError}
                      className="label-upload"
                      src={formDivision.avatar ? formDivision.avatar : AvatarUpload}
                      alt="avatar"
                    />
                  </label>
                </Col>
                <Col md="12">
                  <div className="error error-input text-center">{formDivision?.error_file}</div>
                </Col>
              </Row>
            )}
            <div className=" mt-3">
              <InputField
                label={t('divisionDiagram.divisionPopup.nameDepartment')}
                isRequired={true}
                variant="outlined"
                name="name"
                maxLength={MAX_LENGTH_50}
                defaultValue={formDivision.name}
                onHandleInput={handleInputDivision}
                error={formDivision.error_name}
              />
            </div>

            <div className="mt-3">
              <div className={'form-field'}>
                <DatePicker
                  id="founding-at"
                  dateFormat="dd/MM/yyyy"
                  className={`form-input`}
                  selected={
                    formDivision?.founding_at ? new Date(moment(formDivision?.founding_at, 'DD/MM/YYYY')) : null
                  }
                  onChange={(date: Date) => {
                    setFormDivision({ ...formDivision, founding_at: date, error_founding: '' });
                  }}
                  onFocus={() => {
                    !formDivision?.founding_at && setFocus(true);
                  }}
                  onBlur={() => {
                    focus && setFocus(false);
                  }}
                />
                <label for="name" className={`${(formDivision?.founding_at || focus) && 'input-focus-custom'} form-label`}>
                  {t('divisionDiagram.divisionDetail.foundation')}
                </label>
                <BiCalendar onClick={() => { $('#founding-at').focus(); }} />
              </div>
            </div>
            <div className=" mt-3">
              <InputField
                label={t('divisionDiagram.divisionDetail.branch')}
                defaultValue={infoBranch?.branch_name}
                name="branch"
                variant="outlined"
                disabled={true}
              />
            </div>
            <div className=" mt-3">
              <InputField
                addClass="textArea"
                label={t('divisionDiagram.description')}
                variant="outlined"
                name="description"
                maxLength={MAX_LENGTH_1000}
                defaultValue={formDivision.description}
                onHandleInput={handleInputDivision}
                error={formDivision.error_des}
                rows={2}
              />
            </div>
            <div className=" mt-3">
              <CustomCheckboxYup
                name="is_bdo"
                defaultValue={formDivision?.is_bdo}
                control={control}
                register={register}
                onClick={handelClickBod}
                label={t('divisionDiagram.divisionPopup.isItTheBoardBOD?')}
              />
            </div>
            <div className="mb-2 mt-3">
              <Label className="text-bold color-navy">{t('divisionDiagram.managerTeam')}</Label>
            </div>
            {!isEmptyObject(formDivision.leader) ? (
              <div className="manager-block-team-division">
                <div className="block-noicons">
                  <div className="add-manager">
                    <img
                      onError={handleOnError}
                      alt="avatar"
                      width="100%"
                      height="100%"
                      src={formDivision.leader?.avatar ? formDivision.leader.avatar : Avatar}
                    />
                  </div>
                  <div className="contentEdit pl-2">
                    <div>
                      <h6 className="textSubField">{formDivision.leader?.user_name || formDivision.leader?.name}</h6>
                    </div>
                    <div>
                      <span>{formDivision.leader?.email}</span>
                      <span> - </span>
                      <span>{t('divisionDiagram.divisionPopup.leaderTeam')}</span>
                    </div>
                  </div>
                </div>
                <div className="block-icons">
                  <div className="each-icon">
                    <HiPencil onClick={handleShowManager} />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-start ">
                  <div className="editDeleteButton">
                    <IoIosAdd onClick={handleShowManager} />
                  </div>
                  <div className="textButton">
                    <Label>{t('divisionDiagram.addLeader')}</Label>
                  </div>
                </div>
                <p className="error error-input">{formDivision.error_manager}</p>
              </>
            )}

            <div className="line-team-division-department mt-2 mb-2"></div>
            {formDivision.member?.map((item, key) => (
              <React.Fragment key={key}>
                <div className="manager-block-team-division">
                  <div className="block-noicons">
                    <div className="add-manager">
                      <img
                        onError={handleOnError}
                        alt="avatar"
                        width="100%"
                        height="100%"
                        src={item?.avatar ? item.avatar : Avatar}
                      />
                    </div>
                    <div className="contentEdit pl-2">
                      <div>
                        <h6 className="textSubField">{item?.name}</h6>
                      </div>
                      <div>
                        <span>{item?.email}</span>
                        <span> - </span>
                        <span>{item?.role_name_division}</span>
                      </div>
                    </div>
                  </div>
                  <div className="block-icons">
                    <div className="each-icon">
                      {loadDelete.isDeleted && loadDelete.number === key ? (
                        <i className={'fa fa-spinner fa-pulse fa-3x fa-fw icon-spinner-modal-team'} />
                      ) : (
                        <MdDelete onClick={(event) => handleDeleteRole(event, item, key)} />
                      )}
                    </div>
                    <div className="each-icon ml-3">
                      <HiPencil onClick={() => handleEditMember(item)} />
                    </div>
                  </div>
                </div>
                <div className="line-team-division-department mt-2 mb-2"></div>
              </React.Fragment>
            ))}
            <div className="d-flex justify-content-start">
              <div className="editDeleteButton">
                <IoIosAdd onClick={handleShowRolesMember} />
              </div>
              <div className="textButton">
                <Label>{t('divisionDiagram.addRole')}</Label>
              </div>
            </div>
            <Row className="mt-5">
              <Col className="md-12">
                <ButtonComponent
                  text={
                    formDivision?.id
                      ? t('divisionDiagram.divisionPopup.fulfill')
                      : t('divisionDiagram.divisionPopup.addButton')
                  }
                  addClass="w-100"
                  type="submit"
                  hasDisabled={stateButton}
                  isLoading={stateButton}
                />
              </Col>
            </Row>
          </Form>
        )}
      </ModalComponent>

      <ModalManager
        idDivision={infoDivision?.division_id}
        idTeam={formDivision.id}
        leader={formDivision.leader}
        listMemberRole={formDivision?.member}
        isShowModalManager={isModalManager}
        handleToggleManager={() => setShowModalManager(!isModalManager)}
        isAddNew={formDivision?.id ? false : true}
        onHandelManage={(manage) => handelChangeManage(manage)}
        type={MODEL_DIVISION}
      />

      <ModalRolesMember
        idDivision={infoDivision?.division_id}
        idTeam={formDivision.id}
        isModalRolesMember={isModalRolesMember}
        leader={formDivision.leader}
        listMemberRole={formDivision?.member}
        member={editMember}
        isAddNew={formDivision?.id ? false : true}
        handleToggleManager={() => setModalRolesMember(!isModalRolesMember)}
        onHandelMember={(member) => onHandelMember(member)}
        type={MODEL_DIVISION}
      />
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};

export default ModalTeam;
