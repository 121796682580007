import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from '@mui/material';

import { COLOR_CHECKBOX_NOT_ACTIVE } from 'constants/ColorVariable';
import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import { NOT_HAVE_ACCESS, VIEW, EDIT, APPROVAL } from 'constants/MasterData'
import styles from './styles.module.scss';

export default function CustomizedTooltips(props) {
  const { Controller, control, name, label, defaultValue } = props;
  const { t } = useTranslation();
  return (
    <FormControl component="fieldset" className={styles.fieldsetCustom}>
      <FormLabel className={styles.labelView}>
        {label}
      </FormLabel>
      <Controller
        render={({ field }) => (
          <RadioGroup className={styles.radioStyleCustom} {...field}>
            <FormControlLabel
              value={NOT_HAVE_ACCESS}
              control={
                <Radio
                  sx={{
                    color: COLOR_CHECKBOX_NOT_ACTIVE,
                    '&.Mui-checked': {
                      color: COLOR_CHECKBOX_CUSTOM,
                    },
                  }}
                />
              }
              className={styles.labelRadio}
              label={t('masterData.roleMasterData.notHaveAccess')}
            />
            <FormControlLabel
              value={VIEW}
              control={
                <Radio
                  sx={{
                    color: COLOR_CHECKBOX_NOT_ACTIVE,
                    '&.Mui-checked': {
                      color: COLOR_CHECKBOX_CUSTOM,
                    },
                  }}
                />
              }
              className={styles.labelRadio}
              label={t('masterData.roleMasterData.view')}
            />
            {name !== 'infor_employee' && <FormControlLabel
              value={EDIT}
              control={
                <Radio
                  sx={{
                    color: COLOR_CHECKBOX_NOT_ACTIVE,
                    '&.Mui-checked': {
                      color: COLOR_CHECKBOX_CUSTOM,
                    },
                  }}
                />
              }
              className={styles.labelRadio}
              label={t('masterData.roleMasterData.edit')}
            />}

            {name === 'assets' && <FormControlLabel
              value={APPROVAL}
              control={
                <Radio
                  sx={{
                    color: COLOR_CHECKBOX_NOT_ACTIVE,
                    '&.Mui-checked': {
                      color: COLOR_CHECKBOX_CUSTOM,
                    },
                  }}
                />
              }
              className={styles.labelRadio}
              label={t('masterData.roleMasterData.approval')}
            />}

          </RadioGroup>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue ? defaultValue : VIEW}
      />
    </FormControl>
  );
}
