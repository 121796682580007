export const USE_STATISTICAL_CATEGORY_CONTRACT = 'statistical_category_contract';
export const USE_STATISTICAL_CATEGORY_EMPLOYEE = 'statistical_category_employee';
export const USE_STATISTICAL_STATUS_CONTRACT = 'statistical_status_contract';
export const ZERO_STATUS_ACCEPT = 0;
export const ONE_STATUS_ACCEPT = 1;
export const TWO_STATUS_ACCEPT = 2;
export const THREE_STATUS_ACCEPT = 0;
export const USE_LIST_RETIRE_CONTRACT = 'list_retire_contract';
export const USE_LIST_DIVISION_CONTRACT = 'list_division_contract';
export const USE_REQUEST_RETIRE = 'request_retire';

export const USE_LIST_MEMBER_CONTRACT = 'list_member_contract';

export const USE_LIST_CONTRACT = 'list_contract';
export const USE_DETAIL_CONTRACT = 'detail_contract';
export const USE_CONTRACT_HISTORY = 'contract_history';
export const USE_LIST_CATEGORY_EMPLOYEE_CONTRACT = 'list_category_employee';
export const USE_LIST_WAIT_CONTRACT = 'list_wait_contract';
export const USE_GENERATE_CODE_CONTRACT = 'generate_code_contract';
export const USE_LIST_CONTRACT_DIVISION = 'list_contract_division';
export const USE_LIST_CONTRACT_DIVISION_PENDING = 'list_contract_division_pending';
export const USE_CHECK_CONTRACT_ONBOARD_USER = 'USE_CHECK_CONTRACT_ONBOARD_USER';

export const TAB_LIST_CONTRACT = 'tab_list_contract';
export const TAB_LIST_CONTRACT_WAITING = 'tab_list_contract_waiting';
export const TAB_DETAIL_CONTRACT = 'tab_detail_contract';
export const USE_GET_TOTAL_EMPLOYEES = 'USE_GET_TOTAL_EMPLOYEES';
export const USE_REQUEST_OT = 'USE_REQUEST_OT';

export const TYPE_CONTRACT_WAITING = 4;

export const CURRENT_CONTRACT = 1;
export const LIMIT_CONTRACT = 2;
export const OFF_CONTRACT = 3;

export const DASHBOARD = 0;
export const CONTRACT_WORK = 1;
export const RETIRE_DECISION = 2;
export const CONTRACT_WAITING = 3;

export const STATUS_RETIRE_PROCESSING = 1;
export const STATUS_RETIRE_HANDLED = 2;
export const STATUS_RETIRE_HANDLED_SPECIAL = 3;

export const START_STRING_SLICE = 0;
export const MAX_LENGTH_CONTRACT = 15;

export const ALL_STATUS_RETIRE = 0;
export const NOT_DATA = 0;

export const INDEFINITE_TERM_CONTRACT = 2;
