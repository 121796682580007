import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Collapse, NavItem, Nav } from 'reactstrap';

import styles from './style.module.scss';

const ItemSidebar = (props) => {
  const { routes } = props;

  return (
    <>
      {routes?.title && (
        <div className={styles.groupTitle}>
          <img className={styles.icon} src={routes.title.icon} alt={routes.title.icon} />
          <h4 className={styles.titleSideBar}>{routes.title.name}</h4>
        </div>
      )}
      {routes.nav && (
        <Nav className="mb-md-2" navbar>
          {routes.nav.map((route, key) => {
            if (route.link) {
              let isActive = false;
              if (route.path === window.location.pathname) {
                isActive = true;
              } else {
                route.link.forEach(item => {
                  if (item === window.location.pathname) {
                    isActive = true;
                  }
                });
              }
              return (
                <NavItem key={key}>
                  <NavLink to={route.path} className={`${styles.link} ${isActive && styles.isActive}`}>
                    <span className={styles.text}>{route.name}</span>
                  </NavLink>
                </NavItem>
              );
            } else {
              return (
                <NavItem key={key}>
                  <NavLink to={route.path} className={`${styles.link}`} activeClassName={styles.isActive}>
                    <span className={styles.text}>{route.name}</span>
                  </NavLink>
                </NavItem>
              );
            }
          })}
        </Nav>
      )}
    </>
  );
};

const SideBar = (props) => {
  const { dataRoutes } = props;

  return (
    <div className={classNames(`${styles.sideBar} border-show`)}>
      <div className={classNames(`card ${styles.ctCard}`)}>
        <div className="card-body">
          <Collapse navbar isOpen={true}>
            {dataRoutes && dataRoutes.map((routes, keyData) => <ItemSidebar routes={routes} key={keyData} />)}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
