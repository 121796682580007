import React from 'react'
import { useTranslation } from 'react-i18next'
import ModalComponent from 'components/Modal'
import styles from './styles.module.scss'

const BasicSalaryChangeHistory = ({ title, open, toggle, data }) => {
  const { t } = useTranslation()
  const timeLineSalaries = data ? JSON.parse(JSON.stringify(data)).reverse() : []

  return (
    <ModalComponent title={title} isShowModal={open} toggle={toggle}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.textHeader}>{t('policy.basicSalaryPopup.period')}</span>
          <span className={styles.textHeader}>{t('policy.basicSalaryPopup.salary')}</span>
        </div>
        {timeLineSalaries.map((item, index) => (
          <div className={timeLineSalaries.length > 1 ? styles.item : styles.onlyItem} key={index}>
            <div className={styles.content}>
              <span className={styles.textLeft}>{t('policy.basicSalaryPopup.from')} {item.start_date}</span>
              <span className={styles.textRight}>{new Intl.NumberFormat('de-DE').format(item.insurance_salary)}</span>
            </div>
          </div>
          )
        )}
      </div>
    </ModalComponent>
  )
}

export default BasicSalaryChangeHistory
