import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { TextFieldDate as TextFieldDateDay, TextFieldNoneDate as TextFieldNoneDateDay } from '../DateTime/style.js';
import { CssDateField } from 'components/InputYupAndMui/constant.js';
import { customDate } from 'helpers/formatDate.js';

const DateDayCustomInput = (props) => {
  const {
    label,
    defaultValue,
    onChangeHandle,
    name,
    error,
    isRequired,
    placeholder,
    sizeDate,
    setValue,
    control,
    register,
    isShowModal,
    minDate,
    maxDate,
    disabled,
    isReadOnly,
    setError,
    addErrors,
  } = props;
  const [valueDate, setValueDate] = useState(defaultValue);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (defaultValue == null) {
      setValue(name, null);
      setValueDate(null);
      return;
    }
    if (valueDate) {
      setValue(name, customDate(valueDate, 'DD/MM/YYYY'));
      setValueDate(valueDate);
    } else {
      if (isShowModal !== undefined && !isShowModal) {
        setValue(name, null);
        setValueDate(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, setValue, isShowModal, defaultValue]);

  const handleChangeDate = (newValue) => {
    setError && setError(name, '');
    setValue(name, customDate(newValue, 'DD/MM/YYYY'));
    setValueDate(newValue);
    if (onChangeHandle) {
      onChangeHandle(newValue);
    }
  };

  // Watch value of input date
  const valueWatch = useWatch({
    control,
    name: name,
  });

  return (
    <>
      <CssDateField labelspace={valueWatch ? 0 : '-1px'}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            render={({ field }) => (
              <DesktopDatePicker
                fullWidth
                name={name}
                open={open}
                inputFormat="dd/MM/yyyy"
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={valueDate}
                label={
                  <React.Fragment>
                    {placeholder && !valueDate ? placeholder : label}
                    {isRequired && label ? <sup>*</sup> : ''}
                  </React.Fragment>
                }
                onChange={handleChangeDate}
                inputProps={{
                  style: sizeDate ? { fontSize: '14px' } : { padding: '14px 15px 14px 15px', fontSize: '14px' },
                  readOnly: isReadOnly ? true : false,
                  disabled: isReadOnly ? true : false,
                }}
                renderInput={(params) => {
                  if (label) {
                    return (
                      <TextFieldDateDay {...params} {...register(name)} error={error || addErrors ? true : false} />
                    );
                  }
                  return (
                    <TextFieldNoneDateDay {...params} {...register(name)} error={error || addErrors ? true : false} />
                  );
                }}
                minDate={minDate ? new Date(`${minDate}`) : null}
                maxDate={maxDate ? new Date(`${maxDate}`) : null}
                disabled={disabled}
              />
            )}
            control={control}
            name={name}
          />
        </LocalizationProvider>
      </CssDateField>
      <p className="error-input error">{error}</p>
      {addErrors && <p className="error-input error">{addErrors}</p>}
    </>
  );
};

export default DateDayCustomInput;
