import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  Row,
  Col,
  TabContent,
  NavLink,
  Nav,
  Button,
  NavItem,
  TabPane,
  Container,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';
import { HiPencil } from 'react-icons/hi';
import { BiLinkExternal } from 'react-icons/bi';
import { confirmAlert } from 'react-confirm-alert';
import { NavLink as RouteLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import '@fortawesome/fontawesome-free/css/all.min.css';

import DateMonth from 'components/DateTime/DateMonth';
import Grid from '@mui/material/Grid';
import Loading from 'components/Loading';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { handleExportExcel } from 'helpers';
import { customDate } from 'helpers/formatDate';
import AlertComponent from 'components/Alert';
import ModalDivision from './../ModalDivision';
import ModalTeam from './../ModalTeam';
import defaultAvatar from 'assets/img/icons/avatar_default.svg';
import './styles.scss';
import { apiUploadAvatarDivision } from 'api/department';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import { FORMAT_IMAGES, DEFAULT_PAGE_MUI, PER_PAGE_OPTION, DEFAULT_LIMIT, NOT_DATA } from 'constants/index';
import { toasts } from '../../../../index';
import {
  USE_DETAIL_DEPARTMENT,
  STATUS_OPEN_PROJECT,
  STATUS_CLOSE_PROJECT,
  STATUS_PROCESS_PROJECT,
  STATUS_CANCEL_PROJECT,
  STATUS_POSPONE_PROJECT,
  STATUS_OPP_PROJECT,
  STATUS_DAFT_PROJECT,
  STATUS_PENDING_PROJECT,
  TYPE_DEVELOPMENT_PROJECT,
  TYPE_MAINTENANCE_PROJECT,
} from 'constants/Department';

import {
  useDetailDepartment,
  useListProjectsDivision,
  useOtherMember,
  useDiagramDepartment,
  useDetailTeam,
} from './../../../../hook/useDepartment';
import CommingSoon from 'components/CommingSoon';
import moment from 'moment';
import { ZERO } from 'constants/MasterData';
import { handleOnError } from 'helpers';

const DepartmentDiagram = (props) => {
  const { t } = useTranslation();
  let param = useParams();
  const queryClient = useQueryClient();
  const departmentId = param.departmentId;
  const [stateTab, setStateTab] = useState('1');
  const [isShow, setIsShow] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGE_MUI);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_LIMIT);
  const [foundingAt, setFoundingAt] = useState(moment().format('MM-DD-YYYY'));
  const [projectsDivision, setProjectsDivision] = useState([]);
  const [messageError, setMessageError] = useState('');

  const {
    projects: listProjects,
    isLoading: isLoadingListProject,
    total: totalProjects,
  } = useListProjectsDivision({
    departmentId: departmentId,
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    month: foundingAt,
    apiForACMS: true,
  });
  const { data: uOtherMember } = useOtherMember({ departmentId });

  useEffect(() => {
    setProjectsDivision(listProjects);
  }, [listProjects]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const toggle = (tab) => {
    if (stateTab !== tab) {
      if (Number(tab) === 2) {
        setIsShow(true);
      } else {
        setStateTab(tab);
      }
    }
  };

  const handleInputTimeCreatedProject = (value) => {
    setRowsPerPage(DEFAULT_LIMIT);
    setPage(DEFAULT_PAGE_MUI);
    setFoundingAt(moment(value).format('MM-DD-YYYY'));
  };

  const { data: uDataDiagram, isLoading: isLoadingDataDiagram } = useDiagramDepartment();
  const { data: uData, isLoading: isLoadingData } = useDetailDepartment(departmentId);

  const [dataOriginal, setDataOriginal] = useState([]);
  const [infoBranch, setInfoBranch] = useState({});
  const [infoDivision, setInfoDivision] = useState({});

  const [isShowModalTeam, setShowModalTeam] = useState(false);
  const [isShowModalDivision, setShowModalDivision] = useState(false);
  const [bodExist, setBodExist] = useState({
    is_bod: false,
    division_id: 0,
  });

  const [filterTeam, setFilterTeam] = useState({ team_id: '' });
  const { data: detailTeam, isLoading: isLoadingDetailTeam } = useDetailTeam(filterTeam);
  const [infoTeam, setInForTeam] = useState({});

  useEffect(() => {
    if (uDataDiagram && uDataDiagram?.data.length && uDataDiagram.data[0]?.divisions.length) {
      let _checkBOD = {};
      uDataDiagram.data[0].divisions.forEach((item) => {
        if (item.level) {
          _checkBOD = {
            is_bod: true,
            division_id: item.division_id,
          };
          return;
        }
      });
      setBodExist({ ..._checkBOD });
    }
  }, [uDataDiagram]);

  useEffect(() => {
    setDataOriginal(uData);
    if (uData?.division) {
      setInfoBranch((prevState) => ({
        ...prevState,
        branch_id: uData.division.parent_id,
        branch_name: uData.division.parent_name,
      }));

      setInfoDivision((prevState) => ({
        ...prevState,
        id: uData.division.id,
        division_id: uData.division.id,
        division_name: uData.division.name,
      }));
    }
  }, [uData]);

  const alertUploadDone = (message) => {
    confirmAlert({
      title: message,
      buttons: [
        {
          label: t('common.done'),
          className: 'btn-alert-ok',
        },
      ],
    });
  };

  const [isLoadUpload, setIsLoadUpdate] = useState(false);
  const { mutate: handleSubmitUploadAvatar } = useMutation(
    async (fb) => {
      const result = await apiUploadAvatarDivision(departmentId, fb);
      return result;
    },
    {
      onSuccess: (response) => {
        if (response?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
          alertUploadDone(response?.data?.messages);
        }
        if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
          alertUploadDone(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
        }
        if (response && response?.status === HTTP_OK) {
          alertUploadDone(response?.data?.messages);
          queryClient.invalidateQueries(USE_DETAIL_DEPARTMENT);
        }
        setIsLoadUpdate(false);
      },
      onError: () => {
        setIsLoadUpdate(false);
      },
    },
  );

  const handleUploadAvatar = async (event) => {
    if (event && event.target.files && event.target.files[0]) {
      setIsLoadUpdate(true);
      const data = event.target.files[0];

      const extensionFile = data.name.split('.').pop();
      const sizeFile = data.size;

      if (!FORMAT_IMAGES.indexOf(extensionFile)) {
        setMessageError(t('messages.pleaseUploadImagesInTheCorrectFormat'));
        setIsLoadUpdate(false);
        return;
      }

      if (sizeFile >= 1024 * 1024 * 10) {
        setMessageError(t('messages.pleaseUploadImagesUnder10mb'));
        setIsLoadUpdate(false);
        return;
      }
      const fb = new FormData();
      fb.append('file', data);
      fb.append('file_deleted', JSON.stringify(`${dataOriginal?.division?.avatar}`));

      handleSubmitUploadAvatar(fb);
    }
  };

  const changeTextStatus = (status) => {
    switch (status) {
      case STATUS_OPEN_PROJECT:
        return (
          <TableCell className="color-open" align="left">
            {t('divisionDiagram.divisionDetail.open')}
          </TableCell>
        );
      case STATUS_CLOSE_PROJECT:
        return (
          <TableCell className="color-close" align="left">
            {t('divisionDiagram.divisionDetail.closed')}
          </TableCell>
        );
      case STATUS_PROCESS_PROJECT:
        return (
          <TableCell className="color-inprocess" align="left">
            {t('divisionDiagram.divisionDetail.inprocess')}
          </TableCell>
        );
      case STATUS_CANCEL_PROJECT:
        return (
          <TableCell className="color-cancel" align="left">
            {t('divisionDiagram.divisionDetail.cancelled')}
          </TableCell>
        );
      case STATUS_POSPONE_PROJECT:
        return (
          <TableCell className="color-postpone" align="left">
            {t('divisionDiagram.divisionDetail.postponed')}
          </TableCell>
        );
      case STATUS_OPP_PROJECT:
        return <TableCell align="left">{t('divisionDiagram.divisionDetail.opportunity')}</TableCell>;
      case STATUS_DAFT_PROJECT:
        return <TableCell align="left">{t('divisionDiagram.divisionDetail.daft')}</TableCell>;
      case STATUS_PENDING_PROJECT:
        return <TableCell align="left">{t('divisionDiagram.divisionDetail.pending')}</TableCell>;
      default:
        return;
    }
  };

  const changeTextType = (type) => {
    switch (type) {
      case TYPE_DEVELOPMENT_PROJECT:
        return t('divisionDiagram.divisionDetail.development');
      case TYPE_MAINTENANCE_PROJECT:
        return t('divisionDiagram.divisionDetail.maintenance');
      default:
        break;
    }
  };

  const handleEditDivision = () => {
    setShowModalDivision(true);
  };

  const createDataModalTeam = (detailTeam, team) => {
    if ((detailTeam && !team) || (detailTeam && team && team.id === detailTeam.id)) {
      setInForTeam(detailTeam);
    }
  };

  useEffect(() => {
    createDataModalTeam(detailTeam);
  }, [detailTeam]);

  const handelEditTeam = (team) => {
    setFilterTeam({ ...filterTeam, team_id: team.id });
    createDataModalTeam(detailTeam, team);
    setShowModalTeam(true);
  };

  return (
    <Container fluid className="deparment-detail">
      <CommingSoon
        isShowCustom={isShow}
        handleCloseCustom={() => {
          setIsShow(false);
        }}
        isGoBack={true}
      />
      {isLoadingData ? (
        <Loading />
      ) : (
        <>
          <Breadcrumb className="detail-breadcrumb">
            <BreadcrumbItem>
              <RouteLink className="previous-name" to={{ pathname: '/admin/departmenthandle' }}>
                {t('divisionDiagram.name')}
              </RouteLink>
            </BreadcrumbItem>
            <BreadcrumbItem className="active-name" active>
              {dataOriginal?.division?.name}
            </BreadcrumbItem>
          </Breadcrumb>
          {stateTab === '2' && (
            <Breadcrumb className="export-project">
              <Button
                className="ctButton iconButtonExport"
                onClick={() => {
                  if (!(projectsDivision.length > ZERO)) {
                    toasts.error(t('common.noDataToExport'));
                  } else {
                    handleExportExcel(
                      '/api/division/project/export/excel',
                      {
                        departmentId: departmentId,
                        month: moment(foundingAt).format('DD/MM/YYYY'),
                      },
                      t('divisionDiagram.divisionDetail.nameExportProjects'),
                    );
                  }
                }}
              >
                <BiLinkExternal />
                {t('common.export')}
              </Button>
            </Breadcrumb>
          )}
          <Row>
            <Col md="3">
              <div className="information">
                <div className="header">
                  <div className="bg-img"></div>
                  <div className="avatar">
                    <img
                      onError={handleOnError}
                      alt="just cool man"
                      src={dataOriginal?.division?.avatar ? dataOriginal?.division?.avatar : defaultAvatar}
                    />
                    <div className="edit-zone">
                      {isLoadUpload ? (
                        <div className="edit-button">
                          <i className={'fa fa-spinner fa-pulse fa-3x fa-fw fs-16'} />
                        </div>
                      ) : (
                        <>
                          <input
                            id="uploadAvatar"
                            type="file"
                            name="images[]"
                            accept=".jpg,.jpeg,.png,.jfif"
                            onChange={(event) => handleUploadAvatar(event)}
                            hidden
                          />
                          <label htmlFor="uploadAvatar">
                            <div className="edit-button">
                              <HiPencil onClick={handleUploadAvatar} />
                            </div>
                          </label>
                        </>
                      )}
                      <div className="bg-button"></div>
                    </div>
                  </div>
                  <p className="department-name">{dataOriginal?.division?.name}</p>
                  <div className={classnames(`update-icon position-icon-infor`)}>
                    <HiPencil onClick={handleEditDivision} />
                  </div>
                </div>
                <div className="description">
                  <ul>
                    <li>
                      <p className="head-title">{t('divisionDiagram.divisionDetail.headTitle')}</p>
                      {dataOriginal?.manager != null && (
                        <RouteLink to={{ pathname: '/admin/employees/profile/' + dataOriginal?.manager?.user_id }}>
                          {dataOriginal?.manager?.name}
                        </RouteLink>
                      )}
                      {dataOriginal?.manager == null && t('divisionDiagram.divisionDetail.notUpdated')}
                    </li>
                    {dataOriginal?.custom_role_division?.length !== 0 &&
                      dataOriginal?.custom_role_division?.map((item, key) => {
                        return (
                          <li index={key} className="border-top">
                            <p className="head-title">{item.role_name_division}</p>
                            <RouteLink
                              to={{
                                pathname: '/admin/employees/profile/' + item?.user_id,
                              }}
                            >
                              {item.name}
                            </RouteLink>
                          </li>
                        );
                      })}
                    <li className="border-top">
                      <p className="head-title">{t('divisionDiagram.divisionDetail.foundation')}</p>
                      <p>{dataOriginal?.division?.date}</p>
                    </li>
                    <li className="border-top">
                      <p className="head-title">{t('divisionDiagram.divisionDetail.branch')}</p>
                      <p>{dataOriginal?.division?.parent_name}</p>
                    </li>
                    <li className="border-top">
                      <p className="head-title">{t('divisionDiagram.divisionDetail.membersAmount')}</p>
                      <p>{dataOriginal?.division?.total_member}</p>
                    </li>
                    <li className="border-top">
                      <p className="head-title">{t('divisionDiagram.divisionDetail.address')}</p>
                      <p>{dataOriginal?.division?.address_branch}</p>
                    </li>
                    <li className="border-top">
                      <p className="head-title ">{t('divisionDiagram.divisionDetail.description')}</p>
                      <p>{dataOriginal?.division?.description}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md="9">
              <div className="members-projects">
                <Nav tabs className="certificate">
                  <NavItem>
                    <NavLink className={classnames({ active: stateTab === '1' })} onClick={() => toggle('1')}>
                      {t('divisionDiagram.divisionDetail.listMembers')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: stateTab === '2' })} onClick={() => toggle('2')}>
                      {t('divisionDiagram.divisionDetail.listProject')}
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={stateTab}>
                  <TabPane tabId="1">
                    {dataOriginal?.member?.length === 0 && uOtherMember?.data?.member?.length === 0 ? (
                      <div className="error-no-data">{t('common.notData')}</div>
                    ) : (
                      ''
                    )}
                    {uOtherMember?.data?.check !== 0 && (
                      <Row>
                        <div className="list-members">
                          <div className="header">
                            <p className="head-title border-bot list-title">
                              {!!dataOriginal?.member?.length
                                ? t('divisionDiagram.divisionDetail.other')
                                : t('divisionDiagram.divisionDetail.member')}{' '}
                              ({uOtherMember?.data?.total_member})
                            </p>
                          </div>
                          <div className="body border-top">
                            {uOtherMember?.data?.member?.map((member, i) => {
                              return (
                                <>
                                  <RouteLink
                                    className="member"
                                    to={{
                                      pathname: '/admin/employees/profile/' + member?.user_id,
                                    }}
                                  >
                                    <div index={i}>
                                      <div className="member-body">
                                        <img
                                          onError={handleOnError}
                                          className="avatar-member"
                                          alt="just cool"
                                          src={member.avatar ? member.avatar : defaultAvatar}
                                        />
                                        <div className="description">
                                          <div className="name">{member.name}</div>
                                          <div className="description-body">
                                            <div className="email">{member.email}</div>
                                            <div className="code">
                                              <span>{t('divisionDiagram.divisionDetail.memberCode')}</span>
                                              <span>{member.code}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </RouteLink>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </Row>
                    )}

                    {dataOriginal?.member?.map((team, key) => {
                      return (
                        <Row index={key}>
                          <div className="list-members">
                            <div className="header">
                              <p className="head-title border-bot list-title">
                                {team.name} ({team.total_member})
                              </p>
                              <div className="update-icon position-icon-list">
                                <HiPencil onClick={() => handelEditTeam(team)} />
                              </div>
                            </div>
                            <div className="body border-top">
                              {team?.member?.length === 0 && (
                                <div className="error-no-data mt-4">
                                  {t('divisionDiagram.divisionDetail.emptyTeam')}
                                </div>
                              )}
                              {team?.member?.map((member, i) => {
                                return (
                                  <RouteLink
                                    className="member"
                                    to={{
                                      pathname: '/admin/employees/profile/' + member?.user_id,
                                    }}
                                    key={i}
                                  >
                                    <div index={i}>
                                      <div className="member-body">
                                        <img
                                          onError={handleOnError}
                                          className="avatar-member"
                                          alt="just cool"
                                          src={member.avatar ? member.avatar : defaultAvatar}
                                        />
                                        <div className="description">
                                          <div className="name">{member.name}</div>
                                          <div className="description-body">
                                            <div className="email">{member.email}</div>
                                            <div className="code">
                                              <span>{t('divisionDiagram.divisionDetail.memberCode')}</span>
                                              <span>{member.code}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </RouteLink>
                                );
                              })}
                            </div>
                          </div>
                        </Row>
                      );
                    })}
                    {!!uOtherMember?.data?.member?.length && uOtherMember?.data?.check === 0 && (
                      <Row>
                        <div className="list-members">
                          <div className="header">
                            <p className="head-title border-bot list-title">
                              {!!dataOriginal?.member?.length
                                ? t('divisionDiagram.divisionDetail.other')
                                : t('divisionDiagram.divisionDetail.member')}{' '}
                              ({uOtherMember?.data?.total_member})
                            </p>
                          </div>
                          <div className="body border-top">
                            {uOtherMember?.data?.member?.map((member, i) => {
                              return (
                                <>
                                  <RouteLink
                                    className="member"
                                    to={{
                                      pathname: '/admin/employees/profile/' + member?.user_id,
                                    }}
                                  >
                                    <div index={i}>
                                      <div className="member-body">
                                        <img
                                          onError={handleOnError}
                                          className="avatar-member"
                                          alt="just cool"
                                          src={member.avatar ? member.avatar : defaultAvatar}
                                        />
                                        <div className="description">
                                          <div className="name">{member.name}</div>
                                          <div className="description-body">
                                            <div className="email">{member.email}</div>
                                            <div className="code">
                                              <span>{t('divisionDiagram.divisionDetail.memberCode')}</span>
                                              <span>{member.code}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </RouteLink>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </Row>
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    <Grid container spacing={2}>
                      <Grid item xs={9}>
                        <Typography variant="h7" className="textInfoProject">
                          {t('divisionDiagram.divisionDetail.inforProject')}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <DateMonth
                          onChangeHandle={(value) => handleInputTimeCreatedProject(value)}
                          valueDefault={typeof foundingAt === 'string' ? moment(foundingAt, 'MM-DD-YYYY') : foundingAt}
                        />
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid item xs={12}>
                      <Paper>
                        <TableContainer>
                          <Table aria-label="sticky table">
                            <TableHead>
                              <TableRow className="headerTable">
                                <TableCell align="left">
                                  {t('divisionDiagram.divisionDetail.fieldProject.id')}
                                </TableCell>
                                <TableCell align="left">
                                  {t('divisionDiagram.divisionDetail.fieldProject.name')}
                                </TableCell>
                                <TableCell align="left">
                                  {t('divisionDiagram.divisionDetail.fieldProject.code')}
                                </TableCell>
                                <TableCell align="left">
                                  {t('divisionDiagram.divisionDetail.fieldProject.department')}
                                </TableCell>
                                <TableCell align="left">
                                  {t('divisionDiagram.divisionDetail.fieldProject.status')}
                                </TableCell>
                                <TableCell align="left">
                                  {t('divisionDiagram.divisionDetail.fieldProject.type')}
                                </TableCell>
                                <TableCell align="left">
                                  {t('divisionDiagram.divisionDetail.fieldProject.createdTime')}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {isLoadingListProject && (
                                <TableRow>
                                  <TableCell colSpan={7}>
                                    <Loading />
                                  </TableCell>
                                </TableRow>
                              )}
                              {!isLoadingListProject &&
                              rowsPerPage > NOT_DATA &&
                              projectsDivision &&
                              projectsDivision.length ? (
                                projectsDivision.map((row, indexField) => {
                                  const stone = indexField % 2;
                                  return (
                                    <TableRow key={row?.id} className={stone === 1 ? 'evenRowTable' : ''}>
                                      <TableCell component="th" scope="row">
                                        {row?.id}
                                      </TableCell>
                                      <TableCell align="left">{row?.title}</TableCell>
                                      <TableCell align="left">{row?.code}</TableCell>
                                      <TableCell align="left">{row?.department?.title}</TableCell>
                                      {changeTextStatus(row?.status)}
                                      <TableCell align="left">{changeTextType(row?.type)}</TableCell>
                                      <TableCell align="left">{customDate(row?.created_at, 'MM/YYYY')}</TableCell>
                                    </TableRow>
                                  );
                                })
                              ) : (
                                <TableRow>
                                  <TableCell align="left" colSpan={7}>
                                    <p className=" mt-3 mb-3 error-no-data text-center">{t('common.notData')}</p>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {totalProjects > NOT_DATA && (
                          <div className="table-pagination">
                            <TablePagination
                              rowsPerPageOptions={PER_PAGE_OPTION}
                              count={totalProjects}
                              rowsPerPage={rowsPerPage}
                              component="div"
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </div>
                        )}
                      </Paper>
                    </Grid>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </>
      )}

      {isLoadingDataDiagram ? (
        ''
      ) : (
        <ModalDivision
          isShowModal={isShowModalDivision}
          toggle={() => setShowModalDivision(!isShowModalDivision)}
          infoDivision={infoDivision}
          infoBranch={infoBranch}
          bodExist={bodExist}
        />
      )}

      {isLoadingDataDiagram ? (
        ''
      ) : (
        <ModalTeam
          isShowModal={isShowModalTeam}
          toggle={() => setShowModalTeam(!isShowModalTeam)}
          isLoadingDetailTeam={isLoadingDetailTeam}
          infoTeam={infoTeam}
          infoDivision={infoDivision}
          infoBranch={infoBranch}
        />
      )}

      <AlertComponent
        toggle={() => {
          setMessageError(!messageError);
        }}
        isShowAlert={!!messageError}
      >
        {messageError}
      </AlertComponent>
    </Container>
  );
};

export default DepartmentDiagram;
