import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Row,
  FormGroup,
  Input,
  Col,
  List,
  Button,
  Table,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import $ from 'jquery';
import { Grid } from '@mui/material';
import radio from 'assets/img/icons/radio.png';
import radioChecked from 'assets/img/icons/radio-checked.png';
import iconLoading from 'assets/img/icons/loading.png';
import iconMenuTotal from 'assets/img/icons/menu-total.png';
import Employees from './../index.js';
import * as Constants from 'constants/ListEmployees';
import { DEFAULT_LIMIT, DEFAULT_PAGE, NOT_DATA, ROLE_VIEW, ROLE_NOT_VIEW } from 'constants/index.js';
import ModalCommon from 'components/ModalCommon/ModalCommon.js';
import ModalAddEmployee from './modalAddEmployee';
import PerPage from 'components/PerPage';
import styles from './style.module.scss';
import {
  useListPosition,
  useListLevel,
  useGetGroupUser,
  useListStandbyUser
} from 'hook/useEmployees';
import { ThemContext } from 'layouts/Admin';
import SearchComponent from 'components/Search';
import Loading from 'components/Loading';
import { debounce } from '@mui/material';
import { customDate } from 'helpers/formatDate.js';
import CreateContractModal from '../../Contracts/Contract/Modals/CreateContract.js';
import AlertComponent from 'components/Alert';
import DateMonth from 'components/DateTime/DateMonth';
import moment from 'moment';

const DATA_ALL = { status: false, name: 'Tất cả', id: Constants.ALL, value: Constants.ALL };

const ListEmployees = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  
  const [perPage, setPerpage] = useState(DEFAULT_LIMIT);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [isShowModalCreate, setShowModalCreate] = useState(false);
  const [dataUserCreate, setDataUserCreate] = useState({});
  const [dataFilterBackup, setDataFilterBackup] = useState({});
  const [standbyFormData, setStandbyFormData] = useState({});
  const [pagingData, setPagingData] = useState({
    from: Constants.INIT_FOR_PAGING,
    to: Constants.INIT_FOR_PAGING,
    total: Constants.INIT_FOR_PAGING
  });

  const [filter, setFilter] = useState({
    page,
    perPage,
  });

  const { data: uListPosition } = useListPosition();
  const { data: uListLevel } = useListLevel();
  const { data: uListGroup } = useGetGroupUser();
  const { data: uListStandbyUser, isLoading: isLoadingStandbyUser } = useListStandbyUser(filter);
  const getTextById = (arr, id) => {
    if (arr) {
      const item = arr.find((element) => {return element.id === id;});
      return item.name;
    } else {
      return id;
    }
  }

  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const [filterPosition, setFilterPosition] = useState([]);
  const [filterDivision, setFilterDivision] = useState([]);
  const [filterLevel, setFilterLevel] = useState([]);
  const [filterGroup, setFilterGroup] = useState([]);
  const [keyWord, setKeyWord] = useState('');
  const [divisionSelect, setDivisionSelect] = useState(Constants.ALL);

  const handleCheck = (items, index) => {
    let isCheckAll = true;
    if (index === 0) {
      isCheckAll = !items[0].status;
      items.forEach((item, ind) => (ind === 0 ? (item.status = isCheckAll) : (item.status = false)));
    } else {
      items.forEach(function (item, i) {
        if (((i !== index && item.status === false) || (i === index && item.status === true)) && i !== 0) {
          isCheckAll = false;
        }
      });
      if (isCheckAll) {
        items.forEach((item, ind) => (ind === 0 ? (item.status = true) : (item.status = false)));
      } else {
        items.forEach(function (item, i) {
          if (i === 0) {
            item.status = isCheckAll;
          } else if (index === i) {
            item.status = !item.status;
          }
        });
      }
    }

    return [...items];
  }

  const changeSelectFilterPosition = (item) =>
    setFilterPosition(function (filterPosition) {
      const index = filterPosition.indexOf(item);
      return handleCheck(filterPosition, index);
    });

  const changeSelectFilterLevel = (item) =>
    setFilterLevel(function (filterLevel) {
      const index = filterLevel.indexOf(item);
      return handleCheck(filterLevel, index);
    });

  const changeSelectFilterGroup = (item) =>
    setFilterGroup(function (filterGroup) {
      const index = filterGroup.indexOf(item);
      return handleCheck(filterGroup, index);
    });  

  useEffect(() => {
    if (uListPosition) {
      uListPosition.forEach((item) => {
        item.status = false;
      });
      uListPosition.unshift(JSON.parse(JSON.stringify(DATA_ALL)));
      setFilterPosition(uListPosition);
    }
  }, [uListPosition]);

  useEffect(() => {
    if (uListLevel) {
      uListLevel.forEach((item) => {
        item.status = false;
      });
      uListLevel.unshift(JSON.parse(JSON.stringify(DATA_ALL)));
      setFilterLevel(uListLevel);
    }
  }, [uListLevel]);

  useEffect(() => {
    if (uListGroup?.data) {
      uListGroup?.data.forEach((item) => {
        item.status = false;
      });
      uListGroup?.data.unshift(JSON.parse(JSON.stringify(DATA_ALL)));
      setFilterGroup(uListGroup?.data);
    }
  }, [uListGroup]);

  useEffect(() => {
    if (uListStandbyUser?.data?.list_total_user_by_division && Array.isArray(uListStandbyUser?.data?.list_total_user_by_division)) {
      uListStandbyUser?.data?.list_total_user_by_division?.forEach((item) => {
        item.status = false;
      });
      setFilterDivision(uListStandbyUser?.data?.list_total_user_by_division);
    }
    const total = uListStandbyUser?.data?.list_user_pending.length ?? 0;
    setPagingData({
      from: (page - 1)* perPage + 1, 
      to: Math.min(page * perPage, total),
      total: total
    });
  }, [uListStandbyUser, page, perPage]);

  const changeRadio = function (item) {
    if (item === Constants.ALL) {
      setDivisionSelect(Constants.ALL);
      handleSubmit('', '', Constants.ALL);
    } else {
      setDivisionSelect(item.id);
      handleSubmit('', '', item.id);
    }
  };

  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const enterInput = useCallback(
    debounce((value) => {
      setKeyWord(value);
      handleSubmit('', { status: true, value: value });
    }),
  );

  const changePerPage = (e) => {
    setPerpage(e.target.value);
    setPage(DEFAULT_PAGE);
  };

  const changePage = (value) => {
    if (value) {
      setPage(value);
    }
  };

  const modalDefault = {
    importEmployee: false,
    import: false,
    addEmployee: false,
    position: false,
    level: false,
    group: false
  };

  const getDataFilter = (datas, request) => {
    if (datas?.find((item) => item.name === t('employees.all') && item.status)) {
      datas &&
        datas.forEach((item) => {
          return item.name !== t('employees.all') && request.push(item.id || item.value);
        });
      return;
    }

    datas &&
      datas.forEach((item) => {
        return item.status && item.name !== t('employees.all') && request.push(item.id || item.value);
      });
  };

  const handleSubmit = async (
    e = '',
    targetSearchKey = '',
    divisionChange = null,
    reset = '',
  ) => {
    const requestPosition = [];
    const requestLevel = [];
    const requestGroup = [];

    if (reset) {
      setKeyWord('');
      setDivisionSelect(Constants.ALL);
      setFilterPosition(function (filterPosition) {
        filterPosition.forEach(function (item) {
          item.status = false;
        });
        return [...filterPosition];
      });
      
      setFilterLevel(function (filterLevel) {
        filterLevel.forEach(function (item) {
          item.status = false;
        });
        return [...filterLevel];
      });
      
      setFilterDivision(function (filterDivision) {
        filterDivision.forEach(function (item) {
          item.status = false;
        });
        return [...filterDivision];
      });
      
      setFilterGroup(function (filterGroup) {
        filterGroup.forEach(function (item) {
          item.status = false;
        });
        return [...filterGroup];
      });

      setFilter({
        position_id: null,
        level_id: null,
        division_id: null, 
        contract_start_month: null,
        contract_end_month: null,      
        status: null,
        user: null,
        group_id: null,
        perPage: DEFAULT_LIMIT,
        page: DEFAULT_PAGE,
      });
      
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      getDataFilter(filterLevel, requestLevel);
      getDataFilter(filterPosition, requestPosition);
      getDataFilter(filterGroup, requestGroup);
      setFilter({
        position_id: requestPosition.length > 0 ? requestPosition : null,
        level_id: requestLevel.length > 0 ? requestLevel : null,
        division_id: divisionChange ? 
            (divisionChange === Constants.ALL) ? null : [divisionChange]
          : (divisionSelect === Constants.ALL) ? null : [divisionSelect],
        group_id: requestGroup.length > 0 ? requestGroup : null,
        contract_start_month: filter.contract_start_month,
        contract_end_month: filter.contract_end_month,
        keyword: targetSearchKey?.status ? targetSearchKey.value : keyWord ? keyWord : null,
        perPage: perPage,
        page: DEFAULT_PAGE,
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setModalCheckField(modalDefault);
  };

  const [modalCheckField, setModalCheckField] = useState(modalDefault);

  const toggleCheckField = (nameField) => {
    setModalCheckField(function (modal) {
      if (nameField === Constants.ADD_EMPLOYEE) {
        modal.addEmployee = !modal.addEmployee;
      } else if (nameField === Constants.POSITION) {
        setDataFilterBackup(JSON.parse(JSON.stringify(filterPosition)));
        modal.position = !modal.position;
      } else if (nameField === Constants.LEVEL) {
        setDataFilterBackup(JSON.parse(JSON.stringify(filterLevel)));
        modal.level = !modal.level;
      } else if (nameField === Constants.GROUP) {
        setDataFilterBackup(JSON.parse(JSON.stringify(filterGroup)));
        modal.group = !modal.group;
      }
      return { ...modal };
    });
  };

  $(function scroll() {
    var newWidth = $('table').width();
    $('.top').width(newWidth);
    $('.wrapper-top').scroll(function () {
      $('.wrapper-bottom').scrollLeft($('.wrapper-top').scrollLeft());
    });
    $('.wrapper-bottom').scroll(function () {
      $('.wrapper-top').scrollLeft($('.wrapper-bottom').scrollLeft());
    });
  });

  var px_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;

  $(window).resize(function () {
    isZooming();
  });

  function isZooming() {
    var newPx_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
    if (newPx_ratio === px_ratio) {
      var newWidth = $('table').width();
      $('.top').width(newWidth);
    }
  }

  const getTextTable = (strValue) => {
    if (strValue) return strValue;
    return t('common.notDataContract');
  };

  const handleShowMessage = (value) => {
    if (value) {
      setMessage(value?.[0]);
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (
      dataUserCreate?.user_id &&
      dataInforUser?.hr_permission?.contracts_permission &&
      dataInforUser?.hr_permission?.contracts_permission !== ROLE_VIEW
    ) {
      setShowModalCreate(true);
    }
    // eslint-disable-next-line
  }, [dataUserCreate]);

  return (
    <>
      <Grid container className="nav-fixed">
        <Grid container className="align-items-center">
          <Grid item md={6} xs={12}>
            <Employees />
          </Grid>
        </Grid>
      </Grid>
      <div className="mt-4"></div>
      <div fluid="true" className={styles.employee}>
        <Row className={styles.exportAround} sm={12}>
          <Col xs="3" sm="3" md="3" lg="4" xl="3" className={`${styles.marginLeft}`}></Col>
          <Col xs="8" sm="8" md="8" lg="8" xl="9" className={`${styles.exportRequest} float-right`}></Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="6" lg="8" xl="9">
            <Row>
              <Col xs="12">
                {isLoadingStandbyUser && <Loading />}
                {!isLoadingStandbyUser && uListStandbyUser?.data?.list_user_pending && uListStandbyUser?.data?.list_user_pending.length > 0 && (
                  <>
                    <div className="wrapper-top">
                      <div className="scroll-top">
                        <p className="top">
                          <span>{t('common.export')}</span>
                        </p>
                      </div>
                    </div>
                    <div className="wrapper-bottom">
                      <div className="scroll-bottom">
                        <Table>
                          <thead className={`thead-light`}>
                            <tr className={styles.headTable}>
                              <td className={`${styles.title} width-80px`}>{t('common.listNumber')}</td> 
                              <td className={`${styles.title} `}>{t('employees.nameEmployee')}</td>                          
                              <td className={`${styles.title} `}>{t('employees.level')}</td>
                              <td className={`${styles.title} `}>{t('employees.position')}</td>
                              <td className={`${styles.title} `}>{t('employees.department')}</td>                        
                              <td className={`${styles.title} `}>{t('infoEmployee.groupPersonal')}</td>
                              <td className={`${styles.title} `}>{t('contract.contractDetail.start_date')}</td>
                              <td className={`${styles.title} `}>{t('contract.contractDetail.end_date')}</td>
                              <td>&nbsp;</td>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {uListStandbyUser?.data?.list_user_pending &&
                              uListStandbyUser?.data?.list_user_pending.slice(pagingData.from -1, pagingData.to).map((item, index) => {                           
                                return (
                                  <tr key={index}>
                                    <td>{(page - 1) * perPage + index + 1}</td>                            
                                    <td>{item.full_name}</td>                        
                                    <td>{getTextTable(getTextById(uListLevel, item?.level_official_id))}</td>
                                    <td>{getTextTable(getTextById(uListPosition, item?.position_id))}</td>
                                    <td>{getTextTable(getTextById(uListStandbyUser?.data?.list_total_user_by_division, item?.division_id))}</td>
                                    <td>{getTextTable(getTextById(uListGroup?.data, item?.group_id))}</td>
                                    <td>
                                      {item?.start_date
                                        ? customDate(item?.start_date, 'DD/MM/YYYY')
                                        : getTextTable(item?.start_date)}                                  
                                    </td>
                                    <td>
                                      {item?.end_date
                                        ? customDate(item?.end_date, 'DD/MM/YYYY')
                                        : getTextTable(item?.end_date)}  
                                    </td>
                                    <td>
                                      <Button
                                        onClick={() => {
                                          if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
                                            return false;
                                          }
                                          setStandbyFormData(item);
                                          toggleCheckField(Constants.ADD_EMPLOYEE);
                                        }}
                                        className={styles.btnCreateStandby}
                                        disabled={dataInforUser?.hr_permission?.users_permission !== ROLE_VIEW ? false : true}                                                                            
                                      >   
                                        {t('common.labelCreate')}
                                      </Button>
                                    </td>                                    
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </>
                )}
                {uListStandbyUser?.data?.list_user_pending?.length > 0 && (
                  <PerPage
                    data={pagingData}
                    page={page}
                    perpage={perPage}
                    changePage={changePage}
                    changePerPage={changePerPage}
                    classAdd="perPage"
                  />
                )}
              </Col>
              <Col xl="9">
                {uListStandbyUser?.data?.list_user_pending?.length < 1 && (
                  <p className={`${styles.loading} error-no-data`}>{t('common.notData')}</p>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs="6" sm="6" md="6" lg="4" xl="3">
            <div className={styles.columnFilter}>
              <Row
                sm={12}
                className={`${styles.totalEmployee} ${styles.selected}`}
              >
                <span className={styles.iconStatistical}>
                  <img src={iconMenuTotal} alt="Total" />
                </span>
                <span className={styles.textTotal}>{t('employees.totalStandby')}</span>
                <span className={styles.total}>{uListStandbyUser?.data?.list_user_pending.length}</span>
              </Row>
              <Row sm={12} className={styles.searchFilter}>
                <SearchComponent
                  txtPlaceholder={t('employees.nameEmployee')}
                  addClass={`input-search-box ${styles.formRight}`}
                  handleSearch={enterInput}
                  defaultValue={keyWord}
                />
              </Row>
              <Row sm={12}>
                <Input
                  type="text"
                  placeholder={t('employees.level')}
                  name="level"
                  onClick={() => toggleCheckField(Constants.LEVEL)}
                  className={styles.exampleSelectMulti}
                  value={
                    filterLevel &&
                    (!filterLevel[0]?.status
                      ? filterLevel
                          .map((item, i) =>
                            item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                          )
                          .filter((it) => it)
                      : t('employees.all'))
                  }
                  readOnly
                />
              </Row>
              <Row sm={12}>
                <Input
                  type="text"
                  onClick={() => toggleCheckField(Constants.POSITION)}
                  placeholder={t('employees.position')}
                  name="position"
                  className={styles.exampleSelectMulti}
                  value={
                    filterPosition &&
                    (!filterPosition[0]?.status
                      ? filterPosition
                          .map((item, i) =>
                            item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                          )
                          .filter((it) => it)
                      : t('employees.all'))
                  }
                  readOnly
                />
              </Row>

              <Row sm={12}>
                <Input
                  type="text"
                  onClick={() => toggleCheckField(Constants.GROUP)}
                  placeholder={t('infoEmployee.groupPersonal')}
                  name="group"
                  className={styles.exampleSelectMulti}
                  value={
                    filterGroup &&
                    (!filterGroup[0]?.status
                      ? filterGroup
                          .map((item, i) =>
                            item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                          )
                          .filter((it) => it)
                      : t('employees.all'))
                  }
                  readOnly
                />
              </Row>
              <Row sm={12}>
                <div className={`${styles.paddingElementFilter} width-100`}>
                  <DateMonth
                    label=""
                    name="contract_start_month"
                    valueDefault={
                      typeof filter.contract_start_month === 'string' ? moment(filter.contract_start_month, 'MM/YYYY') : filter.contract_start_month
                    }
                    onChangeHandle={(value) => {
                      
                      setFilter({
                        ...filter,
                        contract_start_month: moment(value).format('MM/YYYY')
                        
                      });
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                    placeholder={t('contract.contractNav.chooseStartDate')}
                    sizeDate="small"
                    max={moment(filter.contract_end_month, 'MM/YYYY').toDate()}
                  />
                </div>
                
              </Row>
              <Row sm={12}>
                <div className={`${styles.paddingElementFilter} width-100`}>
                  <DateMonth
                    label=""
                    name="contract_end_month"
                    valueDefault={
                      typeof filter.contract_end_month === 'string' ? moment(filter.contract_end_month, 'MM/YYYY') : filter.contract_end_month
                    }
                    onChangeHandle={(value) => {
                      setFilter({
                        ...filter,
                        contract_end_month: moment(value).format('MM/YYYY')
                        
                      });
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                    placeholder={t('contract.contractNav.chooseEndDate')}
                    sizeDate="small"
                    min={moment(filter.contract_start_month, 'MM/YYYY').toDate()}
                  />
                </div>
                
              </Row>
              <Row className={styles.rowListRight}>
                <FormGroup tag="fieldset" className={styles.listRight}>
                  <List type="unstyled" className={styles.listRight}>
                    <li>
                      <h2 className={styles.titleFilter}>{t('common.division')}</h2>
                    </li>
                    <li>
                      <div className={styles.division}>
                        {divisionSelect === Constants.ALL ? (
                          <img className={styles.radio} alt="radio" src={radioChecked} />
                        ) : (
                          <img
                            onClick={() => changeRadio(Constants.ALL)}
                            alt="radio"
                            className={styles.radio}
                            src={radio}
                          />
                        )}
                        <span className={styles.itemRadio} onClick={() => changeRadio(Constants.ALL)}>
                          {DATA_ALL.name}
                        </span>
                      </div>
                    </li>
                    {!isLoadingStandbyUser &&
                      filterDivision &&
                      filterDivision.map((item, index) => (
                        <li key={index}>
                          <div className={styles.division}>
                            {item.id === divisionSelect ? (
                              <img className={styles.radio} alt="radio" src={radioChecked} />
                            ) : (
                              <img onClick={() => changeRadio(item)} alt="radio" className={styles.radio} src={radio} />
                            )}
                            <span className={`text-ellipsis ${styles.itemRadio}`} onClick={() => changeRadio(item)}>
                              {item.name}
                            </span>
                            {item.id !== Constants.ALL && (
                              <span className={styles.total}>{item.total_user ? item.total_user : NOT_DATA}</span>
                            )}
                          </div>
                        </li>
                      ))}
                    {isLoadingStandbyUser && <Loading />}
                  </List>
                </FormGroup>
              </Row>
              <Row sm={12}>
                <Button
                  className={styles.reset}
                  onClick={() => handleSubmit('', '', [], 'reset')}
                  size="lg"
                  block
                >
                  <img src={iconLoading} alt="Reset" width="20px" className={styles.imgReset} /> &emsp;
                  {t('common.reset')}
                </Button>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      
      <ModalCommon
        items={filterPosition}
        onSubmit={handleSubmit}
        onClick={changeSelectFilterPosition}
        toggle={() => {
          toggleCheckField(Constants.POSITION);
          setFilterPosition([...dataFilterBackup]);
        }}
        modal={modalCheckField.position}
        title={t('employees.filterPosition')}
        footer={t('common.search')}
      />
      <ModalCommon
        items={filterLevel}
        onSubmit={handleSubmit}
        onClick={changeSelectFilterLevel}
        toggle={() => {
          toggleCheckField(Constants.LEVEL);
          setFilterLevel([...dataFilterBackup]);
        }}
        modal={modalCheckField.level}
        title={t('employees.filterLevel')}
        footer={t('common.search')}
      />
      <ModalCommon
        items={filterGroup}
        onSubmit={handleSubmit}
        onClick={changeSelectFilterGroup}
        toggle={() => {
          toggleCheckField(Constants.GROUP);
          setFilterGroup([...dataFilterBackup]);
        }}
        modal={modalCheckField.group}
        title={t('infoEmployee.selectCategoryEmployees')}
        footer={t('common.search')}
      />
      {modalCheckField.addEmployee && (
        <ModalAddEmployee
          toggle={() => toggleCheckField('addEmployee')}
          data={{ queryClient: queryClient }}
          modal={modalCheckField.addEmployee}
          setDataUserCreate={setDataUserCreate}
          standbyFormData={standbyFormData}
        />
      )}
      
      {isShowModalCreate &&
        dataInforUser?.hr_permission?.contracts_permission &&
        dataInforUser?.hr_permission?.contracts_permission !== ROLE_VIEW &&
        dataInforUser?.hr_permission?.contracts_permission !== ROLE_NOT_VIEW && (
          <CreateContractModal
            onSetShowModal={() => setShowModalCreate(!isShowModalCreate)}
            isShowModalAdd={isShowModalCreate}
            onSetMessage={handleShowMessage}
            user={{ user_id: dataUserCreate.user_id, user_name: dataUserCreate.name }}
          />
        )}
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        <p className={styles.textLeft}>{message}</p>
      </AlertComponent>
    </>
  );
};

export default ListEmployees;
