import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';

import styles from './styles.module.scss';
import ModalComponent from 'components/Modal';
import { YupValidateRecall } from '../YupValidate/yupValidate';
import ButtonComponent from 'components/Buttons';
import { apiReCallAssets } from 'api/assets';
import { HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { USE_LIST_ASSETS, USE_LIST_BRIEF_RENT_PRICE } from 'constants/Assets';

function ModalRecall({ isShowModal, setShowModal, setShowAlert, setMessage, selectedValue }) {
  const { t } = useTranslation();
  const [disableBtn, setDisableBtn] = useState(false);
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(YupValidateRecall()),
  });

  // Submit data
  const onSubmit = async (data) => {
    setDisableBtn(true);
    const response = await apiReCallAssets({
      user_id: selectedValue?.user_id,
      asset_id: selectedValue?.asset_id,
      content: data.content,
    });
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setDisableBtn(false);
      return;
    }
    if (response?.data.status === 200) {
      setShowModal(!isShowModal);
      setDisableBtn(false);
    }
    if (response?.data.status === 200 || response?.data.status === 417) {
      setMessage(response.data.messages);
      setShowAlert(true);
      await queryClient.invalidateQueries(USE_LIST_BRIEF_RENT_PRICE);
      await queryClient.invalidateQueries(USE_LIST_ASSETS);
    }
  };
  return (
    <>
      <ModalComponent
        toggle={() => {
          setShowModal(!isShowModal);
          if (!isShowModal) {
            setDisableBtn(false);
            reset();
          }
        }}
        isShowModal={isShowModal}
        title={t('assets.reasonsForAssetRecovery')}
        classNameAdd={styles.modalRecall}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Category */}
          <section>
            <TextField
              className={styles.fieldReason}
              label={t('assets.enterReasonsForRecovery')}
              multiline
              rows={4}
              onChange={(e) => {
                setValue('content', e.target.value);
                if (errors?.content?.message) {
                  setError('content', '');
                }
              }}
              error={errors?.content?.message ? true : false}
            />
            {errors?.content?.message && <p className="error">{errors?.content?.message}</p>}
          </section>
          <Grid container spacing={2} className="mt-2">
            <Grid item xs={12}>
              <ButtonComponent
                text={t('assets.recall')}
                addClass="w-100 btn-submit"
                handleClick={handleSubmit(onSubmit)}
                type="submit"
                hasDisabled={disableBtn}
                isLoading={disableBtn}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </>
  );
}

export default ModalRecall;
