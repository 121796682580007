import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import '@fortawesome/fontawesome-free/css/all.min.css';

import * as COLOR from 'constants/ColorForm.js';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import iconInputError from './../../assets/img/icons/input_error.png';
import styles from './../Input/style.module.scss';
import { DATA_TYPE_NUMBER, DATA_TYPE_CURRENCY, DATA_TYPE_FLOAT } from 'constants/index.js';
import { isNumberAnode, isNumberCurrency, isFloatAnode } from 'helpers/index.js';
import { styled } from '@mui/material/styles';
import { CssTextField } from 'components/InputYupAndMui/constant';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 12px',

    '&:focus': {
      borderColor: COLOR.GREEN,
    },
    '&:hover': {
      borderColor: COLOR.GREEN,
    },
  },
}));

const InputField = (props) => {
  const {
    autoComplete,
    control,
    dataType,
    error,
    isRequired,
    label,
    maxLength,
    name,
    onHandleInput,
    placeholder,
    setValue,
    sizeInput,
    defaultValue,
    variant,
    disabled,
    isLoading,
  } = props;
  const [valueInput, setValueInput] = useState('');
  const [_autoComplete, setAutoComplete] = useState('off');

  useEffect(() => {
    setValueInput(defaultValue);
    setValue(name, defaultValue);
  }, [defaultValue, setValue, name]);

  useEffect(() => {
    setAutoComplete(autoComplete);
  }, [autoComplete]);

  const handleInput = (event) => {
    let checkInput = false;
    if (dataType) {
      switch (dataType) {
        case DATA_TYPE_NUMBER:
          if (isNumberAnode(event.target.value)) checkInput = true;
          break;

        case DATA_TYPE_CURRENCY:
          if (isNumberCurrency(event.target.value)) {
            checkInput = true;
          }
          break;

        case DATA_TYPE_FLOAT:
          if (isFloatAnode(event.target.value)) checkInput = true;
          break;

        default:
          break;
      }

      if (checkInput) {
        setValueInput(event.target.value);
        setValue(name, event.target.value);
        if (onHandleInput) {
          onHandleInput(event);
        }
      }
    } else {
      if (onHandleInput) {
        onHandleInput(event);
      }
      setValueInput(event.target.value);
      setValue(name, event.target.value);
    }
  };

  return (
    <>
      <div className={classNames(styles.inputFiled, styles.inputGroup)}>
        <Controller
          // render={({ field }) => <TextField {...field} helperText={error} label="234" error={error ? true : false} />}
          render={({ field }) => {
            if (!label) {
              return (
                <div>
                  <FormControl variant={variant} error={true}>
                    <BootstrapInput
                      {...field}
                      value={valueInput}
                      error={error ? true : false}
                      name={name}
                      variant={variant}
                      onChange={handleInput}
                      placeholder={placeholder}
                      disabled={disabled}
                      inputProps={{
                        maxLength: maxLength,
                      }}
                      size={sizeInput ? sizeInput : ''}
                    />
                  </FormControl>
                </div>
              );
            } else {
              return (
                <CssTextField
                  {...field}
                  // helperText={error}
                  label={
                    <React.Fragment>
                      {label}
                      {isRequired && label ? <sup> *</sup> : ''}
                      {isLoading ? <i className="fa-li fa fa-spinner fa-spin" /> : ''}
                    </React.Fragment>
                  }
                  inputProps={{
                    maxLength: maxLength,
                  }}
                  value={valueInput}
                  name={name}
                  disabled={disabled}
                  variant={variant}
                  error={error ? true : false}
                  size={sizeInput ? sizeInput : ''}
                  autoComplete={_autoComplete}
                  onChange={handleInput}
                  // CSS
                  labelspace={valueInput === null || valueInput === '' ? '-1px' : 0}
                />
              );
            }
          }}
          name={name}
          control={control}
        />
        {(error ? true : false) && (
          <div className={styles.image}>
            <img scr={iconInputError} alt={iconInputError} className="hidden" />
            <img alt={iconInputError} src={iconInputError} className={styles.imgInputError} />
          </div>
        )}
      </div>
      <p className="error-input error">{error}</p>
    </>
  );
};

export default InputField;
