import { useQuery } from 'react-query'
import { REQUEST_LIST_INSURANCE } from 'constants/Policy'
import { HTTP_OK } from 'constants/StatusCode'
import { apiGetSocialInsurance } from 'api/policy'

export function useGetSocialInsurance(params) {
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useQuery([REQUEST_LIST_INSURANCE, params], async () => {
    const response = await apiGetSocialInsurance(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });
  
  return { ...response, error, isLoading, refetch };
}
