import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const YupValidateBreakTime = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    hour_start_morning: Yup.string().required(t('messages.thisIsARequiredField')).nullable(),
    hour_end_morning: Yup.string().required(t('messages.thisIsARequiredField')).nullable(),
    hour_start_afternoon: Yup.string().required(t('messages.thisIsARequiredField')).nullable(),
    hour_end_afternoon: Yup.string().required(t('messages.thisIsARequiredField')).nullable(),
    hour_start_night: Yup.string().required(t('messages.thisIsARequiredField')).nullable(),
    hour_end_night: Yup.string().required(t('messages.thisIsARequiredField')).nullable(),
  });
  return validationSchema;
};
