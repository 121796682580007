import React, { useEffect, useState, useContext } from 'react';
import { Col, Table, Row, Form } from 'reactstrap';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HiPencil } from 'react-icons/hi';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

import './style.scss';
import AlertComponent from 'components/Alert/index.js';
import ButtonComponent from 'components/Buttons';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import Loading from 'components/Loading';
import ModalComponent from 'components/Modal/index.js';
import SearchComponent from 'components/Search';
import Sidebar from './../Sidebar.js';
import { customDate } from 'helpers/formatDate.js';
import {
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_OK,
  HTTP_NOT_ACCESS,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_EXPECTATION_FAILED,
} from 'constants/StatusCode.js';
import { MAX_LENGTH_50, MAX_LENGTH_20 } from 'constants/ProfileEmployee.js';
import {
  USE_LIST_ROLE,
  ROLE_VIEW_DIAGRAM,
  ROLE_VIEW_EMPLOYEE,
  ROLE_VIEW_CONTRACT,
  ROLE_VIEW_TIMESHEET,
  ROLE_VIEW_ASSETS,
  ROLE_VIEW_MASTER_DATA,
  ROLE_VIEW_INFOR_EMPLOYEE,
  ROLE_VIEW_POLICY,
  SYSTEM_ACMS_HR,
} from 'constants/MasterData.js';
import { apiDeleteRole, apiCreateRole } from 'api/masterData.js';
import { useListRole } from 'hook/useMasterData.js';
import RadioCustomRole from './RadioCustom/RadioCustom.js';
import YupValidate from './yupValidate';
import { NOT_HAVE_ACCESS, ID_SYSTEM_ADMIN } from 'constants/MasterData';
import { USE_INFOR_USER } from 'constants/Auth.js';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE, DISABLE_BUTTON_ROLE } from 'constants/index.js';

const Role = () => {
  let { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [isShowModal, setShowModal] = useState(false);
  const [key_word, setKeyWord] = useState('');
  const [stateButton, setStateButton] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [errorsCustom, setErrorsCustom] = useState('');
  const [textModal, setTextModal] = useState({
    title: t('masterData.roleMasterData.addTypeRole'),
    btn_submit: t('common.add'),
  });

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(YupValidate()),
    mode: 'onBlur',
  });

  const handleSearch = (value) => {
    if (value.length > MAX_LENGTH_20) {
      return;
    }
    setKeyWord(value);
  };

  const addSkill = () => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    reset();
    setShowModal(true);
    setTextModal({
      title: t('masterData.moreData'),
      btn_submit: t('common.add'),
    });
  };

  const { data: uListRole, isLoading: isLoadingListRole } = useListRole({
    key_word: key_word,
    system_id: SYSTEM_ACMS_HR,
  });

  const alertConfirm = (id) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    if (id === ID_SYSTEM_ADMIN) {
      setMessage(t('masterData.roleMasterData.youCannotDeleteBecauseThisIsTheGroupSystemAdmin'));
      setShowAlert(true);
      return;
    }
    confirmAlert({
      title: t('infoEmployee.youWantToDelete'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteRole({ group_role_id: id, system_id: SYSTEM_ACMS_HR });
            if (response?.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_LIST_ROLE);
              queryClient.invalidateQueries(USE_INFOR_USER);
            }
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (
              response?.data.status === HTTP_INTERNAL_SERVER_ERROR ||
              response?.data.status === HTTP_EXPECTATION_FAILED
            ) {
              setMessage(response?.data?.messages);
              setShowAlert(true);
            }
          },
        },
      ],
    });
  };

  const onSubmit = async (data) => {
    if (data.name.trim() === '') {
      setErrorsCustom(t('messages.pleaseEnterTitle'));
      $('input[name=name]').focus();
      return;
    }
    setStateButton(true);
    let datas = {
      role_id: [
        ROLE_VIEW_DIAGRAM,
        ROLE_VIEW_EMPLOYEE,
        ROLE_VIEW_CONTRACT,
        ROLE_VIEW_TIMESHEET,
        ROLE_VIEW_ASSETS,
        ROLE_VIEW_MASTER_DATA,
        ROLE_VIEW_INFOR_EMPLOYEE,
        ROLE_VIEW_POLICY
      ],
      permission_id: [
        data.diagram,
        data.employee,
        data.contract,
        data.timesheet,
        data.assets,
        data.master_data,
        data.infor_employee,
        data.policy
      ],
      name: data.name,
      system_id: 1,
    };
    let isViewOrEdit = false;
    datas.permission_id.forEach((item) => {
      if (item > NOT_HAVE_ACCESS) {
        isViewOrEdit = true;
      }
    });
    if (!isViewOrEdit) {
      setMessage(t('masterData.roleMasterData.pleaseSelectAtLeastOneViewPermissionOrOneEditPermission'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    let response = {};
    response = await apiCreateRole(datas);
    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages?.name) {
      setErrorsCustom(response.data.messages.name);
      $('input[name=name]').focus();
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModal(false);
      setMessage(response.data.messages);
      setShowAlert(true);
      setStateButton(false);
      queryClient.invalidateQueries(USE_LIST_ROLE);
    }
    setStateButton(false);
  };

  const watchFieldName = useWatch({
    control,
    name: 'name',
  });

  useEffect(() => {
    if (errorsCustom) {
      setErrorsCustom('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFieldName]);

  const checkRole = async (e) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      e.preventDefault();
    }
  };

  return (
    <div className="master-data-role">
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>
        <Col lg={9} xl={10}>
          <Row>
            <Col className="md-12">
              <div className="box-group d-flex float-right">
                <SearchComponent
                  txtPlaceholder={t('masterData.findNameGroup')}
                  addClass="input-search"
                  handleSearch={handleSearch}
                  maxLength={MAX_LENGTH_20}
                  defaultValue={key_word}
                />
                <ButtonComponent
                  text={t('common.add')}
                  icon={<IoIosAdd />}
                  addClass={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                    } ml-3`}
                  className="button-add"
                  handleClick={() => addSkill()}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="md-12">
              <div className="table-list">
                <Table className="align-items-center table-hover" responsive>
                  <thead>
                    <tr>
                      <th scope="col">{t('masterData.STT')}</th>
                      <th scope="col">{t('masterData.roleMasterData.nameRole')}</th>
                      <th scope="col">{t('masterData.roleMasterData.totalEmployees')}</th>
                      <th scope="col">{t('masterData.roleMasterData.dateCreate')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {uListRole?.length === 0 ? (
                      <tr>
                        <td colSpan={5}>
                          <div className="error-no-data">{t('common.notData')}</div>
                        </td>
                      </tr>
                    ) : (
                      uListRole?.map((val, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-bold">{val?.name}</td>
                          <td>{val?.total_member}</td>
                          <td>{customDate(val?.createdAt, 'DD/MM/YYYY')}</td>
                          <td>
                            <div className="col-action d-flex float-right">
                              <div className="edit mr-2">
                                <NavLink
                                  to={{
                                    pathname: '/admin/master-data/detail-users',
                                    state: {
                                      id: val.id,
                                    },
                                  }}
                                  onClick={checkRole}
                                  className={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                    } edit-link`}
                                >
                                  <HiPencil />
                                </NavLink>
                              </div>
                              <div
                                className={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                  } remove mr-3`}
                                onClick={() => alertConfirm(val.id)}
                              >
                                <MdDelete />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                    {isLoadingListRole && (
                      <tr>
                        <td colSpan={5}>
                          <Loading addClass="mt-2" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalComponent
        classNameAdd="modal-add-role-master-data"
        toggle={() => setShowModal(!isShowModal)}
        isShowModal={isShowModal}
        title={t('masterData.roleMasterData.addTypeRole')}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="fieldset-group">
            <CustomInputYup
              placeholder={t('masterData.roleMasterData.nameRole')}
              defaultValue={''}
              label={t('masterData.roleMasterData.nameRole')}
              name="name"
              control={control}
              register={register}
              errors={errors}
              addErrors={errorsCustom}
              useWatch={useWatch}
              required
              setValue={setValue}
              maxLength={MAX_LENGTH_50}
            />
          </div>
          <h6 className="title-role">{t('masterData.roleMasterData.setupRole')}</h6>
          <div className="fieldset-group mt-4 permissions">
            <RadioCustomRole
              name="diagram"
              label={t('divisionDiagram.name')}
              Controller={Controller}
              control={control}
            />
            <RadioCustomRole name="employee" label={t('common.employees')} Controller={Controller} control={control} />
            <RadioCustomRole
              name="contract"
              label={t('dashboard.contract')}
              Controller={Controller}
              control={control}
            />
            <RadioCustomRole
              name="timesheet"
              label={t('dashboard.timekeeping')}
              Controller={Controller}
              control={control}
            />
            <RadioCustomRole
              name="policy"
              label={t('menu.policy')}
              Controller={Controller}
              control={control}
            />
            <RadioCustomRole name="assets" label={t('dashboard.asset')} Controller={Controller} control={control} />
            <RadioCustomRole
              name="master_data"
              label={t('menu.masterData')}
              Controller={Controller}
              control={control}
            />
            <RadioCustomRole
              name="infor_employee"
              label={t('masterData.roleMasterData.watchInforEmployee')}
              Controller={Controller}
              control={control}
            />
          </div>
          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModal(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="w-100"
                handleClick={handleSubmit(onSubmit)}
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </Form>
      </ModalComponent>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default Role;
