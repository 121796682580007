import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';

import { BG_TIMEKEEPING_TIME_LATE_OT } from 'constants/ColorMap.js';
import { BG_TOOLTIP_TIMEKEEPING_TIME_LATE_OT } from 'constants/ColorMap.js';
import { COLOR_TOOLTIP_TIMEKEEPING_TIME_LATE_OT } from 'constants/ColorMap.js';

const ChartBar = ({ data }) => {
  const { t } = useTranslation();

  const labelsDivision = data?.map((item) => item?.division_name);
  const dataLateApproveHour = data?.map((item) => (Number(item?.late_time_approve) / 60).toFixed(2));
  const dataLateUnApprovedHour = data?.map((item) => (Number(item?.late_time_not_approve) / 60).toFixed(2));
  const dataOtHour = data?.map((item) => item?.over_times);

  const options = {
    maintainAspectRatio: false,
    legend: {
      labels: {
        fontSize: 18,
      },
      display: false,
    },
    title: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            borderDashOffset: 2,
            borderDash: [5, 5],
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },

    tooltips: {
      displayColors: false,
      backgroundColor: BG_TOOLTIP_TIMEKEEPING_TIME_LATE_OT,
      yPadding: 10,
      xPadding: 10,
      bodyFontSize: 12,
      callbacks: {
        title: () => null,

        label: function (tooltipItem, data) {
          let index = tooltipItem.index;
          let timeLastApprove = dataLateApproveHour[index];
          let timeLateUnApprove = dataLateUnApprovedHour[index];
          let timeOT = dataOtHour[index];

          return [
            `${t('timekeeping.dashboard.numberOTHours')}: ${timeOT}h`,
            `${t('timekeeping.dashboard.approvedLateHours')}: ${timeLastApprove}h`,
            `${t('timekeeping.dashboard.unApproveLateHours')}: ${timeLateUnApprove}h`,
          ];
        },
        labelTextColor: () => COLOR_TOOLTIP_TIMEKEEPING_TIME_LATE_OT,
      },
    },
  };

  const dataLateOT = {
    labels: labelsDivision,
    datasets: [
      {
        barThickness: 9,
        maxBarThickness: 12,
        data: dataOtHour,
        backgroundColor: BG_TIMEKEEPING_TIME_LATE_OT[0],
      },
      {
        barThickness: 9,
        maxBarThickness: 12,
        data: dataLateApproveHour,
        backgroundColor: BG_TIMEKEEPING_TIME_LATE_OT[1],
      },
      {
        barThickness: 9,
        maxBarThickness: 12,
        data: dataLateUnApprovedHour,
        backgroundColor: BG_TIMEKEEPING_TIME_LATE_OT[2],
      },
    ],
  };

  const datasetKeyProvider = () => {
    return Math.random();
  };

  return (
    <>
      <Bar options={options} data={dataLateOT} datasetKeyProvider={datasetKeyProvider} />
    </>
  );
};

export default React.memo(ChartBar);
