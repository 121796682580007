import { Button, Grid, Popover } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { BiLinkExternal } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Zoom from '@mui/material/Zoom';

import styles from './styles.module.scss';
import { getStartOfMonth, getEndOfMonth } from 'helpers/formatDate';

import paidLeaveIcon from 'assets/img/icons/paid-leave.svg';
import holidayIcon from 'assets/img/icons/holiday.svg';
import filterClockIcon from 'assets/img/icons/background-time-sheet.svg';
import afterNoonIcon from 'assets/img/icons/after-noon.svg';
import sunIcon from 'assets/img/icons/sun.svg';
import fullIcon from 'assets/img/icons/full-icon.svg';
import lateIcon from 'assets/img/icons/late-icon.svg';
import offIcon from 'assets/img/icons/off-icon.svg';
import preIcon from 'assets/img/icons/pre-icon.svg';
import nextIcon from 'assets/img/icons/next-icon.svg';
import calendarDayOff from 'assets/img/icons/calendar-dayoff.svg';
import calendarIcon from 'assets/img/icons/calendar-icon1.svg';
import docsIcon from 'assets/img/icons/docs-icon.svg';
import clockIcon from 'assets/img/icons/clock-icon.svg';
import moneyIcon from 'assets/img/icons/money-icon.svg';
import unpaidLeaveIcon from 'assets/img/icons/unpaid-leave.svg';
import otTimekeepingIcon from 'assets/img/icons/ot-timekeeping.svg';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import remoteTimeKeepingIcon from 'assets/img/icons/remote-timekeeping.svg';
import additionalWorkTimekeepingIcon from 'assets/img/icons/additional-work-timekeeping.svg';
import goEarlyComeLate from 'assets/img/icons/go-early-come-late.svg';

import Modal from 'components/Modal';
import { customDate } from 'helpers/formatDate';
import TimeKeeping from './../index.js';
import ButtonComponent from 'components/Buttons';
import { useTimeSheet, useGetHolidayScheduleRequestLeader, useTotalTimeSheet } from 'hook/useTimeSheet';
import { handleExportExcel, FILTER_BY_MODULE, storeFilterModule, removeFilterModule } from 'helpers';
import Breadcrumb from '../Breadcrumb';
import { formatNumberCurrency } from 'helpers/format';
import { TooltipContent, StyledTooltip } from './TooltipInfo';
import Loading from 'components/Loading';
import {
  REQUEST_TYPE_MORNING_SHIFT,
  REQUEST_TYPE_AFTERNOON_SHIFT,
  LENGTH_TO_REMOVE_BORDER_TOOLTIP,
  LENGTH_TO_DISPLAY_TOOLTIP,
  REQUEST_TYPE_ALL_DAY,
  NOT_DATA,
  NOT_LATE,
} from 'constants/TimeSheet';
import { useRequestRetire, useRequestOt } from 'hook/useContract';
import configs from 'config';
import { handleOnError } from 'helpers/index.js';
import { useProfileUserTimeKeeping } from 'hook/useProfileEmployee';
import { useGetScheduleTimeKeeping } from 'hook/useTimeSheet';

const requestStatus = {
  OPEN: 1,
  WAIT: 2,
  REWORK: 3,
  APPROVED: 4,
  RECALL: 6,
  REJECTED: 7,
};

const timeType = {
  full_time: 1,
  late_time: 2,
  lost_time: 3,
  off_time: 4,
};

const Calendar = () => {
  const { t } = useTranslation();
  const params = useParams();
  const userId = params.userId;

  const [selectMonth, setSelectMonth] = useState(getStartOfMonth());
  const [selectDay, setSelectDay] = useState();
  const [popoverDay, setPopoverDay] = useState(moment().format('Y/m/d'));

  const [listDate, setListDate] = useState();
  const [dataHoliday, setDataHoliday] = useState();
  const [calendarData, setCalendarData] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [requestData, setRequestData] = useState({});
  const { data: holiday } = useGetHolidayScheduleRequestLeader({
    filter: customDate(selectMonth, 'YYYY'),
  });
  const { data: dataRequestFifth } = useRequestRetire(configs.ID_TIMESHEET_REQUEST);
  const { data: dataRequestOT } = useRequestOt(configs.ID_OT_REQUEST);
  const { data, isLoading } = useTimeSheet({
    userId,
    category_id: [dataRequestFifth?.code, dataRequestOT?.code],
    date: customDate(selectMonth, 'MM/YYYY'),
  });
  const { data: totalTimeSheet } = useTotalTimeSheet({ userId, date: customDate(selectMonth, 'MM/YYYY') });
  const { data: profile } = useProfileUserTimeKeeping({}, userId);
  const { data: uGetSchedule } = useGetScheduleTimeKeeping({ user_id: userId });

  useEffect(() => {
    if (data) {
      const cloneData = data?.time_sheet?.reduce((a, v) => {
        return { ...a, [customDate(v.created_at, 'YYYY/MM/DD')]: v };
      }, {});
      const cloneDataRequest = [];

      data?.request?.forEach((item) => {
        let hrDateLog = moment(item.hr_date_log, 'DD/MM/YYYY').format('YYYY/MM/DD');
        if (cloneDataRequest[hrDateLog]) {
          cloneDataRequest[hrDateLog] = [...cloneDataRequest[hrDateLog], item];
        } else {
          cloneDataRequest[hrDateLog] = [item];
        }
      });

      setRequestData(cloneDataRequest);
      setCalendarData(cloneData);
    }
    // eslint-disable-next-line
  }, [data]);

  const checkMonthCurrent = (date) => {
    // eslint-disable-next-line
    if (moment(date, 'YYYY/MM/DD').format('MM') == customDate(selectMonth, 'MM')) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (holiday) {
      const cloneDataRequest = [];
      holiday?.forEach((item) => {
        if (item.start_date !== item.end_date) {
          let startDate = moment(item.start_date, 'DD/MM/YYYY');
          let endDate = moment(item.end_date, 'DD/MM/YYYY');
          let diffDay = endDate.diff(startDate, 'days');
          if (diffDay) {
            for (let i = 0; i < diffDay + 1; i++) {
              cloneDataRequest[moment(startDate).add(i, 'days').format('YYYY/MM/DD')] = 'holiday';
            }
          }
        } else {
          let startDate = moment(item.start_date, 'DD/MM/YYYY').format('YYYY/MM/DD');
          cloneDataRequest[startDate] = 'holiday';
        }
      });
      setDataHoliday(cloneDataRequest);
    }
  }, [holiday]);

  const handlePopoverOpen = (event, date) => {
    if (!!calendarData[date]?.request?.length) {
      setAnchorEl(event.currentTarget);
      setPopoverDay(date);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverDay(null);
  };

  const week = [
    {
      id: 1,
      value: t('common.monday'),
    },
    {
      id: 2,
      value: t('common.tuesday'),
    },
    {
      id: 3,
      value: t('common.wednesday'),
    },
    {
      id: 4,
      value: t('common.thursday'),
    },
    {
      id: 5,
      value: t('common.friday'),
    },
    {
      id: 6,
      value: t('common.saturday'),
    },
    {
      id: 7,
      value: t('common.sunday'),
    },
  ];

  const requestType = {
    1: t('timekeeping.registerLate'),
    2: t('timekeeping.addWorkingTime'),
  };

  const requestStatusTitle = {
    1: t('timekeeping.open'),
    2: t('timekeeping.waited'),
    3: t('timekeeping.rework'),
    4: t('timekeeping.approved'),
    6: t('timekeeping.recall'),
    7: t('timekeeping.rejected'),
  };

  useEffect(() => {
    const startOfMonth = getStartOfMonth(selectMonth);
    const endOfMonth = getEndOfMonth(selectMonth);

    if (selectMonth) {
      storeFilterModule({ selectedMonth: customDate(selectMonth, 'MM/YYYY')}, FILTER_BY_MODULE.TIME_KEEPING_DETAIL)
    }

    let startDate = moment(startOfMonth, 'MM/DD/YYYY').day(1);
    if (startDate.isAfter(startOfMonth)) {
      startDate = moment(startOfMonth, 'MM/DD/YYYY').day(1 - 7);
    }
    let endDate = moment(endOfMonth, 'MM/DD/YYYY').day(0);
    if (endDate.isBefore(endOfMonth)) {
      endDate = moment(endOfMonth, 'MM/DD/YYYY').day(0 + 7);
    }
    const dates = [];

    for (let date = moment(startDate); date.isSameOrBefore(endDate); date = moment(date).add(1, 'day')) {
      dates.push(date.format('YYYY/MM/DD'));
    }

    setListDate(dates);
  }, [selectMonth]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      removeFilterModule();
    });
    return () => {
      window.removeEventListener('beforeunload', () => {
        removeFilterModule();
      });
    }
  }, [])

  const getCheckoutStatus = useCallback(function (checkout, late_minute, label, requestType) {
    if (checkout || [REQUEST_TYPE_ALL_DAY].indexOf(requestType) > NOT_DATA) {
      if (late_minute === NOT_LATE || REQUEST_TYPE_ALL_DAY === requestType) {
        return (
          <>
            <img className="mr-2" src={fullIcon} alt="" />
            <div className={classNames(styles.in, styles.full)}>
              {label}: {checkout ? customDate(checkout, 'HH:mm') : 'N/A'}
            </div>
          </>
        );
      }
      return (
        <>
          <img className="mr-2" src={lateIcon} alt="" />
          <div className={classNames(styles.in, styles.late)}>
            {label}: {checkout ? customDate(checkout, 'HH:mm') : 'N/A'}
          </div>
        </>
      );
    }

    return (
      <>
        <img className="mr-2" src={offIcon} alt="" />
        <div className={classNames(styles.in, styles.off)}>
          {label}: {checkout ? customDate(checkout, 'HH:mm') : 'N/A'}
        </div>
      </>
    );
    // eslint-disable-next-line
  }, []);

  const checkTypeRequest = (data, typeData) => {
    let isCheck = false;
    if (data) {
      data.forEach((item) => {
        if (!isCheck && typeData.includes(String(item.type))) {
          isCheck = true;
        }
      });
    }
    return isCheck;
  };

  const requestLayoutPopup = (typeRequest) => {
    return requestData[selectDay]
      ?.map((dataRq) => dataRq)
      ?.filter((ind) => typeRequest.includes(String(ind.type)))
      ?.map((request, index) => {
        return (
          <div className={styles.otherInfoItem} key={request.id}>
            {index === 0 && (
              <div className="d-flex mb-2">
                <div className={styles.itemName}>{requestType[request.category]}</div>
                <div>
                  <strong>{request.type_name}</strong>
                </div>
              </div>
            )}
            <div className={styles.blockRequest}>
              <div className={styles.total}>
                {index + 1 >= 10 ? index + 1 : '0' + (index + 1)} - {t('timekeeping.status')}:{' '}
                <span
                  className={classNames(styles.itemStatus, {
                    [styles.waitStatus]: +request.status === requestStatus.WAIT,
                    [styles.approvedStatus]: +request.status === requestStatus.APPROVED,
                    [styles.rejectedStatus]: +request.status === requestStatus.REJECTED,
                    [styles.openStatus]: +request.status === requestStatus.OPEN,
                    [styles.reworkStatus]: +request.status === requestStatus.REWORK,
                    [styles.recallStatus]: +request.status === requestStatus.RECALL,
                  })}
                >
                  {requestStatusTitle[request.status]}
                </span>
              </div>
              <div className={styles.infoBasic}>
                <div className={styles.labelOther}>{request.hr_date_log}</div>
                <div className={styles.value}>{request.name}</div>
              </div>
            </div>
          </div>
        );
      });
  };

  return (
    <>
      <Grid container className="d-flex nav-fixed justify-content-between">
        <Grid className="menu-slider-around">
          <Breadcrumb />
        </Grid>

        <Grid item>
          <ButtonComponent
            handleClick={() =>
              handleExportExcel(
                `/api/time-sheet/export/user/${userId}`,
                { date: customDate(selectMonth, 'MM/YYYY') },
                'timesheet.xlsx',
              )
            }
            addClass="export"
            icon={<BiLinkExternal />}
            text={t('common.export')}
          />
        </Grid>
        <Grid container className=" nav-fixed-custom-top pt-0">
          <Grid className="menu-slider-around">
            <TimeKeeping userId={userId} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9} className={styles.filterMonth}>
          <Button
            className={styles.preBtn}
            onClick={() => setSelectMonth(moment(selectMonth).subtract(1, 'month').format('YYYY/MM/DD'))}
          >
            <img src={preIcon} alt="" />
          </Button>
          <div className={styles.month}>
            {t('timekeeping.month')} {customDate(selectMonth, 'MM / YYYY')}
          </div>
          <Button
            className={styles.nextBtn}
            onClick={() => setSelectMonth(moment(selectMonth).add(1, 'month').format('YYYY/MM/DD'))}
          >
            <img src={nextIcon} alt="" />
          </Button>
        </Grid>
        <Grid item xs={9}>
          {isLoading && (
            <div className="center">
              <Loading addClass="mt-3 mb-3" />
            </div>
          )}
          {!isLoading && listDate && (
            <div className={styles.calendar}>
              <div className={styles.calendarHeader}>
                <div className={styles.top}>
                  <div className="d-flex align-items-center mr-4">
                    <div className={classNames(styles.fulltimeIcon, 'mr-2')} />
                    <div>{t('timekeeping.fullTime')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <div className={classNames(styles.lateIcon, 'mr-2')} />
                    <div>{t('timekeeping.loseTime')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <div className={classNames(styles.loseIcon, 'mr-2')} />
                    <div>{t('timekeeping.noTime')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <div className={classNames(styles.offIcon, 'mr-2')} />
                    <div>{t('timekeeping.offTime')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={holidayIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.holiday')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={paidLeaveIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.totalOffDayHasSalary')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={unpaidLeaveIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.totalOffDayNoSalary')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={goEarlyComeLate} alt="" className="mr-2" />
                    <div>{t('timekeeping.goEarlyComeLate')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={additionalWorkTimekeepingIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.additionalWork')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={remoteTimeKeepingIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.remoteEN')}</div>
                  </div>
                  <div className="d-flex align-items-center mr-4">
                    <img src={otTimekeepingIcon} alt="" className="mr-2" />
                    <div>{t('timekeeping.dashboard.OT')}</div>
                  </div>
                </div>
                <div className={styles.bottom}>
                  {week.map((el) => (
                    <div key={el.id} className={styles.weekDay}>
                      {el.value}
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.calendarBody}>
                {listDate?.map((date) => (
                  <div
                    className={`${styles.date} ${
                      moment(selectMonth).month() + 1 !== moment(date).month() + 1 ? styles.outDate : ''
                    }`}
                    key={date}
                    id={date}
                  >
                    {calendarData[date] ? (
                      <>
                        <div className={styles.headBlockTimeKeeping}>
                          {checkMonthCurrent(date) && (
                            <>
                              {checkTypeRequest(requestData[date], configs.TYPE_PAID_LEAVE) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={<TooltipContent item={requestData[date]} type={configs.TYPE_PAID_LEAVE} />}
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img src={paidLeaveIcon} alt="" className={styles.paidLeaveIcon} />
                                </StyledTooltip>
                              )}
                              {checkTypeRequest(requestData[date], configs.TYPE_UNPAID_LEAVE) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={<TooltipContent item={requestData[date]} type={configs.TYPE_UNPAID_LEAVE} />}
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img src={unpaidLeaveIcon} alt="" className={styles.unPaidLeaveIcon} />
                                </StyledTooltip>
                              )}
                              {checkTypeRequest(requestData[date], configs.TYPE_OT) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={<TooltipContent item={requestData[date]} type={configs.TYPE_OT} />}
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img src={otTimekeepingIcon} alt="" className={styles.otTimekeepingIcon} />
                                </StyledTooltip>
                              )}
                              {checkTypeRequest(requestData[date], configs.TYPE_REMOTE) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={<TooltipContent item={requestData[date]} type={configs.TYPE_REMOTE} />}
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img src={remoteTimeKeepingIcon} alt="" className={styles.otTimekeepingIcon} />
                                </StyledTooltip>
                              )}
                              {checkTypeRequest(requestData[date], configs.TYPE_ADDITIONAL_WORK) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <TooltipContent item={requestData[date]} type={configs.TYPE_ADDITIONAL_WORK} />
                                  }
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img
                                    src={additionalWorkTimekeepingIcon}
                                    alt=""
                                    className={styles.otTimekeepingIcon}
                                  />
                                </StyledTooltip>
                              )}
                              {checkTypeRequest(requestData[date], configs.TYPE_GO_EARLY_COME_LATE) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <TooltipContent item={requestData[date]} type={configs.TYPE_GO_EARLY_COME_LATE} />
                                  }
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img src={goEarlyComeLate} alt="" className={styles.otTimekeepingIcon} />
                                </StyledTooltip>
                              )}
                            </>
                          )}
                        </div>
                        <div
                          className={classNames({
                            [styles.fulltime]:
                              calendarData[date].status === timeType.full_time ||
                              [REQUEST_TYPE_MORNING_SHIFT, REQUEST_TYPE_AFTERNOON_SHIFT, REQUEST_TYPE_ALL_DAY].indexOf(
                                calendarData[date].request_type,
                              ) > NOT_DATA,
                            [styles.lateTime]: calendarData[date].status === timeType.late_time,
                            [styles.loseTime]: calendarData[date].status === timeType.lost_time,
                            [styles.offTime]: calendarData[date].status === timeType.off_time,
                          })}
                          onClick={() => setSelectDay(date)}
                          onMouseEnter={(event) => handlePopoverOpen(event, date)}
                          onMouseLeave={handlePopoverClose}
                        >
                          <div className={styles.borderLeft} />
                          {requestData[date]?.length > LENGTH_TO_DISPLAY_TOOLTIP ? (
                            <div className={styles.content}>
                              <div className="d-flex mb-2">
                                <div className={styles.dateTime}>{customDate(date, 'DD/MM')}</div>
                                {checkMonthCurrent(date)
                                  ? dataHoliday?.[date] && <img src={holidayIcon} alt="" className={styles.holiday} />
                                  : null}
                              </div>
                              <div className={styles.dateTime}>
                                {t('timekeeping.working_time')} {calendarData[date].work_time} -{' '}
                                {calendarData[date].status !== timeType.off_time ||
                                (calendarData[date].checkin && !calendarData[date].checkout)
                                  ? `${t('timekeeping.late')} ${calendarData[date].late_time}'`
                                  : t('timekeeping.offTime')}
                              </div>
                              <div className="d-flex">
                                {getCheckoutStatus(
                                  calendarData[date].checkin,
                                  calendarData[date].late_time,
                                  t('timekeeping.in'),
                                  calendarData[date].request_type,
                                )}
                              </div>
                              <div className="d-flex">
                                {getCheckoutStatus(
                                  calendarData[date].checkout,
                                  calendarData[date].early_time,
                                  t('timekeeping.out'),
                                  calendarData[date].request_type,
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className={styles.content}>
                              <div className="d-flex mb-2">
                                <div className={styles.dateTime}>{customDate(date, 'DD/MM')}</div>
                                {checkMonthCurrent(date)
                                  ? dataHoliday?.[date] && <img src={holidayIcon} alt="" className={styles.holiday} />
                                  : null}
                              </div>
                              <div className={styles.dateTime}>
                                {t('timekeeping.working_time')} {calendarData[date].work_time} -{' '}
                                {calendarData[date].status !== timeType.off_time ||
                                (calendarData[date].checkin && !calendarData[date].checkout)
                                  ? `${t('timekeeping.late')} ${calendarData[date].late_time}'`
                                  : t('timekeeping.offTime')}
                              </div>
                              <div className="d-flex">
                                {getCheckoutStatus(
                                  calendarData[date].checkin,
                                  calendarData[date].late_time,
                                  t('timekeeping.in'),
                                  calendarData[date].request_type,
                                )}
                              </div>
                              <div className="d-flex">
                                {getCheckoutStatus(
                                  calendarData[date].checkout,
                                  calendarData[date].early_time,
                                  t('timekeeping.out'),
                                  calendarData[date].request_type,
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.headBlockTimeKeeping}>
                          {checkMonthCurrent(date) && (
                            <>
                              {checkTypeRequest(requestData[date], configs.TYPE_PAID_LEAVE) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={<TooltipContent item={requestData[date]} type={configs.TYPE_PAID_LEAVE} />}
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img src={paidLeaveIcon} alt="" className={styles.paidLeaveIcon} />
                                </StyledTooltip>
                              )}
                              {checkTypeRequest(requestData[date], configs.TYPE_UNPAID_LEAVE) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={<TooltipContent item={requestData[date]} type={configs.TYPE_UNPAID_LEAVE} />}
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img src={unpaidLeaveIcon} alt="" className={styles.unPaidLeaveIcon} />
                                </StyledTooltip>
                              )}
                              {checkTypeRequest(requestData[date], configs.TYPE_OT) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={<TooltipContent item={requestData[date]} type={configs.TYPE_OT} />}
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img src={otTimekeepingIcon} alt="" className={styles.otTimekeepingIcon} />
                                </StyledTooltip>
                              )}
                              {checkTypeRequest(requestData[date], configs.TYPE_REMOTE) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={<TooltipContent item={requestData[date]} type={configs.TYPE_REMOTE} />}
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img src={remoteTimeKeepingIcon} alt="" className={styles.otTimekeepingIcon} />
                                </StyledTooltip>
                              )}
                              {checkTypeRequest(requestData[date], configs.TYPE_ADDITIONAL_WORK) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <TooltipContent item={requestData[date]} type={configs.TYPE_ADDITIONAL_WORK} />
                                  }
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img
                                    src={additionalWorkTimekeepingIcon}
                                    alt=""
                                    className={styles.otTimekeepingIcon}
                                  />
                                </StyledTooltip>
                              )}
                              {checkTypeRequest(requestData[date], configs.TYPE_GO_EARLY_COME_LATE) && (
                                <StyledTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <TooltipContent item={requestData[date]} type={configs.TYPE_GO_EARLY_COME_LATE} />
                                  }
                                  className={
                                    requestData[date] < LENGTH_TO_REMOVE_BORDER_TOOLTIP && 'border-bottom-tooltip-none'
                                  }
                                >
                                  <img src={goEarlyComeLate} alt="" className={styles.otTimekeepingIcon} />
                                </StyledTooltip>
                              )}
                            </>
                          )}
                        </div>
                        <div className={styles.boxHeader}>
                          <div className={classNames(styles.normalDate)}>
                            {customDate(date, 'DD/MM')}
                          </div>
                          {checkMonthCurrent(date)
                            ? dataHoliday?.[date] && <img src={holidayIcon} alt="" className={styles.holiday} />
                            : null}
                        </div>
                      </>
                    )}
                  </div>
                ))}
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={false}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <div className={styles.popover}>
                    <div className={styles.title}>{t('timekeeping.offer')}</div>
                    {requestData[popoverDay]?.map((request) => (
                      <div key={request.id} className={styles.request}>
                        <div>
                          {t('timekeeping.requestType')}:{' '}
                          <span className={styles.value}>{requestType[request.category]}</span>
                        </div>
                        <div>
                          {t('timekeeping.created_at')}: <span>{request.date}</span>
                        </div>
                        <div>
                          {t('timekeeping.created_by')}: <span className={styles.value}>{request.approver}</span>
                        </div>
                        <div>
                          {t('timekeeping.type')}:{' '}
                          <span className={styles.status}>{requestStatusTitle[request.status]}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Popover>
                <Modal
                  isShowModal={!!selectDay}
                  classNameAdd={styles.modalInfoTimekeeping}
                  toggle={() => setSelectDay(!selectDay)}
                  title={t('timekeeping.timeSheetDetail')}
                >
                  <div className={styles.layout}>
                    <div className={`${styles.fullWidth} ${styles.totalTimeSheet}`}>
                      <div className={styles.info}>
                        <div className={styles.name}>{profile?.data?.user_information.name}</div>
                        <div className={styles.email}>{profile?.data?.user_information.email}</div>
                      </div>
                      <div className={styles.totalTimeSheetBody}>
                        <div className={styles.field}>
                          <div className={styles.label}>{t('timekeeping.otherInfo')}</div>
                        </div>
                        <div className={styles.field}>
                          <div className={styles.label}>{t('timekeeping.date')}</div>
                          <div className={styles.value}>
                            {customDate(calendarData[selectDay]?.created_at, 'DD/MM/YYYY')}
                          </div>
                        </div>
                        <hr className={styles.hrFull} />
                        <div className={`${styles.field} ${styles.bdBottom}`}>
                          <div className={styles.left}>
                            <div className={styles.label}>{t('timekeeping.checkin')}</div>
                            <div className={styles.value}>
                              {customDate(calendarData[selectDay]?.checkin, 'HH:mm') || t('common.notDataContract')}
                            </div>
                            <hr className={styles.hrShortRight} />
                          </div>
                          <div className={styles.right}>
                            <div className={styles.label}>{t('timekeeping.checkout')}</div>
                            <div className={styles.value}>
                              {customDate(calendarData[selectDay]?.checkout, 'HH:mm') || t('common.notDataContract')}
                            </div>
                            <hr className={styles.hrShortLeft} />
                          </div>
                        </div>
                        <div className={`${styles.field} ${styles.bdBottom}`}>
                          <div className={styles.left}>
                            <div className={styles.label}>{t('timekeeping.workingTime1')}</div>
                            <div className={styles.value}>{calendarData[selectDay]?.work_time}</div>
                            <hr className={styles.hrShortRight} />
                          </div>
                          <div className={styles.right}>
                            <div className={styles.label}>{t('timekeeping.lateTime1')}</div>
                            <div className={styles.value}>
                              {calendarData[selectDay]?.late_time} {t('common.minute')}
                            </div>
                            <hr className={styles.hrShortLeft} />
                          </div>
                        </div>
                        <div className={`${styles.field} ${styles.bdBottom}`}>
                          <div className={styles.left}>
                            <div className={styles.label}>{t('timekeeping.earlyTime1')}</div>
                            <div className={styles.value}>{calendarData[selectDay]?.early_time}</div>
                            <hr className={styles.hrShortRight} />
                          </div>
                        </div>
                        <div className={`${styles.field}`}>
                          <div className={styles.label}>{t('timekeeping.otherInfo')}</div>
                          <>
                            {requestLayoutPopup(configs.TYPE_PAID_LEAVE)}
                            {requestLayoutPopup(configs.TYPE_UNPAID_LEAVE)}
                            {requestLayoutPopup(configs.TYPE_OT)}
                            {requestLayoutPopup(configs.TYPE_GO_EARLY_COME_LATE)}
                            {requestLayoutPopup(configs.TYPE_ADDITIONAL_WORK)}
                            {requestLayoutPopup(configs.TYPE_REMOTE)}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          )}
        </Grid>
        <Grid xs={3} item>
          <div className={styles.standardShift}>
            <img src={filterClockIcon} className={styles.backGround} alt="" />
            <p className={styles.title}>{t('timekeeping.standardShift')}</p>
            <p className={styles.time}>
              <span>
                <img src={sunIcon} alt={'sun-icon'} />
                {uGetSchedule?.hour_start_morning &&
                  uGetSchedule?.hour_end_morning &&
                  moment(uGetSchedule?.hour_start_morning, 'HH:mm:ss').format('HH:mm') +
                    ' - ' +
                    moment(uGetSchedule?.hour_end_morning, 'HH:mm:ss').format('HH:mm')}
              </span>
              <span className={styles.afterNoon}>
                <img src={afterNoonIcon} alt={'after-noon-icon'} />
                {uGetSchedule?.hour_start_afternoon &&
                  uGetSchedule?.hour_end_afternoon &&
                  moment(uGetSchedule?.hour_start_afternoon, 'HH:mm:ss').format('HH:mm') +
                    ' - ' +
                    moment(uGetSchedule?.hour_end_afternoon, 'HH:mm:ss').format('HH:mm')}
              </span>
            </p>
          </div>

          <div className={styles.dayOff}>
            <img src={calendarDayOff} alt="" />
            <div>
              <div className={styles.label}>{t('timekeeping.offHour')}</div>
              <div className={styles.value}>{totalTimeSheet?.day_offs}</div>
            </div>
          </div>
          <div className={styles.timeSheet}>
            <div className={styles.info}>
              <img onError={handleOnError} src={profile?.data?.user_information.avatar || defaultAvatar} alt="" />
              <div>
                <div className={styles.name}>{profile?.data?.user_information?.name}</div>
                <div className={styles.position}>
                  {profile?.data?.user_information?.office_name} - {totalTimeSheet?.division_name}
                </div>
                <div className={styles.position}>
                  {profile?.data?.user_information?.code}
                </div>
              </div>
            </div>
            <div className={styles.allTime}>
              <div className={classNames(styles.timeSheetItem, styles.special)}>
                <div className={styles.item}>
                  <img src={docsIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.totalWorkingTime')}</div>
                </div>
                <div className={styles.value}>
                  {totalTimeSheet && totalTimeSheet?.total + '/' + totalTimeSheet?.hour_default}
                </div>
              </div>
              <div className={classNames(styles.timeSheetItem)}>
                <div className={styles.item}>
                  <img src={clockIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.numberLateTime')}</div>
                </div>
                <div className={styles.value}>{totalTimeSheet?.late_time}</div>
              </div>
              <div className={classNames(styles.timeSheetItem)}>
                <div className={styles.item}>
                  <img src={clockIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.numberLateEarlyTimePropost')}</div>
                </div>
                <div className={styles.value}>
                  {totalTimeSheet
                    ? totalTimeSheet?.total_time_late_early + `/${totalTimeSheet?.default_time_late_early}`
                    : `0/${totalTimeSheet?.default_time_late_early || 0}`}
                </div>
              </div>
              <div className={classNames(styles.timeSheetItem)}>
                <div className={styles.item}>
                  <img src={moneyIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.totalMoney')}</div>
                </div>
                <div className={styles.value}>
                  {formatNumberCurrency(totalTimeSheet?.fine)} {t('common.currency')}
                </div>
              </div>
              <div className={classNames(styles.timeSheetItem)}>
                <div className={styles.item}>
                  <img src={calendarIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.totalOffDayHasSalaryUnit')}</div>
                </div>
                <div className={styles.value}>{totalTimeSheet?.paid_leave || 0}</div>
              </div>
              <div className={classNames(styles.timeSheetItem)}>
                <div className={styles.item}>
                  <img src={calendarIcon} alt="" />
                  <div className={styles.label}>{t('timekeeping.totalOffDayNoSalaryUnit')}</div>
                </div>
                <div className={styles.value}>{totalTimeSheet?.unpaid_leave || 0}</div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Calendar;
