import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const YupValidateCreateAssets = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    block: Yup.string().required(t('messages.thisIsARequiredField')),
    money: Yup.string().required(t('messages.thisIsARequiredField')),
    minutes: Yup.string().required(t('messages.thisIsARequiredField')),
    next_time_late_early: Yup.string().required(t('messages.thisIsARequiredField')),
  });
  return validationSchema;
};
