import { DatePicker, Form, Input, InputNumber, Select } from 'antd';
import {
  MIN_MONTH_COMMIT,
  MIN_SUPPORT_COST,
  rateCommitOptions,
  FIELD_KEY,
  typeProgramOptions,
  COURSE_OUTSIDE_COMPANY,
  CERTIFICATE,
} from 'constants/Training';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, CalendarIcon } from '../icon';
import { DATE_FORMAT } from '..';
import { useContext, useEffect, useState } from 'react';
import { TrainingContext } from '../Context/TrainingProvider';
import { MAX_LENGTH_255, MAX_LENGTH_1000 } from 'constants/index';

const FormProgram = ({ form, name, handleFinish, type }) => {
  const { t } = useTranslation();
  const [typeProgram, setTypeProgram] = useState();
  const [category, setCategory] = useState();

  const { categories, certificates } = useContext(TrainingContext);

  useEffect(() => {
    if (type) {
      setTypeProgram(type);
    }
  }, [form, type]);

  const mapOptions = (options) =>
    options
      ? options.map((option) => ({
          value: option.value ? option.value : option.id,
          ...option,
          label: option.label ? t(option.label) : option.name,
        }))
      : [];

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: t('messages.thisIsARequiredField'),
    whitespace: t('messages.thisIsARequiredField'),
    number: {
      range: t('messages.pleaseEnterValueBetween', { min: '${min}', max: '${max}' }),
      min: t('messages.pleaseEnterValueGreaterOrEqual', { min: '${min}' }),
    },
    types: {
      integer: t('messages.thisIsNotAnInteger')
    }
  };

  return (
    <Form
      form={form}
      name={name}
      layout="vertical"
      size="large"
      className="materialStyle form"
      validateMessages={validateMessages}
      onFinish={handleFinish}
    >
      <Form.Item name={FIELD_KEY.TYPE} label={t('training.typeProgram')} rules={[{ required: true }]}>
        <Select
          options={mapOptions(typeProgramOptions)}
          onChange={(value) => {
            if (value !== typeProgram) {
              setTypeProgram(value);
            }

            if (value === CERTIFICATE) {
              form.resetFields([FIELD_KEY.START_DATE, FIELD_KEY.END_DATE, FIELD_KEY.NAME]);
            }

            if (value !== COURSE_OUTSIDE_COMPANY) {
              form.resetFields([FIELD_KEY.LOCATION]);
            }
          }}
          suffixIcon={<ArrowDownIcon />}
          allowClear
        />
      </Form.Item>

      {typeProgram === CERTIFICATE && (
        <>
          <Form.Item
            name={FIELD_KEY.CERTIFICATE_CATALOG}
            label={t('training.certificateCatalog')}
            rules={[{ required: typeProgram === CERTIFICATE }]}
          >
            <Select
              options={mapOptions(categories)}
              suffixIcon={<ArrowDownIcon />}
              onChange={(value) => {
                if (value !== category) {
                  setCategory(value);
                  form.resetFields([FIELD_KEY.CERTIFICATE_ID]);
                }
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name={FIELD_KEY.CERTIFICATE_ID}
            label={t('training.certificateName')}
            rules={[{ required: typeProgram === CERTIFICATE }]}
          >
            <Select
              options={mapOptions(certificates).filter((cert) =>
                category ? cert.certificate_category === category : true,
              )}
              suffixIcon={<ArrowDownIcon />}
            />
          </Form.Item>
        </>
      )}

      {typeProgram !== CERTIFICATE && (
        <Form.Item
          name={FIELD_KEY.NAME}
          label={t('training.programName')}
          rules={[{ required: typeProgram !== CERTIFICATE }, { whitespace: true }, { min: 0 }]}
        >
          <Input maxLength={MAX_LENGTH_255} />
        </Form.Item>
      )}

      {typeProgram === COURSE_OUTSIDE_COMPANY && (
        <Form.Item
          name={FIELD_KEY.LOCATION}
          label={t('training.location')}
          rules={[{ required: typeProgram === COURSE_OUTSIDE_COMPANY }, { whitespace: true }]}
        >
          <Input />
        </Form.Item>
      )}

      {typeProgram !== CERTIFICATE && (
        <div className="flex gap-4">
          <Form.Item name={FIELD_KEY.START_DATE} label={t('training.startDate')} rules={[{ required: true }]}>
            <DatePicker
              suffixIcon={<CalendarIcon />}
              placeholder=""
              format={DATE_FORMAT}
              disabledDate={(day) => {
                const endDate = form.getFieldValue(FIELD_KEY.END_DATE);
                return day.isSameOrAfter(endDate) && day && endDate;
              }}
            />
          </Form.Item>

          <Form.Item name={FIELD_KEY.END_DATE} label={t('training.endDate')} rules={[{ required: true }]}>
            <DatePicker
              suffixIcon={<CalendarIcon />}
              placeholder=""
              format={DATE_FORMAT}
              disabledDate={(day) => {
                const startDate = form.getFieldValue(FIELD_KEY.START_DATE);
                return day.isSameOrBefore(startDate) && day && startDate;
              }}
            />
          </Form.Item>
        </div>
      )}

      <Form.Item
        name={FIELD_KEY.SUPPORT_COST}
        label={t('training.supportCost')}
        rules={[
          { required: true },
          {
            type: 'integer',
            min: MIN_SUPPORT_COST,
          },
        ]}
      >
        <InputNumber controls={false} maxLength={9} />
      </Form.Item>

      <Form.Item
        name={FIELD_KEY.MONTH_COMMIT}
        label={t('training.monthCommit')}
        rules={[
          { required: true },
          {
            type: 'integer',
            min: MIN_MONTH_COMMIT,
          },
        ]}
      >
        <InputNumber controls={false} maxLength={2} />
      </Form.Item>

      <Form.Item name={FIELD_KEY.RATE_COMMIT} label={t('training.rateCommit')} rules={[{ required: true }]}>
        <Select options={mapOptions(rateCommitOptions)} suffixIcon={<ArrowDownIcon />} allowClear />
      </Form.Item>

      <Form.Item name={FIELD_KEY.DESCRIPTION} label={t('training.description')}>
        <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} maxLength={MAX_LENGTH_1000} />
      </Form.Item>
    </Form>
  );
};

export default FormProgram;
