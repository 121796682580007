/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext } from 'react';
import { Col, Row, Table } from 'reactstrap';
import { IoIosAdd } from 'react-icons/io';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';

import Sidebar from './../Sidebar.js';
import { useSpecialTimeKeeping } from '../../../../hook/useMasterData.js';
import { MAX_LENGTH_20 } from 'constants/index.js';
import ButtonComponent from 'components/Buttons/index.js';
import Loading from 'components/Loading/index.js';
import { useTranslation } from 'react-i18next';
import SearchComponent from './../../../../components/Search';
import AlertComponent from 'components/Alert/index.js';
import { apiDeleteTimeSpecialKeeping } from 'api/masterData.js';
import { USE_LIST_SPECIAL_TIME_KEEPING } from 'constants/MasterData.js';
import { convertTypeTimeKeeping } from './ultil.js';
import IconDeleteComponent from 'components/Delete/IconDelete.js';
import ModalAddSpecialTimeKeeping from './ModalAddSpecialTimekeeping.js';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_BUTTON_ROLE, DISABLE_ROLE } from 'constants/index.js';

function index() {
  const { t } = useTranslation();
  const dataInforUser = useContext(ThemContext);
  // Searching
  const [filter, setFilter] = useState({
    key_word: '',
  });
  const handleSearch = (value) => {
    if (value.length > MAX_LENGTH_20) {
      return;
    }
    setFilter({ ...filter, key_word: value });
  };

  // State
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);
  const [isShowModal, setShowModal] = useState(false);
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  // Get item
  const { data: listSpecialTimeKeeping, isLoading: isLoadingListTimeKeeping } = useSpecialTimeKeeping(filter);

  return (
    <div>
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>
        <Col lg={9} xl={10}>
          <Row>
            <Col className="md-12">
              <div className="box-group d-flex float-right">
                <SearchComponent
                  txtPlaceholder={t('masterData.specialTimeKeeping.placeholderInput')}
                  addClass="input-search"
                  handleSearch={handleSearch}
                  maxLength={MAX_LENGTH_20}
                />
                <ButtonComponent
                  text={t('common.add')}
                  icon={<IoIosAdd />}
                  addClass={`${
                    dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                  } ml-3 d-flex align-items-center`}
                  className="button-add"
                  handleClick={() =>
                    dataInforUser?.hr_permission?.master_data_permission !== ROLE_VIEW && setShowModal(true)
                  }
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="md-12">
              <div className="table-list">
                <Table className="align-items-center table-hover" responsive>
                  <thead>
                    <tr>
                      <th scope="col">{t('masterData.STT')}</th>
                      <th scope="col">{t('masterData.specialTimeKeeping.table.title')}</th>
                      <th scope="col">{t('masterData.specialTimeKeeping.table.typeTimeKeeping')}</th>
                      <th scope="col">{t('masterData.specialTimeKeeping.totalUser')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listSpecialTimeKeeping?.data?.length === 0 ? (
                      <tr>
                        <td colSpan={8}>
                          <div className="error-no-data">{t('common.notData')}</div>
                        </td>
                      </tr>
                    ) : (
                      listSpecialTimeKeeping?.data?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1 > 9 ? index + 1 : <>0{index + 1}</>}</td>
                          <td className="text-bold">{item.name}</td>
                          <td>{t(convertTypeTimeKeeping(item.type))}</td>
                          <td>{item.total_user}</td>
                          <td>
                            <div className="col-action d-flex float-right">
                              <div
                                className={`${
                                  dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                } edit mr-2`}
                              >
                                <HiPencil
                                  onClick={() => {
                                    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
                                      return;
                                    }
                                    setShowModalEdit(true);
                                    setSelectedValue(item);
                                  }}
                                />
                              </div>
                              <div
                                className={`${
                                  dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                } remove mr-3`}
                              >
                                {dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && <MdDelete />}
                                {dataInforUser?.hr_permission?.master_data_permission !== ROLE_VIEW && (
                                  <IconDeleteComponent
                                    linkDelete={apiDeleteTimeSpecialKeeping(item.id)}
                                    keyQuery={USE_LIST_SPECIAL_TIME_KEEPING}
                                    onSetMessage={setMessage}
                                    onSetShowAlert={setShowAlert}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                    {isLoadingListTimeKeeping && (
                      <tr>
                        <td colSpan={5}>
                          <Loading addClass="mt-2" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Add */}
      {isShowModal && (
        <ModalAddSpecialTimeKeeping
          isShowModal={isShowModal}
          setShowModal={setShowModal}
          type="add"
          message={message}
          setMessage={setMessage}
          isShowAlert={isShowAlert}
          setShowAlert={setShowAlert}
        />
      )}

      {/* Edit */}
      {isShowModalEdit && (
        <ModalAddSpecialTimeKeeping
          selectedValue={selectedValue}
          isShowModal={isShowModalEdit}
          setShowModal={setShowModalEdit}
          type="edit"
          message={message}
          setMessage={setMessage}
          isShowAlert={isShowAlert}
          setShowAlert={setShowAlert}
        />
      )}

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
}

export default index;
