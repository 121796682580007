import { useQuery } from 'react-query';

import { HTTP_OK } from './../constants/StatusCode.js';
import {
  apiGetContractHistory,
  apiGetDetailContract,
  apiGetListDivisionContract,
  apiGetListRetireContract,
  apiStatisticalCategoryContract,
  apiStatisticalCategoryEmployees,
  apiStatisticalStatusContract,
  apiGeListMember,
  apiGetListCategoryEmployees,
  apiGetListWaitContract,
  apiGenerateCodeContract,
  apiListContractDivision,
  apiGetListContract,
  apiListContractDivisionPending,
  apiRequestListRetire,
  apiGetTotalEmployees,
  apiCheckContractOnboardUser,
} from './../api/contract';
import * as Constants from './../constants/Contract.js';

export function useGetTotalEmployees(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(Constants.USE_GET_TOTAL_EMPLOYEES, async () => {
    const response = await apiGetTotalEmployees(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useStatisticalCategoryContract(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_STATISTICAL_CATEGORY_CONTRACT, params], async () => {
    const response = await apiStatisticalCategoryContract(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useStatisticalCategoryEmployees(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_STATISTICAL_CATEGORY_EMPLOYEE, params], async () => {
    const response = await apiStatisticalCategoryEmployees(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });
  return { ...response, error, isLoading };
}

export function useStatisticalStatusContract(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_STATISTICAL_STATUS_CONTRACT, params], async () => {
    const response = await apiStatisticalStatusContract(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListRetireContract(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_RETIRE_CONTRACT, params], async () => {
    const response = await apiGetListRetireContract(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListDivisionContract(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_LIST_DIVISION_CONTRACT, params],
    async () => {
      const response = await apiGetListDivisionContract(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    {
      enabled: params.category_id ? true : false,
    },
  );

  return { ...response, error, isLoading };
}

export function useGeListMemberContract(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_MEMBER_CONTRACT, params], async () => {
    const response = await apiGeListMember(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListContract(params) {
  const {
    data: response,
    isLoading,
    error,
  } = useQuery([Constants.USE_LIST_CONTRACT, params], async () => {
    const response = await apiGetListContract(params);

    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetDetailContract(params) {
  const {
    data: response,
    isLoading,
    error,
  } = useQuery([Constants.USE_DETAIL_CONTRACT, params], async () => {
    const response = await apiGetDetailContract(params);

    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetContractHistory(params) {
  const {
    data: response,
    isLoading,
    error,
  } = useQuery([Constants.USE_CONTRACT_HISTORY, params], async () => {
    const response = await apiGetContractHistory(params);

    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListCategoryEmployees(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_CATEGORY_EMPLOYEE_CONTRACT, params], async () => {
    const response = await apiGetListCategoryEmployees(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListWaitContract(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_WAIT_CONTRACT, params], async () => {
    const response = await apiGetListWaitContract(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGenerateCodeContract(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GENERATE_CODE_CONTRACT, params], async () => {
    if (params && params?.user_id && params?.contract_name) {
      const response = await apiGenerateCodeContract(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListContractDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_CONTRACT_DIVISION, params], async () => {
    const response = await apiListContractDivision(params);
    if (response.status === HTTP_OK) {
      return response.data.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useListContractDivisionPending(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_CONTRACT_DIVISION_PENDING, params], async () => {
    const response = await apiListContractDivisionPending(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useRequestRetire(ID) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(Constants.USE_REQUEST_RETIRE, async () => {
    const response = await apiRequestListRetire(ID);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useRequestOt(ID) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(Constants.USE_REQUEST_OT, async () => {
    const response = await apiRequestListRetire(ID);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });
  return { ...response, error, isLoading };
}

export function useCheckContractOnboardUser(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_CHECK_CONTRACT_ONBOARD_USER, params],
    async () => {
      const response = await apiCheckContractOnboardUser(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    {
      enabled: params.user_id ? true : false,
    },
  );

  return { ...response, error, isLoading };
}
