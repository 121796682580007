import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import { CssSelectField } from 'components/InputYupAndMui/constant';
import { FormControl } from '@mui/material';
import classNames from 'classnames';

const SelectField = (props) => {
  const {
    children,
    control,
    error,
    clearErrors,
    isRequired,
    label,
    name,
    onHandleSelect,
    setValue,
    sizeSelect,
    defaultValue,
    useWatch,
    register,
    isShowModal,
    disabled,
    scrollItem = false,
  } = props;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      }
    },
  };

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue, setValue, name]);

  const handleChange = (event) => {
    setValue(name, event.target.value);
    clearErrors && clearErrors(name)

    if (onHandleSelect) {
      event.target.name = name;
      onHandleSelect(event);
    }
  };

  const valueWatch = useWatch({
    control,
    name: name,
    defaultValue: defaultValue,
  });

  return (
    <>
      <CssSelectField labelspace={valueWatch ? 0 : '-1px'}>
        {isShowModal ? (
          <FormControl fullWidth className={classNames(disabled && 'Mui-disabled-parent')}>
            <Controller
              name={name}
              {...register(name)}
              control={control}
              render={({ field }) => {
                if (label) {
                  return (
                    <>
                      <InputLabel
                        id={'demo-simple-select-label' + name}
                        size={sizeSelect ? sizeSelect : ''}
                        error={error ? true : false}
                      >
                        {label} {isRequired ? <sup> *</sup> : ''}
                      </InputLabel>
                      <Select
                        {...field}
                        value={valueWatch}
                        onChange={handleChange}
                        labelId={'demo-simple-select-label' + name}
                        label={label}
                        MenuProps={scrollItem ? { ...MenuProps } : {}}
                        error={error ? true : false}
                        disabled={disabled || false}
                      >
                        {children}
                      </Select>
                    </>
                  );
                } else {
                  return (
                    <>
                      <InputLabel
                        id={'demo-simple-select-label' + name}
                        size={sizeSelect ? sizeSelect : ''}
                        error={error ? true : false}
                      >
                        .
                      </InputLabel>
                      <Select
                        {...field}
                        value={valueWatch}
                        onChange={handleChange}
                        labelId={'demo-simple-select-label' + name}
                        label="."
                        name={name}
                        error={error ? true : false}
                        disabled={disabled || false}
                      >
                        {children}
                      </Select>
                    </>
                  );
                }
              }}
            />
          </FormControl>
        ) : (
          ''
        )}
      </CssSelectField>
      <p className="error-input error">{error}</p>
    </>
  );
};

export default SelectField;
