export const GENDER_MALE = 0;
export const GENDER_FEMALE = 1;

export const MAX_LENGTH_5 = 5;
export const MAX_LENGTH_10 = 10;
export const MAX_LENGTH_13 = 13;
export const MAX_LENGTH_15 = 15;
export const MAX_LENGTH_20 = 20;
export const MAX_LENGTH_30 = 30;
export const MAX_LENGTH_40 = 40;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_100 = 100;
export const MAX_LENGTH_1000 = 1000;

export const STATUS_ACTIVE = 1;
export const STATUS_UNACTIVE = 0;

export const MARRIE = 1;
export const UNMARRIE = 0;

export const CHILDREN_OVER_TEN_YEARS_OLD = 1;
export const CHILDREN_BELOW_TEN_YEARS_OLD = 2;

export const SENIORITY_UNDER_SIX_MONTHS = 0.5;
export const SENIORITY_SIX_TO_TWELVE_MONTHS_MONTHS = 1;
export const SENIORITY_ONE_TO_TWO_YEARS_MONTHS = 2;
export const SENIORITY_OVER_TWO_YEARS_MONTHS = 3;

export const USE_PROFILE_LIST_POSITION_SKILL = 'profile_list_position_skill';
export const USE_PROFILE_LIST_SKILL = 'profile_list_skill';
export const USE_USER_PROFILE = 'user_profile';
export const USE_USER_ASSETS = 'user_assets';
export const USE_LIST_PROJECT = 'use_list_project';

export const USE_PROFILE_CATEGORY_CERTIFICATE = 'profile_category_certificate';
export const USE_PROFILE_LIST_CERTIFICATE = 'profile_list_certificate';
export const USE_OPTION_EDIT_PROFILE = 'user_option_edit_profile';

export const USE_PROFILE_USER_CERTIFICATE_DETAIL = 'profile_user_certificate_detail';
export const USE_STATUS_TEAM_DIVISION = 'use_status_team_division';
export const USE_GET_CAREER_STAGE = 'USE_GET_CAREER_STAGE';

export const NAME_FIELD_DIVISION = 'division';
export const NAME_CERTIFICATE_OTHER = 'different';

export const TYPE_CCCD = 1;
export const TYPE_CURRICULUM_VITAE = 2;
export const TYPE_HEALTH_CERTIFICATE = 3;
export const TYPE_DEGREE = 4;
export const TYPE_BIRTH_CERTIFICATE = 5;
export const TYPE_REGISTRATION_BOOK = 6;
export const TYPE_PASSPORT = 7;

export const TAB_TYPE_CCCD = 0;
export const TAB_TYPE_CURRICULUM_VITAE = 1;
export const TAB_TYPE_HEALTH_CERTIFICATE = 2;
export const TAB_TYPE_DEGREE = 3;
export const TAB_TYPE_BIRTH_CERTIFICATE = 4;
export const TAB_TYPE_REGISTRATION_BOOK = 5;
export const TAB_TYPE_PASSPORT = 6;

export const TYPE_RELATIVE_FATHER = 1;
export const TYPE_RELATIVE_MOTHER = 2;
export const TYPE_RELATIVE_WIFE = 3;
export const TYPE_RELATIVE_CHILD = 4;
export const TYPE_RELATIVE_SIBLINGS = 5;
export const HUSBAND = 6;

export const HR_TEAM = 0;
export const OTHER_TEAM = 1;
export const DATA_TEAM = 2;

export const NATIONALITY_NOT_SET = 0;
export const NATIONALITY_VIETNAM = 1;
export const NATIONALITY_FOREIGN = 2;
