import React, { useState, useEffect } from 'react';
import {
  ButtonBase,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  RadioGroup,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Controller, useForm } from 'react-hook-form';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import classNames from 'classnames';
import Zoom from '@mui/material/Zoom';

import TimeKeeping from '../index';
import { FILTER_BY_MODULE, handleOnError, getFilterModule } from 'helpers';
import { customDate } from 'helpers/formatDate.js';
import { safeParseJSON } from 'helpers/json';
import SearchComponent from 'components/Search';
import Loading from 'components/Loading';
import DateMonthMonthComponent from 'components/DateTime/DateMonth.js';
import DateDayMonthComponent from 'components/DateTime/DateDay.js';
import * as Constants from 'constants/index.js';
import * as StatusCode from 'constants/StatusCode';
import { convertFormatTimeSend } from 'views/pages/MasterData/SpecialTimekeeping/ultil';
import { useGetOtIndividual, useGetListTypeOfCategory } from 'hook/useTimeSheet';
import iconLoading from 'assets/img/icons/loading.png';
import './style.scss';
import TotalIcon from 'assets/img/icons/total-icon.svg';
import HourglassIcon from 'assets/img/icons/hourglass-icon.svg';
import CheckedNavyIcon from 'assets/img/icons/checked-navy.svg';
import calendarIcon from 'assets/img/icons/calendar-icon.svg';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import { 
  STATUS_REQUEST_OPEN,
  STATUS_REQUEST_IN_PROGRESS, 
  STATUS_REQUEST_REWORK,
  STATUS_REQUEST_COMPLETED, 
  STATUS_REQUEST_RECALL,
  STATUS_REQUEST_DENY,
} from 'constants/TimeKeeping';
import { LENGTH_TO_REMOVE_BORDER_TOOLTIP, LENGTH_TO_DISPLAY_TOOLTIP, CATEGORY_ID_LIST_OT } from 'constants/TimeSheet';
import { transferStatus, transferStatusToColor, transferStatusToBackgroundColor } from 'views/pages/TimeKeeping/Request/ultil';
import { TooltipContentOT, StyledTooltip } from '../../TimeKeeping/Request/TooltipInfo';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import Breadcrumb from '../Breadcrumb';
import { CONTROL_RADIO } from 'components/InputYupAndMui/constant';
import configs from 'config';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AlertComponent from 'components/Alert';
import { confirmAlert } from 'react-confirm-alert';
import { apiRecallRequest } from 'api/timesheet';
import { useQueryClient } from 'react-query';
import { USE_LIST_OT_INDIVIDUAL } from 'constants/TimeSheet';

function OtTimeKeepingIndividual() {
  const { t } = useTranslation();
  const { control, setValue } = useForm();
  const queryClient = useQueryClient();
  const [listTypeOfCategory, setListTypeOfCategory] = useState([]);
  const [isModals, setIsModals] = useState(false);
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);
  const params = useParams();
  const userId = params.userId;

  const [filter, setFilter] = useState({
    key_word: null,
    type: null,
    month: moment().format('MM/YYYY'),
    start_date: null,
    end_date: null,
    status: null,
    user_id: userId,
    page: Constants.DEFAULT_PAGE_MUI,
    per_page: Constants.DEFAULT_LIMIT,
    category_id: CATEGORY_ID_LIST_OT,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // eslint-disable-next-line no-unused-vars
  const [selectedValue, setSelectedValue] = useState();

  const { data: uListTypeOfCategory } = useGetListTypeOfCategory({
    category_id: CATEGORY_ID_LIST_OT,
  });

  function handleCheck(items, index) {
    let isCheckAll = true;
    if (index === 0) {
      isCheckAll = !items[0].status;
      items.forEach((item, ind) => (ind === 0 ? (item.status = isCheckAll) : (item.status = false)));
    } else {
      items.forEach(function (item, i) {
        if (((i !== index && item.status === false) || (i === index && item.status === true)) && i !== 0) {
          isCheckAll = false;
        }
      });
      if (isCheckAll) {
        items.forEach((item, ind) => (ind === 0 ? (item.status = true) : (item.status = false)));
      } else {
        items.forEach(function (item, i) {
          if (i === 0) {
            item.status = isCheckAll;
          } else if (index === i) {
            item.status = !item.status;
          }
        });
      }
    }

    return [...items];
  }

  const changeSelectListTypeOfCategory = (item) => {
    setListTypeOfCategory(function (listTypeOfCategory) {
      const index = listTypeOfCategory.indexOf(item);
      return handleCheck(listTypeOfCategory, index);
    });
  };

  useEffect(() => {
    if (uListTypeOfCategory) {
      const listTypeOfCategory = uListTypeOfCategory?.data?.data?.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));

      setListTypeOfCategory([
        {
          id: undefined,
          name: t('contract.all'),
          status: false,
          value: Constants.ALL,
        },
        ...listTypeOfCategory,
      ]);
    }
  }, [uListTypeOfCategory, t]);

  useEffect(() => {
    const currentValue = safeParseJSON(getFilterModule());
    if (currentValue && currentValue[FILTER_BY_MODULE.TIME_KEEPING_DETAIL]) {
      setFilter((prev) => ({
        ...prev,
        month: currentValue[FILTER_BY_MODULE.TIME_KEEPING_DETAIL].selectedMonth
      }));
    }
  }, [])

  const STATUS_REQUEST = [
    {
      value: STATUS_REQUEST_OPEN,
      labelName: t('timekeeping.request.statusOpen'),
    },
    {
      value: STATUS_REQUEST_IN_PROGRESS,
      labelName: t('timekeeping.request.statusInProgress'),
    },
    {
      value: STATUS_REQUEST_COMPLETED,
      labelName: t('timekeeping.request.statusCompleted'),
    },
    {
      value: STATUS_REQUEST_DENY,
      labelName: t('timekeeping.request.statusDeny'),
    },
    {
      value: STATUS_REQUEST_REWORK,
      labelName: t('timekeeping.request.statusRework'),
    },
    {
      value: STATUS_REQUEST_RECALL,
      labelName: t('timekeeping.request.statusRecall'),
    },
  ];

  const { data: uListOtIndividual, isLoading: isLoadingListOtIndividual } = useGetOtIndividual({
    ...filter,
    page: filter?.page + 1,
  });

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Filter by who send request
  const handleSearchNameWhoSend = (newValue) => {
    setFilter({
      ...filter,
      page: Constants.DEFAULT_PAGE_MUI,
      key_word: newValue,
    });
  };

  // Reset
  const handleReset = () => {
    setFilter({
      key_word: null,
      type: null,
      month: moment().format('MM/YYYY'),
      start_date: null,
      end_date: null,
      status: null,
      user_id: userId,
      page: Constants.DEFAULT_PAGE_MUI,
      per_page: Constants.DEFAULT_LIMIT,
      category_id: CATEGORY_ID_LIST_OT,
    });
    if (uListTypeOfCategory) {
      const listTypeOfCategory = uListTypeOfCategory?.data?.data?.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));

      setListTypeOfCategory([
        {
          id: undefined,
          name: t('contract.all'),
          status: false,
          value: Constants.ALL,
        },
        ...listTypeOfCategory,
      ]);
    }
    setValue('start_date', null);
    setValue('end_date', convertFormatTimeSend(new Date(), 'MM/DD/yyyy'));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const renderMenuItem = () => {
    return (
      <>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            confirmAlert({
              title: t('messages.warningDelete'),
              message: t('messages.confirmDeleteRequest'),
              buttons: [
                {
                  label: t('common.cancel'),
                  className: 'btn-alert-cancel',
                },
                {
                  label: t('common.ok'),
                  className: 'btn-alert-ok',
                  onClick: async () => {
                    const result = await apiRecallRequest(selectedValue.id);
                    if (result) {
                      setMessage(
                        result.data.code === StatusCode.HTTP_OK
                          ? t('timekeeping.request.removeSuccessfully')
                          : result.data.message,
                      );
                      setShowAlert(true);
                      queryClient.invalidateQueries(USE_LIST_OT_INDIVIDUAL);
                    }
                  },
                },
              ],
            });
          }}
        >
          <DeleteIcon />
          {t('timekeeping.request.reMove')}
        </MenuItem>
      </>
    );
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    setSelectedValue(item);
  };

  return (
    <>
      <Grid container className="list-assets request-timekeeping-individual">
        <Grid container className="nav-fixed nav-fixed-custom-top pt-0">
          <Grid className="menu-slider-around">
            <Breadcrumb />
            <TimeKeeping userId={userId} />
          </Grid>
        </Grid>
        <Grid item md={9} className="asset-main">
          <div className="profile-box table-list table-border-show">
            <Paper>
              <TableContainer className="scroll-custom">
                <Table aria-label="sticky table" className="table-scroll">
                  <TableHead className="asset-header">
                    <TableRow className="asset-header-row">
                      <TableCell className="short">{t('timekeeping.request.STT')}</TableCell>
                      <TableCell>{t('timekeeping.request.title')}</TableCell>
                      <TableCell>{t('timekeeping.request.dayRequest')}</TableCell>
                      <TableCell>{t('timekeeping.request.status')}</TableCell>
                      <TableCell>{t('timekeeping.request.createdDay')}</TableCell>
                      <TableCell className="short"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoadingListOtIndividual && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Loading addClass="mt-3 mb-3" />
                        </TableCell>
                      </TableRow>
                    )}
                    {uListOtIndividual &&
                      uListOtIndividual?.data?.data?.map((item, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <TableCell>
                              <a
                                href={`${configs.DOMAIN_WORK_FOLLOW}/request?request_id=${item?.id}`}
                                target="blank"
                                ref={(input) => {
                                  item.handelClick = input;
                                }}
                                className="link-work-fl"
                              >
                                {index + 1 > 9 ? <>{index + 1}</> : <>0{index + 1}</>}
                              </a>
                            </TableCell>
                            {/* Title */}
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              <div>
                                <span className="d-block bolder-500">
                                  {item.name ? item.name : t('common.notDataContract')}
                                </span>
                                <span className="added-info">
                                  {item.type_name ? item.type_name : t('common.notDataContract')}
                                </span>
                              </div>
                            </TableCell>
                            {/* Day request */}
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              <StyledTooltip
                                TransitionComponent={Zoom}
                                title={
                                  item?.overtime_date?.length > LENGTH_TO_DISPLAY_TOOLTIP && (
                                    <TooltipContentOT item={item?.overtime_date} />
                                  )
                                }
                                className={
                                  item?.overtime_date?.length < LENGTH_TO_REMOVE_BORDER_TOOLTIP &&
                                  'border-bottom-tooltip-none'
                                }
                              >
                                <section className="d-flex">
                                  <div>
                                    <span className="d-block bolder-500">
                                      {item?.overtime_date[0]
                                        ? item?.overtime_date[0]?.hr_date_log
                                        : t('common.notDataContract')}
                                    </span>
                                    <div className="added-info d-flex">
                                      <span className="mr-1 d-block">
                                        {item?.overtime_date[0]?.hr_start_time_log &&
                                          `${item?.overtime_date[0]?.hr_start_time_log} - ${item?.overtime_date[0]?.hr_end_time_log} `}
                                      </span>
                                    </div>
                                  </div>
                                </section>
                              </StyledTooltip>
                            </TableCell>
                            {/* Status */}
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              {item?.status ? (
                                <span className={classNames(transferStatusToColor(item?.status))}>
                                  {transferStatus(item?.status)}
                                </span>
                              ) : (
                                t('common.notDataContract')
                              )}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              {item.created_at ? item.created_at : t('common.notDataContract')}
                            </TableCell>
                            {item?.status === STATUS_REQUEST_COMPLETED ? (
                              <TableCell>
                                <div className="floatRight">
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls="long-menu"
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(event) => handleClick(event, item)}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </div>
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    {!uListOtIndividual?.total_request && !isLoadingListOtIndividual && (
                      <tr>
                        <td colSpan={5} className="error-no-data">
                          {t('common.notData')}
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={Constants.PER_PAGE_OPTION}
                component="div"
                count={uListOtIndividual?.total_request}
                rowsPerPage={filter?.per_page}
                page={filter?.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="table-pagination"
              />
            </Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: '220px',
                  width: '20ch',
                },
              }}
            >
              {renderMenuItem()}
            </Menu>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="dayOff">
            <img src={calendarIcon} alt="" />
            <div>
              <div className="label">{t('timekeeping.offHour')}</div>
              <div className="value">{uListOtIndividual?.day_off}</div>
            </div>
          </div>
          <div className="box-filter">
            <div className="info">
              <img
                onError={handleOnError}
                src={uListOtIndividual?.avatar ? uListOtIndividual.avatar : defaultAvatar}
                alt=""
              />
              <div>
                <div className="name">{uListOtIndividual?.user_name}</div>
                <div className="position">
                  {uListOtIndividual?.position} - {uListOtIndividual?.division}
                </div>
              </div>
            </div>
            <div className="filter-by-date">
              <DateMonthMonthComponent
                label=""
                name="month"
                valueDefault={typeof filter.month === 'string' ? moment(filter.month, 'MM/YYYY') : filter.month}
                onChangeHandle={(value) => {
                  setFilter({
                    ...filter,
                    month: customDate(value, 'MM/YYYY'),
                    page: Constants.DEFAULT_PAGE_MUI,
                  });
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                placeholder={t('contract.contractRetire.monthOut')}
                sizeDate="small"
              />
            </div>
            <div className="statistical">
              <div className="statistical-item special-item">
                <div className="d-flex align-items-center">
                  <img src={TotalIcon} alt="" />
                  <span className="statistical-item-title">{t('timekeeping.request.totalRequest')}</span>
                </div>
                <b className="statistical-item-title">{uListOtIndividual?.total_request}</b>
              </div>
              <div className="statistical-item hourglass-padding">
                <div className="d-flex align-items-center">
                  <img src={HourglassIcon} alt="" />
                  <span className="statistical-item-title">{t('timekeeping.OT.pendingProposals')}</span>
                </div>
                <b className="statistical-item-title">
                  {uListOtIndividual?.total_processing +
                    uListOtIndividual?.total_open +
                    uListOtIndividual?.total_rework}
                </b>
              </div>
              <div className="statistical-item">
                <div className="d-flex align-items-center">
                  <img src={CheckedNavyIcon} alt="" />
                  <span className="statistical-item-title">{t('timekeeping.OT.numberProposalsProcessed')}</span>
                </div>
                <b className="statistical-item-title">
                  {uListOtIndividual?.total_completed +
                    uListOtIndividual?.total_recall +
                    uListOtIndividual?.total_rejected}
                </b>
              </div>
            </div>
            <div className="filter-by-input">
              <div className="padding-element-filter">
                <SearchComponent
                  txtPlaceholder={t('employees.searchName')}
                  addClass="input-search-box"
                  defaultValue={filter.key_word}
                  handleSearch={handleSearchNameWhoSend}
                />
              </div>
              <div className={classNames(`text padding-element-filter`)}>
                <InputBase
                  disabled
                  type="text"
                  name="type"
                  className={classNames('exampleSelectMulti', listTypeOfCategory && 'text-input-dark')}
                  onClick={() => {
                    setIsModals((prev) => {
                      return { ...prev, ware_hourse: true };
                    });
                  }}
                  value={
                    listTypeOfCategory &&
                    (!listTypeOfCategory[0]?.status
                      ? listTypeOfCategory
                          .map((item, i) =>
                            item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                          )
                          .filter((it) => it)
                      : t('employees.all'))
                  }
                  placeholder={t('timekeeping.request.type')}
                />
                <ModalCommon
                  items={listTypeOfCategory}
                  onSubmit={() => {
                    let datas = [];
                    listTypeOfCategory.forEach((it) => {
                      it.status && it.id && datas.push(it.id);
                    });
                    setFilter({
                      ...filter,
                      type: datas,
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    setIsModals(false);
                  }}
                  onClick={changeSelectListTypeOfCategory}
                  toggle={() => {
                    setIsModals(!isModals);
                    listTypeOfCategory.forEach((item) => {
                      filter.type.indexOf(item.id) >= 0 || filter.type.length === listTypeOfCategory.length - 1
                        ? (item.status = true)
                        : (item.status = false);
                    });
                    setListTypeOfCategory([...listTypeOfCategory]);
                  }}
                  modal={isModals}
                  title={t('timekeeping.request.type')}
                  footer={t('common.search')}
                />
              </div>
              <div className={classNames(`text from-to`)}>
                <DateDayMonthComponent
                  label=""
                  name="start_date"
                  valueDefault={
                    typeof filter.start_date === 'string' ? moment(filter.start_date, 'DD/MM/YYYY') : filter.start_date
                  }
                  onChangeHandle={(value) => {
                    setFilter({
                      ...filter,
                      start_date: moment(value).format('DD/MM/YYYY'),
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  placeholder={t('timekeeping.request.dayStartRequest')}
                  sizeDate="small"
                />
                <div className="between">~</div>
                <DateDayMonthComponent
                  label=""
                  name="end_date"
                  valueDefault={
                    typeof filter.end_date === 'string' ? moment(filter.end_date, 'DD/MM/YYYY') : filter.end_date
                  }
                  onChangeHandle={(value) => {
                    setFilter({
                      ...filter,
                      end_date: moment(value).format('DD/MM/YYYY'),
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  placeholder={t('timekeeping.request.dayEndRequest')}
                  sizeDate="small"
                />
              </div>
            </div>

            <div>
              <FormControl component="fieldset">
                <FormLabel className={classNames(`text padding-element-filter padding-element-top title`)}>
                  {t('timekeeping.request.status')}
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field} value={filter?.status ? filter?.status : 0}>
                      <FormControlLabel
                        value={0}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            status: null,
                            page: Constants.DEFAULT_PAGE_MUI,
                          });
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                        control={CONTROL_RADIO}
                        label={t('employees.all')}
                      />
                      {STATUS_REQUEST?.map((item, index) => (
                        <div key={index} className="division d-flex justify-content-between">
                          <FormControlLabel
                            key={index}
                            value={item?.value}
                            onChange={(e) => {
                              setFilter({
                                ...filter,
                                status: e.target.value,
                                page: Constants.DEFAULT_PAGE_MUI,
                              });
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            control={CONTROL_RADIO}
                            label={item?.labelName}
                          />
                          <div className={classNames(`dot-status-accept ${transferStatusToBackgroundColor(item?.value)}`)}></div>
                        </div>
                      ))}
                    </RadioGroup>
                  )}
                  name="status"
                  control={control}
                  defaultValue={filter?.status ? filter?.status : STATUS_REQUEST_IN_PROGRESS}
                />
              </FormControl>
            </div>
            <div className="border-top">
              <ButtonBase onClick={() => handleReset()} className={classNames(`reset border-top`)} size="lg">
                <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                {t('common.reset')}
              </ButtonBase>
            </div>
          </div>
        </Grid>
      </Grid>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
}

export default OtTimeKeepingIndividual;
