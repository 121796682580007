import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import SwiperCore, { Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';
import 'swiper/swiper.scss';
import { useParams } from 'react-router';

import styles from './../style.module.scss';

import Achievement from './Achievement';
import CV from './CV';
import Certificates from './Certificates';
import Contract from './Contract';
import Document from './Document';
import Education from './Education';
import Information from './Infomation';
import Relative from './Relative';
import Skill from './Skill';

import LayoutProfile from './../../../../components/LayoutProfile';
import imgAvatar from '../../../../assets/img/theme/avatar.jpg';
import NavProfile from './../Nav.js';
import Breadcrumb from './../Breadcrumb.js';
import { useProfileUser } from 'hook/useProfileEmployee';
import { removeFilterModule } from 'helpers';
import Loading from 'components/Loading';
import { LOADING_MD } from 'constants/index.js';
import classNames from 'classnames';

SwiperCore.use([Navigation]);

const DATA_USER = [
  {
    avatar: imgAvatar,
    name: 'Trần Ngọc Long',
    email: 'long.tran2@amela.vn',
    position: 'Developer',
    manager: 'Nguyễn Thành Tâm',
    dayOfQuitAvai: '4',
    dayOfQuited: '3',
    momentOfLate: '5',
    timeOfLate: '160',
    OT: '200',
    tienPhat: 1000000,
    dateOfBirth: '22/02/2000',
    gender: 0,
    address: 'Tầng 5, Tháp A, Toà Keangnam, Khu Đô thị mới E6 Cầu Giấy, Phạm Hùng, Mễ Trì, Nam Từ Liêm, Hà Nội',
    married: 'Chưa kết hôn',
    level: 'Intern',
    phone: '0123456789',
    division: 'Bonne',
    group: 'Developer',
    codeOfTax: '8116049400',
    nowSalary: '5000000',
    dayOfNowSalary: '5/9/2021',
    typeOfContract: 'HĐLĐ xác định thời hạn',
    termContract: '6/11/2021',
    CCCD: '0000000000',
    CCCDLocation: 'Hà Nội',
    CCCDday: '16/5/2014',
    status: 1,
  },
];

const UserProfile = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const userId = params.userId;

  const { data: uProfileUser, isLoading: isLoadingProfileUser } = useProfileUser('', userId);
  const [contractNow, setContractNow] = useState({});

  useEffect(() => {
    if (uProfileUser?.data?.contract && uProfileUser?.data?.contract.length) {
      let contractNow = uProfileUser.data.contract.filter((item) => item?.active === 1);
      if (contractNow) {
        setContractNow(contractNow[0]);
      }
    }
  }, [uProfileUser]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      removeFilterModule();
    });
    return () => {
      window.removeEventListener('beforeunload', () => {
        removeFilterModule();
      });
    }
  }, [])

  return (
    <>
      <div className={styles.containerRoot}>
        <div className={styles.rowInfo}>
          {isLoadingProfileUser && <Loading addClass="mt-10" type={LOADING_MD} />}
          {uProfileUser && uProfileUser.data && (
            <>
              <Breadcrumb uProfileUser={uProfileUser} tabCurrent={t('infoEmployee.tabEmployeeInfo')} />
              <Row>
                <Col md="3">
                  <LayoutProfile uProfileUser={uProfileUser} filterUser={DATA_USER} />
                </Col>
                <Col md="9">
                  <NavProfile />
                  <div className={styles.contentRoot}>
                    <Row>
                      <Col md="12">
                        <Information uProfileUser={uProfileUser} contractNow={contractNow} />

                        <Relative uProfileUser={uProfileUser} userId={userId} />

                        <Contract uProfileUser={uProfileUser} userId={userId} isLoading={isLoadingProfileUser} />

                        <Education uProfileUser={uProfileUser} userId={userId} />

                        <Achievement uProfileUser={uProfileUser} userId={userId} />

                        <Certificates uProfileUser={uProfileUser} userId={userId} />

                        <Skill uProfileUser={uProfileUser} userId={userId} />

                        <CV uProfileUser={uProfileUser} userId={userId} />

                        <Document uProfileUser={uProfileUser} userId={userId} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {!isLoadingProfileUser && !uProfileUser && (
            <p className={classNames(`${styles.loading} text-center m-0`)}>{t('common.notData')}</p>
          )}
        </div>
      </div>
    </>
  );
};
export default UserProfile;
