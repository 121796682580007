import Loading from 'components/Loading';
import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Table } from 'reactstrap';
import avatarDefault from 'assets/img/icons/avatar_default.svg';
import { bubbleSortDESCByDate } from 'helpers';

const ListBirthDay = (props) => {
    let {isLoadingBirthday , listBirthday , checkIsBirthday} = props;
    const ref = useRef();
    const sortDescBirthday = bubbleSortDESCByDate(listBirthday?.data || []);
    
    useEffect(() => {
      executeScroll();
    });
    
    const executeScroll = () => {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    return (
        <div className="ct-scroll-table">
        {isLoadingBirthday && <Loading />}
        <Table className="align-items-center border-grey table-fixed table-hover">
          <tbody>
            {sortDescBirthday &&
              sortDescBirthday.map((itemBirthDay, index) => (
                <tr key={index} ref={checkIsBirthday(itemBirthDay.birthday) ? ref : null} className={`${checkIsBirthday(itemBirthDay.birthday)}`}>
                  <td className="">
                    <NavLink
                      to={{
                        pathname: '/admin/employees/profile/' + itemBirthDay.user_id,
                      }}
                    >
                      <div className="employee-info">
                        <div className="avatar ct-avatar">
                          <img
                            onError={(e) => {
                              e.target.src = avatarDefault;
                            }}
                            src={itemBirthDay.avatar ? itemBirthDay.avatar : avatarDefault}
                            alt={itemBirthDay.avatar ? itemBirthDay.avatar : avatarDefault}
                          />
                        </div>
                        <div className="name-division">
                          <p className="name">{itemBirthDay.name}</p>
                          <p className="division">
                            {itemBirthDay.position_name} - {itemBirthDay.division_name}
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  </td>
                  <td className="text-right text-date">{itemBirthDay.birthday}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
}

export default ListBirthDay;
