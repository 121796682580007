import { useQuery } from 'react-query';
import {
  apiGetTimeSheet,
  apiGetTotalTimeSheet,
  apiGetTimeSheetList,
  apiGetTimeSheetTotal,
  apiGetRequestIndividual,
  apiGetListTypeRequestIndividual,
  apiGetOtIndividual,
  apiGetListTypeOtIndividual,
  apiGetTotalTimekeepingYear,
  apiGetTotalTimekeepingMonth,
  apiGetLateTimeAllCompany,
  apiGetLateTimeOTMonthAllCompany,
  apiGetOverTImeProjectMonth,
  apiListTypeOfCategory,
  apiGetOTAllCompany,
  apiListCategoryEmployees,
  apiGetCheckIsLocked,
  apiGetSchedule,
  apiGetHolidayScheduleRequestLeader,
} from 'api/timesheet';
import { apiGetAllDivision } from 'api/masterData';
import { HTTP_OK } from 'constants/StatusCode';
import * as Constants from './../constants/TimeSheet';
import { apiGetScheduleTimeKeeping } from 'api/timesheet';

export function useListCategoryEmployees(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_TIMEKEEPING_LIST_CATEGORY_EMPLOYEES, params], async () => {
    const response = await apiListCategoryEmployees(params);
    return response.data;
  });
  return { ...response, error, isLoading };
}

export function useGetListTypeOfCategory(params) {
  const { data, error, isLoading } = useQuery(Constants.USE_LIST_TYPE_REQUEST_BY_CATEGORY, async () => {
    const response = await apiListTypeOfCategory(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useTimeSheet(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_TIME_SHEET, params],
    async () => {
      const response = await apiGetTimeSheet(params);
      if (response.status === HTTP_OK) {
        return response.data;
      }
      return null;
    },
    { enabled: params.userId && params.category_id[0] > 0 && params.category_id[1] > 0 ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useTotalTimeSheet(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_TOTAL_TIME_SHEET, params], async () => {
    const response = await apiGetTotalTimeSheet(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetTimeSheetList(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_TIME_SHEET_LIST, params], async () => {
    const response = await apiGetTimeSheetList(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetTimeSheetTotal(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_TIME_SHEET_TOTAL, params], async () => {
    const response = await apiGetTimeSheetTotal(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListAllDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_ALL_DIVISION, params], async () => {
    const response = await apiGetAllDivision(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetRequestIndividual(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_REQUEST_INDIVIDUAL, params], async () => {
    const response = await apiGetRequestIndividual(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetOtIndividual(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_OT_INDIVIDUAL, params], async () => {
    const response = await apiGetOtIndividual(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetListTypeRequestIndividual(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_TYPE_REQUEST_INDIVIDUAL, params], async () => {
    const response = await apiGetListTypeRequestIndividual(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useGetListTypeOtIndividual(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_TYPE_OT_INDIVIDUAL, params], async () => {
    const response = await apiGetListTypeOtIndividual(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useTotalTimekeepingYear(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_TIMEKEEPING_TOTAL_YEAR, params], async () => {
    if (params?.division_id === 0) delete params.division_id;

    const response = await apiGetTotalTimekeepingYear(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useTotalTimekeepingMonth(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_TIMEKEEPING_TOTAL_MONTH, params], async () => {
    const response = await apiGetTotalTimekeepingMonth(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useListLateTimeAllCompany(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_TIMEKEEPING_LATE_OT_COMPANY, params], async () => {
    const response = await apiGetLateTimeAllCompany(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useGetLateTimeOTMonthAllCompany(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_TIMEKEEPING_LATE_COMPANY, params], async () => {
    const response = await apiGetLateTimeOTMonthAllCompany(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useGetOverTImeProjectMonth(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_TIMEKEEPING_OVERTIME_PROJECT, params], async () => {
    const response = await apiGetOverTImeProjectMonth(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useGetOTAllCompany(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_OT_ALL_COMPANY, params], async () => {
    const response = await apiGetOTAllCompany(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useCheckIsLocked(params) {
  const { data, error, isLoading } = useQuery(Constants.USE_CHECK_IS_LOCKED, async () => {
    const response = await apiGetCheckIsLocked(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useGetSchedule(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_SCHEDULE, params],
    async () => {
      const response = await apiGetSchedule(params);
      return response;
    },
    { enabled: params.user_id ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetScheduleTimeKeeping(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GET_SCHEDULE, params],
    async () => {
      const response = await apiGetScheduleTimeKeeping(params);
      return response;
    },
    { enabled: params.user_id ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useGetHolidayScheduleRequestLeader(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_HOLIDAY_SCHEDULE_REQUEST_LEADER, params], async () => {
    const response = await apiGetHolidayScheduleRequestLeader(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}
