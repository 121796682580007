import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { WHEAT } from 'constants/ColorForm';
import { BG_POSITION } from 'constants/ColorMap';

function CustomCheckboxYup({ errors, register, control, name, defaultValue, label, onClick, isDisable }) {
  const handleClick = (event) => {
    onClick && onClick(event);
  };
  const [isDisableCheck, setIsDisableCheck] = useState(isDisable);

  useEffect(() => { setIsDisableCheck(isDisable) }, [isDisable])

  return (
    <Controller
      render={({ field: { onChange } }) => (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                onChange(e.target.checked);
                handleClick(e);
              }}
              sx={{
                color: WHEAT,
                '&.Mui-checked': {
                  color: BG_POSITION[0],
                },
              }}
              checked={defaultValue}
              disabled={isDisableCheck}
            />
          }
          label={label}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
}

export default CustomCheckboxYup;
