import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';
import { formatNumberCurrency } from 'helpers/format.js';
import { formatHourNumber } from 'helpers/format';

export const ItemInfo = ({ text, number, currency = false }) => {
  return (
    <li className="d-flex justify-content-between">
      <p className={styles.text}>{text}</p>
      <p className={styles.number}>{formatNumberCurrency(number)}</p>
    </li>
  );
};

export const ItemInfoFormatNumber = ({ text, number, currency = false }) => {
  return (
    <li className="d-flex justify-content-between">
      <p className={styles.text}>{text}</p>
      <p className={styles.number}>{formatHourNumber(number)}</p>
    </li>
  );
};

const InfoRight = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      <div className={classNames(`border-show ${styles.boxList}`)}>
        <ul className={styles.listInfo}>
          <ItemInfoFormatNumber text={t('timekeeping.dashboard.standardNumber')} number={data?.work_time} />
        </ul>
      </div>

      <div className={classNames(`border-show mt-3 ${styles.boxList}`)}>
        <ul className={styles.listInfo}>
          <ItemInfoFormatNumber text={t('timekeeping.dashboard.numberOfLatecomers')} number={data?.member_late} />
          <ItemInfoFormatNumber text={t('timekeeping.dashboard.numberOfMinutesLate')} number={data?.late_time} />
          <ItemInfo text={t('timekeeping.dashboard.fine(VND)')} number={data?.fines} currency={true} />
        </ul>
      </div>

      <div className={classNames(`border-show mt-3 ${styles.boxList}`)}>
        <ul className={styles.listInfo}>
          <ItemInfoFormatNumber text={t('timekeeping.dashboard.numberOfPeopleOT')} number={data?.member_over_time} />
          <ItemInfoFormatNumber
            text={t('timekeeping.dashboard.totalOT')}
            number={Number(data?.hour_over_time).toFixed(2)}
          />
        </ul>
      </div>

      <div className={classNames(`border-show mt-3 ${styles.boxList}`)}>
        <ul className={styles.listInfo}>
          <ItemInfo text={t('timekeeping.dashboard.onLeave')} number={data?.paid_leave} />
          <ItemInfo text={t('timekeeping.dashboard.unpaidLeave')} number={data?.unpaid_leave} />
        </ul>
      </div>

      <div className={classNames(`border-show mt-3 ${styles.boxList}`)}>
        <ul className={styles.listInfo}>
          <ItemInfo text={t('timekeeping.dashboard.totalRequest')} number={data?.all_request} />
          <ItemInfo text={t('timekeeping.dashboard.numberApproved')} number={data?.request_approve} />
          <ItemInfo text={t('timekeeping.dashboard.numberInProcess')} number={data?.processing_request} />
          <ItemInfo text={t('timekeeping.dashboard.numberCancel')} number={data?.reject_request} />
        </ul>
      </div>
    </div>
  );
};

export default InfoRight;
