import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './style.module.scss';

import classNames from 'classnames';

const NavComponent = (props) => {
  const { routes } = props;

  return (
    <div>
      <div>
        <ul className={styles.navbar}>
          {routes &&
            routes.map((route, key) => {
              return (
                <li key={key}>
                  <NavLink to={route.path} className={styles.link} activeClassName={styles.isActive}>
                    <span className={classNames(`${styles.navItem} text-bold`)}>{route.name}</span>
                  </NavLink>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default NavComponent;
