import { sendDelete, sendGet, sendPost, sendPut } from '../helpers/axios';
import config from './../config';

export const apiGetAssets = (params, userID) => sendGet(`/api/assets/user/${userID}`, params);
export const apiGetCareerStage = (params) => sendGet(`/api/user-group`, params);
export const apiGetProfile = (params, userID) => sendGet(`/api/user/${userID}`, params);
export const apiGetProfileTimeKeeping = (params, userID) => sendGet(`/api/time-sheet/user-info/${userID}`, params);
export const apiCreateEdu = (params) => sendPost(`/api/user-education`, params);
export const apiUpdateEdu = (params, educationId) => sendPut(`/api/user-education/${educationId}`, params);
export const apiDeleteEdu = (params, educationId) => sendDelete(`/api/user-education/${educationId}`, params);

export const apiDeleteCertificate = (certificateId) => sendDelete(`/api/user-certificate/${certificateId}`);

export const apiDeleteAchievement = (achievementId) => sendDelete(`/api/achievement/${achievementId}`);
export const apiCreateAchievement = (params) => sendPost('/api/achievement', params);
export const apiUpdateAchievement = (achievementId, params) => sendPut(`/api/achievement/${achievementId}`, params);

export const apiDeleteCV = (params, cvId) => sendDelete(`/api/user-curriculum-vitae/${cvId}`, params);
export const apiCreateCV = (params) => sendPost(`/api/user-curriculum-vitae`, params);
export const apiDownloadByLink = (link) => `${config.API_DOMAIN}/api/download-cv?link=${link}`;

export const apiListPositionKill = (params) => sendGet('api/user-skill/list-position', params);
export const apiListKillByPosition = (positionId, params) => sendGet(`api/user-skill/list-skill/${positionId}`, params);
export const apiUpdatesKill = (userId, params) => sendPut(`api/user-skill/${userId}`, params);
export const apiDeleteKill = (userId) => sendDelete(`api/user-skill/${userId}`);

export const apiCreateRelative = (params) => sendPost(`/api/user-children`, params);
export const apiUpdateRelative = (params, relativeId) => sendPut(`/api/user-children/${relativeId}`, params);
export const apiDeleteRelative = (params, relativeId) => sendDelete(`/api/user-children/${relativeId}`, params);

export const apiListCategoryCertificate = (params) => sendGet('/api/certificate-category', params);
export const apiListCertificate = (categoryCertificateId, params) =>
  sendGet(`api/certificate/${categoryCertificateId}`, params);
export const apiCreateCertificate = (params) => sendPost('/api/user-certificate', params);
export const apiUpdateCertificate = (params) => sendPost('/api/user-certificate/update', params);
export const apiGetCertificateDetail = (userCertificateId, params) =>
  sendGet(`/api/user-certificate/${userCertificateId}`, params);

export const apiUpdateInforUser = (params, userId) => sendPut(`/api/user/${userId}`, params);
export const apiCreateUser = (params) => sendPost(`/api/user/`, params);

export const apiImportUser = (params) => sendPost(`/api/import/user/`, params);
export const apiCreateUserFromStandby = (params) => sendPost(`/api/user-from-standby/`, params);

export const apiGetOptionEditProfile = (params) => sendGet(`/api/update-user`, params);

export const apiExportCCCD = (userId) => `${config.API_DOMAIN}/api/user/export/${userId}`;
export const apiCreateCCCD = (params) => sendPost('/api/identification', params);
export const apiUpdateCCCD = (params) => sendPost('/api/update-identification', params);
export const apiDeleteCCCD = (identificationId) => sendDelete(`/api/identification/${identificationId}`);

export const apiCreatePaper = (params) => sendPost('/api/create-image', params);
export const apiUpdatePaper = (params) => sendPost('/api/update-image', params);

export const apiCreateCareerStage = (params) => sendPost('/api/create/user-group', params);
export const apiDeleteCareerStage = (id, params) => sendDelete(`/api/delete/user-group/${id}`, params);

export const apiGetStatusTeamDivison = (params) =>
  sendGet(`/api/check-division-team?division_id=${params.division_id}`, params);
