import Axios from 'axios';
import config from './../config';
import { HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
// import { history } from "../Root";
import storageFC from './storage';

const axiosInstance = Axios.create({
  baseURL: config.API_DOMAIN,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${storageFC.getToken()}`;
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.location.href = config.API_DOMAIN_LOGIN;
    }

    if (error?.response?.status === HTTP_NOT_ACCESS && !error?.response?.data?.is_edit) {
      window.location.href = config.DOMAIN_APP_DOMAIN_AMS_BO + '/admin/page-not-access';
    }

    return error.response;
  },
);

export const sendGet = (url = '', params) => axiosInstance.get(url, { params }).then((res) => res);
export const sendPost = (url = '', params, queryParams) =>
  axiosInstance.post(url, params, { params: queryParams }).then((res) => res);
export const sendPut = (url = '', params) => axiosInstance.put(url, params).then((res) => res);
export const sendPatch = (url = '', params) => axiosInstance.patch(url, params).then((res) => res);
export const sendDelete = (url = '', params) => axiosInstance.delete(url, { data: params }).then((res) => res);
export const sendCustom = (params = {}) => axiosInstance(params).then((res) => res);
