import React from 'react';

import { optionDoughnut } from '../../../../variables/ctChats.js';
import DoughnutComponent from '../../../../components/Doughnut';
import './styles.scss';

const MapCategoryContract = (props) => {
  const { dataMap, colors } = props;

  const labelCategoryContract = dataMap?.map((item) => item.name);
  const dataCategoryContract = dataMap?.map((item) => item.total);

  const DoughnutDataCategoryContract = {
    labels: labelCategoryContract,
    datasets: [
      {
        data: dataCategoryContract,
        backgroundColor: colors,
        hoverOffset: 4,
      },
    ],
  };

  const totalCategoryContract = dataCategoryContract.reduce((a, v) => (a = Number(a) + Number(v)), 0);
  const optionPosition = optionDoughnut.options;

  return (
    <div>
      <div className="position-map box-map">
        <DoughnutComponent
          data={DoughnutDataCategoryContract}
          mapNote={dataMap}
          bgColor={colors}
          title=""
          total={totalCategoryContract}
          options={optionPosition}
          addClass="contract-dashboard-map"
          isBorderBottom={true}
        />
      </div>
    </div>
  );
};

export default MapCategoryContract;
