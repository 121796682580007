import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const YupValidateCreateAssets = (skipValidate) => {
  const { t } = useTranslation();
  const regexDate = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/g;
  const validationSchema = Yup.object().shape({
    asset_category_id: Yup.string().required(t('messages.thisIsARequiredField')),
    asset_rental_price_id: skipValidate ? '' : Yup.string().nullable().required(t('messages.thisIsARequiredField')),
    // Cho nhập chữ, số, cả kí tự đặc biệt
    code: Yup.string()
      .required(t('messages.thisIsARequiredField'))
      .matches(
        /^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]+$/,
        t('messages.assets.pleaseInsertValidCharacter'),
      ),
    name: Yup.string()
      .required(t('messages.assets.pleaseInsertNameAssets'))
      .matches(/.*[^ ].*/, t('messages.assets.pleaseInsertNameAssets')),
    price: Yup.string().required(t('messages.thisIsARequiredField')).nullable(),
    period: Yup.string().required(t('messages.thisIsARequiredField')).nullable(),
    priceRent: Yup.string().required(t('messages.thisIsARequiredField')).nullable(),
    warehouse_id: Yup.string().required(t('messages.thisIsARequiredField')),
    input_date: Yup.string()
      .nullable()
      .required(t('messages.thisIsARequiredField'))
      .matches(regexDate, t('messages.pleaseEnterTheCorrectFormatDate')),
    out_date: Yup.string()
      .nullable()
      .required(t('messages.thisIsARequiredField'))
      .matches(regexDate, t('messages.pleaseEnterTheCorrectFormatDate')),
    type: Yup.string().required(t('messages.thisIsARequiredField')),
  });
  return validationSchema;
};

export const YupValidateEditAssets = (skipValidate = false) => {
  return YupValidateCreateAssets(skipValidate);
};

export const YupValidateRecall = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    content: Yup.string()
      .matches(/^(?!\s+$)/g, t('messages.pleaseEnterTheReasonForTheAssetRecovery'))
      .required(t('messages.pleaseEnterTheReasonForTheAssetRecovery'))
      .max(30, t('messages.maxLength30')),
  });
  return validationSchema;
};
