import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  FormGroup,
  Input,
  Col,
  List,
  Button,
  Table,
  ModalFooter,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import $ from 'jquery';
import { IoIosAdd } from 'react-icons/io';
import { toasts } from '../../../../index';
import { BiLinkExternal } from 'react-icons/bi';

import { Grid } from '@mui/material';

import radio from 'assets/img/icons/radio.png';
import radioChecked from 'assets/img/icons/radio-checked.png';
import iconLoading from 'assets/img/icons/loading.png';
import iconMenuTotal from 'assets/img/icons/menu-total.png';
import iconProfileNotCompleted from 'assets/img/icons/profile-not-completed.png';
import Employees from './../index.js';
import * as Constants from 'constants/ListEmployees';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from 'constants/index.js';
import { FILTER_BY_MODULE, handleExportExcel, getFilterModule, storeFilterModule, removeFilterModule } from 'helpers';
import { safeParseJSON } from 'helpers/json';
import {
  GENDER_MALE,
  GENDER_FEMALE,
  STATUS_ACTIVE,
  STATUS_UNACTIVE,
  CHILDREN_OVER_TEN_YEARS_OLD,
  CHILDREN_BELOW_TEN_YEARS_OLD,
  SENIORITY_UNDER_SIX_MONTHS,
  SENIORITY_SIX_TO_TWELVE_MONTHS_MONTHS,
  SENIORITY_ONE_TO_TWO_YEARS_MONTHS,
  SENIORITY_OVER_TWO_YEARS_MONTHS,
} from 'constants/ProfileEmployee';
import ModalCommon from 'components/ModalCommon/ModalCommon.js';
import ModalAddEmployee from './modalAddEmployee';
import ModalImportEmployee from './modalImportEmployee';
import PerPage from 'components/PerPage';
import styles from './style.module.scss';
import {
  useListUser,
  useListPosition,
  useListLevel,
  useListSkill,
  useListCertificate,
  useListDivision,
} from 'hook/useEmployees';
import { ThemContext } from 'layouts/Admin';
import SearchComponent from 'components/Search';
import Loading from 'components/Loading';
import classNames from 'classnames';
import { ROLE_VIEW, ROLE_NOT_VIEW, DISABLE_BUTTON_ROLE } from 'constants/index.js';
import { customDate } from 'helpers/formatDate.js';
import { NOT_DATA } from 'constants/index.js';
import CreateContractModal from '../../Contracts/Contract/Modals/CreateContract.js';
import AlertComponent from 'components/Alert';
import moment from 'moment';

const DATA_ALL = { status: false, name: 'Tất cả', id: Constants.ALL, value: Constants.ALL };

const ListEmployees = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInfoUser = useContext(ThemContext);
  const history = useHistory()
  const [usersLoaded, setUsersLoaded] = useState([])

  let location = useLocation();
  const datas = location?.state;

  useEffect(() => {
    DATA_SENIORITY.forEach((item) => {
      if (item.value === datas?.filterSeniority) {
        item.status = true;
      }
    });
    let requestSeniority = [];
    setFilterSeniority([...DATA_SENIORITY]);
    DATA_SENIORITY.find((data) => data.status) &&
      DATA_SENIORITY.forEach((item) => {
        item.status && item.name !== t('employees.all') && requestSeniority.push(item.value);
      });
    setFilter((prev) => {
      return { ...prev, seniority: requestSeniority };
    });
    // eslint-disable-next-line
  }, []);
  const DATA_FIELD_EXPORT = [
    { status: false, name: t('employees.all'), value: Constants.ALL },
    { status: true, name: t('employees.codeEmployee'), value: 'code' },
    { status: true, name: t('common.fullName'), value: 'name' },
    { status: true, name: t('employees.email'), value: 'email' },
    { status: false, name: t('common.divison'), value: 'division' },
    { status: false, name: t('common.birthday'), value: 'birthday' },
    { status: false, name: t('employees.gender'), value: 'gender' },
    { status: false, name: t('employees.dateOnboard'), value: 'date_onboard' },
    { status: false, name: t('infoEmployee.codeTax'), value: 'tax_code' },
    { status: false, name: t('infoEmployee.CCCD'), value: 'card_id' },
    { status: false, name: t('employees.phone'), value: 'phone' },
    { status: false, name: t('employees.level'), value: 'level' },
    { status: false, name: t('employees.office'), value: 'office' },
    { status: false, name: t('employees.position'), value: 'position' },
    { status: false, name: t('employees.skill'), value: 'skill' },
    { status: false, name: t('employees.status'), value: 'status' },
    { status: false, name: t('employees.contract'), value: 'contract_category' },
    { status: false, name: t('timekeeping.workTime'), value: 'work_time' },
    { status: false, name: t('infoEmployee.childrent'), value: 'children' },
    { status: false, name: t('infoEmployee.titleEducation'), value: 'education' },
    { status: false, name: t('masterData.certificate'), value: 'certificate' },
    { status: false, name: t('masterData.achievement'), value: 'achievement' },
    { status: false, name: t('employees.officialDate'), value: 'start_contract_official' },
    { status: false, name: t('employees.startContractOff'), value: 'start_contract_probation' },
    { status: false, name: t('employees.checkout'), value: 'checkout' },
  ];

  const DATA_GENDER = [
    { status: false, name: t('employees.all'), value: Constants.ALL },
    { status: false, name: t('infoEmployee.genderMale'), value: GENDER_MALE },
    { status: false, name: t('infoEmployee.genderFemale'), value: GENDER_FEMALE },
  ];

  const DATA_STATUS = [
    { status: false, name: t('employees.all'), value: Constants.ALL },
    { status: true, name: t('infoEmployee.statusActive'), value: STATUS_ACTIVE },
    { status: false, name: t('infoEmployee.statusUnactive'), value: STATUS_UNACTIVE },
  ];

  const DATA_SENIORITY = [
    { status: false, name: t('employees.all'), value: Constants.ALL },
    { status: false, name: t('employees.LessThan6Months'), value: SENIORITY_UNDER_SIX_MONTHS },
    { status: false, name: t('employees.6-12Months'), value: SENIORITY_SIX_TO_TWELVE_MONTHS_MONTHS },
    { status: false, name: t('employees.1-2Years'), value: SENIORITY_ONE_TO_TWO_YEARS_MONTHS },
    { status: false, name: t('employees.Over2Years'), value: SENIORITY_OVER_TWO_YEARS_MONTHS },
  ];

  const DATA_EMPLOYEE_CHILDREN_GENDER = [
    { status: false, name: t('employees.all'), value: Constants.ALL },
    { status: false, name: t('infoEmployee.genderMale'), value: GENDER_MALE },
    { status: false, name: t('infoEmployee.genderFemale'), value: GENDER_FEMALE },
  ];

  const DATA_EMPLOYEE_CHILDREN_AGE = [
    { status: false, name: t('employees.all'), value: Constants.ALL },
    { status: false, name: t('employees.overTenYearsOld'), value: CHILDREN_OVER_TEN_YEARS_OLD },
    { status: false, name: t('employees.bellowTenYearsOld'), value: CHILDREN_BELOW_TEN_YEARS_OLD },
  ];

  const DATA_PROFILE = { status: false, value: '1' };

  const [checkedAll, setCheckedAll] = useState(false);
  const [userChecked, setUserChecked] = useState([]);
  const [perPage, setPerPage] = useState(DEFAULT_LIMIT);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [isShowModalCreate, setShowModalCreate] = useState(false);
  const [dataUserCreate, setDataUserCreate] = useState({});
  const [dataFilterBackup, setDataFilterBackup] = useState({});

  const [filter, setFilter] = useState({
    page,
    perPage,
    status: STATUS_ACTIVE,
  });

  const { data: uListUser, isLoading: isLoadingListUser } = useListUser(filter);

  const { data: uListPosition } = useListPosition();
  const { data: uListLevel } = useListLevel();
  const { data: uListSkill } = useListSkill();
  const { data: uListCertificate } = useListCertificate();
  const { data: uListDivision, isLoading: isLoadingListDivision } = useListDivision({
    ...filter,
    page: null,
    perPage: null,
  });

  const [filterCategoryEmployees, setFilterCategoryEmployees] = useState([]);
  const [categoryEmployeeSelect, setCategoryEmployeeSelect] = useState(Constants.ALL);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const [filterPosition, setFilterPosition] = useState([]);
  const [filterSkill, setFilterSkill] = useState([]);
  const [filterDivision, setFilterDivision] = useState([]);
  const [filterLevel, setFilterLevel] = useState([]);
  const [filterCertificate, setFilterCertificate] = useState([]);
  const [filterProfile, setFilterProfile] = useState(DATA_PROFILE);
  const [keyWord, setKeyWord] = useState('');
  const [divisionSelect, setDivisionSelect] = useState(Constants.ALL);

  const changeCategoryEmployeesRadio = function (item) {
    if (item === Constants.ALL) {
      setCategoryEmployeeSelect(Constants.ALL);
      handleSubmit('', '', '', '', '', '', '', '', Constants.ALL);
    } else {
      setCategoryEmployeeSelect(item.group_id);
      handleSubmit('', '', '', '', '', '', '', '', item.group_id);
    }
  };

  const changeSelectFilterPosition = (item) =>
    setFilterPosition(function (filterPosition) {
      const index = filterPosition.indexOf(item);
      return handleCheck(filterPosition, index);
    });

  const changeSelectFilterSkill = (item) =>
    setFilterSkill(function (filterSkill) {
      const index = filterSkill.indexOf(item);
      return handleCheck(filterSkill, index);
    });

  const changeSelectFilterLevel = (item) =>
    setFilterLevel(function (filterLevel) {
      const index = filterLevel.indexOf(item);
      return handleCheck(filterLevel, index);
    });

  const changeSelectFilterCertificate = (item) =>
    setFilterCertificate(function (filterCertificate) {
      const index = filterCertificate.indexOf(item);
      return handleCheck(filterCertificate, index);
    });

  useEffect(() => {
    if (uListUser?.data?.data) {
      let isCheckedAll = true;
      const userIds = usersLoaded.map((user) => user.user_id);
      uListUser?.data?.data?.forEach((it) => {
        if (!userIds.includes(it.user_id)) {
          usersLoaded.push(it);
        }
        if (userChecked.indexOf(it.user_id) < NOT_DATA) {
          isCheckedAll = false;
          return;
        }
      });
      setUsersLoaded(usersLoaded);
      setCheckedAll(isCheckedAll);
    }
  // eslint-disable-next-line
  }, [uListUser, userChecked]);

  useEffect(() => {
    if (uListPosition) {
      uListPosition.forEach((item) => {
        item.status = false;
      });
      uListPosition.unshift(JSON.parse(JSON.stringify(DATA_ALL)));
      setFilterPosition(uListPosition);
    }
  }, [uListPosition]);

  useEffect(() => {
    if (uListSkill) {
      uListSkill.forEach((item) => {
        item.status = false;
      });
      uListSkill.unshift(JSON.parse(JSON.stringify(DATA_ALL)));
      setFilterSkill(uListSkill);
    }
  }, [uListSkill]);
  useEffect(() => {
    if (uListLevel) {
      uListLevel.forEach((item) => {
        item.status = false;
      });
      uListLevel.unshift(JSON.parse(JSON.stringify(DATA_ALL)));
      setFilterLevel(uListLevel);
    }
  }, [uListLevel]);
  useEffect(() => {
    if (uListCertificate) {
      uListCertificate.forEach((item) => {
        item.status = false;
      });
      uListCertificate.unshift(JSON.parse(JSON.stringify(DATA_ALL)));
      setFilterCertificate(uListCertificate);
    }
  }, [uListCertificate]);

  useEffect(() => {
    if (uListDivision && Array.isArray(uListDivision?.divisions)) {
      uListDivision?.divisions?.forEach((item) => {
        item.status = false;
      });
      setFilterDivision(uListDivision?.divisions);
    }

    if (uListDivision && Array.isArray(uListDivision?.groups)) {
      uListDivision?.groups?.forEach((item) => {
        item.status = false;
      });
      setFilterCategoryEmployees(uListDivision?.groups);
    }
  }, [uListDivision]);

  useEffect(() => {
    const currentValue = safeParseJSON(getFilterModule())
    if (currentValue && currentValue[FILTER_BY_MODULE.LIST_EMPLOYEES]) {
      const filter = currentValue[FILTER_BY_MODULE.LIST_EMPLOYEES]
      setCategoryEmployeeSelect(filter.group_id || Constants.ALL)
      setDivisionSelect(filter.division_id || Constants.ALL)
      setKeyWord(filter.name || '')
      setFilter(filter)
    }
    window.addEventListener('beforeunload', () => {
      removeFilterModule();
    });
    return () => {
      if (!history.location.pathname.includes('/employees/profile')) {
        removeFilterModule()
      }
      window.removeEventListener('beforeunload', () => {
        removeFilterModule();
      });
    }
    // eslint-disable-next-line
  }, [])

  const changeRadio = function (item) {
    if (item === Constants.ALL) {
      setDivisionSelect(Constants.ALL);
      handleSubmit('', '', '', '', Constants.ALL);
    } else {
      setDivisionSelect(item.id);
      handleSubmit('', '', '', '', item.id);
    }
  };

  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeKeyword = (value) => {
    setKeyWord(value);
    handleSubmit('', '', { status: true, value: value });
  }

  const changePerPage = (e) => {
    const { value } = e.target
    setPerPage(value);
    setPage(DEFAULT_PAGE);
    setFilter({
      ...filter,
      perPage: typeof value === 'string' && value ? value : perPage,
    })
  };

  const changePage = (value) => {
    if (value) {
      setPage(value);
      handleSubmit('', '', '', value);
    }
  };

  const modalDefault = {
    importEmployee: false,
    import: false,
    addEmployee: false,
    export: false,
    employeeChildren: false,
    gender: false,
    seniority: false,
    position: false,
    status: false,
    skill: false,
    level: false,
    certificate: false,
  };

  const getDataFilter = (items, request) => {
    if (items?.find((item) => item.name === t('employees.all') && item.status)) {
      items.forEach((item) => {
        return item.name !== t('employees.all') && request.push(item.id || item.value);
      });
      return;
    }
    items?.forEach((item) => {
      return item.status && item.name !== t('employees.all') && request.push(item.id || item.value);
    });
  };

  const handleSubmit = async (
    e = '',
    targetPerPage = '',
    targetSearchKey = '',
    pageChange = '',
    divisionChange = null,
    reset = '',
    handleExport = '',
    profile = '',
    categoryEmployeeChange = null,
  ) => {
    const requestPosition = [];
    const requestLevel = [];
    const requestSkill = [];
    const requestCertificate = [];
    const requestSeniority = [];
    const exportColumns = [];
    var requestProfile = '';

    if (profile) {
      requestProfile = profile.status ? profile.value : null;
    } else {
      requestProfile = filterProfile.status ? filterProfile.value : null;
    }

    if (reset) {
      setFilterSeniority(JSON.parse(JSON.stringify(DATA_SENIORITY)));
      setFilterStatus(JSON.parse(JSON.stringify(DATA_STATUS)));
      setFilterGender(JSON.parse(JSON.stringify(DATA_GENDER)));
      setFilterEmployeeChildrenGender(JSON.parse(JSON.stringify(DATA_EMPLOYEE_CHILDREN_GENDER)));
      setFilterEmployeeChildrenAge(JSON.parse(JSON.stringify(DATA_EMPLOYEE_CHILDREN_AGE)));
      setKeyWord('');
      setDivisionSelect(Constants.ALL);
      setCategoryEmployeeSelect(Constants.ALL);
      setFilterPosition(function (filterPosition) {
        filterPosition.forEach(function (item) {
          item.status = false;
        });
        return [...filterPosition];
      });
      setFilterSkill(function (filterSkill) {
        filterSkill.forEach(function (item) {
          item.status = false;
        });
        return [...filterSkill];
      });
      setFilterLevel(function (filterLevel) {
        filterLevel.forEach(function (item) {
          item.status = false;
        });
        return [...filterLevel];
      });
      setFilterCertificate(function (filterCertificate) {
        filterCertificate.forEach(function (item) {
          item.status = false;
        });
        return [...filterCertificate];
      });

      setFilterDivision(function (filterDivision) {
        filterDivision.forEach(function (item) {
          item.status = false;
        });
        return [...filterDivision];
      });
      setFilterCategoryEmployees(function (filterCategoryEmployees) {
        filterCategoryEmployees.forEach(function (item) {
          item.status = false;
        });
        return [...filterCategoryEmployees];
      });
      setFilterProfile(DATA_PROFILE);
      setFilter({
        office_id: null,
        level_id: null,
        skill: null,
        division: null,
        certificate: null,
        status: STATUS_ACTIVE,
        gender: null,
        user: null,
        profile: null,
        contract: null,
        children_gender: null,
        children_age: null,
        group_id: null,
        perPage: perPage,
        page: 1,
      });
      setUserChecked([]);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      getDataFilter(filterLevel, requestLevel);
      getDataFilter(filterPosition, requestPosition);
      getDataFilter(filterSkill, requestSkill);
      getDataFilter(filterCertificate, requestCertificate);
      getDataFilter(filterSeniority, requestSeniority);

      if (handleExport === 'export') {
        fieldExport &&
          fieldExport.forEach((item) => {
            return item.status && item.name !== t('employees.all') && exportColumns.push(item.value);
          });

        const data = {
          column: exportColumns,
          user_id: userChecked,
        };
        handleExportExcel('/api/user/export/excel', {...data, ...filter}, t('employees.nameFileExport'));
      } else {
        let status = null;
        let gender = null;
        let chilGender = null;
        let chilAge = null;

        filterStatus.find((data) => !data.status) &&
          filterStatus &&
          filterStatus.forEach((item) => {
            if (item.status && item.name !== t('employees.all')) status = item.value;
          });
        filterGender.find((data) => !data.status) &&
          filterGender &&
          filterGender.forEach((item) => {
            if (item.status && item.name !== t('employees.all')) gender = item.value;
          });
        filterEmployeeChildrenGender.find((data) => !data.status) &&
          filterEmployeeChildrenGender &&
          filterEmployeeChildrenGender.forEach((item) => {
            if (item.status && item.name !== t('employees.all')) chilGender = item.value;
          });
        filterEmployeeChildrenAge.find((data) => !data.status) &&
          filterEmployeeChildrenAge &&
          filterEmployeeChildrenAge.forEach((item) => {
            if (item.status && item.name !== t('employees.all')) chilAge = item.value;
          });

        setFilter({
          office_id: requestPosition.length > 0 ? requestPosition : null,
          level_id: requestLevel.length > 0 ? requestLevel : null,
          skill: requestSkill.length > 0 ? requestSkill : null,
          division_id: divisionChange
            ? divisionChange === Constants.ALL
              ? null
              : divisionChange
            : divisionSelect === Constants.ALL
            ? null
            : divisionSelect,
          group_id: categoryEmployeeChange
            ? categoryEmployeeChange === Constants.ALL
              ? null
              : categoryEmployeeChange
            : categoryEmployeeSelect === Constants.ALL
            ? null
            : categoryEmployeeSelect,
          certificate_category_id: requestCertificate.length > 0 ? requestCertificate : null,
          status: status,
          gender: gender,
          profile: requestProfile,
          name: targetSearchKey?.status ? targetSearchKey.value : keyWord ? keyWord : null,
          seniority: requestSeniority,
          children_gender: chilGender,
          children_age: chilAge,
          perPage: typeof targetPerPage === 'string' && targetPerPage ? targetPerPage : perPage,
          page: pageChange ? pageChange : targetPerPage ? page : 1,
        });
        if (!pageChange) {
          setUserChecked([])
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
    setModalCheckField(modalDefault);
  };

  const [fieldExport, setFieldExport] = useState(DATA_FIELD_EXPORT);

  function handleCheck(items, index) {
    let isCheckAll = true;
    if (index === 0) {
      isCheckAll = !items[0].status;
      items.forEach((item, ind) => (ind === 0 ? (item.status = isCheckAll) : (item.status = false)));
    } else {
      items.forEach(function (item, i) {
        if (((i !== index && item.status === false) || (i === index && item.status === true)) && i !== 0) {
          isCheckAll = false;
        }
      });
      if (isCheckAll) {
        items.forEach((item, ind) => (ind === 0 ? (item.status = true) : (item.status = false)));
      } else {
        items.forEach(function (item, i) {
          if (i === 0) {
            item.status = isCheckAll;
          } else if (index === i) {
            item.status = !item.status;
          }
        });
      }
    }

    return [...items];
  }

  const changeSelectExport = (item) => {
    setFieldExport(function (fieldExport) {
      const index = fieldExport.indexOf(item);
      return handleCheck(fieldExport, index);
    });
  };

  const [filterStatus, setFilterStatus] = useState(JSON.parse(JSON.stringify(DATA_STATUS)));

  const changeSelectFilterStatus = (item) =>
    setFilterStatus(function (filterStatus) {
      const index = filterStatus.indexOf(item);
      return handleCheck(filterStatus, index);
    });

  const [filterGender, setFilterGender] = useState(JSON.parse(JSON.stringify(DATA_GENDER)));

  const changeSelectFilterGender = (item) =>
    setFilterGender(function (filterGender) {
      const index = filterGender.indexOf(item);
      return handleCheck(filterGender, index);
    });

  const [filterSeniority, setFilterSeniority] = useState(JSON.parse(JSON.stringify(DATA_SENIORITY)));

  const changeSelectFilterSeniority = (item) =>
    setFilterSeniority(function (filterSeniority) {
      const index = filterSeniority.indexOf(item);
      return handleCheck(filterSeniority, index);
    });

  const [filterEmployeeChildrenGender, setFilterEmployeeChildrenGender] = useState(
    JSON.parse(JSON.stringify(DATA_EMPLOYEE_CHILDREN_GENDER)),
  );
  const [filterEmployeeChildrenAge, setFilterEmployeeChildrenAge] = useState(
    JSON.parse(JSON.stringify(DATA_EMPLOYEE_CHILDREN_AGE)),
  );

  const changeRadioChilGender = (item) =>
    setFilterEmployeeChildrenGender(function (filterEmployeeChildrenGender) {
      const indexOfitem = filterEmployeeChildrenGender.indexOf(item);
      filterEmployeeChildrenGender.map((item, index) =>
        index === indexOfitem ? (item.status = true) : (item.status = false),
      );
      return [...filterEmployeeChildrenGender];
    });

  const changeRadioChilAge = (item) =>
    setFilterEmployeeChildrenAge(function (filterEmployeeChildrenAge) {
      const indexOfitem = filterEmployeeChildrenAge.indexOf(item);
      filterEmployeeChildrenAge.map((item, index) =>
        index === indexOfitem ? (item.status = true) : (item.status = false),
      );
      return [...filterEmployeeChildrenAge];
    });

  const [modalCheckField, setModalCheckField] = useState(modalDefault);

  const onDisplayValueChecked = (items, itemChecked) => {
    if (Array.isArray(itemChecked)) {
      if (items[0]?.status) {
        items.forEach((item) => {
          if (item.value === Constants.ALL) {
            item.status = true;
          } else {
            item.status = false;
          }
        });
      } else {
        if (itemChecked.length) {
          items.forEach((item, index) => {
            if (item.status && index === 0 && item.value === Constants.ALL) {
              item.status = true;
            } else if (itemChecked.includes(item.id)) {
              item.status = true;
            }
          });
        }
      }
    } else {
      items.forEach((item) => {
        if (itemChecked || itemChecked === 0) {
          if (itemChecked === item.value) {
            item.status = true;
          } else {
            item.status = false;
          }
        }
      });
    }
  };

  const toggleCheckField = (nameField) => {
    onDisplayValueChecked(filterPosition, filter.office_id);
    onDisplayValueChecked(filterSkill, filter.skill);
    onDisplayValueChecked(filterLevel, filter.level_id);
    onDisplayValueChecked(filterCertificate, filter.certificate_category_id);
    onDisplayValueChecked(filterSeniority, filter.seniority);
    onDisplayValueChecked(filterGender, filter.gender);
    onDisplayValueChecked(filterStatus, filter.status);

    setModalCheckField(function (modal) {
      if (nameField === Constants.EXPORT) {
        modal.export = !modal.export;
      } else if (nameField === Constants.IMPORT) {
        modal.importEmployee = !modal.importEmployee;
      } else if (nameField === Constants.ADD_EMPLOYEE) {
        modal.addEmployee = !modal.addEmployee;
      } else if (nameField === Constants.EMPLOYEE_CHILDREN) {
        setDataFilterBackup({
          age: JSON.parse(JSON.stringify(filterEmployeeChildrenAge)),
          gender: JSON.parse(JSON.stringify(filterEmployeeChildrenGender)),
        });
        modal.employeeChildren = !modal.employeeChildren;
      } else if (nameField === Constants.GENDER) {
        setDataFilterBackup(JSON.parse(JSON.stringify(filterGender)));
        modal.gender = !modal.gender;
      } else if (nameField === Constants.SENIORITY) {
        setDataFilterBackup(JSON.parse(JSON.stringify(filterSeniority)));
        modal.seniority = !modal.seniority;
      } else if (nameField === Constants.POSITION) {
        setDataFilterBackup(JSON.parse(JSON.stringify(filterPosition)));
        modal.position = !modal.position;
      } else if (nameField === Constants.STATUS) {
        setDataFilterBackup(JSON.parse(JSON.stringify(filterStatus)));
        modal.status = !modal.status;
      } else if (nameField === Constants.SKILL) {
        setDataFilterBackup(JSON.parse(JSON.stringify(filterSkill)));
        modal.skill = !modal.skill;
      } else if (nameField === Constants.LEVEL) {
        setDataFilterBackup(JSON.parse(JSON.stringify(filterLevel)));
        modal.level = !modal.level;
      } else if (nameField === Constants.CERTIFICATE) {
        setDataFilterBackup(JSON.parse(JSON.stringify(filterCertificate)));
        modal.certificate = !modal.certificate;
      }
      return { ...modal };
    });
  };

  const TotalListEmployees = (value) =>
    setFilterProfile(function (filterProfile) {
      filterProfile.status = value;
      handleSubmit('', '', '', '', [], '', '', { ...filterProfile });
      return { ...filterProfile };
    });

  $(function scroll() {
    var newWidth = $('table').width();
    $('.top').width(newWidth);
    $('.wrapper-top').scroll(function () {
      $('.wrapper-bottom').scrollLeft($('.wrapper-top').scrollLeft());
    });
    $('.wrapper-bottom').scroll(function () {
      $('.wrapper-top').scrollLeft($('.wrapper-bottom').scrollLeft());
    });
  });

  var px_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;

  $(window).resize(function () {
    isZooming();
  });

  function isZooming() {
    var newPx_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
    if (newPx_ratio === px_ratio) {
      var newWidth = $('table').width();
      $('.top').width(newWidth);
    }
  }

  const checkAll = (e) => {
    let data = [];
    setCheckedAll(e.target.checked);
    if (e.target.checked) {
      uListUser?.data?.data.forEach((item) => {
        data.push(item.user_id);
      });
      userChecked?.forEach((id) => {
        data.push(id);
      });
    }
    setUserChecked([...data]);
  };

  const handleCheckBox = (item) => {
    if (userChecked.indexOf(item.user_id) < NOT_DATA) {
      userChecked.push(item.user_id);
      setUserChecked([...userChecked]);
    } else {
      let data = [];
      userChecked.forEach((it) => {
        if (it !== item.user_id) {
          data.push(it);
        }
      });
      setUserChecked([...data]);
    }
  };

  const handleRequest = async (e) => {
    if (dataInfoUser?.hr_permission?.users_permission === ROLE_VIEW) {
      e.preventDefault();
      return;
    }

    let isActive = true;

    if (!userChecked.length) {
      e.preventDefault();
      toasts.error(t('common.pleaseDoubleCheckTheFilterDataForConditionAndUserSelection'));
    }

    userChecked.forEach((id) => {
      for (const user of usersLoaded) {
        if (user.user_id === id && !user.status) {
          isActive = false;
          break;
        }
      }
    });

    if (isActive === false) {
      e.preventDefault();
      toasts.error(t('employees.notificationUserActive'));
    }

    await queryClient.removeQueries(Constants.USE_LIST_USERS);
  };

  const getTextTable = (strValue) => {
    if (strValue) return strValue;

    return t('common.notDataContract');
  };

  const getSeniority = (seniority) => {
    let text = '';
    switch (seniority) {
      case Constants.NONE_MONTH:
        text = t('employees.NoneMonths');
        break;
      case Constants.LESS_THAN_6_MONTH:
        text = t('employees.LessThan6Months');
        break;
      case Constants.MONTHS_6_TO_12:
        text = t('employees.6-12Months');
        break;
      case Constants.YEAR_1_TO_2:
        text = t('employees.1-2Years');
        break;
      default:
        text = t('employees.Over2Years');
    }
    return text;
  };

  const handleShowMessage = (value) => {
    if (value) {
      setMessage(value?.[0]);
      setShowAlert(true);
    }
  };

  const enableEditContract =
    dataInfoUser?.hr_permission?.contracts_permission !== ROLE_VIEW &&
    dataInfoUser?.hr_permission?.contracts_permission !== ROLE_NOT_VIEW

  const getValueChecked = (items, idsChecked) => {
    if (Array.isArray(idsChecked)) {
      if (items?.length && !items[0]?.status) {
        const values = items.reduce((firstValue, currentValue) => {
          if ((currentValue.status && currentValue.value !== Constants.ALL) || (idsChecked && idsChecked.includes(currentValue.id))) {
            firstValue.push(' ' + currentValue.name);
          } else if (idsChecked && idsChecked.includes(currentValue.id)) {
            firstValue.push(' ' + currentValue.name);
          }
          return firstValue;
        }, []);
        return values;
      }
      return t('employees.all');
    } else {
      if (idsChecked !== null) {
        let name = '';
        items.forEach((item) => {
          if (idsChecked === null && item.value === Constants.ALL) {
            name = + item.name;
          } else if ((idsChecked || idsChecked === 0) && idsChecked === item.value) {
            name = item.name;
          }
        })
        return name;
      } else if (items.length <= 3) {
        let name = '';
        items.forEach((item) => {
          if (item.status && item.value === Constants.ALL) {
            name = t('employees.all');
          } else if (item.status) {
            name = item.name;
          }
        })
        return name;
      }
      return ''
    }
  };

  const loadFilter = (items, checked, onSave) => {
    items.forEach((item) => {
      if (checked === null && item.value === Constants.ALL) {
        item.status = true;
      } else if ((checked || checked === 0) && checked === item.value) {
        item.status = true;
      }
    });
    onSave(items);
  }

  useEffect(() => {
    if (filter.children_gender !== null) {
      loadFilter(filterEmployeeChildrenGender, filter.children_gender, setFilterEmployeeChildrenGender);
    }
    if (filter.children_age !== null) {
      loadFilter(filterEmployeeChildrenAge, filter.children_age, setFilterEmployeeChildrenAge);
    }
    // eslint-disable-next-line
  }, [filter.children_gender, filter.children_age])

  const nameChecked = (items) => {
    let value = '';
    items.forEach((currentValue) => {
      if (currentValue.status && currentValue.value !== Constants.ALL) {
        value = currentValue.name + ' '
      }
    }, [])
    return value;
  }

  const getValueCheckBox = (listGender, listAge) => {
    return listGender[0]?.status && listAge[0].status
      ? t('employees.all')
      : nameChecked(listGender) + nameChecked(listAge);
  };

  return (
    <>
      <Grid container className="nav-fixed">
        <Grid container className="align-items-center">
          <Grid item md={6} xs={12}>
            <Employees />
          </Grid>
          <Grid item md={6} xs className={`${styles.exportRequest} ${styles.groupButton}`}>
            <Button
              onClick={() => {
                if (dataInfoUser?.hr_permission?.users_permission === ROLE_VIEW) {
                  return false;
                }
                toggleCheckField(Constants.IMPORT);
              }}
              className={`${dataInfoUser?.hr_permission?.users_permission !== ROLE_VIEW && styles.ctButton} ${
                styles.addEmployAndImport
              } ${dataInfoUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE}`}
              disabled={dataInfoUser?.hr_permission?.users_permission !== ROLE_VIEW ? false : true}
            >
              <IoIosAdd />
              {t('common.import')}
            </Button>
            <Button
              onClick={() => {
                if (dataInfoUser?.hr_permission?.users_permission === ROLE_VIEW) {
                  return false;
                }
                toggleCheckField(Constants.ADD_EMPLOYEE);
              }}
              className={`${dataInfoUser?.hr_permission?.users_permission !== ROLE_VIEW && styles.ctButton} ${
                styles.addEmployAndImport
              } ${dataInfoUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE}`}
              disabled={dataInfoUser?.hr_permission?.users_permission !== ROLE_VIEW ? false : true}
            >
              <IoIosAdd />
              {t('common.addEmployee')}
            </Button>
            <NavLink
              onClick={handleRequest}
              to={{
                pathname: '/admin/employees/request',
                state: {
                  userIds: userChecked,
                  keyWord: keyWord,
                  filterStatus: filterStatus,
                  filterPosition: filterPosition,
                  filterSkill: filterSkill,
                  filterLevel: filterLevel,
                  filterGender: filterGender,
                  filterCertificate: filterCertificate,
                  filterSeniority: filterSeniority,
                  filterEmployeeChildrenGender: filterEmployeeChildrenGender,
                  filterEmployeeChildrenAge: filterEmployeeChildrenAge,
                  filterDivision: filterDivision,
                  filterCategoryEmployees: filterCategoryEmployees,
                  profile: filterProfile,
                  selectDivision: divisionSelect,
                },
              }}
              className={`${styles.linkRequest}`}
            >
              <Button
                className={`${dataInfoUser?.hr_permission?.users_permission !== ROLE_VIEW && styles.ctButton} ${
                  styles.addEmployAndImport
                } ${dataInfoUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE}`}
                disabled={dataInfoUser?.hr_permission?.users_permission !== ROLE_VIEW ? false : true}
              >
                <IoIosAdd />
                {t('employees.makeRequest')}
              </Button>
            </NavLink>
            <Button
              onClick={() => {
                toggleCheckField('export');
                setFieldExport(DATA_FIELD_EXPORT)
              }}
              className={`${styles.ctButton} ${styles.iconButtonExport}`}
            >

              <BiLinkExternal />
              {t('common.export')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <div className="mt-4"></div>
      <div fluid="true" className={styles.employee}>
        <Row className={styles.exportAround} sm={12}>
          <Col xs="3" sm="3" md="3" lg="4" xl="3" className={`${styles.marginLeft}`}></Col>
          <Col xs="8" sm="8" md="8" lg="8" xl="9" className={`${styles.exportRequest} float-right`}></Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="6" lg="8" xl="9">
            <Row>
              <Col xs="12">
                {isLoadingListUser && <Loading />}
                {!isLoadingListUser && uListUser?.data?.data && uListUser?.data?.data.length > 0 && (
                  <>
                    <div className={classNames('wrapper-top', styles.stickyScroll)}>
                      <div className="scroll-top">
                        <p className="top">
                          <span>&nbsp;</span>
                        </p>
                      </div>
                    </div>
                    <div className="wrapper-bottom">
                      <div className="scroll-bottom">
                        <Table>
                          <thead className={`thead-light`}>
                            <tr className={styles.headTable}>
                              <td className={`${styles.fixed} ${styles.short}`}>
                                <Input
                                  className={styles.checked}
                                  onChange={checkAll}
                                  type="checkbox"
                                  checked={checkedAll}
                                />
                              </td>
                              <td className={`${styles.title}`}>{t('employees.code')}</td>
                              <td className={`${styles.title} ${styles.fixedName} ${styles.short} width-160px`}>{t('employees.nameEmployee')}</td>
                              <td className={`${styles.title} width-300px`}>{t('employees.email')}</td>
                              <td className={classNames(`${styles.title} pr-3`)}>{t('employees.phone')}</td>
                              <td className={`${styles.title}`}>{t('employees.level')}</td>
                              <td className={`${styles.title}`}>{t('employees.office')}</td>
                              <td className={`${styles.title}`}>{t('employees.department')}</td>
                              <td className={`${styles.title}`}>{t('employees.position')}</td>
                              <td className={classNames(`${styles.title} pr-3`)}>{t('employees.status')}</td>
                              <td className={`${styles.title}`}>{t('employees.contract')}</td>
                              <td className={`${styles.title}`}>{t('employees.contractStartDate')}</td>
                              <td className={`${styles.title}`}>{t('employees.contractEndDate')}</td>
                              <td className={`${styles.title}`}>{t('infoEmployee.groupPersonal')}</td>
                              <td className={classNames(`${styles.title} ${styles.shortGender} pr-3`)}>
                                {t('employees.gender')}
                              </td>
                              <td className={`${styles.title}`}>{t('employees.skill')}</td>
                              <td className={`${styles.title}`}>{t('infoEmployee.workTime')}</td>
                              <td className={`${styles.title}`}>{t('employees.seniority')}</td>
                              <td className={`${styles.title}`}>{t('employees.dateOnboard')}</td>
                              <td className={`${styles.title}`}>{t('employees.childrent')}</td>
                            </tr>
                          </thead>
                          <tbody>
                            {uListUser?.data?.data.map((item, index) => {
                                let male = 0;
                                let female = 0;
                                return (
                                  <tr
                                    key={index}
                                    onClick={() => {
                                      storeFilterModule(filter, FILTER_BY_MODULE.LIST_EMPLOYEES)
                                      item.handelClick.click();
                                    }}
                                  >
                                    <td className={`${styles.fixed}`} onClick={(e) => e.stopPropagation()}>
                                      <Input
                                        className={styles.checked}
                                        onChange={() => handleCheckBox(item)}
                                        type="checkbox"
                                        checked={userChecked.indexOf(item.user_id) < 0 ? false : true}
                                      />
                                    </td>
                                    <td>{item?.code}</td>
                                    <td className={styles.fixedName}>
                                      <NavLink
                                        to={{
                                          pathname: '/admin/employees/profile/' + item?.user_id,
                                        }}
                                        id={'infor-user-' + item?.user_id}
                                        className={styles.linkRequest}
                                        ref={(input) => {
                                          item.handelClick = input;
                                        }}
                                      >
                                        {item.name}
                                      </NavLink>
                                    </td>
                                    <td className={styles.emailColumn}>
                                      <p>{item?.email}</p>
                                    </td>
                                    <td>{getTextTable(item?.phone)}</td>
                                    <td>{getTextTable(item?.level?.name)}</td>
                                    <td>{getTextTable(item?.office?.name)}</td>
                                    <td>{getTextTable(item?.division?.name)}</td>
                                    <td>{getTextTable(item?.position?.name)}</td>
                                    <td>
                                      {item?.status ? (
                                        <span className={styles.active}>{t('employees.active')}</span>
                                      ) : (
                                        <span className={styles.inActive}>{t('employees.inActive')}</span>
                                      )}
                                    </td>
                                    <td>{getTextTable(item?.contract_category)}</td>
                                    <td>{customDate(item?.contract_start_date, 'DD/MM/YYYY') || t('common.notUpdate')}</td>
                                    <td>{customDate(item?.contract_end_date, 'DD/MM/YYYY') || t('common.notUpdate')}</td>
                                    <td>{item?.group_name}</td>
                                    <td>
                                      {item?.gender === GENDER_MALE && t('infoEmployee.genderMale')}
                                      {item?.gender === GENDER_FEMALE && t('infoEmployee.genderFemale')}
                                    </td>
                                    <td>
                                      {(!item?.skills || !item?.skills.length) && t('common.notDataContract')}

                                      {item?.skills &&
                                        item.skills
                                          .map((item) => {
                                            return item.skill || item.skill_name;
                                          })
                                          .join(', ')
                                          .slice(0, 10)}
                                      {item?.skills &&
                                        item.skills
                                          .map((item) => {
                                            return item.skill;
                                          })
                                          .join(', ').length > 10 &&
                                        '...'}
                                    </td>
                                    <td>
                                      {item?.anniversary
                                        ? (item?.anniversary / moment(moment().endOf('year')).dayOfYear()).toFixed(2) +
                                          ' ' +
                                          t('common.year')
                                        : 'N/A'}
                                    </td>
                                    <td>
                                      {item?.work_times ? getSeniority(item?.work_times) : t('common.notDataContract')}
                                    </td>
                                    <td>
                                      {item?.onboard_date
                                        ? customDate(item?.onboard_date, 'DD/MM/YYYY')
                                        : getTextTable(item?.onboard_date)}
                                    </td>
                                    <td>
                                      {item?.children?.forEach((it) => {
                                        if (it?.gender === GENDER_MALE) {
                                          male += 1;
                                        } else {
                                          female += 1;
                                        }
                                      })}
                                      {male > NOT_DATA && male + ' ' + t('infoEmployee.genderMale')}
                                      {male > NOT_DATA && female > NOT_DATA && ', '}
                                      {female > NOT_DATA && female + ' ' + t('infoEmployee.genderFemale')}
                                      {!item?.children?.length && 'N/A'}
                                    </td>
                                  </tr>
                                );
                              }) ?? null}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </>
                )}
                {uListUser?.data?.total > 0 && (
                  <PerPage
                    data={uListUser.data}
                    page={page}
                    perpage={perPage}
                    changePage={changePage}
                    changePerPage={changePerPage}
                    classAdd="perPage"
                  />
                )}
              </Col>
              <Col xl="9">
                {uListUser?.data?.total < 1 && (
                  <p className={`${styles.loading} error-no-data`}>{t('common.notData')}</p>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs="6" sm="6" md="6" lg="4" xl="3">
            <div className={styles.columnFilter}>
              <Row
                sm={12}
                className={`${styles.totalEmployee} ${!filterProfile.status ? styles.selected : styles.notSelected}`}
                onClick={() => TotalListEmployees(false)}
              >
                <span className={styles.iconStatistical}>
                  <img src={iconMenuTotal} alt="Total" />
                </span>
                <span className={styles.textTotal}>{t('employees.totalEmployee')}</span>
                <span className={styles.total}>{uListUser?.data?.total}</span>
              </Row>
              <Row
                sm={12}
                className={`${styles.totalEmployee} ${filterProfile.status ? styles.selected : styles.notSelected}`}
                onClick={() => TotalListEmployees(true)}
              >
                <span className={styles.iconStatistical}>
                  <img src={iconProfileNotCompleted} alt="Total-not-completed" />
                </span>
                <span className={styles.textTotal}>{t('employees.profileNotCompleted')}</span>
                <span className={styles.total}>{uListUser?.total_not_completed}</span>
              </Row>
              <Row sm={12} className={styles.searchFilter}>
                <SearchComponent
                  txtPlaceholder={t('employees.searchName')}
                  addClass={`input-search-box ${styles.formRight}`}
                  handleSearch={onChangeKeyword}
                  defaultValue={keyWord}
                  value={keyWord}
                />
              </Row>
              <Row sm={12}>
                <Input
                  type="text"
                  onClick={() => toggleCheckField(Constants.STATUS)}
                  placeholder={t('employees.status')}
                  name="status"
                  className={styles.exampleSelectMulti}
                  value={getValueChecked(filterStatus, filter.status)}
                  readOnly
                />
              </Row>
              <Row sm={12}>
                <Input
                  type="text"
                  onClick={() => toggleCheckField(Constants.POSITION)}
                  placeholder={t('employees.office')}
                  name="position"
                  className={styles.exampleSelectMulti}
                  value={getValueChecked(filterPosition, filter.office_id)}
                  readOnly
                />
              </Row>
              <Row sm={12}>
                <Input
                  type="text"
                  onClick={() => toggleCheckField(Constants.SKILL)}
                  placeholder={t('employees.skill')}
                  name="skill"
                  className={styles.exampleSelectMulti}
                  value={getValueChecked(filterSkill, filter.skill)}
                  readOnly
                />
              </Row>
              <Row sm={12}>
                <Input
                  type="text"
                  placeholder={t('employees.level')}
                  name="level"
                  onClick={() => toggleCheckField(Constants.LEVEL)}
                  className={styles.exampleSelectMulti}
                  value={getValueChecked(filterLevel, filter.level_id)}
                  readOnly
                />
              </Row>
              <Row sm={12}>
                <Input
                  type="text"
                  placeholder={t('employees.gender')}
                  name="gender"
                  onClick={() => toggleCheckField(Constants.GENDER)}
                  className={styles.exampleSelectMulti}
                  value={getValueChecked(filterGender, filter.gender)}
                  readOnly
                />
              </Row>
              <Row sm={12}>
                <Input
                  type="text"
                  placeholder={t('employees.seniority')}
                  name="seniority"
                  onClick={() => toggleCheckField(Constants.SENIORITY)}
                  className={styles.exampleSelectMulti}
                  value={getValueChecked(filterSeniority, filter.seniority)}
                  readOnly
                />
              </Row>

              <Row sm={12}>
                <Input
                  type="text"
                  placeholder={t('employees.employeeChildren')}
                  name="employeeChildren"
                  onClick={() => toggleCheckField(Constants.EMPLOYEE_CHILDREN)}
                  className={styles.exampleSelectMulti}
                  value={getValueCheckBox(filterEmployeeChildrenGender, filterEmployeeChildrenAge)}
                  readOnly
                />
              </Row>
              <Row sm={12}>
                <Input
                  type="text"
                  onClick={() => toggleCheckField(Constants.CERTIFICATE)}
                  placeholder={t('employees.achievementsAndAwards')}
                  name="certificate"
                  className={styles.exampleSelectMulti}
                  value={getValueChecked(filterCertificate, filter.certificate_category_id)}
                  readOnly
                />
              </Row>
              <Row className={styles.rowListRight}>
                <FormGroup tag="fieldset" className={styles.listRight}>
                  <List type="unstyled" className={styles.listRight}>
                    <li>
                      <h2 className={styles.titleFilter}>{t('infoEmployee.groupPersonal')}</h2>
                    </li>
                    <li>
                      <div className={styles.division}>
                        {categoryEmployeeSelect === Constants.ALL ? (
                          <img className={styles.radio} alt="radio" src={radioChecked} />
                        ) : (
                          <img
                            onClick={() => changeCategoryEmployeesRadio(Constants.ALL)}
                            alt="radio"
                            className={styles.radio}
                            src={radio}
                          />
                        )}
                        <span className={styles.itemRadio} onClick={() => changeCategoryEmployeesRadio(Constants.ALL)}>
                          {DATA_ALL.name}
                        </span>
                      </div>
                    </li>
                    {!isLoadingListDivision &&
                      filterCategoryEmployees &&
                      filterCategoryEmployees.map((item, index) => (
                        <li key={index}>
                          <div className={styles.division}>
                            {item.group_id === categoryEmployeeSelect ? (
                              <img className={styles.radio} alt="radio" src={radioChecked} />
                            ) : (
                              <img
                                onClick={() => changeCategoryEmployeesRadio(item)}
                                alt="radio"
                                className={styles.radio}
                                src={radio}
                              />
                            )}
                            <span
                              className={`text-ellipsis ${styles.itemRadio}`}
                              onClick={() => changeCategoryEmployeesRadio(item)}
                            >
                              {item.group_name}
                            </span>
                            {item.value !== Constants.ALL && (
                              <span className={styles.total}>{item.total ? item.total : NOT_DATA}</span>
                            )}
                          </div>
                        </li>
                      ))}
                    {isLoadingListDivision && <Loading />}
                  </List>
                </FormGroup>
              </Row>
              <Row className={styles.rowListRight}>
                <FormGroup tag="fieldset" className={styles.listRight}>
                  <List type="unstyled" className={styles.listRight}>
                    <li>
                      <h2 className={styles.titleFilter}>{t('common.division')}</h2>
                    </li>
                    <li>
                      <div className={styles.division}>
                        {divisionSelect === Constants.ALL ? (
                          <img className={styles.radio} alt="radio" src={radioChecked} />
                        ) : (
                          <img
                            onClick={() => changeRadio(Constants.ALL)}
                            alt="radio"
                            className={styles.radio}
                            src={radio}
                          />
                        )}
                        <span className={styles.itemRadio} onClick={() => changeRadio(Constants.ALL)}>
                          {DATA_ALL.name}
                        </span>
                      </div>
                    </li>
                    {!isLoadingListDivision &&
                      filterDivision &&
                      filterDivision.map((item, index) => (
                        <li key={index}>
                          <div className={styles.division}>
                            {item.id === divisionSelect ? (
                              <img className={styles.radio} alt="radio" src={radioChecked} />
                            ) : (
                              <img onClick={() => changeRadio(item)} alt="radio" className={styles.radio} src={radio} />
                            )}
                            <span className={`text-ellipsis ${styles.itemRadio}`} onClick={() => changeRadio(item)}>
                              {item.name}
                            </span>
                            {item.value !== Constants.ALL && (
                              <span className={styles.total}>{item.total_member ? item.total_member : NOT_DATA}</span>
                            )}
                          </div>
                        </li>
                      ))}
                    {isLoadingListDivision && <Loading />}
                  </List>
                </FormGroup>
              </Row>
              <Row sm={12}>
                <Button
                  className={styles.reset}
                  onClick={() => handleSubmit('', '', '', '', [], 'reset')}
                  size="lg"
                  block
                >
                  <img src={iconLoading} alt="Reset" width="20px" className={styles.imgReset} /> &emsp;
                  {t('common.reset')}
                </Button>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <ModalCommon
        items={filterStatus}
        onSubmit={handleSubmit}
        onClick={changeSelectFilterStatus}
        toggle={() => {
          toggleCheckField(Constants.STATUS);
          setFilterStatus([...dataFilterBackup]);
        }}
        modal={modalCheckField.status}
        title={t('employees.filterStatus')}
        footer={t('common.search')}
      />
      <ModalCommon
        items={filterPosition}
        onSubmit={handleSubmit}
        onClick={changeSelectFilterPosition}
        toggle={() => {
          toggleCheckField(Constants.POSITION);
          setFilterPosition([...dataFilterBackup]);
        }}
        modal={modalCheckField.position}
        title={t('employees.filterOffice')}
        footer={t('common.search')}
      />
      <ModalCommon
        items={filterLevel}
        onSubmit={handleSubmit}
        onClick={changeSelectFilterLevel}
        toggle={() => {
          toggleCheckField(Constants.LEVEL);
          setFilterLevel([...dataFilterBackup]);
        }}
        modal={modalCheckField.level}
        title={t('employees.filterLevel')}
        footer={t('common.search')}
      />
      <ModalCommon
        items={filterSkill}
        onSubmit={handleSubmit}
        onClick={changeSelectFilterSkill}
        toggle={() => {
          toggleCheckField(Constants.SKILL);
          setFilterSkill([...dataFilterBackup]);
        }}
        modal={modalCheckField.skill}
        title={t('employees.filterSkill')}
        footer={t('common.search')}
      />
      <ModalCommon
        items={filterCertificate}
        onSubmit={handleSubmit}
        onClick={changeSelectFilterCertificate}
        toggle={() => {
          toggleCheckField(Constants.CERTIFICATE);
          setFilterCertificate([...dataFilterBackup]);
        }}
        modal={modalCheckField.certificate}
        title={t('employees.filterCertificate')}
        footer={t('common.search')}
      />
      <ModalCommon
        items={filterGender}
        onSubmit={handleSubmit}
        onClick={changeSelectFilterGender}
        toggle={() => {
          toggleCheckField(Constants.GENDER);
          setFilterGender([...dataFilterBackup]);
        }}
        modal={modalCheckField.gender}
        title={t('employees.filterGender')}
        footer={t('common.search')}
      />
      <ModalCommon
        items={filterSeniority}
        onSubmit={handleSubmit}
        onClick={changeSelectFilterSeniority}
        toggle={() => {
          toggleCheckField(Constants.SENIORITY);
          setFilterSeniority([...dataFilterBackup]);
        }}
        modal={modalCheckField.seniority}
        title={t('employees.filterSenior')}
        footer={t('common.search')}
      />
      <Modal
        isOpen={modalCheckField.employeeChildren}
        toggle={() => {
          toggleCheckField('employeeChildren');
          setFilterEmployeeChildrenAge([...dataFilterBackup?.age]);
          setFilterEmployeeChildrenGender([...dataFilterBackup?.gender]);
        }}
        className={`${styles.modalCommon} modal-dialog-centered`}
      >
        <ModalHeader
          className={styles.modalTitle}
          toggle={() => {
            toggleCheckField('employeeChildren');
          }}
        >
          {t('employees.filterEmployeeChildren')}
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          <div className={styles.itemCheck}>
            <p className={styles.title}>Giới tính</p>
          </div>
          {filterEmployeeChildrenGender.map((item, index) => (
            <div className={styles.itemCheck} key={index}>
              <div className={styles.division}>
                {item.status ? (
                  <img className={styles.radio} alt="radio" src={radioChecked} />
                ) : (
                  <img onClick={() => changeRadioChilGender(item)} alt="radio" className={styles.radio} src={radio} />
                )}
                <span className={styles.itemRadio} onClick={() => changeRadioChilGender(item)}>
                  {item.name}
                </span>
              </div>
            </div>
          ))}

          <div className={styles.itemCheck}>
            <p className={styles.title}>Độ tuổi</p>
          </div>
          {filterEmployeeChildrenAge.map((item, index) => (
            <div className={styles.itemCheck} key={index}>
              <div className={styles.division}>
                {item.status ? (
                  <img className={styles.radio} alt="radio" src={radioChecked} />
                ) : (
                  <img onClick={() => changeRadioChilAge(item)} alt="radio" className={styles.radio} src={radio} />
                )}
                <span className={styles.itemRadio} onClick={() => changeRadioChilAge(item)}>
                  {item.name}
                </span>
              </div>
            </div>
          ))}
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button onClick={handleSubmit} className={styles.buttonSubmit}>
            Search
          </Button>
        </ModalFooter>
      </Modal>
      <ModalCommon
        items={fieldExport}
        onSubmit={() => handleSubmit('', '', '', '', [], '', Constants.EXPORT)}
        onClick={changeSelectExport}
        toggle={() => toggleCheckField(Constants.EXPORT)}
        modal={modalCheckField.export}
        title={t('common.export')}
        footer={t('common.export')}
        nameModal={Constants.EXPORT}
      />
      {modalCheckField.addEmployee && (
        <ModalAddEmployee
          toggle={() => toggleCheckField('addEmployee')}
          data={{ queryClient: queryClient }}
          modal={modalCheckField.addEmployee}
          setDataUserCreate={setDataUserCreate}
          setShowModalCreateContract={enableEditContract && setShowModalCreate}
        />
      )}
      <ModalImportEmployee toggle={() => toggleCheckField('importEmployee')} modal={modalCheckField.importEmployee} />
      {isShowModalCreate && enableEditContract && (
          <CreateContractModal
            onSetShowModal={() => setShowModalCreate(!isShowModalCreate)}
            isShowModalAdd={isShowModalCreate}
            onSetMessage={handleShowMessage}
            user={{ user_id: dataUserCreate.user_id, user_name: dataUserCreate.name }}
          />
        )}
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        <p className={styles.textLeft}>{message}</p>
      </AlertComponent>
    </>
  );
};

export default ListEmployees;
