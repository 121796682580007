import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { HiPencil } from 'react-icons/hi';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { NavLink } from 'react-router-dom';

import Sidebar from './../Sidebar.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AlertComponent from 'components/Alert/index.js';
import './style.scss';
import ButtonComponent from 'components/Buttons';
import SearchComponent from 'components/Search';
import IconDeleteComponent from 'components/Delete/IconDelete.js';
import { useListGroupAccount } from 'hook/useMasterData.js';
import { USE_TYPE_GROUP, USE_LIST_GROUP_ACCOUNT } from 'constants/MasterData';
import { MAX_LENGTH_30 } from 'constants/ProfileEmployee';
import Loading from 'components/Loading';
import { apiDeleteGroupAccount } from 'api/masterData';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE, DISABLE_BUTTON_ROLE } from 'constants/index.js';

const GroupAccount = () => {
  let { t } = useTranslation();
  const dataInforUser = useContext(ThemContext);
  const [message, setMessage] = useState('');
  const [key_word, setKeyWord] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);

  const { data: listGroupAccount, isLoading: isLoadingListGroupAccount } = useListGroupAccount({
    key_word,
  });

  const handleSearch = (value) => {
    setKeyWord(value);
  };

  const checkRole = async (e) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      e.preventDefault();
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="master-data-group-access">
      <Grid container spacing={2}>
        <Grid item lg={3} xl={2} className="sidebar">
          <Sidebar />
        </Grid>
        <Grid item lg={9} xl={10}>
          <div className="box-group d-flex float-right">
            <SearchComponent
              txtPlaceholder={t('masterData.findData')}
              addClass="input-search"
              handleSearch={handleSearch}
              defaultValue={key_word}
              maxLength={MAX_LENGTH_30}
            />
            <NavLink
              onClick={checkRole}
              to={{
                pathname: '/admin/master-data/create-filter/create',
              }}
            >
              <ButtonComponent
                text={t('common.add')}
                icon={<IoIosAdd />}
                addClass={`${
                  dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                } ml-3`}
              />
            </NavLink>
          </div>
          <TableContainer component={Paper} className="table-list">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('common.code')}</TableCell>
                  <TableCell>{t('divisionDiagram.nameTeam')}</TableCell>
                  <TableCell>{t('masterData.groupAccountList.totalEmployee')}</TableCell>
                  <TableCell>{t('masterData.groupAccountList.dateCreate')}</TableCell>
                  <TableCell>{t('masterData.groupAccountList.typeGroup')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listGroupAccount?.data &&
                  listGroupAccount?.data.map((item) => (
                    <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{(item.id < 10 ? '0' : '') + item.id}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.total_member}</TableCell>
                      <TableCell>{item.created_at}</TableCell>
                      <TableCell>
                        {item.type === USE_TYPE_GROUP.filter && t('masterData.groupAccountList.filter')}
                        {item.type === USE_TYPE_GROUP.custom && t('masterData.groupAccountList.custom')}
                      </TableCell>
                      <TableCell>
                        <div className="col-action d-flex float-right">
                          <div className="edit mr-2">
                            <NavLink
                              onClick={checkRole}
                              className={`${
                                dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                              }`}
                              to={{
                                pathname:
                                  item.type === USE_TYPE_GROUP.filter
                                    ? '/admin/master-data/create-filter/' + Number(item.id)
                                    : '/admin/master-data/create-custom/' + Number(item.id),
                              }}
                            >
                              <HiPencil />
                            </NavLink>
                          </div>
                          <div
                            className={`${
                              dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                            } remove mr-3`}
                          >
                            {dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && <MdDelete />}
                            {dataInforUser?.hr_permission?.master_data_permission !== ROLE_VIEW && (
                              <IconDeleteComponent
                                linkDelete={apiDeleteGroupAccount(item.id)}
                                keyQuery={USE_LIST_GROUP_ACCOUNT}
                                onSetMessage={setMessage}
                                onSetShowAlert={setShowAlert}
                              />
                            )}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                {isLoadingListGroupAccount && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Loading addClass="mt-2" />
                    </TableCell>
                  </TableRow>
                )}
                {listGroupAccount && listGroupAccount?.data.length < 1 && (
                  <TableRow>
                    <TableCell className="error-no-data" colSpan={4}>
                      {t('common.notData')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </Box>
  );
};

export default GroupAccount;
