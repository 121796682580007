const configs = {
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  API_DOMAIN_AMS: process.env.REACT_APP_API_DOMAIN_AMS,
  API_TOKEN_AMS: process.env.REACT_APP_API_TOKEN_AMS,
  API_DOMAIN_BWF: process.env.REACT_APP_API_DOMAIN_BWF,
  API_DOMAIN_RECRUIT: process.env.REACT_APP_API_DOMAIN_RECRUIT,
  API_DOMAIN_LOGIN: process.env.REACT_APP_DOMAIN_LOGIN,
  API_DOMAIN_SSO: process.env.REACT_APP_SSO_API_DOMAIN,
  API_DOMAIN_NOTIFICATIONS: process.env.REACT_APP_API_NOTIFICATIONS,
  API_DOMAIN_PROJECT: process.env.REACT_APP_API_DOMAIN_PROJECT,
  SUBDOMAIN_TOKEN: process.env.REACT_APP_CLIENT_SUBDOMAIN_TOKEN,
  DOMAIN_WORK_FOLLOW: process.env.REACT_APP_DOMAIN_AMS_WORKFLOW,
  DOMAIN_APP_DOMAIN_AMS_BO: process.env.REACT_APP_DOMAIN_AMS_BO,
  DOMAIN_AMS_ME: process.env.REACT_APP_DOMAIN_AMS_ME,
  DOMAIN_AMS_RECRUIT: process.env.REACT_APP_DOMAIN_AMS_RECRUIT,
  DOMAIN_AMS_WORKFLOW: process.env.REACT_APP_DOMAIN_AMS_WORKFLOW,
  DOMAIN_AMS_ACCOUNTING: process.env.REACT_APP_DOMAIN_AMS_ACCOUNTING,
  DOMAIN_AMS_PROJECT: process.env.REACT_APP_DOMAIN_AMS_PROJECT,
  DOMAIN_AMS_WIKI: process.env.REACT_APP_WIKI,
  ID_REQUEST_LIST_USER: process.env.REACT_APP_ID_REQUEST_LIST_USERS,
  ID_RETIRE_REQUEST: process.env.REACT_APP_ID_RETIRE_REQUEST,
  DOMAIN_AMS_DIVISION: process.env.REACT_APP_DOMAIN_AMS_DIVISION,
  ID_TIMESHEET_REQUEST: process.env.REACT_APP_ID_TIMESHEET_REQUEST,
  ID_ONSINAL: process.env.REACT_APP_ONSIGNAL_ID,
  ID_SAFARI_ID: process.env.REACT_APP_ONSIGNAL_SAFARI_WEB_ID,
  ID_OT_REQUEST: process.env.REACT_APP_ID_OT_REQUEST,
  TYPE_OT: process.env.REACT_APP_TYPE_OT?.split(','),
  TYPE_GO_EARLY_COME_LATE: process.env.REACT_APP_TYPE_GO_EARLY_COME_LATE?.split(','),
  TYPE_ADDITIONAL_WORK: process.env.REACT_APP_TYPE_ID_ADDITIONAL_WORK?.split(','),
  TYPE_REMOTE: process.env.REACT_APP_TYPE_REMOTE?.split(','),
  TYPE_PAID_LEAVE: process.env.REACT_APP_TYPE_PAID_LEAVE?.split(','),
  TYPE_UNPAID_LEAVE: process.env.REACT_APP_UNPAID_LEAVE?.split(','),
};

export default configs;
