/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useState, useEffect } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { HiPaperAirplane } from "react-icons/hi";
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import classNames from 'classnames';
import {
  ButtonBase,
  Grid,
  debounce,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputBase,
  IconButton,
  MenuItem,
  Menu,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Zoom from '@mui/material/Zoom';

import './style.scss';
import { COLOR_CHECKBOX_NOT_ACTIVE } from 'constants/ColorVariable';
import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import ButtonComponent from 'components/Buttons';
import SearchComponent from 'components/Search';
import iconLoading from 'assets/img/icons/loading.png';
import menuTotal from 'assets/img/icons/menu-total.png';
import OT from 'assets/img/icons/Ot.svg';
import Fire from 'assets/img/icons/fire.svg';
import HourGlass from 'assets/img/icons/hour-glass.svg';
import ActiveNotColor from 'assets/img/icons/active-not-color.svg';
import Loading from 'components/Loading';
import * as Constants from 'constants/index.js';
import * as StatusCode from 'constants/StatusCode';
import TimeKeeping from './../index.js';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import ModalExportOt from './ModalExportOt';
import { useGetListOT, useGetListProject } from 'hook/useTimekeeping';
import { useListAllDivision } from 'hook/useTimeSheet';
import {
  STATUS_REQUEST_OPEN,
  STATUS_REQUEST_IN_PROGRESS,
  STATUS_REQUEST_REWORK,
  STATUS_REQUEST_COMPLETED,
  STATUS_REQUEST_RECALL,
  STATUS_REQUEST_DENY,
} from 'constants/TimeKeeping';
import { CONTROL_RADIO } from 'components/InputYupAndMui/constant';
import { TooltipContentOT, StyledTooltip } from '../../TimeKeeping/Request/TooltipInfo';
import { LENGTH_TO_REMOVE_BORDER_TOOLTIP, LENGTH_TO_DISPLAY_TOOLTIP } from 'constants/TimeSheet';
import { useGetListOTDemo } from 'hook/useTimekeeping';
import DateDayMonthComponent from 'components/DateTime/DateDay.js';
import { customDate } from 'helpers/formatDate.js';
import { convertFormatTimeSend } from 'views/pages/MasterData/SpecialTimekeeping/ultil';
import { transferStatus, transferStatusToColor, transferStatusToBackgroundColor } from '../Request/ultil';
import configs from 'config';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AlertComponent from 'components/Alert';
import { confirmAlert } from 'react-confirm-alert';
import { apiRecallRequest } from 'api/timesheet';
import { useQueryClient } from 'react-query';
import { USE_LIST_OT } from 'constants/TimeKeeping';
import SendMailOT from './SendMailOT';

const ListOT = () => {
  const { t } = useTranslation();
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const queryClient = useQueryClient();
  const { control } = useForm();

  const Status = [
    { label: t('timekeeping.request.statusOpen'), value: STATUS_REQUEST_OPEN },
    { label: t('timekeeping.request.statusInProgress'), value: STATUS_REQUEST_IN_PROGRESS },
    { label: t('timekeeping.request.statusCompleted'), value: STATUS_REQUEST_COMPLETED },
    { label: t('timekeeping.request.statusDeny'), value: STATUS_REQUEST_DENY },
    { label: t('timekeeping.request.statusRework'), value: STATUS_REQUEST_REWORK },
    { label: t('timekeeping.request.statusRecall'), value: STATUS_REQUEST_RECALL },
  ];

  const [openOT, setOpenOT] = useState(false)
  
  const [filter, setFilter] = useState({
    key_word: null,
    division_id: [],
    project_id: [],
    status: null,
    start_date: convertFormatTimeSend(new Date(), '01/MM/YYYY'),
    end_date: convertFormatTimeSend(new Date(), 'DD/MM/YYYY'),
    page: Constants.DEFAULT_PAGE_MUI,
    per_page: Constants.DEFAULT_LIMIT,
  });

  const { data: uDataOT, isLoading: isLoadingOT } = useGetListOT(filter);
  const { data: uListDivisions } = useListAllDivision();
  const { data: uListProjects } = useGetListProject();
  const { data: uDataOTExport, isLoading: isLoadingOTDemo } = useGetListOTDemo(filter);

  const [listDivision, setListDivision] = useState([]);
  const [listProject, setListProject] = useState([]);
  const [dataFilterBackup, setDataFilterBackup] = useState({});
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // eslint-disable-next-line no-unused-vars
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    if (uListDivisions) {
      const division = uListDivisions?.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));

      setListDivision([
        {
          id: undefined,
          name: t('contract.all'),
          status: false,
          value: Constants.ALL,
        },
        ...division,
      ]);
    }
  }, [uListDivisions, t]);

  useEffect(() => {
    if (uListProjects) {
      const project = uListProjects?.projects?.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));

      setListProject([
        {
          id: undefined,
          name: t('contract.all'),
          status: false,
          value: Constants.ALL,
        },
        ...project,
      ]);
    }
  }, [uListProjects, t]);

  const [isModals, setIsModals] = useState({ division: false, project: false });

  const changeSelectDivision = (item) => {
    setListDivision(function (listStatus) {
      const index = listStatus?.indexOf(item);
      return handleCheck(listStatus, index);
    });
  };

  const changeSelectProject = (item) => {
    setListProject(function (listStatus) {
      const index = listStatus?.indexOf(item);
      return handleCheck(listStatus, index);
    });
  };

  function handleCheck(items, index) {
    let isCheckAll = true;
    if (index === 0) {
      isCheckAll = !items[0]?.status;
      items?.forEach((item) => (item.status = isCheckAll));
    } else {
      items?.forEach(function (item, i) {
        if (((i !== index && item?.status === false) || (i === index && item?.status === true)) && i !== 0) {
          isCheckAll = false;
        }
      });
      if (isCheckAll) {
        items?.forEach((item) => (item.status = isCheckAll));
      } else {
        items?.forEach(function (item, i) {
          if (i === 0) {
            item.status = isCheckAll;
          } else if (index === i) {
            item.status = !item.status;
          }
        });
      }
    }

    return [...items];
  }

  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchNameEmployee = useCallback(
    debounce((value) => {
      setFilter({ ...filter, key_word: value, page: Constants.DEFAULT_PAGE_MUI });
    }),
  );

  const handleReset = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFilter({
      key_word: null,
      division_id: [],
      project_id: [],
      status: null,
      start_date: convertFormatTimeSend(new Date(), '01/MM/YYYY'),
      end_date: convertFormatTimeSend(new Date(), 'DD/MM/YYYY'),
      page: Constants.DEFAULT_PAGE_MUI,
      per_page: Constants.DEFAULT_LIMIT,
    });
    const division = uListDivisions.map((item) => ({
      id: item.id,
      name: item.name,
      status: false,
    }));

    setListDivision([
      {
        id: undefined,
        name: t('contract.all'),
        status: false,
        value: Constants.ALL,
      },
      ...division,
    ]);

    const project = uListProjects?.projects?.map((item) => ({
      id: item.id,
      name: item.title,
      status: false,
    }));

    setListProject([
      {
        id: undefined,
        name: t('contract.all'),
        status: false,
        value: Constants.ALL,
      },
      ...project,
    ]);
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getTotalTime = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].total;
    }
    return sum.toFixed(2);
  };

  const getValueTime = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i].value;
    }
    return sum.toFixed(2);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const renderMenuItem = () => {
    return (
      <>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            confirmAlert({
              title: t('messages.warningDelete'),
              message: t('messages.confirmDeleteRequest'),
              buttons: [
                {
                  label: t('common.cancel'),
                  className: 'btn-alert-cancel',
                },
                {
                  label: t('common.ok'),
                  className: 'btn-alert-ok',
                  onClick: async () => {
                    const result = await apiRecallRequest(selectedValue.id);
                    if (result) {
                      setMessage(
                        result.data.code === StatusCode.HTTP_OK
                          ? t('timekeeping.request.removeSuccessfully')
                          : result.data.message,
                      );
                      setShowAlert(true);
                      queryClient.invalidateQueries(USE_LIST_OT);
                    }
                  },
                },
              ],
            });
          }}
        >
          <DeleteIcon />
          {t('timekeeping.request.reMove')}
        </MenuItem>
      </>
    );
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    setSelectedValue(item);
  };

  return (
    <Grid container className="list-OT">
      <Grid container className="nav-fixed pt-0">
        <Grid className="menu-slider-around">
          <TimeKeeping />
        </Grid>
        <Grid item xs sm md lg xl className="button mt-1">
          <ButtonComponent
            addClass="sendMailSelectedItems"
            icon={<HiPaperAirplane />}
            text={t('timekeeping.sendMail')}
            handleClick={() => setOpenOT(true)}
          />
          <ButtonComponent
            handleClick={() => setIsOpenExportModal(true)}
            addClass="export"
            icon={<BiLinkExternal />}
            text={t('common.export')}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={9} className="asset-main">
          <div className="profile-box table-list table-border-show">
            <Paper>
              <TableContainer className={`${true && 'scroll-custom'}`}>
                <Table aria-label="sticky table" className={`${true && 'table-scroll'}`}>
                  <TableHead className="asset-header">
                    <TableRow className="asset-header-row">
                      <TableCell>{t('contract.contractDetail.code')}</TableCell>
                      <TableCell>{t('masterData.groupAccountList.nameEmployee')}</TableCell>
                      <TableCell>{t('employees.email')}</TableCell>
                      <TableCell>{t('division.Division')}</TableCell>
                      <TableCell>{t('timekeeping.OT.project')}</TableCell>
                      <TableCell>{t('timekeeping.OT.date')}</TableCell>
                      <TableCell>{t('timekeeping.OT.otType')}</TableCell>
                      <TableCell>{t('timekeeping.OT.workTime')}</TableCell>
                      <TableCell>{t('timekeeping.OT.workTimeOtOn')}</TableCell>
                      <TableCell>{t('assets.type')}</TableCell>
                      <TableCell className="short"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoadingOT && (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <Loading addClass="mt-3 mb-3" />
                        </TableCell>
                      </TableRow>
                    )}
                    {uDataOT &&
                      uDataOT?.data?.data?.data?.map((item, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <TableCell>
                              <a
                                href={`${configs.DOMAIN_WORK_FOLLOW}/request?request_id=${item?.id}`}
                                target="_blank"
                                ref={(input) => {
                                  item.handelClick = input;
                                }}
                                className="link-work-fl"
                              >
                                {item.code ? item.code : t('common.notDataContract')}
                              </a>
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              <div className="linkRequest">
                                {item?.user_name ? item?.user_name : <>{t('infoEmployee.notUpdate')}</>}
                              </div>
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              <p>{item?.email ? item?.email : <>{t('infoEmployee.notUpdate')}</>}</p>
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              {item.division_name ? item?.division_name : t('infoEmployee.notUpdate')}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              {item.project_name ? item?.project_name : t('infoEmployee.notUpdate')}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              <StyledTooltip
                                TransitionComponent={Zoom}
                                title={
                                  item?.overtime_date?.length >= LENGTH_TO_DISPLAY_TOOLTIP && (
                                    <TooltipContentOT item={item?.overtime_date} />
                                  )
                                }
                                className={
                                  item?.overtime_date?.length < LENGTH_TO_REMOVE_BORDER_TOOLTIP &&
                                  'border-bottom-tooltip-none'
                                }
                              >
                                <section className="d-flex">
                                  <div>
                                    <span className="d-block bolder-500">
                                      {item?.overtime_date[0]
                                        ? item?.overtime_date[0]?.hr_date_log
                                        : t('common.notDataContract')}
                                    </span>
                                  </div>
                                </section>
                              </StyledTooltip>
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              {item.type_name ?? ''}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              {item.overtime_date ? getTotalTime(item?.overtime_date) : t('infoEmployee.notUpdate')}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              {item.overtime_date ? getValueTime(item?.overtime_date) : t('infoEmployee.notUpdate')}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                item.handelClick.click();
                              }}
                            >
                              {item.status ? (
                                <span className={classNames(transferStatusToColor(item?.status))}>
                                  {transferStatus(item?.status)}
                                </span>
                              ) : (
                                t('common.notDataContract')
                              )}
                            </TableCell>
                            {item?.status === STATUS_REQUEST_COMPLETED ? (
                              <TableCell>
                                <div className="floatRight">
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls="long-menu"
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(event) => handleClick(event, item)}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </div>
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                          </TableRow>
                        );
                      })}

                    {uDataOT?.data?.data?.total === 0 && (
                      <tr>
                        <td colSpan={11} className="error-no-data">
                          {t('common.notData')}
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {uDataOT?.data?.data?.total > 0 && (
                <TablePagination
                  rowsPerPageOptions={Constants.PER_PAGE_OPTION}
                  component="div"
                  count={uDataOT?.data?.data?.total}
                  rowsPerPage={filter?.per_page}
                  page={filter?.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="table-pagination"
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                />
              )}
            </Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: '220px',
                  width: '20ch',
                },
              }}
            >
              {renderMenuItem()}
            </Menu>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="box-filter">
            <div className="box-waiting-content">
              <img src={OT} alt={OT} />
              <div className="box-waiting-text">
                <div className="waiting-text">{t('timekeeping.OT.otDoesNotIncludeCoefficients')}</div>
                <div className="waiting-number">{uDataOT?.data?.OT_default.toFixed(2)}</div>
              </div>
            </div>
            <div className="box-waiting-content second">
              <div className="content">
                <img src={OT} alt={OT} />
                <div className="box-waiting-text">
                  <div className="waiting-text">{t('timekeeping.OT.otFactorIncluded')}</div>
                  <div className="waiting-number">{uDataOT?.data?.OT_coefficient.toFixed(2)}</div>
                </div>
              </div>
            </div>
            <div className="box-waiting-content second pb-0">
              <div className="content">
                <img src={Fire} alt={Fire} />
                <div className="box-waiting-text">
                  <div className="waiting-text">{t('timekeeping.OT.numberOfOtProjects')}</div>
                  <div className="waiting-number">{uDataOT?.data?.total_project}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-filter">
            <div className={classNames(`text marginElementBottom border-bottom d-flex`)}>
              <DateDayMonthComponent
                md={5}
                label=""
                addClass="from-date"
                name="start_date"
                valueDefault={filter.start_date ? moment(filter.start_date, 'DD/MM/YYYY') : ''}
                onChangeHandle={(value) => {
                  setFilter({
                    ...filter,
                    start_date: customDate(value, 'DD/MM/YYYY'),
                    page: Constants.DEFAULT_PAGE_MUI,
                  });
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                placeholder={t('masterData.placeholderStartDate')}
                sizeDate="small"
                max={moment(filter?.end_date, 'DD/MM/yyyy').toDate()}
              />
              <p className="between">~</p>
              <DateDayMonthComponent
                md={5}
                label=""
                name="end_date"
                valueDefault={
                  typeof filter.end_date === 'string' ? moment(filter.end_date, 'DD/MM/YYYY') : filter.end_date
                }
                onChangeHandle={(value) => {
                  setFilter({
                    ...filter,
                    end_date: customDate(value, 'DD/MM/YYYY'),
                    page: Constants.DEFAULT_PAGE_MUI,
                  });
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                placeholder={t('masterData.placeholderEndDate')}
                sizeDate="small"
                min={moment(filter?.start_date, 'DD/MM/yyyy').toDate()}
              />
            </div>

            {/* Statistical */}
            <div className="statistical">
              <div className="selectField selected">
                <img alt={menuTotal} src={menuTotal} className="imgFilter" />
                <div className="selectFieldTitle">{t('timekeeping.OT.totalRequests')}</div>
                <div className="selectFieldTotal">{uDataOT?.data?.total_request}</div>
              </div>
              <div className="selectField not-selected">
                <img alt={HourGlass} src={HourGlass} className="imgFilter" />
                <div className="selectFieldTitle">{t('timekeeping.OT.proposalsPendingApproval')}</div>
                <div className="selectFieldTotal">
                  {uDataOT?.data?.request_processing + uDataOT?.data?.request_open + uDataOT?.data?.request_remake}
                </div>
              </div>
              <div className="selectField not-selected">
                <img alt={ActiveNotColor} src={ActiveNotColor} className="imgFilter" />
                <div className="selectFieldTitle">{t('timekeeping.OT.numberOfApprovedProposals')}</div>
                <div className="selectFieldTotal">
                  {uDataOT?.data?.request_completed + uDataOT?.data?.request_deny + uDataOT?.data?.request_recall}
                </div>
              </div>
            </div>

            {/* Filter by search input */}
            <div className={classNames(`text padding-element-filter`)}>
              <SearchComponent
                txtPlaceholder={t('employees.searchName')}
                addClass="input-search-box"
                handleSearch={handleSearchNameEmployee}
                defaultValue={filter?.key_word}
              />
            </div>
            <div className={classNames(`text padding-element-filter`)}>
              <InputBase
                readOnly
                type="text"
                name="division_id"
                className="exampleSelectMulti"
                onClick={() => {
                  setIsModals(() => {
                    return { division: true };
                  });
                  setDataFilterBackup(JSON.parse(JSON.stringify(listDivision)));
                }}
                value={
                  listDivision &&
                  (!listDivision[0]?.status
                    ? listDivision
                        .map((item, i) =>
                          item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                        )
                        .filter((it) => it)
                    : t('employees.all'))
                }
                placeholder={t('contract.contractRetire.division')}
              />
              <ModalCommon
                items={listDivision}
                onSubmit={() => {
                  let datas = [];
                  listDivision.forEach((it) => {
                    it.status && it.id && datas.push(it.id);
                  });
                  setFilter({
                    ...filter,
                    division_id: datas,
                    page: Constants.DEFAULT_PAGE_MUI,
                  });
                  setIsModals((prev) => {
                    return { ...prev, division: false };
                  });
                }}
                onClick={changeSelectDivision}
                toggle={() => {
                  setIsModals((prev) => {
                    return { ...prev, division: !prev.division };
                  });
                  setListDivision([...dataFilterBackup]);
                }}
                modal={isModals.division}
                title={t('contract.contractRetire.division')}
                footer={t('common.search')}
              />
            </div>
            <div className={classNames(`text padding-element-filter`)}>
              <InputBase
                readOnly
                type="text"
                name="project_id"
                className="exampleSelectMulti"
                onClick={() => {
                  setIsModals(() => {
                    return { project: true };
                  });
                  setDataFilterBackup(JSON.parse(JSON.stringify(listProject)));
                }}
                value={
                  listProject &&
                  (!listProject[0]?.status
                    ? listProject
                        .map((item, i) =>
                          item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                        )
                        .filter((it) => it)
                    : t('employees.all'))
                }
                placeholder={t('timekeeping.OT.project')}
              />
              <ModalCommon
                items={listProject}
                onSubmit={() => {
                  let datas = [];
                  listProject.forEach((it) => {
                    it.status && it.id && datas.push(it.id);
                  });
                  setFilter({
                    ...filter,
                    project_id: datas,
                    page: Constants.DEFAULT_PAGE_MUI,
                  });
                  setIsModals((prev) => {
                    return { ...prev, project: false };
                  });
                }}
                onClick={changeSelectProject}
                toggle={() => {
                  setIsModals((prev) => {
                    return { ...prev, project: !prev.project };
                  });
                  setListProject([...dataFilterBackup]);
                }}
                modal={isModals.project}
                title={t('timekeeping.OT.project')}
                footer={t('common.search')}
              />
            </div>

            {/* Filter by radio check */}
            <div>
              <FormControl component="fieldset">
                <FormLabel className={classNames(`text padding-element-filter border-top padding-element-top`)}>
                  {t('assets.type')}
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field} value={filter?.status ? filter?.status : 0}>
                      <FormControlLabel
                        value={0}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            status: null,
                            page: Constants.DEFAULT_PAGE_MUI,
                          });
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                        control={CONTROL_RADIO}
                        label={t('employees.all')}
                      />

                      {Status?.map((item, index) => (
                        <div key={index} className="division d-flex justify-content-between">
                          <FormControlLabel
                            key={index}
                            value={item?.value}
                            onChange={(e) => {
                              setFilter({
                                ...filter,
                                status: e.target.value,
                                page: Constants.DEFAULT_PAGE_MUI,
                              });
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            control={
                              <Radio
                                sx={{
                                  color: COLOR_CHECKBOX_NOT_ACTIVE,
                                  '&.Mui-checked': {
                                    color: COLOR_CHECKBOX_CUSTOM,
                                  },
                                }}
                              />
                            }
                            label={item?.label}
                          />
                          <div
                            className={classNames(`dot-status-accept ${transferStatusToBackgroundColor(item?.value)}`)}
                          ></div>
                        </div>
                      ))}
                    </RadioGroup>
                  )}
                  name="asset_category_id"
                  control={control}
                  defaultValue={filter?.status ? filter?.status : 0}
                />
              </FormControl>
            </div>
            <div className="border-top">
              <ButtonBase onClick={() => handleReset()} className={classNames(`reset border-top`)} size="lg">
                <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                {t('common.reset')}
              </ButtonBase>
            </div>
          </div>
        </Grid>
        <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
          {message}
        </AlertComponent>
      </Grid>
      <SendMailOT
        textModal={t('timekeeping.OT.otMailDetail')}
        overtimes={uDataOT?.data?.data?.data ?? []}
        isShowModal={openOT}
        setIsShowModal={setOpenOT}
      />
      {/* Modal export OT */}
      <ModalExportOt
        isShowModal={isOpenExportModal}
        setShowModal={setIsOpenExportModal}
        filter={filter}
        page={filter?.page}
        perPage={filter?.per_page}
        uDataOTExport={uDataOTExport}
        isLoadingOT={isLoadingOTDemo}
      />
    </Grid>
  );
};

export default ListOT;
