import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import SideNavBar from 'components/SideNavBar';
import { TAB_PROGRAM } from 'constants/Training';
import { TAB_IN_COMMITMENT } from 'constants/Training';
import { TAB_END_OF_COMMITMENT } from 'constants/Training';
import { Modal } from 'antd';
import { t } from 'i18next';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const MONTH_FORMAT = 'MM/YYYY';
export const DATE_FORMAT_YMD = 'YYYY-MM-DD';
export const MONTH_FORMAT_YM = 'YYYY-MM';

export const showConfirm = (props) => {
  Modal.confirm({
    icon: null,
    okText: t('common.confirm'),
    cancelText: t('common.cancel'),
    centered: true,
    className: 'dialog',
    okButtonProps: {
      className: 'btn btn__ok',
    },
    cancelButtonProps: {
      className: 'btn',
    },
    autoFocusButton: null,
    ...props,
    onOk: () => {
      props.onOk?.();
      Modal.destroyAll();
    },
  });
};

const Training = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory();
  const [value, setValue] = useState(TAB_PROGRAM);

  const routes = [
    {
      name: t('training.educationProgram'),
    },
    {
      name: t('training.inCommitment'),
    },
    {
      name: t('training.endOfCommitment'),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === TAB_PROGRAM) {
      history.push('/admin/training/program');
    }
    if (newValue === TAB_IN_COMMITMENT) {
      history.push('/admin/training/in-commitment');
    }
    if (newValue === TAB_END_OF_COMMITMENT) {
      history.push('/admin/training/end-of-commitment');
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/admin/training/program':
        setValue(TAB_PROGRAM);
        break;
      case '/admin/training/in-commitment':
        setValue(TAB_IN_COMMITMENT);
        break;
      case '/admin/training/end-of-commitment':
        setValue(TAB_END_OF_COMMITMENT);
        break;
      default:
        break;
    }
  }, [location.pathname]);

  return <SideNavBar routes={routes} onChange={handleChange} value={value} hasMarginBottom />;
};

export default Training;
