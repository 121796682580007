import uploadIcon from 'assets/img/icons/upload.png';
import { useMutation } from 'react-query';
import { apiUploadCommitFile } from 'api/training';
import { message } from 'antd';
const UploadCommitFile = ({ id, file, refetch, setIsHasCommitFile }) => {
  const uploadMutation = useMutation((file) => apiUploadCommitFile(id, file), {
    onSuccess: () => {
      refetch();
      setIsHasCommitFile(true);
    }
  });
  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];

    const validateFile = (selectedFile) => {
      if (selectedFile.type !== 'application/pdf') {
        return 'Vui lòng tải lên file PDF.';
      }
      if (selectedFile.size > 20 * 1024 * 1024) {
        return 'Vui lòng tải lên file dưới 20MB.';
      }
    }
    const errorMessage = validateFile(selectedFile);
    if (errorMessage) {
      message.error(errorMessage);
    } else {
      const formData = new FormData();
      formData.append('commit_file', selectedFile);

      try {
        await uploadMutation.mutateAsync(formData);
        message.success('Upload file thành công.');
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };
  const uploadFile = () => {
    const fileInput = document.getElementById("fileInput" + id);
    fileInput.click();
  };

  return (

    <div>
      <input id={"fileInput" + id} type="file" onChange={handleFileUpload} style={{ display: 'none' }} />
      <img
        src={uploadIcon}
        alt=""
        style={{ cursor: 'pointer' }}
        onClick={uploadFile}
      />
    </div>
  );
};
export default UploadCommitFile;