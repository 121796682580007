import React from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Breadcrumb = (props) => {
  const { t } = useTranslation();
  const { uProfileUser, tabCurrent } = props;

  return (
    <div className={styles.containerRoot}>
      <div md="8" className={styles.link}>
        {<NavLink to={{ pathname: '/admin/employees/list' }}>{t('menu.employees')}</NavLink>}
        &nbsp; / &nbsp;
        <div>{uProfileUser && uProfileUser?.data?.user_information?.name}</div>
        &nbsp; / &nbsp;
        <b>{tabCurrent}</b>
      </div>
    </div>
  );
};

export default Breadcrumb;
