import { sendGet, sendCustom, sendPost, sendPut, sendDelete } from '../helpers/axios';

export const apiStatisticalCategoryContract = (params) => sendGet('/api/contracts/contract-category', params);
export const apiStatisticalCategoryEmployees = (params) => sendGet('/api/contracts/contract-group', params);
export const apiStatisticalStatusContract = (params) => sendGet('/api/contracts/contract-status', params);

export const apiImportContract = (params) => sendPost(`/api/import/contract`, params);
export const apiDownloadTemplateImportContract = () =>
  sendCustom({
    url: '/api/download/template/import-contract',
    method: 'GET',
    responseType: 'blob',
    Accept: 'application/octet-stream',
  });

export const apiGetListRetireContract = (params) => sendGet('/api/checkout-request', params);
export const apiGetListDivisionContract = (params) => sendGet('/api/checkout-request/total', params);
export const apiExportRetireContract = () => sendGet('/api/checkout-request/export');
export const apiRequestListRetire = (ID) => sendGet(`/api/request/${ID}`);
export const apiExportWaitContract = () => sendGet('/api/contracts/export/checkout-request');

export const apiGeListMember = (params) => sendGet('/api/community/add-member-custom', params);

export const apiGetListContract = (params) => sendGet(`/api/contracts/list-contracts`, params);
export const apiGetDetailContract = (params) =>
  sendGet(`/api/contract-user/${params?.contractId}/infor`, { user_id: params?.userId });
export const apiGetContractHistory = (params) =>
  sendGet(`/api/contract-user/history-contract`, { contract_id: params?.contractId, user_id: params?.userId });

export const apiGetListCategoryEmployees = (params) => sendGet('/api/group', params);
export const apiGenerateCodeContract = (params) => sendGet('/api/contract-get-code', params);
export const apiCreateContract = (params) => sendPost('/api/contract-user/create', params);
export const apiUpdateContract = (params, userId) => sendPut(`api/contract-user/${userId}/update`, params);
export const apiListContractDivision = (params) => sendGet('/api/contracts/list-contracts/total-division', params);

export const apiGetListWaitContract = (params) => sendGet('/api/contracts/list-pending', params);
export const apiDeleteContract = (userId) => sendDelete(`api/contract-user/${userId}/delete`);
export const apiListContractDivisionPending = (params) => sendGet('/api/contracts/list-pending/total-division', params);
export const apiGetTotalEmployees = () => sendGet('/api/dashboard-contract-user');
export const apiCheckContractOnboardUser = (params) => sendGet('/api/contracts/check-onboard', params);

export const apiUpdateRequestRetire = (params) => sendPut(`/api/checkout-request/update/${params.request_id}`, params);
