import { styled } from '@mui/material/styles';
const { Tooltip } = require('@mui/material');

export const StyledTooltip = styled((props) => <Tooltip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-tooltip {
    background-color: #f3f7fb;
    padding: 17px !important;
    color: #333333;

    .employ-name {
      font-size: 16px;
      color: #223354;
    }
  }
`;
