import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import ModalComponent from 'components/Modal';
import { YupValidateCreateAssets } from '../YupValidate/yupValidate';
import { useListCategoryAllAssets, useGetAssetRentalPrice, useListHrWarehouse, useGenerateCode } from 'hook/useAssets';
import SelectField from 'components/ReactForm/SelectField';
import Loading from 'components/Loading';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import { MAX_LENGTH_20, MAX_LENGTH_50, MAX_LENGTH_1000, MAX_LENGTH_15, MAX_LENGTH_3 } from 'constants/index.js';
import CustomInputNumber from 'components/InputYupAndMui/CusromInputNumber';
import { apiCreateAssets } from 'api/assets';
import { HTTP_OK, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import {
  USE_LIST_ASSETS,
  LIST_TYPE_GOOD,
  LIST_TYPE_UNDER_WARRANTY,
  LIST_TYPE_WAIT_FOR_LIQUIDATION,
  LIST_TYPE_LIQUIDATION,
  LIST_TYPE_BROKEN,
  LIST_TYPE_MISSING,
  LIST_TYPE_WAIT_FOR_APPROVE,
} from 'constants/Assets';
import ButtonComponent from 'components/Buttons';
import DateDayCustomInput from 'components/ReactForm/DateDayCustomInput.js';
import './style.scss';

function ModalCreateAssets({ isShowModal, setShowModal, setShowAlert, setMessage }) {
  const { t } = useTranslation();
  const [disableBtn, setDisableBtn] = useState(false);
  const categoryRef = useRef(null);
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(YupValidateCreateAssets()),
  });

  // Data
  const { data: uListCategoryAssets, isLoading: isLoadingListCategoryAssets } = useListCategoryAllAssets();
  const { data: uListHrWarehouse, isLoading: isLoadingListHrWarehouse } = useListHrWarehouse();
  const { data: uListAssets, isLoading: isLoadingListAsset } = useGetAssetRentalPrice()

  // Add item
  const { mutate: addItem } = useMutation(apiCreateAssets, {
    onSettled: (response) => {
      if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
        setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
        setShowAlert(true);
        setDisableBtn(false);
        return;
      }
      if (response.data.status === HTTP_OK) {
        setShowAlert(true);
        setMessage(response.data.messages);
        setDisableBtn(false);
        setShowModal(false);
        queryClient.refetchQueries(USE_LIST_ASSETS);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      if (response.data.messages.code[0] === t('messages.assets.usedAssetsCode') && response.data.status === 422) {
        setError(
          'code',
          {
            type: 'required',
            message: t('messages.assets.usedAssetsCode'),
          },
          { shouldFocus: true },
        );
      }
      setDisableBtn(false);
    },
  });

  // Watch value select category
  const watchAssetCategoryId = useWatch({
    control,
    name: 'asset_category_id',
    defaultValue: '',
  });
  const { data: uGenerateCode, isLoading: isLoadingGenerateCode } = useGenerateCode(watchAssetCategoryId);

  useEffect(() => {
    setValue('code', uGenerateCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAssetCategoryId]);

  // Watch value select price
  const watchPrice = useWatch({
    control,
    name: 'price',
  });

  // Watch value select period
  const watchPeriod = useWatch({
    control,
    name: 'period',
  });

  useEffect(() => {
    if (watchPrice && watchPeriod) {
      setValue('priceRent', watchPrice / watchPeriod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPrice, watchPeriod]);

  // List type
  const listType = [
    {
      id: LIST_TYPE_GOOD,
      name: t('assets.good'),
    },
    {
      id: LIST_TYPE_MISSING,
      name: t('assets.missing'),
    },
    {
      id: LIST_TYPE_UNDER_WARRANTY,
      name: t('assets.underWarranty'),
    },
    {
      id: LIST_TYPE_BROKEN,
      name: t('assets.broken'),
    },
    {
      id: LIST_TYPE_WAIT_FOR_LIQUIDATION,
      name: t('assets.waitForLiquidation'),
    },
    {
      id: LIST_TYPE_LIQUIDATION,
      name: t('assets.liquidation'),
    },
    {
      id: LIST_TYPE_WAIT_FOR_APPROVE,
      name: t('assets.waitForApprove'),
    },
  ];

  // Submit data
  const onSubmit = (data) => {
    const dataToSend = {
      asset_category_id: Number(data.asset_category_id),
      asset_rental_price_id: Number(data.asset_rental_price_id),
      code: data?.code,
      price: Number(data.price),
      period: Number(data.period),
      warehouse_id: Number(data.warehouse_id),
      input_date: data.input_date,
      out_date: data.out_date,
      type: Number(data.type),
      name: data.name,
      description: data.description,
    };
    setDisableBtn(true);
    addItem(dataToSend);
  };

  // scroll to view when errors category
  const handleFocusErrors = () => {
    if (errors?.asset_category_id?.message) {
      categoryRef.current.scrollIntoView();
    }
  };

  // Clear data when close modal
  useEffect(() => {
    if (!isShowModal) {
      reset({
        asset_category_id: '',
        asset_rental_price_id: '',
      });
    }
  }, [isShowModal, reset]);

  const assetsTypeByCategory = (categoryId, assets) => {
    const assetsType = assets?.filter(asset => Number(asset.asset_category_id) === Number(categoryId))
    return assetsType
  }

  return (
    <>
      <ModalComponent
        toggle={() => {
          setShowModal(!isShowModal);
          if (!isShowModal) {
            reset();
            setDisableBtn(false);
          }
        }}
        isShowModal={isShowModal}
        title={t('assets.addAssets')}
        classNameAdd="modal-add-assets"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Category */}
          <section ref={categoryRef}>
            {isLoadingListHrWarehouse && <Loading addClass="mt-3 mb-3" />}
            {!isLoadingListHrWarehouse && (
              <SelectField
                name="asset_category_id"
                label={t('assets.category')}
                error={errors?.asset_category_id?.message}
                clearErrors={clearErrors}
                defaultValue={''}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
              >
                {uListCategoryAssets &&
                  uListCategoryAssets.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </SelectField>
            )}
          </section>
          {/* Asset type */}
          <section className="field-form">
            {isLoadingListAsset && <Loading addClass="mt-3 mb-3" />}
            {!isLoadingListAsset && (
              <SelectField
                name="asset_rental_price_id"
                label={t('masterData.assets.category.rentalPrices.table.columns.categoryName')}
                error={errors?.asset_rental_price_id?.message}
                defaultValue={''}
                control={control}
                required
                setValue={setValue}
                scrollItem={true}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                disabled={!watchAssetCategoryId}
                isRequired
              >
                {assetsTypeByCategory(watchAssetCategoryId, uListAssets?.data).map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </section>
          {/* Assets code */}
          <section className="field-form">
            {isLoadingGenerateCode && <Loading addClass="mt-3 mb-3" />}
            {!isLoadingGenerateCode && (
              <CustomInputYup
                placeholder={t('assets.assetsCode')}
                defaultValue={uGenerateCode || ''}
                label={t('assets.assetsCode')}
                name="code"
                errors={errors}
                clearErrors={clearErrors}
                control={control}
                register={register}
                useWatch={useWatch}
                required
                setValue={setValue}
                maxLength={MAX_LENGTH_20}
              />
            )}
          </section>

          {/* Assets name */}
          <section className="field-form">
            <CustomInputYup
              placeholder={t('assets.assetsName')}
              defaultValue={''}
              label={t('assets.assetsName')}
              name="name"
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              register={register}
              useWatch={useWatch}
              required
              setValue={setValue}
              maxLength={MAX_LENGTH_50}
            />
          </section>

          {/* Assets price */}
          <section className="field-form field-added-placeholder">
            <CustomInputNumber
              placeholder={t('assets.assetsPrice')}
              defaultValue={null}
              label={t('assets.assetsPrice')}
              name="price"
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              register={register}
              useWatch={useWatch}
              required
              setValue={setValue}
              thousandSeparator
              isInteger
              maxLength={MAX_LENGTH_15}
            />
            <span className="placeholder-added">{t('common.currency')}</span>
          </section>

          {/* Assets period */}
          <section className="field-form field-added-placeholder">
            <CustomInputNumber
              placeholder={t('assets.assetsPeriod')}
              defaultValue={null}
              label={t('assets.assetsPeriod')}
              name="period"
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              register={register}
              useWatch={useWatch}
              required
              setValue={setValue}
              isInteger
              maxLength={MAX_LENGTH_3}
            />
            <span className="placeholder-added">{t('common.month')}</span>
          </section>

          {/* Assets price rent */}
          <section className="field-form field-added-placeholder">
            <CustomInputNumber
              placeholder={t('assets.priceRent')}
              defaultValue={null}
              label={t('assets.priceRent')}
              name="priceRent"
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              register={register}
              useWatch={useWatch}
              required
              setValue={setValue}
              thousandSeparator
              disabled
              isInteger
            />
            <span className="placeholder-added">{t('assets.VNDMonth')}</span>
          </section>

          {/* Warehouse */}
          <section className="field-form">
            {isLoadingListCategoryAssets && <Loading addClass="mt-3 mb-3" />}
            {!isLoadingListCategoryAssets && (
              <SelectField
                name="warehouse_id"
                label={t('assets.warehouseName')}
                error={errors?.warehouse_id?.message}
                defaultValue={''}
                // onHandleSelect={handelChangeInput}
                control={control}
                required
                setValue={setValue}
                clearErrors={clearErrors}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
              >
                {uListHrWarehouse &&
                  uListHrWarehouse.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </SelectField>
            )}
          </section>

          {/* Input date */}
          <section className="field-form">
            <DateDayCustomInput
              label={t('assets.inputDate')}
              name="input_date"
              error={errors?.input_date?.message}
              defaultValue={null}
              control={control}
              setValue={setValue}
              register={register}
              setError={setError}
              isShowModal={isShowModal}
              disabled={false}
              isReadOnly={false}
            />
          </section>

          {/* Output date */}
          <section className="field-form">
            <DateDayCustomInput
              label={t('assets.outDate')}
              name="out_date"
              error={errors?.out_date?.message}
              defaultValue={null}
              control={control}
              setValue={setValue}
              register={register}
              setError={setError}
              isShowModal={isShowModal}
              disabled={false}
              isReadOnly={false}
            />
          </section>
          {/* Type */}
          <section className="field-form">
                <SelectField
                  name="type"
                  label={t('assets.type')}
                  error={errors?.type?.message}
                  clearErrors={clearErrors}
                  defaultValue={LIST_TYPE_WAIT_FOR_APPROVE}
                  // onHandleSelect={handelChangeInput}
                  control={control}
                  required
                  setValue={setValue}
                  useWatch={useWatch}
                  register={register}
                  isShowModal={isShowModal}
                  isRequired
                  disabled
                >
                  {listType.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </section>
          {/* Description */}
          <section className="field-form">
            <CustomInputYup
              placeholder={t('assets.description')}
              defaultValue={''}
              label={t('assets.description')}
              name="description"
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              register={register}
              useWatch={useWatch}
              setValue={setValue}
              maxLength={MAX_LENGTH_1000}
              multiline
              rows={6}
            />
          </section>

          {/* Bottom button */}
          <Grid container className="mt-5">
            <Grid item xs={12} className="md-6" onClick={handleFocusErrors}>
              <ButtonComponent
                text={t('assets.addAssets')}
                addClass="btn-submit w-100"
                handleClick={handleSubmit(onSubmit)}
                type="submit"
                hasDisabled={disableBtn}
                isLoading={disableBtn}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </>
  );
}

export default ModalCreateAssets;
