import React from 'react'
import Box from '@mui/material/Box'
import PolicyHeader from 'views/pages/Policy/PolicyHeader'

const PolicyOfTax = () => {
  return (
    <Box className="pb-5">
      <PolicyHeader />
    </Box>
  )
}

export default PolicyOfTax
