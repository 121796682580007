import React, {useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from "reactstrap";
import ModalComponent from 'components/Modal';
import { useForm } from 'react-hook-form';
import './style.scss';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ButtonComponent from "components/Buttons";
import { HTTP_OK } from 'constants/StatusCode.js';
import { apiSendMail } from 'api/sendMail.js';
import AlertComponent from 'components/Alert/index.js';


const editorConfiguration = {
    // plugins: [Alignment, Essentials, Bold, Italic, Paragraph],
    toolbar: [ 'heading', 'bold', 'italic', 'numberedList', 'undo', 'redo']
  };

const SendMailModal = (props) => {
    const { t } = useTranslation();
    const { isShowModal, setShowModal, textModal, date, userIds } = props;
    const {
        handleSubmit,
        clearErrors,
        reset,
      } = useForm({
        mode: 'onChange'
      });
      const [content, setContent] = useState();
      const handleCKeditorState = (event, editor) => {
        const data = editor.getData();
        setContent(data);
      }
      const [stateButton, setStateButton] = useState(false);
      const [isShowAlert, setShowAlert] = useState(false);
      const [message, setMessage] = useState('');
      
      const handleSubmitForm = async () => {
        setStateButton(true);
        const params = {}
        if (userIds.length) {
          params.user_ids = userIds
        }
        let response = {};
        response = await apiSendMail({
          ...params,
          content_email: content ? content : '',
          date:date
        });

        if (response.status === HTTP_OK) {
          setMessage(t('timekeeping.sendMailSuccess'));
          setShowAlert(true);
          setStateButton(false);
        } else {
          setMessage(t('timekeeping.sendMailFailed'));
          setShowAlert(true);
          setStateButton(false);
        }
      }
    return (
        <>
          <ModalComponent
            toggle={() => setShowModal(!isShowModal)}
            isShowModal={isShowModal}
            title={textModal.title}
            classNameAdd="send-mail-modal modal-body-custom"
            classNameBody='body-custom'
          >
            <Form className="" onSubmit={handleSubmit(handleSubmitForm)}>
              
              <div className="">
              <div className="header">
                <div>
                  <div className="text-modal text-header-body-modal">{t('timekeeping.information')}</div>
                  <div className="text-modal text-info-body-modal">{t('timekeeping.sumWorkTime')} :</div>
                  <div className="text-modal text-info-body-modal">{t('timekeeping.sumLateDay')} :</div>
                  <div className="text-modal text-info-body-modal">{t('timekeeping.sumLateMinute')} :</div>
                  <div className="text-modal text-info-body-modal">{t('timekeeping.sumFines')} :</div>
                </div>
              </div>
      
                <div className="content-email-modal">
                  <div className="text-modal text-header-body-modal text-modal-content-email">{t("timekeeping.detailMailTimesheet")}</div>
                  <div>
                    <CKEditor
                      editor={ClassicEditor}
                      data={''}
                      config={editorConfiguration}
                      onChange={(event, editor) => {
                        handleCKeditorState(event, editor);
                      }}
                    />
                  </div>
                </div>

                <div className="footer-email-modal">
                  <div className="text-modal text-header-body-modal text-template-table">{t("timekeeping.templateTableTimesheet")}</div>
                  <div>
                  <TableContainer>
                      <Table>
                        <TableHead className="asset-header">
                          <TableRow className="asset-header-row headTable">
                            <TableCell>{t('timekeeping.dateEmail')}</TableCell>
                            <TableCell>{t('timekeeping.shiftEmail')}</TableCell>
                            <TableCell>{t('timekeeping.workTimeEmail')}</TableCell>
                            <TableCell>{t('timekeeping.logsEmail')}</TableCell>
                            <TableCell>{t('timekeeping.lateTimeEmail')}</TableCell>
                            </TableRow>
                          </TableHead>
                      </Table>
                  </TableContainer>
                  </div>
                  <div className="">
                    <div className="btn-send-mail">
                      <ButtonComponent
                        text={t('timekeeping.cancelMail')}
                        addClass="button-cancel-custom btn-bg-yellow2"
                        handleClick={() => setShowModal(false)}
                      />
                      <ButtonComponent
                        text={t('timekeeping.sendMail')}
                        addClass="button-submit-custom btn-bg-yellow1"
                        type="submit"
                        handleClick={handleSubmit(handleSubmitForm)}
                        hasDisabled={stateButton}
                        isLoading={stateButton}
                      />
                    </div>
              
            </div>
                </div>
              </div>
            </Form>
          </ModalComponent>
          <AlertComponent toggle={() => {
            setShowAlert(!isShowAlert);
            setShowModal(false);
            clearErrors();
            reset();
          }} isShowAlert={isShowAlert}>
            {message}
          </AlertComponent>
        </>
      )
}

export default SendMailModal;
