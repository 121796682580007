import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Input } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { BiLinkExternal } from 'react-icons/bi';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputIcon from '@mui/icons-material/Input';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ButtonBase, Grid, IconButton, Menu, MenuItem } from '@mui/material';

import iconLoading from 'assets/img/icons/loading.png';
import iconSandClock from 'assets/img/icons/sandClock.png';
import imgAvatar from 'assets/img/theme/avatar_default.png';

import styles from './style.module.scss';

import AlertComponent from 'components/Alert';
import ButtonComponent from 'components/Buttons';
import CreateContractModal from './Modals/CreateContract.js';
import EditContractModal from './Modals/EditContract.js';
import DateDayMonthComponent from 'components/DateTime/DateMonth.js';
import Loading from 'components/Loading';
import ModalComponent from 'components/Modal';
import ModalImportContract from './modalImportContract';
import RadioComponent from 'components/RadioComponent/index.js';
import NavContract from '../Nav';
import SearchComponent from 'components/Search';
import {
  ZERO_STATUS_ACCEPT,
  ONE_STATUS_ACCEPT,
  TWO_STATUS_ACCEPT,
  CURRENT_CONTRACT,
  LIMIT_CONTRACT,
  OFF_CONTRACT,
} from 'constants/Contract';
import { DEFAULT_LIMIT, DEFAULT_PAGE_MUI, PER_PAGE_OPTION } from 'constants/index.js';
import ContractDetail from './ContractDetail';
import {
  useGetListCategoryEmployees,
  useStatisticalCategoryContract,
  useListContract,
  useListContractDivision,
} from 'hook/useContract';
import { apiDeleteContract } from 'api/contract.js';
import { USE_LIST_CONTRACT } from 'constants/Contract';
import { HTTP_OK, HTTP_INTERNAL_SERVER_ERROR, HTTP_EXPECTATION_FAILED, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';

import AddIcon from '@mui/icons-material/Add';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import { useLocation } from 'react-router-dom';
import { handleExportExcel } from 'helpers';
import { customDate } from 'helpers/formatDate.js';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE, DISABLE_BUTTON_ROLE } from 'constants/index.js';
import { handleOnError } from 'helpers';
import * as Constants from 'constants/ListEmployees';
import { STATUS_ACTIVE } from 'constants/ProfileEmployee';

const DEFAULT_FORM_DATA = {
  code: '',
  name: '',
  email: '',
  position: '',
  user_id: 0,
  type_contract: 0,
  type_employee: '',
  start_date: null,
  end_date: null,
};

const DEFAULT_FILTER = {
  start_date: null,
  end_date: null,
  division_id: '',
  key_word: '',
  type: CURRENT_CONTRACT,
  group_id: [],
  contract_category_id: [],
  page: DEFAULT_PAGE_MUI,
  per_page: DEFAULT_LIMIT,
};

const Contract = () => {
  let location = useLocation();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const dataInforUser = useContext(ThemContext);

  const [dataStatusContract, setDataStatusContract] = useState([]);
  const [isShowModal, setShowModal] = useState(false);
  const [isShowModalImport, setShowModalImport] = useState(false);

  const [isShowModalCreate, setShowModalCreate] = useState(false);
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const [formDataContract, setFormDataContract] = useState({});
  const [dataFilterBackup, setDataFilterBackup] = useState({});

  const [textModal] = useState(t('contract.contractRetire.detailRetire'));

  const [isOpenFilterCategoryEmployee, setIsOpenFilterCategoryEmployee] = useState(false);
  const [isOpenFilterCategoryContract, setIsOpenFilterCategoryContract] = useState(false);
  const [isOpenFilterStatusUser, setIsOpenFilterStatusUser] = useState(false);

  const [formData] = useState(DEFAULT_FORM_DATA);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const [selectedContractId, setSelectedContractId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isOnBoard, setIsOnBoard] = useState(null);
  const [selectedContract, setSelectedContract] = useState({});
  const [listCategoryEmployeeFilter, setListCategoryEmployeeFilter] = useState([]);
  const [listCategoryContractFilter, setListCategoryContractFilter] = useState([]);
  const [listContractDivisionFilter, setListContractDivisionFilter] = useState([]);
  const [statusUser, setStatusUser] = useState([
    { status: false, name: t('employees.all'), value: Constants.ALL },
    { status: false, name: t('infoEmployee.statusActive'), value: STATUS_ACTIVE },
    { status: false, name: t('infoEmployee.statusUnactive'), value: 2 },
  ]);
  const [contractId, setContractId] = useState();

  const [filter, setFilter] = useState(DEFAULT_FILTER);

  const {
    data: listContract,
    total_active: totalActive,
    total_duration: totalDuration,
    total_expired: totalExpired,
    total_pending: totalPending,
    isLoading: isLoadingListContract,
  } = useListContract({
    ...filter,
    page: filter?.page + 1,
  });
  const { data: listContractDivision } = useListContractDivision({ ...filter, page: filter?.page + 1 });
  const { data: listCategoryEmployee } = useGetListCategoryEmployees();
  const { data: listCategoryContract } = useStatisticalCategoryContract();

  const { control, setValue } = useForm({
    mode: 'onBlur',
  });

  const handleChangeRequest = (event) => {
    if (event.target.value === '') {
      setFilter({ ...filter, type: '', page: DEFAULT_PAGE_MUI });
    } else {
      setFilter({ ...filter, type: event.target.value, page: DEFAULT_PAGE_MUI });
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeDivision = (event) => {
    if (event.target.value === '') {
      setFilter({ ...filter, division_id: '', page: DEFAULT_PAGE_MUI });
    } else {
      setFilter({ ...filter, division_id: event.target.value, page: DEFAULT_PAGE_MUI });
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location && location.type !== undefined && location.type) {
      setFilter((prevState) => ({
        ...prevState,
        type: location.type,
      }));
    }
  }, [location]);

  useEffect(() => {
    if (listCategoryEmployee) {
      const category = listCategoryEmployee.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));

      setListCategoryEmployeeFilter([...category]);
    }
  }, [listCategoryEmployee, t]);

  useEffect(() => {
    if (listCategoryContract) {
      const category = listCategoryContract.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));

      setListCategoryContractFilter([...category]);
    }
  }, [listCategoryContract, t]);

  useEffect(() => {
    if (listContractDivision) {
      const contractDivision = [...listContractDivision];

      setListContractDivisionFilter(
        contractDivision.map((item) => ({
          ...item,
          status: false,
        })),
      );
    }
  }, [listContractDivision]);

  useEffect(() => {
    const statusContract = [
      {
        id: CURRENT_CONTRACT,
        name: t('contract.contractNav.currentContract'),
        total_member: totalActive,
        status: false,
      },
      { id: LIMIT_CONTRACT, name: t('contract.contractNav.limitContract'), total_member: totalDuration, status: false },
      { id: OFF_CONTRACT, name: t('contract.contractNav.offContract'), total_member: totalExpired, status: false },
    ];

    setDataStatusContract(statusContract);
  }, [t, totalActive, totalDuration, totalExpired]);

  const leaderStatus = (leader_status) => {
    if (leader_status === ZERO_STATUS_ACCEPT) {
      return <div className={styles.colorWaiting}>{t('contract.contractRetire.waitingAccept')}</div>;
    } else if (leader_status === ONE_STATUS_ACCEPT) {
      return <div className={styles.colorDeny}>{t('contract.contractRetire.deny')}</div>;
    } else {
      return <div className={styles.colorAccept}>{t('contract.contractRetire.accept')}</div>;
    }
  };

  const handleSearch = (value) => {
    setFilter({ ...filter, key_word: value, page: DEFAULT_PAGE_MUI });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStartDate = (value) => {
    setFilter({ ...filter, start_date: customDate(value, 'MM/YYYY'), page: DEFAULT_PAGE_MUI });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleEndDate = (value) => {
    setFilter({ ...filter, end_date: customDate(value, 'MM/YYYY'), page: DEFAULT_PAGE_MUI });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleAcceptHR = () => {
    setMessage([t('messages.retireContract.acceptHrFirst'), t('messages.retireContract.acceptHrSecond')]);
    setShowModal(false);
    setShowAlert(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {
    setSelectedContract(item);
    setContractId(item.contract_id);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleOpenModalContractDetail = (item) => {
    setIsOnBoard(item.is_onboard);
    setSelectedContractId(item.contract_id);
    setSelectedUserId(item.user_id);
  };

  const handleCloseModalContractDetail = () => {
    setSelectedContractId(null);
    setSelectedUserId(null);
  };

  const handleShowMessage = (value) => {
    if (value) {
      setMessage(value);
      setShowAlert(true);
    }
  };

  const handleModalEditContract = (event) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(null);
    }
    setFormDataContract({ ...selectedContract });
    setShowModalEdit(!isShowModalEdit);
  };

  const handleModalCreateContract = (event) => {
    if (dataInforUser?.hr_permission?.contracts_permission === ROLE_VIEW) {
      return;
    }
    setFormDataContract({});
    setShowModalCreate(!isShowModalCreate);
  };

  const changeSelectCategoryFilter = (item) => {
    const selectItems = [...listCategoryContractFilter];

    setListCategoryContractFilter(selectItems.map((el) => (el.id === item.id ? { ...el, status: !el.status } : el)));
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI });
  };

  const handleSubmitCategoryContract = () => {
    setFilter({
      ...filter,
      contract_category_id: listCategoryContractFilter?.filter((el) => el.status)?.map((el) => el.id) || [],
    });

    setIsOpenFilterCategoryContract(!isOpenFilterCategoryContract);
  };

  const changeSelectCategoryEmployeeFilter = (item) => {
    const selectItems = [...listCategoryEmployeeFilter];

    setListCategoryEmployeeFilter(selectItems.map((el) => (el.id === item.id ? { ...el, status: !el.status } : el)));
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI });
  };

  const changeSelectSelectStatusFilter = (item) => {
    const selectItems = [...statusUser];

    const result = selectItems.find((it) => it.value !== item.value && it.value > 0 && it.status);
    if (result && item.status === false) {
      setStatusUser(
        selectItems.map((el) => (el.value === Constants.ALL ? { ...el, status: true } : { ...el, status: false })),
      );
    } else {
      setStatusUser(
        selectItems.map((el) => (el.value === item.value ? { ...el, status: !el.status } : { ...el, status: false })),
      );
    }

    setFilter({ ...filter, page: DEFAULT_PAGE_MUI });
  };

  const handleSubmitCategoryEmployee = () => {
    setFilter({
      ...filter,
      group_id: listCategoryEmployeeFilter?.filter((el) => el.status)?.map((el) => el.id) || [],
    });

    setIsOpenFilterCategoryEmployee(!isOpenFilterCategoryEmployee);
  };

  const handleSubmitStatusUser = () => {
    setFilter({
      ...filter,
      status: statusUser?.find((el) => el.status && Number(el.value) > 0)?.value || null,
    });

    setIsOpenFilterStatusUser(!isOpenFilterStatusUser);
  };

  const handleResetFilter = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFilter(DEFAULT_FILTER);
    const resetStatus = statusUser.map((user) => ({
      ...user,
      status: false
    }))
    setStatusUser(resetStatus)
    const categoryEmployee = listCategoryEmployeeFilter.map((item) => ({
      ...item,
      status: false,
    }));

    setListCategoryEmployeeFilter(categoryEmployee);

    const categoryContract = listCategoryContractFilter.map((item) => ({
      ...item,
      status: false,
    }));

    setListCategoryContractFilter(categoryContract);

    const contractDivision = listContractDivisionFilter.map((item) => ({
      ...item,
      status: false,
    }));

    setListContractDivisionFilter(contractDivision);
  };

  const alertConfirm = (id) => {
    confirmAlert({
      title: t('infoEmployee.youWantToDelete'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteContract(id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_OK) {
              setSelectedContractId(null);
              setSelectedUserId(null);

              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_LIST_CONTRACT);
              setAnchorEl(null);
            }

            if (
              response?.data.status === HTTP_INTERNAL_SERVER_ERROR ||
              response?.data.status === HTTP_EXPECTATION_FAILED
            ) {
              setMessage(response?.data?.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_LIST_CONTRACT);
              setAnchorEl(null);
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <div className={styles.content}>
        <Grid container>
          <Grid container className="nav-fixed">
            <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
              <Grid container>
                <NavContract />
              </Grid>
            </Grid>
            <Grid item xs sm md lg xl className={styles.button}>
              <ButtonComponent
                addClass={`${styles.export} ${
                  dataInforUser?.hr_permission?.contracts_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                }`}
                icon={<InputIcon />}
                text="Import"
                handleClick={() => {
                  if (dataInforUser?.hr_permission?.contracts_permission === ROLE_VIEW) {
                    return;
                  }
                  setShowModalImport(!isShowModalImport);
                }}
              />
              <ButtonComponent
                addClass={styles.export}
                icon={<BiLinkExternal />}
                text="Export"
                handleClick={() => handleExportExcel('/api/contracts/export/excel', filter, 'contract_list.xlsx')}
              />
              <ButtonComponent
                addClass={`${styles.export} ${
                  dataInforUser?.hr_permission?.contracts_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                }`}
                icon={<AddIcon />}
                text={t('contract.createNew')}
                handleClick={handleModalCreateContract}
              />
            </Grid>
          </Grid>
          <Grid item md={9} className={styles.assetMain}>
            <div className={styles.profileBox}>
              <Paper>
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead className={styles.assetHeader}>
                      <TableRow className={styles.assetHeaderRow}>
                        <TableCell align="center">{t('contract.contractNav.code')}</TableCell>
                        <TableCell align="center">{t('contract.contractNav.name')}</TableCell>
                        <TableCell align="center">{t('employees.email')}</TableCell>
                        <TableCell align="center">{t('contract.contractNav.division')}</TableCell>
                        <TableCell align="center">{t('contract.contractNav.position')}</TableCell>
                        <TableCell align="center">{t('contract.contractNav.skill')}</TableCell>
                        <TableCell align="center">{t('contract.contractNav.typeEmployee')}</TableCell>
                        <TableCell align="center">{t('contract.contractNav.typeContract')}</TableCell>
                        <TableCell align="center">{t('contract.contractNav.startDate')}</TableCell>
                        <TableCell align="center">{t('contract.contractNav.endDate')}</TableCell>
                        <TableCell className={styles.width60}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoadingListContract && (
                        <TableRow>
                          <TableCell colSpan={9}>
                            <Loading />
                          </TableCell>
                        </TableRow>
                      )}
                      {listContract?.data?.map((item, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            onClick={() => handleOpenModalContractDetail(item)}
                            className={styles.tableRow}
                          >
                            <TableCell>{item?.code}</TableCell>
                            <TableCell>
                              <div className={styles.linkRequest}>
                                {item?.name ? item?.name : <>{t('infoEmployee.notUpdate')}</>}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div>{item?.email ? item?.email : <>{t('infoEmployee.notUpdate')}</>}</div>
                            </TableCell>
                            <TableCell>
                              {item?.division_name ? item?.division_name : <>{t('infoEmployee.notUpdate')}</>}
                            </TableCell>
                            <TableCell>
                              {item?.position_name || t('infoEmployee.notUpdate')}
                            </TableCell>
                            <TableCell>
                              {item?.skills.length > 2
                                ? item?.skills
                                    .slice(0, 2)
                                    .map((item) => item.name)
                                    .join(', ') + ' ...'
                                : item?.skills.map((item) => item.name).join(', ') || t('infoEmployee.notUpdate')}
                            </TableCell>
                            <TableCell>
                              {item?.group_name ? item?.group_name : <>{t('infoEmployee.notUpdate')}</>}
                            </TableCell>
                            <TableCell>
                              {item?.contract_category_name ? item.contract_category_name : t('infoEmployee.notUpdate')}
                            </TableCell>
                            <TableCell>
                              {item?.start_date ? item?.start_date : <>{t('infoEmployee.notUpdate')}</>}
                            </TableCell>
                            <TableCell>
                              {item?.end_date ? item?.end_date : <>{t('infoEmployee.notUpdate')}</>}
                            </TableCell>
                            <TableCell>
                              <div className={styles.floatRight}>
                                <IconButton
                                  className={`${
                                    dataInforUser?.hr_permission?.contracts_permission === ROLE_VIEW && DISABLE_ROLE
                                  }`}
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls="long-menu"
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={(event) =>
                                    dataInforUser?.hr_permission?.contracts_permission !== ROLE_VIEW &&
                                    handleClick(event, item)
                                  }
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}

                      {!listContract?.data?.length && !isLoadingListContract && (
                        <tr>
                          <td colSpan={9} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {listContract?.total > 0 && (
                  <TablePagination
                    rowsPerPageOptions={PER_PAGE_OPTION}
                    component="div"
                    count={listContract?.total}
                    rowsPerPage={filter?.per_page}
                    page={filter?.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="table-pagination"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                  />
                )}
              </Paper>
              <ContractDetail
                isOnBoard={isOnBoard}
                contractId={selectedContractId}
                userId={selectedUserId}
                toggle={handleCloseModalContractDetail}
                onHandleDelete={(contractId) => alertConfirm(contractId)}
              />
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: '220px',
                    width: '20ch',
                  },
                }}
              >
                <MenuItem onClick={handleModalEditContract}>
                  <HiPencil />
                  {t('divisionDiagram.update')}
                </MenuItem>
                <MenuItem onClick={() => alertConfirm(contractId)}>
                  <MdDelete />
                  {t('divisionDiagram.delete')}
                </MenuItem>
              </Menu>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className={styles.boxWaiting} onClick={() => history.push('/admin/contract/waiting-contract')}>
              <div className={styles.boxWaitingContent}>
                <img src={iconSandClock} alt={iconSandClock} />
                <div className={styles.boxWaitingText}>
                  <div className={styles.waitingText}>{t('contract.waitingContract')}</div>
                  <div className={styles.waitingNumber}>{totalPending}</div>
                </div>
              </div>
            </div>
            <div className={styles.boxFilter}>
              <div className={styles.paddingElementFilter}>
                <SearchComponent
                  txtPlaceholder={t('contract.contractRetire.search')}
                  addClass="input-search-box"
                  handleSearch={handleSearch}
                  defaultValue={filter?.key_word}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="typeContract"
                  className={styles.exampleSelectMulti}
                  value={
                    listCategoryContractFilter
                      ?.filter((el) => filter?.contract_category_id?.includes(el.id))
                      ?.map((el) => el.name)
                      ?.join(', ') || ''
                  }
                  readOnly
                  onClick={() => {
                    setIsOpenFilterCategoryContract(true);
                    setDataFilterBackup([...listCategoryContractFilter]);
                  }}
                  placeholder={t('contract.contractNav.typeContract')}
                />

                <ModalCommon
                  items={listCategoryContractFilter}
                  onSubmit={handleSubmitCategoryContract}
                  onClick={changeSelectCategoryFilter}
                  toggle={() => {
                    setIsOpenFilterCategoryContract(!isOpenFilterCategoryContract);
                    setListCategoryContractFilter([...dataFilterBackup]);
                  }}
                  modal={isOpenFilterCategoryContract}
                  title={t('contract.contractType')}
                  footer={t('common.search')}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="typeEmployee"
                  className={styles.exampleSelectMulti}
                  value={
                    listCategoryEmployeeFilter
                      ?.filter((el) => filter?.group_id?.includes(el.id))
                      ?.map((el) => el.name)
                      ?.join(', ') || ''
                  }
                  readOnly
                  onClick={() => {
                    setIsOpenFilterCategoryEmployee(true);
                    setDataFilterBackup([...listCategoryEmployeeFilter]);
                  }}
                  placeholder={t('contract.contractNav.typeEmployee')}
                />
                <ModalCommon
                  items={listCategoryEmployeeFilter}
                  onSubmit={handleSubmitCategoryEmployee}
                  onClick={changeSelectCategoryEmployeeFilter}
                  toggle={() => {
                    setIsOpenFilterCategoryEmployee(!isOpenFilterCategoryEmployee);
                    setListCategoryEmployeeFilter([...dataFilterBackup]);
                  }}
                  modal={isOpenFilterCategoryEmployee}
                  title={t('contract.employeeType')}
                  footer={t('common.search')}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="typeEmployee"
                  className={styles.exampleSelectMulti}
                  value={
                    statusUser
                      ?.filter((el) => el.status)
                      ?.map((el) => el.name)
                      ?.join(', ') || ''
                  }
                  readOnly
                  onClick={() => {
                    setIsOpenFilterStatusUser(true);
                    setDataFilterBackup([...statusUser]);
                  }}
                  placeholder={t('contract.contractNav.statusEmployee')}
                />
                <ModalCommon
                  items={statusUser}
                  onSubmit={handleSubmitStatusUser}
                  onClick={changeSelectSelectStatusFilter}
                  toggle={() => {
                    setIsOpenFilterStatusUser(!isOpenFilterStatusUser);
                    setStatusUser([...dataFilterBackup]);
                  }}
                  modal={isOpenFilterStatusUser}
                  title={t('contract.contractNav.statusEmployee')}
                  footer={t('common.search')}
                />
              </div>
              <div className={styles.paddingElementFilter}>
                <DateDayMonthComponent
                  label=""
                  name="start_date"
                  valueDefault={
                    typeof filter.start_date === 'string' ? moment(filter.start_date, 'MM/YYYY') : filter.start_date
                  }
                  onChangeHandle={(value) => handleStartDate(value)}
                  placeholder={t('contract.contractNav.chooseStartDate')}
                  sizeDate="small"
                  max={moment(filter.end_date, 'MM/YYYY').toDate()}
                />
              </div>
              <div className={styles.paddingElementFilter}>
                <DateDayMonthComponent
                  label=""
                  name="end_date"
                  valueDefault={
                    typeof filter.end_date === 'string' ? moment(filter.end_date, 'MM/YYYY') : filter.end_date
                  }
                  onChangeHandle={(value) => handleEndDate(value)}
                  placeholder={t('contract.contractNav.chooseEndDate')}
                  sizeDate="small"
                  min={moment(filter.start_date, 'MM/YYYY').toDate()}
                />
              </div>
              <div className={classNames(`${styles.paddingElementTop} ${styles.borderTop}`)}>
                <FormControl component="fieldset">
                  <FormLabel className={classNames(`${styles.text} ${styles.marginElementBottom}`)}>
                    {t('contract.contractNav.statusContract')}
                  </FormLabel>
                  {dataStatusContract?.length > 0 && (
                    <RadioComponent
                      name="request"
                      control={control}
                      defaultValue={filter?.type}
                      isHaveAll={true}
                      radioList={dataStatusContract}
                      handleChange={handleChangeRequest}
                      setValue={setValue}
                    />
                  )}
                </FormControl>

                <FormControl component="fieldset">
                  <FormLabel
                    className={classNames(
                      `${styles.text} ${styles.marginElementBottom} ${styles.borderTop} ${styles.paddingElementTop}`,
                    )}
                  >
                    {t('contract.contractRetire.division')}
                  </FormLabel>
                  {listContractDivisionFilter?.length > 0 && (
                    <RadioComponent
                      name="division"
                      control={control}
                      defaultValue={filter?.division_id}
                      isHaveAll={true}
                      radioList={listContractDivisionFilter}
                      handleChange={handleChangeDivision}
                      setValue={setValue}
                    />
                  )}
                </FormControl>
              </div>
              <div className={styles.borderTop}>
                <ButtonBase
                  className={classNames(`${styles.reset} ${styles.borderTop}`)}
                  size="lg"
                  onClick={handleResetFilter}
                >
                  <img src={iconLoading} alt="Reset" width="20px" className={styles.imgReset} /> &emsp;
                  {t('common.reset')}
                </ButtonBase>
              </div>
            </div>
          </Grid>
        </Grid>

        <ModalComponent
          classNameAdd={styles.modal}
          toggle={() => setShowModal(!isShowModal)}
          isShowModal={isShowModal}
          title={textModal}
        >
          <div className={styles.infoDetail}>
            <div>
              {formData?.avatar !== '' ? (
                <img onError={handleOnError} className={styles.avatar} src={formData?.avatar} alt={formData?.avatar} />
              ) : (
                <img onError={handleOnError} className={styles.avatar} src={imgAvatar} alt={imgAvatar} />
              )}
            </div>
            <div className={styles.info}>
              <div className={styles.linkRequest}>{formData.name}</div>
              <div className={styles.email}>
                {formData.email} - {formData.position}
              </div>
              <div className={classNames(`${styles.flex} ${styles.statusInfo}`)}>
                {t('contract.contractRetire.status')}: &nbsp;{leaderStatus(formData?.leader_status)}
              </div>
              {formData.hr_status === ONE_STATUS_ACCEPT ? (
                formData.status_assets === ZERO_STATUS_ACCEPT ? (
                  <div className={classNames(`${styles.flex} ${styles.statusInfo}`)}>
                    {t('contract.contractRetire.status')}: &nbsp;
                    <div className={styles.colorWaiting}>{t('contract.contractRetire.notReceive')}</div>
                  </div>
                ) : (
                  <div className={classNames(`${styles.flex} ${styles.statusInfo}`)}>
                    {t('contract.contractRetire.status')}: &nbsp;
                    <div className={styles.colorWaiting}>{t('contract.contractRetire.receive')}</div>
                  </div>
                )
              ) : (
                ''
              )}
            </div>
            {formData.leader_status === TWO_STATUS_ACCEPT && formData.hr_status === ZERO_STATUS_ACCEPT ? (
              <div className={styles.buttonAcceptHr}>
                <ButtonComponent
                  addClass={styles.hrAccept}
                  text={t('contract.contractRetire.hrAccept')}
                  handleClick={handleAcceptHR}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={styles.infoProfile}>
            <Grid container>
              <Grid item md={6}>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.typePosition')}</div>
                  <div className={styles.infoProfileContent}>
                    {formData?.user_type ? formData?.user_type : <>{t('infoEmployee.notUpdate')}</>}
                  </div>
                </div>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.division')}</div>
                  <div className={styles.infoProfileContent}>
                    {formData?.division_name ? formData?.division_name : <>{t('infoEmployee.notUpdate')}</>}
                  </div>
                </div>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.position')}</div>
                  <div className={styles.infoProfileContent}>
                    {formData?.position ? formData?.position : <>{t('infoEmployee.notUpdate')}</>}
                  </div>
                </div>
              </Grid>
              <Grid item md={6}>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.accepter')}</div>
                  <div className={styles.infoProfileContent}></div>
                </div>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.dayOff')}</div>
                  <div className={styles.infoProfileContent}>
                    {formData?.checkout_date ? formData?.checkout_date : <>{t('infoEmployee.notUpdate')}</>}
                  </div>
                </div>
                <div className={styles.infoProfileElement}>
                  <div className={styles.infoProfileLabel}>{t('contract.contractRetire.daySendRequest')}</div>
                  <div className={styles.infoProfileContent}>
                    {formData?.request_date ? formData?.request_date : <>{t('infoEmployee.notUpdate')}</>}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </ModalComponent>
        <ModalImportContract toggle={() => setShowModalImport(!isShowModalImport)} modal={isShowModalImport} />
        <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
          <p className={styles.textLeft}>{message}</p>
        </AlertComponent>

        <CreateContractModal
          onSetShowModal={handleModalCreateContract}
          isShowModalAdd={isShowModalCreate}
          onSetMessage={handleShowMessage}
        />
        {isShowModalEdit && (
          <EditContractModal
            onSetShowModal={handleModalEditContract}
            isShowModalEdit={isShowModalEdit}
            onSetMessage={handleShowMessage}
            formDataContract={formDataContract}
          />
        )}
      </div>
    </>
  );
};

export default Contract;
