import '../style.scss';
import { MdClose } from 'react-icons/md';
import { Form, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_YMD } from '..';
import { useMutation } from 'react-query';
import { apiCreateCourse } from 'api/training';
import FormProgram from './FormProgram';
import { FIELD_KEY, CERTIFICATE, COURSE_OUTSIDE_COMPANY } from 'constants/Training';

const CreateProgram = ({ isOpen, setIsOpen, refetch }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const createCourseMutation = useMutation((payload) => apiCreateCourse(payload), {
    onSuccess: (res) => {
      message.success(res.data.messages);
      refetch();
      handleCancel();
    },
    onError: (error) => {
      message.error(error.response.data.messages);
    },
  });

  const handleFinish = (values) => {
    const type = values[FIELD_KEY.TYPE];

    let payload = {
      type,
      month_commit: values.month_commit,
      support_cost: values.support_cost,
      rate_commit: values.rate_commit,
      description: values.description?.trim(),
    };

    if (type === CERTIFICATE) {
      payload = {
        ...payload,
        certificate_id: values.certificate_id,
        certificate_catalog: values.certificate_catalog,
      };
    } else {
      payload = {
        ...payload,
        name: values.name?.trim(),
        start_date: values.start_date.format(DATE_FORMAT_YMD),
        end_date: values.end_date.format(DATE_FORMAT_YMD),
      };

      if (type === COURSE_OUTSIDE_COMPANY) {
        payload = {
          ...payload,
          location: values.location?.trim(),
        };
      }
    }

    createCourseMutation.mutate(payload);
  };

  const handleCancel = () => {
    setIsOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      wrapClassName={'custom-modal'}
      title={t('training.addProgram')}
      closeIcon={<MdClose color="#fea628" size={24} />}
      cancelButtonProps={{ className: 'btn-cancel' }}
      cancelText={t('common.cancel')}
      okButtonProps={{ className: 'btn-ok', loading: createCourseMutation.isLoading }}
      okText={t('common.save')}
      onCancel={handleCancel}
      onOk={() => {
        form.submit();
      }}
      visible={isOpen}
      centered
      width={470}
    >
      <FormProgram form={form} name={'create-program'} handleFinish={handleFinish} />
    </Modal>
  );
};

export default CreateProgram;
