import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import iconAssetQuantity from '../../../../assets/img/icons/asset_quantity.PNG';
import iconAssetValue from '../../../../assets/img/icons/asset_value.png';
import imgAssetHeaderLeft from '../../../../assets/img/theme/assetHeaderLeft.PNG';
import imgAssetHeaderRight from '../../../../assets/img/theme/assetHeaderRight.PNG';
import imgAvatar from '../../../../assets/img/theme/avatar.jpg';

import styles from './../style.module.scss';
import LayoutProfile from './../../../../components/LayoutProfile';
import NavProfile from './../Nav.js';
import Breadcrumb from './../Breadcrumb.js';
import { formatNumberCurrency } from '../../../../helpers/format.js';
import { useAssetsUser, useProfileUser } from './../../../../hook/useProfileEmployee.js';
import Loading from 'components/Loading';
import * as Constants from 'constants/index.js';
import {
  STATUS_ASSETS_MAINTAINER,
  STATUS_ASSETS_WAIT,
  STATUS_ASSETS_SELL,
  STATUS_ASSETS_BROKEN,
  STATUS_ASSETS_LOST,
} from './../../../../constants/Assets.js';

const DATA_USER = [
  {
    avatar: imgAvatar,
    name: 'Trần Ngọc Long',
    email: 'long.tran2@amela.vn',
    position: 'Developer',
    manager: 'Nguyễn Thành Tâm',
    dayOfQuitAvai: '4',
    dayOfQuited: '3',
    momentOfLate: '5',
    timeOfLate: '160',
    OT: '200',
    tienPhat: 1000000,
    dateOfBirth: '22/02/2000',
    gender: 0,
    address: 'Tầng 5, Tháp A, Toà Keangnam, Khu Đô thị mới E6 Cầu Giấy, Phạm Hùng, Mễ Trì, Nam Từ Liêm, Hà Nội',
    married: 'Chưa kết hôn',
    level: 'Intern',
    phone: '0123456789',
    division: 'Bonne',
    group: 'Developer',
    codeOfTax: '8116049400',
    nowSalary: '5000000',
    dayOfNowSalary: '5/9/2021',
    typeOfContract: 'HĐLĐ xác định thời hạn',
    termContract: '6/11/2021',
    CCCD: '0000000000',
    CCCDLocation: 'Hà Nội',
    CCCDday: '16/5/2014',
    status: 1,
  },
];

const UserAssets = () => {
  const { t } = useTranslation();

  const params = useParams();

  const userId = params.userId;

  const [filter, setFilter] = useState({ page: Constants.DEFAULT_PAGE_MUI, per_page: Constants.DEFAULT_LIMIT });

  const { data: uProfileUser } = useProfileUser('', userId);
  const { data: uAssetUser, isLoading: isLoadingListAssets } = useAssetsUser(filter, userId);

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getStatusAssets = (status) => {
    switch (status) {
      case STATUS_ASSETS_MAINTAINER:
        return <div>{t('dashboard.maintainer')}</div>;
      case STATUS_ASSETS_WAIT:
        return <div>{t('dashboard.waitSelling')}</div>;
      case STATUS_ASSETS_SELL:
        return <div>{t('dashboard.sell')}</div>;
      case STATUS_ASSETS_BROKEN:
        return <div>{t('dashboard.break')}</div>;
      case STATUS_ASSETS_LOST:
        return <div>{t('dashboard.lost')}</div>;
      default:
        return <div>{t('dashboard.good')}</div>;
    }
  };

  return (
    <>
      <div className={styles.containerRoot}>
        <div className={styles.rowInfo}>
          <Breadcrumb uProfileUser={uProfileUser} tabCurrent={t('infoEmployee.tabEmployeeAsset')} />
          <Row>
            <Col md="3">
              <LayoutProfile uProfileUser={uProfileUser} filterUser={DATA_USER} />
            </Col>
            <Col md="9">
              <NavProfile />
              <div className={styles.contentRoot}>
                <div className={styles.contentRoot_2}>
                  <Row className={styles.row}>
                    <Col md="6">
                      <div className={styles.assetTopLeft}>
                        <img
                          className={styles.imgAssetHeaderLeft}
                          alt={imgAssetHeaderLeft}
                          src={imgAssetHeaderLeft}
                        ></img>
                        <img
                          className={styles.iconAssetHeaderLeft}
                          alt={iconAssetQuantity}
                          src={iconAssetQuantity}
                        ></img>
                        <div className={styles.elementLeft}>{t('assetEmployee.totalQuantity')}</div>

                        <div className={styles.elementRight}>{uAssetUser && uAssetUser?.data?.total_asset}</div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className={styles.assetTopRight}>
                        <img
                          className={styles.imgAssetHeaderRight}
                          alt={imgAssetHeaderRight}
                          src={imgAssetHeaderRight}
                        ></img>
                        <img className={styles.iconAssetHeaderRight} alt={iconAssetValue} src={iconAssetValue}></img>
                        <div className={styles.elementLeft}>{t('assetEmployee.totalValue')}</div>

                        {uAssetUser &&
                          (uAssetUser?.data?.price_rent > 0 ? (
                            <div className={styles.elementRight}>
                              {uAssetUser && formatNumberCurrency(uAssetUser?.data?.price_rent)}
                            </div>
                          ) : (
                            <div className={styles.elementRight}>{uAssetUser && uAssetUser?.data?.price_rent}</div>
                          ))}
                      </div>
                    </Col>
                    <Col md="12" className={styles.assetMain}>
                      <div className={styles.profileBox}>
                        <Paper>
                          <TableContainer className={`${true && 'scroll-custom'}`}>
                            <Table aria-label="sticky table" className={`${true && 'table-scroll'}`}>
                              <TableHead className="asset-header">
                                <TableRow className="asset-header-row">
                                  <TableCell>{t('assetEmployee.code')}</TableCell>
                                  <TableCell>{t('assetEmployee.name')}</TableCell>
                                  <TableCell>{t('assetEmployee.quantity')}</TableCell>
                                  <TableCell>{t('assetEmployee.dateGive')}</TableCell>
                                  <TableCell>{t('assetEmployee.dateReceive')}</TableCell>
                                  <TableCell>{t('assetEmployee.status')}</TableCell>
                                  <TableCell>{t('assetEmployee.value')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {isLoadingListAssets && (
                                  <TableRow>
                                    <TableCell colSpan={7}>
                                      <Loading addClass="mt-3 mb-3" />
                                    </TableCell>
                                  </TableRow>
                                )}
                                {uAssetUser &&
                                  uAssetUser?.data?.assets?.data?.map((val, index) => {
                                    return (
                                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell>{val?.asset_code}</TableCell>

                                        <TableCell className={styles.nameAssets}>{val?.asset_name}</TableCell>

                                        <TableCell>{t('assetEmployee.numberQuantity')}</TableCell>

                                        <TableCell>{val?.transfer_date}</TableCell>

                                        <TableCell>
                                          {val?.return_date === null ? (
                                            <span>{t('common.notDataContract')}</span>
                                          ) : (
                                            val?.return_date
                                          )}
                                        </TableCell>

                                        <TableCell>{getStatusAssets(val?.status)}</TableCell>
                                        <TableCell>
                                          {val?.value_depreciation && formatNumberCurrency(val?.value_depreciation)}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}

                                {(uAssetUser?.data?.total_asset === 0) === 0 && (
                                  <tr>
                                    <td colSpan={7} className="error-no-data">
                                      {t('common.notData')}
                                    </td>
                                  </tr>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          {uAssetUser?.data?.assets?.total > 0 && (
                            <TablePagination
                              rowsPerPageOptions={Constants.PER_PAGE_OPTION}
                              component="div"
                              count={uAssetUser?.data?.assets?.total}
                              rowsPerPage={filter?.per_page}
                              page={filter?.page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              className="table-pagination"
                              labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                            />
                          )}
                        </Paper>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default UserAssets;
