import NavContract from '../Nav';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import * as ColorConstants from '../../../../constants/ColorMap.js';
import DateDayComponent from 'components/DateTime/DateDay.js';
import MapDoughnut from './MapDoughnut.js';
import iconContractDashboard from 'assets/img/icons/common/contracts/contract_dashboard.png';
import iconFile from 'assets/img/icons/common/contracts/file.png';
import Loading from 'components/Loading';
import { customDate } from 'helpers/formatDate';

import {
  useStatisticalCategoryContract,
  useStatisticalCategoryEmployees,
  useStatisticalStatusContract,
  useGetTotalEmployees,
  useGetListDivisionContract,
  useRequestRetire,
} from 'hook/useContract.js';
import { STATUS_RETIRE_HANDLED, STATUS_RETIRE_PROCESSING, TYPE_CONTRACT_WAITING } from 'constants/Contract';
import config from 'config';
import { NOT_DATA } from 'constants/index.js';

const FORMAT_DATE = 'DD/MM/YYYY';

const Dashboard = ({ isDashboard }) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState({ date: moment() });
  const [filterRequestRetire, setFilterRequestRetire] = useState({
    date: customDate(moment(), FORMAT_DATE),
    category_id: null,
  });

  const { data: dataCategoryContract, isLoading: isLoadingCategoryContract } = useStatisticalCategoryContract({
    date: customDate(filter.date, FORMAT_DATE),
  });
  const { data: dataCategoryEmployees, isLoading: isLoadingCategoryEmployees } = useStatisticalCategoryEmployees({
    date: customDate(filter.date, FORMAT_DATE),
  });
  const { data: statisticalContract, isLoading: isLoadingStatisticalContract } = useStatisticalStatusContract({
    date: customDate(filter.date, FORMAT_DATE),
  });
  const { data: totalEmployees } = useGetTotalEmployees();
  const { data: uListDivision, isLoading: isLoadingListDivision } = useGetListDivisionContract(filterRequestRetire);
  const { data: uRequestRetire, isLoading: isLoadingRequestRetire } = useRequestRetire(config.ID_RETIRE_REQUEST);

  useEffect(() => {
    setFilterRequestRetire({ ...filterRequestRetire, category_id: uRequestRetire?.code });
    // eslint-disable-next-line
  }, [uRequestRetire]);

  const handleFilterDate = (dateSearch) => {
    setFilter({ ...filter, date: dateSearch });
    setFilterRequestRetire({ ...filterRequestRetire, date: customDate(dateSearch, FORMAT_DATE) });
  };

  function ListItemLink(props) {
    const { icon, text, to, total } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef(function Link(itemProps, ref) {
          return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
        }),
      [to],
    );

    return (
      <li>
        <ListItem button component={renderLink} secondaryAction={total} className="li-list text-bold">
          <ListItemIcon>
            <img src={icon} alt={icon} />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      </li>
    );
  }

  function ListItemLinkRequestRetire(props) {
    const { icon, text, total, status } = props;
    return (
      <li>
        <NavLink
          to={{
            pathname: '/admin/contract/retire',
            state: {
              date: filterRequestRetire.date,
              status: status ? status : null,
            },
          }}
          className="link-to-request"
        >
          <ListItem button secondaryAction={total} className="li-list text-bold">
            <ListItemIcon>
              <img src={icon} alt={icon} />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        </NavLink>
      </li>
    );
  }

  return (
    <div className="contract-dashboard mb-3">
      {!isDashboard && <div className="nav-fixed">{<NavContract />}</div>}

      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} md={12} lg="8">
          {!isDashboard && (
            <Box className="border-show pd-15 pdb-20 box-map static-employee-company mt-3">
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="body-top">
                    <div className="d-flex">
                      <img src={iconContractDashboard} alt={iconContractDashboard} className="icon" />
                      <h4 className="title-body">{t('employees.StatisticsOfPersonnelInTheCompany')}</h4>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="info">
                    {t('employees.employees')} <span>{totalEmployees?.total_member}</span>
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box className={`border-show pd-15 pdb-20 box-map ${isDashboard ? '' : 'mt-3'}`}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <div className="body-top">
                  <div className="d-flex">
                    <img src={iconContractDashboard} alt={iconContractDashboard} className="icon" />
                    <h4 className="title-body">{t('contract.dashboard.statisticalChartByCategory')}</h4>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                {isLoadingCategoryContract ? (
                  <Loading addClass="mt-3" />
                ) : dataCategoryContract ? (
                  <MapDoughnut
                    dataMap={dataCategoryContract.map((item) => item).filter((it) => it.total > NOT_DATA)}
                    colors={ColorConstants.COLOR_COMMON}
                  />
                ) : (
                  <p className="error-no-data">{t('common.notData')}</p>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box className="border-show pd-15 pdb-20 mt-3 box-map">
            <Grid container direction="row">
              <Grid item xs={12}>
                <div className="body-top">
                  <div className="d-flex">
                    <img src={iconContractDashboard} alt={iconContractDashboard} className="icon" />
                    <h4 className="title-body">{t('contract.dashboard.statisticalChartByTypeOfPersonnel')}</h4>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                {isLoadingCategoryEmployees ? (
                  <Loading addClass="mt-3" />
                ) : dataCategoryEmployees ? (
                  <MapDoughnut
                    dataMap={dataCategoryEmployees.map((item) => item).filter((it) => it.total > NOT_DATA)}
                    colors={ColorConstants.COLOR_COMMON}
                  />
                ) : (
                  <p className="error-no-data">{t('common.notData')}</p>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={12} lg="4" className="display-gird">
          <Box container className={`border-show pd-15 ${isDashboard ? '' : 'mt-3'}`}>
            <Grid item xs={12}>
              <DateDayComponent
                label=""
                valueDefault={filter.date}
                onChangeHandle={handleFilterDate}
                sizeDate="small"
              />
              <hr />
            </Grid>

            <Grid item xs={12} className="group-list-f3f7fb">
              <h4 className="title-body">{t('contract.titleContract')}</h4>
              <div>
                {isLoadingStatisticalContract ? (
                  <Loading addClass="mt-3" />
                ) : (
                  <List>
                    {statisticalContract && statisticalContract.length ? (
                      statisticalContract.map((item, index) => (
                        <ListItemLink
                          to={
                            TYPE_CONTRACT_WAITING === item?.type
                              ? { pathname: '/admin/contract/waiting-contract' }
                              : { pathname: '/admin/contract/contract', type: item?.type }
                          }
                          secondaryAction={item.total}
                          key={index}
                          icon={iconFile}
                          text={item.name}
                          total={item.total}
                        />
                      ))
                    ) : (
                      <ListItem>
                        <ListItemText className="error-no-data color-red pd-0" primary={t('common.notData')} />
                      </ListItem>
                    )}
                  </List>
                )}
              </div>
            </Grid>

            <Grid item xs={12} className="group-list-f3f7fb mt-3">
              <h4 className="title-body">{t('contract.request')}</h4>
              <div className="box-request">
                <List>
                  {(isLoadingListDivision || isLoadingRequestRetire) && <Loading addClass="mt-3" />}
                  {!isLoadingListDivision && (
                    <>
                      {uListDivision ? (
                        <>
                          <li>
                            <ListItemLinkRequestRetire
                              secondaryAction={uListDivision?.processing_request + uListDivision?.complete_request}
                              icon={iconFile}
                              text={t('contract.totalRequestForLeaveInTheMonth')}
                              total={
                                uListDivision?.processing_request +
                                uListDivision?.complete_request +
                                uListDivision?.reject_request
                              }
                            />
                          </li>
                          <li>
                            <ListItemLinkRequestRetire
                              secondaryAction={uListDivision?.complete_request}
                              icon={iconFile}
                              status={STATUS_RETIRE_HANDLED}
                              text={t('contract.totalRequestsProcessedInTheMonth')}
                              total={uListDivision?.complete_request}
                            />
                          </li>
                          <li>
                            <ListItemLinkRequestRetire
                              secondaryAction={uListDivision?.processing_request}
                              icon={iconFile}
                              status={STATUS_RETIRE_PROCESSING}
                              text={t('contract.totalUnprocessedRequestsInTheMonth')}
                              total={uListDivision?.processing_request}
                            />
                          </li>
                        </>
                      ) : (
                        <ListItem>
                          <ListItemText className="error-no-data color-red pd-0" primary={t('common.notData')} />
                        </ListItem>
                      )}
                    </>
                  )}
                </List>
              </div>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
