import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import imgAvatar from 'assets/img/theme/avatar_default.png';
import AlertComponent from 'components/Alert';
import { FORMAT_IMAGES } from 'constants/index';
import styles from './style.module.scss';

const UploadImages = (props) => {
  const { t } = useTranslation();
  const { onChangeImage, dataImage } = props;

  const [listImage, setListImage] = useState(dataImage);
  const [listShowImage, setListShowImage] = useState(dataImage);
  const [isNew, setIsNew] = useState(true);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (isNew) {
      setListImage(dataImage);
      setListShowImage(dataImage);
      setIsNew(false);
    }
  }, [dataImage, isNew]);

  const onUploadImg = (event, index) => {
    if (event.target.files.length > 0) {
      let _listImage = [...listImage];
      _listImage[index] = event.target.files[0];

      let isFormatCorrect = false;
      if (!event.target.files[0].type.includes('image')) {
        setListShowImage([...listShowImage]);
        setMessage(t('messages.pleaseUploadImages'));
        setShowAlert(true);
        return;
      }
      if (event.target.files[0].size / 1024 / 1024 > 10) {
        setListShowImage([...listShowImage]);
        setMessage(t('messages.pleaseUploadImagesUnder10mb'));
        setShowAlert(true);
        return;
      }
      FORMAT_IMAGES.forEach((item) => {
        if (event.target.files[0].type.includes(item) && !isFormatCorrect) {
          isFormatCorrect = true;
        }
      });
      if (!isFormatCorrect) {
        setListShowImage([...listShowImage]);
        setMessage(t('messages.pleaseUploadImagesInTheCorrectFormat'));
        setShowAlert(true);
        return;
      }

      setListShowImage(function (uploadImages) {
        if (event.target.files && event.target.files[0]) {
          uploadImages[index] = URL.createObjectURL(event.target.files[0]);
          return [...uploadImages];
        }
      });

      setListImage(function (uploadImages) {
        if (event.target.files && event.target.files[0]) {
          uploadImages[index] = event.target.files[0];
          return [...uploadImages];
        }
      });

      if (listImage.length) {
        onChangeImage(_listImage);
      }
    }
  };

  return (
    <div className={styles.boxImage}>
      {listShowImage &&
        listShowImage.map((item, index) => (
          <div className={styles.boxItemImage} key={index}>
            <div className={styles.uploadImages}>
              <input
                id={'image-avatar' + index + 1}
                type="file"
                name="images[]"
                style={{ display: 'none' }}
                accept=".jpg,.jpeg,.png,.gif,.svg"
                onChange={(event) => onUploadImg(event, index)}
              />
              <label htmlFor={'image-avatar' + index + 1} className={styles.buttonFile}>
                {item && <img src={item} alt={item} className={styles.image} />}
                {!item && <img src={imgAvatar} alt="camera" width="30px" height="30px" className={styles.image} />}
              </label>
            </div>
          </div>
        ))}
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default UploadImages;
