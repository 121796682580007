import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import React, { useState, useEffect, useContext } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import './style.scss';
import AlertComponent from 'components/Alert';
import BoxDataOther from './BoxDataOther.js';
import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import Sidebar from './../Sidebar.js';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_UNPROCESSABLE_ENTITY, HTTP_OK, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { MAX_LENGTH_3, MAX_LENGTH_7 } from 'constants/index.js';
import { USE_LIST_BLOCK_TIME, USE_LIST_SCHEDULE } from 'constants/MasterData';
import { apiUpdateSchedule, apiUpdateBlockTime, apiDeleteSchedule } from 'api/masterData.js';
import { customDate } from 'helpers/formatDate';
import { isNotNullObject } from 'helpers/index.js';
import { useListSchedule, useShowBlockTime } from 'hook/useMasterData';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_BUTTON_ROLE } from 'constants/index.js';
import CustomInputNumber from 'components/InputYupAndMui/CusromInputNumber';
import { YupValidateCreateAssets } from './yupValidate';
import moment from 'moment';
import BreakTime from './BreakTime';
import { useGetBreakTime } from 'hook/useMasterData';

const FORMAT_TIME = 'HH:mm';
const FORMAT_DATE = 'DD/MM';
const startOfNextMonth = moment().add(1, 'M').startOf('month').format('DD/MM/YYYY');

const DataOther = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(YupValidateCreateAssets()),
  });
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [isShowAlert, setShowAlert] = useState(false);
  const [textMessage, setMessage] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [disableBtnLate, setDisableBtnLate] = useState(false);

  const [workingTime, setWorkingTime] = useState([]);
  const { data: listSchedule, isLoading: isLoadingSchedule } = useListSchedule();

  const [formDataMoneyLate, setFormDataMoneyLate] = useState();
  const { data: showBlockTime, isLoading: isLoadingBlockTime } = useShowBlockTime();
  const { data: dataBreakTime, isLoading: isLoadingBreakTime } = useGetBreakTime();
  const onSubmit = (value) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    let data = {
      id: showBlockTime?.id,
      block: value.block,
      minutes: value.minutes,
      money: value.money.replace(',', ''),
      next_time_late_early: value.next_time_late_early,
    };
    if (showBlockTime?.id) {
      setDisableBtnLate(true);
      // setTimeout(() => {
      //   setDisableBtnLate(false);
      // }, [2000]);
      handleApiBlock(showBlockTime?.id, data);
    }
  };
  const handleApiBlock = async (id, data) => {
    let response = await apiUpdateBlockTime(id, data);
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setDisableBtnLate(false);
    }
    if (response?.status === HTTP_OK) {
      setMessage(response.data.messages);
      queryClient.invalidateQueries(USE_LIST_BLOCK_TIME);
      setFormDataMoneyLate({ ...formDataMoneyLate, block_error: '', money_error: '' });
      setShowAlert(!isShowAlert);
      setDisableBtnLate(false);
    }
    if (response?.status === HTTP_UNPROCESSABLE_ENTITY) {
      let errors = {};
      if (response.data.messages?.block) errors.block_error = response.data.messages.block[0];
      if (response.data.messages?.money) errors.money_error = response.data.messages.money[0];
      if (isNotNullObject(errors)) {
        setFormDataMoneyLate({ ...formDataMoneyLate, ...errors });
        setDisableBtnLate(false);
      }
    }
  };

  useEffect(() => {
    if (listSchedule) {
      let newListSchedule = [];
      listSchedule.forEach((element) => {
        element.error_name = '';
        newListSchedule.push(element);
      });
      setWorkingTime(listSchedule);
    }
  }, [listSchedule]);

  const checkValidation = (workingTime, response) => {
    let isError = false;
    let newListSchedule = [];

    workingTime.forEach((element, index) => {
      element.start_date_error = '';
      element.end_date_error = '';
      element.hour_start_morning_error = '';
      element.hour_end_morning_error = '';
      element.hour_start_afternoon_error = '';
      element.hour_end_afternoon_error = '';
      element.date_error = '';
      element.time_error = '';

      if (response) {
        if (response.data.messages[index] !== undefined && response.data.messages[index]) {
          let msg = response.data.messages[index];

          if (msg?.start_date) element.start_date_error = msg?.start_date[0];
          if (msg?.end_date) element.end_date_error = msg?.end_date[0];
          if (msg?.hour_start_morning) element.hour_start_morning_error = msg?.hour_start_morning[0];
          if (msg?.hour_end_morning) element.hour_end_morning_error = msg?.hour_end_morning[0];
          if (msg?.hour_start_afternoon) element.hour_start_afternoon_error = msg?.hour_start_afternoon[0];
          if (msg?.hour_end_afternoon) element.hour_end_afternoon_error = msg?.hour_end_afternoon[0];
          if (msg?.error_time) element.time_error = msg?.error_time[0];
          if (msg?.error_date) element.date_error = msg?.error_date[0];
        }
      } else {
        var focus = true;
        if (!element.start_date) {
          element.start_date_error = t('common.pleaseFillOutThisField');
          if (focus) {
            $('input[name=start_date]').focus();
            focus = false;
          }
          setDisableBtn(false);
        }
        if (!element.end_date) {
          element.end_date_error = t('common.pleaseFillOutThisField');
          if (focus) {
            $('input[name=end_date]').focus();
            focus = false;
          }
          setDisableBtn(false);
        }
        if (!element.hour_start_morning) {
          element.hour_start_morning_error = t('common.pleaseFillOutThisField');
          if (focus) {
            $('input[name=hour_start]').focus();
            focus = false;
          }
          setDisableBtn(false);
        }
        if (!element.hour_end_morning) {
          element.hour_end_morning_error = t('common.pleaseFillOutThisField');
          if (focus) {
            $('input[name=hour_end]').focus();
            focus = false;
          }
          setDisableBtn(false);
        }
        if (moment(element.hour_end_morning, FORMAT_TIME).isBefore(moment(element.hour_start_morning, FORMAT_TIME))) {
          element.hour_end_morning_error = t('messages.pleaseReEnterTime');
          if (focus) {
            $('input[name=hour_end]').focus();
            focus = false;
          }
        }
        if (!element.hour_start_afternoon) {
          element.hour_start_afternoon_error = t('common.pleaseFillOutThisField');
          if (focus) {
            $('input[name=hour_start]').focus();
            focus = false;
          }
          setDisableBtn(false);
        }
        if (moment(element.hour_start_afternoon, FORMAT_TIME).isBefore(moment(element.hour_end_morning, FORMAT_TIME))) {
          element.hour_start_afternoon_error = t('messages.pleaseReEnterTime');
          if (focus) {
            $('input[name=hour_end]').focus();
            focus = false;
          }
        }
        if (!element.hour_end_afternoon) {
          element.hour_end_afternoon_error = t('common.pleaseFillOutThisField');
          if (focus) {
            $('input[name=hour_end]').focus();
            focus = false;
          }
          setDisableBtn(false);
        }
        if (
          moment(element.hour_end_afternoon, FORMAT_TIME).isBefore(moment(element.hour_start_afternoon, FORMAT_TIME))
        ) {
          element.hour_end_afternoon_error = t('messages.pleaseReEnterTime');
          if (focus) {
            $('input[name=hour_end]').focus();
            focus = false;
          }
        }
      }

      if (
        element.start_date_error ||
        element.end_date_error ||
        element.hour_start_morning_error ||
        element.hour_end_morning_error ||
        element.hour_start_afternoon_error ||
        element.hour_end_afternoon_error ||
        element.time_error ||
        element.date_error
      ) {
        isError = true;
      }
      newListSchedule.push(element);
    });

    return {
      is_error: isError,
      new_list_schedule: newListSchedule,
    };
  };

  const handleSubmitWorkingTime = async (event) => {
    event.preventDefault();
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setDisableBtn(true);
    event.preventDefault();
    let checkValidate = checkValidation(workingTime);
    if (checkValidate.is_error) {
      setWorkingTime(checkValidate.new_list_schedule);
      setDisableBtn(false);
      return;
    }

    let newData = [];
    workingTime.forEach((element) => {
      let data = {};
      data.id = element.id;
      data.start_date =
        typeof element.start_date === 'string' ? element.start_date : customDate(element.start_date, FORMAT_DATE);
      data.end_date =
        typeof element.end_date === 'string' ? element.end_date : customDate(element.end_date, FORMAT_DATE);
      data.hour_start_morning =
        typeof element.hour_start_morning === 'string'
          ? element.hour_start_morning
          : customDate(element.hour_start_morning, FORMAT_TIME);
      data.hour_end_morning =
        typeof element.hour_end_morning === 'string'
          ? element.hour_end_morning
          : customDate(element.hour_end_morning, FORMAT_TIME);
      data.hour_start_afternoon =
        typeof element.hour_start_afternoon === 'string'
          ? element.hour_start_afternoon
          : customDate(element.hour_start_afternoon, FORMAT_TIME);
      data.hour_end_afternoon =
        typeof element.hour_end_afternoon === 'string'
          ? element.hour_end_afternoon
          : customDate(element.hour_end_afternoon, FORMAT_TIME);
      newData.push(data);
    });

    let response = await apiUpdateSchedule({ data: newData });
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
    }
    if (response?.status === HTTP_OK) {
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_LIST_SCHEDULE);
    }

    if (response?.status === HTTP_INTERNAL_SERVER_ERROR && response?.data?.message) {
      setMessage(response.data.message);
      setShowAlert(!isShowAlert);
    }

    setDisableBtn(false);

    if (response?.status === HTTP_UNPROCESSABLE_ENTITY) {
      if (response?.data?.messages) {
        setDisableBtn(true);
        let checkValidate = checkValidation(workingTime, response);
        if (checkValidate.is_error) {
          setWorkingTime(checkValidate.new_list_schedule);
          setDisableBtn(false);
          return;
        }
      }
    }
    setDisableBtn(false);
  };

  const handleChangeWorkingTime = (dataWorkingTime) => {
    setWorkingTime(dataWorkingTime);
  };

  const handleDeleteWorkingTime = (id) => {
    confirmAlert({
      title: t('common.doYouWantToDeletedData?'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            let response = await apiDeleteSchedule(id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.status === HTTP_OK) {
              if (response?.data?.messages) {
                setMessage(response.data.messages);
                setShowAlert(true);
              }
              queryClient.invalidateQueries(USE_LIST_SCHEDULE);
            }

            if (response?.status === HTTP_INTERNAL_SERVER_ERROR && response?.data?.message) {
              setMessage(response.data.message);
              setShowAlert(!isShowAlert);
            }
          },
        },
      ],
    });
  };

  return (
    <div>
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>
        <Col lg={9} xl={10}>
          <div className="time-working box-show-none">
            <div className="box-session border-show pd-15">
              <Form onSubmit={handleSubmitWorkingTime}>
                <Row>
                  <Col className="col-md-5">
                    <h4 className="title-body">{t('masterData.workTimeManagement')}</h4>
                  </Col>
                  <Col className="col-md-7">
                    <ButtonComponent
                      text={t('common.save')}
                      addClass={`${
                        dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                      } float-right`}
                      type="submit"
                      hasDisabled={disableBtn}
                      isLoading={disableBtn}
                    />
                  </Col>
                </Row>
                {isLoadingSchedule && <Loading addClass="mt-4" />}
                <BoxDataOther
                  listSchedule={workingTime}
                  onChangeWorkingTime={handleChangeWorkingTime}
                  onDelete={handleDeleteWorkingTime}
                />
              </Form>
            </div>

            <div className="box-session border-show pd-15 mt-3">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg={6} xl={5}>
                    <h4 className="title-body">{t('masterData.lateMoneyManagement')}</h4>
                  </Col>
                  <Col lg={6} xl={7}>
                    <ButtonComponent
                      text={t('common.save')}
                      addClass={`${
                        dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                      } float-right`}
                      type="submit"
                      handleClick={handleSubmit(onSubmit)}
                      hasDisabled={disableBtnLate}
                      isLoading={disableBtnLate}
                    />
                  </Col>
                </Row>

                {isLoadingBlockTime && (
                  <Row>
                    <Col lg={12} xl={12}>
                      <Loading addClass="mt-2" />
                    </Col>
                  </Row>
                )}

                {!isLoadingBlockTime && (
                  <Row>
                    <Col lg={6} xl={5} xs={11}>
                      <div className="box-content">
                        <div className="">
                          <CustomInputNumber
                            placeholder={t('masterData.block')}
                            label={t('masterData.block')}
                            name="block"
                            errors={errors}
                            control={control}
                            register={register}
                            useWatch={useWatch}
                            required
                            setValue={setValue}
                            thousandSeparator
                            isInteger
                            maxLength={MAX_LENGTH_3}
                            defaultValue={showBlockTime?.block}
                            disabled={dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW ? true : false}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} xl={5} xs={1}>
                      <div className="block-time-middle">{t('common.minute')}</div>
                    </Col>
                    <Col lg={6} xl={5} xs={11}>
                      <div className="box-content mt-4">
                        <div className="">
                          <CustomInputNumber
                            placeholder={t('masterData.amountOfMoney')}
                            label={t('masterData.amountOfMoney')}
                            name="money"
                            errors={errors}
                            control={control}
                            register={register}
                            useWatch={useWatch}
                            required
                            setValue={setValue}
                            thousandSeparator
                            isInteger
                            maxLength={MAX_LENGTH_7}
                            defaultValue={showBlockTime?.money}
                            disabled={dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW ? true : false}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} xl={5} xs={1}>
                      <div className="block-time-middle mt-4">{t('common.currency')}</div>
                    </Col>
                    <Col lg={6} xl={5} xs={11}>
                      <div className="box-content mt-4">
                        <div className="">
                          <CustomInputNumber
                            placeholder={t('masterData.minutes')}
                            label={t('masterData.minutes')}
                            name="minutes"
                            errors={errors}
                            control={control}
                            register={register}
                            useWatch={useWatch}
                            required
                            setValue={setValue}
                            thousandSeparator
                            isInteger
                            maxLength={MAX_LENGTH_3}
                            defaultValue={showBlockTime?.minutes}
                            disabled={dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW ? true : false}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} xl={5} xs={1}>
                      <div className="block-time-middle mt-4">{t('common.minute')}</div>
                    </Col>
                    <Col lg={6} xl={5} xs={11}>
                      <div className="box-content mt-4">
                        <div className="">
                          <CustomInputNumber
                            placeholder={t('masterData.minutesForRegistLateEarly')}
                            label={t('masterData.minutesForRegistLateEarly')}
                            name="next_time_late_early"
                            errors={errors}
                            control={control}
                            register={register}
                            useWatch={useWatch}
                            required
                            setValue={setValue}
                            thousandSeparator
                            isInteger
                            maxLength={MAX_LENGTH_3}
                            defaultValue={showBlockTime?.next_time_late_early || showBlockTime?.time_late_early}
                            disabled={dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW ? true : false}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} xl={5} xs={1}>
                      <div className="block-time-middle mt-4">{t('common.minute')}</div>
                    </Col>
                    {!showBlockTime?.next_time_late_early ||
                      showBlockTime?.next_time_late_early === showBlockTime?.time_late_early || (
                        <>
                          <Col md={12}>
                            <p>
                              {t('masterData.minutesApplyForNextMonth')} {startOfNextMonth}
                            </p>
                          </Col>
                        </>
                      )}
                  </Row>
                )}
              </Form>
              <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
                {textMessage}
              </AlertComponent>
            </div>
            {dataBreakTime && <BreakTime dataBreakTime={dataBreakTime} isLoadingBreakTime={isLoadingBreakTime} />}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DataOther;
