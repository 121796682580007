import React from 'react';
import './../style.scss';
import Training, { DATE_FORMAT } from '..';
import { Card, Table } from 'antd';
import { InfoIcon } from '../icon';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DEFAULT_PAGE, DEFAULT_LIMIT } from 'constants/index';
import { REFUND_FULL, FIELD_KEY, STATUS_DONE, END_COMMIT_TYPE } from 'constants/Training';
import { useGetAllUsersCourse, useGetCourses } from 'hook/useTraining';
import { customDate } from 'helpers/formatDate';
import CustomPagination from '../CustomPagination';
import { TrainingProvider } from '../Context/TrainingProvider';
import FilterMember from '../InCommitment/FilterMember';

const EndCommitment = ({ isDashboard }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({
    page: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT,
    list_type: END_COMMIT_TYPE
  });

  const filterCourse = {
    fields: [FIELD_KEY.NAME, FIELD_KEY.ID]
  };

  const { user_courses, isLoading } = useGetAllUsersCourse(filter);
  const { courses } = useGetCourses(filterCourse);

  const columns = [
    {
      title: t('training.index'),
      dataIndex: FIELD_KEY.INDEX,
      key: FIELD_KEY.INDEX,
      align: 'center',
      width: 60,
      render: (_, record, index) => index + filter.limit * (filter.page - 1) + 1,
    },
    {
      title: t('training.userCode'),
      dataIndex: FIELD_KEY.CODE,
      key: FIELD_KEY.CODE,
      width: 100,
    },
    {
      title: t('training.fullName'),
      dataIndex: FIELD_KEY.NAME,
      key: FIELD_KEY.NAME,
      width: 140,
    },
    {
      title: t('training.division'),
      dataIndex: FIELD_KEY.DIVISION,
      key: FIELD_KEY.DIVISION,
      width: 85,
      render: (value) => (value?.name)
    },
    {
      title: t('training.position_'),
      dataIndex: FIELD_KEY.POSITION,
      key: FIELD_KEY.POSITION,
      width: 85,
      render: (value) => (value?.name)
    },
    {
      title: t('training.programName'),
      dataIndex: FIELD_KEY.COURSE,
      key: FIELD_KEY.PROGRAM_NAME,
      width: 150,
      render: (value) => (value?.name)
    },
    {
      title: t('training.startDate'),
      dataIndex: FIELD_KEY.START_DATE,
      key: FIELD_KEY.START_DATE,
      render: (value) => (value ? customDate(value, DATE_FORMAT) : '-'),
      width: 125,
      align: 'center'
    },
    {
      title: t('training.endDate'),
      dataIndex: FIELD_KEY.END_DATE,
      key: FIELD_KEY.END_DATE,
      render: (value) => (value ? customDate(value, DATE_FORMAT) : '-'),
      width: 125,
      align: 'center'
    },
    {
      title: t('training.monthCommit1'),
      dataIndex: FIELD_KEY.COURSE,
      key: FIELD_KEY.MONTH_COMMIT,
      width: 80,
      align: 'center',
      render: (value) => (value?.month_commit)
    },
    {
      title: t('training.supportCost1'),
      dataIndex: FIELD_KEY.SUPPORT_COST,
      key: FIELD_KEY.SUPPORT_COST,
      width: 120,
      render: (value) => value.toLocaleString()?.replace('.', ','),
      align: 'center'
    },
    {
      title: t('training.rateCommit'),
      dataIndex: FIELD_KEY.COURSE,
      key: FIELD_KEY.RATE_COMMIT,
      render: (value) => {
        const rate = value.rate_commit;
        const content = rate === REFUND_FULL ? t('training.refundFull') : t('training.refundByPercent');
        const css = rate === REFUND_FULL ? 'refund-full' : 'refund-percent';
        return <span className={css}>{content}</span>;
      },
      width: 130,
    },
    {
      title: t('training.status'),
      dataIndex: FIELD_KEY.STATUS,
      key: FIELD_KEY.STATUS,
      width: 120,
      render: (value) => {
        const content = value === STATUS_DONE ? t('training.completed') : t('training.notCompleted');
        const css = value === STATUS_DONE ? 'tag status-completed-bg' : 'tag status-not-completed-bg';
        return <span className={css}>{content}</span>;
      },
      align: 'center'
    },
    {
      title: t('training.fineAmount'),
      dataIndex: FIELD_KEY.END_COMMIT_COST,
      key: FIELD_KEY.END_COMMIT_COST,
      width: 100,
      render: (value) => value ? value.toLocaleString()?.replace('.', ',') : value,
      align: 'center'
    },
  ];

  return (
    <TrainingProvider>
      <div className="scroll ct-form-controller-filter mb-3 member-list">
        {!isDashboard && (
          <div className="nav-fixed">
            <Training />
          </div>
        )}
        <Card
          className="card"
          title={
            <span className="title">
              <InfoIcon />
              {t('training.endCommitmentMember')}
            </span>
          }
          extra={
            <FilterMember filter={filter} setFilter={setFilter} courses={courses}/>
          }
        >
          <Table
            columns={columns}
            dataSource={user_courses?.data}
            loading={isLoading}
            rowKey={'id'}
            pagination={false}
            scroll={{ x: 'max-content', y: 500 }}
            footer={() => (
              <CustomPagination
                totalPage={user_courses?.total}
                currentPage={filter.page}
                limit={filter.limit}
                from={1}
                to={user_courses?.to}
                onChangePage={(page, limit) => setFilter({ ...filter, page, limit })}
              />
            )}
          />
        </Card>
      </div>
    </TrainingProvider>
  );
};

export default EndCommitment;
