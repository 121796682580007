import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import { IoMdEye } from 'react-icons/io';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import classNames from 'classnames';

import { DISABLE_ROLE } from 'constants/index.js';

const DivisionBox = (props) => {
  const { t } = useTranslation();
  const {
    divisionBox,
    handleOnclick,
    handleUpdate,
    idShow,
    handleDelete,
    index = '',
    name,
    setIdDivision,
    setIdBranch,
    disable,
    disableDropdown,
  } = props;
  const {
    colorBorder = 'border-grey',
    tooltip = false,
    textcolor = '',
    background = '',
    canShow = false,
    boxRadius = 'division-radius',
    styleBorder = '',
    colorCircle = '',
    bgCircle = '',
    setting = false,
  } = props;

  const [dropdownOpen, setOpen] = useState(false);
  const showChildren = () => {
    handleOnclick(divisionBox);
    if (setIdDivision) setIdDivision(divisionBox?.division_id);
    if (setIdBranch) setIdBranch(divisionBox?.branch_id);
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  let indexId = name ? name + '-' + index : '';

  useEffect(() => {
    setTooltipOpen(false);
  }, [dropdownOpen]);

  return (
    <div>
      <div
        id={indexId}
        onClick={handleOnclick && showChildren}
        className={classNames(
          `border-2 space division-box d-flex align-items-center
          ${divisionBox.isActive && 'division-box-shadow'}
          ${setting && 'justify-content-between'}
          ${boxRadius} ${colorBorder} ${styleBorder} ${background}
          ${disable && DISABLE_ROLE}`,
        )}
      >
        {Number(moment(divisionBox?.founding_at, 'DD/MM/YYYY').format('YYYY')) > 0 && (
          <div className={`founding-at ${bgCircle}`}>
            <span>{Number(moment(divisionBox?.founding_at, 'DD/MM/YYYY').format('YYYY'))}</span>
          </div>
        )}
        <div className={classNames(`border-1 number-box ${colorCircle}`)}> {divisionBox?.total_member} </div>
        <div className={classNames(`${textcolor} text-box`)}>
          {divisionBox?.branch_name || divisionBox?.division_name || divisionBox?.team_name}
        </div>
        {setting && (
          <ButtonDropdown isOpen={dropdownOpen} toggle={() => setOpen(!dropdownOpen)} className={`setting-btn`}>
            <DropdownToggle className="change-direction">...</DropdownToggle>
            <DropdownMenu className="change-width">
              {canShow && (
                <NavLink
                  to={{
                    pathname: `/admin/department/detail/${idShow}`,
                  }}
                >
                  <DropdownItem className="change-padding">
                    <IoMdEye className="icon-diagram" />
                    {t('divisionDiagram.show')}
                  </DropdownItem>
                </NavLink>
              )}
              <DropdownItem className={disableDropdown && DISABLE_ROLE} onClick={!disableDropdown && handleUpdate}>
                <HiPencil className="icon-diagram" />
                {t('divisionDiagram.update')}
              </DropdownItem>
              <DropdownItem divider className="change-margin" />
              <DropdownItem className={disableDropdown && DISABLE_ROLE} onClick={!disableDropdown && handleDelete}>
                <MdDelete className="icon-diagram" />
                {t('divisionDiagram.delete')}
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        )}
      </div>
      {tooltip && !dropdownOpen && (
        <Tooltip
          className="department-tooltip"
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={indexId}
          toggle={toggleTooltip}
        >
          <p className="division-head">
            {t('divisionDiagram.divisionName')}
            {divisionBox?.branch_name || divisionBox?.division_name || divisionBox?.team_name}
          </p>
          <p>
            {t('divisionDiagram.manager')}
            {<span>{divisionBox?.leader === '' ? t('divisionDiagram.notUpdated') : divisionBox?.leader}</span>}
          </p>
          <p>
            {t('divisionDiagram.foundation')}
            {divisionBox?.founding_at}
          </p>
          <p>
            {t('divisionDiagram.totalMembers')}
            {divisionBox?.total_member}
          </p>
          {divisionBox?.team_info?.map((item) => {
            return (
              <>
                <p>
                  {item?.name + ': '}
                  {item?.total_member}
                </p>
              </>
            );
          })}
        </Tooltip>
      )}
    </div>
  );
};

export default DivisionBox;
