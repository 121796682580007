import { sendGet, sendPost } from '../helpers/axios';

export const apiGetTimeSheet = (params) => sendGet(`/api/time-sheet/user/${params.userId}`, params);
export const apiGetTotalTimeSheet = (params) => sendGet(`/api/time-sheet/total/user/${params.userId}`, params);

export const apiGetTimeSheetList = (params) => sendGet(`/api/time-sheet`, params);
export const apiGetTimeSheetTotal = (params) => sendGet(`/api/time-sheet/total`, params);

export const apiGetRequestIndividual = (params) => sendGet(`/api/request-personal/timekeeping`, params);
export const apiGetOtIndividual = (params) => sendGet(`/api/request-personal/ot`, params);
export const apiGetListTypeRequestIndividual = (params) =>
  sendGet(`/api/request-personal/list-type-request-timekeeping`, params);
export const apiGetListTypeOtIndividual = (params) => sendGet(`/api/request-personal/list-type-request-ot`, params);

export const apiGetTotalTimekeepingYear = (params) => sendGet('api/time-sheet/dashboard/total/year', params);
export const apiGetTotalTimekeepingMonth = (params) => sendGet('api/time-sheet/dashboard/total/month', params);
export const apiGetLateTimeAllCompany = (params) => sendGet('api/time-sheet/dashboard/late-time', params);
export const apiGetLateTimeOTMonthAllCompany = (params) => sendGet('api/time-sheet/dashboard/late-time/month', params);
export const apiGetOverTImeProjectMonth = (params) => sendGet('api/time-sheet/dashboard/over-time-project', params);
export const apiListTypeOfCategory = (params) => sendGet('api/list-type-of-category', params);
export const apiListCategoryEmployees = (params) => sendGet('/api/time-sheet/group', params);

export const apiGetOTAllCompany = (params) => sendGet('api/time-sheet/dashboard/over-time', params);
export const apiGetCheckIsLocked = (params) => sendGet('api/time-sheet/check-lock-timesheet', params);
export const apiLockTimesheet = (params) => sendPost('api/time-sheet/lock-timesheet', params);
export const apiUnlockTimesheet = (params) => sendPost('api/time-sheet/unlock-timesheet', params);
export const apiGetSchedule = (params) => sendGet(`/api/schedule-standard/${params.user_id}`, params);
export const apiGetScheduleTimeKeeping = (params) => sendGet(`/api/time-sheet/schedule-standard/${params.user_id}`, params);
export const apiGetHolidayScheduleRequestLeader = (params) => sendGet('/api/holiday/schedule-leader', params);
export const apiRecallRequest = (params) => sendPost(`/api/cancel/request-approved/${params}`, params);
