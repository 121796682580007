/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { createContext } from 'react';
import { useLocation, Route, Switch, Redirect, useHistory } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminFooter from 'components/Footers/AdminFooter.js';
import Header from './../components/Headers/Header.js';
import { useInforAuth } from 'hook/useAuth';
import storageFC from 'helpers/storage';
import configs from 'config';
import routes from 'routes.js';
import { ROLE_NOT_VIEW } from 'constants/index.js';
import ReactGA from 'react-ga4';
import 'antd/dist/antd.css';

export const ThemContext = createContext();

const Admin = () => {
  window.OneSignal = window.OneSignal || [];
  const mainContent = React.useRef(null);
  const location = useLocation();
  const { data: dataInforUser } = useInforAuth();
  let history = useHistory();

  React.useEffect(() => {
    ReactGA.initialize('G-G4DRD5GKLR');
    ReactGA.send('/');
  }, []);

  React.useEffect(() => {
    if (
      window.location.pathname === '/admin/index' &&
      dataInforUser?.hr_permission?.users_permission === ROLE_NOT_VIEW &&
      dataInforUser?.hr_permission?.time_sheet_permission === ROLE_NOT_VIEW &&
      dataInforUser?.hr_permission?.assets_permission === ROLE_NOT_VIEW &&
      dataInforUser?.hr_permission?.contracts_permission === ROLE_NOT_VIEW
    ) {
      if (dataInforUser?.hr_permission?.divisions_permission !== ROLE_NOT_VIEW) {
        history.push('/admin/departmenthandle');
      } else {
        history.push('/admin/master-data/data-other');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInforUser]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  React.useEffect(() => {
    if (!storageFC.getItem('token')) {
      storageFC.setToken(window.location.href, 'previous_url');
      window.location.href = configs.API_DOMAIN_LOGIN;
    }
    // Handle Filter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <ThemContext.Provider value={dataInforUser}>
        <Header routes={routes} />
        <div className="main-content" ref={mainContent}>
          <Container className="" fluid>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/admin/index" />
            </Switch>
          </Container>
          <Container fluid className="mb-5">
            <AdminFooter />
          </Container>
        </div>
      </ThemContext.Provider>
    </>
  );
};

export default Admin;
