import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Table, Row } from 'reactstrap';
import { HiPencil } from 'react-icons/hi';
import InputField from 'components/Input/InputField';
import Box from '@mui/material/Box';
import { MdDelete } from 'react-icons/md';
import { IoIosAdd } from 'react-icons/io';
import { useQueryClient } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import $ from 'jquery';

import './style.scss';
import { isNotNullObject } from 'helpers/index';
import {
  HTTP_OK,
  HTTP_NOT_ACCESS,
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_EXPECTATION_FAILED,
} from 'constants/StatusCode.js';
import ButtonComponent from 'components/Buttons';
import ModalComponent from 'components/Modal';
import SearchComponent from 'components/Search';
import {
  apiCreateCategoryCertificate,
  apiDeleteCategoryCertificate,
  apiUpdateCategoryCertificate,
} from './../../../../../api/masterData.js';
import { useListCategoryCertificate } from 'hook/useMasterData.js';
import AlertComponent from 'components/Alert';
import { USE_CATEGORY_CERTIFICATE, CATEGORY_CERTIFICATE } from 'constants/MasterData';
import { MAX_LENGTH_30 } from 'constants/ProfileEmployee';
import Loading from 'components/Loading';
import Sidebar from './../../Sidebar.js';
import Menu from './../index.js';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE, DISABLE_BUTTON_ROLE } from 'constants/index.js';

const CategoryCertificate = () => {
  let { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [isShowModalAdd, setShowModalAdd] = useState(false);
  const [key_word, setKeyWord] = useState('');
  const [formAdd, setFormAdd] = useState({
    id: null,
    name: '',
    error_name: '',
  });
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [textModal, setTextModal] = useState({
    title: t('masterData.moreCategoryCertifacate'),
    btn_submit: t('common.add'),
  });
  const [stateButton, setStateButton] = useState(false);

  const { data: listCategoryCertificate, isLoading: isLoadingListCategoryCertificate } = useListCategoryCertificate({
    key_word,
  });

  const handleSearch = (value) => {
    setKeyWord(value);
  };

  const addCategoryCertificate = () => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setShowModalAdd(true);
    setFormAdd({ ...formAdd, id: null, name: '', error_name: '' });
    setTextModal({
      title: t('masterData.moreCategoryCertifacate'),
      btn_submit: t('common.add'),
    });
  };

  const handleInput = (e) => {
    setFormAdd({ ...formAdd, [e.target.name]: e.target.value, error_name: '' });
  };

  function validate() {
    var error = {};
    if (!formAdd.name) {
      error.error_name = t('common.pleaseFillOutThisField');
      $('input[name=name]').focus();
    }
    if (formAdd.name.length > MAX_LENGTH_30) {
      error.error_name = t('common.pleaseEnterLessThanThirtyCharacters');
      $('input[name=name]').focus();
    }

    if (isNotNullObject(error)) {
      setFormAdd({
        ...formAdd,
        ...error,
      });
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = async (event) => {
    setFormAdd({
      ...formAdd,
      error_name: '',
      error_code: '',
      error_description: '',
    });
    setStateButton(true);
    event.preventDefault();
    if (!validate()) {
      setStateButton(false);
      return;
    }
    setFormAdd({
      ...formAdd,
      error_name: '',
    });

    const data = {
      name: formAdd.name,
    };

    let response = {};
    if (!formAdd.id) {
      response = await apiCreateCategoryCertificate(data);
    } else {
      response = await apiUpdateCategoryCertificate(formAdd.id, data);
    }

    if (response?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      if (msg?.name) {
        $('input[name=name]').focus();
      }
      setFormAdd({
        ...formAdd,
        error_name: msg?.name
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModalAdd(false);
      setFormAdd({
        ...formAdd,
        id: null,
        name: '',
        code: '',
        description: '',
        error_name: '',
        error_code: '',
        error_description: '',
      });
      setMessage(response.data.messages);
      setShowAlert(true);
      setStateButton(false);
      queryClient.invalidateQueries(USE_CATEGORY_CERTIFICATE);
    }

    setTextModal({
      ...textModal,
      title: t('masterData.moreData'),
      btn_submit: t('common.add'),
    });
  };

  const alertConfirm = (id) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    confirmAlert({
      title: t('common.doYouWantToDeletedData?'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteCategoryCertificate(id);

            if (response?.status === HTTP_INTERNAL_SERVER_ERROR && response?.data?.messages) {
              setMessage(response.data.messages);
              setShowAlert(true);
            }
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response?.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_CATEGORY_CERTIFICATE);
            }

            if (response?.data.status === HTTP_EXPECTATION_FAILED) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_CATEGORY_CERTIFICATE);
            }
          },
        },
      ],
    });
  };

  const handleEdit = (categoryCertificate) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setFormAdd({
      ...formAdd,
      id: categoryCertificate.id,
      name: categoryCertificate.name,
      error_name: '',
    });
    setTextModal({ ...textModal, title: t('masterData.updatingCategoryCertificate'), btn_submit: t('common.update') });
    setShowModalAdd(!isShowModalAdd);
  };

  return (
    <Row>
      <Col lg={3} xl={2}>
        <Sidebar />
      </Col>
      <Col lg={9} xl={10}>
        <Menu type={CATEGORY_CERTIFICATE} />
        <Row>
          <Col className="md-12">
            <div className="box-group d-flex float-right">
              <SearchComponent
                txtPlaceholder={t('masterData.findData')}
                addClass="input-search"
                handleSearch={handleSearch}
                defaultValue={key_word}
                maxLength={MAX_LENGTH_30}
              />
              <ButtonComponent
                text={t('common.add')}
                icon={<IoIosAdd />}
                addClass={`${
                  dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                } ml-3`}
                handleClick={addCategoryCertificate}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="md-12">
            <div className="table-list">
              <Table className="align-items-center table-hover" responsive>
                <thead>
                  <tr>
                    <th scope="col">{t('common.code')}</th>
                    <th scope="col">{t('masterData.categoryCertificate')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {listCategoryCertificate &&
                    listCategoryCertificate.map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td className="text-bold">{item.name}</td>
                        <td>
                          <div className="col-action d-flex float-right">
                            <div
                              className={`${
                                dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                              } edit mr-2`}
                            >
                              <HiPencil onClick={() => handleEdit(item)}></HiPencil>
                            </div>
                            <div
                              className={`${
                                dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                              } remove mr-3`}
                            >
                              <MdDelete onClick={() => alertConfirm(item.id)}></MdDelete>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  {isLoadingListCategoryCertificate && (
                    <tr>
                      <td colSpan={4}>
                        <Loading addClass="mt-2" />
                      </td>
                    </tr>
                  )}
                  {listCategoryCertificate && listCategoryCertificate.length < 1 && (
                    <tr>
                      <td colSpan={4} className="error-no-data ">
                        {t('common.notData')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Col>
      <ModalComponent
        toggle={() => setShowModalAdd(!isShowModalAdd)}
        isShowModal={isShowModalAdd}
        title={textModal.title}
      >
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className="mt-4">
            <InputField
              label={t('masterData.categoryCertificate')}
              variant="outlined"
              name="name"
              maxLength={MAX_LENGTH_30}
              defaultValue={formAdd.name}
              value={formAdd.name}
              isRequired={true}
              onHandleInput={handleInput}
              error={formAdd.error_name}
            />
            <p className="error">{formAdd?.message}</p>
          </div>
          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalAdd(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="w-100"
                handleClick={() => setShowModalAdd(true)}
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </Row>
  );
};

export default CategoryCertificate;
