import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query';
import classNames from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import styles from './../styles.module.scss';
import ButtonComponent from 'components/Buttons';
import CustomCheckboxYup from 'components/InputYupAndMui/CustomCheckboxYup';
import ModalComponent from 'components/Modal';
import { MAX_LENGTH_30 } from 'constants/index.js';
import {
  useGeListMemberContract,
  useStatisticalCategoryContract,
  useGenerateCodeContract,
  useCheckContractOnboardUser,
} from 'hook/useContract';

import { YupValidateEditContract } from './ValidationEditContract.js';
import { apiUpdateContract } from 'api/contract.js';
import { HTTP_UNPROCESSABLE_ENTITY, HTTP_OK, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import InputField from 'components/ReactForm/InputField.js';
import SelectField from 'components/ReactForm/SelectField.js';
import DateDayCustomInput from 'components/ReactForm/DateDayCustomInput.js';

import { getStrError } from 'helpers/index.js';
import styleSelect from './../../../../components/Select/style.module.scss';
import { TAB_LIST_CONTRACT, USE_LIST_CONTRACT, TAB_DETAIL_CONTRACT } from 'constants/Contract.js';
import { INDEFINITE_TERM_CONTRACT } from 'constants/Contract.js';
import { handleOnError } from 'helpers';

const EditContract = ({
  onSetMessage,
  isShowModal,
  onSetShowModal,
  formDataContract,
  txtTitleModal,
  txtBtnModal,
  type,
  keyCache,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const isClickCheckboxOnboard = useRef(false);
  const {
    handleSubmit,
    control,
    register,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(YupValidateEditContract()),
    // mode: 'onBlur',
  });

  const [filter] = useState({ key_word: '' });
  const [idContract, setIdContract] = useState(formDataContract.type_contract);
  const [checkContractOnboard, setCheckContractOnboard] = useState({ user_id: '' });
  const [keyGenerate, setKeyGenerate] = useState({
    user_id: 0,
    contract_name: '',
  });

  const { data: checkContract } = useCheckContractOnboardUser(checkContractOnboard);
  const { data: uListMember } = useGeListMemberContract(filter);
  const [errorForm, setErrorForm] = useState({});
  const { data: uListCategoryContract } = useStatisticalCategoryContract();
  const { data: generateCodeContract, isLoading: isLoadingGenerate } = useGenerateCodeContract(keyGenerate);
  const [contractCode, setContractCode] = useState('');

  const watchSelectTypeContract = useWatch({
    control,
    name: 'type_contract',
  });

  useEffect(() => {
    if (checkContract) {
      confirmAlert({
        title: t('assets.thereAlreadyExistsAnotherHDLDWhichIsTheOnboardHDLDAreYouSureYouWantToChange?'),
        buttons: [
          {
            label: t('common.cancel'),
            className: 'btn-alert-cancel',
            onClick: async () => {
              setValue('is_onboard', false);
              setCheckContractOnboard({ user_id: '' });
            },
          },
          {
            label: t('common.ok'),
            className: 'btn-alert-ok',
          },
        ],
      });
    }
    // Handle Filter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkContract, t]);

  useEffect(() => {
    if (formDataContract) {
      setContractCode(formDataContract.code_contract);
      setKeyGenerate((prevState) => ({
        ...prevState,
        user_id: formDataContract.user_id,
      }));
    }
  }, [formDataContract]);

  useEffect(() => {
    if (!isShowModal) {
      setErrorForm({});
    }
  }, [isShowModal]);

  useEffect(() => {
    if (generateCodeContract) {
      if (idContract === formDataContract.type_contract) {
        setContractCode(formDataContract.code_contract);
      } else {
        setContractCode(generateCodeContract);
      }
    }
  }, [generateCodeContract, formDataContract, idContract]);

  const onSubmit = async (data) => {
    setErrorForm({});
    let isOnboard = data.is_onboard && { is_onboard: data.is_onboard };
    let formSubmit = {
      user_id: formDataContract.user_id,
      contract_category: data.type_contract,
      code: data.code_contract,
      start_date: data.start_date,
      end_date: Number(data.type_contract) === INDEFINITE_TERM_CONTRACT ? null : data.end_date,
      ...isOnboard,
    };

    let response = {};
    if (type === TAB_LIST_CONTRACT || type === TAB_DETAIL_CONTRACT) {
      response = await apiUpdateContract(formSubmit, formDataContract?.contract_id);
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      onSetMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      onSetShowModal(false);
    }
    if (response?.status === HTTP_OK) {
      onSetShowModal(false);
      onSetMessage([response.data.messages]);
      queryClient.invalidateQueries(keyCache);

      if (type === TAB_DETAIL_CONTRACT) {
        queryClient.invalidateQueries(USE_LIST_CONTRACT);
      }
    }

    if (response?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      let msg = response.data.messages;
      if (msg?.code) {
        setError('code_contract', {
          message: getStrError(msg?.code[0]),
        });
      }
      if (msg?.start_date) {
        setError('start_date', {
          message: getStrError(msg?.start_date[0]),
        });
      }
      if (msg?.end_date) {
        setError('end_date', {
          message: getStrError(msg?.end_date[0]),
        });
      }
      if (msg?.user_id) {
        setError('user', {
          message: getStrError(msg?.user_id[0]),
        });
      }
      if (msg?.contract_category) {
        setError('type_contract', {
          message: getStrError(msg?.contract_category[0]),
        });
      }
      if (msg?.time_error) {
        setErrorForm({ ...errorForm, time_error: msg?.time_error });
      } else {
        setErrorForm({ ...errorForm, time_error: '' });
      }
    }
  };

  const handleChangeUser = (value) => {
    if (value) {
      setKeyGenerate({ ...keyGenerate, user_id: value.user_id });
    }
  };

  const handelChangeInput = (event) => {
    if (event.target.name === 'type_contract') {
      let categoryContract = uListCategoryContract.filter((item) => item.id === event.target.value);
      if (categoryContract && categoryContract.length) {
        setKeyGenerate({ ...keyGenerate, contract_name: categoryContract[0].name });
        setIdContract(event.target.value);
      }
    }
  };

  const watchStartDateValue = useWatch({
    control,
    name: 'start_date',
  });

  const watchEndDateValue = useWatch({
    control,
    name: 'end_date',
  });

  const watchCheckboxValueOnboard = useWatch({
    control,
    name: 'is_onboard',
    defaultValue: formDataContract.is_onboard,
  });

  return (
    <>
      <ModalComponent toggle={onSetShowModal} isShowModal={isShowModal} title={txtTitleModal}>
        <Box autoComplete="off" className={styles.modalCreateContract} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {formDataContract && formDataContract?.user_id ? (
                <InputField
                  label={t('contract.employeeName')}
                  name="user"
                  error=""
                  control={control}
                  setValue={setValue}
                  disabled={true}
                  maxLength={MAX_LENGTH_30}
                  defaultValue={formDataContract?.name}
                />
              ) : (
                <>
                  <div className={classNames(styleSelect.selectComponent, styleSelect.selectComponentAuto)}>
                    {uListMember && (
                      <Controller
                        name="user"
                        control={control}
                        defaultValue={null}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                          <Autocomplete
                            {...field}
                            disableClearable
                            // disablePortal
                            filterSelectedOptions
                            autoHighlight
                            getOptionDisabled={(option) => option.disabled}
                            getOptionLabel={(option) => option.user_name}
                            // onChange={(event, value) => field.onChange(value)}
                            onChange={(event, value) => {
                              field.onChange(value);
                              handleChangeUser(value);
                            }}
                            options={uListMember}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                  loading="lazy"
                                  width="20"
                                  onError={handleOnError}
                                  src={option.avatar}
                                  srcSet={`${option.avatar} 2x`}
                                  alt={option.avatar}
                                />
                                {option.user_name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                required
                                error={!!error}
                                helperText={error?.message}
                                label={t('contract.employeeName')}
                                name="user"
                                type="search"
                                inputRef={ref}
                                {...params}
                              />
                            )}
                          />
                        )}
                      />
                    )}
                  </div>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <SelectField
                name="type_contract"
                label={t('contract.contractNav.typeContract')}
                error={errors?.type_contract?.message}
                defaultValue={formDataContract?.type_contract}
                onHandleSelect={handelChangeInput}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
              >
                {uListCategoryContract &&
                  uListCategoryContract.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </SelectField>
            </Grid>

            <Grid item xs={12}>
              <InputField
                label={t('contract.codeContract')}
                name="code_contract"
                error={errors?.code_contract?.message}
                defaultValue={contractCode}
                isLoading={isLoadingGenerate}
                control={control}
                setValue={setValue}
                maxLength={MAX_LENGTH_30}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <p className="fs-14 color-navy text-bold">{t('contract.durationContract')}</p>
            </Grid>
            <Grid item xs={6}>
              <DateDayCustomInput
                label={t('masterData.placeholderStartDate')}
                name="start_date"
                error={errors?.start_date?.message}
                control={control}
                setValue={setValue}
                setError={setError}
                defaultValue={formDataContract?.start_date}
                register={register}
                maxDate={watchEndDateValue ? moment(watchEndDateValue, 'DD/MM/YYYY').subtract(1, 'days') : null}
              />
              <p className="error-input error">{errorForm?.time_error}</p>
            </Grid>

            <Grid item xs={6}>
              <DateDayCustomInput
                label={t('masterData.placeholderEndDate')}
                name="end_date"
                error={errors?.end_date?.message}
                control={control}
                setValue={setValue}
                defaultValue={
                  Number(watchSelectTypeContract) === INDEFINITE_TERM_CONTRACT ? null : formDataContract?.end_date
                }
                setError={setError}
                register={register}
                minDate={watchStartDateValue ? moment(watchStartDateValue, 'DD/MM/YYYY').add(1, 'days') : null}
                disabled={Number(watchSelectTypeContract) === INDEFINITE_TERM_CONTRACT ? true : false}
                isReadOnly={Number(watchSelectTypeContract) === INDEFINITE_TERM_CONTRACT ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={`${styles.colorCheckBox}`}>
                <CustomCheckboxYup
                  name="is_onboard"
                  onClick={(value) => {
                    if (!formDataContract?.is_onboard && value.target.checked) {
                      setCheckContractOnboard({ user_id: formDataContract?.user_id });
                    }
                    if (!formDataContract?.is_onboard && !value.target.checked) {
                      setCheckContractOnboard({ user_id: '' });
                    }
                    isClickCheckboxOnboard.current = true;
                  }}
                  defaultValue={watchCheckboxValueOnboard}
                  control={control}
                  register={register}
                  label={t('contract.haveContractOnboard')}
                />
              </div>
            </Grid>
            <p className={styles.notification}>{t('contract.notiEditOrCreate')}</p>
            <div className={styles.footerModal}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <ButtonComponent
                    text={t('common.cancel')}
                    addClass="w-100 btn-bg-yellow2"
                    handleClick={onSetShowModal}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ButtonComponent
                    text={txtBtnModal}
                    addClass="w-100"
                    type="submit"
                    handleClick={handleSubmit(onSubmit)}
                    hasDisabled={isSubmitting}
                    isLoading={isSubmitting}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Box>
      </ModalComponent>
    </>
  );
};

export default EditContract;
