import { DatePicker, Form, InputNumber, Select } from 'antd';
import {
  MIN_MONTH_COMMIT,
  MIN_SUPPORT_COST,
  rateCommitOptions,
  FIELD_KEY,
} from 'constants/Training';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, CalendarIcon } from '../icon';
import { DATE_FORMAT } from '..';
import styles from './style.module.scss';
import moment from 'moment';

const FormMember = ({ form, name, handleFinish, selectedMember }) => {
  const { t } = useTranslation();

  const mapOptions = (options) =>
    options
      ? options.map((option) => ({
          value: option.value ? option.value : option.id,
          ...option,
          label: option.label ? t(option.label) : option.name,
        }))
      : [];
  
  const handleDateChange = () => {
    const startDate = form.getFieldValue(FIELD_KEY.START_DATE);
    const monthCommit = form.getFieldValue(FIELD_KEY.MONTH_COMMIT);
    form.setFieldsValue({
      [FIELD_KEY.END_DATE]: moment(startDate).add(monthCommit, 'months')
    }); 
  }
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: t('messages.thisIsARequiredField'),
    whitespace: t('messages.thisIsARequiredField'),
    number: {
      range: t('messages.pleaseEnterValueBetween', { min: '${min}', max: '${max}' }),
      min: t('messages.pleaseEnterValueGreaterOrEqual', { min: '${min}' }),
    },
    types: {
      integer: t('messages.thisIsNotAnInteger')
    },
  };

  return (
    <Form
      form={form}
      name={name}
      layout="vertical"
      size="large"
      className="materialStyle form"
      validateMessages={validateMessages}
      onFinish={handleFinish}
    > 
      <div className={styles.formInfo}>
        <p className={styles.name}>{selectedMember.name}</p>
        <p className={styles.email + ' email'}>{selectedMember.email}</p>
      </div>
      <div className="flex gap-4">
          <Form.Item
            name={FIELD_KEY.START_DATE}
            label={t('training.startDate')}
          >
            <DatePicker
              suffixIcon={<CalendarIcon />}
              placeholder=""
              format={DATE_FORMAT}
              disabledDate={(day) => {
                const endDate = form.getFieldValue(FIELD_KEY.END_DATE);
                return day.isSameOrAfter(endDate) && day && endDate;
              }}
              onChange={handleDateChange}
            />
          </Form.Item>

          <Form.Item
            name={FIELD_KEY.END_DATE}
            label={t('training.endDate')}
          >
            <DatePicker
              suffixIcon={<CalendarIcon />}
              placeholder=""
              format={DATE_FORMAT}
              disabledDate={(day) => {
                const startDate = form.getFieldValue(FIELD_KEY.START_DATE);
                return day.isSameOrBefore(startDate) && day && startDate;
              }}
              onChange={handleDateChange}
              disabled
            />
          </Form.Item>
      </div>

      <Form.Item
        name={FIELD_KEY.MONTH_COMMIT}
        label={t('training.monthCommit')}
        rules={[
          { required: true },
          {
            type: 'integer',
            min: MIN_MONTH_COMMIT,
          },
        ]}
      >
        <InputNumber controls={false} disabled/>
      </Form.Item>

      <Form.Item
        name={FIELD_KEY.SUPPORT_COST}
        label={t('training.supportCost')}
        rules={[
          { required: true },
          {
            type: 'integer',
            min: MIN_SUPPORT_COST,
          },
        ]}
      >
        <InputNumber controls={false} maxLength={9}/>
      </Form.Item>

      <Form.Item name={FIELD_KEY.RATE_COMMIT} label={t('training.rateCommit')} rules={[{ required: true }]}>
        <Select 
          options={mapOptions(rateCommitOptions)} 
          suffixIcon={<ArrowDownIcon />} 
          allowClear 
          className='modal-select-option'
          disabled
        />
      </Form.Item>

    </Form>
  );
};

export default FormMember;
