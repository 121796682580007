import { styled } from '@mui/material/styles';
import { Radio, TextField } from '@mui/material';

import * as COLOR from 'constants/ColorForm.js';
import { COLOR_CHECKBOX_NOT_ACTIVE } from 'constants/ColorVariable';
import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';

export const IS_INPUT_SEARCH = true;

// Style general
const muiLabel = {
  '& label': {
    fontSize: '14px',
  },
  '&:hover label': {
    color: COLOR.GREEN,
  },
  '& label.Mui-focused': {
    color: COLOR.GREEN,
    top: '0px !important',
  },
  '&:hover label.Mui-disabled': {
    color: COLOR.SILVER,
  },
  '& label.Mui-disabled': {
    color: COLOR.SILVER,
  },
  '& label.Mui-error': {
    color: COLOR.RED,
  },
};
const muiError = {
  '&:hover fieldset': {
    borderColor: COLOR.RED,
  },
  '&.Mui-focused fieldset': {
    borderColor: COLOR.RED,
  },
  '.MuiSvgIcon-root': {
    color: COLOR.RED,
  },
};
const muiGeneral = {
  height: '50px',
  fontSize: '14px',

  '& fieldset': {
    borderColor: COLOR.WHEAT,
  },

  // Hover
  '&:hover fieldset': {
    borderColor: COLOR.GREEN,
  },

  // Focus
  '&.Mui-focused fieldset': {
    borderColor: COLOR.GREEN,
  },

  // Disabled
  '&.Mui-disabled fieldset': {
    borderColor: COLOR.WHEAT,
  },

  '&.Mui-disabled:hover .MuiSvgIcon-root': {
    color: 'currentColor',
  },

  '&.Mui-disabled:hover fieldset': {
    borderColor: COLOR.WHEAT,
  },
};

// Normal Input Input
export const CssTextField = styled(TextField)(({ labelspace, heightauto }) => ({
  // Style for label
  '&': muiLabel,
  '& label': {
    top: labelspace,
  },

  // General
  '& .MuiOutlinedInput-root': muiGeneral,
  '& .MuiInputBase-formControl': {
    height: heightauto,
  },
  '& .MuiOutlinedInput-root.Mui-error': muiError,
}));

// Select
export const CssSelectField = styled('div')(({ labelspace }) => ({
  // Style for label
  '&': muiLabel,
  '& label': {
    top: labelspace,
  },
  '&:hover .Mui-disabled-parent label': {
    color: COLOR.SILVER,
  },
  // General
  '& .MuiOutlinedInput-root': muiGeneral,

  // Select icon
  '& .MuiSelect-icon': {
    fontSize: '2.25rem',
  },
  '&:hover .MuiSvgIcon-root': {
    color: COLOR.GREEN,
  },
  '& .Mui-disabled-parent .MuiSvgIcon-root': {
    color: 'currentColor',
  },
  '.Mui-focused .MuiSvgIcon-root': {
    color: COLOR.GREEN,
  },

  // Error
  '& .MuiOutlinedInput-root.Mui-error': muiError,
}));

// Autocomplete
export const CssAutoCompleteField = styled(TextField)(({ labelspace }) => ({
  // Style for label
  '&': muiLabel,
  '& label': {
    top: labelspace,
  },
  // General
  '& .MuiOutlinedInput-root': muiGeneral,

  // Autocomplete icon
  '& .MuiAutocomplete-inputRoot': {
    height: 'auto',
    paddingRight: '9px !important',
  },
  '& .MuiAutocomplete-endAdornment': {
    position: 'relative',
    right: '-3px !important',
  },
  '& .MuiAutocomplete-popupIndicator .MuiSvgIcon-root': {
    fontSize: '2.25rem',
  },
  '&:hover .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
    color: COLOR.GREEN,
  },
  '.Mui-focused .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
    color: COLOR.GREEN,
  },

  // Error
  '& .MuiOutlinedInput-root.Mui-error': muiError,
}));

// Date
export const CssDateField = styled('div')(({ labelspace }) => ({
  // Style for label
  '&': muiLabel,
  '& label': {
    top: labelspace,
  },

  // Calendar icon
  '& .MuiSelect-icon': {
    fontSize: '2.25rem',
  },
  '&:hover .MuiSvgIcon-root': {
    color: COLOR.GREEN,
  },
  '.Mui-focused .MuiSvgIcon-root': {
    color: COLOR.GREEN,
  },

  // General
  '& .MuiOutlinedInput-root': muiGeneral,
  '& .MuiOutlinedInput-root.Mui-error': muiError,
}));

// Radio component
export const CONTROL_RADIO = (
  <Radio
    sx={{
      color: COLOR_CHECKBOX_NOT_ACTIVE,
      '&.Mui-checked': {
        color: COLOR_CHECKBOX_CUSTOM,
      },
    }}
  />
);
