import React, { useCallback, useState, useContext } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { Controller, useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import $ from 'jquery';
import moment from 'moment';
import classNames from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './style.scss';
import styles from './style.module.scss';
import {
  ButtonBase,
  Grid,
  debounce,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  MenuItem,
  Menu,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TablePagination from '@mui/material/TablePagination';
import CachedIcon from '@mui/icons-material/Cached';

import { useListBriefRentPrice, useListDetailRentPrice, useTotalAssetUsingOfDivison } from 'hook/useAssets.js';
import { convertFormatTimeSend } from 'views/pages/MasterData/SpecialTimekeeping/ultil';
import { COLOR_CHECKBOX_NOT_ACTIVE } from 'constants/ColorVariable';
import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import { customDate } from 'helpers/formatDate.js';
import ButtonComponent from 'components/Buttons';
import SearchComponent from 'components/Search';
import iconLoading from 'assets/img/icons/loading.png';
import seeDetail from 'assets/img/icons/see-detail.svg';
import seeShortened from 'assets/img/icons/see-shortened.svg';
import checked from 'assets/img/icons/check-view.svg';
import Loading from 'components/Loading';
import TotalRent from 'assets/img/icons/total-rent.svg';
import iconActive from 'assets/img/icons/active.png';
import down from 'assets/img/icons/down.svg';
import { handleExportExcel } from 'helpers';
import * as Constants from 'constants/index.js';
import {
  USE_LIST_BRIEF_RENT_PRICE,
  USE_LIST_DETAIL_RENT_PRICE,
  USE_TOTAL_ASSET_USING_OF_DIVISION,
} from 'constants/Assets.js';
import { COLOR_MENU_SELECTED } from 'constants/ColorVariable.js';
import DateDayMonthComponent from 'components/DateTime/DateDay.js';
import { formatNumberCurrency } from 'helpers/format';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';
import Assets from '../index';
import AlertComponent from 'components/Alert';
import ModalRecall from '../Modal/ModalRecall';
import './style.scss';

const RentalPriceList = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);

  const { control } = useForm({
    mode: 'onBlur',
  });

  const [filter, setFilter] = useState({
    key_word_asset: null,
    key_word: null,
    from_date: null,
    to_date: convertFormatTimeSend(new Date(), 'DD/MM/YYYY'),
    division_id: null,
    page: Constants.DEFAULT_PAGE_MUI,
    per_page: Constants.DEFAULT_LIMIT,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [valueSelected, setValueSelected] = React.useState({});
  const [anchorElRecall, setAnchorElRecall] = React.useState(null);
  const [isShowModalRecall, setIsShowModalRecall] = useState(false);
  const [widthBrowser, setWidthBrowser] = useState($(window).width());
  const view = window.location.href.indexOf('brief') < 0 ? false : true;
  const open = Boolean(anchorEl);
  const openReCall = Boolean(anchorElRecall);

  const handleClickReCall = (event, item) => {
    setValueSelected(item);
    setAnchorElRecall(event.currentTarget);
    event.stopPropagation();
  };

  const handleCloseReCall = (event) => {
    event.stopPropagation();
    setAnchorElRecall(null);
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const { data: uListBriefRentPrice, isLoading: isLoadingListBriefRentPrice } = useListBriefRentPrice({
    ...filter,
    page: filter?.page + 1,
  });

  const { data: uListDetailRentPrice, isLoading: isLoadingListDetailRentPrice } = useListDetailRentPrice({
    ...filter,
    page: filter?.page + 1,
  });

  const { data: uTotalAssetUsingOfDivison, isLoading: isLoadingTotalAssetUsingOfDivison } = useTotalAssetUsingOfDivison(
    {
      ...filter,
    },
  );

  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchName = useCallback(
    debounce((value) => {
      setFilter({ ...filter, key_word: value, page: Constants.DEFAULT_PAGE_MUI });
    }),
  );

  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchNameAssets = useCallback(
    debounce((value) => {
      setFilter({ ...filter, key_word_asset: value, page: Constants.DEFAULT_PAGE_MUI });
    }),
  );

  const handleReset = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFilter({
      key_word_asset: '',
      key_word: '',
      division_id: '',
      from_date: null,
      to_date: convertFormatTimeSend(new Date(), 'DD/MM/YYYY'),
      page: Constants.DEFAULT_PAGE_MUI,
      per_page: Constants.DEFAULT_LIMIT,
    });
    await queryClient.invalidateQueries(USE_LIST_BRIEF_RENT_PRICE);
    await queryClient.invalidateQueries(USE_LIST_DETAIL_RENT_PRICE);
    await queryClient.invalidateQueries(USE_TOTAL_ASSET_USING_OF_DIVISION);
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  $(function scroll() {
    var newWidth = $('table').width();
    $('.top').width(newWidth);
    $('.wrapper-top').scroll(function () {
      $('.scroll-custom').scrollLeft($('.wrapper-top').scrollLeft());
    });
    $('.scroll-custom').scroll(function () {
      $('.wrapper-top').scrollLeft($('.scroll-custom').scrollLeft());
    });
  });

  var px_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;

  $(window).resize(function () {
    isZooming();
    setWidthBrowser($(window).width());
  });

  function isZooming() {
    var newPx_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
    if (newPx_ratio === px_ratio) {
      var newWidth = $('table').width();
      $('.top').width(newWidth);
    }
  }

  const recallAssets = () => {
    confirmAlert({
      title: t('assets.areYouSureYouWantToRecoverThisProperty'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            setIsShowModalRecall(true);
          },
        },
      ],
    });
  };

  return (
    <>
      <Grid container className="rental-price-list">
        <Grid container className="nav-fixed">
          <Grid container className="align-items-center">
            <Grid item md={8} xs={12}>
              <Assets />
            </Grid>
            <Grid className="button" item md={4} xs>
              <ButtonComponent
                handleClick={() =>
                  view
                    ? handleExportExcel(
                        '/api/asset/brief-rent-price/export/excel',
                        {
                          ...filter,
                        },
                        'brief_rent_price_list.xlsx',
                      )
                    : handleExportExcel(
                        '/api/asset/detail-rent-price/export/excel',
                        {
                          ...filter,
                        },
                        'detail_rent_price_list.xlsx',
                      )
                }
                addClass="export"
                icon={<BiLinkExternal />}
                text={t('common.export')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={9} className="asset-main">
            <div className="profile-box table-list table-border-show">
              {view && (
                <Paper>
                  <TableContainer className="scroll-custom">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead className="asset-header head-title">
                        <TableRow className="asset-header-row sum ">
                          <TableCell className="short">{t('assets.assetsListRequests.stt')}</TableCell>
                          <TableCell className="nor">{t('assets.userCode')}</TableCell>
                          <TableCell>{t('assets.user')}</TableCell>
                          <TableCell>{t('employees.email')}</TableCell>
                          <TableCell>{t('division.Division')}</TableCell>
                          <TableCell>{t('assets.rentalPriceList.rentalAmount')}</TableCell>
                          <TableCell className="short">
                            {' '}
                            <div className="floatRight">
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls="long-menu"
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event) => handleClick(event)}
                              >
                                <img src={down} alt={down} />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {/* Sum */}
                      <TableHead className="asset-header sum-header">
                        {/* Sum */}
                        {uListBriefRentPrice?.data?.total > 0 && (
                          <TableRow hover tabIndex={-1}>
                            <TableCell colSpan={5} className="total">
                              {t('assets.rentalPriceList.totalList')}
                            </TableCell>
                            <TableCell colSpan={2} className="total">
                              {formatNumberCurrency(uListBriefRentPrice?.total_rent_price_filter)}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableHead>

                      <TableBody>
                        {/* Loading */}
                        {isLoadingListBriefRentPrice && (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <Loading addClass="mt-3 mb-3" />
                            </TableCell>
                          </TableRow>
                        )}

                        {/* Main content table */}
                        {uListBriefRentPrice &&
                          uListBriefRentPrice?.data?.data?.map((item, index) => {
                            return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                <TableCell>{index + filter.page * filter.per_page + 1}</TableCell>
                                <TableCell>{item?.user_code ? item?.user_code : t('common.notDataContract')}</TableCell>
                                <TableCell>
                                  <p className="user-name">{item?.name ? item.name : t('common.notDataContract')}</p>
                                </TableCell>
                                <TableCell>
                                  <p>{item?.email ? item?.email : t('common.notDataContract')}</p>
                                </TableCell>
                                <TableCell>
                                  {item?.division_name ? item.division_name : t('common.notDataContract')}
                                </TableCell>
                                <TableCell>
                                  {item?.total_rent_price
                                    ? formatNumberCurrency(item.total_rent_price)
                                    : 0}
                                </TableCell>
                                <TableCell>
                                  <div className="floatRight">
                                    <IconButton
                                      className={`${
                                        dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW && DISABLE_ROLE
                                      }`}
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls="long-menu"
                                      aria-expanded={open ? 'true' : undefined}
                                      aria-haspopup="true"
                                      onClick={(event) =>
                                        dataInforUser?.hr_permission?.assets_permission !== ROLE_VIEW &&
                                        handleClickReCall(event, item)
                                      }
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}

                        {/* No data */}
                        {!uListBriefRentPrice?.data?.total && !isLoadingListBriefRentPrice && (
                          <tr>
                            <td colSpan={6} className="error-no-data">
                              {t('common.notData')}
                            </td>
                          </tr>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {uListBriefRentPrice && uListBriefRentPrice?.data?.total ? (
                    <TablePagination
                      rowsPerPageOptions={Constants.PER_PAGE_OPTION}
                      component="div"
                      count={uListBriefRentPrice?.data?.total}
                      rowsPerPage={filter?.per_page}
                      page={filter?.page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      className="table-pagination"
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                    />
                  ) : null}
                </Paper>
              )}
              {!view && (
                <Paper>
                  {uListDetailRentPrice?.data?.total > 0 && Number(widthBrowser) < 1366 && (
                    <div className="wrapper-top">
                      <div className="scroll-top">
                        <p className="top">
                          <span>{t('common.export')}</span>
                        </p>
                      </div>
                    </div>
                  )}
                  <TableContainer className="scroll-custom">
                    <Table stickyHeader aria-label="sticky table" className="table-scroll">
                      <TableHead className="asset-header head-title">
                        <TableRow className="asset-header-row sum ">
                          <TableCell>{t('assets.codeAsset')}</TableCell>
                          <TableCell>{t('assets.assetsName')}</TableCell>
                          <TableCell>{t('assets.priceRentVnd')}</TableCell>
                          <TableCell>{t('assets.userCode')}</TableCell>
                          <TableCell className="long right">{t('assets.user')}</TableCell>
                          <TableCell className="long right">{t('employees.email')}</TableCell>
                          <TableCell>{t('division.Division')}</TableCell>
                          <TableCell>{t('assets.rentalPriceList.borrowedDate')}</TableCell>
                          <TableCell>{t('assets.rentalPriceList.returnDate')}</TableCell>
                          <TableCell className="long">{t('assets.rentalPriceList.numberOfDaysOfUse')}</TableCell>
                          <TableCell>{t('assets.rentalPriceList.total')}</TableCell>
                          <TableCell className="short">
                            <div className="floatRight">
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls="long-menu"
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event) => handleClick(event)}
                              >
                                <img src={down} alt={down} />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead className="asset-header sum-header custom-header">
                        {uListDetailRentPrice?.data?.total > 0 && (
                          <TableRow hover tabIndex={-1}>
                            <TableCell colSpan={10} className="total detail">
                              {t('assets.rentalPriceList.totalList')}
                            </TableCell>
                            <TableCell colSpan={2} className="total">
                              {formatNumberCurrency(uListDetailRentPrice?.total_rent_price_filter)}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableHead>
                      <TableBody>
                        {isLoadingListDetailRentPrice && (
                          <TableRow>
                            <TableCell colSpan={12}>
                              <Loading addClass="mt-3 mb-3" />
                            </TableCell>
                          </TableRow>
                        )}
                        {uListDetailRentPrice &&
                          uListDetailRentPrice?.data?.data?.map((item, index) => {
                            return (
                              <TableRow hover tabIndex={-1} key={index}>
                                <TableCell>{item?.code ? item.code : t('common.notDataContract')}</TableCell>
                                <TableCell>{item?.name ? item.name : t('common.notDataContract')}</TableCell>
                                <TableCell>
                                  {item.rent_price
                                    ? formatNumberCurrency(Math.round(item.rent_price))
                                    : 0}
                                </TableCell>
                                <TableCell>{item?.user_code ? item?.user_code : t('common.notDataContract')}</TableCell>
                                <TableCell>
                                  <p className="user-name">
                                    {item?.user_name ? item.user_name : t('common.notDataContract')}
                                  </p>
                                </TableCell>
                                <TableCell>
                                  <p>{item?.email ? item?.email : t('common.notDataContract')}</p>
                                </TableCell>
                                <TableCell>
                                  {item?.division_name ? item.division_name : t('common.notDataContract')}
                                </TableCell>
                                <TableCell>{item?.hand_over ? item.hand_over : t('common.notDataContract')}</TableCell>
                                <TableCell>{item?.take_back ? item.take_back : t('common.notDataContract')}</TableCell>
                                <TableCell>{item?.time_use ? item.time_use : t('common.notDataContract')}</TableCell>
                                <TableCell>
                                  {item.total_rent_price
                                    ? formatNumberCurrency(item.total_rent_price)
                                    : 0}
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            );
                          })}
                        {!uListDetailRentPrice?.data?.total && !isLoadingListDetailRentPrice && (
                          <TableRow>
                            <TableCell colSpan={12} className="error-no-data">
                              {t('common.notData')}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {uListDetailRentPrice && (
                    <TablePagination
                      rowsPerPageOptions={Constants.PER_PAGE_OPTION}
                      component="div"
                      count={uListDetailRentPrice?.data?.total}
                      rowsPerPage={filter?.per_page}
                      page={filter?.page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      className="table-pagination"
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                    />
                  )}
                </Paper>
              )}
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: '220px',
                    width: '22ch',
                  },
                }}
              >
                <NavLink
                  to={{
                    pathname: '/admin/assets/rental-price-list/brief',
                  }}
                  className={styles.linkRequest}
                >
                  <MenuItem
                    sx={{
                      background: view ? COLOR_MENU_SELECTED : '',
                      '&:hover': {
                        background: view ? COLOR_MENU_SELECTED + ' !important' : '',
                      },
                    }}
                  >
                    <ListItemIcon>
                      <img src={seeShortened} alt={seeShortened} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        marginRight: '10px',
                      }}
                      variant="inherit"
                      className="abc"
                    >
                      {t('assets.rentalPriceList.seeShortened')}
                    </Typography>
                    {view && (
                      <ListItemIcon>
                        <img src={checked} alt={checked} />
                      </ListItemIcon>
                    )}
                  </MenuItem>
                </NavLink>
                <NavLink
                  to={{
                    pathname: '/admin/assets/rental-price-list/detail',
                  }}
                  className={styles.linkRequest}
                >
                  <MenuItem
                    sx={{
                      background: !view ? COLOR_MENU_SELECTED : '',
                      '&:hover': {
                        background: !view ? COLOR_MENU_SELECTED + ' !important' : '',
                      },
                    }}
                  >
                    <ListItemIcon>
                      <img src={seeDetail} alt={seeDetail} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        marginRight: '12px',
                      }}
                      variant="inherit"
                    >
                      {' '}
                      {t('assets.rentalPriceList.seeDetail')}
                    </Typography>
                    {!view && (
                      <ListItemIcon>
                        <img src={checked} alt={checked} />
                      </ListItemIcon>
                    )}
                  </MenuItem>
                </NavLink>
              </Menu>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorElRecall}
                open={openReCall}
                onClose={handleCloseReCall}
                PaperProps={{
                  style: {
                    maxHeight: '220px',
                    width: '20ch',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorElRecall(null);
                    recallAssets();
                  }}
                >
                  <CachedIcon />
                  {t('divisionDiagram.recall')}
                </MenuItem>
              </Menu>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className="box-waiting">
              <div className="box-waiting-content">
                <img src={iconActive} alt={iconActive} />
                <div className="box-waiting-text">
                  <div className="waiting-text">{t('assets.rentalPriceList.assetsInUse')}</div>
                  <div className="waiting-number">
                    {view ? uListBriefRentPrice?.total_asset_using : uListDetailRentPrice?.total_asset_using}
                  </div>
                </div>
              </div>
              <div className="box-waiting-content second">
                <div className="content">
                  <img src={TotalRent} alt={TotalRent} />
                  <div className="box-waiting-text">
                    <div className="waiting-text">{t('assets.rentalPriceList.totalRent')}</div>
                    <div className="waiting-number">
                      {formatNumberCurrency(
                        view ? uListBriefRentPrice?.total_rent_price : uListDetailRentPrice?.total_rent_price,
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-filter">
              <div className={classNames(`text padding-element-filter`)}>
                <SearchComponent
                  txtPlaceholder={t('assets.assetsListRequests.searchNameEmployee')}
                  addClass="input-search-box"
                  handleSearch={handleSearchName}
                  defaultValue={filter?.key_word}
                  maxLength={Constants.MAX_LENGTH_20}
                />
              </div>
              {!view && (
                <>
                  <div className={classNames(`text padding-element-filter`)}>
                    <SearchComponent
                      txtPlaceholder={t('assets.searchNameAssets')}
                      addClass="input-search-box"
                      handleSearch={handleSearchNameAssets}
                      defaultValue={filter?.key_word_asset}
                      maxLength={Constants.MAX_LENGTH_20}
                    />
                  </div>
                </>
              )}
              <div className={classNames(`text month`)}>
                <DateDayMonthComponent
                  label=""
                  addClass="from-date"
                  name="from_date"
                  valueDefault={
                    typeof filter.from_date === 'string' ? moment(filter.from_date, 'DD/MM/YYYY') : filter.from_date
                  }
                  onChangeHandle={(value) => {
                    setFilter({
                      ...filter,
                      from_date: customDate(value, 'DD/MM/YYYY'),
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  placeholder={t('masterData.placeholderStartDate')}
                  sizeDate="small"
                  max={moment(filter?.to_date, 'DD/MM/yyyy').toDate()}
                />
                <p className="between">~</p>
                <DateDayMonthComponent
                  label=""
                  name="to_date"
                  valueDefault={
                    typeof filter.to_date === 'string' ? moment(filter.to_date, 'DD/MM/YYYY') : filter.to_date
                  }
                  onChangeHandle={(value) => {
                    setFilter({
                      ...filter,
                      to_date: customDate(value, 'DD/MM/YYYY'),
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  placeholder={t('masterData.placeholderEndDate')}
                  sizeDate="small"
                  min={moment(filter?.from_date, 'DD/MM/yyyy').toDate()}
                  max={moment().toDate()}
                />
              </div>
              <div className={classNames(`padding-element-top`)}>
                <FormControl component="fieldset">
                  <FormLabel className={classNames(`text padding-element-filter border-top padding-element-top`)}>
                    {t('division.Division')}
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup {...field} value={filter?.division_id ? filter?.division_id : 0}>
                        <FormControlLabel
                          value={0}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              division_id: null,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={
                            <Radio
                              sx={{
                                color: COLOR_CHECKBOX_NOT_ACTIVE,
                                '&.Mui-checked': {
                                  color: COLOR_CHECKBOX_CUSTOM,
                                },
                              }}
                            />
                          }
                          label={t('employees.all')}
                        />
                        {isLoadingTotalAssetUsingOfDivison && <Loading />}
                        {Array.isArray(uTotalAssetUsingOfDivison?.data) &&
                          uTotalAssetUsingOfDivison?.data?.map((item, index) => (
                            <div key={index} className="division">
                              <FormControlLabel
                                key={index}
                                value={item?.id}
                                onChange={(e) => {
                                  setFilter({
                                    ...filter,
                                    division_id: e.target.value,
                                    page: Constants.DEFAULT_PAGE_MUI,
                                  });
                                  window.scrollTo({ top: 0, behavior: 'smooth' });
                                }}
                                control={
                                  <Radio
                                    sx={{
                                      color: COLOR_CHECKBOX_NOT_ACTIVE,
                                      '&.Mui-checked': {
                                        color: COLOR_CHECKBOX_CUSTOM,
                                      },
                                    }}
                                  />
                                }
                                label={item?.name}
                              />
                              <span className="total">{item.total_using ? item?.total_using : 0}</span>
                            </div>
                          ))}
                      </RadioGroup>
                    )}
                    name="asset_category_id"
                    control={control}
                    defaultValue={filter?.division_id ? filter?.division_id : 0}
                  />
                </FormControl>
              </div>
              <div className="border-top">
                <ButtonBase onClick={() => handleReset()} className={classNames(`reset border-top`)} size="lg">
                  <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                  {t('common.reset')}
                </ButtonBase>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {isShowModalRecall && (
        <ModalRecall
          isShowModal={isShowModalRecall}
          setShowModal={setIsShowModalRecall}
          selectedValue={valueSelected}
          setMessage={setMessage}
          setShowAlert={setShowAlert}
        />
      )}
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};

export default RentalPriceList;
