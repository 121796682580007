import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Avatar } from '@mui/material';

import Modal from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import AlertComponent from 'components/Alert';

import styles from './styles.module.scss';
import EditContract from './EditContract.js';
import { ThemContext } from 'layouts/Admin';
import { useGetContractHistory, useGetDetailContract } from 'hook/useContract';
import { ROLE_VIEW, DISABLE_BUTTON_ROLE } from 'constants/index.js';
import { handleOnError } from 'helpers';

const ContractDetail = ({ contractId, userId, toggle, onHandleDelete, isOnBoard }) => {
  const { t } = useTranslation();
  const dataInforUser = useContext(ThemContext);

  const { data: detailContract = {} } = useGetDetailContract({ contractId, userId });
  const { data: contractHistory = [] } = useGetContractHistory({ contractId, userId });
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const [message, setMessage] = useState();
  const [isShowAlert, setShowAlert] = useState(false);

  const handleShowMessage = (value) => {
    setMessage(value);
    setShowAlert(true);
  };

  const handleShowModal = () => {
    setShowModalEdit(!isShowModalEdit);
  };

  return (
    <>
      <Modal
        isShowModal={!!contractId}
        toggle={toggle}
        title={t('contract.contractDetail.contractDetail')}
        classNameAdd="modalContractDetail"
      >
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <Avatar onError={handleOnError} alt="" src={detailContract?.avatar} sx={{ width: 35, height: 35, marginRight: 1.2 }} />

            <div className={styles.headerLeftInfo}>
              <p className={styles.username}>{detailContract?.user_name}</p>
              <p className={styles.email}>
                {detailContract?.email} - {detailContract?.position_name}
              </p>
              <p className={styles.divisionName}>
                {t('contract.contractDetail.code')}: {detailContract?.user_code}
              </p>
            </div>
          </div>

          <div className={styles.headerRight}>
            <ButtonComponent
              addClass={`btn ${dataInforUser?.hr_permission?.contracts_permission !== ROLE_VIEW && 'btn-bg-yellow2'} ${styles.btnDelete
                } ${dataInforUser?.hr_permission?.contracts_permission === ROLE_VIEW && ' button-delete'}`}
              text={t('contract.contractDetail.btnDelete')}
              handleClick={() =>
                dataInforUser?.hr_permission?.contracts_permission !== ROLE_VIEW && onHandleDelete(contractId)
              }
            />
            <ButtonComponent
              addClass={`btn ${styles.btnEdit} ${dataInforUser?.hr_permission?.contracts_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                }`}
              text={t('contract.contractDetail.btnEdit')}
              handleClick={dataInforUser?.hr_permission?.contracts_permission !== ROLE_VIEW && handleShowModal}
            />
          </div>
        </div>

        <div className={styles.body}>
          <div className={styles.contractInfo}>
            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.contractType')}</p>
              <p className={styles.contractFieldValue}>{detailContract?.contract_category_name}</p>
            </div>

            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.start_date')}</p>
              <p className={styles.contractFieldValue}>{detailContract?.start_date}</p>
            </div>

            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.contractCode')}</p>
              <p className={styles.contractFieldValue}>{detailContract?.code}</p>
            </div>

            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.end_date')}</p>
              <p className={styles.contractFieldValue}>{detailContract?.end_date}</p>
            </div>

            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.customerType')}</p>
              <p className={styles.contractFieldValue}>{detailContract?.group_name}</p>
            </div>

            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.created_user')}</p>
              <p className={styles.contractFieldValue}>{detailContract?.created_user}</p>
            </div>

            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.division')}</p>
              <p className={styles.contractFieldValue}>{detailContract?.division_name}</p>
            </div>

            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.date_created')}</p>
              <p className={styles.contractFieldValue}>{detailContract?.date_created}</p>
            </div>

            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.position')}</p>
              <p className={styles.contractFieldValue}>{detailContract?.position_name}</p>
            </div>

            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.edited_user')}</p>
              <p className={styles.contractFieldValue}>{detailContract?.user_updated}</p>
            </div>

            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.receiveNotifyEmail')}</p>
              <p className={styles.contractFieldValue}>
                {detailContract?.send_mail
                  ? t('contract.contractDetail.receiveEmail')
                  : t('contract.contractDetail.noReceiveEmail')}
              </p>
            </div>

            <div className={styles.contractField}>
              <p className={styles.contractFieldTitle}>{t('contract.contractDetail.date_edited')}</p>
              <p className={styles.contractFieldValue}>{detailContract?.date_updated}</p>
            </div>
          </div>

          <div className={styles.contractHistory}>
            <div className={styles.subTitle}>{t('contract.contractDetail.contractHistory')}</div>

            <Timeline>
              {contractHistory?.map((contract) => (
                <TimelineItem key={contract.id}>
                  <TimelineOppositeContent color="text.secondary">
                    {contract.start_date} - {contract.end_date}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color={contract?.active ? 'success' : 'grey'} />
                    <TimelineConnector />
                  </TimelineSeparator>

                  <TimelineContent>
                    <div className={classNames(styles.timelineDetail, { [styles.active]: contract.active })}>
                      <div className={styles.timelineField}>
                        <p className={styles.timelineFieldTitle}>{t('contract.contractDetail.contractType')}</p>
                        <p className={styles.timelineFieldValue}>{contract.contract_category_name}</p>
                      </div>

                      <div className={styles.timelineField}>
                        <p className={styles.timelineFieldTitle}>{t('contract.contractDetail.created_user')}</p>
                        <p className={styles.timelineFieldValue}>{contract.created_user}</p>
                      </div>

                      <div className={styles.timelineField}>
                        <p className={styles.timelineFieldTitle}>{t('contract.contractDetail.customerType')}</p>
                        <p className={styles.timelineFieldValue}>{contract.group_name}</p>
                      </div>

                      <div className={styles.timelineField}>
                        <p className={styles.timelineFieldTitle}>{t('contract.contractDetail.date_created')}</p>
                        <p className={styles.timelineFieldValue}>{contract.date_created}</p>
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>
        </div>

        <EditContract
          isOnBoard={isOnBoard}
          formDataContract={detailContract}
          onSetMessage={handleShowMessage}
          isShowModalEdit={isShowModalEdit}
          onSetShowModal={handleShowModal}
        />

        <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
          <p>{message}</p>
        </AlertComponent>
      </Modal>
    </>
  );
};

export default ContractDetail;
