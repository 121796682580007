import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import SideNavBar from 'components/SideNavBar';
import { TAB_DASHBOARD } from 'constants/TimeKeeping';
import { TAB_TIMEKEEPING } from 'constants/TimeKeeping';
import { TAB_REQUEST } from 'constants/TimeKeeping';
import { TAB_OT_TABLE } from 'constants/TimeKeeping';

const TimeKeeping = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory();
  const [value, setValue] = useState(TAB_DASHBOARD);

  const routes = [
    {
      name: t('common.dashboard'),
    },
    {
      name: t('timekeeping.timeSheets'),
    },
    {
      name: t('timekeeping.request.name'),
    },
    {
      name: t('timekeeping.listOT'),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === TAB_DASHBOARD) {
      history.push('/admin/timekeeping/dashboard');
    }
    if (newValue === TAB_TIMEKEEPING) {
      history.push('/admin/timekeeping/time-sheets');
    }
    if (newValue === TAB_REQUEST) {
      history.push('/admin/timekeeping/request');
    }
    if (newValue === TAB_OT_TABLE) {
      history.push('/admin/timekeeping/list-ot');
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/admin/timekeeping/dashboard':
        setValue(TAB_DASHBOARD);
        break;
      case '/admin/timekeeping/time-sheets':
        setValue(TAB_TIMEKEEPING);
        break;
      case '/admin/timekeeping/request':
        setValue(TAB_REQUEST);
        break;
      case '/admin/timekeeping/list-ot':
        setValue(TAB_OT_TABLE);
        break;

      default:
        break;
    }
  }, [location.pathname]);

  return <SideNavBar routes={routes} onChange={handleChange} value={value} hasMarginBottom />;
};

export default TimeKeeping;
