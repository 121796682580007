import '../style.scss';
import { MdClose } from 'react-icons/md';
import { Form, Modal, InputNumber, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { FIELD_KEY, MIN_SUPPORT_COST } from 'constants/Training';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { apiEndCommitMemberCourse } from 'api/training';
import { HTTP_ACCEPTED } from 'constants/StatusCode';
import { useGetEndCommitCost } from 'hook/useTraining';

const CancelCommit = ({ isOpen, setIsOpen, refetch, selectedMember }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { end_commit_cost } = useGetEndCommitCost(selectedMember?.id);
  
  useEffect(() => {
    let fields = {
      [FIELD_KEY.END_COMMIT_COST]: end_commit_cost
    };

    form.setFieldsValue(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isOpen, selectedMember, end_commit_cost]);

  const updateUserCourseEndCommitMutation = useMutation((data) => apiEndCommitMemberCourse(data.id, data.payload), {
    onSuccess: (res) => {
      const status = res?.status;

      if (status === HTTP_ACCEPTED) {
        message.success(res.data.messages);
      } else {
        message.error(res.data.messages);
      }

      refetch();
      handleCancel();
    },
    onError: (error) => {
      message.error(error.response.data.messages);
    },
  });

  const handleFinish = (payload) => {
    updateUserCourseEndCommitMutation.mutate({id: selectedMember.id, payload});
  };

  const handleCancel = () => {
    form.resetFields();
    setIsOpen(false);
  };
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: t('messages.thisIsARequiredField'),
    whitespace: t('messages.thisIsARequiredField'),
    number: {
      range: t('messages.pleaseEnterValueBetween', { min: '${min}', max: '${max}' }),
      min: t('messages.pleaseEnterValueGreaterOrEqual', { min: '${min}' }),
    },
    types: {
      integer: t('messages.thisIsNotAnInteger')
    },
  };

  return (
    <>
      <Modal
        wrapClassName={'custom-modal'}
        title={t('training.cancelCommitment')}
        closeIcon={<MdClose color="#fea628" size={24} />}
        cancelButtonProps={{ className: 'btn-cancel' }}
        cancelText={t('common.cancel')}
        okButtonProps={{ className: 'btn-ok', loading: updateUserCourseEndCommitMutation.isLoading}}
        okText={t('common.confirm')}
        onCancel={handleCancel}
        onOk={() => {
          form.submit();
        }}
        visible={isOpen}
        width={500}
        style={{top: '120px'}}
      >
        <Form
            form={form}
            name={''}
            layout="vertical"
            size="large"
            className="materialStyle form"
            validateMessages={validateMessages}
            onFinish={handleFinish}
        > 
            <p>{t('training.areYouSureMember')} <b>{selectedMember?.name}</b> {t('training.hasCanceledCommit')}</p>
            <Form.Item
                name={FIELD_KEY.END_COMMIT_COST}
                label={t('timekeeping.fineCommitAmount')}
                rules={[
                { required: true },
                {
                    type: 'integer',
                    min: MIN_SUPPORT_COST,
                },
                ]}
            >
                <InputNumber controls={false} maxLength={9}/>
            </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CancelCommit;
