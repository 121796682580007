import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { message, Progress, Upload } from "antd"
import { Box } from "@mui/material"
import { HTTP_OK } from "constants/StatusCode"
import { handleExportExcel } from "helpers"
import { apiImportSocialInsurance } from "api/policy"
import ButtonComponent from "components/Buttons"
import ModalComponent from "components/Modal"
import IconImport from 'assets/img/icons/icon-import.png';
import Illegal from 'assets/img/icons/file-import-illegal.png';
import styles from './styles.module.scss'

const ImportModal = (props) => {
  const { refetch, open, onClose } = props
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(true)
  const [errors, setErrors] = useState([])
  const [fileList, setFileList] = useState([])
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  const handleChangeUpload = (info) => {
    if (info.file) {
      setDisabled(false)
    }
    setErrors([])
    setFileList(info.fileList)
  }

  const handleSubmit = async () => {
    if (!fileList.length) {
      setErrors([ ...errors, t('common.pleaseFillOutThisFieldImport') ])
      setDisabled(true)
      return
    }
    if (fileList[0]?.originFileObj) {
      setIsUploading(true)
      const formData = new FormData()
      formData.append('file', fileList[0].originFileObj)

      apiImportSocialInsurance(formData, {
        onUploadProgress: (event) => {
          setProgress(Number(event.loaded * 100 / event.total).toFixed(0))
        }
      }).then(res => {
        if (res.data.status === HTTP_OK) {
          message.success(t('masterData.assets.category.rentalPrices.import.message.success'))
        } else {
          setErrors(res?.data?.errors)
          setProgress(0)
          setFileList([])
          setIsUploading(false)
          return
        }
        refetch()
        handleClose()
      })
    }
  }

  const handleDownloadTemplate = () => {
    handleExportExcel(
      '/api/social-insurance/download/template',
      { link: 'https://acms-s3.s3.ap-southeast-1.amazonaws.com/template_import_social_insurance.xlsx' },
      'template_import_social_insurance.xlsx',
    )
  }

  const handleClose = () => {
    setDisabled(true)
    setErrors([])
    setFileList([])
    setProgress(0)
    setIsUploading(false)
    onClose()
  }

  return (
    <ModalComponent
      title={t('policy.importEmployeesParticipatingInsurance')}
      isShowModal={open}
      toggle={handleClose}
    >
      <Box>
        <Upload.Dragger
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={handleChangeUpload}
          maxCount={1}
          fileList={fileList}
          beforeUpload={() => false}
          showUploadList={false}
        >
          <img src={IconImport} alt="Import" className={styles.iconImport} />
          <p>{t('employees.notificationImport')}</p>
          <p>{t('employees.formatImport')}</p>
        </Upload.Dragger>
      </Box>
      <Box className="mt-4">
        <a
          href
          className={styles.link}
          onClick={handleDownloadTemplate}
        >
          {t('employees.notificationImportUser')}
        </a>
      </Box>
      <Box className="mt-4">
          <Box className={styles.progress}>
            <Box className={styles.progress__info}>
              <div>
                <span>{fileList?.[0] && isUploading ? 'Importing: ' : 'Import: '}</span>
                <span className={styles.fileName}>{fileList[0]?.name}</span>
              </div>
              <div className={styles.indicator}>{isUploading ? `${progress}%` : '0%'}</div>
            </Box>
            <Progress
              percent={progress}
              strokeWidth={4}
              status={progress < 100 ? 'active' : 'success'}
              strokeColor='#2ECC71'
              success={{ strokeColor: '#2ECC71' }}
              showInfo={false}
            />
          </Box>
          {errors?.map((error, index) => {
            return (
              <p key={index} className={styles.error}>
                {<img src={Illegal} alt="FileInvalid" className={styles.fileInvalid} />}
                {error}
              </p>
            )
          }) ?? null}
      </Box>
      <Box display='flex' gap={2} className="mt-5">
        <Box flex={1}>
          <ButtonComponent
            text={t('masterData.assets.category.rentalPrices.import.cancelBtn')}
            addClass="w-100 btn-bg-yellow2"
            handleClick={handleClose}
          />
        </Box>
        <Box flex={1}>
          <ButtonComponent
            text={t('masterData.assets.category.rentalPrices.import.importBtn')}
            addClass='w-100'
            handleClick={handleSubmit}
            hasDisabled={disabled}
          />
        </Box>
      </Box>
    </ModalComponent>
  )
}

export default ImportModal
