export const CHOOSE_ALL = 0;

export const INSURANCE_POLICY = 0;
export const POLICY_OF_TAX = 1;

// Tăng mới đóng đủ
export const STATUS_INCREASE_FULL = 1;
// Tăng mới giảm trừ
export const STATUS_INCREASE_PART = 2;
// Đang tham gia
export const STATUS_WORKING = 3;
// Giảm hẳn
export const STATUS_DECREASE_END = 4;
// Giảm nghỉ thai sản
export const STATUS_DECREASE_MATERNITY = 5;
// Giảm nghỉ ốm
export const STATUS_DECREASE_SICK = 6;
// Giảm nghỉ không lương
export const STATUS_DECREASE_UNPAID = 7;
// Truy thu
export const STATUS_RECALL = 8;
// Giảm hẳn (có truy thu)
export const STATUS_DECREASE_END_RECALL = 9;
// Không cần báo giảm
export const STATUS_NOT_DECREASE = 10;

export const FILTER_STATUS_INSURANCE = [
  { labelName: 'Tất cả', value: CHOOSE_ALL },
  { labelName: 'Tăng mới đóng đủ', value: STATUS_INCREASE_FULL },
  { labelName: 'Tăng mới giảm trừ', value: STATUS_INCREASE_PART },
  { labelName: 'Đang tham gia', value: STATUS_WORKING },
  { labelName: 'Giảm hẳn', value: STATUS_DECREASE_END },
  { labelName: 'Giảm nghỉ thai sản', value: STATUS_DECREASE_MATERNITY },
  { labelName: 'Giảm nghỉ ốm', value: STATUS_DECREASE_SICK },
  { labelName: 'Giảm nghỉ không lương', value: STATUS_DECREASE_UNPAID },
  { labelName: 'Truy thu', value: STATUS_RECALL },
  { labelName: 'Giảm hẳn (có truy thu)', value: STATUS_DECREASE_END_RECALL },
]

export const INCREASE_STATUS = [
  { id: STATUS_INCREASE_FULL, name: 'Tăng mới đóng đủ' },
  { id: STATUS_INCREASE_PART, name: 'Tăng mới giảm trừ' },
]

export const DECREASE_STATUS = [
  { id: STATUS_DECREASE_END, name: 'Giảm hẳn' },
  { id: STATUS_DECREASE_MATERNITY, name: 'Giảm nghỉ thai sản' },
  { id: STATUS_DECREASE_SICK, name: 'Giảm nghỉ ốm' },
  { id: STATUS_DECREASE_UNPAID, name: 'Giảm nghỉ không lương' },
  { id: STATUS_NOT_DECREASE, name: 'Không cần báo giảm' },
]

export const REQUEST_LIST_INSURANCE = 'request_list_insurance'

export const NOT_RECALL = 0
export const IS_RECALL = 1

