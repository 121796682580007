import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query';
import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import styles from './style.module.scss';
import ButtonComponent from 'components/Buttons';
import ModalComponent from 'components/Modal';
import SelectField from 'components/ReactForm/SelectField.js';
import { apiUpdateRequestRetire } from 'api/contract';
import { YupValidateEditRequestRetire } from './ValidationEditContract.js';
import DateDay from 'components/ReactForm/DateDay.js';
import {
  USE_LIST_RETIRE_CONTRACT,
  ZERO_STATUS_ACCEPT,
  THREE_STATUS_ACCEPT,
  TWO_STATUS_ACCEPT,
} from 'constants/Contract';

const EditRequestRetire = ({ onSetMessage, onSetShowModal, isShowModalEdit, formData }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(YupValidateEditRequestRetire()),
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    let formSubmit = {
      checkout_date: moment(data.date).format('DD/MM/YYYY'),
      status: data.status === THREE_STATUS_ACCEPT ? ZERO_STATUS_ACCEPT : data.status,
      request_id: formData?.request_id,
    };

    let response = {};
    response = await apiUpdateRequestRetire(formSubmit);
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      onSetMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      onSetShowModal(false);
    }
    if (response?.status === HTTP_OK) {
      onSetShowModal(false);
      onSetMessage([response.data.messages]);
      queryClient.invalidateQueries(USE_LIST_RETIRE_CONTRACT);
    }
    if (response?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      onSetMessage(t(response.data.messages));
      onSetShowModal(false);
    }
  };

  return (
    <>
      <ModalComponent toggle={onSetShowModal} isShowModal={isShowModalEdit} title={t('contract.editRequest')}>
        <Box autoComplete="off" className={styles.modalCreateContract} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p className={styles.textEdit}>{t('contract.retire')}</p>
            </Grid>
            <Grid item xs={12}>
              <DateDay
                label={t('contract.selectDateRetire')}
                name="date"
                control={control}
                setValue={setValue}
                defaultValue={moment(formData?.checkout_date, 'DD/MM/yyyy').toDate()}
                register={register}
                minDate={moment()}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectField
                name="status"
                label={t('contract.statusAccept')}
                defaultValue={formData?.status}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModalEdit}
              >
                <MenuItem value={TWO_STATUS_ACCEPT}>{t('contract.contractRetire.approved')}</MenuItem>
                <MenuItem value={THREE_STATUS_ACCEPT}>{t('contract.contractRetire.removeDecision')}</MenuItem>
              </SelectField>
            </Grid>
            <div className={styles.footerModal}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <ButtonComponent
                    text={t('common.cancel')}
                    addClass="w-100 btn-bg-yellow2"
                    handleClick={onSetShowModal}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ButtonComponent
                    text={t('assets.edit')}
                    addClass="w-100"
                    type="submit"
                    handleClick={handleSubmit(onSubmit)}
                    isLoading={isSubmitting}
                    hasDisabled={isSubmitting}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Box>
      </ModalComponent>
    </>
  );
};

export default EditRequestRetire;
