import React, { useCallback, useState, useEffect, useContext, useRef } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { Controller, useForm } from 'react-hook-form';
import { HiPencil } from 'react-icons/hi';
import CachedIcon from '@mui/icons-material/Cached';
import CheckCircle from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQueryClient } from 'react-query';
import $ from 'jquery';
import moment from 'moment';
import classNames from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import InputIcon from '@mui/icons-material/Input';
import Tooltip from '@mui/material/Tooltip';
import './style.scss';
import {
  ButtonBase,
  Grid,
  debounce,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputBase,
  IconButton,
  MenuItem,
  Menu,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useListAssets, useListCategoryAssets, useListTypeAssets, useListWareHouse } from 'hook/useAssets.js';
import { COLOR_CHECKBOX_NOT_ACTIVE } from 'constants/ColorVariable';
import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import ButtonComponent from 'components/Buttons';
import SearchComponent from 'components/Search';
import iconLoading from 'assets/img/icons/loading.png';
import Loading from 'components/Loading';
import iconAssetsTotal from 'assets/img/icons/icon-asset-total.svg';
import iconActive from 'assets/img/icons/active.png';
import iconCancel from 'assets/img/icons/cancel.svg';
import { handleExportExcel } from 'helpers';
import * as Constants from 'constants/index.js';
import { STATUS_USING, STATUS_STORAGE } from 'constants/Assets.js';
import {
  USE_LIST_ASSETS,
  USE_LIST_CATEGORY_ASSETS,
  USE_LIST_TYPE_ASSETS,
  LIST_TYPE_GOOD,
  LIST_TYPE_UNDER_WARRANTY,
  LIST_TYPE_WAIT_FOR_LIQUIDATION,
  LIST_TYPE_LIQUIDATION,
  LIST_TYPE_BROKEN,
  LIST_TYPE_WAIT_FOR_APPROVE,
  CANCEL,
} from 'constants/Assets';
import DateDayMonthComponent from 'components/DateTime/DateDay.js';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import ModalCreateAssets from '../Modal/ModalCreateAssets.js';
import { formatNumberCurrency } from 'helpers/format';
import ModalImportAsset from './ModalImportAssets';
import Assets from '../index';
import './style.scss';
import ModalRecall from '../Modal/ModalRecall';
import ModalEditAssets from '../Modal/ModalEditAssets';
import ModalDetailAssets from '../Modal/ModalDetailAssets';
import AlertComponent from 'components/Alert';
import { ZERO } from 'constants/MasterData';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE, DISABLE_BUTTON_ROLE, APPROVE, ROLE_EDIT } from 'constants/index.js';
import { COLOR_COMMON } from 'constants/ColorMap';
import { apiApproveAssets, apiDeleteAssets } from 'api/assets';
import { apiCancelAssets } from 'api/assets';

const ListAssets = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const { control } = useForm({
    mode: 'onBlur',
  });

  const DATA_USAGE_STATUS = [
    {
      id: undefined,
      name: t('contract.all'),
      status: false,
      value: Constants.ALL,
    },
    {
      id: STATUS_USING,
      name: t('assets.using'),
      status: false,
    },
    {
      id: STATUS_STORAGE,
      name: t('assets.storage'),
      status: false,
    },
  ];

  const [filter, setFilter] = useState({
    key_word_user: null,
    key_word: null,
    warehouse_id: '',
    status: '',
    status_using: '',
    input_date: null,
    asset_category_id: null,
    type: null,
    page: Constants.DEFAULT_PAGE_MUI,
    per_page: Constants.DEFAULT_LIMIT,
  });

  const isFirst = useRef(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isShowModalAddAssets, setIsShowModalAddAssets] = useState(false);
  const [isShowModalEditAssets, setIsShowModalEditAssets] = useState(false);
  const [isShowModalDetailAssets, setIsShowModalDetailAssets] = useState(false);
  const [isShowModalRecall, setIsShowModalRecall] = useState(false);
  const [isShowModalImport, setShowModalImport] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);
  const [widthBrowser, setWidthBrowser] = useState($(window).width());
  const [dataFilterBackup, setDataFilterBackup] = useState({});

  const open = Boolean(anchorEl);
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    setSelectedValue(item);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const {
    data: uListAssets,
    isLoading: isLoadingListAssets,
    refetch: reFetchListAssets,
  } = useListAssets({
    ...filter,
    page: filter?.page + 1,
  });
  const { data: uListCategoryAssets, isLoading: isLoadingListCategoryAssets } = useListCategoryAssets(filter);
  const { data: uListTypeAssets, isLoading: isLoadingListTypeAssets } = useListTypeAssets(filter);
  const { data: uListWareHouse } = useListWareHouse(filter);

  const [listWareHourse, setListWareHourse] = useState([]);
  const [listStatus, setListStatus] = useState(DATA_USAGE_STATUS);
  const [isModals, setIsModals] = useState({ ware_hourse: false, usage_status: false });

  useEffect(() => {
    let idAsset = window.location.href.slice(window.location.href.indexOf('id=') + 3);
    if (idAsset > 0 && !isFirst.current) {
      isFirst.current = true;
      setSelectedValue({ id: idAsset });
      setIsShowModalDetailAssets(true);
    }
    if (selectedIndex !== null) {
      setSelectedValue(uListAssets?.data?.data[selectedIndex])
    }
    // eslint-disable-next-line
  }, [uListAssets]);

  useEffect(() => {
    if (uListWareHouse) {
      const wareHourse = uListWareHouse.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));

      setListWareHourse([
        {
          id: undefined,
          name: t('contract.all'),
          status: false,
          value: Constants.ALL,
        },
        ...wareHourse,
      ]);
    }
  }, [uListWareHouse, t]);

  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchNameEmployee = useCallback(
    debounce((value) => {
      setFilter({ ...filter, key_word_user: value, page: Constants.DEFAULT_PAGE_MUI });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }),
  );

  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchNameAssets = useCallback(
    debounce((value) => {
      setFilter({ ...filter, key_word: value, page: Constants.DEFAULT_PAGE_MUI });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }),
  );

  function handleCheck(items, index) {
    let isCheckAll = true;
    if (index === 0) {
      isCheckAll = !items[0].status;
      items.forEach((item, ind) => (ind === 0 ? (item.status = isCheckAll) : (item.status = false)));
    } else {
      items.forEach(function (item, i) {
        if (((i !== index && item.status === false) || (i === index && item.status === true)) && i !== 0) {
          isCheckAll = false;
        }
      });
      if (isCheckAll) {
        items.forEach((item, ind) => (ind === 0 ? (item.status = true) : (item.status = false)));
      } else {
        items.forEach(function (item, i) {
          if (i === 0) {
            item.status = isCheckAll;
          } else if (index === i) {
            item.status = !item.status;
          }
        });
      }
    }

    return [...items];
  }

  const changeSelectWareHourse = (item) => {
    setListWareHourse(function (listWareHourse) {
      const index = listWareHourse.indexOf(item);
      return handleCheck(listWareHourse, index);
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const changeSelectStatus = (item) => {
    setListStatus(function (listStatus) {
      const index = listStatus.indexOf(item);
      return handleCheck(listStatus, index);
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleReset = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFilter({
      key_word_user: '',
      key_word: '',
      warehouse_id: '',
      status: '',
      input_date: null,
      asset_category_id: null,
      type: null,
      page: Constants.DEFAULT_PAGE_MUI,
      per_page: Constants.DEFAULT_LIMIT,
    });
    setListStatus(DATA_USAGE_STATUS);
    const wareHourse = uListWareHouse.map((item) => ({
      id: item.id,
      name: item.name,
      status: false,
    }));
    setListWareHourse([
      {
        id: undefined,
        name: t('contract.all'),
        status: false,
        value: Constants.ALL,
      },
      ...wareHourse,
    ]);
    await queryClient.invalidateQueries(USE_LIST_ASSETS);
    await queryClient.invalidateQueries(USE_LIST_CATEGORY_ASSETS);
    await queryClient.invalidateQueries(USE_LIST_TYPE_ASSETS);
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getStatusAsset = (status) => {
    if (status === LIST_TYPE_GOOD) {
      return t('assets.good');
    }
    if (status === LIST_TYPE_UNDER_WARRANTY) {
      return t('assets.underWarranty');
    }
    if (status === LIST_TYPE_WAIT_FOR_LIQUIDATION) {
      return t('assets.waitForLiquidation');
    }
    if (status === LIST_TYPE_LIQUIDATION) {
      return t('assets.liquidation');
    }
    if (status === LIST_TYPE_BROKEN) {
      return t('assets.broken');
    }
    if (status === LIST_TYPE_WAIT_FOR_APPROVE) {
      return t('assets.waitForApprove');
    }
    if (status === CANCEL) {
      return t('assets.cancel');
    }
    return t('assets.missing');
  };

  $(function scroll() {
    var newWidth = $('table').width();
    $('.top').width(newWidth);
    $('.wrapper-top').scroll(function () {
      $('.scroll-custom').scrollLeft($('.wrapper-top').scrollLeft());
    });
    $('.scroll-custom').scroll(function () {
      $('.wrapper-top').scrollLeft($('.scroll-custom').scrollLeft());
    });
  });

  var px_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;

  $(window).resize(function () {
    isZooming();
    setWidthBrowser($(window).width());
  });

  function isZooming() {
    var newPx_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
    if (newPx_ratio === px_ratio) {
      var newWidth = $('table').width();
      $('.top').width(newWidth);
    }
  }

  const recallAssets = () => {
    confirmAlert({
      title: t('assets.areYouSureYouWantToRecoverThisProperty'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            setIsShowModalRecall(true);
          },
        },
      ],
    });
  };

  const approveAssets = () => {
    confirmAlert({
      title: t('assets.pleaseConfirmAsset'),
      buttons: [
        {
          label: t('assets.cancelAsset'),
          className: 'btn-alert-cancel',
          onClick: async () => {
            const result = await apiCancelAssets(selectedValue);
            if (result) {
              setMessage(t('assets.cancelAssetSuccessfully'));
              setShowAlert(true);
              reFetchListAssets();
              setIsShowModalDetailAssets(false);
            }
          },
        },
        {
          label: t('common.approve'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const result = await apiApproveAssets(selectedValue);
            if (result) {
              setMessage(t('assets.approveSuccessfully'));
              setShowAlert(true);
              reFetchListAssets();
              setIsShowModalDetailAssets(false);
            }
          },
        },
      ],
    });
  };

  const deleteAssets = () => {
    confirmAlert({
      title: t('assets.areYouSureYouWantToDeleteThisProperty'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const result = await apiDeleteAssets(selectedValue);
            if (result) {
              setMessage(t('assets.deleteSuccessfully'));
              setShowAlert(true);
              reFetchListAssets();
              setIsShowModalDetailAssets(false);
            }
          },
        },
      ],
    });
  };

  const renderButtonImport = (permission) => {
    if (permission !== ROLE_EDIT && permission !== APPROVE) {
      return null;
    }
    return (
      <ButtonComponent
        handleClick={() => {
          if (permission === ROLE_VIEW) {
            return;
          }
          setShowModalImport(true);
        }}
        addClass={`${permission === ROLE_VIEW && DISABLE_BUTTON_ROLE} export`}
        icon={<InputIcon />}
        text={t('common.import')}
      />
    );
  };

  const renderMenuItem = () => {
    if (selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE) {
      if (dataInforUser?.hr_permission?.assets_permission === APPROVE) {
        return (
          <>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setIsShowModalEditAssets(true);
              }}
            >
              <HiPencil />
              {t('divisionDiagram.update')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                approveAssets();
              }}
            >
              <CheckCircle />
              {t('divisionDiagram.approve')}
            </MenuItem>
          </>
        );
      } else {
        return (
          <>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setIsShowModalEditAssets(true);
              }}
            >
              <HiPencil />
              {t('divisionDiagram.update')}
            </MenuItem>
          </>
        );
      }
    } else {
      if (selectedValue?.type !== CANCEL) {
        if (!selectedValue?.user_id) {
          return (
            <>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setIsShowModalEditAssets(true);
                }}
              >
                <HiPencil />
                {t('divisionDiagram.update')}
              </MenuItem>
            </>
          );
        } else {
          return (
            <>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setIsShowModalEditAssets(true);
                }}
              >
                <HiPencil />
                {t('divisionDiagram.update')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  recallAssets();
                }}
              >
                <CachedIcon />
                {t('divisionDiagram.recall')}
              </MenuItem>
            </>
          );
        }
      } else {
        return (
          <>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setIsShowModalEditAssets(true);
              }}
            >
              <HiPencil />
              {t('divisionDiagram.update')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                deleteAssets();
              }}
            >
              <DeleteIcon />
              {t('divisionDiagram.delete')}
            </MenuItem>
          </>
        );
      }
    }
  };

  return (
    <>
      <Grid container className="list-assets">
        <Grid container className="nav-fixed">
          <Grid container className="align-items-center">
            <Grid item md={6} xs={12}>
              <Assets />
            </Grid>
            <Grid item className="button" md={6} xs>
              {renderButtonImport(dataInforUser?.hr_permission?.assets_permission)}
              <ButtonComponent
                handleClick={() => handleExportExcel('/api/asset/export/excel', filter, 'assets_list.xlsx')}
                addClass="export"
                icon={<BiLinkExternal />}
                text={t('common.export')}
              />
              <ButtonComponent
                addClass={`${
                  dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                } create`}
                icon={<AddIcon />}
                text={t('assets.createNew')}
                handleClick={() => {
                  if (dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW) {
                    return;
                  }
                  setIsShowModalAddAssets(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={9} className="asset-main">
            <div className="profile-box table-list table-border-show">
              <Paper>
                {uListAssets?.data?.total > 0 && Number(widthBrowser) < 1366 && (
                  <div className="wrapper-top">
                    <div className="scroll-top">
                      <p className="top">
                        <span>{t('common.export')}</span>
                      </p>
                    </div>
                  </div>
                )}
                <TableContainer className={`${uListAssets?.data?.total && 'scroll-custom'}`}>
                  <Table aria-label="sticky table" className={`${uListAssets?.data?.total && 'table-scroll'}`}>
                    <TableHead className="asset-header">
                      <TableRow className="asset-header-row">
                        <TableCell className="col-code">{t('assetEmployee.code')}</TableCell>
                        <TableCell>{t('assetEmployee.name')}</TableCell>
                        <TableCell className="col-date">{t('assets.dateAdded')}</TableCell>
                        <TableCell className="col-code">{t('assets.userCode')}</TableCell>
                        <TableCell className="col-user">{t('assets.user')}</TableCell>
                        <TableCell className="col-user">{t('employees.email')}</TableCell>
                        <TableCell>{t('assets.assetsListRequests.category')}</TableCell>
                        <TableCell>{t('assets.priceCurrency')}</TableCell>
                        <TableCell>{t('assets.type')}</TableCell>
                        <TableCell>{t('assets.warehouseName')}</TableCell>
                        <TableCell className="short"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead className="asset-header sum-header">
                      {/* Sum */}
                      <TableRow hover tabIndex={-1}>
                        <TableCell colSpan={7} className="total">
                          {t('assets.rentalPriceList.totalAssests')} ( {uListAssets?.asset_total_plus} )
                        </TableCell>
                        <TableCell colSpan={4} className="total">
                          {formatNumberCurrency(uListAssets?.price_asset_total_plus)}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoadingListAssets && (
                        <TableRow>
                          <TableCell colSpan={10}>
                            <Loading addClass="mt-3 mb-3" />
                          </TableCell>
                        </TableRow>
                      )}
                      {uListAssets &&
                        uListAssets?.data?.data?.map((item, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              onClick={() => {
                                setSelectedValue(item);
                                setSelectedIndex(index);
                                setIsShowModalDetailAssets(true);
                              }}
                            >
                              <TableCell>{item.code ? item.code : t('common.notDataContract')}</TableCell>
                              <TableCell className='cel-wrap'>                     
                                <Tooltip title={item.name} placement="top-start">
                                  <span>{item.name ? item.name : t('common.notDataContract')}</span>
                                </Tooltip>
                              </TableCell>
                              <TableCell>{item.input_date ? item.input_date : t('common.notDataContract')}</TableCell>
                              <TableCell>{item.user_name ? item.user_code : t('common.notDataContract')}</TableCell>
                              <TableCell>
                                <p>
                                  {item.user_name ? (
                                    <span className="text-bold">{item.user_name}</span>
                                  ) : (
                                    t('common.notDataContract')
                                  )}
                                </p>
                                { item.position_name && item.division_name && (
                                  <p className="position">
                                    {item.position_name} - {item.division_name}
                                  </p>
                                  ) 
                                }
                              </TableCell>
                              <TableCell>
                                <p>{item.user_email ? item.user_email : t('common.notDataContract')}</p>
                              </TableCell>
                              <TableCell>
                                {item.asset_category_name ? item.asset_category_name : t('common.notDataContract')}
                              </TableCell>
                              <TableCell>{formatNumberCurrency(item.price)}</TableCell>
                              <TableCell
                                className={`
                                  ${getStatusAsset(item?.type) === t('assets.good') && 'status-good'}
                                  ${getStatusAsset(item?.type) === t('assets.underWarranty') && 'status-underWarranty'}
                                  ${
                                    getStatusAsset(item?.type) === t('assets.waitForLiquidation') &&
                                    'status-waitForLiquidation'
                                  }
                                  ${getStatusAsset(item?.type) === t('assets.liquidation') && 'status-liquidation'}
                                  ${getStatusAsset(item?.type) === t('assets.broken') && 'status-broken'}
                                  ${getStatusAsset(item?.type) === t('assets.missing') && 'status-missing'}
                                  ${
                                    getStatusAsset(item?.type) === t('assets.waitForApprove') && 'status-waitForApprove'
                                  }
                                  ${getStatusAsset(item?.type) === t('assets.cancel') && 'status-cancel'}
                                `}
                              >
                                {item.type ? getStatusAsset(item.type) : t('common.notDataContract')}
                              </TableCell>
                              <TableCell style={{ color: COLOR_COMMON[item?.warehouse_id] }}>
                                {item.warehouse_name ? item.warehouse_name : t('common.notDataContract')}
                              </TableCell>
                              <TableCell>
                                <div className="floatRight">
                                  <IconButton
                                    className={`${
                                      dataInforUser?.hr_permission?.assets_permission === ROLE_VIEW && DISABLE_ROLE
                                    }`}
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls="long-menu"
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(event) =>
                                      dataInforUser?.hr_permission?.assets_permission !== ROLE_VIEW &&
                                      handleClick(event, item)
                                    }
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}

                      {!uListAssets?.data?.total && !isLoadingListAssets && (
                        <tr>
                          <td colSpan={10} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {uListAssets?.data?.total > ZERO && (
                  <TablePagination
                    rowsPerPageOptions={Constants.PER_PAGE_OPTION}
                    component="div"
                    count={uListAssets?.data?.total}
                    rowsPerPage={filter?.per_page}
                    page={filter?.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="table-pagination"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                  />
                )}
              </Paper>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: '220px',
                    width: '20ch',
                  },
                }}
              >
                {renderMenuItem()}
              </Menu>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className="box-waiting">
              <div className="box-waiting-content">
                <img className="total-assets" src={iconAssetsTotal} alt={iconAssetsTotal} />
                <div className="box-waiting-text">
                  <div className="waiting-text">{t('assets.assetsListRequests.totalAssetNow')}</div>
                  <div className="waiting-number">{uListAssets?.asset_total}</div>
                </div>
              </div>
              <div className="box-waiting-content second">
                <div className="content">
                  <img src={iconActive} alt={iconActive} />
                  <div className="box-waiting-text">
                    <div className="waiting-text">{t('assets.assetsListRequests.assetsWorking')}</div>
                    <div className="waiting-number">{uListAssets?.asset_using}</div>
                  </div>
                </div>
              </div>
              <div className="box-waiting-content third">
                <div className="content">
                  <img src={iconCancel} alt={iconCancel} />
                  <div className="box-waiting-text">
                    <div className="waiting-text">{t('assets.assetsListRequests.assetsNotWorking')}</div>
                    <div className="waiting-number">{uListAssets?.asset_no_using}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-filter">
              <div className={classNames(`text padding-element-filter`)}>
                <SearchComponent
                  txtPlaceholder={t('assets.assetsListRequests.searchNameEmployee')}
                  addClass="input-search-box"
                  handleSearch={handleSearchNameEmployee}
                  defaultValue={filter?.key_word_user}
                  maxLength={Constants.MAX_LENGTH_20}
                />
              </div>
              <div className={classNames(`text padding-element-filter`)}>
                <SearchComponent
                  txtPlaceholder={t('assets.searchNameAssets')}
                  addClass="input-search-box"
                  handleSearch={handleSearchNameAssets}
                  defaultValue={filter?.key_word}
                  maxLength={Constants.MAX_LENGTH_20}
                />
              </div>
              <div className={classNames(`text padding-element-filter`)}>
                <InputBase
                  readOnly
                  type="text"
                  name="warehouse_id"
                  className={classNames('exampleSelectMulti', listWareHourse && 'text-input-dark')}
                  onClick={() => {
                    setIsModals((prev) => {
                      return { ...prev, ware_hourse: true };
                    });
                    setDataFilterBackup(JSON.parse(JSON.stringify(listWareHourse)));
                  }}
                  value={
                    listWareHourse &&
                    (!listWareHourse[0]?.status
                      ? listWareHourse
                          .map((item, i) =>
                            item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                          )
                          .filter((it) => it)
                      : t('employees.all'))
                  }
                  placeholder={t('masterData.bunker')}
                />
                <ModalCommon
                  items={listWareHourse}
                  onSubmit={() => {
                    let datas = [];
                    listWareHourse.forEach((it) => {
                      it.status && it.id && datas.push(it.id);
                    });
                    setFilter({
                      ...filter,
                      warehouse_id: datas,
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    setIsModals((prev) => {
                      return { ...prev, ware_hourse: false };
                    });
                  }}
                  onClick={changeSelectWareHourse}
                  toggle={() => {
                    setIsModals((prev) => {
                      return { ...prev, ware_hourse: !prev.ware_hourse };
                    });
                    setListWareHourse([...dataFilterBackup]);
                  }}
                  modal={isModals.ware_hourse}
                  title={t('masterData.bunker')}
                  footer={t('common.search')}
                />
              </div>
              <div className={classNames(`cursor-pointer text padding-element-filter`)}>
                <InputBase
                  readOnly
                  type="text"
                  name="status"
                  className={classNames('exampleSelectMulti', listStatus && 'text-input-dark')}
                  value={
                    listStatus &&
                    (!listStatus[0]?.status
                      ? listStatus
                          .map((item, i) =>
                            item.status && item.value !== Constants.ALL && item.id
                              ? (i !== 1 ? ' ' : '') + item.name
                              : '',
                          )
                          .filter((it) => it)
                      : t('employees.all'))
                  }
                  onClick={() => {
                    setDataFilterBackup(JSON.parse(JSON.stringify(listStatus)));
                    setIsModals((prev) => {
                      return { ...prev, usage_status: true };
                    });
                  }}
                  placeholder={t('assets.usageStatus')}
                />
                <ModalCommon
                  items={listStatus}
                  onSubmit={() => {
                    let datas = [];
                    let statusUsing = '';
                    let checkAll = true;
                    listStatus.forEach((it) => {
                      it.status && it.id && it.id !== STATUS_USING && datas.push(it.id);
                      it.status && it.id === STATUS_USING && (statusUsing = it.id);
                      !it.status && (checkAll = false);
                    });
                    if (checkAll) {
                      setFilter({
                        ...filter,
                        status: '',
                        status_using: '',
                        page: Constants.DEFAULT_PAGE_MUI,
                      });
                    } else {
                      setFilter({
                        ...filter,
                        status: datas ? datas : null,
                        status_using: statusUsing,
                        page: Constants.DEFAULT_PAGE_MUI,
                      });
                    }
                    setIsModals((prev) => {
                      return { ...prev, usage_status: false };
                    });
                  }}
                  onClick={changeSelectStatus}
                  toggle={() => {
                    setIsModals((prev) => {
                      return { ...prev, usage_status: !prev.usage_status };
                    });
                    setListStatus([...dataFilterBackup]);
                  }}
                  modal={isModals.usage_status}
                  title={t('assets.usageStatus')}
                  footer={t('common.search')}
                />
              </div>

              <div className={classNames(`text date`)}>
                <div className={classNames(`text`)}>
                  <DateDayMonthComponent
                      label=""
                      name="from_date"
                      valueDefault={
                        typeof filter.from_date === 'string' ? moment(filter.from_date, 'DD/MM/YYYY') : filter.from_date
                      }
                      onChangeHandle={(value) => {
                        setFilter({
                          ...filter,
                          from_date: moment(value).format('DD/MM/YYYY'),
                          page: Constants.DEFAULT_PAGE_MUI,
                        });
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }}
                      placeholder={t('masterData.placeholderStartDate')}
                      sizeDate="small"
                      max={moment(filter?.to_date, 'DD/MM/yyyy').toDate()}
                  />
                </div>
                <p className="between">~</p>
                <div className={classNames(`text`)}>
                  <DateDayMonthComponent
                      label=""
                      name="to_date"
                      valueDefault={
                        typeof filter.to_date === 'string' ? moment(filter.to_date, 'DD/MM/YYYY') : filter.to_date
                      }
                      onChangeHandle={(value) => {
                        setFilter({
                          ...filter,
                          to_date: moment(value).format('DD/MM/YYYY'),
                          page: Constants.DEFAULT_PAGE_MUI,
                        });
                        window.scrollTo({top: 0, behavior: 'smooth'});
                      }}
                      placeholder={t('masterData.placeholderEndDate')}
                      sizeDate="small"
                      min={moment(filter?.from_date, 'DD/MM/yyyy').toDate()}
                  />
                </div>
              </div>

              <div className={classNames(`padding-element-top`)}>
                <FormControl component="fieldset">
                  <FormLabel className={classNames(`text padding-element-filter border-top padding-element-top`)}>
                    {t('assets.assetsListRequests.category')}
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup {...field} value={filter?.asset_category_id ? filter?.asset_category_id : 0}>
                        <FormControlLabel
                          value={0}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              asset_category_id: null,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={
                            <Radio
                              sx={{
                                color: COLOR_CHECKBOX_NOT_ACTIVE,
                                '&.Mui-checked': {
                                  color: COLOR_CHECKBOX_CUSTOM,
                                },
                              }}
                            />
                          }
                          label={t('employees.all')}
                        />
                        {isLoadingListCategoryAssets && <Loading />}
                        {uListCategoryAssets?.map((item, index) => (
                          <div key={index} className="division">
                            <FormControlLabel
                              key={index}
                              value={item?.id}
                              onChange={(e) => {
                                setFilter({
                                  ...filter,
                                  asset_category_id: e.target.value,
                                  page: Constants.DEFAULT_PAGE_MUI,
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                              }}
                              control={
                                <Radio
                                  sx={{
                                    color: COLOR_CHECKBOX_NOT_ACTIVE,
                                    '&.Mui-checked': {
                                      color: COLOR_CHECKBOX_CUSTOM,
                                    },
                                  }}
                                />
                              }
                              label={item?.name}
                            />
                            <span className="total">{item.total_asset ? item?.total_asset : 0}</span>
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                    name="asset_category_id"
                    control={control}
                    defaultValue={filter?.asset_category_id ? filter?.asset_category_id : 0}
                  />
                </FormControl>
              </div>
              <div className={classNames(`padding-element-top`)}>
                <FormControl component="fieldset">
                  <FormLabel className={classNames(`text padding-element-filter border-top padding-element-top`)}>
                    {t('assetEmployee.status')}
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup {...field} value={filter?.type ? filter?.type : 0}>
                        <FormControlLabel
                          value={0}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              type: null,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={
                            <Radio
                              sx={{
                                color: COLOR_CHECKBOX_NOT_ACTIVE,
                                '&.Mui-checked': {
                                  color: COLOR_CHECKBOX_CUSTOM,
                                },
                              }}
                            />
                          }
                          label={t('employees.all')}
                        />
                        {isLoadingListTypeAssets && <Loading />}
                        {uListTypeAssets?.map((item, index) => (
                          <div key={index} className="division space-content">
                            <FormControlLabel
                              key={index}
                              value={item?.type}
                              onChange={(e) => {
                                setFilter({
                                  ...filter,
                                  type: e.target.value,
                                  page: Constants.DEFAULT_PAGE_MUI,
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                              }}
                              control={
                                <Radio
                                  sx={{
                                    color: COLOR_CHECKBOX_NOT_ACTIVE,
                                    '&.Mui-checked': {
                                      color: COLOR_CHECKBOX_CUSTOM,
                                    },
                                  }}
                                />
                              }
                              label={item?.name}
                            />
                            <div className="total-pd-top d-flex">
                              <div
                                className={`
                             ${'dot-status'}
                             ${getStatusAsset(item?.type) === t('assets.good') && 'color-status-good'}
                             ${getStatusAsset(item?.type) === t('assets.underWarranty') && 'color-status-underWarranty'}
                             ${
                               getStatusAsset(item?.type) === t('assets.waitForLiquidation') &&
                               'color-status-waitForLiquidation'
                             }
                             ${getStatusAsset(item?.type) === t('assets.liquidation') && 'color-status-liquidation'}
                             ${getStatusAsset(item?.type) === t('assets.broken') && 'color-status-broken'}
                             ${getStatusAsset(item?.type) === t('assets.missing') && 'color-status-missing'}
                             ${
                               getStatusAsset(item?.type) === t('assets.waitForApprove') &&
                               'color-status-waitForApprove'
                             }
                             ${getStatusAsset(item?.type) === t('assets.cancel') && 'color-status-cancel'}
                           `}
                              ></div>
                              <span className="total-custom">{item.total ? item?.total : 0}</span>
                            </div>
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                    name="type"
                    control={control}
                    defaultValue={filter?.type ? filter?.type : 0}
                  />
                </FormControl>
              </div>
              <div className="border-top">
                <ButtonBase onClick={() => handleReset()} className={classNames(`reset border-top`)} size="lg">
                  <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                  {t('common.reset')}
                </ButtonBase>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {isShowModalAddAssets && (
        <ModalCreateAssets
          isShowModal={isShowModalAddAssets}
          setShowModal={setIsShowModalAddAssets}
          message={message}
          setMessage={setMessage}
          isShowAlert={isShowAlert}
          setShowAlert={setShowAlert}
          dataInforUser={dataInforUser}
        />
      )}

      {isShowModalEditAssets && (
        <ModalEditAssets
          isShowModal={isShowModalEditAssets}
          setShowModal={setIsShowModalEditAssets}
          setMessage={setMessage}
          setShowAlert={setShowAlert}
          selectedValue={selectedValue}
          dataInforUser={dataInforUser}
        />
      )}

      {isShowModalDetailAssets && (
        <ModalDetailAssets
          isShowModal={isShowModalDetailAssets}
          setShowModal={setIsShowModalDetailAssets}
          selectedValue={selectedValue}
          setIsShowModalEditAssets={setIsShowModalEditAssets}
          displayEdit={true}
          setMessage={setMessage}
          setShowAlert={setShowAlert}
          approveAssets={approveAssets}
          deleteAssets={deleteAssets}
        />
      )}
      {isShowModalRecall && (
        <ModalRecall
          isShowModal={isShowModalRecall}
          setShowModal={setIsShowModalRecall}
          selectedValue={selectedValue}
          setMessage={setMessage}
          setShowAlert={setShowAlert}
        />
      )}
      <ModalImportAsset
        isApprove={dataInforUser?.hr_permission?.assets_permission === APPROVE}
        toggle={setShowModalImport}
        modal={isShowModalImport}
      />

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};

export default ListAssets;
