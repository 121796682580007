import { useQuery } from 'react-query';
import { HTTP_OK } from './../constants/StatusCode.js';
import {
  apiGetAssets,
  apiGetProfile,
  apiListPositionKill,
  apiListKillByPosition,
  apiListCategoryCertificate,
  apiListCertificate,
  apiGetOptionEditProfile,
  apiGetCertificateDetail,
  apiGetStatusTeamDivison,
  apiGetCareerStage,
  apiGetProfileTimeKeeping
} from './../api/profileEmployee';
import { apiGetProject } from './../api/userProject';
import * as Constants from './../constants/ProfileEmployee.js';

export function useGetCareerStage(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_CAREER_STAGE, params], async () => {
    const response = await apiGetCareerStage(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  },
    {
      enabled: params.user_id ? true : false,
    },
  );

  return { ...response, error, isLoading };
}

export function useAssetsUser(params, userID) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_USER_ASSETS, params], async () => {
    const response = await apiGetAssets(params, userID);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useProfileUser(params, userID) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_USER_PROFILE, userID], async () => {
    const response = await apiGetProfile(params, userID);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useProfileUserTimeKeeping(params, userID) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_USER_PROFILE, userID], async () => {
    const response = await apiGetProfileTimeKeeping(params, userID);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useOptionEditProfile(params, userID) {
  const { data, error, isLoading } = useQuery(Constants.USE_OPTION_EDIT_PROFILE, async () => {
    const response = await apiGetOptionEditProfile(params, userID);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useGetProject(params, userID) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_PROJECT, params], async () => {
    const response = await apiGetProject(params, userID);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useListPositionKill(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(Constants.USE_PROFILE_LIST_POSITION_SKILL, async () => {
    const response = await apiListPositionKill(params);
    if (response && response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListKillByPosition(positionId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_PROFILE_LIST_SKILL, positionId], async () => {
    if (!positionId) {
      return null;
    }

    const response = await apiListKillByPosition(positionId, params);
    if (response && response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListCategoryCertificate(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(Constants.USE_PROFILE_CATEGORY_CERTIFICATE, async () => {
    const response = await apiListCategoryCertificate(params);
    if (response && response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListCertificate(categoryCertificateId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_PROFILE_LIST_CERTIFICATE, categoryCertificateId], async () => {
    const response = await apiListCertificate(categoryCertificateId, params);
    if (response && response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetCertificateDetail(userCertificateId, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_PROFILE_USER_CERTIFICATE_DETAIL, userCertificateId], async () => {
    if (!userCertificateId) {
      return null;
    }
    const response = await apiGetCertificateDetail(userCertificateId, params);
    if (response && response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetStatusTeamDivison(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_STATUS_TEAM_DIVISION, params], async () => {
    const response = await apiGetStatusTeamDivison(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}
