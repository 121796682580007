import 'react-confirm-alert/src/react-confirm-alert.css';
import Box from '@mui/material/Box';
import $ from 'jquery';
import React, { useState, useContext } from 'react';
import { Col, Table, Row } from 'reactstrap';
import { HiPencil } from 'react-icons/hi';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import './style.scss';
import AlertComponent from 'components/Alert';
import ButtonComponent from 'components/Buttons';
import InputField from 'components/Input/InputField';
import Loading from 'components/Loading';
import ModalComponent from 'components/Modal';
import SearchComponent from 'components/Search';
import Sidebar from './../Sidebar.js';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import { MAX_LENGTH_20, MAX_LENGTH_255 } from 'constants/index.js';
import { USE_WAREHOUSE_LIST } from 'constants/MasterData';
import { apiCreateWarehouse, apiDeleteWarehouse, apiUpdateWarehouse } from 'api/masterData.js';
import { useListWarehouse } from 'hook/useMasterData.js';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE, DISABLE_BUTTON_ROLE } from 'constants/index.js';

const FORM_DEFAULT = {
  id: 0,
  name: '',
  address: '',
  error_name: '',
  error_address: '',
};

const Warehouse = () => {
  let { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isShowModalAdd, setShowModalAdd] = useState(false);
  const [key_word, setKeyWord] = useState('');
  const dataInforUser = useContext(ThemContext);
  const [formAdd, setFormAdd] = useState(FORM_DEFAULT);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [textModal, setTextModal] = useState({
    title: t('masterData.moreData'),
    btn_submit: t('common.add'),
  });

  const { data: listWarehouse, isLoading: isLoadingListWarehouse } = useListWarehouse({ key_word });

  const handleSearch = (value) => {
    setKeyWord(value);
  };

  const addWarehouse = () => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setShowModalAdd(true);
    setFormAdd({ ...formAdd, ...FORM_DEFAULT });
    setTextModal({
      title: t('masterData.moreData'),
      btn_submit: t('common.add'),
    });
  };

  const handleInput = (e) => {
    let _error = {};
    if (e.target.value) {
      _error = { ['error_' + e.target.name]: '' };
    }
    setFormAdd({ ...formAdd, [e.target.name]: e.target.value, ..._error });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormAdd({ ...formAdd, error_name: '' });
    var focus = true;
    if (!formAdd.name) {
      setFormAdd({ ...formAdd, error_name: t('common.pleaseFillOutThisField') });
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
      return;
    }
    if (formAdd.name.length > MAX_LENGTH_20) {
      setFormAdd({ ...formAdd, error_name: t('common.pleaseEnterLessThanTwentyCharacters') });
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
      return;
    }

    if (formAdd.address && formAdd.address.length > MAX_LENGTH_255) {
      setFormAdd({ ...formAdd, error_address: t('common.pleaseEnterLessThanSixtyCharacters'), error_name: '' });
      if (focus) {
        $('input[name=address]').focus();
        focus = false;
      }
      return;
    }

    const data = {
      name: formAdd.name,
      address: formAdd.address,
    };

    let response = {};
    if (!formAdd.id) {
      response = await apiCreateWarehouse(data);
    } else {
      response = await apiUpdateWarehouse(formAdd.id, data);
    }

    if (response?.status === HTTP_UNPROCESSABLE_ENTITY) {
      if (response.data.messages?.name) {
        if (focus) {
          $('input[name=name]').focus();
          focus = false;
        }
        setFormAdd({ ...formAdd, error_name: response.data.messages.name[0] });
      }
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModalAdd(false);
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_WAREHOUSE_LIST);
    }

    setTextModal({
      ...textModal,
      title: t('masterData.moreData'),
      btn_submit: t('common.add'),
    });
  };

  const alertConfirm = (id) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    confirmAlert({
      title: t('common.doYouWantToDeletedData?'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteWarehouse(id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === 200 || response?.data?.status === 417) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_WAREHOUSE_LIST);
            }
          },
        },
      ],
    });
  };

  const handleEdit = (Warehouse) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setFormAdd({
      ...formAdd,
      id: Warehouse.id,
      name: Warehouse.name,
      address: Warehouse.address,
      error_name: '',
      error_address: '',
    });
    setTextModal({ ...textModal, title: t('masterData.updatingData'), btn_submit: t('common.update') });
    setShowModalAdd(!isShowModalAdd);
  };

  return (
    <div>
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>
        <Col lg={9} xl={10}>
          <Row>
            <Col className="md-12">
              <div className="box-group d-flex float-right">
                <SearchComponent
                  txtPlaceholder={t('masterData.findData')}
                  addClass="input-search"
                  handleSearch={handleSearch}
                  defaultValue={key_word}
                />
                <ButtonComponent
                  text={t('common.add')}
                  icon={<IoIosAdd />}
                  addClass={`${
                    dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                  } ml-3`}
                  handleClick={addWarehouse}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="md-12">
              <div className="table-list">
                <Table className="align-items-center table-hover" responsive>
                  <thead>
                    <tr>
                      <th scope="col">{t('masterData.warehouseCode')}</th>
                      <th scope="col">{t('masterData.warehouseName')}</th>
                      <th scope="col">{t('common.address')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listWarehouse &&
                      listWarehouse.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td className="text-bold">{item.name}</td>
                          <td>{item.address}</td>
                          <td>
                            <div className="col-action d-flex float-right">
                              <div
                                className={`${
                                  dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                } edit mr-2`}
                              >
                                <HiPencil onClick={() => handleEdit(item)}></HiPencil>
                              </div>
                              <div
                                className={`${
                                  dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                } remove mr-3`}
                              >
                                <MdDelete onClick={() => alertConfirm(item.id)}></MdDelete>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {isLoadingListWarehouse && (
                      <tr>
                        <td colSpan={4}>
                          <Loading addClass="mt-2" />
                        </td>
                      </tr>
                    )}
                    {!isLoadingListWarehouse && (!listWarehouse || (listWarehouse && !listWarehouse.length)) && (
                      <tr>
                        <td colSpan={4} className="error-no-data">
                          {t('common.notData')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalComponent
        toggle={() => setShowModalAdd(!isShowModalAdd)}
        isShowModal={isShowModalAdd}
        title={textModal.title}
      >
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className="mt-3">
            <InputField
              label={t('masterData.warehouseName')}
              isRequired={true}
              variant="outlined"
              name="name"
              defaultValue={formAdd.name}
              onHandleInput={handleInput}
              maxLength={MAX_LENGTH_20}
              error={formAdd.error_name}
            />
          </div>

          <div className="mt-4">
            <InputField
              label={t('common.address')}
              variant="outlined"
              name="address"
              defaultValue={formAdd.address}
              onHandleInput={handleInput}
              maxLength={MAX_LENGTH_255}
              error={formAdd.error_address}
            />
          </div>
          <Row className="mt-4">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalAdd(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="w-100"
                handleClick={() => setShowModalAdd(true)}
                type="submit"
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default Warehouse;
