import '../style.scss';
import { MdClose } from 'react-icons/md';
import { Form, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_YMD } from '..';
import { FIELD_KEY, CERTIFICATE, COURSE_OUTSIDE_COMPANY, COURSE_IN_COMPANY } from 'constants/Training';
import { useMutation } from 'react-query';
import { apiUpdateCourse } from 'api/training';
import { useEffect } from 'react';
import moment from 'moment';
import FormProgram from './FormProgram';

const UpdateProgram = ({ isOpen, setIsOpen, refetch, selectedCourse }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    let fields = {
      ...selectedCourse,
      [FIELD_KEY.START_DATE]: selectedCourse.start_date ? moment(selectedCourse.start_date):'',
      [FIELD_KEY.END_DATE]: selectedCourse.end_date ? moment(selectedCourse.end_date):'',
    };

    form.setFieldsValue(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isOpen, selectedCourse]);

  const updateCourseMutation = useMutation((data) => apiUpdateCourse(data.payload, data.id), {
    onSuccess: (res) => {
      message.success(res.data.messages);
      refetch();
      handleCancel();
    },
    onError: (error) => {
      message.error(error.response.data.messages);
    },
  });

  const handleFinish = (values) => {
    const type = values[FIELD_KEY.TYPE];

    let payload = {
      type,
      month_commit: values.month_commit,
      support_cost: values.support_cost,
      rate_commit: values.rate_commit,
      description: values.description?.trim(),
    };
    let additionalField = {}
    switch (type) {
      case CERTIFICATE:
        additionalField = {
          certificate_id: values.certificate_id,
          certificate_catalog: values.certificate_catalog,
        }
        break;
      case COURSE_IN_COMPANY:
        additionalField = {
          name: values.name?.trim(),
          start_date: values.start_date.format(DATE_FORMAT_YMD),
          end_date: values.end_date.format(DATE_FORMAT_YMD),
        }
        break;
      case  COURSE_OUTSIDE_COMPANY: 
        additionalField = {
          name: values.name?.trim(),
          start_date: values.start_date.format(DATE_FORMAT_YMD),
          end_date: values.end_date.format(DATE_FORMAT_YMD),
          location: values.location?.trim(),
        }
        break;
      default:
        additionalField = {} 
    }
    payload = {
      ...payload,
      ...additionalField
    };
    updateCourseMutation.mutate({ payload, id: selectedCourse.id });
  };

  const handleCancel = () => {
    setIsOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      wrapClassName={'custom-modal'}
      title={t('training.updateProgram')}
      closeIcon={<MdClose color="#fea628" size={24} />}
      cancelButtonProps={{ className: 'btn-cancel' }}
      cancelText={t('common.cancel')}
      okButtonProps={{ className: 'btn-ok', loading: updateCourseMutation.isLoading }}
      okText={t('common.save')}
      onCancel={handleCancel}
      onOk={() => {
        form.submit();
      }}
      visible={isOpen}
    >
      <FormProgram
        form={form}
        name={'update-program'}
        handleFinish={handleFinish}
        type={selectedCourse[FIELD_KEY.TYPE]}
      />
    </Modal>
  );
};

export default UpdateProgram;
