import '../style.scss';
import { MdClose } from 'react-icons/md';
import { Form, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { FIELD_KEY } from 'constants/Training';
import { useMutation } from 'react-query';
import { apiUpdateMemberCourseInCommit } from 'api/training';
import { useEffect } from 'react';
import moment from 'moment';
import FormMember from './FormMember';
import { DATE_FORMAT_YMD } from '..';
import { HTTP_ACCEPTED } from 'constants/StatusCode';

const UpdateMember = ({ isOpen, setIsOpen, refetch, setIsDisable, selectedMember }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    const fields = {
      ...selectedMember,
      [FIELD_KEY.MONTH_COMMIT]: selectedMember.course?.month_commit,
      [FIELD_KEY.RATE_COMMIT]: selectedMember.course?.rate_commit,
      [FIELD_KEY.START_DATE]: moment(selectedMember.start_date),
      [FIELD_KEY.END_DATE]: moment(selectedMember.end_date),
    };

    form.setFieldsValue(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isOpen, selectedMember]);

  const updateUserCourseInCommitMutation = useMutation((data) => apiUpdateMemberCourseInCommit(data.id, data.payload), {
    onSuccess: (res) => {
      const status = res?.status;

      if (status === HTTP_ACCEPTED) {
        message.success(res.data.messages);
      } else {
        message.error(res.data.messages);
      }

      refetch();
      handleCancel();
    },
    onError: (error) => {
      message.error(error.response.data.messages);
    },
  });

  const handleFinish = (values) => {
    let payload = {
      support_cost: values.support_cost,
      start_date: values.start_date.format(DATE_FORMAT_YMD),
      end_date: values.end_date.format(DATE_FORMAT_YMD),
    };

    updateUserCourseInCommitMutation.mutate({id: selectedMember.id, payload });
  };

  const handleCancel = () => {
    setIsDisable(false);
    setIsOpen(false);
    form.resetFields();
  };

  const handleCancelCommit = () => {
    setIsOpen(false);
  }

  return (
    <>
      <Modal
        wrapClassName={'custom-modal'}
        title={t('training.updateMemberCommitment')}
        closeIcon={<MdClose color="#fea628" size={24} />}
        cancelButtonProps={{ className: 'btn-cancel' }}
        cancelText={t('common.cancel')}
        okButtonProps={{ className: 'btn-ok', loading: updateUserCourseInCommitMutation.isLoading }}
        okText={t('common.save')}
        onCancel={handleCancel}
        onOk={() => {
          form.submit();
        }}
        visible={isOpen}
        style={{top: '110px'}}
        width={500}
      >
        <FormMember
          form={form}
          name={'update-program'}
          handleFinish={handleFinish}
          handleCancelCommit = {handleCancelCommit}
          selectedMember={selectedMember}
        />
      </Modal>
    </>
    
  );
};

export default UpdateMember;
