import { sendGet, sendPost, sendDelete, sendPut } from '../helpers/axios';

export const apiGetMoneyLate = (params) => sendGet('/api/money-late', params);

export const apiListSchedule = (params) => sendGet('/api/schedule', params);
export const apiUpdateSchedule = (params) => sendPut('/api/schedule', params);
export const apiDeleteSchedule = (workingTimeId, params) => sendDelete(`/api/schedule/${workingTimeId}`, params);
export const apiShowBlockTime = (params) => sendGet('/api/block-time', params);
export const apiUpdateBlockTime = (blockId, params) => sendPut(`/api/block-time/${blockId}`, params);

export const apiListWarehouse = (params) => sendGet('/api/hr-warehouse', params);
export const apiCreateWarehouse = (params) => sendPost('/api/hr-warehouse', params);
export const apiDeleteWarehouse = (idWarehouse) => sendDelete(`/api/hr-warehouse/${idWarehouse}`);
export const apiUpdateWarehouse = (idWarehouse, params) => sendPut(`/api/hr-warehouse/${idWarehouse}`, params);

export const apiCategoryListContract = (params) => sendGet('/api/contract-category', params);
export const apiCreateCategoryContract = (params) => sendPost('api/contract-category', params);
export const apiUpdateCategoryContract = (cateContactId, params) =>
  sendPut(`api/contract-category/${cateContactId}`, params);
export const apiDeleteCategoryContract = (cateContactId) => sendDelete(`api/contract-category/${cateContactId}`);

export const apiCategoryListPosition = (params) => sendGet('/api/position', params);
export const apiCreateCategoryPosition = (params) => sendPost('api/position', params);
export const apiUpdateCategoryPosition = (catePositionId, params) => sendPut(`api/position/${catePositionId}`, params);
export const apiDeleteCategoryPosition = (catePositionId) => sendDelete(`api/position/${catePositionId}`);

export const apiListCategoryEmployees = (params) => sendGet('/api/group', params);
export const apiCreateCategoryEmployees = (params) => sendPost('api/group', params);
export const apiUpdateCategoryEmployees = (cateEmployeesId, params) => sendPut(`api/group/${cateEmployeesId}`, params);
export const apiDeleteCategoryEmployees = (cateEmployeesId) => sendDelete(`api/group/${cateEmployeesId}`);

export const apiGetSkill = (params) => sendGet('/api/skill', params);
export const apiGetPosition = (params) => sendGet('/api/list-position', params);
export const apiCreateSkill = (params) => sendPost('/api/skill', params);
export const apiDeleteSkill = (skillID) => sendDelete(`/api/skill/${skillID}`);
export const apiUpdateSkill = (skillID, params) => sendPut(`/api/skill/${skillID}`, params);

export const apiGetAssetCategory = (params) => sendGet('/api/asset-category', params);
export const apiCreateAssetCategory = (params) => sendPost('/api/asset-category', params);
export const apiDeleteAssetCategory = (assetID) => sendDelete(`/api/asset-category/${assetID}`);
export const apiUpdateAssetCategory = (assetID, params) => sendPut(`/api/asset-category/${assetID}`, params);

export const apiGetCategoryCertificate = (params) => sendGet('/api/certificate-category', params);
export const apiCreateCategoryCertificate = (params) => sendPost('/api/certificate-category', params);
export const apiDeleteCategoryCertificate = (categoryCertificateID) =>
  sendDelete(`/api/certificate-category/${categoryCertificateID}`);
export const apiUpdateCategoryCertificate = (categoryCertificateID, params) =>
  sendPut(`/api/certificate-category/${categoryCertificateID}`, params);

export const apiGetCertificate = (params) => sendGet('/api/certificate', params);
export const apiCreateCertificate = (params) => sendPost('/api/certificate', params);
export const apiDeleteCertificate = (certificateID) => sendDelete(`/api/certificate/${certificateID}`);
export const apiUpdateCertificate = (certificateID, params) => sendPut(`/api/certificate/${certificateID}`, params);

export const apiGetLevel = (params) => sendGet('/api/level', params);
export const apiCreateLevel = (params) => sendPost('/api/level', params);
export const apiDeleteLevel = (levelID) => sendDelete(`/api/level/${levelID}`);
export const apiUpdateLevel = (levelID, params) => sendPut(`/api/level/${levelID}`, params);

export const apiGetHoliday = (params) => sendGet('/api/holiday', params);
export const apiCreateHoliday = (params) => sendPost('/api/holiday', params);
export const apiDeleteHoliday = (ID) => sendDelete(`/api/holiday/${ID}`);
export const apiUpdateHoliday = (ID, params) => sendPut(`/api/holiday/${ID}`, params);

export const apiListOverTime = (params) => sendGet('/api/over-time', params);
export const apiCreateOverTime = (params) => sendPost('/api/over-time', params);
export const apiUpdateOverTime = (IdOverTime, params) => sendPut(`/api/over-time/${IdOverTime}`, params);
export const linkDeleteOverTime = (IdOverTime) => `/api/over-time/${IdOverTime}`;

// Time special keeping
export const apiGetTimeSpecialKeeping = (params) => sendGet('/api/timesheet', params);
export const apiDeleteTimeSpecialKeeping = (ID) => `/api/timesheet/${ID}`;
export const apiGetUserPositionContract = (params) => sendGet('/api/user-position-contract', params);
export const apiAddTimeSpecialKeeping = (params) => {
  sendPost('/api/timesheet', params);
};

export const apiGetGroupAccount = (params) => sendGet('/api/community', params);
export const apiGetDetailGroupAccount = (ID) => sendGet(`/api/community/${ID}`);
export const apiDeleteGroupAccount = (ID) => `/api/community/${ID}`;
export const apiGetMemberCreateGroupAccountFilter = (params) => sendGet('/api/community-filter', params);
export const apiGetDivisionCreateGroupAccountFilter = (params) => sendGet('/api/division', params);
export const apiGetPositionCreateGroupAccountFilter = (params) => sendGet('/api/division-position', params);
export const apiAddCreateGroupAccountFilter = (params) => sendPost('/api/community', params);
export const apiUpdateCreateGroupAccountFilter = (Id, params) => sendPost(`/api/community/${Id}`, params);

export const apiGetAllDivision = (params) => sendGet('/api/division', params);
export const apiGetAllDivisionPosition = (params) => sendGet('/api/division-position', params);
export const apiGetCommunityAddMemberCustom = (params) => sendGet('/api/community/add-member-custom', params);

export const apiGetListMappingRequest = (params) => sendGet('/api/request/list', params);
export const apiUpdateMappingRequest = (idRequestMapping, params) =>
  sendPut(`/api/request/update/${idRequestMapping}`, params);
export const apiCreateMappingRequest = (params) => sendPost('/api/request/create', params);
export const apiDeleteMappingRequest = (idRequestMapping, params) =>
  sendDelete(`/api/request/delete/${idRequestMapping}`, params);
export const apiGetListWorkflow = (params) => sendGet('/api/v1/hr-list-type', params);
export const apiGetListCategory = (params) => sendGet('/api/v1/hr-list-category', params);

export const apiGetListRole = (params) => sendGet('/api/group-role/list', params);
export const apiCreateRole = (params) => sendPost('/api/group-role', params);
export const apiUpdateRole = (params) => sendPut('/api/group-role', params);
export const apiDeleteRole = (params) => sendDelete('/api/group-role', params);
export const apiGetListUserDetailRole = (params) => sendGet('/api/group-role/list-user', params);
export const apiAddUserDetailRole = (params) => sendPost('/api/group-role/user', params);
export const apiDeleteUserDetailRole = (params) => sendDelete('/api/group-role/user', params);
export const apiGetDetailRole = (params) => sendGet('/api/group-role/detail', params);
export const apiCheckUserRole = (params) => sendGet('/api/group-role/user/check-group', params);
export const apiGetBreakTime= (params) => sendGet('/api/overtimeoff/detail', params);
export const apiUpdateBreakTime= (params) => sendPut('/api/overtimeoff/update', params);
