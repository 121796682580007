import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Table, Row } from 'reactstrap';
import MenuItem from '@mui/material/MenuItem';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { IoIosAdd } from 'react-icons/io';
import { useQueryClient } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import Box from '@mui/material/Box';
import InputField from 'components/Input/InputField';
import 'react-confirm-alert/src/react-confirm-alert.css';
import $ from 'jquery';

import './style.scss';
import { isNotNullObject } from 'helpers/index';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_EXPECTATION_FAILED, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import { MAX_LENGTH_30 } from '../../../../../constants/ProfileEmployee.js';
import ButtonComponent from 'components/Buttons';
import ModalComponent from 'components/Modal';
import SearchComponent from 'components/Search';
import { apiCreateCertificate, apiDeleteCertificate, apiUpdateCertificate } from './../../../../../api/masterData.js';
import { useListCertificate, useListCategoryCertificate } from 'hook/useMasterData.js';
import AlertComponent from 'components/Alert';
import { USE_CERTIFICATE, MANAGER_CERTIFICATE } from 'constants/MasterData';
import Loading from 'components/Loading';
import SelectComponent from 'components/Select/Select.js';
import Sidebar from './../../Sidebar.js';
import Menu from './../index.js';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE, DISABLE_BUTTON_ROLE } from 'constants/index.js';

const ManagerCertificate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [isShowModalAdd, setShowModalAdd] = useState(false);
  const [filter, setFilter] = useState({ filter: '' });
  const [formAdd, setFormAdd] = useState({
    id: null,
    name: '',
    certificate_category: '',
    error_name: '',
    error_category_id: '',
  });
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [textModal, setTextModal] = useState({
    title: t('masterData.moreCertificate'),
    btn_submit: t('common.add'),
  });
  const [stateButton, setStateButton] = useState(false);

  const { data: listCertificate, isLoading: isLoadingListCertificate } = useListCertificate(filter);
  const { data: uListCategoryCertificate } = useListCategoryCertificate();

  const handleSearch = (value) => {
    setFilter({ ...filter, key_word: value });
  };

  const changeFilterCategory = (event) => {
    setFilter({ ...filter, filter: event.target.value > 0 ? event.target.value : '' });
  };

  const addCertificate = () => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setShowModalAdd(true);
    setFormAdd({
      ...formAdd,
      id: null,
      name: '',
      certificate_category: '',
      error_name: '',
      error_certificate_category: '',
    });
    setTextModal({
      title: t('masterData.moreCertificate'),
      btn_submit: t('common.add'),
    });
  };

  const handleInput = (e) => {
    const error = {};
    if (e.target.name === 'name') {
      error.error_name = '';
    }
    if (e.target.name === 'category_id') {
      error.error_certificate_category = '';
    }
    setFormAdd({ ...formAdd, ...error, [e.target.name]: e.target.value });
  };

  function validate() {
    var error = {};
    if (!formAdd.name) {
      error.error_name = t('common.pleaseFillOutThisField');
      $('input[name=name]').focus();
    }
    if (formAdd.name.length > MAX_LENGTH_30) {
      error.error_name = t('common.pleaseEnterLessThanThirtyCharacters');
      $('input[name=name]').focus();
    }
    if (!formAdd.certificate_category) {
      error.error_certificate_category = t('common.pleaseFillOutThisField');
    }

    if (isNotNullObject(error)) {
      setFormAdd({
        ...formAdd,
        ...error,
      });
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = async (event) => {
    setFormAdd({
      ...formAdd,
      error_name: '',
      error_certificate_category: '',
    });
    setStateButton(true);
    event.preventDefault();
    if (!validate()) {
      setStateButton(false);
      return;
    }

    setFormAdd({
      ...formAdd,
      error_name: '',
      error_certificate_category: '',
    });

    const data = {
      name: formAdd.name,
      certificate_category: formAdd.certificate_category,
    };

    let response = {};
    if (!formAdd.id) {
      response = await apiCreateCertificate(data);
    } else {
      response = await apiUpdateCertificate(formAdd.id, data);
    }

    if (response?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      if (msg?.name) {
        $('input[name=name]').focus();
      } else if (msg?.certificate_category) {
        $('input[name=certificate_category]').focus();
      }
      setFormAdd({
        ...formAdd,
        error_name: msg?.name
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_category_id: msg?.certificate_category
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModalAdd(false);
      setFormAdd({
        ...formAdd,
        id: null,
        name: '',
        certificate_category: '',
        error_name: '',
        error_certificate_category: '',
      });
      setMessage(response.data.messages);
      setShowAlert(true);
      setStateButton(false);
      queryClient.invalidateQueries(USE_CERTIFICATE);
    }

    setTextModal({
      ...textModal,
      title: t('masterData.moreCertificate'),
      btn_submit: t('common.add'),
    });
  };

  const alertConfirm = (id) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    confirmAlert({
      title: t('common.doYouWantToDeletedData?'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteCertificate(id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response?.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_CERTIFICATE);
            }

            if (response?.data.status === HTTP_EXPECTATION_FAILED) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_CERTIFICATE);
            }
          },
        },
      ],
    });
  };

  const handleEdit = (Certificate) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setFormAdd({
      ...formAdd,
      id: Certificate.id,
      name: Certificate.name,
      certificate_category: Certificate.certificate_category ? Certificate.certificate_category : 0,
      error_name: '',
      error_certificate_category: '',
    });
    setTextModal({ ...textModal, title: t('masterData.refactorCertificate'), btn_submit: t('common.update') });
    setShowModalAdd(!isShowModalAdd);
  };

  return (
    <Row className="master-certificate manager-certificate">
      <Col lg={3} xl={2}>
        <Sidebar />
      </Col>
      <Col lg={9} xl={10}>
        <Menu type={MANAGER_CERTIFICATE} />
        <Row>
          <Col className="md-12">
            <div className="box-group d-flex float-right">
              <SearchComponent
                txtPlaceholder={t('masterData.findData')}
                addClass="input-search"
                handleSearch={handleSearch}
                defaultValue={filter.key_word}
                maxLength={MAX_LENGTH_30}
              />
              <div className="dropBox ml-3">
                <SelectComponent
                  name="category_id"
                  addClass="select"
                  valueDefault={filter?.filter ? filter?.filter : 0}
                  onHandleChangeSelect={changeFilterCategory}
                  sizeSelect="small"
                >
                  <MenuItem value={0}>{t('masterData.categoryCertificateCategory')}</MenuItem>
                  {uListCategoryCertificate &&
                    uListCategoryCertificate.map((item) => {
                      return (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
              <ButtonComponent
                text={t('common.add')}
                icon={<IoIosAdd />}
                addClass={`${
                  dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                } ml-3`}
                handleClick={addCertificate}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="md-12">
            <div className="table-list">
              <Table className="align-items-center table-hover" responsive>
                <thead>
                  <tr>
                    <th scope="col">{t('masterData.categoryCertificateCode')}</th>
                    <th scope="col">{t('masterData.categoryCertificateName')}</th>
                    <th scope="col">{t('masterData.categoryCertificateCategory')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {listCertificate &&
                    listCertificate.map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td className="text-bold">{item.name}</td>
                        <td>{item.category}</td>
                        <td>
                          <div className="col-action d-flex float-right">
                            <div
                              className={`${
                                dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                              } edit mr-2`}
                            >
                              <HiPencil onClick={() => handleEdit(item)}></HiPencil>
                            </div>
                            <div
                              className={`${
                                dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                              } remove mr-3`}
                            >
                              <MdDelete onClick={() => alertConfirm(item.id)}></MdDelete>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  {isLoadingListCertificate && (
                    <tr>
                      <td colSpan={4}>
                        <Loading addClass="mt-2" />
                      </td>
                    </tr>
                  )}
                  {listCertificate && listCertificate.length < 1 && (
                    <tr>
                      <td colSpan={4} className="error-no-data">
                        {t('common.notData')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Col>
      <ModalComponent
        toggle={() => setShowModalAdd(!isShowModalAdd)}
        isShowModal={isShowModalAdd}
        title={textModal.title}
      >
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className="fieldset-group mt-4 select-category">
            <SelectComponent
              label={t('masterData.selectCategoryCertificateCategory')}
              name="certificate_category"
              valueDefault={formAdd.certificate_category}
              onHandleChangeSelect={handleInput}
              error={formAdd.error_certificate_category}
            >
              {uListCategoryCertificate &&
                uListCategoryCertificate.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </SelectComponent>
          </div>
          <div className="mt-4">
            <InputField
              label={t('masterData.certificateName')}
              variant="outlined"
              name="name"
              maxLength={MAX_LENGTH_30}
              defaultValue={formAdd.name}
              value={formAdd.name}
              onHandleInput={handleInput}
              isRequired={true}
              error={formAdd.error_name}
            />
          </div>
          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalAdd(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="w-100"
                handleClick={() => setShowModalAdd(true)}
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </Row>
  );
};

export default ManagerCertificate;
