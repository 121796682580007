import './style.scss';
import React, { useState, useEffect } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { Controller, useForm } from 'react-hook-form';
import { NavLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { useQueryClient } from 'react-query';
import { Input } from 'reactstrap'
import { IoMdUnlock, IoMdLock } from 'react-icons/io';
import { HiPaperAirplane } from "react-icons/hi";
import { confirmAlert } from 'react-confirm-alert';
import { ButtonBase, Grid, FormControlLabel, Radio, RadioGroup, InputBase } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import iconLoading from 'assets/img/icons/loading.png';
import menuTotal from 'assets/img/icons/menu-total.png';
import sad from 'assets/img/icons/sad.svg';
import clock from 'assets/img/icons/clock.png';
import monetaryFine from 'assets/img/icons/monetary-fine.svg';
import * as Constants from 'constants/index.js';
import { COLOR_CHECKBOX_NOT_ACTIVE, COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import {
  USE_TOTAL_TIME_SHEET,
  USE_GET_TIME_SHEET_LIST,
  DEFAULT_COMPLETE_TIMESHEETS,
  COMPLETED_TIMESHEETS,
  NOT_COMPLETE_TIMESHEETS,
  LEAVE_OF_ABSENCE,
  UNAUTHORIZED_ABSENCES,
  USE_CHECK_IS_LOCKED,
  IS_LOCKED,
  IS_OPENED,
} from 'constants/TimeSheet';
import { ZERO } from 'constants/MasterData';
import { MAX_LENGTH_CONTRACT, START_STRING_SLICE } from 'constants/Contract';
import { FILTER_BY_MODULE, handleExportExcel, getFilterModule, storeFilterModule, removeFilterModule } from 'helpers';
import { customDate } from 'helpers/formatDate.js';
import { formatNumberCurrency } from 'helpers/format';
import { safeParseJSON } from 'helpers/json';
import TimeKeeping from './../index.js';
import SendMailModal from './SendMailModal';
import DateDayMonthComponent from 'components/DateTime/DateMonth.js';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import ButtonComponent from 'components/Buttons';
import SearchComponent from 'components/Search';
import Loading from 'components/Loading';
import { apiLockTimesheet, apiUnlockTimesheet, apiGetCheckIsLocked } from 'api/timesheet';
import {
  useGetTimeSheetList,
  useGetTimeSheetTotal,
  useListAllDivision,
  useListCategoryEmployees,
  useCheckIsLocked,
} from 'hook/useTimeSheet';
import configs from 'config';
import styles from './style.module.scss';

const TimeSheets = () => {
  const [isShowModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { control } = useForm({
    mode: 'onBlur',
  });
  const history = useHistory()
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [usersChecked, setUsersChecked] = useState([]);

  const refetchCheckLock = async (payload) => {
    const res = await apiGetCheckIsLocked(payload);
    queryClient.setQueriesData(USE_CHECK_IS_LOCKED, res.data);
  };

  const alertConfirmLockTimesheet = (filter) => {
    confirmAlert({
      title: t('timekeeping.confirmLockTimesheet'),
      buttons: [
        {
          label: t('common.CANCEL'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            await apiLockTimesheet({ date: filter.date });
            refetchCheckLock(filter);
          },
        },
      ],
    });
  };

  const alertConfirmUnlockTimesheet = (filter) => {
    confirmAlert({
      title: t('timekeeping.confirmUnlockTimesheet'),
      buttons: [
        {
          label: t('common.comeBack'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            await apiUnlockTimesheet({ date: filter.date });
            refetchCheckLock(filter);
          },
        },
      ],
    });
  };
  const [filter, setFilter] = useState({
    key_word: null,
    date: moment().format('MM/YYYY'),
    status: '',
    type: '',
    complete: '',
    division_id: '',
    page: Constants.DEFAULT_PAGE_MUI,
    per_page: Constants.DEFAULT_LIMIT,
  });

  const [isModals, setIsModals] = useState({ division: false });
  const { data: uStatisticalCategoryEmployees, isLoading: isLoadingStatisticalCategoryEmployees } =
    useListCategoryEmployees();
  const { data: uTimeSheetList, isLoading: isLoadingTimeSheetList } = useGetTimeSheetList({
    ...filter,
    page: filter?.page + 1,
  });
  const { data: dataLock } = useCheckIsLocked({
    ...filter,
  });
  const { data: uTimeSheetTotal } = useGetTimeSheetTotal({
    ...filter,
    page: filter?.page + 1,
    count_user: 1,
  });
  const { data: uListDivisions } = useListAllDivision();
  const [dataFilterBackup, setDataFilterBackup] = useState({});
  const [listDivision, setListDivision] = useState([]);
  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchNameEmployee = (value) => {
    setFilter({ ...filter, key_word: value, page: Constants.DEFAULT_PAGE_MUI });
    setUsersChecked([])
    setCheckBoxAll(false)
  }

  useEffect(() => {
    if (uListDivisions) {
      const division = uListDivisions.map((item) => ({
        id: item.id,
        name: item.name,
        status: false,
      }));

      setListDivision([
        {
          id: undefined,
          name: t('contract.all'),
          status: false,
          value: Constants.ALL,
        },
        ...division,
      ]);
    }
  }, [uListDivisions, t]);

  useEffect(() => {
    const currentFilterValue = safeParseJSON(getFilterModule());
    if (currentFilterValue && currentFilterValue[FILTER_BY_MODULE.TIME_SHEETS]) {
      setFilter(currentFilterValue[FILTER_BY_MODULE.TIME_SHEETS]);
    }
    window.addEventListener('beforeunload', () => {
      removeFilterModule();
    });
    return () => {
      if (!history.location.pathname.includes('/timekeeping/individual/calendar')) {
        removeFilterModule();
      }
      window.removeEventListener('beforeunload', () => {
        removeFilterModule();
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const timeSheets = uTimeSheetList?.data ?? []
    for (const timeSheet of timeSheets) {
      if (usersChecked.indexOf(timeSheet.user_id) < Constants.NOT_DATA) {
        setCheckBoxAll(false)
        break
      }
      setCheckBoxAll(true)
    }
  }, [uTimeSheetList, usersChecked])

  const changeSelectDivision = (item) => {
    setListDivision(function (listStatus) {
      const index = listStatus.indexOf(item);
      return handleCheck(listStatus, index);
    });
  };

  function handleCheck(items, index) {
    let isCheckAll = true;
    if (index === 0) {
      isCheckAll = !items[0].status;
      items.forEach((item, ind) => (ind === 0 ? (item.status = isCheckAll) : (item.status = false)));
    } else {
      items.forEach(function (item, i) {
        if (((i !== index && item.status === false) || (i === index && item.status === true)) && i !== 0) {
          isCheckAll = false;
        }
      });
      if (isCheckAll) {
        items.forEach((item, ind) => (ind === 0 ? (item.status = true) : (item.status = false)));
      } else {
        items.forEach(function (item, i) {
          if (i === 0) {
            item.status = isCheckAll;
          } else if (index === i) {
            item.status = !item.status;
          }
        });
      }
    }

    return [...items];
  }

  const handleReset = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setFilter({
      key_word: null,
      date: moment().format('MM/YYYY'),
      status: '',
      type: '',
      complete: '',
      division_id: '',
      page: Constants.DEFAULT_PAGE_MUI,
      per_page: Constants.DEFAULT_LIMIT,
    });
    setUsersChecked([])
    setCheckBoxAll(false)
    const division = uListDivisions.map((item) => ({
      id: item.id,
      name: item.name,
      status: false,
    }));

    setListDivision([
      {
        id: undefined,
        name: t('contract.all'),
        status: false,
        value: Constants.ALL,
      },
      ...division,
    ]);
    await queryClient.invalidateQueries(USE_TOTAL_TIME_SHEET);
    await queryClient.invalidateQueries(USE_GET_TIME_SHEET_LIST);
    refetchCheckLock(filter);
  };

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [textModal, setTextModal] = useState({
    title: t('timekeeping.detailMailTimesheetTitle'),
    btn_submit: t('common.save'),
  });

  const openModalSendMail = () => {
    setShowModal(true);
    setTextModal({
      title: t('timekeeping.detailMailTimesheetTitle'),
      btn_submit: t('common.save'),
    });
  };

  const getDivisionsSelected = (items, divisionIds) => {
    if (items?.length && !items[0].status) {
      const divisions = items.reduce((firstValue, currentValue, index) => {
        if ((currentValue.status && currentValue.value !== Constants.ALL) || divisionIds.includes(currentValue.id)) {
          firstValue.push(' ' + currentValue.name);
        } else if (!divisionIds.length && index === ZERO) {
          firstValue.push(t('employees.all'));
        }
        return firstValue;
      }, []);
      return divisions;
    }
    return t('employees.all');
  };

  const onDisplayValueChecked = (items, divisionIds) => {
    if (items?.length && !items[0].status) {
      const divisions = items.reduce((firstValue, currentValue, index) => {
        if (divisionIds.includes(currentValue.id)) {
          items[index].status = true;
        } else if (!divisionIds.length && index === ZERO) {
          items[ZERO].status = true;
        }
        return firstValue;
      }, []);

      return divisions;
    }
  };

  const onChangeCheckValue = ({ event, type, userId }) => {
    const { checked } = event.target
    const timeSheets = uTimeSheetList?.data ?? []
    if (type === Constants.ALL) {
      setCheckBoxAll(checked)
      const userIds = [...usersChecked]
      if (checked) {
        timeSheets.forEach((timeSheet) => {
          if (!userIds.includes(timeSheet.user_id)) {
            userIds.push(timeSheet.user_id)
          }
        })
        setUsersChecked(userIds)
      } else {
        timeSheets.forEach((timeSheet) => {
          const index = userIds.indexOf(timeSheet.user_id)
          if (index !== -1) {
            userIds.splice(index, 1)
          }
          setUsersChecked(userIds)
          setCheckBoxAll(false)
        })
      }
    } else {
      const userIds = [...usersChecked, userId]
      setUsersChecked(userIds)
      if (!checked) {
        const userUnCheck = usersChecked.filter(id => id !== userId)
        setUsersChecked(userUnCheck)
        setCheckBoxAll(false)
      } else {
        let isCheckedAll = true
        for (const timeSheet of timeSheets) {
          if (userIds.indexOf(timeSheet.user_id) < 0) {
            isCheckedAll = false
            break
          }
        }
        setCheckBoxAll(isCheckedAll)
      }
    }
  }

  return (
    <Grid container className="list-time-sheets">
      <Grid container className="nav-fixed pt-0">
        <Grid className="menu-slider-around">
          <TimeKeeping />
        </Grid>
        <Grid item xs sm md lg xl className="button mt-1">
          <ButtonComponent
            text={t('timekeeping.emailSelectedItem')}
            hasDisabled={!usersChecked.length}
            addClass={styles.sendMailSelectedItems}
            icon={<HiPaperAirplane />}
            handleClick={openModalSendMail}
          />
          <ButtonComponent
            handleClick={() => {
              openModalSendMail()
              setUsersChecked([])
            }}
            addClass="send-mail"
            icon={<HiPaperAirplane />}
            text={t('timekeeping.sendMail')}
          />
          {Number(dataLock?.code) === IS_LOCKED && (
            <ButtonComponent
              handleClick={() => {
                alertConfirmUnlockTimesheet(filter);
              }}
              addClass="unlock-table-timesheet"
              icon={<IoMdUnlock />}
              text={t('timekeeping.unlockTimesheet')}
            />
          )}
          {Number(dataLock?.code) === IS_OPENED && (
            <ButtonComponent
              handleClick={() => {
                alertConfirmLockTimesheet(filter);
              }}
              addClass="lock-table-timesheet"
              icon={<IoMdLock />}
              text={t('timekeeping.lockTableTimesheet')}
            />
          )}
          <ButtonComponent
            handleClick={() => handleExportExcel('/api/time-sheet/export', filter, 'brief_rent_price_list.xlsx')}
            addClass="export"
            icon={<BiLinkExternal />}
            text={t('common.export')}
          />
          <a href={`${configs.DOMAIN_WORK_FOLLOW}/request`} target="blank">
            <ButtonComponent
              addClass="create-request"
              icon={<AddIcon />}
              text={t('assets.assetsListRequests.createRequest')}
            />
          </a>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={9} className="asset-main">
          <div className="profile-box table-list table-border-show">
            <Paper>
              <TableContainer className={`${uTimeSheetList?.total > 0 && 'scroll-custom'}`}>
                <Table aria-label="sticky table" className={`${uTimeSheetList?.total > 0 && 'table-scroll'}`}>
                  <TableHead className="asset-header">
                    <TableRow className="asset-header-row">
                      <TableCell className={styles.checkboxAll}>
                        <Input
                          type="checkbox"
                          className={styles.checked}
                          checked={checkBoxAll}
                          onChange={(event) => onChangeCheckValue({ event, type: Constants.ALL })}
                        />
                      </TableCell>
                      <TableCell className="w100">{t('contract.contractRetire.code')}</TableCell>
                      <TableCell className="long">{t('contract.contractRetire.name')}</TableCell>
                      <TableCell className="long">{t('employees.email')}</TableCell>
                      <TableCell>{t('contract.contractRetire.division')}</TableCell>
                      <TableCell className='w150'>{t('timekeeping.contractType')}</TableCell>
                      {uStatisticalCategoryEmployees &&
                        uStatisticalCategoryEmployees?.map((item, index) => {
                          return (
                            <TableCell key={index}>
                              {item?.name?.length > MAX_LENGTH_CONTRACT ? (
                                <Tooltip title={item?.name} arrow>
                                  <span>{item?.name.slice(START_STRING_SLICE, MAX_LENGTH_CONTRACT) + '...'}</span>
                                </Tooltip>
                              ) : (
                                item?.name
                              )}
                            </TableCell>
                          );
                        })}
                      <TableCell>{t('timekeeping.workTime')}</TableCell>
                      <TableCell>{t('timekeeping.daysLate')}</TableCell>
                      <TableCell>{t('timekeeping.minuteLate')}</TableCell>
                      <TableCell>{t('timekeeping.fineAmount')}</TableCell>
                      <TableCell>{t('timekeeping.leaveTime')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uTimeSheetList &&
                      !isLoadingStatisticalCategoryEmployees &&
                      uTimeSheetList?.data?.map((item, index) => {
                        let workTime = 0;
                        let lastTime = 0;
                        let workTimeContract = 0;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            onClick={() => {
                              storeFilterModule(filter, FILTER_BY_MODULE.TIME_SHEETS)
                              item.handelClick.click()
                            }}
                          >
                            <TableCell onClick={(event) => event.stopPropagation()}>
                              <Input
                                type="checkbox"
                                className={styles.checked}
                                checked={!!usersChecked.includes(item.user_id)}
                                onChange={(event) => onChangeCheckValue({ event, userId: item.user_id })}
                              />
                            </TableCell>
                            <TableCell>
                              <NavLink
                                to={{ pathname: '/admin/timekeeping/individual/calendar/' + item?.user_id }}
                                className={styles.linkRequest}
                                ref={(input) => item.handelClick = input}
                              >
                                {item?.code}
                              </NavLink>
                            </TableCell>
                            <TableCell>{item?.user_name}</TableCell>
                            <TableCell><p>{item?.email}</p></TableCell>
                            <TableCell>{item?.division_name}</TableCell>
                            <TableCell>{item?.contract_category}</TableCell>
                            {uStatisticalCategoryEmployees?.map((it, index) => {
                                workTimeContract = 0;
                                return (
                                  <TableCell key={index}>
                                    {item?.group?.forEach((group) => {
                                      if (Number(it?.id) === Number(group?.group_id)) {
                                        workTime += Number(group?.work_time);
                                        lastTime += Number(group?.late_time);
                                      }
                                      if (Number(it?.id) === Number(group?.group_id)) {
                                        workTimeContract += Number(group?.work_time);
                                      }
                                    })}
                                    {workTimeContract}
                                  </TableCell>
                                );
                              }) ?? null}
                            <TableCell className={item.is_completed > 0 && 'lack-of-time'}>{workTime}</TableCell>
                            <TableCell>{item.days_late}</TableCell>
                            <TableCell>{lastTime}</TableCell>
                            <TableCell>{item?.fines}</TableCell>
                            <TableCell>{item?.day_offs}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {isLoadingTimeSheetList && (
                <div className="center">
                  <Loading addClass="mt-3 mb-3" />
                </div>
              )}
              {!uTimeSheetList?.total && !isLoadingTimeSheetList && (
                <p className="error-no-data">{t('common.notData')}</p>
              )}
              {uTimeSheetList?.total > 0 && (
                <TablePagination
                  rowsPerPageOptions={Constants.PER_PAGE_OPTION}
                  component="div"
                  count={uTimeSheetList?.total}
                  rowsPerPage={filter?.per_page}
                  page={filter?.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="table-pagination"
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                />
              )}
            </Paper>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="box-filter">
            <div className={classNames(`text marginElementBottom`)}>
              <DateDayMonthComponent
                label=""
                name="date"
                valueDefault={typeof filter.date === 'string' ? moment(filter.date, 'MM/YYYY') : filter.date}
                onChangeHandle={async (value) => {
                  const payload = {
                    ...filter,
                    date: customDate(value, 'MM/YYYY'),
                    page: Constants.DEFAULT_PAGE_MUI,
                  };
                  setUsersChecked([])
                  setCheckBoxAll(false)
                  setFilter(payload);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  refetchCheckLock(payload);
                }}
                placeholder={t('contract.contractRetire.monthOut')}
                sizeDate="small"
              />
            </div>
            <div className="selectField selected">
              <img alt={menuTotal} src={menuTotal} className="imgFilter" />
              <div className="selectFieldTitle">{t('timekeeping.standardWorkNumber')}</div>
              <div className="selectFieldTotal">
                {uTimeSheetTotal?.standard_work_number && uTimeSheetTotal.standard_work_number}
              </div>
            </div>
            <div className="selectField notSelected">
              <img alt={sad} src={sad} className="imgFilter" />
              <div className="selectFieldTitle">{t('timekeeping.numberOfLatecomers')}</div>
              <div className="selectFieldTotal">
                {uTimeSheetTotal?.person_late_time && uTimeSheetTotal.person_late_time}
              </div>
            </div>
            <div className="selectField notSelected">
              <img alt={clock} src={clock} className="imgFilter" />
              <div className="selectFieldTitle">{t('timekeeping.minutesLate')}</div>
              <div className="selectFieldTotal">{uTimeSheetTotal?.late_time && uTimeSheetTotal.late_time}</div>
            </div>
            <div className="selectField marginElementBottom notSelected">
              <img alt={monetaryFine} src={monetaryFine} className="imgFilter" />
              <div className="selectFieldTitle">{t('timekeeping.fineAmount')}</div>
              <div className="selectFieldTotal">
                {uTimeSheetTotal?.fines && formatNumberCurrency(uTimeSheetTotal?.fines) + t('common.currency')}
              </div>
            </div>
            <div className={classNames(`text padding-element-filter`)}>
              <SearchComponent
                txtPlaceholder={t('employees.searchName')}
                addClass="input-search-box"
                handleSearch={handleSearchNameEmployee}
                defaultValue={filter?.key_word}
                value={filter?.key_word || ''}
              />
            </div>
            <div className={classNames(`text padding-element-filter none-bottom`)}>
              <InputBase
                disabled
                type="text"
                name="division"
                className="exampleSelectMulti"
                onClick={() => {
                  setIsModals(() => {
                    onDisplayValueChecked(listDivision, filter.division_id)
                    return { division: true };
                  });
                  setDataFilterBackup(JSON.parse(JSON.stringify(listDivision)));
                }}
                value={getDivisionsSelected(listDivision, filter.division_id)}
                placeholder={t('contract.contractRetire.division')}
              />
              <ModalCommon
                items={listDivision}
                onSubmit={() => {
                  let datas = [];
                  listDivision.forEach((it) => {
                    it.status && it.id && datas.push(it.id);
                  });
                  setFilter({
                    ...filter,
                    division_id: datas,
                    page: Constants.DEFAULT_PAGE_MUI,
                  });
                  setUsersChecked([])
                  setCheckBoxAll(false)
                  setIsModals((prev) => {
                    return { ...prev, division: false };
                  });
                }}
                onClick={changeSelectDivision}
                toggle={() => {
                  setIsModals((prev) => {
                    onDisplayValueChecked(listDivision, filter.division_id)
                    return { ...prev, division: !prev.division };
                  });
                  setListDivision([...dataFilterBackup]);
                }}
                modal={isModals.division}
                title={t('contract.contractRetire.division')}
                footer={t('common.search')}
              />
            </div>
            <div className={classNames(`padding-element-top`)}>
              <FormControl component="fieldset">
                <FormLabel className={classNames(`text padding-element-filter border-top padding-element-top`)}>
                  {t('timekeeping.labor')}
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field} value={filter?.complete ? filter?.complete : DEFAULT_COMPLETE_TIMESHEETS}>
                      <FormControlLabel
                        value={DEFAULT_COMPLETE_TIMESHEETS}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            complete: null,
                            page: Constants.DEFAULT_PAGE_MUI,
                          });
                          setUsersChecked([])
                          setCheckBoxAll(false)
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                        control={
                          <Radio
                            sx={{
                              color: COLOR_CHECKBOX_NOT_ACTIVE,
                              '&.Mui-checked': {
                                color: COLOR_CHECKBOX_CUSTOM,
                              },
                            }}
                          />
                        }
                        label={t('employees.all')}
                      />
                      <div className="division">
                        <FormControlLabel
                          value={COMPLETED_TIMESHEETS}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              complete: e.target.value,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            setUsersChecked([])
                            setCheckBoxAll(false)
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={
                            <Radio
                              sx={{
                                color: COLOR_CHECKBOX_NOT_ACTIVE,
                                '&.Mui-checked': {
                                  color: COLOR_CHECKBOX_CUSTOM,
                                },
                              }}
                            />
                          }
                          label={t('timekeeping.enoughWork')}
                        />
                        <span className="total">
                          {uTimeSheetTotal?.work_time?.complete ? uTimeSheetTotal?.work_time?.complete : 0}
                        </span>
                      </div>
                      <div className="division">
                        <FormControlLabel
                          value={NOT_COMPLETE_TIMESHEETS}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              complete: e.target.value,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            setUsersChecked([])
                            setCheckBoxAll(false)
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={
                            <Radio
                              sx={{
                                color: COLOR_CHECKBOX_NOT_ACTIVE,
                                '&.Mui-checked': {
                                  color: COLOR_CHECKBOX_CUSTOM,
                                },
                              }}
                            />
                          }
                          label={t('timekeeping.lackOfWork')}
                        />
                        <span className="total">
                          {uTimeSheetTotal?.work_time?.not_complete ? uTimeSheetTotal?.work_time?.not_complete : 0}
                        </span>
                      </div>
                    </RadioGroup>
                  )}
                  name="complete"
                  control={control}
                  defaultValue={filter?.complete ? filter?.complete : 0}
                />
              </FormControl>
            </div>
            <div className={classNames(`padding-element-top`)}>
              <FormControl component="fieldset">
                <FormLabel className={classNames(`text padding-element-filter border-top padding-element-top`)}>
                  {t('infoEmployee.groupPersonal')}
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field} value={filter?.group_id ? filter?.group_id : 0}>
                      <FormControlLabel
                        value={0}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            group_id: null,
                            page: Constants.DEFAULT_PAGE_MUI,
                          });
                          setUsersChecked([])
                          setCheckBoxAll(false)
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                        control={
                          <Radio
                            sx={{
                              color: COLOR_CHECKBOX_NOT_ACTIVE,
                              '&.Mui-checked': {
                                color: COLOR_CHECKBOX_CUSTOM,
                              },
                            }}
                          />
                        }
                        label={t('employees.all')}
                      />
                      {uTimeSheetTotal?.group?.map((item, index) => (
                        <div key={index} className="division">
                          <FormControlLabel
                            key={index}
                            value={item?.id}
                            onChange={(e) => {
                              setFilter({
                                ...filter,
                                group_id: e.target.value,
                                page: Constants.DEFAULT_PAGE_MUI,
                              });
                              setUsersChecked([])
                              setCheckBoxAll(false)
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            control={
                              <Radio
                                sx={{
                                  color: COLOR_CHECKBOX_NOT_ACTIVE,
                                  '&.Mui-checked': {
                                    color: COLOR_CHECKBOX_CUSTOM,
                                  },
                                }}
                              />
                            }
                            label={item?.name}
                          />
                          <span className="total">{item.number ? item?.number : 0}</span>
                        </div>
                      ))}
                    </RadioGroup>
                  )}
                  name="type"
                  control={control}
                  defaultValue={filter?.group_id ? filter?.group_id : 0}
                />
              </FormControl>
            </div>
            <div className={classNames(`padding-element-top`)}>
              <FormControl component="fieldset">
                <FormLabel className={classNames(`text padding-element-filter border-top padding-element-top`)}>
                  {t('timekeeping.onLeave')}
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field} value={filter?.type ? filter?.type : 0}>
                      <FormControlLabel
                        value={ZERO}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            type: null,
                            page: Constants.DEFAULT_PAGE_MUI,
                          });
                          setUsersChecked([])
                          setCheckBoxAll(false)
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                        control={
                          <Radio
                            sx={{
                              color: COLOR_CHECKBOX_NOT_ACTIVE,
                              '&.Mui-checked': {
                                color: COLOR_CHECKBOX_CUSTOM,
                              },
                            }}
                          />
                        }
                        label={t('employees.all')}
                      />
                      <div className="division">
                        <FormControlLabel
                          value={LEAVE_OF_ABSENCE}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              type: e.target.value,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            setUsersChecked([])
                            setCheckBoxAll(false)
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={
                            <Radio
                              sx={{
                                color: COLOR_CHECKBOX_NOT_ACTIVE,
                                '&.Mui-checked': {
                                  color: COLOR_CHECKBOX_CUSTOM,
                                },
                              }}
                            />
                          }
                          label={t('timekeeping.unpaidLeave')}
                        />
                        <span className="total">
                          {uTimeSheetTotal?.type?.unpaid_leave ? uTimeSheetTotal?.type?.unpaid_leave : 0}
                        </span>
                      </div>
                      <div className="division">
                        <FormControlLabel
                          value={UNAUTHORIZED_ABSENCES}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              type: e.target.value,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            setUsersChecked([])
                            setCheckBoxAll(false)
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={
                            <Radio
                              sx={{
                                color: COLOR_CHECKBOX_NOT_ACTIVE,
                                '&.Mui-checked': {
                                  color: COLOR_CHECKBOX_CUSTOM,
                                },
                              }}
                            />
                          }
                          label={t('timekeeping.allows')}
                        />
                        <span className="total">
                          {uTimeSheetTotal?.type?.paid_leave ? uTimeSheetTotal?.type?.paid_leave : 0}
                        </span>
                      </div>
                    </RadioGroup>
                  )}
                  name="type"
                  control={control}
                  defaultValue={filter?.type ? filter?.type : 0}
                />
              </FormControl>
            </div>
            <div className="border-top">
              <ButtonBase onClick={() => handleReset()} className={classNames(`reset border-top`)} size="lg">
                <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                {t('common.reset')}
              </ButtonBase>
            </div>
          </div>
        </Grid>
      </Grid>
      {isShowModal && (
        <SendMailModal
          userIds={usersChecked}
          isShowModal={isShowModal}
          setShowModal={setShowModal}
          textModal={textModal}
          date={filter.date}
        />
      )}
    </Grid>
  );
};

export default TimeSheets;
