import { sendGet } from 'helpers/axios';

export const apiGetRequestTotal = (params) => sendGet('/api/time-sheet/request/total', params);

export const apiGetRequestTable = (params) => sendGet('/api/time-sheet/request', params);

export const apiGetTypeRequest = (ID, params) => sendGet(`/api/request/${ID}`, params);
export const apiGetCategoryId = (idMappingRequest) => sendGet('api/request/' + idMappingRequest);
export const apiListTypeOfCategoryHr = (params) => sendGet('api/time-sheet/request/type-name', params);

export const apiListOT = (params) => sendGet('api/time-sheet/list-OT', params);
export const apiListOTDemo = (params) => sendGet('api/time-sheet/demo/list-OT', params);
export const apiGetDivision = (params) => sendGet('/api/time-sheet/list-division', params);
