import 'react-confirm-alert/src/react-confirm-alert.css';
import React, { useState, useContext } from 'react';
import { Col, Table, Row, Form } from 'reactstrap';
import { HiPencil } from 'react-icons/hi';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import './style.scss';
import AlertComponent from './../../../../components/Alert';
import ButtonComponent from './../../../../components/Buttons';
import InputField from 'components/Input/InputField';
import Loading from 'components/Loading';
import ModalComponent from './../../../../components/Modal';
import SearchComponent from './../../../../components/Search';
import Sidebar from './../Sidebar.js';
import {
  HTTP_EXPECTATION_FAILED,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_OK,
  HTTP_NOT_ACCESS,
  HTTP_UNPROCESSABLE_ENTITY,
} from './../../../../constants/StatusCode.js';
import { MAX_LENGTH_20 } from 'constants/index.js';
import { USE_CATEGORY_POSITION } from 'constants/MasterData';
import { useListCategoryPosition } from './../../../../hook/useMasterData.js';
import {
  apiCreateCategoryPosition,
  apiDeleteCategoryPosition,
  apiUpdateCategoryPosition,
} from './../../../../api/masterData.js';
import $ from 'jquery';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE, DISABLE_BUTTON_ROLE } from 'constants/index.js';

const DEFAULT_FORM_DATA = {
  id: null,
  name: '',
  error_name: '',
};

const CategoryPosition = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [isShowModalAdd, setShowModalAdd] = useState(false);
  const [keyword, setKeyWord] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [formAdd, setFormAdd] = useState(DEFAULT_FORM_DATA);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [textModal, setTextModal] = useState({
    title: t('masterData.addPosition'),
    btn_submit: t('common.add'),
  });
  const { data: listCategoryPosition, isLoading: isLoadingPosition } = useListCategoryPosition({ keyword });

  const handleSearch = (value) => {
    setKeyWord(value);
  };

  const addPosition = () => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setShowModalAdd(true);
    setFormAdd({ ...formAdd, id: null, name: '', error_name: '' });
    setTextModal({
      title: t('masterData.addPosition'),
      btn_submit: t('common.add'),
    });
  };

  const handleInput = (e) => {
    let _error = {};
    if (e.target.value) {
      _error = { ['error_' + e.target.name]: '' };
    }

    setFormAdd({ ...formAdd, [e.target.name]: e.target.value, ..._error });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormAdd({ ...formAdd, error_name: '' });
    var focus = true;
    if (!formAdd.name) {
      setFormAdd({ ...formAdd, error_name: t('common.pleaseFillOutThisField') });
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
      return;
    }

    if (formAdd.name.length > MAX_LENGTH_20) {
      setFormAdd({ ...formAdd, error_name: t('common.pleaseEnterLessThanTwentyCharacters') });
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
      return;
    }
    setDisableButton(true);
    const data = {
      name: formAdd.name,
    };

    let response = {};
    if (!formAdd.id) {
      response = await apiCreateCategoryPosition(data);
    } else {
      response = await apiUpdateCategoryPosition(formAdd.id, data);
    }
    if (response?.status === HTTP_UNPROCESSABLE_ENTITY) {
      setDisableButton(false);
      if (response.data.messages?.name) {
        setFormAdd({ ...formAdd, error_name: response.data.messages.name[0] });
        if (focus) {
          $('input[name=name]').focus();
          focus = false;
        }
      }
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setDisableButton(false);
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
    }

    if (
      response?.data.status === HTTP_INTERNAL_SERVER_ERROR ||
      response?.data.status === HTTP_EXPECTATION_FAILED
    ) {
      setShowModalAdd(false);
      setMessage(response?.data?.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_CATEGORY_POSITION);
    }
    if (response?.data?.status === HTTP_OK) {
      setDisableButton(false);
      setShowModalAdd(false);
      setFormAdd({ ...formAdd, id: null, name: '', error_name: '' });
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_CATEGORY_POSITION);

      setTextModal({
        ...textModal,
        title: t('masterData.addPosition'),
        btn_submit: t('common.add'),
      });
    }
    setDisableButton(false);
  };

  const alertConfirm = (id) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    confirmAlert({
      title: t('common.doYouWantToDeletedData?'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteCategoryPosition(id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_CATEGORY_POSITION);
            }

            if (
              response?.data.status === HTTP_INTERNAL_SERVER_ERROR ||
              response?.data.status === HTTP_EXPECTATION_FAILED
            ) {
              setMessage(response?.data?.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_CATEGORY_POSITION);
            }
          },
        },
      ],
    });
  };

  const handleEdit = (CategoryPosition) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setFormAdd({
      ...formAdd,
      id: CategoryPosition.id,
      name: CategoryPosition.name,
      error_name: '',
    });
    setTextModal({ ...textModal, title: t('masterData.updatePosition'), btn_submit: t('common.update') });
    setShowModalAdd(!isShowModalAdd);
  };

  return (
    <div className="master-data-category-position">
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>
        <Col lg={9} xl={10}>
          <Row>
            <Col className="md-12">
              <div className="box-group d-flex float-right">
                <SearchComponent
                  txtPlaceholder={t('masterData.findData')}
                  addClass="input-search"
                  handleSearch={handleSearch}
                  defaultValue={keyword}
                />
                <ButtonComponent
                  text={t('common.add')}
                  icon={<IoIosAdd />}
                  addClass={`${
                    dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                  } ml-3`}
                  handleClick={addPosition}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="md-12">
              <div className="table-list">
                <Table className="align-items-center table-hover" responsive>
                  <thead>
                    <tr>
                      <th scope="col">{t('masterData.positionCode')}</th>
                      <th scope="col">{t('masterData.positionName')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listCategoryPosition &&
                      listCategoryPosition.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td className="text-bold">{item.name}</td>
                          <td>
                            <div className="col-action d-flex float-right">
                              <div
                                className={`${
                                  dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                } edit mr-2`}
                              >
                                <HiPencil onClick={() => handleEdit(item)}></HiPencil>
                              </div>
                              <div
                                className={`${
                                  dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                } remove mr-3`}
                              >
                                <MdDelete onClick={() => alertConfirm(item.id)}></MdDelete>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {isLoadingPosition && (
                      <tr>
                        <td colSpan={3}>
                          <Loading addClass="mt-2" />
                        </td>
                      </tr>
                    )}
                    {!isLoadingPosition &&
                      (!listCategoryPosition || (listCategoryPosition && !listCategoryPosition.length)) && (
                        <tr>
                          <td colSpan={4} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalComponent
        toggle={() => setShowModalAdd(!isShowModalAdd)}
        isShowModal={isShowModalAdd}
        title={textModal.title}
      >
        <Form className="mt-3 box-show-none" onSubmit={handleSubmit}>
          <InputField
            label={t('masterData.positionName')}
            isRequired={true}
            variant="outlined"
            name="name"
            defaultValue={formAdd.name}
            onHandleInput={handleInput}
            maxLength={MAX_LENGTH_20}
            error={formAdd.error_name}
          />

          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalAdd(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="w-100"
                hasDisabled={disableButton}
                isLoading={disableButton}
                handleClick={() => setShowModalAdd(true)}
                type="submit"
              />
            </Col>
          </Row>
        </Form>
      </ModalComponent>

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default CategoryPosition;
