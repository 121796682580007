import React, { useEffect, useState } from 'react';

import { TimePicker, Input } from 'antd';

import styles from './styles.module.scss';
import moment from 'moment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const styleLabel = {
  position: 'absolute',
  top: '-21px',
  left: '-5px',
  backgroundColor: '#fff',
  fontSize: '11px',
};

const TimeComponentCustom = (props) => {
  const { label, error, name, placeholder, value, format, handleChangeTime, setValue, disable, setError } = props;

  const [open, setOpen] = useState(false);
  const [valueInput, setValueInput] = useState('');

  useEffect(() => {
    if (value) {
      setValue && setValue(name, value);
    }
    // eslint-disable-next-line
  }, [value]);

  const handleSelectTime = (time) => {
    if (moment(time, 'HH:mm').isValid()) {
      setValueInput(moment(time).format('HH:mm'));
    }
    handleChangeTime(time);
    setError && setError(name, '');
  };

  const handleInputTimePicker = (e) => {
    setValueInput(e.target.value);
    if (moment(e.target.value, 'HH:mm').isValid()) {
      handleChangeTime(moment(e.target.value, 'HH:mm'));
      setValue && setValue(name, moment(e.target.value, 'HH:mm'));
    } else {
      setValue && setValue(name, null);
    }
  };

  const handleOpenTimePicker = () => {
    setOpen(true);
  };

  const handleCloseTimePicker = () => {
    setOpen(false);
  };

  return (
    <>
      <TimePicker
        {...props}
        id={name}
        open={open}
        name={name}
        className={!error ? styles.selectTime : styles.selectTimeError}
        placeholder={placeholder}
        format={format}
        value={value || null}
        onSelect={handleSelectTime}
        disabled={disable}
        suffixIcon={
          <div
            onClick={() => {
              handleOpenTimePicker();
            }}
          >
            <AccessTimeIcon />
          </div>
        }
        clearIcon={
          <div
            onClick={() => {
              handleChangeTime(null);
              setValue && setValue(name, null);
              setValueInput('');
            }}
          >
            <HighlightOffIcon />
          </div>
        }
        showNow={false}
        inputRender={(props) => {
          return (
            <>
              {label && !disable && <p style={error ? { ...styleLabel, color: 'red' } : styleLabel}>{placeholder}</p>}
              <Input
                id={props.name}
                className={styles.inputTime}
                placeholder={props.placeholder}
                onFocus={handleOpenTimePicker}
                onBlur={() => {
                  handleCloseTimePicker();
                  setValueInput('');
                }}
                onChange={handleInputTimePicker}
                value={valueInput || props.value}
              />
            </>
          );
        }}
      />
      <p className="error-input error">{error}</p>
    </>
  );
};

export default TimeComponentCustom;
