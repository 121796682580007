import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Label, Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import ButtonComponent from 'components/Buttons';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW } from 'constants/index.js';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { YupValidateBreakTime } from './yupValidateBreakTime';
import TimePickerCustom from 'components/DateTime/TimePickerCustom';
import { DISABLE_BUTTON_ROLE } from 'constants/index.js';
import Loading from 'components/Loading';
import styles from './BreakTime.module.scss';
import { apiUpdateBreakTime } from 'api/masterData';
import { HTTP_OK } from 'constants/StatusCode';
import AlertComponent from 'components/Alert';
import { HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { HTTP_INTERNAL_SERVER_ERROR } from 'constants/StatusCode';
import { HTTP_UNPROCESSABLE_ENTITY } from 'constants/StatusCode';
const BreakTime = (props) => {
  const { t } = useTranslation();

  const { dataBreakTime, isLoadingBreakTime } = props;
  const dataInforUser = useContext(ThemContext);
  const [isShowAlert, setShowAlert] = useState(false);
  const [textMessage, setMessage] = useState('');
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(YupValidateBreakTime()),
  });
  const [disableBtn, setDisableBtn] = useState(false);

  const watchStartTimeMorning = useWatch({
    control,
    name: 'hour_start_morning',
    defaultValue: moment(dataBreakTime?.hour_start_morning, 'HH:mm:ss'),
  });

  const watchEndTimeMorning = useWatch({
    control,
    name: 'hour_end_morning',
    defaultValue: moment(dataBreakTime?.hour_end_morning, 'HH:mm:ss'),
  });

  const watchStartTimeAfternoon = useWatch({
    control,
    name: 'hour_start_afternoon',
    defaultValue: moment(dataBreakTime?.hour_start_afternoon, 'HH:mm:ss'),
  });

  const watchEndTimeAfternoon = useWatch({
    control,
    name: 'hour_end_afternoon',
    defaultValue: moment(dataBreakTime?.hour_end_afternoon, 'HH:mm:ss'),
  });

  const watchStartTimeNight = useWatch({
    control,
    name: 'hour_start_night',
    defaultValue: moment(dataBreakTime?.hour_start_night, 'HH:mm:ss'),
  });

  const watchEndTimeNight = useWatch({
    control,
    name: 'hour_end_night',
    defaultValue: moment(dataBreakTime?.hour_end_night, 'HH:mm:ss'),
  });

  useEffect(() => {
    if (watchEndTimeMorning && watchStartTimeMorning && watchEndTimeMorning.isBefore(watchStartTimeMorning)) {
      setError('hour_end_morning', {
        type: 'required',
        message: t('messages.pleaseChooseTheEndTimeBiggerThanTheStartTime'),
      });
    } else {
      setError('hour_end_morning', '');
    }

    if (watchEndTimeAfternoon && watchStartTimeAfternoon && watchEndTimeAfternoon.isBefore(watchStartTimeAfternoon)) {
      setError('hour_end_afternoon', {
        type: 'required',
        message: t('messages.pleaseChooseTheEndTimeBiggerThanTheStartTime'),
      });
    } else {
      setError('hour_end_afternoon', '');
    }

    if (watchEndTimeNight && watchStartTimeNight && watchEndTimeNight.isBefore(watchStartTimeNight)) {
      setError('hour_end_night', {
        type: 'required',
        message: t('messages.pleaseChooseTheEndTimeBiggerThanTheStartTime'),
      });
    } else {
      setError('hour_end_night', '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchStartTimeMorning,
    watchEndTimeMorning,
    watchStartTimeAfternoon,
    watchEndTimeAfternoon,
    watchStartTimeNight,
    watchEndTimeNight,
  ]);

  const onSubmit = async (values) => {
    setDisableBtn(true);
    const data = {
      hour_start_morning: moment(values.hour_start_morning).format('HH:mm'),
      hour_end_morning: moment(values.hour_end_morning).format('HH:mm'),
      hour_start_afternoon: moment(values.hour_start_afternoon).format('HH:mm'),
      hour_end_afternoon: moment(values.hour_end_afternoon).format('HH:mm'),
      hour_start_night: moment(values.hour_start_night).format('HH:mm'),
      hour_end_night: moment(values.hour_end_night).format('HH:mm'),
    };

    const response = await apiUpdateBreakTime(data);

    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
    }
    if (response?.status === HTTP_OK) {
      setMessage(response?.data.data);
      setShowAlert(true);
    }
    if (response?.status === HTTP_UNPROCESSABLE_ENTITY) {
      setMessage(t('common.editDataFail'));
      setShowAlert(true);
    }
    if (response?.status === HTTP_INTERNAL_SERVER_ERROR && response?.data?.message) {
      setMessage(response.data.message);
      setShowAlert(!isShowAlert);
    }

    setDisableBtn(false);
  };

  return (
    <>
      {isLoadingBreakTime && (
        <Row>
          <Col lg={12} xl={12}>
            <Loading addClass="mt-2" />
          </Col>
        </Row>
      )}

      <div className="box-session border-show pd-15 mt-3">
        <Col lg={12} xl={12} className="pd-0">
          <div className="box-content">
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
              <Row>
                <Col lg={5} className="col-md-5">
                  <h4 className="title-body">{t('masterData.breakTimeManagement')}</h4>
                </Col>
                <Col lg={7} className="col-md-7">
                  <ButtonComponent
                    text={t('common.save')}
                    addClass={`${
                      dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                    } float-right`}
                    handleClick={handleSubmit(onSubmit)}
                    hasDisabled={
                      errors?.hour_end_morning?.message ||
                      errors?.hour_end_afternoon?.message ||
                      errors?.hour_end_night?.message
                    }
                    isLoading={disableBtn}
                    type="submit"
                  />
                </Col>
              </Row>
              <div className={styles.blockBreakTime}>
                <Label className="text-bold color-navy">{t('masterData.offMorning')}</Label>
                <Row>
                  <Col className="col-md-3">
                    <TimePickerCustom
                      placeholder={t('masterData.placeholderStartTime')}
                      name={'hour_start_morning'}
                      value={watchStartTimeMorning}
                      format="HH:mm"
                      setValue={setValue}
                      setError={setError}
                      handleChangeTime={(time) => {
                        setValue('hour_start_morning', moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null);
                      }}
                      error={errors?.hour_start_morning?.message}
                    />
                  </Col>
                  <Col className="col-md-3">
                    <TimePickerCustom
                      placeholder={t('masterData.placeholderEndTime')}
                      name={'hour_end_morning'}
                      value={watchEndTimeMorning}
                      format="HH:mm"
                      setValue={setValue}
                      setError={setError}
                      handleChangeTime={(time) => {
                        setValue('hour_end_morning', moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null);
                      }}
                      error={errors?.hour_end_morning?.message}
                    />
                  </Col>
                </Row>
              </div>
              <div className={styles.blockBreakTime}>
                <Label className="text-bold color-navy">{t('masterData.offAfternoon')}</Label>
                <Row>
                  <Col className="col-md-3">
                    <TimePickerCustom
                      placeholder={t('masterData.placeholderStartTime')}
                      name={'hour_start_afternoon'}
                      value={watchStartTimeAfternoon}
                      format="HH:mm"
                      setValue={setValue}
                      setError={setError}
                      handleChangeTime={(time) => {
                        setValue(
                          'hour_start_afternoon',
                          moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null,
                        );
                      }}
                      error={errors?.hour_start_afternoon?.message}
                    />
                  </Col>
                  <Col className="col-md-3">
                    <TimePickerCustom
                      placeholder={t('masterData.placeholderEndTime')}
                      name={'hour_end_afternoon'}
                      value={watchEndTimeAfternoon}
                      format="HH:mm"
                      setValue={setValue}
                      setError={setError}
                      handleChangeTime={(time) => {
                        setValue('hour_end_afternoon', moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null);
                      }}
                      error={errors?.hour_end_afternoon?.message}
                    />
                  </Col>
                </Row>
              </div>
              <div className={styles.blockBreakTime}>
                <Label className="text-bold color-navy">{t('masterData.offNight')}</Label>
                <Row>
                  <Col className="col-md-3">
                    <TimePickerCustom
                      placeholder={t('masterData.placeholderStartTime')}
                      name={'hour_start_night'}
                      value={watchStartTimeNight}
                      format="HH:mm"
                      setValue={setValue}
                      setError={setError}
                      handleChangeTime={(time) => {
                        setValue('hour_start_night', moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null);
                      }}
                      error={errors?.hour_start_night?.message}
                    />
                  </Col>
                  <Col className="col-md-3">
                    <TimePickerCustom
                      placeholder={t('masterData.placeholderEndTime')}
                      name={'hour_end_night'}
                      value={watchEndTimeNight}
                      format="HH:mm"
                      setValue={setValue}
                      setError={setError}
                      handleChangeTime={(time) => {
                        setValue('hour_end_night', moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null);
                      }}
                      error={errors?.hour_end_night?.message}
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Col>
      </div>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {textMessage}
      </AlertComponent>
    </>
  );
};

export default BreakTime;
