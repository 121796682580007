import { useQuery } from 'react-query';

import { USE_INFOR_USER } from 'constants/Auth';
import { getInforUser } from '../api/auth';
import { SYSTEM_ACMS_HR } from 'constants/MasterData'

export function useInforAuth() {
  const { data, isLoading, error } = useQuery(USE_INFOR_USER, async () => {
    const response = await getInforUser({ system_id: SYSTEM_ACMS_HR });

    return response?.data;
  });

  return { ...data, isLoading, error };
}
