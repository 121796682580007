import { useGetCertificateCategory, useGetCertificates } from 'hook/useTraining';
import { createContext } from 'react';

export const TrainingContext = createContext();

export const TrainingProvider = ({ children }) => {
  const { data: categories } = useGetCertificateCategory();
  const { data: certificates } = useGetCertificates();

  return <TrainingContext.Provider value={{ categories, certificates }}>{children}</TrainingContext.Provider>;
};
