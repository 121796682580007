import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const YupValidate = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('messages.pleaseEnterTitle'))
      .matches(/.*[^ ].*/, t('messages.pleaseEnterTitle')),
    start_date: Yup.date()
      .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
      .nullable()
      .required(t('messages.pleaseSelectStartDate')),
    no_time_end: Yup.boolean().default(false),
    end_date: Yup.date()
      .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
      .nullable()
      .min(Yup.ref('start_date'), ({ min }) => t('messages.pleaseSelectPossible'))
      .when('no_time_end', {
        is: false,
        then: Yup.date()
          .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
          .nullable()
          .min(Yup.ref('start_date'), ({ min }) => t('messages.pleaseSelectPossible'))
          .required(t('messages.pleaseSelectEndDate')),
      }),
    type: Yup.number().default(0),
    hour_start_morning: Yup.date()
      .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
      .nullable()
      .when('type', {
        is: 1,
        then: Yup.date()
          .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
          .nullable()
          .required(t('messages.thisIsARequiredField')),
      }),
    hour_end_morning: Yup.date()
      .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
      .nullable()
      .min(Yup.ref('hour_start_morning'), ({ min }) => t('messages.pleaseReEnterTime'))
      .when('type', {
        is: 1,
        then: Yup.date()
          .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
          .nullable()
          .min(Yup.ref('hour_start_morning'), ({ min }) => t('messages.pleaseReEnterTime'))
          .required(t('messages.thisIsARequiredField')),
      }),
    hour_start_afternoon: Yup.date()
      .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
      .nullable()
      .min(Yup.ref('hour_end_morning'), ({ min }) => t('messages.pleaseReEnterTime'))
      .when('type', {
        is: 1,
        then: Yup.date()
          .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
          .nullable()
          .min(Yup.ref('hour_end_morning'), ({ min }) => t('messages.pleaseReEnterTime'))
          .required(t('messages.thisIsARequiredField')),
      }),
    hour_end_afternoon: Yup.date()
      .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
      .nullable()
      .min(Yup.ref('hour_start_afternoon'), ({ min }) => t('messages.pleaseReEnterTime'))
      .when('type', {
        is: 1,
        then: Yup.date()
          .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
          .nullable()
          .min(Yup.ref('hour_start_afternoon'), ({ min }) => t('messages.pleaseReEnterTime'))
          .required(t('messages.thisIsARequiredField')),
      }),
  });
  return validationSchema;
};

export default YupValidate;
