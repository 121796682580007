import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import vi from 'translations/vi.json';
import en from 'translations/en.json';
import ja from 'translations/ja.json';

// tslint:disable-next-line: no-floating-promises
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: 'vi',
    fallbackLng: 'vi',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      vi: { translation: vi },
      en: { translation: en },
      ja: { translation: ja },
    },
  });
