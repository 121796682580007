import React, { useCallback, useEffect, useState } from 'react';
import {
  ButtonBase,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { BiLinkExternal } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { debounce } from '@material-ui/core';
import classNames from 'classnames';

import iconAssetsTotal from 'assets/img/icons/icon-asset-total.svg';
import ButtonComponent from 'components/Buttons';
import Assets from '../index';
import SearchComponent from 'components/Search';
import DateDayMonthComponent from 'components/DateTime/DateMonth.js';
import * as Constants from 'constants/index.js';
import { COLOR_CHECKBOX_NOT_ACTIVE } from 'constants/ColorVariable';
import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import iconLoading from 'assets/img/icons/loading.png';
import './style.scss';
import { useAssetsDepreciation } from 'hook/useAssets';
import Loading from 'components/Loading';
import { formatNumberCurrency } from 'helpers/format';
import { useAssetsDepreciationCategory } from 'hook/useAssets';
import { HAVE_VALUE_DEPRECIATION, HAVE_NO_VALUE_DEPRECIATION } from 'constants/Assets';
import { handleExportExcel } from 'helpers';
import { toasts } from '../../../../index';
import { MAX_LENGTH_20 } from 'constants/index';
import useViewport from 'helpers/UseViewPort';
import { isZooming } from 'helpers';
import { handleScrollTopTable } from 'helpers';
import { HAVE_NOT_CALC_VALUE_DEPRECIATION } from 'constants/Assets';
import ModalDetailAssets from '../Modal/ModalDetailAssets';

function DepreciationTable() {
  const { t } = useTranslation();
  const { control } = useForm();
  const [filter, setFilter] = useState({
    key_word: '',
    asset_category_id: null,
    date: moment().format('MM/YYYY'),
    value: '',
    page: Constants.DEFAULT_PAGE_MUI,
    per_page: Constants.DEFAULT_LIMIT,
  });
  const [selectedValue, setSelectedValue] = useState();
  const [isShowModalDetailAssets, setIsShowModalDetailAssets] = useState(false);

  // data
  const { data: uAssetsDepreciation, isLoading: isLoadingAssetsDepreciation } = useAssetsDepreciation({
    ...filter,
    page: filter?.page + 1,
  });

  const { data: uAssetsDepreciationCategory, isLoading: isLoadingAssetsDepreciationCategory } =
    useAssetsDepreciationCategory({ ...filter, page: null, per_page: null });

  // Value depreciation
  const LIST_VALUE_DEPRECIATION = [
    {
      value: HAVE_VALUE_DEPRECIATION,
      labelName: t('assets.assetsDepreciationTable.haveValue'),
      total: uAssetsDepreciation?.asset_still_valuable,
    },
    {
      value: HAVE_NO_VALUE_DEPRECIATION,
      labelName: t('assets.assetsDepreciationTable.haveNoValue'),
      total: uAssetsDepreciation?.asset_out_of_value,
    },
    {
      value: HAVE_NOT_CALC_VALUE_DEPRECIATION,
      labelName: t('assets.assetsDepreciationTable.haveNotCalcValue'),
      total: uAssetsDepreciation?.asset_no_start_depreciation,
    },
  ];

  // Search by name
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchNameAssets = useCallback(
    debounce((value) => {
      setFilter({ ...filter, key_word: value });
    }),
  );

  // Change page
  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Reset
  const handleReset = () => {
    setFilter({
      key_word: null,
      date: moment().format('MM/YYYY'),
      asset_category_id: null,
      value: '',
      page: Constants.DEFAULT_PAGE_MUI,
      per_page: Constants.DEFAULT_LIMIT,
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Responsive
  const viewPort = useViewport();
  const isMobileTableScroll = viewPort.width <= 1665 && viewPort.width >= 898;

  // Handle table scroll top
  $(window).resize(function () {
    isZooming();
  });
  useEffect(() => {
    if (isMobileTableScroll) {
      window.addEventListener('scroll', handleScrollTopTable());
    }
  }, [isMobileTableScroll]);

  const formatCurrent = (value) => {
    if (String(value).indexOf('.') >= 0) {
      return formatNumberCurrency(String(value).slice(0, String(value).indexOf('.')));
    }
    return formatNumberCurrency(value);
  };

  return (
    <>
      <Grid container className="list-assets depreciation-assets-table">
        <Grid container className="nav-fixed">
          <Grid container className="align-items-center">
            <Grid item md={8} xs={12}>
              <Assets />
            </Grid>
            <Grid item className="button" md={4} xs>
              <ButtonComponent
                handleClick={() => {
                  if (uAssetsDepreciation?.category_filter?.total === 0) {
                    toasts.error(t('common.pleaseCheckFilterCondition'));
                  } else {
                    handleExportExcel('/api/asset-table-depreciation-export', filter, 'asset_depreciation_table.xlsx');
                  }
                }}
                addClass="export"
                icon={<BiLinkExternal />}
                text={t('common.export')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={9} className="asset-main">
            <div className="profile-box table-list table-border-show">
              {/* Table */}
              <Paper>
                <TableContainer className="scroll-custom">
                  <Table stickyHeader aria-label="sticky table" className="table-scroll">
                    {/* Sticky header */}
                    <TableHead className="asset-header head-title">
                      <TableRow className="asset-header-row sum ">
                        <TableCell>{t('assetEmployee.code')}</TableCell>
                        <TableCell>{t('assets.assetsDepreciationTable.assetsName')}</TableCell>
                        <TableCell>{t('assets.assetsDepreciationTable.type')}</TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsDepreciationTable.dayToCountDepreciation1')}</span>
                          <span>{t('assets.assetsDepreciationTable.dayToCountDepreciation2')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsDepreciationTable.purePrice')}</span>
                          <span>{t('assets.assetsDepreciationTable.VND')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">
                            {t('assets.assetsDepreciationTable.monthNumberDepreciation1')}
                          </span>
                          <span>{t('assets.assetsDepreciationTable.monthNumberDepreciation2')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsDepreciationTable.periodAccumulated1')}</span>
                          <span className="d-block">{t('assets.assetsDepreciationTable.periodAccumulated2')}</span>
                          <span>{t('assets.assetsDepreciationTable.VND')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">
                            {t('assets.assetsDepreciationTable.valueDepreciationCurrent1')}
                          </span>
                          <span className="d-block">
                            {t('assets.assetsDepreciationTable.valueDepreciationCurrent2')}
                          </span>
                          <span>{t('assets.assetsDepreciationTable.VND')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsDepreciationTable.nextAccumulated1')}</span>
                          <span className="d-block">{t('assets.assetsDepreciationTable.nextAccumulated2')}</span>
                          <span>{t('assets.assetsDepreciationTable.VND')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsDepreciationTable.restValue')}</span>
                          <span>{t('assets.assetsDepreciationTable.VND')}</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* Sum */}
                    <TableHead className="asset-header sum-header">
                      {/* Sum */}
                      {uAssetsDepreciation?.assets?.total !== 0 && (
                        <TableRow className="sum">
                          <TableCell colSpan={4}>{t('assets.assetsDepreciationTable.sumAB')}</TableCell>
                          <TableCell colSpan={1}>{formatCurrent(uAssetsDepreciation?.total_price)}</TableCell>
                          <TableCell colSpan={1}>{formatCurrent(uAssetsDepreciation?.total_period)}</TableCell>
                          <TableCell colSpan={1}>
                            {formatCurrent(uAssetsDepreciation?.total_accumulate_previous_term)}
                          </TableCell>
                          <TableCell colSpan={1}>
                            {formatCurrent(uAssetsDepreciation?.total_accumulate_current_term)}
                          </TableCell>
                          <TableCell colSpan={1}>
                            {formatCurrent(uAssetsDepreciation?.total_accumulate_end_term)}
                          </TableCell>
                          <TableCell colSpan={1}>{formatCurrent(uAssetsDepreciation?.total_remain)}</TableCell>
                        </TableRow>
                      )}
                    </TableHead>

                    <TableBody>
                      {/* Loading */}
                      {isLoadingAssetsDepreciation && (
                        <TableRow>
                          <TableCell colSpan={9}>
                            <Loading addClass="mt-3 mb-3" />
                          </TableCell>
                        </TableRow>
                      )}

                      {/* Main content table */}
                      {uAssetsDepreciation &&
                        uAssetsDepreciation?.assets?.data?.map((item, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              onClick={() => {
                                setSelectedValue(item);
                                setIsShowModalDetailAssets(true);
                              }}
                            >
                              <TableCell>{item.code ? item.code : t('common.notDataContract')}</TableCell>
                              <TableCell className="text-bold text-new-line">
                                <Tooltip title={item.name} placement="top-start">
                                  <span>{item.name ? item.name : t('common.notDataContract')}</span>
                                </Tooltip>
                              </TableCell>
                              <TableCell>
                                {item.asset_category_name ? item.asset_category_name : t('common.notDataContract')}
                              </TableCell>
                              <TableCell>
                                {item.date_depreciation ? item.date_depreciation : t('common.notDataContract')}
                              </TableCell>
                              <TableCell>{formatCurrent(item?.price)}</TableCell>
                              <TableCell>{item.period ? item.period : t('common.notDataContract')}</TableCell>
                              <TableCell>{formatCurrent(item?.value_previous_term)}</TableCell>
                              <TableCell>{formatCurrent(item?.value_end_term)}</TableCell>
                              <TableCell>{formatCurrent(item?.value_previous_term + item?.value_end_term)}</TableCell>
                              <TableCell>{formatCurrent(item?.value_remain)}</TableCell>
                            </TableRow>
                          );
                        })}

                      {/* No data */}
                      {uAssetsDepreciation?.assets?.total === 0 && (
                        <tr>
                          <td colSpan={10} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {uAssetsDepreciation?.assets?.total > 0 && (
                  <TablePagination
                    rowsPerPageOptions={Constants.PER_PAGE_OPTION}
                    component="div"
                    count={uAssetsDepreciation?.assets?.total}
                    rowsPerPage={filter?.per_page}
                    page={filter?.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="table-pagination"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                  />
                )}
              </Paper>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className="box-waiting">
              <div className="box-waiting-content">
                <img className="total-assets" src={iconAssetsTotal} alt={iconAssetsTotal} />
                <div className="box-waiting-text">
                  <div className="waiting-text">{t('assets.assetsDepreciationTable.totalAsset')}</div>
                  <div className="waiting-number">{uAssetsDepreciation?.total_asset}</div>
                </div>
              </div>
            </div>
            <div className="box-filter">
              {/* Assets name search */}
              <div className={classNames(`text padding-element-filter`)}>
                <SearchComponent
                  txtPlaceholder={t('assets.assetsDepreciationTable.findByNameAssets')}
                  addClass="input-search-box"
                  defaultValue={filter?.key_word}
                  handleSearch={handleSearchNameAssets}
                  maxLength={MAX_LENGTH_20}
                />
              </div>

              {/* Assets date search */}
              <div className={classNames(`text`)}>
                <DateDayMonthComponent
                  label=""
                  name="date"
                  valueDefault={typeof filter.date === 'string' ? moment(filter.date, 'MM/YYYY') : filter.date}
                  onChangeHandle={(value) => {
                    setFilter({
                      ...filter,
                      date: moment(value).format('MM/YYYY'),
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  placeholder={t('assets.dateAdded')}
                  sizeDate="small"
                  max={moment().toDate()}
                />
              </div>

              {/* Assets category search */}
              <div className={classNames(`padding-element-top`)}>
                <FormControl component="fieldset">
                  <FormLabel className={classNames(`text padding-element-filter border-top padding-element-top`)}>
                    {t('assets.assetsListRequests.category')}
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup {...field} value={filter?.asset_category_id ? filter?.asset_category_id : 0}>
                        <FormControlLabel
                          value={0}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              asset_category_id: null,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={
                            <Radio
                              sx={{
                                color: COLOR_CHECKBOX_NOT_ACTIVE,
                                '&.Mui-checked': {
                                  color: COLOR_CHECKBOX_CUSTOM,
                                },
                              }}
                            />
                          }
                          label={t('employees.all')}
                        />
                        {isLoadingAssetsDepreciationCategory && <Loading />}
                        {uAssetsDepreciationCategory?.map((item, index) => (
                          <div key={index} className="division">
                            <FormControlLabel
                              key={index}
                              value={item?.id}
                              onChange={(e) => {
                                setFilter({
                                  ...filter,
                                  asset_category_id: e.target.value,
                                  page: Constants.DEFAULT_PAGE_MUI,
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                              }}
                              control={
                                <Radio
                                  sx={{
                                    color: COLOR_CHECKBOX_NOT_ACTIVE,
                                    '&.Mui-checked': {
                                      color: COLOR_CHECKBOX_CUSTOM,
                                    },
                                  }}
                                />
                              }
                              label={item?.name}
                            />
                            <span className="total">{item.total_asset ? item?.total_asset : 0}</span>
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                    name="asset_category_id"
                    control={control}
                    defaultValue={filter?.asset_category_id ? filter?.asset_category_id : 0}
                  />
                </FormControl>
              </div>
              <div className={classNames(`padding-element-top`)}>
                <FormControl component="fieldset">
                  <FormLabel className={classNames(`text padding-element-filter border-top padding-element-top`)}>
                    {t('assets.assetsDepreciationTable.valueDepre')}
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup {...field} value={filter?.value ? filter?.value : 0}>
                        <FormControlLabel
                          value={0}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              value: null,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={
                            <Radio
                              sx={{
                                color: COLOR_CHECKBOX_NOT_ACTIVE,
                                '&.Mui-checked': {
                                  color: COLOR_CHECKBOX_CUSTOM,
                                },
                              }}
                            />
                          }
                          label={t('employees.all')}
                        />
                        {LIST_VALUE_DEPRECIATION?.map((item, index) => (
                          <div key={index} className="division">
                            <FormControlLabel
                              key={index}
                              value={item?.value}
                              onChange={(e) => {
                                setFilter({
                                  ...filter,
                                  value: e.target.value,
                                  page: Constants.DEFAULT_PAGE_MUI,
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                              }}
                              control={
                                <Radio
                                  sx={{
                                    color: COLOR_CHECKBOX_NOT_ACTIVE,
                                    '&.Mui-checked': {
                                      color: COLOR_CHECKBOX_CUSTOM,
                                    },
                                  }}
                                />
                              }
                              label={item?.labelName}
                            />
                            <span className="total">{item.total ? item?.total : 0}</span>
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                    name="type"
                    control={control}
                    defaultValue={filter?.type ? filter?.type : 0}
                  />
                </FormControl>
              </div>
              <div className="border-top">
                <ButtonBase onClick={() => handleReset()} className={classNames(`reset border-top`)} size="lg">
                  <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                  {t('common.reset')}
                </ButtonBase>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {isShowModalDetailAssets && (
        <ModalDetailAssets
          isShowModal={isShowModalDetailAssets}
          setShowModal={setIsShowModalDetailAssets}
          selectedValue={selectedValue}
          displayEdit={false}
        />
      )}
    </>
  );
}

export default DepreciationTable;
