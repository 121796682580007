import React from 'react';
import { Row, Col, Breadcrumb, BreadcrumbItem, Container, Media } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import imgAvatar from 'assets/img/theme/avatar_default.png';
import iconAdd from 'assets/img/icons/add.png';

import './style.scss';
import ButtonComponent from 'components/Buttons';
import * as Constants from 'constants/ListEmployees';
import { useRequestListUser } from 'hook/useEmployees';
import Loading from 'components/Loading';
import sign from 'jwt-encode';
import { SECRET_JWT_REQUEST_USERS } from 'constants/index.js';
import configs from 'config';
import { useGetCategoryId } from 'hook/useTimekeeping';
import { handleOnError } from 'helpers';

const Request = () => {
  let location = useLocation();
  const datas = location?.state;
  const { t } = useTranslation();
  const { data: uRequestListUser, isLoading: isLoadingRequestListUser } = useRequestListUser({
    user_id: datas?.userIds,
  });
  const { data: idCategory } = useGetCategoryId(configs.ID_REQUEST_LIST_USER);

  return (
    <div>
      <Container fluid className="layout-request">
        <Row>
          <Col sm="6">
            <Breadcrumb>
              <BreadcrumbItem>
                <NavLink to="/admin/employees/dashboard">{t('menu.employees')}</NavLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <NavLink to="/admin/employees/list">{t('employees.listEmployee')}</NavLink>
              </BreadcrumbItem>
              <BreadcrumbItem active>{t('request.requestObject')}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col sm="6" className="request-top-right">
            <span>
              <a
                href={`${configs.DOMAIN_WORK_FOLLOW}/request?user_id=${sign(
                  datas?.userIds,
                  SECRET_JWT_REQUEST_USERS,
                )}&id_work_fl=${idCategory?.data?.code}`}
                target="blank"
              >
                <ButtonComponent className="request" imgIcon={iconAdd} text={t('employees.confirm')} />
              </a>
            </span>
          </Col>
        </Row>
        <Row className="request-box">
          <Col className="part-title" sm="12">
            <p className="title">{t('request.titleRequest')}</p>
          </Col>
          <Col sm="6" className="type-request">
            <div className="item-request">
              <p className="item">{t('timekeeping.request.enterKeyWord')}</p>
              <p className="detail">{datas?.keyWord ? datas?.keyWord : t('common.notData')} </p>
            </div>
            <div className="item-request">
              <p className="item">{t('request.completeProfile')}</p>
              <p className="detail">
                {datas?.profile && (datas?.profile?.status ? t('employees.profileNotCompleted') : t('employees.all'))}
              </p>
            </div>
            <div className="item-request">
              <p className="item">{t('employees.status')}</p>
              <p className="detail">
                {datas?.filterStatus &&
                  datas?.filterStatus
                    .map((item) => (item.status ? item.name : ''))
                    .filter((it) => it)
                    .join(',')}
                {!datas?.filterStatus.find((data) => data.status) && t('employees.all')}
              </p>
            </div>
            <div className="item-request">
              <p className="item">{t('employees.skill')}</p>
              <p className="detail">
                {datas?.filterSkill &&
                  datas?.filterSkill
                    .map((item) => (item.status ? item.name : ''))
                    .filter((it) => it)
                    .join(',')}
                {!datas?.filterSkill.find((data) => data.status) && t('employees.all')}
              </p>
            </div>
            <div className="item-request">
              <p className="item">{t('employees.level')}</p>
              <p className="detail">
                {datas?.filterLevel &&
                  datas?.filterLevel
                    .map((item) => (item.status ? item.name : ''))
                    .filter((it) => it)
                    .join(',')}
                {!datas?.filterLevel.find((data) => data.status) && t('employees.all')}
              </p>
            </div>
            <div className="item-request">
              <p className="item">{t('employees.gender')}</p>
              <p className="detail">
                {datas?.filterGender &&
                  datas?.filterGender
                    .map((item) => (item.status ? item.name : ''))
                    .filter((it) => it)
                    .join(',')}
                {!datas?.filterGender.find((data) => data.status) && t('employees.all')}
              </p>
            </div>
          </Col>
          <Col sm="6" className="type-request">
            <div className="item-request">
              <p className="item">{t('employees.position')}</p>
              <p className="detail">
                {datas?.filterPosition &&
                  datas?.filterPosition
                    .map((item) => (item.status ? item.name : ''))
                    .filter((it) => it)
                    .join(',')}
                {!datas?.filterPosition.find((data) => data.status) && t('employees.all')}
              </p>
            </div>
            <div className="item-request">
              <p className="item">{t('common.division')}</p>
              <p className="detail">
                {datas?.filterDivision &&
                  datas?.filterDivision
                    .map((item) => (item.id === location.selectDivision ? item.name : ''))
                    .filter((it) => it)
                    .join(',')}
                {datas?.selectDivision === Constants.ALL && t('employees.all')}
              </p>
            </div>
            <div className="item-request">
              <p className="item">{t('employees.seniority')}</p>
              <p className="detail">
                {datas?.filterSeniority &&
                  datas?.filterSeniority
                    .map((item) => (item.status ? item.name : ''))
                    .filter((it) => it)
                    .join(',')}
                {!datas?.filterSeniority.find((data) => data.status) && t('employees.all')}
              </p>
            </div>
            <div className="item-request">
              <p className="item">{t('request.genderChildrent')}</p>
              <p className="detail">
                {datas?.filterEmployeeChildrenGender &&
                  datas?.filterEmployeeChildrenGender
                    .map((item) => (item.status ? item.name : ''))
                    .filter((it) => it)
                    .join(',')}
                {!datas?.filterEmployeeChildrenGender.find((data) => data.status) && t('employees.all')}
              </p>
            </div>
            <div className="item-request">
              <p className="item">{t('request.ageChildrent')}</p>
              <p className="detail">
                {datas?.filterEmployeeChildrenAge &&
                  datas?.filterEmployeeChildrenAge
                    .map((item) => (item.status ? item.name : ''))
                    .filter((it) => it)
                    .join(',')}
                {!datas?.filterEmployeeChildrenAge.find((data) => data.status) && t('employees.all')}
              </p>
            </div>
          </Col>
        </Row>
        <Row className="request-box">
          <Col className="part-title" sm="12">
            <p className="title">{t('request.listRequest')}</p>
            {!isLoadingRequestListUser && !uRequestListUser && <p className="error-no-data">{t('common.notData')}</p>}
          </Col>
          {!isLoadingRequestListUser && uRequestListUser && (
            <Row className="request-object">
              {uRequestListUser.map((item) => (
                <Col xs="12" sm="12" md="6" lg="4" xl="3" className="item-persionnel">
                  <Media>
                    <Media left>
                      <img
                        onError={handleOnError}
                        className="avatar"
                        src={item.avatar ? item.avatar : imgAvatar}
                        alt="Avatar"
                      />
                    </Media>
                    <Media body>
                      <Media heading>{item.name}</Media>
                      <p className="infor-personnel">
                        {item.position} - {item.division}
                      </p>
                      <p className="infor-personnel">{item.email}</p>
                    </Media>
                  </Media>
                </Col>
              ))}
            </Row>
          )}
          {isLoadingRequestListUser && <Loading />}
        </Row>
      </Container>
    </div>
  );
};

export default Request;
