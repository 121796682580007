import React from 'react';
import { Row, Col, Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import ModalComponent from 'components/Modal';
import { useGetCompany } from './../../../hook/useDepartment';
import InputField from 'components/Input/InputField';
import ButtonComponent from 'components/Buttons';
import { MAX_LENGTH_50, MAX_LENGTH_200 } from './../../../constants';

const ModalBranch = (props) => {
  const { t } = useTranslation();
  const { formAddBranch, onSubmit, handleInput, stateButton, isShowModal, textModal, toggle, toggleSubmit } = props;

  const { data: useCompany } = useGetCompany();

  return (
    <>
      <ModalComponent toggle={toggle} isShowModal={isShowModal} title={textModal.title}>
        <Form className="mt-3" onSubmit={onSubmit}>
          <div className="fieldset-group mt-3">
            <div className="form">
              <input className="disableBranch" variant="outlined" disabled defaultValue={useCompany} />
            </div>
          </div>

          <div className="mt-3 ">
            <div className="form">
              <InputField
                label={t('divisionDiagram.nameBranch')}
                isRequired={true}
                variant="outlined"
                name="name"
                maxLength={MAX_LENGTH_50}
                defaultValue={formAddBranch.name}
                onHandleInput={handleInput}
                error={formAddBranch.error_name}
              />
            </div>
          </div>

          <div className=" mt-3">
            <div className="form">
              <InputField
                label={t('divisionDiagram.addressBranch')}
                isRequired={true}
                variant="outlined"
                name="address"
                maxLength={MAX_LENGTH_200}
                defaultValue={formAddBranch.address}
                onHandleInput={handleInput}
                error={formAddBranch.error_address}
              />
            </div>
          </div>

          <Row className="mt-5">
            <Col className="md-12">
              <ButtonComponent
                text={textModal.btn_submit}
                addClass="w-100"
                handleClick={toggleSubmit}
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </Form>
      </ModalComponent>
    </>
  );
};

export default ModalBranch;
