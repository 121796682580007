import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import moment from 'moment';

import ModalComponent from 'components/Modal';
import { YupValidateEditAssets } from '../YupValidate/yupValidate';
import './style.scss';
import { useListCategoryAllAssets, useGetAssetRentalPrice, useListHrWarehouse } from 'hook/useAssets';
import SelectField from 'components/ReactForm/SelectField';
import Loading from 'components/Loading';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import { MAX_LENGTH_20, MAX_LENGTH_50, MAX_LENGTH_1000, MAX_LENGTH_15, MAX_LENGTH_3 } from 'constants/index.js';
import CustomInputNumber from 'components/InputYupAndMui/CusromInputNumber';
import { HTTP_OK, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { USE_LIST_ASSETS } from 'constants/Assets';
import { apiEditAssets } from 'api/assets';
import { LIST_TYPE_GOOD } from 'constants/Assets';
import { LIST_TYPE_UNDER_WARRANTY } from 'constants/Assets';
import { LIST_TYPE_WAIT_FOR_LIQUIDATION } from 'constants/Assets';
import { LIST_TYPE_LIQUIDATION } from 'constants/Assets';
import { LIST_TYPE_BROKEN } from 'constants/Assets';
import { LIST_TYPE_MISSING, LIST_TYPE_WAIT_FOR_APPROVE } from 'constants/Assets';
import { USE_DETAIL_ASSETS } from 'constants/Assets';
import ButtonComponent from 'components/Buttons';
import DateDayCustomInput from 'components/ReactForm/DateDayCustomInput';
import { APPROVE } from 'constants/index.js';

function ModalEditAssets({ isShowModal, setShowModal, setShowAlert, setMessage, dataInforUser, selectedValue }) {
  const { t } = useTranslation();
  const [disableBtn, setDisableBtn] = useState(false);
  const queryClient = useQueryClient();

  // List type
  const listTypeRoleApprove = [
    {
      id: LIST_TYPE_GOOD,
      name: t('assets.good'),
    },
    {
      id: LIST_TYPE_UNDER_WARRANTY,
      name: t('assets.underWarranty'),
    },
    {
      id: LIST_TYPE_WAIT_FOR_LIQUIDATION,
      name: t('assets.waitForLiquidation'),
    },
    {
      id: LIST_TYPE_LIQUIDATION,
      name: t('assets.liquidation'),
    },
    {
      id: LIST_TYPE_BROKEN,
      name: t('assets.broken'),
    },
    {
      id: LIST_TYPE_MISSING,
      name: t('assets.missing'),
    },
    {
      id: LIST_TYPE_WAIT_FOR_APPROVE,
      name: t('assets.waitForApprove'),
    },
  ];

  const listTypeRoleEdit = [
    {
      id: LIST_TYPE_GOOD,
      name: t('assets.good'),
    },
    {
      id: LIST_TYPE_UNDER_WARRANTY,
      name: t('assets.underWarranty'),
    },
    {
      id: LIST_TYPE_WAIT_FOR_LIQUIDATION,
      name: t('assets.waitForLiquidation'),
    },

    {
      id: LIST_TYPE_BROKEN,
      name: t('assets.broken'),
    }
  ];

  // Data
  const { data: uListCategoryAssets, isLoading: isLoadingListCategoryAssets } = useListCategoryAllAssets();
  const { data: uListHrWarehouse, isLoading: isLoadingListHrWarehouse } = useListHrWarehouse();
  const { data: uListAssets, isLoading: isLoadingListAsset } = useGetAssetRentalPrice()

  const selectedWareHouseItem = uListHrWarehouse?.find((item) => item.id === selectedValue?.warehouse_id);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(YupValidateEditAssets(!(selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE))),
  });

  // Add item API
  const { mutate: editItem } = useMutation((data) => apiEditAssets(selectedValue?.id, data), {
    onSettled: (response) => {
      if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
        setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
        setShowAlert(true);
        setDisableBtn(false);
        return;
      }
      if (response.data.status === HTTP_OK) {
        setShowAlert(true);
        setMessage(response.data.messages);
        setDisableBtn(false);
        setShowModal(false);
        queryClient.refetchQueries(USE_LIST_ASSETS);
        queryClient.refetchQueries(USE_DETAIL_ASSETS);
      }
      setDisableBtn(false);
    },
  });

  // Watch value select category
  const watchAssetCategoryId = useWatch({
    control,
    name: 'asset_category_id',
    defaultValue: selectedValue && selectedValue?.asset_category_id,
  });

  // Watch value name
  const watchNameAssets = useWatch({
    control,
    name: 'name',
    defaultValue: selectedValue && selectedValue?.name,
  });

  // Watch value price
  const watchPrice = useWatch({
    control,
    name: 'price',
    defaultValue: selectedValue?.price && selectedValue?.price ,
    // exact: true,
  });

  // Watch value period
  const watchPeriod = useWatch({
    control,
    name: 'period',
    defaultValue: selectedValue?.period && selectedValue?.period,
  });

  // Watch value price rent
  const watchPriceRent = useWatch({
    control,
    name: 'priceRent',
    defaultValue: selectedValue && selectedValue?.price / watchPeriod,
  });

  useEffect(() => {
    if (watchPrice && watchPeriod) {
      setValue('priceRent', watchPrice / watchPeriod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPrice, watchPeriod]);

  useEffect(() => {
    if (watchAssetCategoryId !== selectedValue.asset_category_id) {
      setValue('asset_rental_price_id', null)
    }
    // eslint-disable-next-line
  }, [watchAssetCategoryId, selectedValue.asset_category_id])

  // Watch value type
  const watchType = useWatch({
    control,
    name: 'type',
    defaultValue: selectedValue && selectedValue?.type,
  });

  // Watch value des
  const watchDes = useWatch({
    control,
    name: 'description',
    defaultValue: selectedValue?.description ? selectedValue?.description : '',
  });

  // Submit data
  const onSubmit = (data) => {
    const dataToSend = {
      asset_category_id: Number(data.asset_category_id),
      asset_rental_price_id: Number(data.asset_rental_price_id) || null,
      code: data?.code,
      price: watchPrice,
      period: Number(data.period),
      input_date: data.input_date,
      out_date: data.out_date,
      warehouse_id: Number(data.warehouse_id),
      type: Number(data.type),
      name: watchNameAssets,
      description: data.description,
      previous_status: selectedValue?.type,
      warehouse_id_previous: selectedValue?.warehouse_id,
    };
    setDisableBtn(true);
    if (selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE) {
      const rentalPriceByCategory = assetsTypeByCategory(Number(data.asset_category_id), uListAssets?.data)
      const rentalIds = rentalPriceByCategory.map(rental => rental.id)
      if (!rentalPriceByCategory.length) {
        setError('asset_rental_price_id', { message: t('messages.thisIsARequiredField') })
      } else if (!rentalIds.includes(data.asset_rental_price_id)) {
        setError('asset_rental_price_id', { message: t('messages.thisIsARequiredField') })
      }
    }
    editItem(dataToSend);
  };

  // Clear data
  useEffect(() => {
    if (!isShowModal) {
      setValue('description', selectedValue?.description ? selectedValue?.description : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowModal]);

  const assetsTypeByCategory = (categoryId, assets) => {
    const assetsType = assets.filter(asset => Number(asset.asset_category_id) === Number(categoryId))
    return assetsType
  }

  const renderType = () => {
    if(dataInforUser?.hr_permission?.assets_permission === APPROVE) {
      return listTypeRoleApprove.map((item) => (
        <MenuItem value={item.id} key={item.id}>
          {item.name}
        </MenuItem>
      ))
    }
    return listTypeRoleEdit.map((item) => (
      <MenuItem value={item.id} key={item.id}>
        {item.name}
      </MenuItem>
    ))
  }

  return (
    <>
      <ModalComponent
        toggle={() => {
          setShowModal(!isShowModal);
          if (!isShowModal) {
            setDisableBtn(false);
            reset();
          }
        }}
        isShowModal={isShowModal}
        title={t('assets.editAssets')}
        classNameAdd="modal-add-assets"
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {/* Category */}
          <section>
            {isLoadingListHrWarehouse && <Loading addClass="mt-3 mb-3" />}
            {!isLoadingListHrWarehouse && (
              <SelectField
                name="asset_category_id"
                label={t('assets.category')}
                error={errors?.asset_category_id?.message}
                defaultValue={selectedValue && selectedValue?.asset_category_id}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
                disabled={!(selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE)}
              >
                {uListCategoryAssets &&
                  uListCategoryAssets.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </SelectField>
            )}
          </section>
          {/* Asset type */}
          <section className="field-form">
            {isLoadingListAsset && <Loading addClass="mt-3 mb-3" />}
            {!isLoadingListAsset && (
              <SelectField
                name="asset_rental_price_id"
                label={t('masterData.assets.category.rentalPrices.table.columns.categoryName')}
                error={errors?.asset_rental_price_id?.message}
                defaultValue={selectedValue && selectedValue?.asset_rental_price_id}
                control={control}
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                scrollItem={true}
                isShowModal={isShowModal}
                isRequired
                disabled={!(selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE)}
              >
                {assetsTypeByCategory(watchAssetCategoryId, uListAssets?.data).map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </section>
          {/* Assets code */}
          <section className="field-form">
            <CustomInputYup
              placeholder={t('assets.assetsCode')}
              defaultValue={selectedValue && selectedValue?.code}
              label={t('assets.assetsCode')}
              name="code"
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              register={register}
              useWatch={useWatch}
              required
              setValue={setValue}
              maxLength={MAX_LENGTH_20}
              disabled={!(selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE)}
            />
          </section>

          {/* Assets name */}
          <section className="field-form">
            <CustomInputYup
              placeholder={t('assets.assetsName')}
              defaultValue={selectedValue && selectedValue?.name}
              label={t('assets.assetsName')}
              name="name"
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              register={register}
              useWatch={useWatch}
              required
              setValue={setValue}
              maxLength={MAX_LENGTH_50}
              disabled={!(selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE)}
            />
          </section>

          {/* Assets price */}
          <section className="field-form field-added-placeholder">
            <CustomInputNumber
              placeholder={t('assets.assetsPrice')}
              defaultValue={watchPrice}
              label={t('assets.assetsPrice')}
              name="price"
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              register={register}
              useWatch={useWatch}
              required
              setValue={setValue}
              thousandSeparator
              isInteger
              maxLength={MAX_LENGTH_15}
              disabled={!(selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE)}
            />
            <span className="placeholder-added">{t('common.currency')}</span>
          </section>

          {/* Assets period */}
          <section className="field-form field-added-placeholder">
            <CustomInputNumber
              placeholder={t('assets.assetsPeriod')}
              defaultValue={watchPeriod}
              label={t('assets.assetsPeriod')}
              name="period"
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              register={register}
              useWatch={useWatch}
              required
              setValue={setValue}
              isInteger
              maxLength={MAX_LENGTH_3}
              disabled={!(selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE)}
            />
            <span className="placeholder-added">{t('common.month')}</span>
          </section>

          {/* Assets price rent */}
          <section className="field-form field-added-placeholder">
            <CustomInputNumber
              placeholder={t('assets.priceRent')}
              defaultValue={watchPriceRent}
              label={t('assets.priceRent')}
              name="priceRent"
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              register={register}
              useWatch={useWatch}
              required
              setValue={setValue}
              thousandSeparator
              disabled
              isInteger
            />
            <span className="placeholder-added">{t('assets.VNDMonth')}</span>
          </section>

          {/* Warehouse */}
          <section className="field-form">
            {isLoadingListCategoryAssets && <Loading addClass="mt-3 mb-3" />}
            {!isLoadingListCategoryAssets && (
              <SelectField
                name="warehouse_id"
                label={t('assets.warehouseName')}
                error={errors?.warehouse_id?.message}
                clearErrors={clearErrors}
                defaultValue={selectedValue && selectedWareHouseItem?.id}
                control={control}
                required
                setValue={setValue}
                useWatch={useWatch}
                register={register}
                isShowModal={isShowModal}
                isRequired
              >
                {uListHrWarehouse &&
                  uListHrWarehouse.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </SelectField>
            )}
          </section>

          {/* Input date */}
          <section className="field-form">
            <DateDayCustomInput
              label={t('assets.inputDate')}
              name="input_date"
              error={errors?.input_date?.message}
              defaultValue={selectedValue && moment(selectedValue?.input_date, 'DD/MM/yyyy').toDate()}
              control={control}
              setValue={setValue}
              register={register}
              setError={setError}
              isShowModal={isShowModal}
              disabled={!(selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE)}
              isReadOnly={false}
            />
          </section>

          {/* Output date */}
          <section className="field-form">
            <DateDayCustomInput
              label={t('assets.outDate')}
              name="out_date"
              error={errors?.out_date?.message}
              defaultValue={selectedValue && moment(selectedValue?.out_date, 'DD/MM/yyyy').toDate()}
              control={control}
              setValue={setValue}
              register={register}
              setError={setError}
              isShowModal={isShowModal}
              disabled={!(selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE)}
              isReadOnly={false}
            />
          </section>

          {/* Type */}
          <section className="field-form">
            <SelectField
              name="type"
              label={t('assets.type')}
              error={errors?.type?.message}
              clearErrors={clearErrors}
              defaultValue={watchType}
              control={control}
              required
              setValue={setValue}
              useWatch={useWatch}
              register={register}
              isShowModal={isShowModal}
              isRequired
              disabled={selectedValue?.type === LIST_TYPE_WAIT_FOR_APPROVE}
            >
              {renderType()}
            </SelectField>
          </section>

          {/* Description */}
          <section className="field-form">
            <CustomInputYup
              placeholder={t('assets.description')}
              defaultValue={watchDes}
              label={t('assets.description')}
              name="description"
              errors={errors}
              clearErrors={clearErrors}
              control={control}
              register={register}
              useWatch={useWatch}
              setValue={setValue}
              maxLength={MAX_LENGTH_1000}
              multiline
              rows={6}
            />
          </section>

          {/* Bottom button */}
          <Grid container spacing={2} className="mt-5">
            <Grid item xs={12} className="md-6">
              <ButtonComponent
                text={t('common.save')}
                addClass="w-100 btn-submit"
                handleClick={handleSubmit(onSubmit)}
                type="submit"
                hasDisabled={disableBtn}
                isLoading={disableBtn}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </>
  );
}

export default ModalEditAssets;
