import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const YupValidate = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('messages.pleaseEnterTitle')).max(50, t('common.pleaseEnterLessThanFiftyCharacters')),
  });
  return validationSchema;
};

export default YupValidate;
