import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { IoMdEye } from 'react-icons/io';

import styles from './styles.module.scss';

const Box = ({
  index = 0,
  item = {},
  isItemAddNew = false,
  isActive = false,
  isHighline = false,
  isLastItem = false,
  totalChidItem = 0,
  totalItemClass = 'circle-grey',
  codeItemClass = 'bg-code-grey',
  borderColorClass = 'border-grey',
  borderTypeClass = 'border-circle',
  hasMenuSetting = false,
  handleSelectBox = () => {},
  name = '',
  hasParent = false,
}) => {
  const { t } = useTranslation();

  const [dropdownIdOpen, setIdOpen] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    setTooltipOpen(false);
  }, [dropdownIdOpen]);

  let indexId = name ? name + '-' + index : '';

  return (
    <div className={styles.box}>
      {!!Object.keys(item).length && (
        <>
          {hasParent && !isLastItem && (
            <div className={classNames(styles.verticalLine, { [styles.highlineWidth]: isHighline })} />
          )}

          {!!totalChidItem && isActive && <div className={styles.totalItemInLine}>{totalChidItem}</div>}

          {(hasParent || isItemAddNew) && (
            <div className={classNames(styles.beforeLine, { [styles.highlineHeight]: isActive })}></div>
          )}

          {!isItemAddNew ? (
            <div
              className={classNames(styles.boxBody, { [borderColorClass]: isActive, [borderTypeClass]: isActive })}
              onClick={() => handleSelectBox({ id: item.id, index })}
              id={indexId}
            >
              <div className={classNames(styles.totalItem, { [totalItemClass]: isActive })}>{item.total_member}</div>
              <div className={classNames(styles.name, { [styles.active]: isActive })}>{item.name}</div>

              {hasMenuSetting && (
                <ButtonDropdown
                  isOpen={dropdownIdOpen}
                  toggle={() => setIdOpen(!dropdownIdOpen)}
                  className={styles.btnDropdown}
                >
                  <DropdownToggle className={styles.iconDropdown}>...</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className={styles.dropdownItem}>
                      <IoMdEye className="mr-2"/>
                      {t('divisionDiagram.show')}
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              )}

              {isActive && hasMenuSetting && !dropdownIdOpen && (
                <Tooltip
                  className="department-tooltip"
                  placement="top"
                  isOpen={tooltipOpen}
                  autohide={false}
                  target={indexId}
                  toggle={toggleTooltip}
                >
                  <p className="division-head">
                    {t(`divisionDiagram.${name}`)}
                    {': '}
                    {item?.name}
                  </p>
                  <p>
                    {t('divisionDiagram.manager')}
                    {': '}
                    <span>{item?.leader}</span>
                  </p>
                  <p>
                    {t('divisionDiagram.foundation')}
                    {': '}
                    {item?.founding_at}
                  </p>
                  <p>
                    {t('divisionDiagram.totalMembers')}
                    {': '}
                    {item?.total_member}
                  </p>
                </Tooltip>
              )}

              {item.code && <div className={classNames(styles.code, { [codeItemClass]: isActive })}>{item.code}</div>}
            </div>
          ) : (
            <div className={classNames(styles.boxBodyAddNewItem, borderTypeClass)}>
              <div className={styles.addIcon}>+</div>
              <div className={styles.addTitle}>{item.name}</div>
            </div>
          )}

          <div
            className={classNames(styles.afterLine, {
              [styles.hidden]: !isActive || !totalChidItem,
              [styles.highlineHeight]: isActive,
            })}
          />
        </>
      )}
    </div>
  );
};

export default Box;
