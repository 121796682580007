import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { Row, Col, Form, Label } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { IoIosAdd } from 'react-icons/io';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Avatar from 'assets/img/icons/avatar_default.svg';

import './styles.scss';
import AlertComponent from 'components/Alert';
import ButtonComponent from 'components/Buttons';
import DateDayMonthComponent from 'components/DateTime/DateDay.js';
import InputField from 'components/Input/InputField';
import Loading from 'components/Loading';
import ModalComponent from 'components/Modal';

import { apiDeleteRoleName, apiCreateTeam, apiUpdateTeam } from 'api/department';

import ModalManager from './ModalManager/index';
import ModalRolesMember from './ModalRolesMember';

import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { MAX_LENGTH_50, MAX_LENGTH_1000 } from 'constants/index.js';
import { MODEL_TEAM } from 'constants/Department';
import { USE_DETAIL_TEAM, USE_DIAGRAM_DEPARTMENT } from 'constants/Department';

import { isEmptyObject, removeSpecial, getStrError } from 'helpers';
import { sendDate, dateResponse } from 'helpers/formatDate';
import { handleOnError } from 'helpers';

const DEFAULT_ERRORS_DATA_TEAM = {
  error_name: '',
  error_founding: '',
  error_des: '',
  error_parent: '',
};

const DEFAULT_FORM_DATA_TEAM = {
  id: 0,
  name: '',
  founding_at: null,
  description: '',
  parent_id: 0,
  leader: {},
  member: [],
  ...DEFAULT_ERRORS_DATA_TEAM,
};

const CHECK_DEFAULT = {
  isDeleted: false,
  number: -1,
};

const ModalTeam = (props) => {
  const { t } = useTranslation();
  const { toggle, isLoadingDetailTeam, isShowModal, infoTeam, infoBranch, infoDivision } = props;
  const queryClient = useQueryClient();

  const [formTeam, setFormTeam] = useState(DEFAULT_FORM_DATA_TEAM);
  const [stateButton, setStateButton] = useState(false);
  const [editMember, setEditMember] = useState({});
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [loadDelete, setLoadDelete] = useState(CHECK_DEFAULT);

  useEffect(() => {
    if (infoTeam) {
      setFormTeam((prevState) => ({
        ...prevState,
        id: infoTeam.id,
        name: infoTeam.name,
        parent_id: infoTeam.division_id,
        founding_at: infoTeam.founding_at ? dateResponse(infoTeam.founding_at) : null,
        description: infoTeam.description ?? '',
        leader: infoTeam?.lead && infoTeam?.lead.length ? infoTeam.lead[0] : {},
        member: infoTeam?.member ? infoTeam.member : [],
        ...DEFAULT_ERRORS_DATA_TEAM,
      }));
    } else {
      setFormTeam(DEFAULT_FORM_DATA_TEAM);
      setEditMember({});
    }
  }, [infoTeam, isShowModal]);

  const handleInputTeam = (e) => {
    const error = {};
    if (e.target.name === 'name') {
      e.target.value = removeSpecial(e.target.value);
      error.error_name = '';
    } else if (e.target.name === 'major') {
      error.error_major = '';
    }

    setFormTeam({
      ...formTeam,
      [e.target.name]: e.target.value,
      ...error,
    });
  };

  const handleInputTimeTeam = (value) => {
    setFormTeam({ ...formTeam, founding_at: value, error_founding: '' });
  };

  function formatRoleName(dataMember) {
    let role_name_team = [];
    if (dataMember)
      dataMember.forEach((item) => {
        role_name_team.push({
          user_id: item.id,
          role_name: item.role_name_team,
        });
      });
    return role_name_team.length ? role_name_team : '';
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStateButton(true);

    if (!validateTeam()) {
      setStateButton(false);
      return;
    }
    let role_name_team = formatRoleName(formTeam?.member);

    const data = {
      name: formTeam.name.trim(),
      founding_at: formTeam.founding_at ? sendDate(formTeam.founding_at) : '',
      description: formTeam?.description ? formTeam.description.trim() : '',
      user_id: isEmptyObject(formTeam?.leader) ? '' : formTeam.leader?.id,
      role_name_team: role_name_team ? JSON.stringify([...role_name_team]) : '',
      parent_id: infoDivision?.division_id,
    };

    if (!formTeam.description) delete data.description;
    if (!formTeam.founding_at) delete data.founding_at;
    if (!role_name_team) delete data.role_name_team;

    let response = {};
    if (!formTeam.id) {
      response = await apiCreateTeam(data);
    } else {
      response = await apiUpdateTeam(formTeam.id, data);
    }

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setFormTeam({
        ...formTeam,
        error_name: getStrError(msg?.name),
        error_founding: getStrError(msg?.founding_at),
        error_des: getStrError(msg?.description),
      });
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      toggle();
      setStateButton(false);
      setFormTeam({
        ...formTeam,
        ...DEFAULT_FORM_DATA_TEAM,
      });
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_DIAGRAM_DEPARTMENT);
      queryClient.invalidateQueries(USE_DETAIL_TEAM);
    }
  };

  function validateTeam() {
    var errors = {};
    if (!formTeam.name) {
      errors.error_name = t('common.pleaseFillOutThisField');
    }
    if (formTeam.name?.length > MAX_LENGTH_50) {
      errors.error_name = t('common.pleaseEnterLessThanFiftyCharacters');
    }
    if (!isEmptyObject(errors)) {
      setFormTeam({
        ...formTeam,
        ...errors,
      });
      return false;
    }
    return true;
  }

  //====== modal manger and roles =====
  const [isModalManager, setShowModalManager] = useState(false);
  const [isModalRolesMember, setModalRolesMember] = useState(false);

  const handleShowManager = () => {
    setShowModalManager(!isModalManager);
  };
  const handelChangeManage = (manage) => {
    setFormTeam({ ...formTeam, leader: manage });
  };

  const handleShowRolesMember = () => {
    setEditMember({});
    setModalRolesMember(!isModalManager);
  };

  const handleEditMember = (member) => {
    let _member = { ...member, role_name: member.role_name_team };
    setEditMember(_member);
    setModalRolesMember(!isModalManager);
  };

  const onHandelMember = (member) => {
    let _members = [...formTeam.member];
    if (isEmptyObject(editMember)) {
      _members.push({
        ...member.member,
        role_name_team: member.role,
      });
    } else {
      formTeam.member.forEach((item, key) => {
        if (item.user_id === editMember.user_id) {
          _members[key] = { ...member.member };
          _members[key].role_name_team = member.role;
        }
      });
    }

    setFormTeam({ ...formTeam, member: _members });
  };

  // ============= deleted role ==============
  const handleDeleteRole = (event, member, key) => {
    alertConfirmDeleteRoleName(member, key);
  };

  const { mutate: handelDeleteRolesName } = useMutation(
    async (id) => {
      const result = await apiDeleteRoleName(id);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(USE_DETAIL_TEAM);
        setLoadDelete(CHECK_DEFAULT);
      },
      onError: () => {
        setLoadDelete(CHECK_DEFAULT);
      },
    },
  );

  const alertConfirmDeleteRoleName = async (memberEdit, key) => {
    confirmAlert({
      title: t('divisionDiagram.divisionPopup.confirmDel'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.done'),
          className: 'btn-alert-ok',
          onClick: async () => {
            if (formTeam?.id) {
              setLoadDelete({
                isDeleted: true,
                number: key,
              });
              handelDeleteRolesName(memberEdit.id);
            } else {
              let roleMember = formTeam.member.filter((item) => {
                return memberEdit.id !== item?.user_id;
              });
              setFormTeam({ ...formTeam, member: roleMember });
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <ModalComponent
        toggle={toggle}
        isShowModal={isShowModal}
        title={formTeam?.id ? t('divisionDiagram.updateTeamTitle') : t('divisionDiagram.addTeamTitle')}
      >
        {isLoadingDetailTeam ? (
          <Loading />
        ) : (
          <Form className="mt-2" onSubmit={handleSubmit}>
            <div className="fieldset-group">
              <InputField
                label={t('divisionDiagram.divisionDetail.branch')}
                defaultValue={infoBranch?.branch_name}
                name="branch"
                variant="outlined"
                disabled={true}
              />
            </div>
            <div className="fieldset-group mt-3">
              <InputField
                label={t('divisionDiagram.department')}
                defaultValue={infoDivision?.division_name}
                name="division"
                variant="outlined"
                disabled={true}
              />
            </div>
            <div className="fieldset-group mt-3">
              <InputField
                label={t('divisionDiagram.nameTeam')}
                isRequired={true}
                variant="outlined"
                name="name"
                maxLength={MAX_LENGTH_50}
                defaultValue={formTeam.name}
                onHandleInput={handleInputTeam}
                error={formTeam.error_name}
              />
            </div>
            <div className="mt-3">
              <DateDayMonthComponent
                label={t('divisionDiagram.divisionDetail.foundation')}
                valueDefault={
                  typeof formTeam.founding_at === 'string'
                    ? moment(formTeam.founding_at, 'DD/MM/YYYY')
                    : formTeam.founding_at
                }
                onChangeHandle={handleInputTimeTeam}
                error={formTeam?.error_founding}
              />
            </div>
            <div className="fieldset-group mt-3">
              <InputField
                addClass="textArea"
                label={t('divisionDiagram.description')}
                variant="outlined"
                name="description"
                maxLength={MAX_LENGTH_1000}
                defaultValue={formTeam.description}
                onHandleInput={handleInputTeam}
                error={formTeam.error_des}
                rows={2}
              />
            </div>
            <div className="mb-2 mt-3">
              <Label className="text-bold color-navy">{t('divisionDiagram.managerTeam')}</Label>
            </div>
            {!isEmptyObject(formTeam.leader) ? (
              <div className="manager-block-team-division">
                <div className="block-noicons">
                  <div className="add-manager">
                    <img
                      onError={handleOnError}
                      alt="avatar"
                      width="100%"
                      height="100%"
                      src={formTeam.leader?.avatar ? formTeam.leader.avatar : Avatar}
                    />
                  </div>
                  <div className="contentEdit pl-2">
                    <div>
                      <h6 className="textSubField">{formTeam.leader?.user_name || formTeam.leader?.name}</h6>
                    </div>
                    <div>
                      <span>{formTeam.leader?.email}</span>
                      <span> - </span>
                      <span>{t('divisionDiagram.divisionPopup.leaderTeam')}</span>
                    </div>
                  </div>
                </div>
                <div className="block-icons">
                  <div className="each-icon">
                    <HiPencil onClick={handleShowManager} />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-start ">
                  <div className="editDeleteButton">
                    <IoIosAdd onClick={handleShowManager} />
                  </div>
                  <div className="textButton">
                    <Label>{t('divisionDiagram.addLeader')}</Label>
                  </div>
                </div>
                <p className="error">{formTeam.error_manager}</p>
              </>
            )}
            <div className="line-team-division-department mt-2 mb-2"></div>
            {formTeam.member?.map((item, key) => (
              <React.Fragment key={key}>
                <div className="manager-block-team-division">
                  <div className="block-noicons">
                    <div className="add-manager">
                      <img
                        onError={handleOnError}
                        alt="avatar"
                        width="100%"
                        height="100%"
                        src={item?.avatar ? item.avatar : Avatar}
                      />
                    </div>
                    <div className="contentEdit pl-2">
                      <div>
                        <h6 className="textSubField">{item?.name}</h6>
                      </div>
                      <div>
                        <span>{item?.email}</span>
                        <span> - </span>
                        <span>{item?.role_name_team}</span>
                      </div>
                    </div>
                  </div>
                  <div className="block-icons">
                    <div className="each-icon">
                      {loadDelete.isDeleted && loadDelete.number === key ? (
                        <i className={'fa fa-spinner fa-pulse fa-3x fa-fw icon-spinner-modal-team'} />
                      ) : (
                        <MdDelete onClick={(event) => handleDeleteRole(event, item, key)} />
                      )}
                    </div>
                    <div className="each-icon ml-3">
                      <HiPencil onClick={() => handleEditMember(item)} />
                    </div>
                  </div>
                </div>
                <div className="line-team-division-department mt-2 mb-2"></div>
              </React.Fragment>
            ))}
            <div className="d-flex justify-content-start">
              <div className="editDeleteButton">
                <IoIosAdd onClick={handleShowRolesMember} />
              </div>
              <div className="textButton">
                <Label>{t('divisionDiagram.addRole')}</Label>
              </div>
            </div>
            <Row className="mt-5">
              <Col className="md-12">
                <ButtonComponent
                  text={formTeam?.id ? t('divisionDiagram.updateTeam') : t('divisionDiagram.addMoreTeam')}
                  addClass="w-100"
                  type="submit"
                  hasDisabled={stateButton}
                  isLoading={stateButton}
                />
              </Col>
            </Row>
          </Form>
        )}
      </ModalComponent>

      <ModalManager
        idDivision={infoDivision?.division_id}
        idTeam={formTeam.id}
        leader={formTeam.leader}
        listMemberRole={formTeam?.member}
        isShowModalManager={isModalManager}
        handleToggleManager={() => setShowModalManager(!isModalManager)}
        isAddNew={formTeam?.id ? false : true}
        onHandelManage={(manage) => handelChangeManage(manage)}
        type={MODEL_TEAM}
      />

      <ModalRolesMember
        idDivision={infoDivision?.division_id}
        idTeam={formTeam.id}
        isModalRolesMember={isModalRolesMember}
        leader={formTeam.leader}
        listMemberRole={formTeam?.member}
        member={editMember}
        isAddNew={formTeam?.id ? false : true}
        handleToggleManager={() => setModalRolesMember(!isModalRolesMember)}
        onHandelMember={(member) => onHandelMember(member)}
        type={MODEL_TEAM}
      />
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};

export default ModalTeam;
