import React, { useState, useContext } from 'react';
import { HiPencil } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import $ from 'jquery';

import { Col, Table, Row, Label } from 'reactstrap';

import Box from '@mui/material/Box';

import './style.scss';
import ModalComponent from 'components/Modal';
import InputField from 'components/Input/InputField';
import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import AlertComponent from 'components/Alert';
import { isFloatAnode, checkValidate, isNotNullObject, checkErrorResponse } from 'helpers/index.js';
import { HTTP_OK, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { HTTP_UNPROCESSABLE_ENTITY } from 'constants/StatusCode';
import { MAX_LENGTH_50, DATA_TYPE_FLOAT } from 'constants/index.js';
import { USE_LIST_OVER_TIME } from 'constants/MasterData';
import { apiUpdateOverTime } from 'api/masterData.js';
import { useListOverTime } from 'hook/useMasterData.js';
import Sidebar from '../Sidebar.js';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';
import TimeComponentCustom from 'components/DateTime/TimeComponentCustom';

const FORM_DEFAULT = {
  id: 0,
  name: '',
  start_first_shift: null,
  end_first_shift: null,
  first_coefficient: '',
  start_second_shift: null,
  end_second_shift: null,
  second_coefficient: '',
  start_third_shift: null,
  end_third_shift: null,
  third_coefficient: '',
  errors: {},
};

const OverTime = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [isShowModal, setShowModal] = useState(false);
  const [stateButton, setStateButton] = useState(false);
  const [formAddEdit, setFormAddEdit] = useState(FORM_DEFAULT);

  const { data: dataOverTime, isLoading: isLoadingListOverTime } = useListOverTime();

  const handleEdit = (overTime) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    let _overTime = { ...overTime };
    _overTime.start_first_shift = moment(overTime.start_first_shift, 'HH:mm');
    _overTime.end_first_shift = moment(overTime.end_first_shift, 'HH:mm');
    _overTime.start_second_shift = moment(overTime.start_second_shift, 'HH:mm');
    _overTime.end_second_shift = moment(overTime.end_second_shift, 'HH:mm');
    _overTime.start_third_shift = moment(overTime.start_third_shift, 'HH:mm');
    _overTime.end_third_shift = moment(overTime.end_third_shift, 'HH:mm');

    setFormAddEdit({ ...formAddEdit, ..._overTime, errors: {} });
    setShowModal(true);
  };

  const handleInput = (e) => {
    if (
      (e.target.name === 'first_coefficient' ||
        e.target.name === 'second_coefficient' ||
        e.target.name === 'third_coefficient') &&
      isFloatAnode(e.target.value)
    ) {
      let keyError = e.target.name + '_error';
      setFormAddEdit({
        ...formAddEdit,
        [e.target.name]: e.target.value,
        errors: { ...formAddEdit.errors, [keyError]: '' },
      });
    }
  };

  const handleChangeDateTime = (date, name) => {
    let _errors = { ...formAddEdit.errors };
    _errors[name + '_error'] = '';

    setFormAddEdit({ ...formAddEdit, [name]: moment(date).isValid() ? moment(date) : null, errors: { ..._errors } });
  };

  const VALIDATE = [
    {
      name: 'start_first_shift',
      name_error: 'start_first_shift_error',
      validate: [
        {
          required: 'required',
          message: t('messages.masterData.pleaseSelectStartTime'),
        },
      ],
    },
    {
      name: 'end_first_shift',
      name_error: 'end_first_shift_error',
      validate: [
        {
          required: 'required',
          message: t('messages.masterData.pleaseSelectEndTime'),
        },
      ],
    },
    {
      name: 'first_coefficient',
      name_error: 'first_coefficient_error',
      validate: [
        {
          required: 'required',
          message: t('messages.masterData.pleaseEnterTheOTSystemInShiftFirst'),
        },
      ],
    },
    {
      name: 'start_second_shift',
      name_error: 'start_second_shift_error',
      validate: [
        {
          required: 'required',
          message: t('messages.masterData.pleaseSelectStartTime'),
        },
      ],
    },
    {
      name: 'end_second_shift',
      name_error: 'end_second_shift_error',
      validate: [
        {
          required: 'required',
          message: t('messages.masterData.pleaseSelectEndTime'),
        },
      ],
    },
    {
      name: 'second_coefficient',
      name_error: 'second_coefficient_error',
      validate: [
        {
          required: 'required',
          message: t('messages.masterData.pleaseEnterTheOTSystemInShiftSecond'),
        },
      ],
    },
    {
      name: 'start_third_shift',
      name_error: 'start_third_shift_error',
      validate: [
        {
          required: 'required',
          message: t('messages.masterData.pleaseSelectStartTime'),
        },
      ],
    },
    {
      name: 'end_third_shift',
      name_error: 'end_third_shift_error',
      validate: [
        {
          required: 'required',
          message: t('messages.masterData.pleaseSelectEndTime'),
        },
      ],
    },
    {
      name: 'third_coefficient',
      name_error: 'third_coefficient_error',
      validate: [
        {
          required: 'required',
          message: t('messages.masterData.pleaseEnterTheOTSystemInShiftThird'),
        },
      ],
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    let errorsValidate = checkValidate(formAddEdit, VALIDATE);

    if (isNotNullObject(errorsValidate)) {
      var focus = true;
      if (errorsValidate.start_first_shift) {
        if (focus) {
          $('input[name=start_first_shift]').focus();
          focus = false;
        }
      } else if (errorsValidate.end_first_shift) {
        if (focus) {
          $('input[name=end_first_shift]').focus();
          focus = false;
        }
      } else if (errorsValidate.first_coefficient) {
        if (focus) {
          $('input[name=first_coefficient]').focus();
          focus = false;
        }
      } else if (errorsValidate.start_second_shift) {
        if (focus) {
          $('input[name=start_second_shift]').focus();
          focus = false;
        }
      } else if (errorsValidate.end_second_shift) {
        if (focus) {
          $('input[name=end_second_shift]').focus();
          focus = false;
        }
      } else if (errorsValidate.second_coefficient) {
        if (focus) {
          $('input[name=second_coefficient]').focus();
          focus = false;
        }
      } else if (errorsValidate.start_third_shift) {
        if (focus) {
          $('input[name=start_third_shift]').focus();
          focus = false;
        }
      } else if (errorsValidate.end_third_shift) {
        if (focus) {
          $('input[name=end_third_shift]').focus();
          focus = false;
        }
      } else if (errorsValidate.third_coefficient) {
        if (focus) {
          $('input[name=third_coefficient]').focus();
          focus = false;
        }
      }
      setFormAddEdit({ ...formAddEdit, errors: { ...errorsValidate } });
      setStateButton(false);
      return;
    }
    let data = {
      name: formAddEdit.name,
      start_first_shift: formAddEdit.start_first_shift.format('HH:mm'),
      end_first_shift: formAddEdit.end_first_shift.format('HH:mm'),
      start_second_shift: formAddEdit.start_second_shift.format('HH:mm'),
      end_second_shift: formAddEdit.end_second_shift.format('HH:mm'),
      start_third_shift: formAddEdit.start_third_shift.format('HH:mm'),
      end_third_shift: formAddEdit.end_third_shift.format('HH:mm'),
      first_coefficient: formAddEdit.first_coefficient,
      second_coefficient: formAddEdit.second_coefficient,
      third_coefficient: formAddEdit.third_coefficient,
      id: formAddEdit.id,
    };

    let response = await apiUpdateOverTime(formAddEdit.id, data);
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModal(false);
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_LIST_OVER_TIME);
      setStateButton(false);
    }

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      let errors = checkErrorResponse(response.data.messages, VALIDATE);

      if (isNotNullObject(errors)) {
        setFormAddEdit({ ...formAddEdit, errors: { ...errors } });
      }
      setStateButton(false);
    }
  };

  return (
    <div>
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>
        <Col lg={9} xl={10}>
          <div className="table-list">
            <Table className="align-items-center table-hover" responsive>
              <thead>
                <tr>
                  <th scope="col">{t('common.STT')}</th>
                  <th scope="col">{t('masterData.overTime.textOT')}</th>
                  <th scope="col">{t('masterData.overTime.firstShift')}</th>
                  <th scope="col">{t('masterData.overTime.firstShiftCoefficient')}</th>
                  <th scope="col">{t('masterData.overTime.secondShift')}</th>
                  <th scope="col">{t('masterData.overTime.secondShiftCoefficient')}</th>
                  <th scope="col">{t('masterData.overTime.thirdShift')}</th>
                  <th scope="col">{t('masterData.overTime.thirdShiftCoefficient')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {isLoadingListOverTime ? (
                  <tr>
                    <td colSpan={7}>
                      <Loading addClass="mt-5" />
                    </td>
                  </tr>
                ) : dataOverTime ? (
                  dataOverTime?.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>
                        {item.start_first_shift} - {item.end_first_shift}
                      </td>
                      <td>{item.first_coefficient}</td>
                      <td>
                        {item.start_second_shift} - {item.end_second_shift}
                      </td>
                      <td>{item.second_coefficient}</td>
                      <td>
                        {item.start_third_shift} - {item.end_third_shift}
                      </td>
                      <td>{item.third_coefficient}</td>
                      <td>
                        <div className="col-action d-flex float-right">
                          <div
                            className={`${
                              dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                            } edit mr-2`}
                          >
                            <HiPencil onClick={() => handleEdit(item)} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="error-no-data">
                      {t('common.notData')}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      <ModalComponent
        toggle={() => setShowModal(!isShowModal)}
        isShowModal={isShowModal}
        title={t('masterData.overTime.editCoefficientOT')}
      >
        <Row>
          <Col md={12}>
            <p className="text-bold color-navy">{t('masterData.overTime.generalInformation')}</p>
            <div className="mt-2">
              <InputField
                label=""
                isRequired={true}
                variant="outlined"
                name="name"
                defaultValue={formAddEdit.name}
                error={formAddEdit.errors?.name}
                disabled={true}
              />
            </div>
          </Col>
        </Row>
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <p className="text-bold mt-3 mb-3 color-navy">{t('masterData.overTime.OTCaseInformation')}</p>
            </Col>

            <Col md={12}>
              <Label className="text-bold mb-3 color-navy">{t('masterData.overTime.firstShift')}</Label>
            </Col>
            <Col md={6}>
              <div className="">
                <TimeComponentCustom
                  label={t('masterData.placeholderStartTime')}
                  placeholder={t('masterData.placeholderStartTime')}
                  format="HH:mm"
                  handleChangeTime={(time) => {
                    if (moment(time).isValid()) {
                      handleChangeDateTime(time, 'start_first_shift');
                    } else {
                      handleChangeDateTime(null, 'start_first_shift');
                    }
                  }}
                  value={formAddEdit.start_first_shift || null}
                  error={formAddEdit.errors?.start_first_shift_error}
                  onClear={false}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="">
                <TimeComponentCustom
                  label={t('masterData.placeholderEndTime')}
                  placeholder={t('masterData.placeholderEndTime')}
                  format="HH:mm"
                  handleChangeTime={(time) => handleChangeDateTime(time, 'end_first_shift')}
                  value={formAddEdit.end_first_shift || null}
                  error={formAddEdit.errors?.end_first_shift_error}
                  onClear={false}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mt-3">
                <InputField
                  label={t('masterData.overTime.coefficient')}
                  isRequired={true}
                  variant="outlined"
                  name="first_coefficient"
                  defaultValue={formAddEdit.first_coefficient}
                  error={formAddEdit.errors?.first_coefficient_error}
                  onHandleInput={handleInput}
                  dataType={DATA_TYPE_FLOAT}
                  maxLength={MAX_LENGTH_50}
                />
              </div>
            </Col>

            <Col md={12}>
              <Label className="text-bold mb-3 mt-3 color-navy">{t('masterData.overTime.secondShift')}</Label>
            </Col>
            <Col md={6}>
              <div className="">
                <TimeComponentCustom
                  label={t('masterData.placeholderStartTime')}
                  placeholder={t('masterData.placeholderStartTime')}
                  format="HH:mm"
                  handleChangeTime={(time) => handleChangeDateTime(time, 'start_second_shift')}
                  value={formAddEdit.start_second_shift || null}
                  error={formAddEdit.errors?.start_second_shift_error}
                  onClear={false}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="">
                <TimeComponentCustom
                  label={t('masterData.placeholderEndTime')}
                  placeholder={t('masterData.placeholderEndTime')}
                  format="HH:mm"
                  handleChangeTime={(time) => handleChangeDateTime(time, 'end_second_shift')}
                  value={formAddEdit.end_second_shift || null}
                  error={formAddEdit.errors?.end_second_shift_error}
                  onClear={false}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mt-3">
                <InputField
                  label={t('masterData.overTime.coefficient')}
                  isRequired={true}
                  variant="outlined"
                  name="second_coefficient"
                  defaultValue={formAddEdit.second_coefficient}
                  error={formAddEdit.errors?.second_coefficient_error}
                  onHandleInput={handleInput}
                  dataType={DATA_TYPE_FLOAT}
                  maxLength={MAX_LENGTH_50}
                />
              </div>
            </Col>

            <Col md={12}>
              <Label className="text-bold mb-3 mt-3 color-navy">{t('masterData.overTime.thirdShift')}</Label>
            </Col>
            <Col md={6}>
              <div className="">
                <TimeComponentCustom
                  label={t('masterData.placeholderStartTime')}
                  placeholder={t('masterData.placeholderStartTime')}
                  format="HH:mm"
                  handleChangeTime={(time) => handleChangeDateTime(time, 'start_third_shift')}
                  value={formAddEdit.start_third_shift || null}
                  error={formAddEdit.errors?.start_third_shift_error}
                  onClear={false}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="">
                <TimeComponentCustom
                  label={t('masterData.placeholderEndTime')}
                  placeholder={t('masterData.placeholderEndTime')}
                  format="HH:mm"
                  handleChangeTime={(time) => handleChangeDateTime(time, 'end_third_shift')}
                  value={formAddEdit.end_third_shift || null}
                  error={formAddEdit.errors?.end_third_shift_error}
                  onClear={false}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mt-3">
                <InputField
                  label={t('masterData.overTime.coefficient')}
                  isRequired={true}
                  variant="outlined"
                  name="third_coefficient"
                  defaultValue={formAddEdit.third_coefficient}
                  error={formAddEdit.errors?.third_coefficient_error}
                  onHandleInput={handleInput}
                  dataType={DATA_TYPE_FLOAT}
                  maxLength={MAX_LENGTH_50}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="md-6  mt-1">
              <ButtonComponent
                text={t('timekeeping.cancelMail')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModal(false)}
              />
            </Col>
            <Col className="md-6 mt-1">
              <ButtonComponent
                text={t('common.save')}
                addClass="w-100"
                handleClick={() => setShowModal(true)}
                type="submit"
                hasDisabled={stateButton}
                isLoading={stateButton}
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default OverTime;
