import { sendGet, sendPost, sendPut, sendDelete, sendCustom } from 'helpers/axios'

export const apiAddSocialInsurance = (params) => sendPost('/api/social-insurance', params)
export const apiEditSocialInsurance = (params, id) => sendPut(`/api/social-insurance/${id}`, params)
export const apiGetSocialInsurance = (params) => sendGet('/api/social-insurance/list', params)
export const apiDeleteSocialInsurance = (id) => sendDelete(`/api/social-insurance/${id}`)
export const apiCheckExistsUser = (params) => sendPost('/api/social-insurance/validate-user', params)

export const apiImportSocialInsurance = (data, additionalConfig) => sendCustom({
  url: '/api/social-insurance/import',
  method: 'POST',
  Accept: 'application/octet-stream',
  data,
  ...additionalConfig,
})

