export const TYPE_EXPORT_PDF = 'type_export_pdf';
export const DEFAULT_LIMIT = 20;
export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT_LARGE = 20;
export const DEFAULT_PAGE_MUI = 0;
export const DEFAULT_OFFSET = 0;

export const LOADING_SM = 'sm';
export const LOADING_MD = 'md';

export const ALL = 'all';

export const SEND_EMAIL = 1;
export const NOT_SEND_EMAIL = 0;

export const NOT_IS_ONBOARD = 0;

export const MAX_LENGTH_3 = 3;
export const MAX_LENGTH_7 = 7;
export const MAX_LENGTH_12 = 12;
export const MAX_LENGTH_15 = 15;
export const MAX_LENGTH_20 = 20;
export const MAX_LENGTH_30 = 30;
export const MAX_LENGTH_40 = 40;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_60 = 60;
export const MAX_LENGTH_100 = 100;
export const MAX_LENGTH_200 = 200;
export const MAX_LENGTH_255 = 255;
export const MAX_LENGTH_1000 = 1000;

export const MAX_MINUTES_LATE = 480;

export const PER_PAGE_OPTION = [20, 40, 60];
export const PER_COURSE_PAGE_OPTION = [20, 30, 50, 100];


export const DATA_TYPE_NUMBER = 'number';
export const DATA_TYPE_CURRENCY = 'currency';
export const DATA_TYPE_FLOAT = 'float';

export const FORMAT_IMAGES = ['JPG', 'JPEG', 'PNG', 'JFIF', 'jpg', 'jpeg', 'png', 'jfif'];

export const TOTAL_WORK = 168;
export const VALIDATE_NOT_ZERO = 0;
export const NOT_DATA = 0;
export const OPTION_ALL = 0;

export const ROLE_NOT_VIEW = 'none';
export const ROLE_VIEW = 'view';
export const ROLE_EDIT = 'edit';
export const APPROVE = "approve";
export const DISABLE_ROLE = 'disable';
export const DISABLE_BUTTON_ROLE = 'disable-button';

export const USE_GET_NOTIFICATIONS = 'USE_GET_NOTIFICATIONS';
export const SECRET_JWT_REQUEST_USERS = 'amela-secret';
export const USE_TOTAL_GET_NOTIFICATIONS = 'USE_TOTAL_GET_NOTIFICATIONS';

export const SYSTEM_ME = 1;
export const SYSTEM_BO = 2;
export const SYSTEM_RECRUIT = 3;
export const SYSTEM_WORKFLOW = 4;
export const SYSTEM_WIKI = 5;
export const SYSTEM_PROJECT = 6;
