import React from 'react';
import { useTranslation } from 'react-i18next';

const PageNotFound = (props) => {
    let { t } = useTranslation();
    return <>
        <p className='error-no-data'>{t('common.youDoNotHaveAccess')}</p>
    </>;
};

export default PageNotFound;
