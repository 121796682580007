import React, { useRef, useState } from 'react';
import {
  ButtonBase,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TablePagination,
} from '@mui/material';
import { BiLinkExternal } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';

import iconInventory from 'assets/img/icons/icon-inventory.svg';
import ButtonComponent from 'components/Buttons';
import Assets from '../index';
import DateDayMonthComponent from 'components/DateTime/DateMonth.js';
import * as Constants from 'constants/index.js';
import { COLOR_CHECKBOX_NOT_ACTIVE } from 'constants/ColorVariable';
import { COLOR_CHECKBOX_CUSTOM } from 'constants/ColorVariable';
import iconLoading from 'assets/img/icons/loading.png';
import './style.scss';
import Loading from 'components/Loading';
import { formatNumberCurrency } from 'helpers/format';
import { handleExportExcel } from 'helpers';
import { toasts } from '../../../../index';
import { useGetExportImportInventory } from 'hook/useAssets';
import {
  MAX_LENGTH_PRICE,
  START_STRING_SLICE,
} from 'constants/Assets';

function ExportImportInventory() {
  const { t } = useTranslation();
  const { control } = useForm();
  const [filter, setFilter] = useState({
    asset_category_id: null,
    date: moment().format('MM/YYYY'),
    page: Constants.DEFAULT_PAGE_MUI,
    per_page: Constants.DEFAULT_LIMIT,
  });
  const tableRef = useRef(null);

  // data
  const { data: uExportImportInventory, isLoading: isLoadingExportImportInventory } = useGetExportImportInventory({
    ...filter,
    page: filter?.page + 1,
  });

  const LIST_BOX_RIGHT = [
    {
      name: t('assets.assetsExportImportInventory.beginningInventory'),
      value: uExportImportInventory?.total_asset_start_term,
    },
    {
      name: t('assets.assetsExportImportInventory.importInsidePhase'),
      value: uExportImportInventory?.total_asset_import_in_term,
    },
    {
      name: t('assets.assetsExportImportInventory.exportInsidePhase'),
      value: uExportImportInventory?.total_asset_export_in_term,
    },
    {
      name: t('assets.assetsExportImportInventory.lastPhaseInventory'),
      value: uExportImportInventory?.total_asset_end_term,
    },
  ];

  // Change page
  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: Constants.DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Reset
  const handleReset = () => {
    setFilter({
      date: moment().format('MM/YYYY'),
      asset_category_id: null,
      page: Constants.DEFAULT_PAGE_MUI,
      per_page: Constants.DEFAULT_LIMIT,
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Grid container className="list-assets export-import-inventory">
        <Grid container className="nav-fixed">
          <Grid container className="align-items-center">
            <Grid item md={8} xs={12}>
              <Assets />
            </Grid>
            <Grid item className="button" md={4} xs>
              <ButtonComponent
                handleClick={() => {
                  if (uExportImportInventory?.category_filter?.total === 0) {
                    toasts.error(t('common.pleaseCheckFilterCondition'));
                  } else {
                    handleExportExcel('/api/table-asset-in-out/export', filter, 'table_asset_in_out.xlsx');
                  }
                }}
                addClass="export"
                icon={<BiLinkExternal />}
                text={t('common.export')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          {/* Left side */}
          <Grid item md={9} className="asset-main">
            <div className="profile-box table-list table-border-show">
              {/* Table */}
              <Paper>
                {/* Main table */}
                <TableContainer className="scroll-custom">
                  <Table stickyHeader aria-label="sticky table" ref={tableRef} className="table-scroll">
                    {/* Sticky header */}
                    <TableHead className="asset-header">
                      <TableRow className="asset-header-row sum ">
                        <TableCell>{t('assets.assetsExportImportInventory.categoryCode')}</TableCell>
                        <TableCell>{t('assets.assetsExportImportInventory.category')}</TableCell>
                        <TableCell>{t('assets.assetsExportImportInventory.beginningInventory')}</TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsExportImportInventory.moneyEqual')}</span>
                          <span>{t('assets.assetsExportImportInventory.VND')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsExportImportInventory.importInsidePhase')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsExportImportInventory.moneyEqual')}</span>
                          <span>{t('assets.assetsExportImportInventory.VND')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsExportImportInventory.exportInsidePhase')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsExportImportInventory.moneyEqual')}</span>
                          <span>{t('assets.assetsExportImportInventory.VND')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsExportImportInventory.lastPhaseInventory')}</span>
                        </TableCell>
                        <TableCell>
                          <span className="d-block">{t('assets.assetsExportImportInventory.moneyEqual')}</span>
                          <span>{t('assets.assetsExportImportInventory.VND')}</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* Sum */}
                    <TableHead className="asset-header sum-header">
                      {uExportImportInventory?.category_filter?.total > 0 && (
                        <TableRow className="sum">
                          <TableCell colSpan={2}>{t('assets.assetsDepreciationTable.sumAB')}</TableCell>
                          <TableCell colSpan={1}>{uExportImportInventory?.asset_inventory_start_term}</TableCell>
                          <TableCell colSpan={1} className="custom-with">
                            {formatNumberCurrency(uExportImportInventory?.price_inventory_start_term)?.length > MAX_LENGTH_PRICE ? (
                              <Tooltip title={formatNumberCurrency(uExportImportInventory?.price_inventory_start_term)} arrow>
                                <span>
                                  {formatNumberCurrency(uExportImportInventory?.price_inventory_start_term).slice(
                                    START_STRING_SLICE,
                                    MAX_LENGTH_PRICE,
                                  ) + '...'}
                                </span>
                              </Tooltip>
                            ) : (
                              formatNumberCurrency(uExportImportInventory?.price_inventory_start_term)
                            )}
                          </TableCell>
                          <TableCell colSpan={1}>{uExportImportInventory?.asset_import_in_term}</TableCell>
                          <TableCell colSpan={1}>
                            {formatNumberCurrency(uExportImportInventory?.total_price_import_in_term)?.length > MAX_LENGTH_PRICE ? (
                              <Tooltip title={formatNumberCurrency(uExportImportInventory?.total_price_import_in_term)} arrow>
                                <span>
                                  {formatNumberCurrency(uExportImportInventory?.total_price_import_in_term).slice(
                                    START_STRING_SLICE,
                                    MAX_LENGTH_PRICE,
                                  ) + '...'}
                                </span>
                              </Tooltip>
                            ) : (
                              formatNumberCurrency(uExportImportInventory?.total_price_import_in_term)
                            )}
                          </TableCell>
                          <TableCell colSpan={1}>{uExportImportInventory?.asset_export_in_term}</TableCell>
                          <TableCell colSpan={1}>
                            {formatNumberCurrency(uExportImportInventory?.total_price_export_in_term)?.length > MAX_LENGTH_PRICE ? (
                              <Tooltip title={formatNumberCurrency(uExportImportInventory?.total_price_export_in_term)} arrow>
                                <span>
                                  {formatNumberCurrency(uExportImportInventory?.total_price_export_in_term).slice(
                                    START_STRING_SLICE,
                                    MAX_LENGTH_PRICE,
                                  ) + '...'}
                                </span>
                              </Tooltip>
                            ) : (
                              formatNumberCurrency(uExportImportInventory?.total_price_export_in_term)
                            )}
                          </TableCell>
                          <TableCell colSpan={1}>{uExportImportInventory?.asset_inventory_end_term}</TableCell>
                          <TableCell colSpan={1} className="breakWord">
                            {formatNumberCurrency(uExportImportInventory?.price_inventory_end_term)?.length > MAX_LENGTH_PRICE ? (
                              <Tooltip title={formatNumberCurrency(uExportImportInventory?.price_inventory_end_term)} arrow>
                                <span>
                                  {formatNumberCurrency(uExportImportInventory?.price_inventory_end_term).slice(
                                    START_STRING_SLICE,
                                    MAX_LENGTH_PRICE,
                                  ) + '...'}
                                </span>
                              </Tooltip>
                            ) : (
                              formatNumberCurrency(uExportImportInventory?.price_inventory_end_term)
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableHead>

                    <TableBody>
                      {/* Loading */}
                      {isLoadingExportImportInventory && (
                        <TableRow>
                          <TableCell colSpan={10}>
                            <Loading addClass="mt-3 mb-3" />
                          </TableCell>
                        </TableRow>
                      )}

                      {/* Main content table */}
                      {uExportImportInventory &&
                        uExportImportInventory?.category_filter?.data?.map((item, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              <TableCell>{item.code ? item.code : t('common.notDataContract')}</TableCell>
                              <TableCell>{item.name ? item.name : t('common.notDataContract')}</TableCell>
                              <TableCell>
                                {item ? item.asset_inventory_start_term : t('common.notDataContract')}
                              </TableCell>
                              <TableCell>
                                {formatNumberCurrency(item?.price_inventory_start_term)?.length > MAX_LENGTH_PRICE ? (
                                  <Tooltip title={formatNumberCurrency(item?.price_inventory_start_term)} arrow>
                                    <span>
                                      {formatNumberCurrency(item?.price_inventory_start_term).slice(
                                        START_STRING_SLICE,
                                        MAX_LENGTH_PRICE,
                                      ) + '...'}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  formatNumberCurrency(item?.price_inventory_start_term)
                                )}
                              </TableCell>
                              <TableCell>
                                {item ? item.asset_import_in_term : t('common.notDataContract')}
                              </TableCell>
                              <TableCell>
                                {formatNumberCurrency(item?.total_price_import_in_term)?.length > MAX_LENGTH_PRICE ? (
                                  <Tooltip title={formatNumberCurrency(item?.total_price_import_in_term)} arrow>
                                    <span>
                                      {formatNumberCurrency(item?.total_price_import_in_term).slice(
                                        START_STRING_SLICE,
                                        MAX_LENGTH_PRICE,
                                      ) + '...'}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  formatNumberCurrency(item?.total_price_import_in_term)
                                )}
                              </TableCell>
                              <TableCell>
                                {item ? item.asset_export_in_term : t('common.notDataContract')}
                              </TableCell>
                              <TableCell>
                                {formatNumberCurrency(item?.total_price_export_in_term)?.length > MAX_LENGTH_PRICE ? (
                                  <Tooltip title={formatNumberCurrency(item?.total_price_export_in_term)} arrow>
                                    <span>
                                      {formatNumberCurrency(item?.total_price_export_in_term).slice(
                                        START_STRING_SLICE,
                                        MAX_LENGTH_PRICE,
                                      ) + '...'}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  formatNumberCurrency(item?.total_price_export_in_term)
                                )}
                              </TableCell>
                              <TableCell>
                                {uExportImportInventory ? item.asset_inventory_end_term : t('common.notDataContract')}
                              </TableCell>
                              <TableCell className="breakWord">
                                {formatNumberCurrency(item?.price_inventory_end_term)?.length > MAX_LENGTH_PRICE ? (
                                  <Tooltip title={formatNumberCurrency(item?.price_inventory_end_term)} arrow>
                                    <span>
                                      {formatNumberCurrency(item?.price_inventory_end_term).slice(
                                        START_STRING_SLICE,
                                        MAX_LENGTH_PRICE,
                                      ) + '...'}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  formatNumberCurrency(item?.price_inventory_end_term)
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}

                      {/* No data */}
                      {uExportImportInventory?.category_filter?.total === 0 && (
                        <tr>
                          <td colSpan={10} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {uExportImportInventory?.category_filter?.total > 0 && <TablePagination
                  rowsPerPageOptions={Constants.PER_PAGE_OPTION}
                  component="div"
                  count={uExportImportInventory?.category_filter?.total}
                  rowsPerPage={filter?.per_page}
                  page={filter?.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="table-pagination"
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                />}
              </Paper>
            </div>
          </Grid>

          {/* Right side */}
          <Grid item md={3}>
            <div className={classNames('box-waiting', isLoadingExportImportInventory && 'added-padding')}>
              {isLoadingExportImportInventory && <Loading />}
              {!isLoadingExportImportInventory &&
                LIST_BOX_RIGHT.map((item, index) => (
                  <div key={index} className="box-waiting-content">
                    <img src={iconInventory} alt={iconInventory} />
                    <div className="box-waiting-text">
                      <div className="waiting-text">{item?.name}</div>
                      <div className="waiting-number">{item?.value}</div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="box-filter">
              {/* Assets date search */}
              <div className={classNames(`text`)}>
                <DateDayMonthComponent
                  label=""
                  name="date"
                  valueDefault={typeof filter.date === 'string' ? moment(filter.date, 'MM/YYYY') : filter.date}
                  onChangeHandle={(value) => {
                    setFilter({
                      ...filter,
                      date: moment(value).format('MM/YYYY'),
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  placeholder={t('assets.dateAdded')}
                  sizeDate="small"
                  max={new Date()}
                />
              </div>

              {/* Assets category search */}
              <div className={classNames(`padding-element-top`)}>
                <FormControl component="fieldset">
                  <FormLabel className={classNames(`text padding-element-filter border-top padding-element-top`)}>
                    {t('assets.assetsListRequests.category')}
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup {...field} value={filter?.asset_category_id ? filter?.asset_category_id : 0}>
                        <FormControlLabel
                          value={0}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              asset_category_id: null,
                              page: Constants.DEFAULT_PAGE_MUI,
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          control={
                            <Radio
                              sx={{
                                color: COLOR_CHECKBOX_NOT_ACTIVE,
                                '&.Mui-checked': {
                                  color: COLOR_CHECKBOX_CUSTOM,
                                },
                              }}
                            />
                          }
                          label={t('employees.all')}
                        />
                        {isLoadingExportImportInventory && <Loading />}
                        {uExportImportInventory?.categories?.map((item, index) => (
                          <div key={index} className="division">
                            <FormControlLabel
                              key={index}
                              value={item?.id}
                              onChange={(e) => {
                                setFilter({
                                  ...filter,
                                  asset_category_id: e.target.value,
                                  page: Constants.DEFAULT_PAGE_MUI,
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                              }}
                              control={
                                <Radio
                                  sx={{
                                    color: COLOR_CHECKBOX_NOT_ACTIVE,
                                    '&.Mui-checked': {
                                      color: COLOR_CHECKBOX_CUSTOM,
                                    },
                                  }}
                                />
                              }
                              label={item?.name}
                            />
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                    name="asset_category_id"
                    control={control}
                    defaultValue={filter?.asset_category_id ? filter?.asset_category_id : 0}
                  />
                </FormControl>
              </div>
              <div className="border-top">
                <ButtonBase onClick={() => handleReset()} className={classNames(`reset border-top`)} size="lg">
                  <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
                  {t('common.reset')}
                </ButtonBase>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ExportImportInventory;
