import React, { useState } from 'react';
import { customDate } from 'helpers/formatDate';
import { message } from 'antd';
import pdfIcon from 'assets/img/icons/pdf.png';
import delIcon from 'assets/img/icons/recycle.png';
import downloadIcon from 'assets/img/icons/download.png';
import UploadCommitFile from './UploadCommitFile';
import { DATE_FORMAT } from '..';
import { useMutation } from 'react-query';
import { apiDeleteCommitFile } from 'api/training';
import { apiDeleteMemberFromCourse } from 'api/training';
import { HTTP_OK } from 'constants/StatusCode';
import { showConfirm } from '..';
import { sendCustom } from 'helpers/axios';
const MemberInfo = ({ member, refetch, t , course_name }) => {
    const deleteCommitFileMutation = useMutation(apiDeleteCommitFile, {
        onSuccess: (res) => {
            const messageText = res?.data?.messages;
            const status = res?.status;

            if (status === HTTP_OK) {
                message.success(messageText);
            } else {
                message.error(messageText);
            }
            refetch();
        },
    });
    const deleteMemberMutation = useMutation(apiDeleteMemberFromCourse, {
        onSuccess: (res) => {
            const messageText = res?.data?.messages;
            const status = res?.status;

            if (status === HTTP_OK) {
                message.success(messageText);
            } else {
                message.error(messageText);
            }
            refetch();
        },
    });
    const [isHasCommitFile, setIsHasCommitFile] = useState(!!member.file);
    const handleDelete = () => {
        deleteCommitFileMutation.mutate(member.user_course_id)
        setIsHasCommitFile(false);
    }
    const downloadCommitFile = async (url, fileName) => {
        const res = await sendCustom({
            url,
            responseType: 'blob',
          });
          const blob = new Blob([res.data], { type: res.data.type });
          const link = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.setAttribute('download', fileName);
          a.setAttribute('href', link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
    };
    return (
        <div className='flex member-info'>
            <div className='flex' style={{ flexBasis: '80%', gap: '40px' }}>
                <div className="avatar-container">
                    <img style={{ borderRadius: '50px' }} src={member.avatar} width="63" height="63" alt="" />
                    <span className="delete-icon"><img src={delIcon} width="48" height="48" alt=""
                        onClick={() =>
                            showConfirm({
                                content: t('training.doYouWantDeleteThisMember'),
                                width: 328,
                                onOk: () => deleteMemberMutation.mutate(member.user_course_id),
                            })
                        }
                    /></span>
                </div>
                <div className='flex flex-col '>
                    <p style={{ color: '#223354' }} className='mb-1 title '>{member.name}</p>
                    <span style={{ color: '#8D8D8D' }}>{member.email}</span>
                    <span style={{ color: '#FEA628' }}>
                        {customDate(member.start_date, DATE_FORMAT)}~{customDate(member.end_date, DATE_FORMAT)}
                    </span>
                </div>
            </div>
            <div style={{ cursor: 'pointer' }} className='flex flex-col items-center'>
                <img src={pdfIcon} alt="" />
                <div className='flex'>
                    {isHasCommitFile ? (
                        <>
                            <img
                                src={delIcon}
                                width={24}
                                height={24}
                                alt=""
                                onClick={() => {
                                    handleDelete(member.user_course_id);
                                }}
                            />
                            <img src={downloadIcon} width={24} height={23} alt="" onClick={()=>downloadCommitFile(`/api/course-user/${member.user_course_id}/commit-file?name=${member.name}_Cam ket dao tao.pdf`,`${member.name}_${course_name}_Cam kết đào tạo.pdf`)}/>
                        </>
                    ) : (
                        <UploadCommitFile refetch={refetch} id={member.user_course_id} setIsHasCommitFile={setIsHasCommitFile} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MemberInfo;
