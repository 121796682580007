import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';

import SearchComponent from 'components/Search';
import ButtonComponent from 'components/Buttons';

import defaultAvatar from 'assets/img/icons/default-avatar.svg';
import { handleOnError } from 'helpers';
import defaultDevice from 'assets/img/icons/default-device.svg';
import Loading from 'components/Loading';

import styles from './style.module.scss';

import { useSearchDashboard } from 'hook/useDashboard';
import { formatNumberCurrency } from 'helpers/format';
import { MAX_LENGTH_20 } from 'constants/index.js';
import { ROLE_NOT_VIEW } from 'constants/index.js';

const menu = {
  ALL: 1,
  EMPLOYEE: 2,
  EVENT: 3,
  ASSET: 4,
  DIVISION: 5,
};

const SHOW = 6;

const Search = ({ key_word, dataInforUser }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({ key_word });
  const [selectMenu, setMenu] = useState(menu.ALL);
  const [showAllEmployee, setShowAllEmployee] = useState(false);
  const [showAllAsset, setShowAllAsset] = useState(false);
  const [showAllDivision, setShowAllDivision] = useState(false);
  // eslint-disable-next-line
  const [showAllEvent, setShowAllEvent] = useState(false);
  const [dataUser, setDataUser] = useState('');

  const handleSearch = (value) => {
    setFilter({ key_word: value });
  };

  const { data, isLoading } = useSearchDashboard(filter);

  const assetStatus = {
    1: t('dashboard.good'),
    2: t('dashboard.maintainer'),
    3: t('dashboard.waitSelling'),
    4: t('dashboard.sell'),
    5: t('dashboard.break'),
    6: t('dashboard.lost'),
  };

  useEffect(() => {
    setDataUser(dataInforUser);
  }, [dataInforUser]);

  useEffect(() => {
    switch (selectMenu) {
      case menu.EMPLOYEE:
        setShowAllEmployee(true);
        break;
      case menu.ASSET:
        setShowAllAsset(true);
        break;
      case menu.DIVISION:
        setShowAllDivision(true);
        break;
      case menu.EVENT:
        setShowAllEvent(true);
        break;

      default:
        setShowAllEmployee(false);
        setShowAllAsset(false);
        setShowAllDivision(false);
        setShowAllEvent(false);
        break;
    }
  }, [selectMenu]);

  return (
    <>
      <div className={styles.filterResult}>
        <SearchComponent
          txtPlaceholder={t('dashboard.search')}
          addClass="input-search-box mr-2 text-input-bold"
          handleSearch={handleSearch}
          defaultValue={filter?.key_word}
          maxLength={MAX_LENGTH_20}
        />
        {dataUser && (
          <ul className={styles.menu}>
            <li
              className={classNames(styles.menuItem, { [styles.active]: selectMenu === menu.ALL })}
              onClick={() => setMenu(menu.ALL)}
            >
              {t('dashboard.all')}
            </li>
            {dataUser && dataUser?.hr_permission?.users_permission !== ROLE_NOT_VIEW && (
              <li
                className={classNames(styles.menuItem, { [styles.active]: selectMenu === menu.EMPLOYEE })}
                onClick={() => setMenu(menu.EMPLOYEE)}
              >
                {t('dashboard.employee')}
              </li>
            )}

            {/* <li
              className={classNames(styles.menuItem, { [styles.active]: selectMenu === menu.EVENT })}
              onClick={() => setMenu(menu.EVENT)}
            >
              {t('dashboard.event')}
            </li> */}
            {dataUser && dataUser?.hr_permission?.assets_permission !== ROLE_NOT_VIEW && (
              <li
                className={classNames(styles.menuItem, { [styles.active]: selectMenu === menu.ASSET })}
                onClick={() => setMenu(menu.ASSET)}
              >
                {t('dashboard.asset')}
              </li>
            )}
            {dataUser && dataUser?.hr_permission?.divisions_permission !== ROLE_NOT_VIEW && (
              <li
                className={classNames(styles.menuItem, { [styles.active]: selectMenu === menu.DIVISION })}
                onClick={() => setMenu(menu.DIVISION)}
              >
                {t('dashboard.division')}
              </li>
            )}
          </ul>
        )}
        {(selectMenu === menu.ALL || selectMenu === menu.EMPLOYEE) &&
          dataUser &&
          dataUser?.hr_permission?.users_permission !== ROLE_NOT_VIEW && (
            <div className={styles.searchItem}>
              <div className={styles.header}>{t('dashboard.employee')}</div>
              <div className={styles.body}>
                {isLoading && <Loading />}
                {!isLoading && !data?.users?.length && <div className="error-no-data">{t('common.notData')}</div>}

                <div className={styles.employees}>
                  {data?.users?.slice(0, showAllEmployee ? data?.users?.length : SHOW)?.map((user) => (
                    <NavLink
                      to={{
                        pathname: '/admin/employees/profile/' + user?.user_id,
                      }}
                      className={`${styles.link} ${styles.blockEmployee}`}
                    >
                      <div className={styles.employee} key={user.id}>
                        <img
                          onError={(e) => {
                            e.target.src = defaultAvatar;
                          }}
                          src={user.avatar || defaultAvatar}
                          alt=""
                        />
                        <div>
                          <div className={`${styles.name} ${styles.link}`}>{user.name}</div>
                          <div className={`${styles.email} ${styles.link}`}>
                            {user.position_name} - {user.division_name}
                          </div>
                          <div className={`${styles.email} ${styles.link}`}>{user.email}</div>
                        </div>
                      </div>
                    </NavLink>
                  ))}
                </div>
              </div>

              {!isLoading && data?.users?.length > SHOW && !showAllEmployee && (
                <div className={styles.footer}>
                  <ButtonComponent
                    text={t('dashboard.viewAll')}
                    handleClick={() => setMenu(menu.EMPLOYEE)}
                    addClass="btnViewAll"
                  />
                </div>
              )}
            </div>
          )}

        {/* {(selectMenu === menu.ALL || selectMenu === menu.EVENT) && (
          <div className={styles.searchItem}>
            <div className={styles.header}>{t('dashboard.event')}</div>
            <div className={styles.body}>
              <div className={styles.events}>
                <div className={styles.wrapGutter}>
                  <div className={styles.event}>
                    <img src={defaultEvent} alt="" />
                    <div className={styles.body}>
                      <div className={styles.time}>THỨ 7, 1 THÁNG 10 - 2 THÁNG 10</div>
                      <div className={styles.title}>Hội nghị giảng dạy vật long toàn quốc - lần thứ 5</div>
                      <div className={styles.numberRegister}>290 người đăng ký</div>

                      <div className={styles.btnRegister}>
                        <ButtonComponent text={t('dashboard.register')} handleClick={() => {}} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.wrapGutter}>
                  <div className={styles.event}>
                    <img src={defaultEvent} alt="" />
                    <div className={styles.body}>
                      <div className={styles.time}>THỨ 7, 1 THÁNG 10 - 2 THÁNG 10</div>
                      <div className={styles.title}>Hội nghị giảng dạy vật long toàn quốc - lần thứ 5</div>
                      <div className={styles.numberRegister}>290 người đăng ký</div>

                      <div className={styles.btnRegister}>
                        <ButtonComponent text={t('dashboard.register')} handleClick={() => {}} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.wrapGutter}>
                  <div className={styles.event}>
                    <img src={defaultEvent} alt="" />
                    <div className={styles.body}>
                      <div className={styles.time}>THỨ 7, 1 THÁNG 10 - 2 THÁNG 10</div>
                      <div className={styles.title}>Hội nghị giảng dạy vật long toàn quốc - lần thứ 5</div>
                      <div className={styles.numberRegister}>290 người đăng ký</div>

                      <div className={styles.btnRegister}>
                        <ButtonComponent text={t('dashboard.register')} handleClick={() => {}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!showAllEvent && (
              <div className={styles.footer}>
                <ButtonComponent
                  text={t('dashboard.viewAll')}
                  handleClick={() => setMenu(menu.EVENT)}
                  addClass="btnViewAll"
                />
              </div>
            )}
          </div>
        )} */}

        {(selectMenu === menu.ALL || selectMenu === menu.ASSET) &&
          dataUser &&
          dataUser?.hr_permission?.assets_permission !== ROLE_NOT_VIEW && (
            <div className={styles.searchItem}>
              <div className={styles.header}>{t('dashboard.asset')}</div>
              <div className={styles.body}>
                {isLoading && <Loading />}
                {!isLoading && !data?.assets?.length && <div className="error-no-data">{t('common.notData')}</div>}

                <div className={styles.assets}>
                  {data?.assets?.slice(0, showAllAsset ? data?.assets?.length : SHOW)?.map((asset) => (
                    <div className={styles.asset} key={asset.id}>
                      <img src={defaultDevice} alt="" />
                      <div>
                        <div className={styles.name}>{asset.name}</div>
                        <div className={styles.status}>
                          {asset.code} - {t('dashboard.status')}{' '}
                          <span className={styles.type}>{assetStatus[asset.type]}</span> - {asset.warehouse_name}
                        </div>
                        <div className={styles.value}>
                          {formatNumberCurrency(asset.price)}
                          {t('common.VND')}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {!isLoading && data?.assets?.length > SHOW && !showAllAsset && (
                <div className={styles.footer}>
                  <ButtonComponent
                    text={t('dashboard.viewAll')}
                    handleClick={() => setMenu(menu.ASSET)}
                    addClass="btnViewAll"
                  />
                </div>
              )}
            </div>
          )}

        {(selectMenu === menu.ALL || selectMenu === menu.DIVISION) &&
          dataUser &&
          dataUser?.hr_permission?.divisions_permission !== ROLE_NOT_VIEW && (
            <div className={styles.searchItem}>
              <div className={styles.header}>{t('dashboard.division')}</div>
              <div className={styles.body}>
                {isLoading && <Loading />}
                {!isLoading && !data?.divisions?.length && <div className="error-no-data">{t('common.notData')}</div>}

                <div className={styles.divisions}>
                  {data?.divisions?.slice(0, showAllDivision ? data?.divisions?.length : SHOW)?.map((division) => (
                    <div className={styles.division} key={division.id}>
                      <img onError={handleOnError} src={division.avatar || defaultAvatar} alt="" />
                      <div>
                        <div className={styles.name}>{division.name}</div>
                        <div className={styles.manager}>
                          {t('dashboard.manager')}:{' '}
                          <Link to="#" className={styles.user}>
                            {division.manager_name}
                          </Link>
                        </div>
                        <div className={styles.totalMember}>
                          {t('dashboard.totalMember')}: {division.total_member}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {!isLoading && data?.divisions?.length > SHOW && !showAllDivision && (
                <div className={styles.footer}>
                  <ButtonComponent
                    text={t('dashboard.viewAll')}
                    handleClick={() => setMenu(menu.DIVISION)}
                    addClass="btnViewAll"
                  />
                </div>
              )}
            </div>
          )}
      </div>
    </>
  );
};

export default React.memo(Search);
