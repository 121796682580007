/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import classNames from 'classnames';
import { CATEGORY_CERTIFICATE, MANAGER_CERTIFICATE } from 'constants/MasterData';
import './style.scss';

const EditRole = (props) => {
  let { t } = useTranslation();
  const { type, id } = props;

  return (
    <Box sx={{ flexGrow: 1 }} className="master-data-edit-role">
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" className="bread-crumbs">
          <NavLink to={{ pathname: '/admin/master-data/role' }}>{t('masterData.role')}</NavLink>
          <Typography color="text.primary" className="page-current">
            {t('masterData.roleMasterData.detail')}
          </Typography>
        </Breadcrumbs>
      </div>
      <div className='nav-role'>
        <ul className="navbar-master-data-certificate">
          <li>
            <NavLink to={{
              pathname: '/admin/master-data/detail-users',
              state: {
                id: id
              }
            }} className={classNames(`link ${type === CATEGORY_CERTIFICATE && 'isActive'}`)}>
              <span className={classNames(`navItem text-bold`)}>{t('masterData.roleMasterData.member')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={{
              pathname: '/admin/master-data/detail-role',
              state: {
                id: id
              }
            }} className={classNames(`link ${type === MANAGER_CERTIFICATE && 'isActive'}`)}>
              <span className={classNames(`navItem text-bold`)}>{t('masterData.roleMasterData.role')}</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </Box>
  );
};

export default React.memo(EditRole);
