import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import $ from 'jquery';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import AlertComponent from 'components/Alert';
import ProgressBar from 'components/ProgressBar/index.js';
import Import from 'assets/img/icons/import.png';
import IconImport from 'assets/img/icons/icon-import.png';
import Invalid from 'assets/img/icons/file-import-invalid.png';
import Illegal from 'assets/img/icons/file-import-illegal.png';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_INTERNAL_SERVER_ERROR, HTTP_EXPECTATION_FAILED, HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import { USE_LIST_ASSETS, USE_LIST_CATEGORY_ASSETS, USE_LIST_TYPE_ASSETS } from 'constants/Assets';
import { apiDownloadTemplateImportAssets, apiImportAssets, apiImportAssetsForRoleEdit } from 'api/assets';

import styles from './style.module.scss';

const ImportEmployees = (props) => {
  const {
    handleSubmit,
  } = useForm({});
  const queryClient = useQueryClient();
  const { toggle, modal, isApprove } = props;
  const { t } = useTranslation();
  const [textModal] = useState({
    titleEdit: t('assets.importListAssets'),
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState([]);

  const importText = t('common.import');
  const [progress, setProgress] = useState(0);
  const [isShowAlert, setShowAlert] = useState(false);
  const [notifiUpload, setNotifiUpload] = useState({ status: importText, name: '', file: '' });
  const [stateButton, setStateButton] = useState(false);

  useEffect(() => {
    setProgress(0);
    setError([]);
    setNotifiUpload({ status: importText, name: '', file: '' });
    $('#imageImport').val('');
  }, [modal, importText]);

  const onSubmit = async (event) => {
    setStateButton(true);
    if (!notifiUpload.file) {
      setStateButton(false);
      setNotifiUpload({ ...notifiUpload, error_file: t('common.pleaseFillOutThisFieldImport') });
      return;
    }
    setNotifiUpload({ ...notifiUpload, status: t('common.importing') });
    const fb = new FormData();
    fb.append('file', notifiUpload.file);

    let response = {};
    if (isApprove) {
      response = await apiImportAssets(fb);
    } else {
      response = await apiImportAssetsForRoleEdit(fb);
    }

    if (
      (Number(response?.data?.status) === HTTP_UNPROCESSABLE_ENTITY ||
        Number(response?.data?.status) === HTTP_INTERNAL_SERVER_ERROR || Number(response?.data?.status) === HTTP_EXPECTATION_FAILED) &&
      response?.data?.messages
    ) {
      setError(response?.data?.messages);
      setNotifiUpload({ ...notifiUpload, file: '', name: '', status: importText });
      $('#imageImport').val('');
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setProgress(100);
      $('#imageImport').val('');
      setNotifiUpload({ status: importText, name: '', file: '' });
      await queryClient.invalidateQueries(USE_LIST_ASSETS);
      await queryClient.invalidateQueries(USE_LIST_CATEGORY_ASSETS);
      await queryClient.invalidateQueries(USE_LIST_TYPE_ASSETS);
      setInterval(success(response), 1000);
    }
    setStateButton(false);
  };

  const success = async (response) => {
    toggle(false);
    setMessage(response.data.messages);
    setShowAlert(true);
    setStateButton(false);
  };

  const handleUpload = (event) => {
    if (event.target.files[0]) {
      setError([]);
      setNotifiUpload({
        ...notifiUpload,
        name: event.target.files[0].name,
        file: event.target.files[0],
        error_file: '',
      });
    }
  };

  const dowloadTemplate = async () => {
    var response = await apiDownloadTemplateImportAssets();
    const url = await window.URL.createObjectURL(new Blob([response.data], { type: `application/octet-stream` }));
    const link = document.createElement('a');
    link.href = url;
    link.download = t('assets.templateImport');
    link.click();
    link.remove();
  };

  return (
    <>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
      <ModalComponent toggle={() => toggle(false)} isShowModal={modal} classNameAdd={styles.importUser} title={textModal.titleEdit}>
        <Box className="mt-3" onSubmit={handleSubmit(onSubmit)}>
          <div className={`fieldset-group mt-4 ${styles.import}`}>
            <input
              type="file"
              id="imageImport"
              name="file_import[]"
              onChange={(event) => handleUpload(event)}
              style={{ display: 'none' }}
              accept=".xlsx"
            />
            <label htmlFor="imageImport" className={styles.labelImport}>
              <img src={IconImport} alt="Import" className={`${styles.iconImport} ${styles.infor}`} />
              <p className={`${styles.notifi} ${styles.infor}`} >{t('employees.notificationImport')}</p>
              <p className={`${styles.format} ${styles.infor}`} >{t('employees.formatImport')}</p>
              <img src={Import} alt="Import" className={styles.imgImport} />
            </label>
          </div>
          <div className="fieldset-group mt-3">
            <p className={styles.downloadTemplate} onClick={dowloadTemplate}>
              {t('employees.notificationImportUser')}
            </p>
            <label className={styles.inforImport}>{' ' + notifiUpload.status + ': ' + notifiUpload.name} </label>
            <span className={styles.progress}>{progress}%</span>
            <ProgressBar className={styles.progressImport} bgcolor="#2ECC71" completed={progress} />
          </div>
          {notifiUpload?.error_file && <p className={styles.error}>{notifiUpload?.error_file}</p>}
          {Array.isArray(error) &&
            error.length > 0 &&
            error.map((item, i) => {
              return (
                <p key={i} className={styles.error}>
                  {
                    <img
                      src={Array.isArray(error) && error.length > 0 ? Illegal : Invalid}
                      alt="FileInvalid"
                      className={styles.fileInvalid}
                    />
                  }
                  {item.line &&
                    item.message &&
                    t('common.line') +
                    ' ' +
                    item.line +
                    ', ' +
                    t('common.column') +
                    ' ' +
                    item.column +
                    ': ' +
                    item.message}
                  {!item.line && !item.message && item}
                </p>
              );
            })}
          {!Array.isArray(error) && (
            <p className={styles.error}>
              {<img src={Illegal} alt="FileInvalid" className={styles.fileInvalid} />}
              {error.field && error.field}
            </p>
          )}
          <div className={styles.footerModal}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <ButtonComponent text={t('common.cancel')} addClass="w-100 btn-bg-yellow2" handleClick={() => toggle(false)} />
              </Grid>
              <Grid item xs={6}>
                <ButtonComponent
                  text={t('common.import')}
                  addClass="w-100"
                  type="submit"
                  handleClick={handleSubmit(onSubmit)}
                  hasDisabled={stateButton}
                />
              </Grid>
            </Grid>
          </div>
        </Box>
      </ModalComponent>
    </>
  );
};

export default ImportEmployees;
