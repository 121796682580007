export const USE_TIME_SHEET = 'USE_TIME_SHEET';
export const USE_TOTAL_TIME_SHEET = 'USE_TOTAL_TIME_SHEET';

export const USE_GET_TIME_SHEET_LIST = 'USE_GET_TIME_SHEET_LIST';
export const USE_GET_TIME_SHEET_TOTAL = 'USE_GET_TIME_SHEET_TOTAL';
export const USE_LIST_ALL_DIVISION = 'USE_LIST_ALL_DIVISION';

export const USE_LIST_REQUEST_INDIVIDUAL = 'USE_LIST_REQUEST_INDIVIDUAL';
export const USE_LIST_OT_INDIVIDUAL = 'USE_LIST_OT_INDIVIDUAL';
export const USE_LIST_TYPE_REQUEST_INDIVIDUAL = 'USE_LIST_TYPE_REQUEST_INDIVIDUAL';
export const USE_LIST_TYPE_OT_INDIVIDUAL = 'USE_LIST_TYPE_OT_INDIVIDUAL';
export const USE_TIMEKEEPING_TOTAL_YEAR = 'USE_TIMEKEEPING_TOTAL_YEAR';
export const USE_TIMEKEEPING_TOTAL_MONTH = 'USE_TIMEKEEPING_TOTAL_MONTH';
export const USE_TIMEKEEPING_LATE_OT_COMPANY = 'USE_TIMEKEEPING_LATE_OT_COMPANY';
export const USE_TIMEKEEPING_LATE_COMPANY = 'USE_TIMEKEEPING_LATE_COMPANY';
export const USE_TIMEKEEPING_OVERTIME_PROJECT = 'USE_TIMEKEEPING_OVERTIME_PROJECT';
export const USE_LIST_TYPE_REQUEST_BY_CATEGORY = 'USE_LIST_TYPE_REQUEST_BY_CATEGORY';
export const USE_LIST_OT_ALL_COMPANY = 'USE_LIST_OT_ALL_COMPANY';
export const USE_TIMEKEEPING_LIST_CATEGORY_EMPLOYEES = 'USE_TIMEKEEPING_LIST_CATEGORY_EMPLOYEES';
export const USE_CHECK_IS_LOCKED = 'USE_CHECK_IS_LOCKED';
export const USE_GET_SCHEDULE = 'USE_GET_SCHEDULE';
export const USE_GET_HOLIDAY_SCHEDULE_REQUEST_LEADER = 'USE_GET_HOLIDAY_SCHEDULE_REQUEST_LEADER';

export const DEFAULT_COMPLETE_TIMESHEETS = -1;
export const COMPLETED_TIMESHEETS = 1;
export const NOT_COMPLETE_TIMESHEETS = 0;

export const LENGTH_NO_BORDER = 1;
export const LENGTH_TO_DISPLAY_TOOLTIP = 1;
export const LENGTH_TO_REMOVE_BORDER_TOOLTIP = 2;

export const APPROVE = 1;
export const NOT_APPROVED = 2;
export const CATEGORY_ID_LIST_REQUEST = 7;
export const CATEGORY_ID_LIST_OT = 8;

export const LEAVE_OF_ABSENCE = 3;
export const UNAUTHORIZED_ABSENCES = 2;

export const REQUEST_TYPE_MORNING_SHIFT = 1;
export const REQUEST_TYPE_AFTERNOON_SHIFT = 2;
export const REQUEST_TYPE_ALL_DAY = 3;

export const NOT_DATA = -1;
export const NOT_LATE = 0;

export const IS_LOCKED = 1;
export const IS_OPENED = 0;
