import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import ReactLoading from 'react-loading';
import { CssAutoCompleteField } from './constant';
import avatarDefault from 'assets/img/icons/avatar_default.svg';
import { OPTION_ALL } from 'constants/index.js';
import { handleOnError } from 'helpers';

function CustomAutoCompleteCheckAll({
  valueAutoFill,
  control,
  name,
  label,
  setValue,
  defaultValue,
  handleChange,
  limitTags,
  keyClose,
  handleSearch,
  isLoading,
  onBlur,
  addItemsDelete,
  addDeleteAllItem,
  positionId,
  contractCategory,
}) {
  const { t } = useTranslation();
  const [tags, setTags] = useState({
    tags: defaultValue || [],
  });

  const [selectedValue, setSelectedValue] = useState(defaultValue ? defaultValue : []);
  const [datas, setDatas] = useState([]);

  const handleDelete = (item) => {
    let datas = [];
    addItemsDelete(item.user_id);
    tags?.tags?.forEach((it) => {
      if (item.user_id !== it.user_id) {
        datas.push(it);
      }
    });
    setTags({
      tags: datas,
    });
    // Func added when change
    if (handleChange) {
      handleChange(datas);
    }
    setSelectedValue(datas);
  };

  const onTagsChange = (e, values, reason, detail) => {
    let checkAll = detail?.option?.user_id === 0 ? true : false;
    if ((reason === 'removeOption' && checkAll) || reason === 'clear') {
      // Delete all item when click clear all
      if (reason === 'clear' && addDeleteAllItem) {
        const result = tags?.tags?.map((item) => item?.user_id || item?.id);
        addDeleteAllItem(result);
      }
      setTags({
        tags: [],
      });
      // Func added when change
      if (handleChange) {
        handleChange([]);
      }
      setSelectedValue([]);
    } else {
      if (checkAll) {
        setTags({
          tags: datas,
        });
        // Func added when change
        if (handleChange) {
          handleChange(datas);
        }
        setSelectedValue(datas);
      } else {
        setTags({
          tags: values,
        });
        // Func added when change
        if (handleChange) {
          handleChange(values);
        }
        setSelectedValue(values);
        values = [];
      }
    }
  };
  useEffect(() => {
    let transformed = tags?.tags?.map((item) => item?.user_id);
    setValue(name, transformed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  // Set tags to [] when have "keyClose" --> set default value to []
  useEffect(() => {
    if (keyClose !== undefined && keyClose === false) {
      setTags({
        tags: [],
      });
    }
  }, [keyClose]);

  useEffect(() => {
    if (name === 'user_id' && valueAutoFill?.length > 0) {
      setDatas([{ name: t('common.all'), user_id: 0 }, ...valueAutoFill]);
      if (positionId?.length > 0 || contractCategory?.length > 0) {
        setTags({
          tags: [{ name: t('common.all'), user_id: 0 }, ...valueAutoFill],
        });

        // Func added when change
        if (handleChange) {
          handleChange([{ name: t('common.all'), user_id: 0 }, ...valueAutoFill]);
        }
        setSelectedValue([{ name: t('common.all'), user_id: 0 }, ...valueAutoFill]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueAutoFill]);

  return (
    <Controller
      render={() => (
        <Autocomplete
          key={keyClose}
          multiple
          limitTags={limitTags ? limitTags : 3}
          options={datas}
          getOptionLabel={(option) => {
            if (datas?.length) {
              if (option.user_id === OPTION_ALL) {
                return option?.name;
              }
              return (
                <>
                  <div className="flex">
                    <img
                      onError={handleOnError}
                      src={option.avatar ? option.avatar : avatarDefault}
                      alt={option.avatar}
                      className="avatar"
                    />
                    <div>
                      <p>{option?.name}</p>
                      <p className="email">{option?.email}</p>
                    </div>
                  </div>
                </>
              );
            }
          }}
          renderTags={() =>
            tags?.tags?.map((option) => {
              return option.user_id > 0 && <Chip label={option.name} onDelete={() => handleDelete(option)} />;
            })
          }
          value={tags?.tags}
          onChange={(e, values, reason, detail) => onTagsChange(e, values, reason, detail)}
          defaultValue={tags?.tags}
          filterOptions={(options) =>
            options?.filter(
              (optionFilter) =>
                !selectedValue.find((el) => {
                  if (el.user_id) {
                    return el.user_id === optionFilter.user_id;
                  } else if (el.id) {
                    return el.id === optionFilter.id;
                  }
                  return false;
                }),
            )
          }
          loading={isLoading}
          loadingText={<ReactLoading type="spinningBubbles" color={'#ffdba9'} height={25} width={25} />}
          renderInput={(params) => (
            <CssAutoCompleteField
              {...params}
              label={label}
              onChange={(e) => {
                if (handleSearch) {
                  handleSearch(e.target.value);
                }
              }}
              onBlur={onBlur && onBlur}
              labelspace={tags?.tags?.length === 0 ? '3px' : 0}
            />
          )}
        />
      )}
      control={control}
      name={name}
    ></Controller>
  );
}

export default CustomAutoCompleteCheckAll;
