import React, { useEffect, useState } from 'react';
import moment from 'moment';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import { TextFieldDate as TextFieldDateMonth, TextFieldNoneDate as TextFieldNoneDateMonth } from './style.js';
import { useForm, useWatch } from 'react-hook-form';
import { CssDateField } from 'components/InputYupAndMui/constant.js';

const FORMAT = 'YYYY';

const DateYear = (props) => {
    const { control } = useForm();

    const { label, valueDefault, onChangeHandle, name, error, isRequired, placeholder, sizeDate, min, max, addClass } =
        props;
    const [value, setValue] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setValue(valueDefault);
    }, [valueDefault]);

    const handleChangeDate = (newValue) => {
        onChangeHandle(newValue);
    };

    // Watch value of input date
    const valueWatch = useWatch({
        control,
        name: name,
    });

    return (
        <>
            <CssDateField labelspace={sizeDate ? '-1px' : valueWatch ? 0 : '6px'}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        name={name}
                        open={open}
                        inputFormat="yyyy"
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        views={['year']}
                        openTo="year"
                        value={value}
                        label={
                            <React.Fragment>
                                {placeholder && !value ? placeholder : label}
                                {isRequired && label ? <sup>*</sup> : ''}
                            </React.Fragment>
                        }
                        minDate={
                            min?.toString() === 'Invalid Date' || min === null || min === undefined
                                ? moment('1/1/1900', 'yyyy').toDate()
                                : new Date(`${min}`)
                        }
                        maxDate={max ? new Date(`${max}`) : null}
                        onChange={handleChangeDate}
                        renderInput={(params) => {
                            if (label) {
                                return (
                                    <TextFieldDateMonth
                                        {...params}
                                        error={error ? true : false}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{
                                            height: sizeDate && '14px',
                                        }}
                                        size={sizeDate ? sizeDate : ''}
                                        value={value ? moment(value).format(FORMAT) : null}
                                        placeholder={placeholder}
                                    />
                                );
                            }
                            return (
                                <TextFieldNoneDateMonth
                                    {...params}
                                    error={error ? true : false}
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                    sx={{
                                        height: sizeDate && '14px',
                                    }}
                                    size={sizeDate ? sizeDate : ''}
                                    value={value ? moment(value).format(FORMAT) : ''}
                                    placeholder={placeholder}
                                    className={addClass}
                                />
                            );
                        }}
                    />
                </LocalizationProvider>
            </CssDateField>
            <p className="error-input error">{error}</p>
        </>
    );
};

export default DateYear;
