import React, { useState, useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Bar } from 'react-chartjs-2';

import Assets from './../index.js';
import YearPicker from 'components/DateTime/Year';

import chartIcon from 'assets/img/icons/chart-icon.svg';
import chartIcon2 from 'assets/img/icons/chart-icon2.svg';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';

import styles from './styles.module.scss';
import { useGetAssetDashboard } from 'hook/useAssets.js';
import { useGetAssetCategory } from 'hook/useAssets.js';
import { useGetAssetDivision } from 'hook/useAssets.js';
import { customDate } from 'helpers/formatDate.js';
import { useGetAssetUser } from 'hook/useAssets.js';
import { formatNumberCurrency } from 'helpers/format.js';
import { formatCurrencyNumber } from 'helpers/format.js';

const DashboardAssets = ({ isDashboard }) => {
  const { t } = useTranslation();

  const [year, setYear] = useState(customDate(new Date(), 'YYYY'));
  const [dataChartCategory, setDataChartCategory] = useState();
  const [dataChartDivision, setDataChartDivision] = useState();

  const { data: assetDashboard } = useGetAssetDashboard({ date: year });
  const { data: assetCategory } = useGetAssetCategory({ date: year });
  const { data: assetDivision } = useGetAssetDivision({ date: year });
  const { data: assetUser, isLoading: isLoadingAssetUser } = useGetAssetUser({ date: year });

  const options = {
    maintainAspectRatio: false,
    legend: {
      labels: {
        fontSize: 18,
      },
      display: false,
    },
    title: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            borderDashOffset: 2,
            borderDash: [5, 5],
          },
          stacked: false,
          ticks: {
            callback: function (value, index, values) {
              return formatNumberCurrency(value);
            },
            beginAtZero: true,
            fontColor: '#333333',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: '#333333',
          },
        },
      ],
    },

    tooltips: {
      displayColors: false,
      backgroundColor: '#F3F7FB',
      callbacks: {
        title: () => null,

        beforeLabel: function (tooltipItem, data) {
          return (
            `${t('assets.dashboard.quantity')}: ` +
            formatCurrencyNumber(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].total)
          );
        },

        label: function (tooltipItem, data) {
          return (
            `${t('assets.dashboard.money')}: ` +
            formatNumberCurrency(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y)
          );
        },

        labelTextColor: () => '#223354',
      },
    },
  };

  useEffect(() => {
    if (assetCategory) {
      setDataChartCategory({
        labels: assetCategory.map((item) => item.name),
        datasets: [
          {
            data: assetCategory.map((item) => ({
              y: item.total_price,
              total: item.total_asset,
            })),
            backgroundColor: '#2ECC71',
            barThickness: 30,
          },
        ],
      });
    }
  }, [assetCategory]);

  useEffect(() => {
    if (assetDivision) {
      setDataChartDivision({
        labels: assetDivision.map((item) => item.name),
        datasets: [
          {
            data: assetDivision.map((item) => ({
              y: item.total_rent,
              total: item.total_asset,
            })),
            backgroundColor: '#29B1CC',
            barThickness: 30,
          },
        ],
      });
    }
  }, [assetDivision]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 100);
  }, []);

  return (
    <>
      {!isDashboard && (
        <div className="nav-fixed">
          <Grid container className="align-items-center">
            <Grid container className="nav-">
              <Grid item md={8} xs={12}>
                <Assets />
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}

      <div className={classNames(styles.searchComponent, { [styles.positionRight]: isDashboard })}>
        <YearPicker
          label=""
          name="year"
          valueDefault={year}
          onChangeHandle={(value) => setYear(customDate(value, 'YYYY'))}
          placeholder={t('contract.contractNav.chooseEndDate')}
          sizeDate="small"
        />
      </div>

      <div className={styles.totalAsset}>
        <div className={styles.itemAsset}>
          <div className={styles.waitApprove}>
            <div className={styles.title}>{t('assets.dashboard.waitApproveAssets')}</div>
            {assetDashboard?.map((item) => (
              <div className={styles.storeItem} key={item.id}>
                <div className={styles.name}>
                  {t('assets.dashboard.office')} {item.name}
                </div>
                <div className={styles.storeItemField}>
                  <div className={styles.label}>{t('assets.dashboard.quantity')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_pending.total)}</div>
                </div>

                <div className={styles.strike} />

                <div className={styles.storeItemField}>
                  <div className={styles.label}>{t('assets.dashboard.value')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_pending.value)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.itemAsset}>
          <div className={styles.cancel}>
            <div className={styles.title}>{t('assets.dashboard.cancelAssets')}</div>

            {assetDashboard?.map((item) => (
              <div className={styles.storeItem} key={item.id}>
                <div className={styles.name}>
                  {t('assets.dashboard.office')} {item.name}
                </div>
                <div className={styles.storeItemField}>
                  <div className={styles.label}>{t('assets.dashboard.quantity')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_cancel.total)}</div>
                </div>

                <div className={styles.strike} />

                <div className={styles.storeItemField}>
                  <div className={styles.label}>{t('assets.dashboard.value')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_cancel.value)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.itemAsset}>
          <div className={styles.all}>
            <div className={styles.title}>{t('assets.dashboard.totalAssets')}</div>

            {assetDashboard?.map((item) => (
              <div className={styles.storeItem} key={item.id}>
                <div className={styles.name}>
                  {t('assets.dashboard.office')} {item.name}
                </div>
                <div className={styles.storeItemField}>
                  <div className={styles.label}>{t('assets.dashboard.quantity')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.total_asset.total)}</div>
                </div>

                <div className={styles.strike} />

                <div className={styles.storeItemField}>
                  <div className={styles.label}>{t('assets.dashboard.value')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.total_asset.value)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.itemAsset}>
          <div className={styles.inUse}>
            <div className={styles.title}>{t('assets.dashboard.totalAssetsInUse')}</div>

            {assetDashboard?.map((item) => (
              <div className={styles.storeItem} key={item.id}>
                <div className={styles.name}>
                  {t('assets.dashboard.office')} {item.name}
                </div>
                <div className={styles.storeItemField}>
                  <div className={styles.label}>{t('assets.dashboard.quantity')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_using.total)}</div>
                </div>

                <div className={styles.strike} />

                <div className={styles.storeItemField}>
                  <div className={styles.label}>{t('assets.dashboard.value')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_using.value)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.itemAsset}>
          <div className={styles.lost}>
            <div className={styles.title}>{t('assets.dashboard.totalAssetsLose')}</div>

            {assetDashboard?.map((item) => (
              <div className={styles.storeItem} key={item.id}>
                <div className={styles.name}>
                  {t('assets.dashboard.office')} {item.name}
                </div>
                <div className={styles.storeItemField}>
                  <div className={styles.label}>{t('assets.dashboard.quantity')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_lost.total)}</div>
                </div>

                <div className={styles.strike} />

                <div className={styles.storeItemField}>
                  <div className={styles.label}>{t('assets.dashboard.value')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_lost.value)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.totalInStorage}>
        <div className={styles.title}>{t('assets.dashboard.inStorage')}</div>

        {assetDashboard?.map((item) => (
          <div className={styles.storageItem} key={item.id}>
            <div className={styles.storageName}>
              {t('assets.dashboard.storage')} {item.name}
            </div>
            <Grid container spacing={2}>
              <Grid item xs={3} className={styles.storageStatus}>
                <div className={styles.status}>{t('assets.dashboard.noUse')}</div>
                <div className="d-flex justify-content-between align-items-end p-3">
                  <div className={styles.label}>{t('assets.dashboard.quantity')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_no_using.total)}</div>
                </div>

                <div className="d-flex justify-content-between align-items-end bg-light-gray p-3">
                  <div className={styles.label}>{t('assets.dashboard.value')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_no_using.value)}</div>
                </div>
              </Grid>

              <Grid item xs={3} className={styles.storageStatus}>
                <div className={styles.status}>{t('assets.dashboard.break')}</div>
                <div className="d-flex justify-content-between align-items-end p-3">
                  <div className={styles.label}>{t('assets.dashboard.quantity')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_broken.total)}</div>
                </div>

                <div className="d-flex justify-content-between align-items-end bg-light-gray p-3">
                  <div className={styles.label}>{t('assets.dashboard.value')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_broken.value)}</div>
                </div>
              </Grid>

              <Grid item xs={3} className={styles.storageStatus}>
                <div className={styles.status}>{t('assets.dashboard.waitingSell')}</div>
                <div className="d-flex justify-content-between align-items-end p-3">
                  <div className={styles.label}>{t('assets.dashboard.quantity')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_waiting_sell.total)}</div>
                </div>

                <div className="d-flex justify-content-between align-items-end bg-light-gray p-3">
                  <div className={styles.label}>{t('assets.dashboard.value')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_waiting_sell.value)}</div>
                </div>
              </Grid>

              <Grid item xs={3} className={styles.storageStatus}>
                <div className={styles.status}>{t('assets.dashboard.sell')}</div>
                <div className="d-flex justify-content-between align-items-end p-3">
                  <div className={styles.label}>{t('assets.dashboard.quantity')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_sell.total)}</div>
                </div>

                <div className="d-flex justify-content-between align-items-end bg-light-gray p-3">
                  <div className={styles.label}>{t('assets.dashboard.value')}</div>
                  <div className={styles.value}>{formatNumberCurrency(item.asset_sell.value)}</div>
                </div>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
      {assetCategory?.length > 0 && (
        <div className={styles.chartAsset}>
          <div className={styles.chartTitle}>
            <img src={chartIcon} alt="" />
            <div className={styles.title}>{t('assets.dashboard.assetByCategory')}</div>
          </div>

          <div className={styles.barChart}>
            {dataChartCategory && (
              <>
                <label className="employees">{t('assets.dashboard.value')}</label>
                <Bar options={options} data={dataChartCategory} />
              </>
            )}
          </div>
        </div>
      )}

      <Grid container spacing={2} className={styles.hiringAsset}>
        {assetDivision?.length > 0 && (
          <Grid item xs={6}>
            <div className={classNames(styles.chartAsset, styles.chartHireAsset)}>
              <div className={styles.chartTitle}>
                <img src={chartIcon} alt="" />
                <div className={styles.title}>{t('assets.dashboard.assetByDivision')}</div>
              </div>

              <div className={styles.barChart}>
                {dataChartDivision && <Bar options={options} data={dataChartDivision} />}
              </div>
            </div>
          </Grid>
        )}
        <Grid item xs={assetDivision?.length > 0 ? 6 : 12}>
          <div className={styles.assetTable}>
            <div className={styles.chartTitle}>
              <img src={chartIcon2} alt="" />
              <div className={styles.title}>{t('assets.dashboard.assetByUser')}</div>
            </div>

            <TableContainer sx={{ maxHeight: 315 }} className={styles.tableContainer}>
              <Table aria-label="sticky table" stickyHeader>
                <TableHead className={styles.assetHeader}>
                  <TableRow className={styles.assetHeaderRow}>
                    <TableCell>{t('assets.dashboard.no')}</TableCell>
                    <TableCell>{t('assets.dashboard.name')}</TableCell>
                    <TableCell>{t('assets.dashboard.value')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={styles.tableBody}>
                  {assetUser?.map((item, index) => (
                    <TableRow hover className={styles.tableRow} key={item.user_id}>
                      <TableCell>{index + 1 < 10 ? `0${index + 1}` : index + 1}</TableCell>
                      <TableCell className={styles.nameColumn}>
                        <img src={item.avatar || defaultAvatar} alt="" className={styles.userAvatar} />
                        <div>
                          <div className={styles.name}>{item.user_name}</div>
                          <div className={styles.email}>{item.email}</div>
                        </div>
                      </TableCell>
                      <TableCell>
                        {formatNumberCurrency(item.total_price)}
                        {t('assets.dashboard.vnd')}
                      </TableCell>
                    </TableRow>
                  ))}

                  {!assetUser?.length && !isLoadingAssetUser && (
                    <tr>
                      <td colSpan={3} className="error-no-data">
                        {t('common.notData')}
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardAssets;
