import React, { useEffect, useCallback, useState, useContext } from 'react';
import { ButtonBase, Grid, debounce, InputBase } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BiLinkExternal } from 'react-icons/bi';
import NavContract from '../Nav';
import moment from 'moment';
import classNames from 'classnames';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import { WHEAT } from 'constants/ColorForm';
import { BG_POSITION } from 'constants/ColorMap';

import styles from './style.module.scss';
import ButtonComponent from 'components/Buttons';
import Loading from 'components/Loading';
import SearchComponent from 'components/Search';
import AlertComponent from './../../../../components/Alert';
import CreateWaitingContract from './CreateWaitingContract';
import DateDayMonthComponent from 'components/DateTime/DateDay.js';
import iconLoading from 'assets/img/icons/loading.png';
import iconSandClock from 'assets/img/icons/sandClock.png';
import { useListContractDivisionPending, useGetListWaitContract } from 'hook/useContract';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { DEFAULT_LIMIT, DEFAULT_PAGE_MUI, MAX_LENGTH_20, PER_PAGE_OPTION } from 'constants/index.js';
import { USE_LIST_WAIT_CONTRACT } from 'constants/Contract';
import TablePagination from '@mui/material/TablePagination';
import { handleExportExcel } from 'helpers';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';
import ModalCommon from 'components/ModalCommon/ModalCommon';
import * as Constants from 'constants/index.js';

const WaitingContract = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [dataDivision, setDataDivision] = useState([]);
  const [filter, setFilter] = useState({
    start_date: null,
    division_id: 0,
    key_word: null,
    page: DEFAULT_PAGE_MUI,
    per_page: DEFAULT_LIMIT,
  });
  const { data: uListWaitContract, isLoading: isLoadingListWait } = useGetListWaitContract({
    ...filter,
    page: filter?.page + 1,
  });
  const { data: uListDivision, isLoading: isLoadingListDivision } = useListContractDivisionPending(filter);

  const [isShowModalCreate, setShowModalCreate] = useState(false);
  const [formDataContract, setFormDataContract] = useState({});
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState([]);
  const [dataFilterBackup, setDataFilterBackup] = useState({});
  const [isModals, setIsModals] = useState({ has_contract: false });
  const [TYPE, setType] = useState([
    { id: undefined, status: false, name: 'Tất cả', value: 'all' },
    { id: 1, status: false, name: 'Chưa có hợp đồng lao động' },
    { id: 2, status: false, name: 'Đã có hợp đồng lao động' },
  ]);

  const { control, setValue } = useForm({
    mode: 'onBlur',
  });

  const changeSelectType = (item) => {
    setType(function (TYPE) {
      const index = TYPE.indexOf(item);
      return handleCheck(TYPE, index);
    });
  };

  function handleCheck(items, index) {
    let isCheckAll = true;
    if (index === 0) {
      isCheckAll = !items[0].status;
      items.forEach((item, ind) => (ind === 0 ? (item.status = isCheckAll) : (item.status = false)));
    } else {
      items.forEach(function (item, i) {
        if (((i !== index && item.status === false) || (i === index && item.status === true)) && i !== 0) {
          isCheckAll = false;
        }
      });
      if (isCheckAll) {
        items.forEach((item, ind) => (ind === 0 ? (item.status = true) : (item.status = false)));
      } else {
        items.forEach(function (item, i) {
          if (i === 0) {
            item.status = isCheckAll;
          } else if (index === i) {
            item.status = !item.status;
          }
        });
      }
    }

    return [...items];
  }

  const handleChangeDivision = (event) => {
    setFilter({ ...filter, division_id: event.target.value, page: DEFAULT_PAGE_MUI });
  };

  useEffect(
    (dataDivision) => {
      if (uListDivision && !dataDivision && Array.isArray(uListDivision)) {
        uListDivision.forEach((item) => {
          item.status = false;
        });
        setDataDivision(uListDivision);
      }
    },
    [uListDivision],
  );

  const handleStartDate = (value) => {
    setFilter((prev) => {
      return { ...prev, start_date: moment(value).format('DD/MM/YYYY'), page: DEFAULT_PAGE_MUI };
    });
  };

  const changePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const changePerPage = (event) => {
    setFilter({ ...filter, page: DEFAULT_PAGE_MUI, per_page: +event.target.value });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Handle Filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value) => {
      setFilter({ ...filter, key_word: value, page: DEFAULT_PAGE_MUI });
    }),
  );

  const handleModalCreateEdit = (name, user_id) => {
    if (dataInforUser?.hr_permission?.contracts_permission === ROLE_VIEW) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      return;
    }
    setFormDataContract({
      code: '',
      name: name,
      email: '',
      position: '',
      user_id: user_id,
      type_contract: 0,
      type_employee: '',
      start_date: null,
      end_date: null,
    });
    setShowModalCreate(!isShowModalCreate);
  };

  const handleShowMessage = (value) => {
    if (value) {
      setMessage(value);
      setShowAlert(true);
    }
  };

  const handleReset = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setValue('division', null);
    setFilter({
      ...filter,
      start_date: null,
      division_id: '',
      key_word: '',
      page: DEFAULT_PAGE_MUI,
      per_page: DEFAULT_LIMIT,
    });
    await queryClient.invalidateQueries(USE_LIST_WAIT_CONTRACT);
  };

  return (
    <>
      <div className={styles.content}>
        <Grid container>
          <Grid container className="nav-fixed">
            <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
              <Grid container>
                <NavContract />
              </Grid>
            </Grid>
            <Grid item xs sm md lg xl className={styles.button}>
              <ButtonComponent
                addClass={styles.export}
                icon={<BiLinkExternal />}
                text="Export"
                handleClick={() =>
                  handleExportExcel(
                    '/api/contracts/export/list-contract-pending/excel',
                    {
                      key_word: filter.key_word,
                      start_date: filter.start_date,
                      division_id: filter.division_id,
                    },
                    'list-contract-pending.xlsx',
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid item md={9} className={styles.assetMain}>
            <div className={`${styles.profileBox} table-list table-border-show`}>
              <Paper>
                <TableContainer>
                  <Table aria-label="sticky table">
                    <TableHead className={styles.assetHeader}>
                      <TableRow className={styles.assetHeaderRow}>
                        <TableCell align="center">{t('contract.contractNav.code')}</TableCell>
                        <TableCell align="center">{t('contract.contractNav.name')}</TableCell>
                        <TableCell align="center">{t('employees.email')}</TableCell>
                        <TableCell align="center">{t('contract.contractNav.division')}</TableCell>
                        <TableCell align="center">{t('contract.contractWaiting.position')}</TableCell>
                        <TableCell align="center">{t('contract.contractNav.startDate')}</TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoadingListWait && (
                        <TableRow>
                          <TableCell colSpan={7}>
                            <Loading />
                          </TableCell>
                        </TableRow>
                      )}
                      {uListWaitContract?.data &&
                        uListWaitContract?.data?.map((item, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              <TableCell>{item?.code}</TableCell>
                              <TableCell>
                                <div className={styles.linkRequest}>
                                  {item?.name ? item?.name : <>{t('infoEmployee.notUpdate')}</>}
                                  {item?.total_future ? (
                                    <span className={styles.numberFuture}>{item.total_future}</span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>
                                <div>{item?.email ? item?.email : <>{t('infoEmployee.notUpdate')}</>}</div>
                              </TableCell>
                              <TableCell>
                                {item?.division_name ? item?.division_name : <>{t('infoEmployee.notUpdate')}</>}
                              </TableCell>
                              <TableCell>
                                {item?.position ? item?.position : <>{t('infoEmployee.notUpdate')}</>}
                              </TableCell>
                              <TableCell>
                                {item?.start_date ? item?.start_date : <>{t('infoEmployee.notUpdate')}</>}
                              </TableCell>
                              <TableCell>
                                <ButtonBase
                                  onClick={() =>
                                    dataInforUser?.hr_permission?.contracts_permission !== ROLE_VIEW &&
                                    handleModalCreateEdit(item?.name, item?.user_id)
                                  }
                                  className={`${styles.createButton} ${
                                    dataInforUser?.hr_permission?.contracts_permission === ROLE_VIEW && DISABLE_ROLE
                                  }`}
                                  size="lg"
                                >
                                  <div className={styles.textButton}>{t('contract.contractWaiting.create')}</div>
                                </ButtonBase>
                              </TableCell>
                            </TableRow>
                          );
                        })}

                      {!uListWaitContract?.data?.length && !isLoadingListWait && (
                        <tr>
                          <td colSpan={7} className="error-no-data">
                            {t('common.notData')}
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {uListWaitContract?.total > 0 && (
                  <TablePagination
                    rowsPerPageOptions={PER_PAGE_OPTION}
                    component="div"
                    count={uListWaitContract?.total}
                    rowsPerPage={filter?.per_page}
                    page={filter?.page}
                    onPageChange={changePage}
                    onRowsPerPageChange={changePerPage}
                    className="table-pagination"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
                  />
                )}
              </Paper>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className={styles.boxWaiting}>
              <div className={styles.boxWaitingContent}>
                <img src={iconSandClock} alt={iconSandClock} />
                <div className={styles.boxWaitingText}>
                  <div className={styles.waitingText}>{t('contract.waitingContract')}</div>
                  <div className={styles.waitingNumber}>{uListWaitContract?.total}</div>
                </div>
              </div>
            </div>
            <div className={styles.boxFilter}>
              <div className={styles.paddingElementFilter}>
                <SearchComponent
                  txtPlaceholder={t('contract.contractRetire.search')}
                  addClass="input-search-box"
                  handleSearch={handleSearch}
                  defaultValue={filter?.key_word}
                  maxLength={MAX_LENGTH_20}
                />
              </div>
              <div>
                <DateDayMonthComponent
                  label=""
                  name="start_date"
                  valueDefault={
                    typeof filter.start_date === 'string' ? moment(filter.start_date, 'DD/MM/YYYY') : filter.start_date
                  }
                  onChangeHandle={(value) => handleStartDate(value)}
                  placeholder={t('contract.contractWaiting.startDate')}
                  sizeDate="small"
                />
              </div>
              <div className={classNames(`${styles.paddingSearch}`)}>
                <InputBase
                  disabled
                  type="text"
                  name="type_contract"
                  className={classNames(`${styles.inputSelectMulti}`)}
                  onClick={(prev) => {
                    setIsModals(() => {
                      return { ...prev, type: true };
                    });
                    setDataFilterBackup([...TYPE]);
                  }}
                  value={
                    TYPE &&
                    (!TYPE[0]?.status
                      ? TYPE.map((item, i) =>
                          item.status && item.value !== Constants.ALL ? (i !== 1 ? ' ' : '') + item.name : '',
                        ).filter((it) => it)
                      : t('employees.all'))
                  }
                  placeholder={t('contract.contractWaiting.type')}
                />
                <ModalCommon
                  items={TYPE}
                  onSubmit={() => {
                    let data = [];

                    TYPE.forEach((it) => {
                      it.status && it.id && data.push(it.id);
                    });

                    setFilter({
                      ...filter,
                      type_contract: data ? data : null,
                      page: Constants.DEFAULT_PAGE_MUI,
                    });
                    setIsModals((prev) => {
                      return { ...prev, type: false };
                    });
                  }}
                  onClick={changeSelectType}
                  toggle={() => {
                    setIsModals((prev) => {
                      return { ...prev, type: !prev.type };
                    });
                    setType([...dataFilterBackup]);
                  }}
                  modal={isModals.type}
                  title={t('contract.contractWaiting.type')}
                  footer={t('contract.contractWaiting.search')}
                />
              </div>
              <div className={classNames(`${styles.paddingElementTop}`)}>
                <FormControl component="fieldset">
                  <FormLabel
                    className={classNames(
                      `${styles.text} ${styles.marginElementBottom} ${styles.borderTop} ${styles.paddingElementTop}`,
                    )}
                  >
                    {t('contract.contractRetire.division')}
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup {...field} value={filter?.division_id ? filter?.division_id : 0}>
                        <FormControlLabel
                          value={0}
                          onChange={(e) => {
                            setValue('division', e.target.value);
                            handleChangeDivision(e);
                          }}
                          control={
                            <Radio
                              sx={{
                                color: WHEAT,
                                '&.Mui-checked': {
                                  color: BG_POSITION[0],
                                },
                              }}
                            />
                          }
                          label={t('employees.all')}
                        />
                        {isLoadingListDivision && !dataDivision.length > 0 && <Loading />}
                        {dataDivision?.map((item, index) => (
                          <div key={index} className={styles.division}>
                            <FormControlLabel
                              key={index}
                              value={item?.id}
                              onChange={(e) => {
                                setValue('division', e.target.value);
                                handleChangeDivision(e);
                              }}
                              control={
                                <Radio
                                  sx={{
                                    color: WHEAT,
                                    '&.Mui-checked': {
                                      color: BG_POSITION[0],
                                    },
                                  }}
                                />
                              }
                              label={item?.name}
                            />
                            <span className={styles.total}>{item.total_member ? item?.total_member : 0}</span>
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                    name="division"
                    control={control}
                    defaultValue={filter?.division_id ? filter?.division_id : 0}
                  />
                </FormControl>
              </div>
              <div className={styles.borderTop}>
                <ButtonBase
                  onClick={() => handleReset()}
                  className={classNames(`${styles.reset} ${styles.borderTop}`)}
                  size="lg"
                >
                  <img src={iconLoading} alt="Reset" width="20px" className={styles.imgReset} /> &emsp;
                  {t('common.reset')}
                </ButtonBase>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        <p className={styles.textLeft}>{message}</p>
      </AlertComponent>

      <CreateWaitingContract
        onSetShowModal={handleModalCreateEdit}
        isShowModalAdd={isShowModalCreate}
        onSetMessage={handleShowMessage}
        formDataContract={formDataContract}
      />
    </>
  );
};

export default WaitingContract;
