export const TAB_PROGRAM = 0;
export const TAB_IN_COMMITMENT = 1;
export const TAB_END_OF_COMMITMENT = 2;


export const COURSE_IN_COMPANY = 1;
export const COURSE_OUTSIDE_COMPANY = 2;
export const CERTIFICATE = 3;

export const REFUND_FULL = 1;
export const REFUND_BY_PERCENT = 2;

export const MIN_MONTH_COMMIT = 0;
export const MAX_MONTH_COMMIT = 99;

export const MIN_SUPPORT_COST = 0;
export const MAX_SUPPORT_COST = 999999999;

export const STATUS_NOT_DONE = 0;
export const STATUS_DONE = 1;

export const IN_COMMIT_TYPE = 'in_commitment';
export const END_COMMIT_TYPE = 'end_commitment';
export const END_COMMIT = 'end-commit';
export const END_COMMIT_COST = 'end-commit-cost';


export const typeProgramOptions = [
  { value: COURSE_IN_COMPANY, label: 'training.courseInCompany' },
  { value: COURSE_OUTSIDE_COMPANY, label: 'training.courseOutsideCompany' },
  { value: CERTIFICATE, label: 'training.certificate' },
];

export const rateCommitOptions = [
  {
    value: REFUND_FULL,
    label: 'training.refundFull',
  },
  {
    value: REFUND_BY_PERCENT,
    label: 'training.refundByPercent',
  },
];

// field key
export const FIELD_KEY = {
  INDEX: 'index',
  TYPE: 'type',
  ID: 'id',
  NAME: 'name',
  MONTH_COMMIT: 'month_commit',
  SUPPORT_COST: 'support_cost',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  RATE_COMMIT: 'rate_commit',
  USER_IDS: 'user_ids',
  USER_ID: 'user_id',
  DESCRIPTION: 'description',
  LOCATION: 'location',
  TYPE_PROGRAM: 'type_program',
  CERTIFICATE_ID: 'certificate_id',
  CERTIFICATE_CATALOG: 'certificate_catalog',
  END_COMMIT_COST: 'end_commit_cost',
  STATUS: 'status',
  DIVISION: 'division',
  POSITION: 'position',
  USER_COURSE_COUNT: 'user_course_count',
  START_MONTH: 'start_month',
  END_MONTH: 'end_month',
  KEY_WORD: 'keyword',
  CODE: 'code',
  COURSE: 'course',
  COURSE_ID: 'course_id'
};

// api endpoint
export const COURSE_API = '/api/course';
export const ALL_USERS_API = '/api/users';
export const COURSE_USER_API = '/api/course-user';
export const CERTIFICATE_CATEGORY = '/api/certificate-category';
export const CERTIFICATE_API = '/api/certificate';
export const USERS_COMMIT_API = '/api/course-user-in-commit';
