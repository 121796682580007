import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { INSURANCE_POLICY, POLICY_OF_TAX } from 'constants/Policy'
import SideNavBar from 'components/SideNavBar'

const Policy = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [value, setValue] = useState(POLICY_OF_TAX)

  const routes = [
    // {
    //   name: t('policy.policyOfTax'),
    // },
    {
      name: t('policy.insurancePolicy'),
    },
  ]

  const onChangeRoute = (_, routeValue) => {
    setValue(routeValue)
    // if (routeValue === POLICY_OF_TAX) {
    //   history.push('/admin/policy/tax')
    // }
    if (routeValue === INSURANCE_POLICY) {
      history.push('/admin/policy/insurance')
    }
  }

  useEffect(() => {
    switch (history.location.pathname) {
      // case '/admin/policy/tax':
      //   setValue(POLICY_OF_TAX)
      //   break
      case '/admin/policy/insurance':
        setValue(INSURANCE_POLICY)
        break
      default:
        break
    }
  }, [history.location.pathname])

  return <SideNavBar routes={routes} onChange={onChangeRoute} value={value} />
}

export default Policy
