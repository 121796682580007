import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Table, Row, Form } from 'reactstrap';
import { useQueryClient } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';

import Sidebar from './../Sidebar.js';
import SearchComponent from './../../../../components/Search';
import ButtonComponent from './../../../../components/Buttons';
import { IoIosAdd } from 'react-icons/io';
import { HiPencil } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import './style.scss';
import ModalComponent from 'components/Modal/index.js';
import Loading from 'components/Loading';
import {
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_OK,
  HTTP_NOT_ACCESS,
  HTTP_INTERNAL_SERVER_ERROR,
  HTTP_EXPECTATION_FAILED,
} from 'constants/StatusCode.js';
import AlertComponent from 'components/Alert/index.js';
import { apiCreateLevel, apiUpdateLevel, apiDeleteLevel } from 'api/masterData';
import { USE_LIST_LEVEL } from 'constants/MasterData';
import { useGetLevel } from 'hook/useMasterData';
import { isNotNullObject } from 'helpers/index.js';
import { MAX_LENGTH_30 } from 'constants/ProfileEmployee.js';
import InputField from 'components/Input/InputField.js';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE, DISABLE_BUTTON_ROLE } from 'constants/index.js';

const DEFAULT_FORM_DATA = {
  id: null,
  name: '',
  coefficient: '',
  error_name: '',
  error_coefficient: ''
};

const DEFAULT_FORM_DATA_ERROR = {
  error_name: '',
  error_coefficient: ''
};

const Level = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [isShowModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [stateButton, setStateButton] = useState(false);
  const [formAdd, setFormAdd] = useState(DEFAULT_FORM_DATA);
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [textModal, setTextModal] = useState({
    title: t('masterData.moreData'),
    btn_submit: t('common.add'),
  });

  const handleSearch = (value) => {
    setName(value);
  };

  const addLevel = () => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setShowModal(true);
    setFormAdd({ ...formAdd, ...DEFAULT_FORM_DATA });
    setTextModal({
      title: t('masterData.moreData'),
      btn_submit: t('common.add'),
    });
  };

  function onclickLevel(idx) {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    setShowModal(true);
    setTextModal({
      title: t('masterData.updateSkill'),
      btn_submit: t('common.update'),
    });
    setFormAdd({
      ...formAdd,
      id: idx.id,
      name: idx.name,
      coefficient: idx.coefficient,
      error_name: '',
      error_coefficient: ''
    });
  }

  const handleInput = (e) => {
    setFormAdd({ ...formAdd, [e.target.name]: e.target.value, ...DEFAULT_FORM_DATA_ERROR });
  };

  const { data: uListLevel, isLoading: isLoadingListLevel } = useGetLevel({ key_word: name });

  const alertConfirm = (id) => {
    if (dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW) {
      return;
    }
    confirmAlert({
      title: t('infoEmployee.youWantToDelete'),
      buttons: [
        {
          label: t('infoEmployee.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('infoEmployee.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteLevel(id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_LIST_LEVEL);
            }

            if (
              response?.data.status === HTTP_INTERNAL_SERVER_ERROR ||
              response?.data.status === HTTP_EXPECTATION_FAILED
            ) {
              setMessage(response?.data?.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_LIST_LEVEL);
            }
          },
        },
      ],
    });
  };

  function validate() {
    var messages = {};

    if (!formAdd.name) {
      messages.error_name = t('common.pleaseFillOutThisField');
    }
    if (formAdd.name.length > MAX_LENGTH_30) {
      messages.error_name = t('common.pleaseEnterLessThanThirtyCharacters');
    }
    if (!formAdd.coefficient) {
      messages.error_coefficient = t('common.pleaseFillOutThisField');
    }

    if (isNotNullObject(messages)) {
      setFormAdd({
        ...formAdd,
        ...messages,
      });
      return false;
    }
    return true;
  }

  const handleSubmit = async (event) => {
    setFormAdd({ ...formAdd, ...DEFAULT_FORM_DATA_ERROR });
    setStateButton(true);
    event.preventDefault();

    if (!validate()) {
      setStateButton(false);
      return;
    }

    setFormAdd({ ...formAdd, ...DEFAULT_FORM_DATA_ERROR });

    const data = {
      name: formAdd.name.trim(),
      coefficient: formAdd.coefficient
    };

    let response = {};
    if (!formAdd.id) {
      response = await apiCreateLevel(data);
    } else {
      response = await apiUpdateLevel(formAdd.id, data);
    }

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setFormAdd({
        ...formAdd,
        error_name: msg?.name
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });

      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModal(false);
      setStateButton(false);
      setFormAdd({ ...formAdd, ...DEFAULT_FORM_DATA });
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_LIST_LEVEL);
    }
    setTextModal({
      ...textModal,
      title: t('masterData.moreData'),
      btn_submit: t('common.add'),
    });
    setStateButton(false);
  };

  return (
    <div>
      <Row>
        <Col lg={3} xl={2}>
          <Sidebar />
        </Col>
        <Col lg={9} xl={10}>
          <Row>
            <Col className="md-12">
              <div className="box-group d-flex float-right">
                <SearchComponent
                  txtPlaceholder={t('masterData.findData')}
                  addClass="input-search"
                  handleSearch={handleSearch}
                  defaultValue={name}
                />
                <ButtonComponent
                  text={t('common.add')}
                  icon={<IoIosAdd />}
                  addClass={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_BUTTON_ROLE
                    } ml-3`}
                  className="button-add"
                  handleClick={addLevel}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="md-12">
              <div className="table-list">
                <Table className="align-items-center table-hover" responsive>
                  <thead>
                    <tr>
                      <th scope="col">{t('common.code')}</th>
                      <th scope="col">{t('masterData.typeLevel')}</th>
                      <th scope="col">{t('masterData.coefficient')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {uListLevel?.data?.length === 0 ? (
                      <tr>
                        <td colSpan={3}>
                          <div className="error-no-data">{t('common.notData')}</div>
                        </td>
                      </tr>
                    ) : (
                      uListLevel?.data?.map((val, index) => (
                        <tr key={index}>
                          <td>{val.id > 9 ? <>{val.id}</> : <>0{val.id}</>}</td>
                          <td className="text-bold">{val.name}</td>
                          <td>{val.coefficient}</td>
                          <td>
                            <div className="col-action d-flex float-right">
                              <div
                                className={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                  } edit mr-2`}
                                onClick={() => onclickLevel(val)}
                              >
                                <HiPencil />
                              </div>
                              <div
                                className={`${dataInforUser?.hr_permission?.master_data_permission === ROLE_VIEW && DISABLE_ROLE
                                  } remove mr-3`}
                                onClick={() => alertConfirm(val.id)}
                              >
                                <MdDelete />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                    {isLoadingListLevel && (
                      <tr>
                        <td colSpan={4}>
                          <Loading addClass="mt-2" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalComponent toggle={() => setShowModal(!isShowModal)} isShowModal={isShowModal} title={textModal.title}>
        <Form className="mt-3" onSubmit={handleSubmit}>
          <div className="fieldset-group mt-4 ">
            <InputField
              label={t('masterData.nameLevel')}
              variant="outlined"
              name="name"
              maxLength={MAX_LENGTH_30}
              defaultValue={formAdd.name}
              onHandleInput={handleInput}
              error={formAdd.error_name}
              isRequired={true}
            />
          </div>
          <div className="fieldset-group mt-4 ">
            <InputField
              label={t('masterData.coefficient')}
              variant="outlined"
              name="coefficient"
              defaultValue={formAdd.coefficient}
              onHandleInput={handleInput}
              error={formAdd.error_coefficient}
              isRequired={true}
            />
          </div>

          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModal(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent text={textModal.btn_submit} addClass="w-100" type="submit" hasDisabled={stateButton} />
            </Col>
          </Row>
        </Form>
      </ModalComponent>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </div>
  );
};

export default Level;
