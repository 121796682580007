import { Col, Row, Label } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import classNames from 'classnames';

import radio from './../../../../assets/img/icons/radio.png';
import radioChecked from './../../../../assets/img/icons/radio-checked.png';

import InputField from 'components/Input/InputField';
import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import SelectComponent from 'components/Select/Select';
import AlertComponent from 'components/Alert';
import { customDate } from 'helpers/formatDate';
import { isNotNullObject, DEFAULT_NOT_REQUIRED } from 'helpers/index';
import { apiCreateUserFromStandby } from 'api/profileEmployee';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY, HTTP_NOT_ACCESS } from '../../../../constants/StatusCode.js';
import * as Constants from 'constants/ProfileEmployee.js';
import {
  HR_TEAM,
  OTHER_TEAM,
  DATA_TEAM,
} from 'constants/ProfileEmployee.js';
import { USE_LIST_STANDBY_EMPLOYEES } from 'constants/ListEmployees';

import styles from './style.module.scss';
import { useOptionEditProfile, useGetStatusTeamDivison } from 'hook/useProfileEmployee';
import { useGetGroupUser } from 'hook/useEmployees';
import { DATA_TYPE_NUMBER } from 'constants/index.js';

const DEFAULT_DATA_ADD_EMPLOYEE = {
  name: '',
  email: '',
  tax_code: '',
  phone: '',
  address: '',
  temp_address: '',
  division_id: 0,
  team_id: 0,
  level_id: 0,
  group_id: 0,
  position_id: 0,
  office_id: 0,
  status: 1,
  role_id: 0,
  gender: Constants.GENDER_MALE,
  marital: Constants.UNMARRIE,
  error_name: '',
  error_tax: '',
  error_birthday: '',
  error_phone: '',
  error_permanent_address: '',
  error_temporary_address: '',
  error_division: '',
  error_team: '',
  error_level: '',
  error_position: '',
  error_status: '',
};

const ModalAddEmployee = (props) => {
  const { toggle, modal, setDataUserCreate, standbyFormData } = props;

  const { t } = useTranslation();
  const [textModal] = useState({
    titleEdit: t('common.addEmployee'),
  });
  const [statusTeam, setStatusTeam] = useState({ division_id: standbyFormData.division_id });
  const { data: uGetGroupUser } = useGetGroupUser({});
  const { data: uOptionEditProfile } = useOptionEditProfile();
  const { data: uStatusTeamDivision } = useGetStatusTeamDivison(statusTeam);

  const hrTeam = [
    {
      team_id: DEFAULT_NOT_REQUIRED,
      team_name: t('employees.hrTeam'),
    },
  ];

  const otherTeam = [
    {
      team_id: DEFAULT_NOT_REQUIRED,
      team_name: t('employees.otherTeam'),
    },
  ];

  const [dataTeam, setDataTeam] = useState([]);
  const [formSubmit, setFormSubmit] = useState(DEFAULT_DATA_ADD_EMPLOYEE);
  const [dateBirthDay, setDateBirthDay] = useState(null);
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);
  const [focus, setFocus] = useState(false);
  const [isShowAlertSubmitSuccess, setShowAlertSubmitSuccess] = useState(false);
  const [stateButton, setStateButton] = useState(false); 

  useEffect(() => {
    if (Number(uStatusTeamDivision) === HR_TEAM) {
      setDataTeam(hrTeam);
    }
    if (Number(uStatusTeamDivision) === OTHER_TEAM) {
      uOptionEditProfile?.data?.division.forEach((item) => {
        if (Number(formSubmit.division_id) === Number(item.division_id)) {
          setDataTeam([...otherTeam, ...item.team]);
        }
      });
    }
    if (Number(uStatusTeamDivision) === DATA_TEAM) {
      uOptionEditProfile?.data?.division.forEach((item) => {
        if (Number(formSubmit.division_id) === Number(item.division_id)) {
          setDataTeam(item.team);
        }
      });
    }
    // Handle Filter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uStatusTeamDivision, statusTeam]);

  useEffect(() => {
    setDateBirthDay(null);
    setFormSubmit(DEFAULT_DATA_ADD_EMPLOYEE);
  }, [modal]);

  useEffect(() => {
    setFormSubmit(
      {
        ...DEFAULT_DATA_ADD_EMPLOYEE,
        candidate_id: standbyFormData.id,
        name: standbyFormData.full_name,
        email: standbyFormData.email,
        gender: (standbyFormData.sex === 1) ? 0 : 1,
        phone: standbyFormData.mobile,
        division_id: standbyFormData.division_id,
        level_id: standbyFormData.level_official_id,
        group_id: standbyFormData.group_id,
        position_id: standbyFormData.position_id,
        office_id: standbyFormData.position_id

      });
      setDateBirthDay(new Date(Date.parse(standbyFormData.birthday)));  
  }, [standbyFormData]);

  const handleInput = (e, nameField) => {
    var teamID = {};
    if (
      (e.target.name === 'phone' && Number(e.target.value) !== 'NaN' && e.target.value >= 0) ||
      e.target.name !== 'phone'
    ) {
      const error = {};
      if (e.target.name === 'name') {
        const regex =
          /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        e.target.value = e.target.value.replace(regex, '');
        error.error_name = '';
      } else if (e.target.name === 'email') {
        error.error_email = '';
      } else if (e.target.name === 'tax_code') {
        error.error_tax = '';
      } else if (e.target.name === 'social_insurance_code') {
        error.error_social_insurance_code = '';
      } else if (e.target.name === 'phone') {
        error.error_phone = '';
      } else if (e.target.name === 'address') {
        error.error_permanent_address = '';
      } else if (e.target.name === 'temp_address') {
        error.error_temporary_address = '';
      } else if (e.target.name === 'marital') {
        error.error_tax = '';
      } else if (e.target.name === 'division_id') {
        error.error_team = '';
        error.error_division = '';
      } else if (e.target.name === 'position_id') {
        error.error_position = '';
      } else if (e.target.name === 'office_id') {
        error.error_office = '';
      } else if (e.target.name === 'level_id') {
        error.error_level = '';
      } else if (e.target.name === 'group_id') {
        error.error_group_id = '';
      } else if (e.target.name === 'status') {
        error.error_status = '';
      } else if (e.target.name === 'team_id') {
        error.error_team = '';
      }

      if (nameField === Constants.NAME_FIELD_DIVISION) {
        teamID.team_id = 0;
      }

      setFormSubmit({
        ...formSubmit,
        ...error,
        ...teamID,
        [e.target.name]: e.target.value,
      });
    } else {
      e.preventDefault();
    }

    if (nameField === Constants.NAME_FIELD_DIVISION) {
      if (!e.target.value) {
        setDataTeam([]);
      } else {
        setStatusTeam({ division_id: e.target.value });
      }
    }
  };

  $('input[type=tel]').keydown(function () {
    return false;
  });

  const changeRadio = (nameField, value) => {
    setFormSubmit({
      ...formSubmit,
      [nameField]: value,
    });
  };

  function validate() {
    var error = {};
    var focus = true;
    if (!formSubmit.name) {
      error.error_name = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (formSubmit.name && formSubmit.name.length > Constants.MAX_LENGTH_40) {
      error.error_name = t('common.pleaseEnterLessThanFortyCharacters');
      if (focus) {
        $('input[name=name]').focus();
        focus = false;
      }
    }
    if (!formSubmit.email) {
      error.error_email = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=email]').focus();
        focus = false;
      }
    }
    // eslint-disable-next-line
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formSubmit.email)) {
      error.error_email = t('common.pleaseEnterCorrectEmailFormat');
      if (focus) {
        $('input[name=email]').focus();
        focus = false;
      }
    }

    if (formSubmit.tax_code && formSubmit.tax_code.length > Constants.MAX_LENGTH_30) {
      error.error_tax = t('common.pleaseEnterLessThanThirtyCharacters');
      if (focus) {
        $('input[name=tax_code]').focus();
        focus = false;
      }
    }
    if (formSubmit.social_insurance_code && formSubmit.social_insurance_code.length > Constants.MAX_LENGTH_30) {
      error.error_social_insurance_code = t('common.pleaseEnterLessThanThirtyCharacters');
      if (focus) {
        $('input[name=social_insurance_code]').focus();
        focus = false;
      }
    }
    if (!dateBirthDay) {
      error.error_birthday = t('common.pleaseFillOutThisField');
      if (focus) {
        $('input[name=birthday]').focus();
        focus = false;
      }
    }
    if (!formSubmit.division_id || formSubmit.division_id === '0') {
      error.error_division = t('common.pleaseSelectField');
      if (focus) {
        $('input[name=division_id]').focus();
        focus = false;
      }
    }
    if (!formSubmit.team_id || formSubmit.team_id === '0') {
      error.error_team = t('common.pleaseSelectField');
      if (focus) {
        $('input[name=team_id]').focus();
        focus = false;
      }
    }
    if (!formSubmit.position_id || formSubmit.position_id === '0') {
      error.error_position = t('common.pleaseSelectField');
      if (focus) {
        $('input[name=position_id]').focus();
        focus = false;
      }
    }
    if (!formSubmit.office_id || formSubmit.office_id === '0') {
      error.error_office = t('common.pleaseSelectField');
      if (focus) {
        $('input[name=office_id]').focus();
        focus = false;
      }
    }
    if (!formSubmit.level_id || formSubmit.level_id === '0') {
      error.error_level = t('common.pleaseSelectField');
      if (focus) {
        $('input[name=level_id]').focus();
        focus = false;
      }
    }
    if (!formSubmit.group_id || formSubmit.group_id === '0') {
      error.error_group_id = t('common.pleaseSelectField');
      if (focus) {
        $('input[name=group_id]').focus();
        focus = false;
      }
    }

    if (isNotNullObject(error)) {
      setFormSubmit({
        ...formSubmit,
        ...error,
      });
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = async (event) => {
    setFormSubmit({
      ...formSubmit,
      error_name: '',
      error_tax: '',
      error_birthday: '',
      error_phone: '',
      error_social_insurance_code: '',
      error_permanent_address: '',
      error_temporary_address: '',
      error_division: '',
      error_team: '',
      error_level: '',
      error_email: '',
      error_position: '',
      error_office: '',
      error_status: '',
      error_group_id: '',
    });
    event.preventDefault();
    setStateButton(true);
    if (!validate()) {
      setStateButton(false);
      return;
    }

    const data = {
      ...formSubmit,
      name: formSubmit.name.trim(),
      team_id: formSubmit.team_id > 0 ? formSubmit.team_id : '',
      level_id: formSubmit.level_id > 0 ? formSubmit.level_id : '',
      group_id: formSubmit.group_id > 0 ? formSubmit.group_id : '',
      birthday: customDate(dateBirthDay, 'DD/MM/YYYY'),
    };
    let response = {};
    response = await apiCreateUserFromStandby(data);
    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      const msg = response.data.messages;
      setFormSubmit({
        ...formSubmit,
        error_name: msg?.name
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_email: msg?.email
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_tax: msg?.tax_code
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_social_insurance_code: msg?.social_insurance_code
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_birthday: msg?.birthday
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_phone: msg?.phone
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_permanent_address: msg?.address
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_temporary_address: msg?.temp_address
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_division: msg?.division_id
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_team: msg?.team_id
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_level: msg?.level_id
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_group_id: msg?.group_id
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_position: msg?.position_id
          ?.map((item) => {
            return item;
          })
          .join('\n'),
        error_office: msg?.office_id
          ?.map((item) => {
            return item;
          })
          .join('\n'),  
        error_status: msg?.status
          ?.map((item) => {
            return item;
          })
          .join('\n'),
      });
      if (msg?.name) {
        $('input[name=name]').focus();
      } else if (msg?.email) {
        $('input[name=email]').focus();
      } else if (msg?.phone) {
        $('input[name=phone]').focus();
      } else if (msg?.division_id) {
        $('input[name=division_id]').focus();
      } else if (msg?.team_id) {
        $('input[name=team_id]').focus();
      } else if (msg?.position_id) {
        $('input[name=position_id]').focus();
      } else if (msg?.group_id) {
        $('input[name=group_id]').focus();
      } else if (msg?.level_id) {
        $('input[name=level_id]').focus();
      }
      setStateButton(false);
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
      setStateButton(false);
      return;
    }

    if (response?.data?.status === HTTP_OK) {
      setDataUserCreate(response?.data?.data);
      setMessage(response.data.messages);
      setShowAlertSubmitSuccess(true);
      setStateButton(false);
      await props.data.queryClient.invalidateQueries(USE_LIST_STANDBY_EMPLOYEES);
    }
    setStateButton(false);
  };

  return (
    <>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
      <AlertComponent
        toggle={() => {
          toggle(false);
          setFormSubmit(DEFAULT_DATA_ADD_EMPLOYEE);
          setShowAlertSubmitSuccess(!isShowAlertSubmitSuccess);
        }}
        isShowAlert={isShowAlertSubmitSuccess}
      >
        {message}
      </AlertComponent>
      <ModalComponent toggle={toggle} isShowModal={modal} classNameAdd="edit-infor-user" title={textModal.titleEdit}>
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div>
            <InputField
              label={t('common.fullName')}
              variant="outlined"
              name="name"
              maxLength={Constants.MAX_LENGTH_40}
              defaultValue={formSubmit?.name}
              onHandleInput={handleInput}
              isRequired={true}
              error={formSubmit.error_name}
            />
          </div>
          <div className="mt-3">
            <InputField
              label={t('employees.email')}
              variant="outlined"
              name="email"
              defaultValue={formSubmit?.email}
              onHandleInput={handleInput}
              isRequired={true}
              error={formSubmit.error_email}
            />
          </div>
          <div className="fieldset-group mt-3">
            <Label className={classNames(`${styles.gender} color-navy mb-2`)}> {t('employees.gender')}</Label>
            <img
              className={styles.radio}
              alt="radio"
              onClick={() => changeRadio('gender', Constants.GENDER_MALE)}
              src={formSubmit?.gender === Constants.GENDER_MALE ? radioChecked : radio}
            />
            <Label className={styles.cusor} onClick={() => changeRadio('gender', Constants.GENDER_MALE)}>
              {t('infoEmployee.genderMale')}
            </Label>
            <img
              alt="radio"
              onClick={() => changeRadio('gender', 1)}
              className={`${styles.radio} ${styles.right}`}
              src={formSubmit?.gender === Constants.GENDER_FEMALE ? radioChecked : radio}
            />
            <Label className={styles.cusor} onClick={() => changeRadio('gender', Constants.GENDER_FEMALE)}>
              {t('infoEmployee.genderFemale')}
            </Label>
          </div>
          <div className="fieldset-group mt-3">
            <div className={styles.formField}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className={`${styles.formInput} ${formSubmit?.error_birthday && styles.fieldError}`}
                selected={dateBirthDay && new Date(dateBirthDay)}
                onChange={(date: Date) => {
                  setDateBirthDay(date);
                  setFormSubmit({
                    ...formSubmit,
                    error_birthday: '',
                  });
                }}
                maxDate={new Date()}
                onFocus={() => {
                  !dateBirthDay && setFocus(true);
                }}
                onBlur={() => {
                  focus && setFocus(false);
                }}
              />
              <label
                for="name"
                className={`${(dateBirthDay || focus) && styles.inputFocusCustom} ${styles.formLabel} ${
                  formSubmit?.error_birthday && styles.labelError
                }`}
              >
                {t('common.birthday') + ' *'}
              </label>
            </div>
            <p className={`error ${styles.errorBirthday}`}>{formSubmit?.error_birthday}</p>
          </div>
          <div className="mt-3">
            <InputField
              label={t('infoEmployee.phone')}
              variant="outlined"
              name="phone"
              maxLength={Constants.MAX_LENGTH_15}
              onHandleInput={handleInput}
              defaultValue={formSubmit?.phone}
              error={formSubmit.error_phone}
              dataType={DATA_TYPE_NUMBER}
            />
          </div>
          <div className="mt-3">
            <InputField
              label={t('infoEmployee.permanentAddress')}
              variant="outlined"
              name="address"
              maxLength={Constants.MAX_LENGTH_100}
              onHandleInput={handleInput}
              defaultValue={formSubmit?.address}
              error={formSubmit.error_permanent_address}
            />
          </div>
          <div className="mt-3">
            <InputField
              label={t('infoEmployee.temporaryAddress')}
              variant="outlined"
              name="temp_address"
              maxLength={Constants.MAX_LENGTH_100}
              onHandleInput={handleInput}
              defaultValue={formSubmit?.temp_address}
              error={formSubmit.error_temporary_address}
            />
          </div>
          <div className="fieldset-group mt-4">
            <Label className={classNames(`${styles.gender} color-navy mb-3`)}> {t('infoEmployee.married')}</Label>
            <img
              className={styles.radio}
              onClick={() => changeRadio('marital', Constants.MARRIE)}
              alt="radio"
              src={formSubmit?.marital === Constants.MARRIE ? radioChecked : radio}
            />
            <Label className={styles.cusor} onClick={() => changeRadio('marital', Constants.MARRIE)}>
              {t('infoEmployee.statusMarried')}
            </Label>
            <img
              alt="radio"
              className={`${styles.radio} ${styles.right}`}
              onClick={() => changeRadio('marital', Constants.UNMARRIE)}
              src={formSubmit?.marital === Constants.UNMARRIE ? radioChecked : radio}
            />
            <Label className={styles.cusor} onClick={() => changeRadio('marital', Constants.UNMARRIE)}>
              {t('infoEmployee.statusMarrie')}
            </Label>
          </div>
          <Row>
            <Col md={6}>
              <div className="select-field mt-3">
                <SelectComponent
                  name="division_id"
                  valueDefault={formSubmit.division_id}
                  error={formSubmit.error_division}
                  onHandleChangeSelect={(e) => handleInput(e, Constants.NAME_FIELD_DIVISION)}
                  label={t('employees.department')}
                >
                  <MenuItem value="0">{t('infoEmployee.selectDivision')}</MenuItem>
                  {uOptionEditProfile?.data?.division &&
                    uOptionEditProfile?.data?.division.map((item, index) => {
                      return (
                        <MenuItem value={item.division_id} key={index}>
                          {item.division_name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
            </Col>
            <Col md={6}>
              <div className="select-field mt-3">
                <SelectComponent
                  name="team_id"
                  valueDefault={formSubmit.team_id}
                  onHandleChangeSelect={handleInput}
                  error={formSubmit.error_team}
                  label={t('infoEmployee.subordinateGroup')}
                >
                  <MenuItem value="0">{t('infoEmployee.selectSubordinateGroup')}</MenuItem>
                  {dataTeam &&
                    dataTeam.map((item, index) => {
                      return (
                        <MenuItem value={item.team_id} key={index}>
                          {item.team_name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
            </Col>
            <Col md={6}>
              <div className="select-field mt-3">
                <SelectComponent
                  name="office_id"
                  valueDefault={formSubmit.office_id}
                  onHandleChangeSelect={handleInput}
                  error={formSubmit.error_office}
                  label={t('employees.office')}
                >
                  <MenuItem value="0">{t('employees.filterOffice')}</MenuItem>
                  {uOptionEditProfile?.data?.position &&
                    uOptionEditProfile?.data?.position.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
            </Col>
            <Col md={6}>
              <div className="select-field mt-3">
                <SelectComponent
                  name="level_id"
                  valueDefault={formSubmit.level_id}
                  onHandleChangeSelect={handleInput}
                  error={formSubmit?.error_level}
                  label={t('employees.level')}
                >
                  <MenuItem value="0">{t('infoEmployee.selectLevel')}</MenuItem>
                  {uOptionEditProfile?.data?.level &&
                    uOptionEditProfile?.data?.level.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
            </Col>
            <Col md={6}>
              <div className="select-field mt-3">
                <SelectComponent
                  name="position_id"
                  valueDefault={formSubmit.position_id}
                  onHandleChangeSelect={handleInput}
                  error={formSubmit.error_position}
                  label={t('employees.position')}
                >
                  <MenuItem value="0">{t('employees.filterPosition')}</MenuItem>
                  {uOptionEditProfile?.data?.position &&
                    uOptionEditProfile?.data?.position.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
            </Col>
            <Col md={6}>
              <div className="select-field mt-3">
                <SelectComponent
                  name="group_id"
                  valueDefault={formSubmit.group_id}
                  onHandleChangeSelect={handleInput}
                  error={formSubmit?.error_group_id}
                  label={t('infoEmployee.groupPersonal')}
                >
                  <MenuItem value="0">{t('infoEmployee.selectCategoryEmployees')}</MenuItem>
                  {uGetGroupUser?.data &&
                    uGetGroupUser?.data?.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={6}>
              <ButtonComponent text={t('common.cancel')} addClass="w-100 btn-bg-yellow2" handleClick={toggle} />
            </Col>
            <Col md={6}>
              <ButtonComponent
                text={t('common.save')}
                addClass="w-100"
                type="submit"
                isLoading={stateButton}
                hasDisabled={stateButton}
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>
    </>
  );
};

export default ModalAddEmployee;
