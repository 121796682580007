import { ButtonBase, Grid, InputBase } from '@mui/material';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { debounce } from '@material-ui/core';

import Assets from '..';
import iconLoading from 'assets/img/icons/loading.png';
import SearchComponent from 'components/Search';
import { useListWareHouse } from 'hook/useAssets';
import ModalRadio from 'components/ModalRadio';
import {
  CHOOSE_HANOVER,
  CHOOSE_RECALL,
  CHOOSE_EDIT_STT,
  CHOOSE_CREATE_NEW,
  CHOOSE_TRANSFER,
  CHOOSE_ALL,
  CHOOSE_DELETE,
} from 'constants/Assets';
import CustomInputDate from 'components/InputYupAndMui/CustomInputDate';
import { convertFormatTimeSend } from 'views/pages/MasterData/SpecialTimekeeping/ultil';
import './style.scss';
import ListActivityAssets from './ListActivityAssets';
import { useApiHistoryAssets } from 'hook/useAssets';
import { useListCategoryAssets } from 'hook/useAssets';
import { MAX_LENGTH_20 } from 'constants/index.js';

function ActivityAssets() {
  // Create
  const { t } = useTranslation();
  const [isModals, setIsModals] = useState({ ware_house: false, category: false, activity_id: false });
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const [filter, setFilter] = useState({
    users: '',
    assets: '',
    warehouse_id: '',
    asset_category_id: '',
    activity_id: '',
    start_date: null,
    end_date: convertFormatTimeSend(new Date(), 'DD/MM/yyyy'),
  });

  // Watch value autocomplete user
  const watchValueEndDate = useWatch({
    control,
    name: 'end_date',
    defaultValue: convertFormatTimeSend(new Date(), 'MM/DD/yyyy'),
  });

  const LIST_ACTION_WITH_ASSETS = [
    {
      value: CHOOSE_ALL,
      labelName: t('employees.all'),
    },
    {
      value: CHOOSE_HANOVER,
      labelName: t('assets.handover'),
    },
    {
      value: CHOOSE_RECALL,
      labelName: t('assets.recall'),
    },
    {
      value: CHOOSE_EDIT_STT,
      labelName: t('assets.assetsActivity.editStatus'),
    },
    {
      value: CHOOSE_CREATE_NEW,
      labelName: t('assets.createNew'),
    },
    {
      value: CHOOSE_TRANSFER,
      labelName: t('assets.transfer'),
    },
    {
      value: CHOOSE_DELETE,
      labelName: t('assets.deleteAsset'),
    },
  ];

  // Reset
  const handleReset = () => {
    setFilter({
      users: '',
      assets: '',
      warehouse_id: '',
      asset_category_id: '',
      activity_id: '',
      start_date: null,
      end_date: null,
    });
    setValue('start_date', null);
    setValue('end_date', convertFormatTimeSend(new Date(), 'MM/DD/yyyy'));
  };

  // Filter by name employee
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchNameEmployee = useCallback(
    debounce((newValue) => {
      setFilter({
        ...filter,
        users: newValue,
      });
    }),
  );

  // Filter by assets
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchAssets = useCallback(
    debounce((newValue) => {
      setFilter({
        ...filter,
        assets: newValue,
      });
    }),
  );

  // Filter by warehouse_id
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterByWarehouseId = useCallback(
    debounce((newValue) => {
      setFilter({
        ...filter,
        warehouse_id: Number(newValue) !== 0 ? Number(newValue) : null,
      });
    }),
  );

  // Filter by warehouse_id
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterByCategory = useCallback(
    debounce((newValue) => {
      setFilter({
        ...filter,
        asset_category_id: Number(newValue) !== 0 ? Number(newValue) : null,
      });
    }),
  );

  // Filter by warehouse_id
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterByAction = useCallback(
    debounce((newValue) => {
      setFilter({
        ...filter,
        activity_id: Number(newValue) !== 0 ? Number(newValue) : null,
      });
    }),
  );

  // Filter by start time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterByStartTime = useCallback(
    debounce((newValue) => {
      setFilter({
        ...filter,
        start_date: convertFormatTimeSend(newValue, 'DD/MM/yyyy'),
      });
    }),
  );

  // Filter by end time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterByEndTime = useCallback(
    debounce((newValue) => {
      setFilter({
        ...filter,
        end_date: convertFormatTimeSend(newValue, 'DD/MM/yyyy'),
      });
    }),
  );

  // data
  const {
    data: uHistoryAssets,
    isLoading: isLoadingHistoryAssets,
    hasNextPage,
    fetchNextPage,
  } = useApiHistoryAssets({
    ...filter,
    warehouse_id: filter?.warehouse_id === '' ? null : filter?.warehouse_id,
    asset_category_id: filter?.asset_category_id === '' ? null : filter?.asset_category_id
  });

  const { data: uListWareHouse, isLoading: isLoadingListWareHouse } = useListWareHouse();
  let LIST_WAREHOUSE_ASSETS = uListWareHouse?.map(({ id, name }) => ({ value: id, labelName: name }));
  if (LIST_WAREHOUSE_ASSETS?.length) {
    LIST_WAREHOUSE_ASSETS = [{ value: CHOOSE_ALL, labelName: t('employees.all') }, ...LIST_WAREHOUSE_ASSETS]
  }

  const { data: uListCategoryAssets, isLoading: isLoadingListCategoryAssets } = useListCategoryAssets();
  let LIST_CATEGORY_ASSETS = uListCategoryAssets?.map(({ id, name }) => ({ value: id, labelName: name }));
  if (LIST_CATEGORY_ASSETS?.length) {
    LIST_CATEGORY_ASSETS = [{ value: CHOOSE_ALL, labelName: t('employees.all') }, ...LIST_CATEGORY_ASSETS]
  }

  // Reload scroll to top
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const getDefaultValue = (value) => {
    return Number(value) || null
  }

  return (
    <Grid container className="list-assets activity-assets">
      {/* Swiper */}
      <Grid container className="nav-fixed">
        <Grid item md={8} xs={12}>
          <Assets />
        </Grid>
      </Grid>

      {/* Content main */}
      <Grid item xs={9}>
        <ListActivityAssets
          data={uHistoryAssets}
          isLoading={isLoadingHistoryAssets}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      </Grid>

      {/* Filter */}
      <Grid item xs={3}>
        <div className="box-filter">
          {/* Search by name */}
          <div className={classNames(`text padding-element-filter`)}>
            {/* Assets name search */}
            <SearchComponent
              txtPlaceholder={t('assets.assetsDepreciationTable.findByNameEmploy')}
              addClass="input-search-box"
              handleSearch={handleSearchNameEmployee}
              maxLength={MAX_LENGTH_20}
              defaultValue={filter?.users}
            />
          </div>

          {/* Search by code */}
          <div className={classNames(`text padding-element-filter`)}>
            {/* Assets code search */}
            <SearchComponent
              txtPlaceholder={t('assets.assetsDepreciationTable.findByNameAssets')}
              addClass="input-search-box"
              handleSearch={handleSearchAssets}
              maxLength={MAX_LENGTH_20}
              defaultValue={filter?.assets}
            />
          </div>

          {/* Warehouse */}
          <div className={classNames(`text padding-element-filter`)}>
            <InputBase
              disabled
              type="text"
              name="warehouse_id"
              className={classNames('exampleSelectMulti', filter?.warehouse_id !== '' && 'text-input-dark')}
              onClick={() => {
                setIsModals((prev) => {
                  return { ...prev, ware_house: true };
                });
              }}
              placeholder={t('assets.warehouseName')}
              value={
                filter?.warehouse_id !== ''
                  ? LIST_WAREHOUSE_ASSETS?.filter((item) => item.value === Number(filter?.warehouse_id))[0]?.labelName
                  : ''
              }
            />
            <ModalRadio
              items={LIST_WAREHOUSE_ASSETS}
              toggle={() => {
                setIsModals((prev) => {
                  return { ...prev, ware_house: !prev.ware_house };
                });
              }}
              modal={isModals.ware_house}
              title={t('masterData.warehouse')}
              footer={t('common.search')}
              loading={isLoadingListWareHouse}
              onSubmit={handleFilterByWarehouseId}
              defaultValue={getDefaultValue(filter?.warehouse_id)}
            />
          </div>

          {/* Category */}
          <div className={classNames(`text padding-element-filter`)}>
            <InputBase
              disabled
              type="text"
              name="category"
              className={classNames('exampleSelectMulti', filter?.asset_category_id !== '' && 'text-input-dark')}
              onClick={() => {
                setIsModals((prev) => {
                  return { ...prev, category: true };
                });
              }}
              placeholder={t('common.category')}
              value={
                filter?.asset_category_id !== ''
                  ? LIST_CATEGORY_ASSETS?.filter((item) => item.value === Number(filter?.asset_category_id))[0]
                    ?.labelName
                  : ''
              }
            />
            <ModalRadio
              items={LIST_CATEGORY_ASSETS}
              toggle={() => {
                setIsModals((prev) => {
                  return { ...prev, category: !prev.category };
                });
              }}
              modal={isModals.category}
              title={t('common.category')}
              footer={t('common.search')}
              loading={isLoadingListCategoryAssets}
              onSubmit={handleFilterByCategory}
              defaultValue={getDefaultValue(filter?.asset_category_id)}
            />
          </div>

          {/* activity_id */}
          <div className={classNames(`text padding-element-filter`)}>
            <InputBase
              disabled
              type="text"
              name="activity_id"
              className={classNames('exampleSelectMulti', filter?.activity_id !== '' && 'text-input-dark')}
              onClick={() => {
                setIsModals((prev) => {
                  return { ...prev, activity_id: true };
                });
              }}
              placeholder={t('assets.act')}
              value={
                filter?.activity_id !== ''
                  ? LIST_ACTION_WITH_ASSETS?.filter((item) => item.value === Number(filter?.activity_id))[0]?.labelName
                  : ''
              }
            />
            <ModalRadio
              items={LIST_ACTION_WITH_ASSETS}
              toggle={() => {
                setIsModals((prev) => {
                  return { ...prev, activity_id: !prev.activity_id };
                });
              }}
              modal={isModals.activity_id}
              title={t('assets.act')}
              footer={t('common.search')}
              loading={isLoadingListWareHouse}
              onSubmit={handleFilterByAction}
              defaultValue={Number(filter?.activity_id)}
            />
          </div>

          {/* Date */}
          <div className="d-flex padding-element-filter justify-content-between align-items-center">
            <div>
              <CustomInputDate
                control={control}
                label={t('masterData.placeholderStartDate')}
                defaultValue={null}
                name="start_date"
                register={register}
                useWatch={useWatch}
                setValue={setValue}
                errors={errors}
                max={watchValueEndDate}
                handleChange={handleFilterByStartTime}
              />
            </div>
            <span className="ml-1 mr-1">~</span>
            <div>
              <CustomInputDate
                control={control}
                label={t('masterData.placeholderEndDate')}
                defaultValue={moment().toDate()}
                name="end_date"
                register={register}
                useWatch={useWatch}
                setValue={setValue}
                errors={errors}
                min={moment(filter?.start_date, 'DD/MM/yyyy').toDate()}
                max={moment().toDate()}
                handleChange={handleFilterByEndTime}
              />
            </div>
          </div>

          <div className="border-top">
            <ButtonBase onClick={() => handleReset()} className={classNames(`reset border-top`)} size="lg">
              <img src={iconLoading} alt="Reset" width="20px" className="img-reset" /> &emsp;
              {t('common.reset')}
            </ButtonBase>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default ActivityAssets;
