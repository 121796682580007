import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Col, Row, Form } from 'reactstrap';
import { HiPencil } from 'react-icons/hi';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import iconAchievement from 'assets/img/icons/achievement.png';
import imgCup from 'assets/img/cup.png';

import styles from './../style.module.scss';

import AlertComponent from 'components/Alert';
import ButtonComponent from 'components/Buttons';
import DateDayComponent from 'components/DateTime/DateDay.js';
import InputField from 'components/Input/InputField';
import ModalComponent from 'components/Modal';
import { HTTP_OK, HTTP_INTERNAL_SERVER_ERROR, HTTP_UNPROCESSABLE_ENTITY, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { MAX_LENGTH_100 } from 'constants/index.js';
import { USE_USER_PROFILE } from 'constants/ProfileEmployee';
import { apiCreateAchievement, apiUpdateAchievement, apiDeleteAchievement } from 'api/profileEmployee';
import { customDate } from 'helpers/formatDate';
import { ThemContext } from 'layouts/Admin';
import { ROLE_VIEW, DISABLE_ROLE } from 'constants/index.js';

const FORM_DEFAULT = {
  id: 0,
  name: '',
  name_error: '',
  start_date: null,
  start_date_error: '',
};

const Achievement = (props) => {
  const { uProfileUser, userId } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dataInforUser = useContext(ThemContext);
  const [message, setMessage] = useState('');
  const [isShowAlert, setShowAlert] = useState(false);
  const [isShowModal, setShowModal] = useState(false);
  const [formAchievement, setFormAchievement] = useState(FORM_DEFAULT);
  const [isBtnSubmit, setIsBtnSubmit] = useState(false);
  const [textModal, setTextModal] = useState({
    titleAdd: t('infoEmployee.addAchievements'),
    textButton: t('common.save'),
  });

  const openAddModal = () => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    setTextModal({
      titleAdd: t('infoEmployee.addAchievements'),
      textButton: t('common.save'),
    });
    setShowModal(true);
    setFormAchievement({ ...formAchievement, ...FORM_DEFAULT });
  };

  const openEditModal = (achievement) => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    setTextModal({
      titleAdd: t('infoEmployee.editAchievements'),
      textButton: t('common.update'),
    });
    setFormAchievement({
      ...formAchievement,
      id: achievement.user_achievement_id,
      name: achievement.user_achievement_name,
      start_date: achievement.date ? moment(achievement.date, 'DD/MM/YYYY') : null,
      start_date_error: '',
    });
    setShowModal(true);
  };

  const handleDeleteAchievement = (achievementId) => {
    if (dataInforUser?.hr_permission?.users_permission === ROLE_VIEW) {
      return;
    }
    confirmAlert({
      title: t('common.doYouWantToDeletedData?'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteAchievement(achievementId);

            if (response.data.status === HTTP_OK) {
              setMessage(response.data.messages);
              setShowAlert(true);
              queryClient.invalidateQueries(USE_USER_PROFILE);
            }
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_INTERNAL_SERVER_ERROR) {
              setMessage(response.data.messages);
              setShowAlert(true);
            }
          },
        },
      ],
    });
  };

  const handleInput = (e) => {
    setFormAchievement({
      ...formAchievement,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsBtnSubmit(true);
    setFormAchievement({ ...formAchievement, name_error: '', start_date_error: '' });

    if (!formAchievement.name) {
      setFormAchievement({ ...formAchievement, name_error: t('common.thisIsObligation') });
      setIsBtnSubmit(false);
      return;
    }

    const data = {
      name: formAchievement.name,
      start_date: customDate(formAchievement.start_date, 'DD/MM/YYYY') || null,
      user_id: userId,
    };

    let response = {};

    if (formAchievement.id) {
      response = await apiUpdateAchievement(formAchievement.id, data);
    } else {
      response = await apiCreateAchievement(data);
    }

    if (response) setIsBtnSubmit(false);
    if (response?.status === HTTP_UNPROCESSABLE_ENTITY) {
      if (response?.data.messages?.name) {
        setFormAchievement({ ...formAchievement, name_error: response.data.messages.name[0] });
      }
      if (response?.data.messages?.start_date) {
        setFormAchievement({ ...formAchievement, start_date_error: response.data.messages.start_date[0] });
      }
      return;
    }
    if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
      setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
      setShowAlert(true);
    }
    if (response?.data?.status === HTTP_OK) {
      setShowModal(false);
      setFormAchievement({ ...formAchievement, ...FORM_DEFAULT });
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.invalidateQueries(USE_USER_PROFILE);
    }
  };

  const handleChangeDate = (date) => {
    setFormAchievement({
      ...formAchievement,
      start_date: date,
    });
  };

  return (
    <>
      <div className={styles.profileBoxAchie}>
        <Row className={styles.rowTitle}>
          <Col xs="10">
            <div className={styles.profileTitle}>
              <img alt={iconAchievement} className={styles.imgTitle} src={iconAchievement} />
              {t('infoEmployee.titleAchievement')}
            </div>
          </Col>
          <Col xs="2">
            {uProfileUser?.data?.user_achievements && uProfileUser?.data?.user_achievements.length !== 0 && (
              <div
                className={classNames(
                  `${styles.editDeleteButton} mr--15 ${
                    dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                  }`,
                )}
              >
                <IoIosAdd onClick={openAddModal} />
              </div>
            )}
          </Col>
        </Row>

        <div className="mt-3"></div>

        <div className={styles.salaryBoxParent}>
          <Row>
            {uProfileUser?.data?.user_achievements?.length ? (
              uProfileUser.data.user_achievements.map((achievement, index) => (
                <Col key={index} md="6">
                  <div className={styles.certificationBox}>
                    <div className={styles.leftCerti}>
                      <img alt={imgCup} src={imgCup} />
                    </div>
                    <div className={styles.rightCerti}>
                      <div className={styles.profileContentInfo}>
                        <p className={styles.widthCustom}>{achievement.user_achievement_name}</p>
                      </div>
                      <div className={classNames(`${styles.profileContentInfo} ${styles.time} `)}>
                        {t('infoEmployee.time')}: {achievement?.date}
                      </div>
                      <div className="clear-both">
                        <div
                          className={`${styles.editDeleteButtonChild} ${
                            dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                          }`}
                        >
                          <HiPencil onClick={() => openEditModal(achievement)} />
                        </div>
                        <div
                          className={`${styles.editDeleteButtonChild} ${
                            dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                          }`}
                        >
                          <MdDelete onClick={() => handleDeleteAchievement(achievement.user_achievement_id)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className={styles.achievementNull}>
                <div
                  className={`${styles.editDeleteButton} ${
                    dataInforUser?.hr_permission?.users_permission === ROLE_VIEW && DISABLE_ROLE
                  }`}
                >
                  <IoIosAdd onClick={openAddModal} />
                </div>
                <div className={styles.profileContentInfoAlert}>{t('infoEmployee.addAchievements')}</div>
              </div>
            )}
          </Row>
        </div>

        <ModalComponent toggle={() => setShowModal(!isShowModal)} isShowModal={isShowModal} title={textModal.titleAdd}>
          <Form className="mt-3" onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <div className="fieldset-group mb-4">
                  <InputField
                    label={t('employees.nameAchievements')}
                    variant="outlined"
                    name="name"
                    maxLength={MAX_LENGTH_100}
                    defaultValue={formAchievement?.name}
                    onHandleInput={handleInput}
                    isRequired={true}
                    error={formAchievement.name_error}
                  />
                </div>
              </Col>

              <Col md={12} className="mb-4">
                <DateDayComponent
                  label=""
                  valueDefault={formAchievement.start_date}
                  onChangeHandle={handleChangeDate}
                  error={formAchievement?.start_date_error}
                  placeholder={t('common.time')}
                />
                {formAchievement.start_date ? (
                  <div
                    className={styles.iconClose}
                    onClick={() => handleChangeDate(null)}
                  >
                    <IoMdClose />
                  </div>
                ) : null}
              </Col>

              <Col className="md-6 ">
                <ButtonComponent
                  text={t('common.cancel')}
                  addClass="w-100 btn-bg-yellow2"
                  handleClick={() => setShowModal(false)}
                />
              </Col>
              <Col className="md-6">
                <ButtonComponent
                  text={textModal.textButton}
                  addClass="w-100"
                  handleClick={() => setShowModal(true)}
                  type="submit"
                  hasDisabled={isBtnSubmit}
                  isLoading={isBtnSubmit}
                />
              </Col>
            </Row>
          </Form>
        </ModalComponent>

        <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
          {message}
        </AlertComponent>
      </div>
    </>
  );
};

export default Achievement;
