
import {
  apiGetRequestTotal,
  apiGetRequestTable,
  apiGetCategoryId,
  apiListTypeOfCategoryHr,
  apiListOT,
  apiListOTDemo,
  apiGetDivision
} from 'api/timekeeping.js';
import { apiListProjects } from 'api/timekeepingProject.js'
import { HTTP_OK } from 'constants/StatusCode.js';
import { useQuery } from 'react-query';

import * as Constants from '../constants/TimeKeeping.js';

export function useGetCategoryId(idMappingRequest) {
  const { data, error, isLoading } = useQuery(Constants.USE_GET_CATEGORY_ID, async () => {
    const response = await apiGetCategoryId(idMappingRequest);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useGetListTypeOfCategoryHr(params) {
  const { data, error, isLoading } = useQuery(
    [Constants.USE_LIST_TYPE_REQUEST_BY_CATEGORY_HR, params],
    async () => {
      const response = await apiListTypeOfCategoryHr(params);
      if (response.status === HTTP_OK) {
        return response?.data?.data;
      }
      return null;
    },
    {
      enabled: params.category_id ? true : false,
    },
  );

  return { data, error, isLoading };
}

export function useGetTotalTimekeepingRequest(params) {
  const { data, error, isLoading } = useQuery(
    [Constants.USE_TOTAL_TIMEKEEPING_REQUEST, params],
    async () => {
      const response = await apiGetRequestTotal(params);
      if (response.status === HTTP_OK) {
        return response?.data?.data;
      }
      return null;
    },
    {
      enabled: params.category_id ? true : false,
    },
  );

  return { data, error, isLoading };
}

export function useGetGetRequestTable(params, idCategory) {
  const { data, error, isLoading } = useQuery(
    [Constants.USE_TIMEKEEPING_REQUEST, params],
    async () => {
      const response = await apiGetRequestTable(params);
      if (response.status === HTTP_OK) {
        return response?.data?.data;
      }
      return null;
    },
    {
      enabled: params.category_id ? true : false,
    },
  );

  return { data, error, isLoading };
}

export function useGetListOT(params) {
  const { data, error, isLoading } = useQuery([Constants.USE_LIST_OT, params], async () => {
    const response = await apiListOT(params);
    if (response.status === HTTP_OK) {
      return response?.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useGetListProject(params) {
  const { data, error, isLoading } = useQuery([Constants.USE_LIST_PROJECTS, params], async () => {
    const response = await apiListProjects(params);
    if (response.status === HTTP_OK) {
      return response?.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useGetListOTDemo(params) {
  const { data, error, isLoading } = useQuery([Constants.USE_LIST_OT_DEMO, params], async () => {
    const response = await apiListOTDemo(params);
    if (response.status === HTTP_OK) {
      return response?.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useListDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_DIVISION_TIMEKEEPING_DASHBOARD, params], async () => {
    if (params?.division_id === 0) {
      params = {};
    }
    const response = await apiGetDivision(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}
