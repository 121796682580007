import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import defaultAvatar from 'assets/img/icons/default-avatar.svg';

import styles from './styles.module.scss';
import { handleOnError } from 'helpers';

export default function AccordionBox({ divisions = [] }) {
  const { t } = useTranslation();

  const [divisionOpen, setDivisionOpen] = useState({});

  const toggle = (division) => {
    setDivisionOpen({ ...divisionOpen, [division.division_id]: !divisionOpen[division.division_id] });
  };

  return (
    <div className={styles.accordionBox}>
      <div className={classNames(styles.beforeLine, { [styles.highlineHeight]: true })}></div>

      <div className={styles.boxBody}>
        {divisions?.map((division) => (
          <div key={division.division_id}>
            <div className={styles.divisionHeader} onClick={() => toggle(division)}>
              <div className={styles.divisionInfo}>
                <img onError={handleOnError} src={division.avatar || defaultAvatar} alt="" />
                <div className={styles.info}>
                  <div className={styles.name}>{division.division_name}</div>
                  <div className={styles.leader}>
                    {t('divisionDiagram.manager')}
                     <strong>{division?.leader === null ? t('divisionDiagram.notUpdated') : division?.leader}</strong>
                  </div>
                </div>
              </div>

              <div className={styles.role}>
                {division?.total?.map((el, index) => (
                  <div className={styles.roleItem} key={index}>
                    {el.level}: {el.members}
                  </div>
                ))}
              </div>

              {divisionOpen[division.division_id] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </div>

            <div
              className={classNames(styles.divisionBody, { [styles.visibility]: !!divisionOpen[division.division_id] })}
            >
              {division?.members?.map((member, index) => (
                <div className={styles.memberInfo} key={index}>
                  <img onError={handleOnError} src={member.avatar || defaultAvatar} alt="" />
                  <div className={styles.info}>
                    <div className={styles.name}>{member.name}</div>
                    <div className={styles.role}>
                      {member.position_name} - {member.level}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
