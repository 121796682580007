export const ZERO_STATUS = 0;
export const ONE_STATUS = 1;
export const TWO_STATUS = 2;
export const THREE_STATUS = 3;
export const USE_LIST_ALL_CATEGORY_ASSETS = 'assets_list_category';
export const USE_LIST_ALL_HR_WAREHOUSE = 'assets_hr_warehouse';

export const USE_LIST_ASSETS = 'use_list_assets';
export const USE_LIST_CATEGORY_ASSETS = 'use_list_category_assets';
export const USE_LIST_TYPE_ASSETS = 'use_list_type_assets';
export const USE_LIST_WARE_HOURSE = 'use_list_ware_hourse';

export const USE_GENERATE_CODE = 'use_generate_code';
export const USE_DETAIL_ASSETS = 'assets_detail';
export const USE_HISTORY_ASSETS = 'assets_history';

export const USE_LIST_BRIEF_RENT_PRICE = 'use_list_brief_rent_price';
export const USE_LIST_DETAIL_RENT_PRICE = 'use_list_detail_rent_price';
export const USE_TOTAL_ASSET_USING_OF_DIVISION = 'use_total_asset_using_of_division';
export const USE_LIST_REQUEST_ASSETS = 'use_list_request_assets';
export const USE_TOTAL_LIST_REQUEST_ASSETS = 'use_total_list_request_assets';
export const USE_GET_RENTAL_PRICES = 'get_rental_prices'

export const USE_DASHBOARD_ASSET = 'USE_DASHBOARD_ASSET';
export const USE_CATEGORY_ASSET = 'USE_CATEGORY_ASSET';
export const USE_DIVISION_ASSET = 'USE_DIVISION_ASSET';
export const USE_USER_ASSET = 'USE_USER_ASSET';

export const LIST_TYPE_GOOD = 1;
export const LIST_TYPE_UNDER_WARRANTY = 2;
export const LIST_TYPE_WAIT_FOR_LIQUIDATION = 3;
export const LIST_TYPE_LIQUIDATION = 4;
export const LIST_TYPE_BROKEN = 5;
export const LIST_TYPE_MISSING = 6;
export const LIST_TYPE_WAIT_FOR_APPROVE = 7;
export const CANCEL = 8;
export const ACTION_NOT_VERIFY = 0;
export const ACTION_HAND_OVER = 1;
export const ACTION_RECALL = 2;
export const ACTION_TRANSFER = 3;
export const ACTION_REMOTE = 4;
export const ACTION_CHANGE_STATUS = 4;
export const ACTION_IMPORT = 5;
export const ACTION_CREATE = 6;
export const ACTION_APPROVE = 7;
export const ACTION_CANCEL = 8;
export const STATUS_REMOTE = 2;
export const STATUS_STORAGE = 3;
export const STATUS_USING = 1;

export const CHOOSE_ALL = 0;
export const CHOOSE_HANOVER = 1;
export const CHOOSE_RECALL = 2;
export const CHOOSE_TRANSFER = 3;
export const CHOOSE_EDIT_STT = 4;
export const CHOOSE_CREATE_NEW = 5;
export const CHOOSE_DELETE = 6;
export const CHOOSE_APPROVE = 7;
export const CHOOSE_CANCEL= 8;
// Depreciation table
export const USE_ASSET_DEPRECIATION = 'use_asset_depreciation';
export const USE_ASSET_DEPRECIATION_CATEGORY = 'use_asset_depreciation_category';
export const HAVE_VALUE_DEPRECIATION = 1;
export const HAVE_NO_VALUE_DEPRECIATION = 2;
export const HAVE_NOT_CALC_VALUE_DEPRECIATION = 3;

// History assets
export const USE_HISTORY_ASSET = 'use_history_asset';
export const DAY_OF_WEEK_SUNDAY = 1;
export const DAY_OF_WEEK_MONDAY = 2;
export const DAY_OF_WEEK_TUESDAY = 3;
export const DAY_OF_WEEK_WEDNESDAY = 4;
export const DAY_OF_WEEK_THURSDAY = 5;
export const DAY_OF_WEEK_FRIDAY = 6;
export const DAY_OF_WEEK_SATURDAY = 7;

// Export Import Inventory
export const USE_LIST_EXPORT_IMPORT_INVENTORY = 'use_list_export_import_inventory';

// List menu assets
export const DASHBOARD = 0;
export const ASSETS_LIST = 1;
export const REQUEST_LIST = 2;
export const RENTAL_TABLE = 3;
export const ACTION = 4;
export const EXPORT_IMPORT_INVENTORY = 5;
export const DEPRECIATION_TABLE = 6;

export const ID_MAPPING_REQUEST = 5;

//List status assets
export const STATUS_ASSETS_GOOD = 1;
export const STATUS_ASSETS_MAINTAINER = 2;
export const STATUS_ASSETS_WAIT = 3;
export const STATUS_ASSETS_SELL = 4;
export const STATUS_ASSETS_BROKEN = 5;
export const STATUS_ASSETS_LOST = 6;
export const STATUS_ASSETS_WAITING_APPROVE = 7;
export const STATUS_ASSETS_CANCEL = 8;
export const MAX_LENGTH_PRICE = 11;
export const MAX_LENGTH_CONTENT = 20;
export const START_STRING_SLICE = 0;

//select type add asset
export const ADD_ONE_ASSET = 1;
export const ADD_MORE_ASSET = 2;
