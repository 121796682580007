import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import Nav from '../Nav';
import Box from 'components/BoxDiagram';
import AccordionBox from 'components/BoxDiagram/Accordion';
import Loading from 'components/Loading';

import styles from './styles.module.scss';

import { useGetSkillDiagram } from 'hook/useDepartment';

const SkillDiagram = () => {
  const { data: udata } = useGetSkillDiagram();

  const [positions, setPositions] = useState([]);
  const [skills, setSkills] = useState([]);
  const [divisions, setDivisions] = useState([]);

  const [positionActive, setPositionActive] = useState({
    id: null,
    index: 0,
  });

  const [skillActive, setSkillActive] = useState({
    id: null,
    index: 0,
  });

  useEffect(() => {
    if (udata) {
      setPositionActive({
        id: udata?.data[0]?.position_id,
        index: 0,
      });

      setSkillActive({
        id: udata?.data[0]?.skills[0]?.skill_id,
        index: 0,
      });

      setPositions(udata?.data);
      setSkills(udata?.data[0]?.skills);
      setDivisions(udata?.data[0]?.skills[0]?.divisions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [udata]);

  useEffect(() => {
    if (udata) {
      const position = udata?.data?.find((position) => position.position_id === positionActive.id);
      const skills = position?.skills || [];
      const skill = skills?.find((skill) => skill.skill_id === skillActive.id);
      const divisions = skill?.divisions || [];

      setSkills(skills);
      setDivisions(divisions);
    }
  }, [udata, positionActive, skillActive]);

  useEffect(() => {
    if (udata) {
      const position = udata?.data?.find((position) => position.position_id === positionActive.id);
      const skills = position?.skills || [];

      setSkillActive({
        id: skills[0]?.skill_id,
        index: 0,
      });
    }
  }, [udata, positionActive]);

  const amelaVN = {
    isActive: true,
    name: 'Amela',
    total_member: udata?.total,
  };

  return (
    <div className={styles.diagramSkill}>
      <div className="nav-fixed">
        <Nav />
      </div>
      {udata ? (
        <Grid container className={styles.diagram}>
          <Grid item xs={2} minWidth={200}>
            <Box
              item={amelaVN}
              isActive={true}
              isHighline={true}
              totalItemClass="circle-orange"
              borderColorClass="border-orange"
              hasParent={false}
              totalChidItem={udata?.data?.length}
            />
          </Grid>

          <Grid item xs={2} minWidth={200}>
            {positions?.map((item, index) => (
              <Box
                key={item.branch_id}
                item={{
                  ...item,
                  id: item.position_id,
                  name: item.position_name,
                  total_member: item.total_position,
                }}
                index={index}
                isActive={positionActive.id === item.position_id}
                isHighline={index < positionActive?.index}
                handleSelectBox={setPositionActive}
                totalItemClass="circle-green"
                codeItemClass="bg-code-green"
                borderColorClass="border-green"
                isLastItem={index === positions?.length - 1}
                totalChidItem={item?.skills?.length}
                hasParent={true}
              />
            ))}
          </Grid>

          <Grid item xs={2} minWidth={200}>
            {Array.from(Array(positionActive?.index).keys()).map((item, index) => (
              <Box key={index} />
            ))}
            {skills?.map((item, index) => (
              <Box
                key={item.skill_id}
                item={{
                  ...item,
                  id: item.skill_id,
                  name: item.skill_name,
                  total_member: item.total_skill,
                }}
                index={index}
                isActive={skillActive.id === item.skill_id}
                isHighline={index < skillActive?.index}
                handleSelectBox={setSkillActive}
                totalItemClass="circle-pink"
                codeItemClass="bg-code-pink"
                borderColorClass="border-pink"
                isLastItem={index === skills?.length - 1}
                totalChidItem={item?.divisions?.length}
                hasParent={true}
              />
            ))}
          </Grid>

          <Grid item xs={6} minWidth={200}>
            {!!divisions.length && (
              <>
                {Array.from(Array(positionActive?.index + skillActive?.index).keys()).map((item, index) => (
                  <Box key={index} />
                ))}
                <AccordionBox divisions={divisions} />
              </>
            )}
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default SkillDiagram;
