import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Doughnut } from 'react-chartjs-2';

import styles from './style.module.scss';

const DoughnutComponent = (props) => {
  const { data, mapNote, bgColor, title, total, options, addClass, isBorderBottom } = props;
  const [number, setNumber] = useState(1);

  useEffect(() => {
    if (mapNote && mapNote.length) {
      setNumber(mapNote.length);
    }
  }, [isBorderBottom, mapNote]);

  return (
    <div className={classNames(`${styles.boxMap} ${addClass} d-flex align-items-center`)}>
      <div className={classNames(`${styles.mapDoughnut} ct-doughnut`)}>
        <div className={styles.total}>{total}</div>
        <Doughnut data={data} width={1} height={1} options={options} />
      </div>
      <div className={classNames(`${styles.infoMap} ct-info-map`)}>
        <h5 className={styles.title}>{title}</h5>
        <div className={classNames(`${styles.boxNote} ct-box-note`)}>
          {mapNote &&
            mapNote.map((item, index) => {
              return (
                <div
                  className={classNames(
                    `${styles.itemNote} item-note ${
                      isBorderBottom &&
                      ((number % 2 !== 0 && index + 1 === number - 1) ||
                        number <= 2 ||
                        (number % 2 === 0 && (index + 1 === number || index + 1 === number - 1)))
                        ? styles.notBorder
                        : ''
                    }`,
                  )}
                  key={index}
                >
                  <p>
                    <span className={styles.bgRadio} style={{ backgroundColor: bgColor[index] }}></span>
                    <span>{item.name + (item.total > 0 && ':')}</span>{' '}
                    <span className="total"> {item.total > 0 ? item.total : ''}</span>
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DoughnutComponent;
