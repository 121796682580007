import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';
import { COLOR_COMMON } from 'constants/ColorMap';
import { BG_TOOLTIP_TIMEKEEPING_TIME_LATE_OT } from 'constants/ColorMap';
import { COLOR_LATE_OT_COMPANY_TIMEKEEPING } from 'constants/ColorMap';
import { COLOR_TOOLTIP_TIMEKEEPING_TIME_LATE_OT } from 'constants/ColorMap';
import { formatNumberCurrency } from 'helpers/format';
import { formatHourNumber } from 'helpers/format';

export default function ChartDoughnut({ data, unit, textInfo }) {
  const { t } = useTranslation();

  const dataLabel = data?.map((item) => item?.division_name);
  const dataChart = data?.map((item) => item?.total_time);
  const dataMoney = data?.map((item) => item?.money);
  const totalChart = dataChart?.reduce((a, v) => (a = Number(a) + Number(v)), 0);

  const data1 = {
    responsive: false,
    labels: dataLabel,
    datasets: [
      {
        data: dataChart,
        backgroundColor: COLOR_COMMON,
        hoverOffset: 4,
      },
    ],
  };

  const options1 = {
    responsive: true,
    cutoutPercentage: 60,
    legend: {
      display: false,
      position: 'bottom',
    },
    tooltips: {
      displayColors: false,
      backgroundColor: BG_TOOLTIP_TIMEKEEPING_TIME_LATE_OT,
      yPadding: 10,
      xPadding: 10,
      bodyFontSize: 12,
      titleFontColor: COLOR_LATE_OT_COMPANY_TIMEKEEPING,
      titleFontSize: 14,
      callbacks: {
        title: function (tooltipItem) {
          let index = tooltipItem[0].index;
          let text = dataLabel[index];
          return text;
        },

        label: function (tooltipItem, data) {
          let index = tooltipItem.index;
          let minute = dataChart[index];
          let money = dataMoney[index];

          if (unit === 'm') {
            return [
              `${t('timekeeping.dashboard.numberLateMinutes')}: ${minute}${unit}`,
              `${
                formatNumberCurrency(money)
                  ? t('timekeeping.dashboard.numberFines') + ':' + formatNumberCurrency(money) + t('common.currency')
                  : ''
              }`,
            ];
          }
          return [
            `${t('timekeeping.dashboard.timeOT')}: ${minute}${unit}`,
            `${
              formatNumberCurrency(money)
                ? t('timekeeping.dashboard.moneyOT') + ':' + formatNumberCurrency(money) + t('common.currency')
                : ''
            }`,
          ];
        },
        labelTextColor: () => COLOR_TOOLTIP_TIMEKEEPING_TIME_LATE_OT,
      },
    },
  };

  return (
    <div className={styles.boxChartNoteDoughnut}>
      <div className={styles.chartDoughnut}>
        {totalChart ? (
          <div className={styles.infoDoughnut}>
            <div className={styles.info}>
              <p className={styles.text}>{t('common.total')}</p>
              <p className={styles.total}>
                {formatHourNumber(String(totalChart).indexOf('.') >= 0 ? totalChart.toFixed(2) : totalChart)}
                {' ' + unit}
              </p>
            </div>
          </div>
        ) : data?.length ? (
          ''
        ) : (
          <div className="errorNoData color-red mt-4">{t('common.notData')}</div>
        )}
        <Doughnut data={data1} options={options1} height={250} />
      </div>
      <div className="mt-4">
        <ul className={styles.note}>
          {data?.map((item, index) => (
            <li key={index} className="d-flex justify-content-between">
              <div className={styles.text}>
                <span style={{ backgroundColor: COLOR_COMMON[index] }}></span>
                {item.division_name}
              </div>
              <div className={styles.time}>
                {formatHourNumber(item.total_time)}
                {' ' + unit}
              </div>
              <div className={styles.money}>
                {formatNumberCurrency(item.money)} {item.money && ' ' + t('common.currency')}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
