import { useInfiniteQuery, useQuery } from 'react-query';

import { HTTP_OK } from './../constants/StatusCode.js';
import {
  apiListAssets,
  apiListCategoryAssets,
  apiListTypeAssets,
  apiListWareHouse,
  apiGetListCategoryAssets,
  apiGetListHrWarehouse,
  apiGetListGenerateCode,
  apiListBriefRentPrice,
  apiListDetailRentPrice,
  apiTotalAssetUsingOfDivison,
} from 'api/assets';
import * as Constants from './../constants/Assets.js';
import {
  apiGetExportImportInventory,
  apiGetTotalAssetUser,
  apiGetTotalAssetDivision,
  apiDetailAssets,
  apiDetailHistoryAssets,
  apiListAssetsDepreciation,
  apiListCategoryDepreciation,
  apiHistoryAssets,
  apiGetTotalAssetCategory,
  apiGetTotalAssetDashBoard,
  apiGetListRequestAssets,
  apiGetTotalListRequestAssets,
  apiGetAssetRentalPrice,
  apiGetAssetRentalPriceAll,
} from 'api/assets.js';

export function useListRequestAssets(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_LIST_REQUEST_ASSETS, params],
    async () => {
      const response = await apiGetListRequestAssets(params);
      if (response.status === HTTP_OK) {
        return response;
      }

      return null;
    },
    {
      enabled: params.category_id ? true : false,
    },
  );

  return { ...response, error, isLoading };
}

export function useTotalListRequestAssets(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_TOTAL_LIST_REQUEST_ASSETS, params],
    async () => {
      const response = await apiGetTotalListRequestAssets(params);
      if (response.status === HTTP_OK) {
        return response;
      }

      return null;
    },
    {
      enabled: params.category_id ? true : false,
    },
  );

  return { ...response, error, isLoading };
}

export function useListAssets(params) {
  const {
    data: response,
    error,
    isLoading,
    refetch
  } = useQuery([Constants.USE_LIST_ASSETS, params], async () => {
    const response = await apiListAssets(params);
    if (response.status === HTTP_OK) {
      return response;
    }

    return null;
  });

  return { ...response, error, isLoading, refetch };
}

export function useListBriefRentPrice(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_BRIEF_RENT_PRICE, params], async () => {
    const response = await apiListBriefRentPrice(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListDetailRentPrice(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_DETAIL_RENT_PRICE, params], async () => {
    const response = await apiListDetailRentPrice(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useTotalAssetUsingOfDivison(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_TOTAL_ASSET_USING_OF_DIVISION, params], async () => {
    const response = await apiTotalAssetUsingOfDivison(params);
    if (response.status === HTTP_OK) {
      return response;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useListCategoryAllAssets(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_ALL_CATEGORY_ASSETS, params], async () => {
    const response = await apiGetListCategoryAssets(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useListCategoryAssets(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_CATEGORY_ASSETS, params], async () => {
    const response = await apiListCategoryAssets(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useListHrWarehouse(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_ALL_HR_WAREHOUSE, params], async () => {
    const response = await apiGetListHrWarehouse(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useListTypeAssets(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_TYPE_ASSETS, params], async () => {
    const response = await apiListTypeAssets(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useListWareHouse(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(Constants.USE_LIST_WARE_HOURSE, async () => {
    const response = await apiListWareHouse(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useGenerateCode(id, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [Constants.USE_GENERATE_CODE, params, id],
    async () => {
      const response = await apiGetListGenerateCode(id, params);
      if (response.status === HTTP_OK) {
        return response.data;
      }

      return null;
    },
    { enabled: id ? true : false },
  );

  return { ...response, error, isLoading };
}

export function useHistoryAssets(id, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_HISTORY_ASSETS, params, id], async () => {
    const response = await apiDetailHistoryAssets(id, params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

// apiDetailAssets

export function useDetailAssets(id, params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_DETAIL_ASSETS, params, id], async () => {
    const response = await apiDetailAssets(id, params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

// Depreciation
export function useAssetsDepreciation(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_ASSET_DEPRECIATION, params], async () => {
    const response = await apiListAssetsDepreciation(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetAssetDashboard(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_DASHBOARD_ASSET, params], async () => {
    const response = await apiGetTotalAssetDashBoard(params);

    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useAssetsDepreciationCategory(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_ASSET_DEPRECIATION_CATEGORY, params], async () => {
    const response = await apiListCategoryDepreciation(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetAssetCategory(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_ASSET_DEPRECIATION_CATEGORY, params], async () => {
    const response = await apiGetTotalAssetCategory(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

// History assets
export function useApiHistoryAssets(params) {
  const fetchData = async ({ pageParam = 1 }) => {
    const response = await apiHistoryAssets(pageParam, params);
    if (response.status === HTTP_OK) {
      return response;
    }
  };

  const { data, error, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [Constants.USE_HISTORY_ASSET, params],
    fetchData,
    {
      getNextPageParam: (lastPage) => {
        const totalItem = lastPage?.data?.data?.total || 0;
        if (totalItem === 0) return undefined;
        const totalPages = totalItem / Number(lastPage?.data?.data?.per_page || "1") + 1;
        const nextUrl = lastPage?.data?.data?.next_page_url;
        if (!nextUrl) return undefined;
        const nextPage = nextUrl.substring(7, nextUrl.length);
        if (!nextPage) return undefined;
        if (Number(nextPage) < Number(totalPages)) {
          return nextPage;
        }
        return undefined;
      },
    },
  );

  return { data, error, isLoading, fetchNextPage, hasNextPage };
}
export function useGetAssetDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_DIVISION_ASSET, params], async () => {
    const response = await apiGetTotalAssetDivision(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export function useGetAssetUser(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_USER_ASSET, params], async () => {
    const response = await apiGetTotalAssetUser(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

// Export Import Inventory
export function useGetExportImportInventory(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_EXPORT_IMPORT_INVENTORY, params], async () => {
    const response = await apiGetExportImportInventory(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }

    return null;
  });

  return { ...response, error, isLoading };
}

export const useGetAssetRentalPrice = (params, isAll = true) => {
  const { data, error, isLoading, refetch } = useQuery(
    [Constants.USE_GET_RENTAL_PRICES, params],
    async () => {
      let res;
      if (isAll) {
        res = await apiGetAssetRentalPriceAll(params)
      } else {
        res = await apiGetAssetRentalPrice(params)
      }
      if (res.status === HTTP_OK) {
        return res.data
      }
      return null
    }
  )
  return { data, error, isLoading, refetch }
}
