import { useQuery } from 'react-query'
import { REQUEST_LIST_USER_ACTIVE } from 'constants/User'
import { apiGetListUserInfoActive } from 'api/user'

export function useListUserInfoActive(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([REQUEST_LIST_USER_ACTIVE, params], async () => {
    const response = await apiGetListUserInfoActive(params)
    return response?.data
  })
  return { ...response, error, isLoading }
}
