import React from 'react';
import Zoom from '@mui/material/Zoom';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Fade from '@mui/material/Fade';

import IconHome from '../../../../assets/img/icons/icon-home.svg';
import Loading from 'components/Loading';
import IconPlus from '../../../../assets/img/icons/icon-plus.svg';
import IconBack from '../../../../assets/img/icons/icon-back.svg';
import IconUp from '../../../../assets/img/icons/icon-up.svg';
import iconApprove from '../../../../assets/img/icons/iconApprove.svg';
import iconCancel from '../../../../assets/img/icons/icon-cancel.svg';
import iconDelete from '../../../../assets/img/icons/icon-delete.svg';
import {
  CHOOSE_HANOVER,
  CHOOSE_RECALL,
  CHOOSE_CREATE_NEW,
  CHOOSE_TRANSFER,
  CHOOSE_EDIT_STT,
  CHOOSE_DELETE,
} from 'constants/Assets';
import {
  DAY_OF_WEEK_SUNDAY,
  DAY_OF_WEEK_MONDAY,
  DAY_OF_WEEK_TUESDAY,
  DAY_OF_WEEK_WEDNESDAY,
  DAY_OF_WEEK_THURSDAY,
  DAY_OF_WEEK_FRIDAY,
  DAY_OF_WEEK_SATURDAY,
} from 'constants/Assets';
import { StyledTooltip } from './StyledTooltip';
import {
  LIST_TYPE_GOOD,
  LIST_TYPE_UNDER_WARRANTY,
  LIST_TYPE_WAIT_FOR_LIQUIDATION,
  LIST_TYPE_LIQUIDATION,
  LIST_TYPE_BROKEN,
  LIST_TYPE_MISSING,
  LIST_TYPE_WAIT_FOR_APPROVE,
  CANCEL,
} from 'constants/Assets';
import configs from 'config';
import { CHOOSE_APPROVE } from 'constants/Assets';
import { CHOOSE_CANCEL } from 'constants/Assets';

const TooltipContent = ({ item, type }) => {
  const { t } = useTranslation();
  return (
    <div>
      <p className="employ-name">
        {t('employees.nameEmployee')}:{' '}
        {type === t('assets.assetsActivity.typeAdmin') ? item?.admin?.name : item?.detail?.name}
      </p>
      <p>
        {t('employees.code')}: {type === t('assets.assetsActivity.typeAdmin') ? item?.admin?.code : item?.detail?.code}
      </p>
      <p className="mt-0">
        {t('employees.email')}:{' '}
        {type === t('assets.assetsActivity.typeAdmin') ? item?.admin?.email : item?.detail?.email}
      </p>
    </div>
  );
};

function ListActivityAssets(props) {
  const { data, isLoading, hasNextPage, fetchNextPage } = props;
  const { t } = useTranslation();

  const handleScroll = (e) => {
    let bottom =
      Math.ceil(e.target.scrollHeight - e.target.scrollTop) === Math.ceil(e.target.clientHeight) ||
      Math.ceil(e.target.scrollHeight) - Math.ceil(e.target.scrollTop) === Math.ceil(e.target.clientHeight);

    if (bottom && hasNextPage) {
      fetchNextPage();
    }
  };

  // Transfer icon
  const transferIcon = (type, detail) => {
    if (detail.from === CHOOSE_APPROVE && detail.to === CANCEL) {
      return iconCancel;
    }
    if (type === CHOOSE_HANOVER || type === CHOOSE_RECALL) {
      return IconUp;
    }
    if (type === CHOOSE_TRANSFER) {
      return IconHome;
    }
    if (type === CHOOSE_EDIT_STT && detail.from !== CHOOSE_APPROVE) {
      return IconBack;
    }
    if (type === CHOOSE_CREATE_NEW) {
      return IconPlus;
    }
    if (detail.from === CHOOSE_APPROVE && detail.to === CHOOSE_HANOVER) {
      return iconApprove;
    }
    if (type === CHOOSE_DELETE) {
      return iconDelete;
    }
  };

  // Transfer action
  const transferAction = (type) => {
    if (type === CHOOSE_HANOVER) {
      return t('assets.assetsActivity.handoverBy');
    }
    if (type === CHOOSE_RECALL) {
      return t('assets.assetsActivity.recallBy');
    }
    if (type === CHOOSE_TRANSFER) {
      return t('assets.assetsActivity.transferBy');
    }
    if (type === CHOOSE_EDIT_STT) {
      return t('assets.assetsActivity.editSTT');
    }
    if (type === CHOOSE_CREATE_NEW) {
      return t('assets.assetsActivity.createAssets');
    }
  };

  // Transfer status asset
  const transferStatusAsset = (type) => {
    if (type === LIST_TYPE_GOOD) {
      return t('assets.good');
    }
    if (type === LIST_TYPE_UNDER_WARRANTY) {
      return t('assets.underWarranty');
    }
    if (type === LIST_TYPE_WAIT_FOR_LIQUIDATION) {
      return t('assets.waitForLiquidation');
    }
    if (type === LIST_TYPE_LIQUIDATION) {
      return t('assets.liquidation');
    }
    if (type === LIST_TYPE_BROKEN) {
      return t('assets.broken');
    }
    if (type === LIST_TYPE_MISSING) {
      return t('assets.missing');
    }
    if (type === LIST_TYPE_WAIT_FOR_APPROVE) {
      return t('assets.waitForApprove');
    }
    if (type === CANCEL) {
      return t('assets.cancel');
    }
  };

  // Transfer day of week
  const transferDayOfWeek = (day_of_week) => {
    if (day_of_week === DAY_OF_WEEK_SUNDAY) {
      return t('common.sunday');
    }
    if (day_of_week === DAY_OF_WEEK_MONDAY) {
      return t('common.monday');
    }
    if (day_of_week === DAY_OF_WEEK_TUESDAY) {
      return t('common.tuesday');
    }
    if (day_of_week === DAY_OF_WEEK_WEDNESDAY) {
      return t('common.wednesday');
    }
    if (day_of_week === DAY_OF_WEEK_THURSDAY) {
      return t('common.thursday');
    }
    if (day_of_week === DAY_OF_WEEK_FRIDAY) {
      return t('common.friday');
    }
    if (day_of_week === DAY_OF_WEEK_SATURDAY) {
      return t('common.saturday');
    }
  };

  // Transfer day of week ( abbreviation )
  const transferAbbreviationDayOfWeek = (day_of_week) => {
    if (day_of_week === DAY_OF_WEEK_SUNDAY) {
      return t('assets.assetsActivity.SUN');
    }
    if (day_of_week === DAY_OF_WEEK_MONDAY) {
      return t('assets.assetsActivity.MON');
    }
    if (day_of_week === DAY_OF_WEEK_TUESDAY) {
      return t('assets.assetsActivity.TUE');
    }
    if (day_of_week === DAY_OF_WEEK_WEDNESDAY) {
      return t('assets.assetsActivity.WED');
    }
    if (day_of_week === DAY_OF_WEEK_THURSDAY) {
      return t('assets.assetsActivity.THU');
    }
    if (day_of_week === DAY_OF_WEEK_FRIDAY) {
      return t('assets.assetsActivity.FRI');
    }
    if (day_of_week === DAY_OF_WEEK_SATURDAY) {
      return t('assets.assetsActivity.SAT');
    }
  };

  // Data that not flat() yet ( [[{...}], [{...}]])
  const pureData = data?.pages?.map((page) =>
    page?.data?.data?.data?.filter((item, index) => {
      return item;
    }),
  );

  // Flat the data
  const flattedData = pureData?.flat();

  // Data to render, add "duplicate" to item which duplicate
  const activityData = flattedData?.map((item, index) => {
    if (flattedData[index]?.date?.slice(0, 10)?.localeCompare(flattedData[index + 1]?.date?.slice(0, 10)) === 0) {
      flattedData[index + 1].date = `${flattedData[index + 1].date} duplicate`;
    }
    return item;
  });

  // Check if what date is duplicate
  const isSameGroup = (index) => {
    if (activityData) {
      if (activityData[index]?.date?.slice(0, 10)?.localeCompare(activityData[index + 1]?.date?.slice(0, 10)) === 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  // const renderActivities = (item) => {
  //   if (item?.type === CHOOSE_EDIT_STT) {
  //     if (item?.detail?.from === LIST_TYPE_WAIT_FOR_APPROVE && item?.detail?.to === LIST_TYPE_GOOD) {
  //       return (
  //         <>
  //           <span>Được duyệt bởi {item?.admin.name}</span>
  //         </>
  //       );
  //     }
  //     return (
  //       <>
  //         <span>
  //           : {transferStatusAsset(item?.detail?.from)} `{'->'}` {transferStatusAsset(item?.detail?.to)}
  //         </span>
  //       </>
  //     );
  //   }
  // }

  return (
    <InfiniteScroll
      className="activity-content-wrapper"
      hasMore={hasNextPage}
      loader={<Loading key="activity-item" />}
      loadMore={() => { }}
      onScroll={(e) => handleScroll(e)}
    >
      {isLoading && <Loading />}
      {pureData &&
        pureData[0] !== undefined &&
        activityData?.map((item, index) => (
          <Fade in timeout={1000}>
            <div in key={index} className={classNames('activity-item', isSameGroup(index) === true ? 'mb-0' : 'mb-5')}>
              <p
                className={classNames(
                  'activity-time',
                  (isSameGroup(index) === true || item?.date?.includes('duplicate') === true) && 'pb-0',
                )}
              >
                {item?.date?.includes('duplicate') === false && (
                  <span>
                    {transferDayOfWeek(item?.day_of_week)}, {item?.date?.slice(0, 10)}
                  </span>
                )}
              </p>
              {/* Item Detail */}
              <div className={classNames('activity-time-detail', isSameGroup(index) === true && 'border-bottom-0')}>
                <div className="activity-left-content">
                  <span className="activity-left-content-dd-mm">
                    {transferAbbreviationDayOfWeek(item?.day_of_week)} {item?.date?.slice(0, 2)}
                  </span>
                  <span className="activity-left-content-hh">{item?.date?.slice(11, 16)}</span>
                  <img className="home-icon" src={transferIcon(item?.type, item?.detail)} alt="" />
                </div>
                <div className="activity-right-content">
                  <p className="activity-assets-name">
                    {t('assets.assetsActivity.assets')} <b>{item?.asset_name}</b>{' '}
                    <a
                      href={`${configs.DOMAIN_APP_DOMAIN_AMS_BO}/admin/assets/list-assets?id=${item?.asset_id}`}
                      target="blank"
                      className="link-to-asset"
                    >
                      ({item?.asset_code})
                    </a>
                  </p>
                  {/* Type = 3 */}
                  {item?.type === CHOOSE_TRANSFER && (
                    <p className="activity-from-to">
                      {t('assets.assetsActivity.fromWarehouseTo')} : {item?.detail?.from} `{'->'}` {item?.detail?.to}
                    </p>
                  )}
                  <p className="activity-made-by">
                    {/* type action */}

                    {/* action for ( type = 1 or 2) */}
                    {(item?.type === CHOOSE_HANOVER || item?.type === CHOOSE_RECALL) && (
                      <>
                        <span>{transferAction(item?.type)} </span>
                        <b>
                          <StyledTooltip TransitionComponent={Zoom} title={<TooltipContent item={item} />}>
                            <span>
                              <b>{item?.detail?.name}</b>
                            </span>
                          </StyledTooltip>
                        </b>
                      </>
                    )}{' '}
                    {/* type action */}
                    {/* action for ( type = 1 or 2) */}
                    {(item?.type === CHOOSE_CANCEL || item?.type === LIST_TYPE_GOOD) && (
                      <>
                        <span>{transferAction(item?.type)} </span>
                        <b>
                          <StyledTooltip TransitionComponent={Zoom} title={<TooltipContent item={item} />}>
                            <span>
                              <b>{item?.detail?.name}</b>
                            </span>
                          </StyledTooltip>
                        </b>
                      </>
                    )}{' '}
                    {/* type action */}
                    {item?.detail?.from !== CHOOSE_CANCEL && item?.detail?.to !== LIST_TYPE_GOOD ? (
                      <span>{transferAction(item?.type)} </span>
                    ) : null}
                    {/* action for ( type = 1 or 2) */}
                    {(item?.type === CHOOSE_CANCEL || item?.type === LIST_TYPE_GOOD) && (
                      <b>
                        <StyledTooltip TransitionComponent={Zoom} title={<TooltipContent item={item} />}>
                          <span>
                            <b>{item?.detail?.name}</b>
                          </span>
                        </StyledTooltip>
                      </b>
                    )}{' '}
                    {/* Edit STT ( type = 4 ) */}
                    {item?.type === CHOOSE_EDIT_STT &&
                      item?.detail?.from === LIST_TYPE_WAIT_FOR_APPROVE &&
                      item?.detail?.to === LIST_TYPE_GOOD && (
                        <>
                          <span>
                            Được duyệt bởi{' '}
                            <StyledTooltip
                              TransitionComponent={Zoom}
                              title={<TooltipContent item={item} type={t('assets.assetsActivity.typeAdmin')} />}
                            >
                              <span>
                                <b>{item?.admin?.name}</b>
                              </span>
                            </StyledTooltip>
                          </span>
                        </>
                      )}
                    {/* Edit STT ( type = 4 ) */}
                    {Number(item?.type) === CHOOSE_EDIT_STT &&
                      (Number(item?.detail?.from) === CHOOSE_APPROVE) &&
                      (Number(item?.detail?.to) === CHOOSE_CANCEL) && (
                        <>
                          <span>
                            Bị hủy bởi{' '}
                            <StyledTooltip
                              TransitionComponent={Zoom}
                              title={<TooltipContent item={item} type={t('assets.assetsActivity.typeAdmin')} />}
                            >
                              <span>
                                <b>{item?.admin?.name}</b>
                              </span>
                            </StyledTooltip>
                          </span>
                        </>
                      )}
                    {item?.type === CHOOSE_EDIT_STT &&
                      item?.detail?.from !== LIST_TYPE_WAIT_FOR_APPROVE &&
                      item?.detail?.to !== LIST_TYPE_GOOD && (
                        <>
                          <span>{transferAction(item?.type)} </span>
                          <span>
                            : {transferStatusAsset(item?.detail?.from)} `{'->'}` {transferStatusAsset(item?.detail?.to)}
                          </span>
                        </>
                      )}
                    {item?.type === CHOOSE_EDIT_STT &&
                      item?.detail?.from === CHOOSE_CANCEL &&
                      item?.detail?.to === CHOOSE_HANOVER && (
                        <>
                          <span>{transferAction(item?.type)} </span>
                          <span>
                            : {transferStatusAsset(item?.detail?.from)} `{'->'}` {transferStatusAsset(item?.detail?.to)}
                          </span>
                        </>
                      )}
                    {/* {renderActivities(item)} */}
                    {item?.type === CHOOSE_DELETE && <span>Bị xóa bởi {item?.admin.name}</span>}
                    {/* Space ( type = 1, 2 or 4) */}
                    {(item?.type === CHOOSE_HANOVER ||
                      item?.type === CHOOSE_RECALL ||
                      item?.type === CHOOSE_EDIT_STT) && <br />}
                    {/* Action made by ( type = 1, 2 or 4) */}
                    {item?.detail?.from !== LIST_TYPE_WAIT_FOR_APPROVE && item?.detail?.to !== LIST_TYPE_GOOD ? (
                      <>
                        {item?.type === CHOOSE_CREATE_NEW && <span>{transferAction(item?.type)} </span>}
                        {(item?.type === CHOOSE_HANOVER ||
                          item?.type === CHOOSE_RECALL ||
                          item?.type === CHOOSE_EDIT_STT) &&
                          t('assets.assetsActivity.by')}{' '}
                        {/* Tooltip for admin */}
                        <StyledTooltip
                          TransitionComponent={Zoom}
                          title={<TooltipContent item={item} type={t('assets.assetsActivity.typeAdmin')} />}
                        >
                          <span>
                            <b>{item?.admin?.name}</b>
                          </span>
                        </StyledTooltip>
                      </>
                    ) : null}
                  </p>
                  {item?.content && (
                    <p className="activity-made-by">
                      <span>{t('assets.reasonsForRecovery')}</span>
                      <span>
                        <b>{' ' + item?.content}</b>
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Fade>
        ))}
      {pureData && (pureData[0] === undefined || pureData[0]?.length === 0) && (
        <div className="error-no-data">{t('common.notData')}</div>
      )}
    </InfiniteScroll>
  );
}

export default ListActivityAssets;
