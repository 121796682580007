
const Footer = () => {
  return (
    <footer>

    </footer>
  );
};

export default Footer;
