import { useQuery } from 'react-query';
import { HTTP_OK } from './../constants/StatusCode.js';
import {
  apiGetDivision,
  apiGetUsers,
  apiGetPosition,
  apiGetSkill,
  apiGetLevel,
  apiGetCertificate,
  apiSeniorityAndGender,
  apiListBirthday,
  apiTotalEmployees,
  apiCelebrationEmployee,
  apiPositionLevel,
  apiGetRequestListUser,
  apiGetStatusTeamOfDivision,
  apiGetTotalEmployeesByMonth,
  apiGetGroupUser,
} from './../api/employees';
import { apiGetStandbyUsers } from '../api/employeesRecruit';
import * as Constants from './../constants/ListEmployees';

export function useGetGroupUser(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_EMPLOYEES_GET_GROUP_USER, params], async () => {
    if (params?.division_id === 0) {
      params = {};
    }
    const response = await apiGetGroupUser(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useGetStatusTeamOfDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_STATUS_TEAM_OF_DIVISION, params], async () => {
    if (params?.division_id === 0) {
      params = {};
    }
    const response = await apiGetStatusTeamOfDivision(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useGetTotalEmployeesByMonth(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_GET_TOTAL_EMPLOYEES_BY_MONTH, params], async () => {
    if (params?.division_id === 0) {
      params = {};
    }
    const response = await apiGetTotalEmployeesByMonth(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useListDivision(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_DIVISION, params], async () => {
    if (params?.division_id === 0) {
      params = {};
    }
    const response = await apiGetDivision(params);
    return response.data;
  });

  return { ...response, error, isLoading };
}

export function useSeniorityAndGender(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery('seniority_gender', async () => {
    const response = await apiSeniorityAndGender(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListBirthday(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(['dashboard_list_birthday', params], async () => {
    const response = await apiListBirthday(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useTotalEmployees(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(['dashboard_total_employee', params], async () => {
    const response = await apiTotalEmployees(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useCelebrationEmployee(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(['dashboard_celebration_employee', params], async () => {
    const response = await apiCelebrationEmployee(params);
    if (response.status === HTTP_OK) {
      return response;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function usePositionLevel(params) {
  if (params?.division_id === 0) {
    params = {};
  }
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(['dashboard_position_level', params], async () => {
    const response = await apiPositionLevel(params);
    if (response.status === HTTP_OK) {
      return response.data;
    }
    return null;
  });

  return { ...response, error, isLoading };
}

export function useListUser(params) {
  const {
    data: response,
    error,
    isLoading,
  } = useQuery([Constants.USE_LIST_USERS, params], async () => {
    const response = await apiGetUsers(params);
    return response;
  });

  return { ...response, error, isLoading };
}

export function useListPosition(params) {
  const { data, error, isLoading } = useQuery(Constants.USE_LIST_POSITION, async () => {
    const response = await apiGetPosition(params);
    if (response.status === HTTP_OK) {
      return response.data.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useListLevel(params) {
  const { data, error, isLoading } = useQuery(Constants.USE_LIST_LEVEL, async () => {
    const response = await apiGetLevel(params);
    if (response.status === HTTP_OK) {
      return response.data.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useListSkill(params) {
  const { data, error, isLoading } = useQuery(Constants.USE_LIST_SKILL, async () => {
    const response = await apiGetSkill(params);
    if (response.status === HTTP_OK) {
      return response.data.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useListCertificate(params) {
  const { data, error, isLoading } = useQuery(Constants.USE_LIST_CERTIFICATE, async () => {
    const response = await apiGetCertificate(params);
    if (response.status === HTTP_OK) {
      return response.data.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useRequestListUser(params) {
  const { data, error, isLoading } = useQuery(Constants.USE_REQUEST_GET_LIST_USER, async () => {
    const response = await apiGetRequestListUser(params);
    if (response.status === HTTP_OK) {
      return response.data.data;
    }
    return null;
  });

  return { data, error, isLoading };
}

export function useListStandbyUser(params) {
  const { data: response, error, isLoading } = useQuery([Constants.USE_LIST_STANDBY_EMPLOYEES, params], async () => {
    const response = await apiGetStandbyUsers(params);
    response.data.data.list_user_pending.sort(function(user1, user2){
      return new Date(user1.start_date) - new Date(user2.start_date);
    });
    return response;
  });

  return { ...response, error, isLoading };
}
