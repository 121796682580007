import './../style.scss';
import Training, { DATE_FORMAT, showConfirm } from '..';
import { Card, Button, Table, message } from 'antd';
import { InfoIcon, SearchIcon } from '../icon';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { useState } from 'react';
import { DEFAULT_PAGE, DEFAULT_LIMIT } from 'constants/index';
import { REFUND_FULL, FIELD_KEY } from 'constants/Training';
import { customDate } from 'helpers/formatDate';
import CustomPagination from '../CustomPagination';
import CreateProgram from './CreateProgram';
import { useGetCourses } from 'hook/useTraining';
import { MdDelete, MdEdit, MdExpandLess, MdExpandMore } from 'react-icons/md';
import { apiDeleteCourse } from 'api/training';
import { useMutation } from 'react-query';
import { HTTP_OK } from 'constants/StatusCode';
import UpdateProgram from './UpdateProgram';
import AddMember from './AddMember';
import { useGetAllUsers } from 'hook/useTraining';
import FilterProgram from './FilterProgram';
import { COURSE_IN_COMPANY } from 'constants/Training';
import { COURSE_OUTSIDE_COMPANY } from 'constants/Training';
import { TrainingProvider } from '../Context/TrainingProvider';
import { useHistory } from 'react-router-dom';
const EducationProgram = ({ isDashboard }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({
    page: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT,
  });
  const [isCollapse, setIsCollapse] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenAddMember, setIsOpenAddMember] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});
  const { courses, isLoading, refetch } = useGetCourses(filter);
  const { users } = useGetAllUsers();
  const history = useHistory();

  const deleteCourseMutation = useMutation(apiDeleteCourse, {
    onMutate: () => setIsDisable(true),
    onSuccess: (res) => {
      const messageText = res?.data?.messages;
      const status = res?.status;

      if (status === HTTP_OK) {
        message.success(messageText);
      } else {
        message.error(messageText);
      }

      refetch();
      setIsDisable(false);
    },
  });

  const columns = [
    {
      title: t('training.index'),
      dataIndex: FIELD_KEY.INDEX,
      key: FIELD_KEY.INDEX,
      align: 'center',
      width: 60,
      render: (_, record, index) => index + filter.limit * (filter.page - 1) + 1,
    },
    {
      title: t('training.programType'),
      dataIndex: FIELD_KEY.TYPE,
      key: FIELD_KEY.TYPE,
      width: 160,
      render: (value) => (value === COURSE_IN_COMPANY
        ? t('training.courseInCompany')
        : value === COURSE_OUTSIDE_COMPANY
          ? t('training.courseOutsideCompany')
          : t('training.certificate')
      )
    },
    {
      title: t('training.programName'),
      dataIndex: FIELD_KEY.NAME,
      key: FIELD_KEY.NAME,
      width: 150,
    },
    {
      title: t('training.totalStudents'),
      dataIndex: FIELD_KEY.USER_COURSE_COUNT,
      key: FIELD_KEY.USER_COURSE_COUNT,
      width: 140,
      align: 'center'
    },
    {
      title: t('training.monthCommit'),
      dataIndex: FIELD_KEY.MONTH_COMMIT,
      key: FIELD_KEY.MONTH_COMMIT,
      width: 140,
      align: 'center'
    },
    {
      title: t('training.supportCost'),
      dataIndex: FIELD_KEY.SUPPORT_COST,
      key: FIELD_KEY.SUPPORT_COST,
      width: 130,
      render: (value) => value.toLocaleString()?.replace('.', ','),
      align: 'center'
    },
    {
      title: t('training.startDate'),
      dataIndex: FIELD_KEY.START_DATE,
      key: FIELD_KEY.START_DATE,
      render: (value) => (value ? customDate(value, DATE_FORMAT) : '-'),
      width: 120,
      align: 'center'
    },
    {
      title: t('training.endDate'),
      dataIndex: FIELD_KEY.END_DATE,
      key: FIELD_KEY.END_DATE,
      render: (value) => (value ? customDate(value, DATE_FORMAT) : '-'),
      width: 120,
      align: 'center'
    },
    {
      title: t('training.rateCommit'),
      dataIndex: FIELD_KEY.RATE_COMMIT,
      key: FIELD_KEY.RATE_COMMIT,
      render: (value) => {
        const content = value === REFUND_FULL ? t('training.refundFull') : t('training.refundByPercent');
        const css = value === REFUND_FULL ? 'refund-full' : 'refund-percent';
        return <span className={css}>{content}</span>;
      },
      width: 160,
    },
    {
      title: t('training.addMember'),
      align: 'center',
      width: 130,
      render: (_, record) => (
        <Button
          className="button addMember"
          type="primary"
          icon={<FiPlus size={24} />}
          onClick={() => {
            setIsDisable(true);
            setIsOpenAddMember(true);
            setSelectedCourse(record);
          }}
        >
          Add
        </Button>
      ),
    },
    {
      align: 'center',
      width: 115,
      render: (_, record) => (
        <div className="flex items-center">
          <Button
            className="button edit-course"
            icon={
              <MdEdit
                size={24}
                color="#909090"
                onClick={() => {
                  setIsDisable(true);
                  setIsOpenUpdate(true);
                  setSelectedCourse(record);
                }}
              />
            }
          />
          <Button
            className="button delete-course"
            icon={<MdDelete size={24} color="#909090" />}
            disabled={isDisable}
            onClick={() =>
              showConfirm({
                content: t('training.doYouWantDeleteThisProgram'),
                width: 328,
                onOk: () => deleteCourseMutation.mutate(record.id),
              })
            }
          />
        </div>
      ),
    },
  ];

  return (
    <TrainingProvider>
      <div className="scroll ct-form-controller-filter mb-3">
        {!isDashboard && (
          <div className="nav-fixed">
            <Training />
          </div>
        )}
        <Card
          className="card mb-3"
          title={
            <span className="title">
              <SearchIcon />
              {t('dashboard.search')}
            </span>
          }
          extra={
            <span className="cursor-pointer" onClick={() => setIsCollapse(!isCollapse)}>
              {isCollapse ? <MdExpandMore size={25} color="#FEA628" /> : <MdExpandLess size={25} color="#FEA628" />}
            </span>
          }
        >
          {!isCollapse ? <FilterProgram filter={filter} setFilter={setFilter} /> : '' }
        </Card>
        <Card
          className="card"
          title={
            <span className="title">
              <InfoIcon />
              {t('training.educationProgram')}
            </span>
          }
          extra={
            <Button
              className="button addProgram"
              type="primary"
              icon={<FiPlus size={24} />}
              onClick={() => setIsOpenCreate(true)}
            >
              {t('training.addProgram')}
            </Button>
          }
        >
          <Table
            columns={columns}
            dataSource={courses?.data || []}
            loading={isLoading || deleteCourseMutation.isLoading}
            rowKey={'id'}
            pagination={false}
            onRow={(record) => ({
              onClick: (event) => {
                setSelectedCourse(record);
                if (!event.target.closest('.button')) {
                  history.push({
                    pathname: `/admin/training/program-details/${record.id}`,
                    state: { record }
                  });
                }

              },
            })}
            scroll={{ x: 'max-content', y: 500 }}
            footer={() => (
              <CustomPagination
                loading={isLoading}
                totalPage={courses?.total}
                currentPage={filter.page}
                limit={filter.limit}
                from={courses?.from}
                to={courses?.to}
                onChangePage={(page, limit) => setFilter({ ...filter, page, limit })}
              />
            )}
          />
        </Card>

        {isOpenCreate && <CreateProgram isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} refetch={refetch} />}
        {isOpenUpdate && (
          <UpdateProgram
            isOpen={isOpenUpdate}
            setIsOpen={setIsOpenUpdate}
            refetch={refetch}
            setIsDisable={setIsDisable}
            selectedCourse={selectedCourse}
          />
        )}
        {isOpenAddMember && (
          <AddMember
            isOpen={isOpenAddMember}
            setIsOpen={setIsOpenAddMember}
            refetch={refetch}
            setIsDisable={setIsDisable}
            selectedCourse={selectedCourse}
            users={users}
          />
        )}
      </div>
    </TrainingProvider>
  );
};

export default EducationProgram;
