import * as ConstantProfile from 'constants/ProfileEmployee';
import { GENDER_MALE } from 'constants/ProfileEmployee';

export const getTypeRelative = (type) => {
  let strTypeRelative = 'infoEmployee.notUpdate';

  switch (type) {
    case ConstantProfile.TYPE_RELATIVE_FATHER:
      strTypeRelative = 'infoEmployee.father';
      break;
    case ConstantProfile.TYPE_RELATIVE_MOTHER:
      strTypeRelative = 'infoEmployee.mother';
      break;
    case ConstantProfile.TYPE_RELATIVE_WIFE:
      strTypeRelative = 'infoEmployee.wife';
      break;
    case ConstantProfile.TYPE_RELATIVE_CHILD:
      strTypeRelative = 'infoEmployee.child';
      break;
    case ConstantProfile.TYPE_RELATIVE_SIBLINGS:
      strTypeRelative = 'infoEmployee.siblings';
      break;
    case ConstantProfile.HUSBAND:
      strTypeRelative = 'infoEmployee.hubsband';
      break;

    default:
      break;
  }
  return strTypeRelative;
};

export const getGenderEmployee = (typeGender) => {
  if (typeGender === GENDER_MALE) {
    return 'infoEmployee.genderMale';
  }
  return 'infoEmployee.genderFemale';
};
