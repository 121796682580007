import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Col, Label, Row } from 'reactstrap';
import './style.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { sendPost, sendPut } from '../../../../helpers/axios';
import { useMutation, useQueryClient } from 'react-query';

import { HTTP_NOT_ACCESS } from 'constants/StatusCode.js';
import ButtonComponent from 'components/Buttons/index.js';
import ModalComponent from 'components/Modal';
import YupValidate from './yupValidate';
import CustomRadioInput from 'components/InputYupAndMui/CustomRadioInput';
import CustomCheckboxYup from 'components/InputYupAndMui/CustomCheckboxYup';
import { useGetPosition } from 'hook/useMasterData';
import { useListCategoryContract } from 'hook/useMasterData';
import { convertFormatTimeSend } from './ultil.js';
import * as Constants from 'constants/MasterData.js';
import { MAX_LENGTH_100, MAX_LENGTH_50 } from 'constants/index.js';
import { useListUserPositionContract } from 'hook/useMasterData';
import CustomAutoComplete from 'components/InputYupAndMui/CustomAutoComplete';
import CustomAutoCompleteCheckAll from 'components/InputYupAndMui/CustomAutoCompleteCheckAll';
import { IS_NOT_DISABLE_CHECKBOX_INFINITY } from 'constants/MasterData';
import { IS_DISABLE_CHECKBOX_INFINITY } from 'constants/MasterData';
import { IS_CHECK_RADIO_INDEPENDENCE } from 'constants/MasterData';
import { IS_CHECK_RADIO_DEPENDENCE } from 'constants/MasterData';
import DateDay from 'components/ReactForm/DateDay.js';
import AlertComponent from 'components/Alert';
import CustomInputYup from 'components/InputYupAndMui/CustomInputYup';
import { debounce } from '@material-ui/core';
import TimeComponentCustom from 'components/DateTime/TimeComponentCustom';

function ModalAddSpecialTimeKeeping({
  isShowModal,
  setShowModal,
  selectedValue,
  type,
  isShowAlert,
  setShowAlert,
  message,
  setMessage,
}) {
  const { t } = useTranslation();
  const [disableBtn, setDisableBtn] = useState(false);
  const [filter, setFilter] = useState({
    key_word: '',
    position: [],
    contract: [],
  });
  const [filterPosition, setFilterPosition] = useState({
    key_word: '',
  });
  const [filterContract, setFilterContract] = useState({
    key_word: '',
  });

  const [arrayDeleted, setArrayDeleted] = useState({
    arr: [],
  });
  const radioList = [
    // full
    {
      value: IS_CHECK_RADIO_DEPENDENCE,
      labelName: t('masterData.specialTimeKeeping.dependCheck'),
    },
    // disable
    {
      value: IS_CHECK_RADIO_INDEPENDENCE,
      labelName: t('masterData.specialTimeKeeping.noDependCheck'),
    },
  ];
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(YupValidate()),
  });

  // Watch value type timekeeping
  const watchTypeTimekeepingValue = useWatch({
    control,
    name: 'type',
    defaultValue: selectedValue !== undefined ? selectedValue?.type : IS_DISABLE_CHECKBOX_INFINITY,
  });

  useEffect(() => {
    if (Number(watchTypeTimekeepingValue) === 1) {
      clearErrors(['hour_start_morning', 'hour_end_morning', 'hour_start_afternoon', 'hour_end_afternoon']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchTypeTimekeepingValue]);

  // Watch value start time morning
  const watchStartMorning = useWatch({
    control,
    name: 'hour_start_morning',
    defaultValue:
      selectedValue?.hour_start_morning !== null &&
      selectedValue !== undefined &&
      Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY
        ? moment(moment(selectedValue?.hour_start_morning, 'HH:mm:ss').format('HH:mm'), 'HH:mm')
        : null,
  });

  // Watch value end morning
  const watchEndMorning = useWatch({
    control,
    name: 'hour_end_morning',
    defaultValue:
      selectedValue?.hour_end_morning !== null &&
      selectedValue !== undefined &&
      Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY
        ? moment(moment(selectedValue?.hour_end_morning, 'HH:mm:ss').format('HH:mm'), 'HH:mm')
        : null,
  });

  // Watch value start afternoon
  const watchStartAfternoon = useWatch({
    control,
    name: 'hour_start_afternoon',
    defaultValue:
      selectedValue?.hour_start_afternoon !== null &&
      selectedValue !== undefined &&
      Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY
        ? moment(moment(selectedValue?.hour_start_afternoon, 'HH:mm:ss').format('HH:mm'), 'HH:mm')
        : null,
  });

  // Watch value end afternoon
  const watchEndAfternoon = useWatch({
    control,
    name: 'hour_end_afternoon',
    defaultValue:
      selectedValue?.hour_end_afternoon !== null &&
      selectedValue !== undefined &&
      Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY
        ? moment(moment(selectedValue?.hour_end_afternoon, 'HH:mm:ss').format('HH:mm'), 'HH:mm')
        : null,
  });

  // Watch value checkbox no time end
  const watchCheckboxValue = useWatch({
    control,
    name: 'no_time_end',
    defaultValue: selectedValue?.end_date === null ? true : false,
  });

  useEffect(() => {
    if (watchCheckboxValue) {
      clearErrors('end_date');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCheckboxValue]);

  // Watch value start date
  const watchStartDateValue = useWatch({
    control,
    name: 'start_date',
  });

  // Watch value end date
  const watchEndDateValue = useWatch({
    control,
    name: 'end_date',
  });

  // Watch value select position
  const watchSelectPosition = useWatch({
    control,
    name: 'position_id',
  });

  // Watch value select type contract
  const watchSelectTypeContract = useWatch({
    control,
    name: 'contract_category',
  });

  // Watch value autocomplete user
  const watchValueAutoComplete = useWatch({
    control,
    name: 'user_id',
  });

  // Position
  const { data: uListPosition, isLoading: isLoadingListPosition } = useGetPosition(filterPosition);

  // Type contract
  const { data: uListTypeContract, isLoading: isLoadingListTypeContract } = useListCategoryContract(filterContract);

  // List user contract position
  const { data: uListUserPositionContract, isLoading: isLoadingListUserPositionContract } =
    useListUserPositionContract(filter);

  // Searching
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchPosition = useCallback(
    debounce((value) => {
      setFilterPosition({ key_word: value });
    }, 1000),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchTypeContract = useCallback(
    debounce((value) => {
      setFilterContract({ key_word: value });
    }, 1000),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchPositionContract = useCallback(
    debounce((value) => {
      setFilter({ position: watchSelectPosition, contract: watchSelectTypeContract, key_word: value });
    }, 1000),
    [watchSelectPosition, watchSelectTypeContract],
  );

  useEffect(() => {
    setFilter({
      ...filter,
      position: watchSelectPosition?.length !== 0 ? watchSelectPosition : undefined,
      contract: watchSelectTypeContract?.length !== 0 ? watchSelectTypeContract : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSelectPosition, watchSelectTypeContract]);

  // Handle case filter all user when list user search change
  useEffect(() => {
    setFilter({
      ...filter,
      key_word: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchValueAutoComplete]);

  useEffect(() => {
    setFilterPosition({
      key_word: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSelectPosition]);

  useEffect(() => {
    setFilterContract({
      key_word: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSelectTypeContract]);

  // Add item API
  const queryClient = useQueryClient();
  const { mutate: addItem } = useMutation((formField) => sendPost('/api/timesheet', formField), {
    onSettled: (response) => {
      if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
        setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
        setShowAlert(true);
        setDisableBtn(false);
        setShowModal(false);
        return;
      }
      setShowAlert(true);
      setMessage(response.data.messages);
      setDisableBtn(false);
      setShowModal(false);
      queryClient.refetchQueries(Constants.USE_LIST_SPECIAL_TIME_KEEPING);
    },
  });

  // Edit item API
  const { mutate: editItem } = useMutation((formField) => sendPut(`/api/timesheet/${selectedValue?.id}`, formField), {
    onSettled: (response) => {
      if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
        setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
        setShowAlert(true);
        setDisableBtn(false);
        setShowModal(false);
        return;
      }
      setDisableBtn(true);
      setMessage(response.data.messages);
      setShowAlert(true);
      queryClient.refetchQueries(Constants.USE_LIST_SPECIAL_TIME_KEEPING);
      setDisableBtn(false);
      setShowModal(false);
    },
  });

  // Delete item from autocomplete
  const addItemsDelete = (item) => {
    var newArray = arrayDeleted.arr.slice();
    newArray.push(item);
    setArrayDeleted({ arr: newArray });
  };

  const addDeleteAllItem = (items) => {
    setArrayDeleted({
      arr: items,
    });
  };

  // Submit data
  const onSubmit = async (data) => {
    const convertData = {
      name: data.name,
      type: Number(data.type),
      start_date: convertFormatTimeSend(data['start_date'], 'DD/MM/yyyy') || selectedValue?.start_date,

      end_date:
        convertFormatTimeSend(watchEndDateValue, 'DD/MM/yyyy') === 'Invalid date'
          ? undefined
          : convertFormatTimeSend(watchEndDateValue, 'DD/MM/yyyy') || undefined,

      hour_start_morning:
        convertFormatTimeSend(watchStartMorning, 'HH:mm') === 'Invalid date'
          ? undefined
          : convertFormatTimeSend(watchStartMorning, 'HH:mm') || undefined,

      hour_end_morning:
        convertFormatTimeSend(watchEndMorning, 'HH:mm') === 'Invalid date'
          ? undefined
          : convertFormatTimeSend(watchEndMorning, 'HH:mm') || undefined,

      hour_start_afternoon:
        convertFormatTimeSend(watchStartAfternoon, 'HH:mm') === 'Invalid date'
          ? undefined
          : convertFormatTimeSend(watchStartAfternoon, 'HH:mm') || undefined,

      hour_end_afternoon:
        convertFormatTimeSend(watchEndAfternoon, 'HH:mm') === 'Invalid date'
          ? undefined
          : convertFormatTimeSend(watchEndAfternoon, 'HH:mm') || undefined,
      user_id: watchValueAutoComplete?.length !== 0 ? watchValueAutoComplete : undefined,
      status: Number(watchCheckboxValue ? 1 : 0),
      user_deleted: arrayDeleted?.arr?.length !== 0 ? arrayDeleted?.arr : undefined,
    };
    setDisableBtn(true);
    if (type === 'add') {
      await addItem(convertData);
    }
    if (type === 'edit') {
      await editItem(convertData);
    }
  };

  return (
    <>
      <ModalComponent
        toggle={() => {
          setShowModal(!isShowModal);
          if (!isShowModal) {
            reset();
          }
        }}
        isShowModal={isShowModal}
        title={
          type === 'add'
            ? t('masterData.specialTimeKeeping.addTimekeeping')
            : t('masterData.specialTimeKeeping.changeTimeKeeping')
        }
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {/* Title  */}
          <div className="mt-2 mb-4">
            <CustomInputYup
              placeholder={t('masterData.specialTimeKeeping.table.title')}
              defaultValue={type === 'edit' ? selectedValue?.name : ''}
              label={t('masterData.specialTimeKeeping.table.title')}
              name="name"
              errors={errors}
              control={control}
              register={register}
              useWatch={useWatch}
              required
              setValue={setValue}
              maxLength={MAX_LENGTH_100}
            />
          </div>

          {/* Type time keeping */}
          <section>
            <p className="text-bold color-navy"> {t('masterData.specialTimeKeeping.table.typeTimeKeeping')}</p>
            <Col>
              <div className="mt-2 mb-2">
                <CustomRadioInput
                  control={control}
                  radioList={radioList}
                  defaultValue={Number(selectedValue?.type) || IS_CHECK_RADIO_INDEPENDENCE}
                  name="type"
                  setValue={setValue}
                />
              </div>
            </Col>
          </section>

          {/* Time CI and CO */}
          <section>
            <p className="text-bold color-navy"> {t('masterData.specialTimeKeeping.timeCiCo')}</p>
            <Label className="text-bold color-navy mb-2 mt-2">{t('masterData.specialTimeKeeping.morningTime')}</Label>
            <Row>
              <Col md={6}>
                <section className="custom-input-timePicker-mui">
                  <TimeComponentCustom
                    label={t('masterData.placeholderStartTime')}
                    placeholder={t('masterData.placeholderStartTime')}
                    name={'hour_start_morning'}
                    value={
                      Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY ? watchStartMorning : null
                    }
                    format="HH:mm"
                    setValue={setValue}
                    setError={setError}
                    disable={Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY ? false : true}
                    handleChangeTime={(time) => {
                      setValue('hour_start_morning', moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null);
                    }}
                    error={errors?.hour_start_morning?.message}
                  />
                </section>
              </Col>
              <Col md={6}>
                <section className="custom-input-timePicker-mui">
                  <TimeComponentCustom
                    label={t('masterData.placeholderEndTime')}
                    placeholder={t('masterData.placeholderEndTime')}
                    name={'hour_end_morning'}
                    value={
                      Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY ? watchEndMorning : null
                    }
                    format="HH:mm"
                    setValue={setValue}
                    setError={setError}
                    disable={Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY ? false : true}
                    handleChangeTime={(time) => {
                      setValue('hour_end_morning', moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null);
                    }}
                    error={errors?.hour_end_morning?.message}
                  />
                </section>
              </Col>
            </Row>

            <Label className="text-bold color-navy mb-2 mt-3">{t('masterData.specialTimeKeeping.nightTime')}</Label>
            <Row>
              <Col md={6}>
                <section className="custom-input-timePicker-mui">
                  <TimeComponentCustom
                    label={t('masterData.placeholderStartTime')}
                    placeholder={t('masterData.placeholderStartTime')}
                    name={'hour_start_afternoon'}
                    value={
                      Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY
                        ? watchStartAfternoon
                        : null
                    }
                    format="HH:mm"
                    setValue={setValue}
                    setError={setError}
                    disable={Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY ? false : true}
                    handleChangeTime={(time) => {
                      setValue('hour_start_afternoon', moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null);
                    }}
                    error={errors?.hour_start_afternoon?.message}
                  />
                </section>
              </Col>
              <Col md={6}>
                <section className="custom-input-timePicker-mui">
                  <TimeComponentCustom
                    label={t('masterData.placeholderEndTime')}
                    placeholder={t('masterData.placeholderEndTime')}
                    name={'hour_end_afternoon'}
                    value={
                      Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY ? watchEndAfternoon : null
                    }
                    format="HH:mm"
                    setValue={setValue}
                    setError={setError}
                    disable={Number(watchTypeTimekeepingValue) === IS_NOT_DISABLE_CHECKBOX_INFINITY ? false : true}
                    handleChangeTime={(time) => {
                      setValue('hour_end_afternoon', moment(time, 'HH:mm').isValid() ? moment(time, 'HH:mm') : null);
                    }}
                    error={errors?.hour_end_afternoon?.message}
                  />
                </section>
              </Col>
            </Row>
          </section>

          {/* Time */}
          <section>
            <p className="text-bold color-navy mt-3 mb-1"> {t('masterData.specialTimeKeeping.dayAppend')}</p>
            <CustomCheckboxYup
              name="no_time_end"
              defaultValue={watchCheckboxValue}
              control={control}
              register={register}
              label={t('masterData.specialTimeKeeping.noTimeEnd')}
            />
            <Row>
              <Col className="md-6">
                <DateDay
                  label={t('masterData.placeholderStartDate')}
                  name="start_date"
                  error={errors?.start_date?.message}
                  control={control}
                  setValue={setValue}
                  register={register}
                  isShowModal={isShowModal}
                  defaultValue={moment(selectedValue?.start_date, 'DD/MM/yyyy').toDate()}
                />
              </Col>
              <Col className="md-6">
                <DateDay
                  label={t('masterData.placeholderEndDate')}
                  name="end_date"
                  error={errors?.end_date?.message}
                  defaultValue={moment(selectedValue?.end_date, 'DD/MM/yyyy').toDate()}
                  control={control}
                  setValue={setValue}
                  register={register}
                  isShowModal={isShowModal}
                  disabled={watchCheckboxValue}
                  minDate={selectedValue?.start_date || moment(watchStartDateValue, 'DD/MM/yyyy').toDate()}
                />
              </Col>
            </Row>
          </section>

          {/* Object append */}
          <section>
            <p className="text-bold color-navy mt-3 mb-3"> {t('masterData.specialTimeKeeping.objectAppend')}</p>

            {/* Position */}
            <CustomAutoComplete
              valueAutoFill={uListPosition?.data}
              label={t('employees.position')}
              defaultValue={null}
              name="position_id"
              control={control}
              register={register}
              errors={errors}
              useWatch={useWatch}
              setValue={setValue}
              // addItemsDelete={addItemsDelete}
              // addDeleteAllItem={addDeleteAllItem}
              handleSearch={handleSearchPosition}
              isLoading={isLoadingListPosition}
            />

            <div className="mt-3 mb-3">
              {/* Contract type */}
              <CustomAutoComplete
                valueAutoFill={uListTypeContract}
                placeholder={t('masterData.specialTimeKeeping.table.typeContract')}
                defaultValue={null}
                label={t('masterData.specialTimeKeeping.table.typeContract')}
                name="contract_category"
                control={control}
                register={register}
                errors={errors}
                useWatch={useWatch}
                setValue={setValue}
                handleSearch={handleSearchTypeContract}
                isLoading={isLoadingListTypeContract}
              />
            </div>
            <div className="mt-3 mb-3">
              {/* User select */}
              <CustomAutoCompleteCheckAll
                valueAutoFill={uListUserPositionContract?.data}
                placeholder={t('masterData.specialTimeKeeping.insertNameEmployee')}
                defaultValue={type === 'edit' ? selectedValue?.user : null}
                label={t('masterData.specialTimeKeeping.insertNameEmployee')}
                name="user_id"
                control={control}
                register={register}
                errors={errors}
                useWatch={useWatch}
                maxLength={MAX_LENGTH_50}
                setValue={setValue}
                addItemsDelete={addItemsDelete}
                addDeleteAllItem={addDeleteAllItem}
                handleSearch={handleSearchPositionContract}
                isLoading={isLoadingListUserPositionContract}
                positionId={watchSelectPosition}
                contractCategory={watchSelectTypeContract}
              />
            </div>
          </section>

          {/* Bottom button */}
          <Row className="mt-5">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModal(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={type === 'add' ? t('common.add') : t('common.update')}
                addClass="w-100"
                handleClick={() => handleSubmit(onSubmit)}
                type="submit"
                hasDisabled={disableBtn}
                isLoading={disableBtn}
              />
            </Col>
          </Row>
        </form>
      </ModalComponent>

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
}

export default ModalAddSpecialTimeKeeping;
