import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import iconChecked from './../../assets/img/icons/checked.png';
import * as Constants from 'constants/ListEmployees';

import styles from './styles.module.scss';

import dragIcon from 'assets/img/icons/drag-icon.svg';
import classNames from 'classnames';

const ModalCommon = (props) => {
  const { toggle, modal, isDrag, onDragEnd } = props;

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className={`${styles.modalCommon} modal-dialog-centered`}>
        <ModalHeader className={styles.modalTitle} toggle={toggle}>
          {props.title}
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          <div
            className={props?.items[0]?.status ? `d-flex ${styles.itemCheck} ${styles.activeExport}` : styles.itemCheck}
            onClick={() => props.onClick(props.items[0])}
          >
            {props.items[0]?.status && <img src={iconChecked} alt="checked" className={styles.checked} />}

            <p className={classNames(styles.exportTitle, { [styles.dragTitle]: isDrag })}>{props.items[0]?.name}</p>
          </div>
          <hr className={styles.hrModal} />

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-1">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {props.items &&
                    [...props.items].slice(1, props.items.length).map((item, index) => (
                      <Draggable draggableId={item.name} index={index} key={item.name} isDragDisabled={!isDrag}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <div
                              className={
                                item.status ? `d-flex ${styles.itemCheck} ${styles.activeExport}` : styles.itemCheck
                              }
                              onClick={() => props.onClick(item)}
                            >
                              {item.status && <img src={iconChecked} alt="checked" className={styles.checked} />}
                              {isDrag &&
                                (props.items[0].status ||
                                  ([...props.items].slice(1, props.items.length).filter((el) => el.status).length >=
                                    2 &&
                                    item.status)) && (
                                  <img
                                    src={dragIcon}
                                    alt=""
                                    className={styles.dragIcon}
                                    {...provided.dragHandleProps}
                                  />
                                )}

                              <p className={classNames(styles.exportTitle, { [styles.dragTitle]: isDrag })}>
                                {item.name}
                              </p>
                            </div>
                            <hr className={styles.hrModal} />
                          </div>
                        )}
                      </Draggable>
                    ))}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button
            onClick={props.onSubmit}
            className={styles.buttonSubmit}
            disabled={
              props.nameModal === Constants.EXPORT && !props.items.some((item) => true === item.status) ? true : false
            }
          >
            {props.footer}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalCommon;
