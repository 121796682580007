import { useState } from 'react';
import { Card, Divider, Button } from 'antd';
import { MdEdit } from 'react-icons/md';
import { REFUND_FULL, FIELD_KEY} from 'constants/Training';
import UpdateProgram from './UpdateProgram';
import { DATE_FORMAT } from '..';
import { customDate } from 'helpers/formatDate';
const CourseOutsideCompanyDetail = ({ record, t, refetch }) => {
  const { Meta } = Card;
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});
  return (
    <div>
    <Card className="course-info" style={{ marginBottom: '65px' }}>
      <Meta title={t('training.courseName')} />
      <div>{record[FIELD_KEY.NAME]}</div>
      <Divider />
      <Meta title={t('training.type')} />
      <div>{t('training.courseOutsideCompany')}</div>
      <Button
        className="btn-edit-course"
        size="small"
        shape="circle"
        icon={<MdEdit color='#FEA628' size={16} />}
        onClick={() => {
          setIsOpenUpdate(true);
          setSelectedCourse(record);
        }}
      />
      <Divider />
      <Meta title={t('training.location')} />
      <div>{record[FIELD_KEY.LOCATION]}</div>
      <Divider />
      <Meta title={t('training.timeCommit')} />
      {record[FIELD_KEY.START_DATE] && record[FIELD_KEY.END_DATE] ? (
         <div>{customDate(record[FIELD_KEY.START_DATE], DATE_FORMAT)}~{customDate(record[FIELD_KEY.END_DATE], DATE_FORMAT)}</div>
      ) : (
        "N/A"
      )}
      <Divider />
      <Meta title={t('training.maxSupportCost')} />
      <div>{record[FIELD_KEY.SUPPORT_COST].toLocaleString()?.replace('.', ',')} VND/người</div>
      <Divider />
      <Meta title={t('training.monthCommit')} />
      <div>{record[FIELD_KEY.MONTH_COMMIT]} tháng</div>
      <Divider />
      <Meta title={t('training.rateCommit')} />
      {record[FIELD_KEY.RATE_COMMIT] === REFUND_FULL ? (
        <div>{t('training.refundFull')}</div>
      ) : (
        <div>{t('training.refundByPercent')}</div>
      )}
      <Divider />
      <Meta title={t('training.description')} />
      <p>{record[FIELD_KEY.DESCRIPTION]}</p>
    </Card>
    {isOpenUpdate && (
      <UpdateProgram
      isOpen={isOpenUpdate}
      setIsOpen={setIsOpenUpdate}
      refetch={refetch}
      selectedCourse={selectedCourse}
      />
    )}
    </div>
  );
};

export default CourseOutsideCompanyDetail;